import { push } from "connected-react-router";
import { put, takeLatest } from "redux-saga/effects";
import { successMessage } from "../../components/Common/Feedback/Message";
import { loginUserFailed, loginUserSucceeded } from "../actions/userActions";
import { LOGIN_USER, LOGOUT_USER } from "../constants/constants";

function* loginUserSaga(action) {
  try {
    yield put(loginUserSucceeded(action.payload));
    yield put(push("/"));
  } catch (error) {
    yield put(loginUserFailed(error));
  }
}

export function* handleLogout() {
  localStorage.clear();
  successMessage("authentication.logged_out.text");
  yield put(push("/authenticate"));
}

function* userSaga() {
  yield takeLatest(LOGIN_USER, loginUserSaga);
  yield takeLatest(LOGOUT_USER, handleLogout);
}

export default userSaga;
