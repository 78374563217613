import { Card, Steps } from "antd";
import React from "react";
import "./styles.less";

function FilingSteps({ steps, currentStep, onCurrentStepChange }) {
  return (
    <Card className="jur-dispute-filing-steps">
      <Steps current={currentStep} onChange={onCurrentStepChange}>
        {steps.map((step, index) => (
          <Steps.Step
            title={step.title}
            key={index}
            disabled={currentStep < index}
          />
        ))}
      </Steps>
    </Card>
  );
}

export default FilingSteps;
