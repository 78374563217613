import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "defensive-acts";
const catcher = logAndThrow(tag);

export function updateDefence(disputeId, body) {
  return Api.put(`/disputes/${disputeId}/defence`, body).catch(
    catcher("update", "Failed to Submit Defence")
  );
}

export function createCounterclaim(disputeId, body) {
  return Api.post(`/disputes/${disputeId}/counter-claim`, body).catch(
    catcher("create", "Failed to Submit Counter Claim")
  );
}

export function updateCounterclaimreply(disputeId, counterClaimId, body) {
  return Api.put(
    `/disputes/${disputeId}/counter-claim/${counterClaimId}/reply`,
    body
  ).catch(catcher("update", "Failed to Submit Reply To Counter Claim"));
}

export function updateDeadline(disputeId, body) {
  return Api.put(`/disputes/${disputeId}/extend-deadline`, body).catch(
    catcher("update", "Failed to update deadline")
  );
}
