import { LeftOutlined } from "@ant-design/icons";
import { Space, Steps } from "antd";
import _ from "lodash";
import React from "react";
import "./styles.less";

const { Step } = Steps;

function JurStepsNavigation({ steps = 1, currentStep = 0, onBack }) {
  return (
    <Space>
      <span className="jur-step-navigation-current-step">{`Step ${
        currentStep + 1
      } of ${steps}`}</span>
      <span onClick={onBack}>
        <LeftOutlined
          className={`jur-step-navigation-back ${
            currentStep === 0 ? "jur-step-navigation-back-disabled" : ""
          }`}
        />
      </span>
      <Steps
        className="jur-step-navigation-pagination"
        size="small"
        progressDot
        current={currentStep}
      >
        {_.times(steps).map((a, i) => (
          <Step key={i} />
        ))}
      </Steps>
    </Space>
  );
}

export default JurStepsNavigation;
