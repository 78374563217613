import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React from "react";
import DisputeHeader from "../Common/DisputeHeader";
import Editor from "../Common/Editor";
import Translation from "../Common/Translation";
import Uploader from "../Common/Uploader";
import { handleChange } from "../DisputeFiling/helpers";

const { confirm } = Modal;

export default function CounterclaimRender({
  hubSlug,
  dispute,
  disputeId,
  extraCrumbs,
  onSubmit,
  isSubmitting,
  errors,
}) {
  return (
    <Card>
      <DisputeHeader
        dispute={dispute}
        disputeId={disputeId}
        hubSlug={hubSlug}
        extraCrumbs={extraCrumbs}
      >
        <Typography.Title level={3}>
          <Translation text="defensive_actions.counterclaim.title" />
        </Typography.Title>
        <Typography.Paragraph>
          <Translation text="defensive_actions.submit_counter_claim.description" />
        </Typography.Paragraph>
        <Formik initialValues={{}}>
          {({ values, setFieldValue }) => {
            const uploaderProps = {
              name: "counterclaimFilesUploader",
              fileCategory: "Counter Claim Statement",
              onDone: (file) => {
                const files = [file].concat(values.files);
                setFieldValue("files", files);
              },
              onRemove: (file) => {
                const files = values.files?.filter((f) => f?.uid !== file.uid);
                setFieldValue("files", files);
              },
              useDragger: true,
            };
            const handleChangeOf = handleChange(values, setFieldValue);
            const errorProps = (name) => {
              return {
                validateStatus: errors && errors[name] ? "error" : undefined,
                help: errors && errors[name],
              };
            };

            const showConfirm = () => {
              confirm({
                title: (
                  <Translation text="defensive_actions.counter_claim_confirm.text" />
                ),
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  values.files = values?.files?.filter(Boolean);
                  onSubmit(values);
                },
                okText: <Translation text="general.submit.button" />,
              });
            };

            return (
              <Form layout="vertical">
                <FormItem
                  {...errorProps("statement")}
                  required
                  name="statement"
                >
                  <Editor
                    onChange={handleChangeOf("statement")}
                    defaultValue={values.claim}
                  ></Editor>
                </FormItem>
                <FormItem {...errorProps("files")} name="files">
                  <Uploader {...uploaderProps}>
                    <Translation text="general.or_drop_files.button" />
                  </Uploader>
                </FormItem>
                <Button
                  type="primary"
                  onClick={showConfirm}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  <Translation text="general.submit.button" pad={true} />
                </Button>
              </Form>
            );
          }}
        </Formik>
      </DisputeHeader>
    </Card>
  );
}
