import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import * as actions from "../../redux/actions/responseFilingActions";
import * as selectors from "../../redux/selectors/responseFilingSelector";
import Spinner from "../Common/Spinner";
import { addDraftKey } from "../DisputeFiling/IndexHOC";
import ShowRender from "./ShowRender";

export default function () {
  const history = useHistory();
  const hubSlug = useHubSlug();
  const [disputeId, dispute] = useDispute();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const step = stepFromName(new URLSearchParams(search).get("step"));
  const currentStep = step || useSelector(selectors.selectCurrentStep) || 0;
  const isNextDisabled = useSelector(selectors.selectIsNextDisabled);
  const respondent = useSelector(selectors.selectRespondent);
  const response = useSelector(selectors.selectResponseDetails);
  const files = useSelector(selectors.selectResponseFiles);
  const userInvites = useSelector(selectors.selectInvites);
  const sod = useSelector(selectors.selectSod);

  const goBack = () => dispatch(actions.handleStepBack());

  const onClickDashboard = () => history.push("/");

  const handleSaveAndBack = () => {
    handleSaveDraft({ goBack: true });
  };

  const handleDiscardAndBack = () => {
    dispatch(actions.handleStepBack());
  };

  const next = () => {
    if (currentStep === 0) {
      dispatch(actions.putUpdateRespondent(disputeId, respondent));
    }
    if (currentStep === 1)
      dispatch(actions.putUpdateResponseDetails(disputeId, response));
    if (currentStep === 2)
      dispatch(actions.putUpdateResponseFiles(disputeId, files));
    if (currentStep === 3)
      dispatch(actions.putUpdateInvites(disputeId, { invitees: userInvites }));
  };

  const handleSaveDraft = ({ goBack = false }) => {
    if (currentStep === 0) {
      dispatch(
        actions.putUpdateRespondent(
          disputeId,
          addDraftKey(respondent),
          hubSlug,
          goBack
        )
      );
    }
    if (currentStep === 1)
      dispatch(
        actions.putUpdateResponseDetails(
          disputeId,
          addDraftKey(response),
          hubSlug,
          goBack
        )
      );
    if (currentStep === 2)
      dispatch(
        actions.putUpdateResponseFiles(
          disputeId,
          addDraftKey(files),
          hubSlug,
          goBack
        )
      );
    if (currentStep === 3)
      dispatch(
        actions.putUpdateInvites(
          disputeId,
          addDraftKey({ invitees: userInvites }),
          hubSlug,
          goBack
        )
      );
  };

  const handleCurrentStepChange = (step) => {
    dispatch(actions.handleStepChange(step));
  };

  const handleSodClick = () => dispatch(actions.putUpdateSod(disputeId, sod));

  useEffect(() => void dispatch(actions.handleStartResponseFiling()), [
    dispatch,
  ]);

  return dispute ? (
    <ShowRender
      dispute={dispute}
      disputeId={disputeId}
      currentStep={currentStep}
      next={next}
      isNextDisabled={isNextDisabled}
      goBack={goBack}
      onClickDashboard={onClickDashboard}
      hubSlug={hubSlug}
      onSodClick={handleSodClick}
      onSaveAndBack={handleSaveAndBack}
      onDiscardAndBack={handleDiscardAndBack}
      onSaveDraft={handleSaveDraft}
      onCurrentStepChange={handleCurrentStepChange}
    />
  ) : (
    <Spinner />
  );
}

function stepFromName(name) {
  if (name === "sign") return 4;
  if (name === "completed") return 5;
  return false;
}
