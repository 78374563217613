import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/arbitratorPanelActions";
import * as apis from "../apis/arbitratorPanelApi";
import * as types from "../constants/constants";

const tag = "arbitrator-panel";

export function* getIndex(action) {
  const { disputeId } = action.payload;
  try {
    const res = yield call(apis.index, disputeId);
    yield put(actions.handleIndex(res));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreate(action) {
  const { disputeId, list } = action.payload;
  try {
    const res = yield call(apis.create, disputeId, list);
    yield put(actions.handleCreate(res));
    window.location.reload();
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateStrike(action) {
  const { hubSlug, disputeId, list } = action.payload;
  try {
    yield call(apis.updateStrike, disputeId, list);
    yield put(push(`/${hubSlug}/disputes/${disputeId}`));
  } catch (e) {
    console.error(tag, "saga putUpdateStrike error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* arbitratorPanelSaga() {
  yield takeLatest(types.ARBITRATORPANEL_GET_INDEX, getIndex);
  yield takeLatest(types.ARBITRATORPANEL_POST_CREATE, postCreate);
  yield takeLatest(types.ARBITRATORPANEL_PUT_UPDATE_STRIKE, putUpdateStrike);
}
