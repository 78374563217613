import { Button, Steps, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import CenteredContent from "../Layouts/CenteredContent";
import Translation from "./Translation";

const { Step } = Steps;

function CompletedStep({ steps, current, disputeId }) {
  return (
    <div className="jur-completed-step-background">
      <CenteredContent
        contentWidth={7}
        colProps={{ xs: 16, sm: 14, md: 12, lg: 10 }}
      >
        <center className="jur-completed-step-wrapper">
          <img
            className="jur-completed-step-illustration"
            src={require("../../assets/illustrations/handshake.png")}
            alt="logo"
          />

          <Steps direction="vertical" current={current}>
            {steps.map((step) => (
              <Step
                title={
                  <Typography.Title
                    level={5}
                    className="h5-medium primary-color"
                  >
                    {step.title}
                  </Typography.Title>
                }
                description={
                  <Typography.Text className="secondary-color">
                    {step.description}
                  </Typography.Text>
                }
              />
            ))}
          </Steps>
          <Link to={`/region-us/disputes/${disputeId}`}>
            <Button type="primary" className="jur-completed-step-button">
              <Translation text="general.take_me_to_the_dispute.button" />
            </Button>
          </Link>
        </center>
      </CenteredContent>
    </div>
  );
}

export default CompletedStep;
