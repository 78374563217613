import { Alert, Col, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Radio } from "formik-antd";
import React from "react";
import {
  AddressFields,
  toFormItem,
} from "../../helpers/formHelpers";
import FormQuestion, {
  QuestionHelpText,
  QuestionTitle,
} from "../Common/FormQuestion";
import JurLink from "../Common/JurLink";
import Translation from "../Common/Translation";

export default function ({ respondent = {}, onUpdate, errors, errorProps }) {
  return (
    <Formik initialValues={respondent}>
      {({ values, setFieldValue }) => {
        const formItemProps = {
          values,
          setFieldValue,
          propogateValues: onUpdate,
          errors,
          errorProps,
        };
        const isPartyBusiness = values.entityType === "Business";
        const labelValue = isPartyBusiness ? "business" : "respondent";
        const isTypeSelected = Boolean(values.entityType);
        const respondentType = () => (
          <>
            {toFormItem(formItemProps)({
              name: "entityType",
              customLabel: (
                <FormQuestion
                  title={
                    <Translation text="claimant.is_respondent_business_or_individual.text" />
                  }
                />
              ),
              helpText: (
                <QuestionHelpText>
                  <Translation text="claimant.this_will_help_decide_cost_split.text" />
                </QuestionHelpText>
              ),
              component: Radio.Group,
              componentProps: { options: entityOptions() },
            })}
            {isTypeSelected ? (
              <Alert
                banner={true}
                message={
                  <>
                    <Translation text="claimant.responsible_for_providing.text" />{" "}
                    {isPartyBusiness ? "50%" : "95%"}
                    <Translation text="claimant.of_the_jur_fee.text" />
                    <Translation text="claimant.see_more_details.text" />{" "}
                    <JurLink>here</JurLink>{" "}
                    <Translation text="claimant.on_fee_splitting.text" />
                  </>
                }
                type="info"
                showIcon
                className="persistent-message"
              />
            ) : null}
            <br />
          </>
        );

        const respondentName = () => {
          return (
            <>
              {isPartyBusiness ? (
                toFormItem(formItemProps)({
                  name: "firstName",
                  customLabel: (
                    <FormQuestion
                      title={
                        <Translation
                          text={`claimant.complete_name_of_${labelValue}.text`}
                        />
                      }
                    />
                  ),
                })
              ) : (
                <>
                  <FormItem
                    label={
                      <FormQuestion
                        title={<Translation text="general.name.text" />}
                      />
                    }
                    name="name"
                    required={true}
                  >
                    <Row gutter={24}>
                      <Col span={12}>
                        {toFormItem({ ...formItemProps, compact: true })({
                          name: "firstName",
                          placeholder: "First Name",
                        })}
                      </Col>
                      <Col span={12}>
                        <FormItem name="lastName">
                          {toFormItem({ ...formItemProps, compact: true })({
                            name: "lastName",
                            placeholder: "Last Name",
                          })}
                        </FormItem>
                      </Col>
                    </Row>
                  </FormItem>
                </>
              )}
            </>
          );
        };

        const director = () => (
          <>
            {toFormItem(formItemProps)({
              name: "directorName",
              customLabel: (
                <FormQuestion
                  title={
                    <Translation
                      text={`claimant.director_of_respondent_business.text`}
                    />
                  }
                />
              ),
            })}
          </>
        );

        const email = () => (
          <>
            {toFormItem(formItemProps)({
              name: "email",
              customLabel: (
                <FormQuestion
                  title={
                    <Translation text={`claimant.email_of_respondent.text`} />
                  }
                />
              ),
            })}
          </>
        );

        const address = () => (
          <FormItem
            required={true}
            name="address"
            label={<QuestionTitle text={"claimant.mailing_address.text"} />}
          >
            <AddressFields {...formItemProps} />
          </FormItem>
        );

        return (
          <Form layout="vertical" className="jur-dispute-filing-form">
            {respondentType()}
            {Boolean(values?.entityType) ? (
              <>
                {respondentName()}
                {isPartyBusiness ? <>{director()}</> : null}
                {email()}
                {address()}
              </>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
}

function entityOptions() {
  return [
    { label: "Business", value: "Business" },
    { label: "Individual", value: "Individual" },
  ];
}
