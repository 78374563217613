import { push } from "connected-react-router";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  errorMessage,
  successMessage,
} from "../../components/Common/Feedback/Message";
import * as actions from "../actions/arbitralAwardActions";
import { handleUpdateSlug } from "../actions/hubActions";
import * as signActions from "../actions/signActions";
import * as timelineActions from "../actions/timelineActions";
import * as apis from "../apis/arbitralAwardApi";
import * as types from "../constants/constants";

const indexTLParams = (disputeId) => {
  return {
    activityId: disputeId,
    activityType: "Arbitral Award Index",
  };
};

const tag = "arbitral-award";

export function* getIndex(action) {
  const { disputeId, page } = action.payload;
  try {
    const res = yield call(apis.index, disputeId, page);
    yield put(actions.handleIndex(res));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShow(action) {
  const { disputeId, awardId } = action.payload;
  try {
    const res = yield call(apis.show, disputeId, awardId);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreate(action) {
  const { disputeId, body, resetForm } = action.payload;
  try {
    const res = yield call(apis.create, disputeId, body);
    yield put(actions.handleCreate(res));
    successMessage("arbitral_award.arbitral_award_submitted.text");
    yield put(timelineActions.getShow(disputeId, indexTLParams(disputeId)));
    yield put(actions.getIndex(disputeId));
    resetForm();
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdate(action) {
  const {
    disputeId,
    hubSlug,
    awardId,
    fileId,
    body,
  } = action.payload;
  try {
    const res = yield call(apis.update, disputeId, awardId, body);
    yield put(actions.handleUpdate(res));

    if (body?.status === "Sent To Parties") {
      yield put(
        signActions.postCreateSignature({
          fileIds: [fileId],
          flow: "Arbitral Award",
          signType: "Self Sign",
          envelopeRedirectUrl: `/${hubSlug}/disputes/${disputeId}/arbitral-awards`,
          uiRedirectUrl: `/${hubSlug}/disputes/${disputeId}/arbitral-awards`,
          disputeId,
          hubSlug,
          signableId: awardId,
        })
      );
    }

    yield put(timelineActions.getShow(disputeId, indexTLParams(disputeId)));
    yield put(actions.getIndex(disputeId));
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putDeadlineUpdate(action) {
  const { disputeId, body, resetForm } = action.payload;
  try {
    const res = yield call(apis.updateDeadline, disputeId, body);
    yield put(actions.handleUpdate(res));
    yield put(actions.getIndex(disputeId));
    resetForm();
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateCompleted(action) {
  const { disputeId, hubSlug } = action.payload?.params?.meta;
  try {
    successMessage("arbitral_award.updated_arbitral_award_signature.text");
    if (hubSlug && disputeId) {
      yield put(handleUpdateSlug(hubSlug));
      yield put(push(`/${hubSlug}/disputes/${disputeId}/arbitral-awards`));
    } else yield put(push(`/`));
  } catch (e) {
    console.error(tag, `saga putUpdateCompleted error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* handleSignatureError(action) {
  const { disputeId, hubSlug } = action.payload?.params;
  try {
    errorMessage("general.signature_error.text");
    if (hubSlug && disputeId) {
      yield put(handleUpdateSlug(hubSlug));
      yield put(push(`/${hubSlug}/disputes/${disputeId}/arbitral-awards`));
    } else yield put(push(`/`));
  } catch (e) {
    console.error(tag, `saga putUpdateCompleted error:`, e);
    yield put(actions.handleError(e));
  }
}

export default function* arbitralAwardSaga() {
  yield takeLatest(types.ARBITRALAWARD_GET_INDEX, getIndex);
  yield takeLatest(types.ARBITRALAWARD_GET_SHOW, getShow);
  yield takeEvery(types.ARBITRALAWARD_POST_CREATE, postCreate);
  yield takeLatest(types.ARBITRALAWARD_PUT_UPDATE, putUpdate);
  yield takeLatest(types.ARBITRALAWARD_PUT_DEADLINE_UPDATE, putDeadlineUpdate);
  yield takeLatest(
    types.ARBITRALAWARD_PUT_UPDATE_COMPLETED,
    putUpdateCompleted
  );
  yield takeLatest(
    types.ARBITRALAWARD_HANDLE_SIGNATURE_ERROR,
    handleSignatureError
  );
}
