import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRosterIndex } from "../../redux/actions/hubActions";
import {
  selectRosterIndex,
  selectRosterIndexMeta,
} from "../../redux/selectors/hubsSelector";
import Spinner from "../Common/Spinner";
import IndexRender from "./IndexRender";

export default function IndexHOC() {
  const dispatch = useDispatch();
  const rosterIndex = useSelector(selectRosterIndex);
  const rosterIndexMeta = useSelector(selectRosterIndexMeta);

  const handlePageChange = (page) => {
    dispatch(getRosterIndex(page));
  };

  useEffect(() => {
    dispatch(getRosterIndex());
  }, [dispatch]);

  return rosterIndex ? (
    <IndexRender
      list={rosterIndex}
      meta={rosterIndexMeta}
      onPageChange={handlePageChange}
    />
  ) : (
    <Spinner />
  );
}
