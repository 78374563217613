import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { canAccess, useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { breadcrumbRender } from "../../helpers/routeHelpers";
import * as actions from "../../redux/actions/disputeTorActions";
import * as disputeUserActions from "../../redux/actions/disputeUserActions";
import * as fileActions from "../../redux/actions/fileActions";
import { postCreateSignature } from "../../redux/actions/signActions";
import { SENT_TO_HUB, SENT_TO_PARTIES, HUB_APPROVED, HUB_REJECTED } from "../../redux/constants/TORStatuses";
import * as selectors from "../../redux/selectors/disputeTorSelector";
import { selectParticipants } from "../../redux/selectors/disputeUsersSelector";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import IndexRender from "./IndexRender";

export default function () {
  const hubSlug = useHubSlug();
  const dispatch = useDispatch();

  const [disputeId, dispute] = useDispute();
  const disputeParams = { disputeId, dispute, hubSlug };

  const rejectedTor = useSelector(selectors.selectDisputeTor);
  const errors = useSelector(selectors.selectError);
  const participants = useSelector(selectParticipants)?.filter((u) => canAccess(u, "view", "tor"));
  const disputeTors = useSelector(selectors.selectDisputeTors);
  const signableTors = disputeTors.data?.meta?.signableTors || [];
  const pendingTors = disputeTors.data?.data?.filter((tor) => tor.attributes?.status === SENT_TO_HUB);
  const { isReviewTermsVisible, isSendToHubVisible, isSendToPartiesVisible, isLoading } = useSelector(selectors.whole);

  const canSendTOR = useAccess("create", "tor");
  const canReviewTOR = useAccess("review", "tor");

  const [selectedTOR, setSelectedTOR] = useState(null);

  const downloadFile = (id) => dispatch(fileActions.getShow(id));
  const downloadTemplate = () => downloadFile(disputeTors?.data?.meta?.template?.id);

  const handleSendToHub = (id, clearForm) => {
    dispatch(actions.postCreate(disputeId, { fileId: id }, clearForm));
  };

  const handleReviewTerms = (body, resetForm) => {
    dispatch(actions.putUpdate(disputeId, body.torId, body, resetForm));
  };

  const handleSendToParties = (body, resetForm) =>
    void dispatch(
      postCreateSignature({
        signerIds: body.userIds,
        fileIds: [body.torFileId],
        flow: "Terms Of Reference",
        signType: "Group Sign",
        envelopeRedirectUrl: `/${hubSlug}/disputes/${disputeId}/tor`,
        uiRedirectUrl: `/${hubSlug}/disputes/${disputeId}/tor`,
        disputeId,
        hubSlug,
        signableId: body.torId,
        onEnvelopeCreated: () => {
          dispatch(
            actions.putUpdate(
              disputeId,
              body.torId,
              {
                deadline: body.deadline,
              },
              resetForm
            )
          );
        },
      })
    );

  const handleTorAction = (record) => {
    const torStatus = record?.attributes?.status;
    setSelectedTOR(record);

    if (STATUSES_AND_ACTIONS[torStatus]?.hasAccess) STATUSES_AND_ACTIONS[torStatus].action(record);
  };

  const handleActionText = (torStatus, torId) => {
    if (STATUSES_AND_ACTIONS[torStatus]?.hasAccess) {
      if (torStatus === SENT_TO_PARTIES && signableTors.find((tor) => tor.torId === torId)) {
        return <Translation text="terms_of_reference.sign.action" />;
      } else if (torStatus === HUB_REJECTED) {
        return <Translation text="terms_of_reference.see_reviews.action" />;
      } else if (torStatus === HUB_APPROVED) {
        return <Translation text="terms_of_reference.share.action" />;
      } else if (torStatus === SENT_TO_HUB) {
        return <Translation text="terms_of_reference.review.action" />;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  const getTorDetails = (tor) => tor?.id && dispatch(actions.getShow(disputeId, tor?.id));

  const openSendToHub = () => {
    dispatch(actions.handleIsSendToHubOpen(true));
  };

  const closeSendToHub = () => {
    dispatch(actions.handleIsSendToHubOpen(false));
  };

  const openReviewTerms = () => {
    dispatch(actions.handleIsReviewOpen(true));
  };

  const closeReviewTerms = () => {
    dispatch(actions.handleIsReviewOpen(false));
  };

  const openSendToParties = () => {
    dispatch(actions.handleIsSendToPartiesOpen(true));
  };

  const closeSendToParties = () => {
    dispatch(actions.handleIsSendToPartiesOpen(false));
  };

  const deleteTOR = (id) => void dispatch(fileActions.deleteFile(id));

  const signTor = (record) => {
    window.location = disputeTors?.data?.meta?.signableTors?.find((x) => x.torId === record?.id)?.redirectUrl;
  };

  const STATUSES_AND_ACTIONS = {
    [SENT_TO_HUB]: {
      hasAccess: canReviewTOR,
      action: openReviewTerms,
    },
    [HUB_APPROVED]: {
      hasAccess: canSendTOR,
      action: openSendToParties,
    },
    [HUB_REJECTED]: {
      hasAccess: canSendTOR,
      action: getTorDetails,
    },
    [SENT_TO_PARTIES]: {
      hasAccess: !canSendTOR && !canReviewTOR,
      action: signTor,
    },
  };

  useEffect(() => void dispatch(disputeUserActions.getIndex(disputeId)), [disputeId, dispatch]);

  useEffect(() => {
    dispatch(actions.getIndex(disputeId));
  }, [disputeId, dispatch]);

  const handlePageChange = (page) => {
    dispatch(actions.getIndex(disputeId, page));
  };

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/tor`,
      breadcrumbName: <Translation text="terms_of_reference.terms_of_reference.title" />,
    },
  ];

  return dispute ? (
    <IndexRender
      hubSlug={hubSlug}
      disputeId={disputeId}
      dispute={dispute}
      disputeParams={disputeParams}
      extraCrumbs={extraCrumbs}
      participants={participants}
      disputeTors={disputeTors}
      rejectedTor={rejectedTor}
      selectedTOR={selectedTOR}
      errors={errors}
      downloadTemplate={downloadTemplate}
      downloadFile={downloadFile}
      openSendToHub={openSendToHub}
      closeSendToHub={closeSendToHub}
      isSendToHubVisible={isSendToHubVisible}
      handleSendToHub={handleSendToHub}
      canSendTOR={canSendTOR}
      canReviewTOR={canReviewTOR}
      isReviewTermsVisible={isReviewTermsVisible}
      openReviewTerms={openReviewTerms}
      closeReviewTerms={closeReviewTerms}
      handleReviewTerms={handleReviewTerms}
      handleTorAction={handleTorAction}
      handleActionText={handleActionText}
      isSendToPartiesVisible={isSendToPartiesVisible}
      openSendToParties={openSendToParties}
      closeSendToParties={closeSendToParties}
      handleSendToParties={handleSendToParties}
      breadcrumb={crumbs(hubSlug, disputeId, dispute)}
      isLoading={isLoading}
      pendingTors={pendingTors}
      onPageChange={handlePageChange}
      deleteTOR={deleteTOR}
    />
  ) : (
    <Spinner card={false} />
  );
}

const crumbs = (hubSlug, disputeId, dispute) => {
  return {
    routes: [
      {
        path: `/`,
        breadcrumbName: "Dashboard",
      },
      {
        path: `/${hubSlug}/disputes/${disputeId}`,
        breadcrumbName: dispute?.name,
      },
      {
        path: `/${hubSlug}/disputes/${disputeId}/tor`,
        breadcrumbName: "Terms of Reference",
      },
    ],
    itemRender: breadcrumbRender,
  };
};
