import { Typography } from "antd";
import React from "react";
import { toEpoch } from "../../../../helpers/dateHelpers";
import DateTime from "../../../Common/DateTime";
import Translation from "../../../Common/Translation";
import Footnote from "../../../Typography/Footnote";

const { Title, Text } = Typography;

export default function LastUpdate({ condition, assistedDispute }) {
  if (!condition || !assistedDispute?.lastUpdate) return null;
  const { title, description, created_at } = assistedDispute?.lastUpdate;

  return (
    <div>
      <div>
        <Title className="primary-color" level={4}>
          <Translation text="assisted_dispute.last_update.text" />
        </Title>
      </div>
      <div className="jur-dispute-show-last-updates-content">
        <Footnote strong>
          {<DateTime epochSeconds={toEpoch(created_at)} />}
        </Footnote>
        <Title level={5} strong>
          {title}
        </Title>
        <Text className="secondary-color">{description}</Text>
      </div>
    </div>
  );
}
