import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "dispute-users (participants)";
const catcher = logAndThrow(tag);

export function index(disputeId) {
  return Api.get(`/disputes/${disputeId}/participants`)
    .then((r) => r.data)
    .catch(catcher("index"));
}

export function show(disputeId, disputeUserId) {
  return Api.get(
    `/disputes/${disputeId}/participants/dispute-users/${disputeUserId}`
  )
    .then((r) => r.data)
    .catch(catcher("show"));
}

export function showHubUser(disputeId, hubUserId) {
  return Api.get(`/disputes/${disputeId}/participants/hub-users/${hubUserId}`)
    .then((r) => r.data)
    .catch(catcher("showHubUser"));
}

/** Not being used */
export function create(disputeId, body) {
  return Api.post(`/disputes/${disputeId}/dispute-users`, body).catch(
    catcher("create")
  );
}

/** Not being used */
export function update(disputeId, disputeUserId, body) {
  return Api.put(
    `/disputes/${disputeId}/dispute-users/${disputeUserId}`,
    body
  ).catch(catcher("update"));
}

export function updateKyc(disputeId, body) {
  return Api.put(
    `/disputes/${disputeId}/participants/dispute-users`,
    body
  ).catch(catcher("updateKyc", "Failed to update profile"));
}

export function updateStatus(disputeId, disputeUserId, body) {
  return Api.put(
    `/disputes/${disputeId}/participants/${disputeUserId}/kyc`,
    body
  ).catch(catcher("updateStatus", "Failed to update KYC status"));
}
