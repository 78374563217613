import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table } from "antd";
import React from "react";
import { toEpoch } from "../../../helpers/dateHelpers";
import DateTime from "../../Common/DateTime";
import Translation from "../../Common/Translation";

export default function PhaseListTable({
  phases,
  onUpdatePhases,
  setIsAddItemVisible,
  resetForm,
  onDelete,
}) {
  const onRowSelect = (selectedRowKeys, selectedRows) => {
    const updatedPhases = phases.map((p, i) => ({
      ...p,
      isCurrentPhase: i === selectedRowKeys[0],
    }));
    onUpdatePhases(updatedPhases);
  };

  const rowSelection = {
    columnTitle: "Current Phase?",
    type: "radio",
    selectedRowKeys: getSelectedRowKey(phases),
    onChange: onRowSelect,
  };

  return (
    <div>
      <Table
        pagination={false}
        columns={columns(setIsAddItemVisible, resetForm, onDelete)}
        rowKey="index"
        dataSource={phases?.filter((p) => !p.isDeleted)}
        rowSelection={rowSelection}
      />
    </div>
  );
}

const getSelectedRowKey = (phases) => {
  return (phases ?? [])?.length === 0
    ? []
    : [(phases ?? []).findIndex((p) => p.isCurrentPhase)];
};

function columns(setIsAddItemVisible, resetForm, onDelete) {
  return [
    { label: "Name", dataIndex: "stateName" },
    {
      label: "Description",
      dataIndex: "stateDescription",
    },
    {
      label: "Deadline",
      dataIndex: "deadline",
      render: (d) => <DateTime epochSeconds={toEpoch(d)} />,
    },
    {
      label: "Actions",
      dataIndex: "actions",
      render: (_, row) => {
        return (
          <>
            <Button
              key="edit"
              icon={
                <EditOutlined
                  onClick={() => {
                    resetForm({ values: row });
                    setIsAddItemVisible(true);
                  }}
                />
              }
            />
            <Popconfirm
              key="delete"
              onConfirm={() => onDelete(row.index)}
              title="Are you sure you want ot delete this phase?"
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        );
      },
    },
  ].map((col) => ({ ...col, title: <Translation text={col.label} /> }));
}
