import React, { useEffect } from "react";
import { NamespacesConsumer } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getInviteeErrors } from "../../helpers/formHelpers";
import {
  getShowInvites,
  handleUpdateInvitesForm,
} from "../../redux/actions/disputeFilingActions";
import {
  selectDisputeId,
  selectErrors,
  selectInvites,
  selectIsErrorHidden,
} from "../../redux/selectors/disputeFilingSelector";
import Spinner from "../Common/Spinner";
import InviteRender from "./InviteRender";

export default function InviteHOC(props) {
  const dispatch = useDispatch();
  const disputeId = useSelector(selectDisputeId);
  const invites = useSelector(selectInvites);
  const errors = useSelector(selectErrors);
  const inviteeErrors = getInviteeErrors(errors);
  const isErrorHidden = useSelector(selectIsErrorHidden);

  const addInviteItem = () =>
    dispatch(handleUpdateInvitesForm(addOne(invites)));

  const handleUpdateInvites = (index) => (values) => {
    const resultant = [...invites];
    resultant[index] = values;
    dispatch(handleUpdateInvitesForm(resultant));
  };

  const removeInviteItem = (index) => () => {
    const updatedInvites = [...(invites || [])];
    updatedInvites.splice(index, 1);
    dispatch(handleUpdateInvitesForm(updatedInvites));
  };

  useEffect(() => void dispatch(getShowInvites(disputeId)), [
    disputeId,
    dispatch,
  ]);

  return invites ? (
    <NamespacesConsumer>
      {(t) => (
        <InviteRender
          onAddInviteItem={addInviteItem}
          invites={invites}
          onUpdateInvitesForm={handleUpdateInvites}
          onRemoveInviteItem={removeInviteItem}
          errors={inviteeErrors}
          errorProps={{ isHidden: isErrorHidden }}
          t={t}
        />
      )}
    </NamespacesConsumer>
  ) : (
    <Spinner card={true} />
  );
}

function addOne(array) {
  const maxCuid = Math.max(...array.map((i) => i.cuid));
  return [].concat(array).concat({
    cuid: maxCuid * 2 + 1,
  });
}
