import { Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getShow } from "../../redux/actions/fileActions";

export default function FileOpener({
  fileId,
  downloadUrl,
  children,
  link = false,
}) {
  const dispatch = useDispatch();

  const openFile = () => {
    if (downloadUrl) {
      window.open(downloadUrl, "_blank");
    } else {
      dispatch(getShow(fileId));
    }
  };

  let body = children;

  if (link) {
    body = <Typography.Link onClick={openFile}>{children}</Typography.Link>;
    return body;
  }

  return (
    <div className="jur-pointer" onClick={openFile}>
      {body}
    </div>
  );
}
