import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";
import "./styles.less";

function JurPointer({ label, success = true }) {
  return (
    <div className="jur-pointer-wrapper">
      <CheckCircleOutlined className="jur-pointer-icon" />
      <div className="jur-pointer-text">{label}</div>
    </div>
  );
}

export default JurPointer;
