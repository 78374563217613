import { Card, Typography } from "antd";
import React from "react";
import DisputeHeader from "../Common/DisputeHeader";
import PDFRender from "../Common/PDFRender";
import TimelineWrapper from "../Common/TimelineWrapper";
import Translation from "../Common/Translation";

export default function ({
  dispute,
  disputeId,
  hubSlug,
  extraCrumbs,
  fileId,
  fileName,
  memorandumId,
}) {
  return (
    <TimelineWrapper
      activityType="Hearing Memorandum Show"
      activityId={memorandumId}
    >
      <Card>
        <DisputeHeader
          dispute={dispute}
          disputeId={disputeId}
          hubSlug={hubSlug}
          extraCrumbs={extraCrumbs}
        >
          <Typography.Title level={3}>
            <Translation text="hearings.memorandum.title" />
          </Typography.Title>
          <Card
            type="inner"
            title={
              <Typography.Title level={5} strong>
                {fileName}
              </Typography.Title>
            }
          >
            <PDFRender fileId={fileId} />
          </Card>
        </DisputeHeader>
      </Card>
    </TimelineWrapper>
  );
}
