import React, { useState, useEffect } from "react";
import { Button, Modal, Select } from "antd";
import { Formik } from "formik";
import { Checkbox, DatePicker, Form, Input, Table, FormItem } from "formik-antd";
import { useDispatch, useSelector } from "react-redux";
import { useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useErrorHider } from "../../helpers/errorHelpers";
import { modalProps } from "../../helpers/modalHelpers";
import { useParticipants } from "../../helpers/participantHelpers";
import { postCreate } from "../../redux/actions/hearingActions";
import { selectErrors, whole } from "../../redux/selectors/hearingsSelector";
import Translation from "../Common/Translation";
import { datePickerProps } from "../../helpers/dateHelpers";
import { errorAsExtra } from "../../helpers/errorHelpers";
import Editor from "../Common/Editor/Editor";
import UserRole from "../Common/UserRole";
import UserRoleSelect from "../Common/UserRoleSelect";
import { handleChange, toFormItem } from "../DisputeFiling/helpers";
import hearingValidate from "./hearingValidate";
import participantValidate from "./participantValidate";

export default function ({ visible, setVisible }) {
  const [postCreateFetch, setPostCreateFetch] = useState(false);
  const [finishCreatePostFetch, setFinishCreatePostFetch] = useState(false);
  const errorProps = useErrorHider();
  const errors = useSelector(selectErrors);
  const apiErrorOf = errorAsExtra(errors, errorProps?.isHidden);
  const canCreate = useAccess("create", "hearing");
  const [disputeId, dispute] = useDispute();
  const [participants] = useParticipants();
  const { isLoading } = useSelector(whole);

  useEffect(() => {
    if (!isLoading) {
      if (finishCreatePostFetch && Object.keys(errors).length === 0) {
        setVisible(false);
        setFinishCreatePostFetch(false);
      } else if (finishCreatePostFetch && Object.keys(errors).length !== 0) setFinishCreatePostFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, finishCreatePostFetch]);

  useEffect(() => {
    if (postCreateFetch && !isLoading) {
      setFinishCreatePostFetch(true);
      setPostCreateFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCreateFetch]);

  const dispatch = useDispatch();

  const handleSchedule = (values) => {
    setPostCreateFetch(true);
    dispatch(postCreate(disputeId, values));
  };

  return dispute && canCreate && participants?.length ? (
    <Formik
      onSubmit={errorProps?.callAndUnhide(handleSchedule)}
      initialValues={{ participants }}
      validationSchema={hearingValidate}
    >
      {({ submitForm, values, setFieldValue }) => {
        const handleChangeOf = handleChange(values, setFieldValue);
        return (
          <Modal
            centered
            width={946}
            visible={visible}
            maskClosable={false}
            {...modalProps()}
            onCancel={() => setVisible(false)}
            title={<Translation text="hearings.schedule_meeting_title.button" />}
            footer={[
              <Button onClick={submitForm} type="primary" name="submit" loading={isLoading}>
                <Translation text="hearings.schedule.form_button" pad={true} />
              </Button>,
            ]}
          >
            <Form layout="vertical" className="jur-hearing-form-wrapper">
              {items(errors, errorProps, handleChangeOf)}
              <FormItem
                label={<Translation text="hearings.duration_in_minutes.column" />}
                name="duration"
                {...apiErrorOf("duration", true)}
              >
                <Select
                  placeholder="Select duration"
                  name="duration"
                  onChange={(min) => handleChangeOf("duration")(min)}
                >
                  {Array.from({ length: 12 }, (_, i) => (i + 1) * 5).map((min, index) => (
                    <Select.Option key={index} value={min}>
                      {min}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                name="participants"
                label={<Translation text="hearings.participants.text" />}
                {...apiErrorOf("participants", true)}
              >
                <Table
                  name="userIds"
                  columns={columns(values.participants, setFieldValue)}
                  rowKey="userId"
                  dataSource={values.participants}
                  pagination={false}
                />
              </FormItem>
              <AddNewParticipant onAdd={handleAdd(setFieldValue, values.participants?.length)} />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  ) : null;
}

function columns(invited, setFieldValue) {
  return [
    {
      title: <Translation text="hearings.name.column" />,
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: <Translation text="general.role.column" />,
      dataIndex: "role",
      key: "role",
      render: (_, record) => <UserRole role={record?.role} group={record?.userGroup} />,
    },
    {
      title: <Translation text="hearings.email.column" />,
      dataIndex: "email",
      key: "email",
      render: (text) => <div className="jur-hearings-email-column">{text}</div>,
    },
    {
      title: <Translation text="hearings.invitations.column" />,
      align: "center",
      dataIndex: "email",
      render: (_, __, index) => (
        <Checkbox name={`participants.${index}.isInvited`} onChange={handleCheck(setFieldValue, index)}>
          <span className="jur-checkbox-text">Invite</span>
        </Checkbox>
      ),
    },
  ];
}

function items(errors, errorProps, handleChangeOf) {
  return [
    { name: "title", label: "hearings.title.column" },
    {
      name: "agenda",
      label: "hearings.agenda.label",
      component: Editor,
      componentProps: {
        onChange: handleChangeOf("agenda"),
        className: "jur-quill-editor-small",
      },
    },
    {
      name: "scheduledAt",
      label: "hearings.date.label",
      component: DatePicker,
      componentProps: { ...datePickerProps({ showTime: true }) },
    },
  ].map(toFormItem({ errors, errorProps }));
}

function AddNewParticipant({ onAdd }) {
  return (
    <Formik initialValues={{}} onSubmit={onAdd} validationSchema={participantValidate}>
      {({ submitForm, values }) => (
        <Form layout="inline" className="jur-add-new-participant-form">
          <Form.Item name="fullName" label={<Translation text="hearings.schedule.column.full_name" />}>
            <Input name="fullName" maxLength="178" />
          </Form.Item>
          <Form.Item name="email" label={<Translation text="general.email.text" />}>
            <Input name="email" type="email" />
          </Form.Item>
          <Form.Item name="role" label={<Translation text="general.role.text" />}>
            <UserRoleSelect />
          </Form.Item>
          <Form.Item name="add-action" label="&nbsp;">
            <Button onClick={submitForm} disabled={!isValid(values)}>
              + <Translation text="hearings.add_new_participant.button" />
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}

function isValid({ fullName, email, role }) {
  return [fullName, email, role].every(Boolean);
}

function handleCheck(setFieldValue, index) {
  return function ({ target }) {
    const { checked } = target;
    setFieldValue(`participants.${index}.isInvited`, checked);
    if (!checked) setFieldValue(`participants.${index}.allowMemorandum`, false);
  };
}

function handleAdd(setFieldValue, index) {
  return function (values, { resetForm }) {
    setFieldValue(`participants.${index}`, values);
    resetForm({});
  };
}
