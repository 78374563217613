import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "response-filing";
const catcher = logAndThrow(tag);

export function create(body) {
  return Api.post(`/response-filing`, body).catch(
    catcher("create", "Failed to save response")
  );
}

function showOf(crumb) {
  return function (disputeId) {
    return Api.get(`/disputes/${disputeId}/response-filing/${crumb}`)
      .then((res) => res.data)
      .catch(catcher(`${crumb} via show maker`));
  };
}

function updateOf(crumb) {
  return function (disputeId, body) {
    const url = `/disputes/${disputeId}/response-filing/${
      crumb === "kyc" ? "" : crumb
    }`;

    return Api.put(url, body).catch(
      catcher(
        `${crumb} via update maker`,
        "Failed to save. Please check form inputs carefully"
      )
    );
  };
}

export const showDefenceTeam = showOf("defence-team");
export const updateDefenceTeam = updateOf("defence-team");

export const showKyc = showOf("kyc");
export const updateKyc = updateOf("kyc");

export const showRespondent = showOf("respondent");
export const updateRespondent = updateOf("respondent");

export const showResponseDetails = showOf("response-details");
export const updateResponseDetails = updateOf("response-details");

export const showResponseFiles = showOf("response-files");
export const updateResponseFiles = updateOf("response-files");

export const showInvites = showOf("invite");
export const updateInvites = updateOf("invite");

export const showPayment = showOf("payment");
export const updatePayment = updateOf("payment");

export const showSod = showOf("review");

export const updateCompleted = updateOf("completed");
