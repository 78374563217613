import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Select } from "formik-antd";
import React from "react";
import { disputeRoleSelectOptions } from "../../helpers/disputeHelpers";
import { errorAsExtra } from "../../helpers/errorHelpers";
import { toFormItem } from "../../helpers/formHelpers";
import { QuestionTitle } from "../Common/FormQuestion";
import Translation from "../Common/Translation";

export default function InviteRender({
  invites = [],
  t,
  errors = {},
  errorProps = {},
  onAddInviteItem,
  onRemoveInviteItem = () => {},
  onUpdateInvitesForm = () => {},
}) {
  return (
    <>
      <div className="jur-invite-user-list-heading">
        <QuestionTitle text="claimant.invite_your_team.text" />
      </div>
      <Button
        onClick={onAddInviteItem}
        icon={<PlusOutlined />}
        className="primary-color"
      >
        <Translation pad={true} text="claimant.add_new_invite.button" />
      </Button>
      {invites.map((invite, index) => (
        <Formik initialValues={invite}>
          {({ values, setFieldValue }) => (
            <div>
              <Form layout="inline">
                {inviteItems({
                  t,
                  propogateValues: onUpdateInvitesForm(index),
                  values,
                  setFieldValue,
                  onRemove: onRemoveInviteItem(index),
                  errors: errors[index],
                  errorProps,
                  index,
                  count: invites.length,
                })}
              </Form>
            </div>
          )}
        </Formik>
      ))}
    </>
  );
}

export function inviteItems({
  t,
  propogateValues,
  values,
  setFieldValue,
  onRemove,
  errors,
  errorProps,
  index,
  count,
}) {
  const { isHidden } = errorProps;
  const apiErrorOf = errorAsExtra(errors, isHidden);
  function handleSelect(value) {
    const name = "role";
    setFieldValue(name, value);
    propogateValues({ ...values, [name]: value }, name, value);
  }

  return (
    <Row
      justify="space-between"
      className={`jur-invite-user-list-item ${
        count === index + 1 ? "jur-invite-user-list-last-item" : ""
      }`}
    >
      <div className="jur-invite-user-list-item-label primary-color">
        <Typography.Text>
          <Translation text="general.member.text" /> {index + 1}
        </Typography.Text>
      </div>
      <Col span={22}>
        <Row gutter={[16, 16]}>
          {[
            {
              name: "email",
              placeholder: t("general.email.text"),
              type: "email",
            },
          ]
            .map(
              toFormItem({
                values,
                setFieldValue,
                propogateValues,
                errors,
                errorProps,
                compact: true,
              })
            )
            .concat(
              [
                <FormItem key="last" name="role" {...apiErrorOf("role", true)}>
                  <Select
                    name="role"
                    placeholder={t("general.choose_role.text")}
                    onSelect={handleSelect}
                  >
                    {disputeRoleSelectOptions(t)}
                  </Select>
                </FormItem>,
              ].filter(Boolean)
            )
            .map((f) => (
              <Col span={12}>{f}</Col>
            ))}
        </Row>
      </Col>
      <Col span={2} className="jur-invite-user-list-delete-icon">
        <Button onClick={onRemove} type="text" key="remove-button">
          <DeleteOutlined />
        </Button>
      </Col>
    </Row>
  );
}
