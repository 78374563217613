export const locale = () => undefined;

export function envBaseAppUrl(fallback = "http://localhost:8000/api") {
  return process.env.REACT_APP_BASE_API_URL || fallback;
}

export function baseAPIUrl(slug) {
  const url = envBaseAppUrl();
  return url + (slug ? `/${slug}` : "");
}

export function appEnv() {
  return process.env.NODE_ENV || "testing";
}

export const dateOptions = (showWeekday = false) => ({
  weekday: showWeekday ? "short" : undefined,
  year: "numeric",
  month: "short",
  day: "numeric",
});

export const timeOptions = () => ({
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
});

export const datetimeOptions = (showWeekday = false) => ({
  weekday: showWeekday ? "short" : undefined,
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  timeZoneName: "short",
});
