import { Col, Row, Space } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { putUpdateAccept } from "../../redux/actions/disputeActions";
import showConfirmModal from "../Common/confirmModal";
import Translation from "../Common/Translation";
import { AcceptButton } from "../DisputeAssignment/AcceptRender";
import Reject from "./RejectForm";

function AcceptRejectDispute({ disputeId, status }) {
  const dispatch = useDispatch();

  const onAccept = () => {
    showConfirmModal({
      label: "dispute_assignment.accept_dispute_confirmation.text",
      onConfirm: () => dispatch(putUpdateAccept(disputeId, status)),
    });
  };
  return (
    <Col span={24}>
      <Row
        justify="space-between"
        className="jur-dispute-invite-defence-wrapper"
      >
        <Col>
          <img
            className="jur-dispute-invite-defence-badge"
            src={require("../../assets/illustrations/badge.png")}
            alt="badge"
          />
          <Translation text="response_filing.response_to_request_for_arbitration.text" />
        </Col>
        <Col>
          <Space>
            <AcceptButton onClick={onAccept} />
            <Reject
              disputeId={disputeId}
              status={status}
              isAssignment={false}
            />
          </Space>
        </Col>
      </Row>
    </Col>
  );
}

export default AcceptRejectDispute;
