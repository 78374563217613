import { Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getShow } from "../redux/actions/disputeActions";
import {
  ATTORNEY,
  LEGAL_REPRESENTATIVE,
  TEAM_MEMBER,
} from "../redux/constants/UserRoleConstants";
import { current } from "../redux/selectors/disputesSelector";

export function useDispute({ id, noWarning = false } = {}) {
  const { disputeId } = useParams();
  const effectiveDisputeId = id || disputeId;

  if (!effectiveDisputeId && !noWarning) {
    console.warn("useDispute couldn't get effective dispute id");
  }

  const dispute = useSelector(current);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dispute) dispatch(getShow(disputeId));
  }, [dispatch, disputeId, dispute]);

  return [effectiveDisputeId, dispute];
}

export const disputeRoleSelectOptions = (t) => (
  <>
    <Select.Option value={ATTORNEY}>{t("general.attorney.text")}</Select.Option>
    <Select.Option value={TEAM_MEMBER}>
      {t("general.team_member.text")}
    </Select.Option>
    <Select.Option value={LEGAL_REPRESENTATIVE}>
      {t("general.legal_representative.text")}
    </Select.Option>
    {/* <Select.Option value={GUEST}>{t("general.witness.text")}</Select.Option> */}
  </>
);
