import * as types from "../constants/constants";

export const getIndex = (disputeId, page = 1,group = null) => ({
  type: types.DISPUTEFILES_GET_INDEX,
  payload: { disputeId, page,group },
});

export const handleIndex = (disputeFiles) => ({
  type: types.DISPUTEFILES_HANDLE_INDEX,
  payload: disputeFiles,
});

export const handleError = (e) => ({
  type: types.DISPUTEFILES_HANDLE_ERROR,
  error: e,
});

export const postCreate = (disputeId, fileId, fileCategory, body, currentPage) => ({
  type: types.DISPUTEFILES_POST_CREATE,
  payload: { disputeId, fileId, fileCategory, body, currentPage },
});

export const putUpdateCompleted = (disputeId, params) => ({
  type: types.DISPUTEFILES_PUT_UPDATE_COMPLETED,
  payload: { disputeId, params },
});
