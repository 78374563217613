import { Col, Row } from "antd";
import React from "react";
import DiscoverJustice from "../Illustrations/DiscoverJustice";
import "./layouts.less";

export default function LeftSidebarLayout({
  sidebarContent = <DiscoverJustice />,
  mainContent,
  navbar = false,
}) {
  return (
    <Row
      className={navbar ? "layout-wrapper" : "layout-wrapper-without-navbar"}
    >
      <Col className="sidebar-content" span={7}>
        {sidebarContent}
      </Col>
      <Col className="main-content" span={17}>
        {mainContent}
      </Col>
    </Row>
  );
}
