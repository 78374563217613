import { Button } from "antd";
import React from "react";
import Translation from "../components/Common/Translation";

export const CloseIcon = () => (
  <>
    <Button type="link">
      <Translation text="general.close.button" />
    </Button>
  </>
);

export function modalProps() {
  return { closeIcon: <CloseIcon />, destroyOnClose: true };
}
