import { Button, Col, Row, Space, Typography } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { Formik } from "formik";
import { Checkbox, DatePicker, Form } from "formik-antd";
import React, { useRef } from "react";
import { dateFormat } from "../../../helpers/dateHelpers";
import { ErrorDisplay } from "../../../helpers/errorHelpers";
import { toKyc } from "../../../transformers/formTransformer";
import { toFormItem } from "../../DisputeFiling/helpers";
import Translation from "../Translation";
import { toUploadFormItem } from "../Uploader";

export default function ({
  kyc,
  errors,
  onKycUpdate,
  prefix,
  uploadInfo,
  onSubmit,
  errorProps,
  titleLevel = 5,
  role = "Attorney",
  title = "claimant.attorney_kyc.subtitle",
}) {
  const kycRef = useRef();

  return (
    <div ref={kycRef}>
      <Typography.Title level={titleLevel}>
        <Translation text={title} />
      </Typography.Title>
      <Formik initialValues={toKyc(kyc)} onSubmit={onSubmit}>
        {({ values, setFieldValue, submitForm }) => {
          const toggle = (e) => {
            if (onKycUpdate)
              onKycUpdate({
                ...values,
                isLawFirm: Boolean(e.target.checked),
              });

            if (e.target.checked) scrollToLawFirmForm(kycRef);
          };

          return (
            <Form layout="vertical">
              <Space direction="vertical">
                <Row gutter={8}>
                  {kycItems({
                    propogateValues: onKycUpdate,
                    values,
                    setFieldValue,
                    errors,
                    errorProps,
                    role,
                  })}
                </Row>

                <Typography.Title level={5}>
                  <Translation text="general.upload_files.text" />
                </Typography.Title>
                <ErrorDisplay
                  errors={errors}
                  name="files"
                  isHidden={errorProps.isHidden}
                />
                {KycfileItems({
                  propogateValues: onKycUpdate,
                  values,
                  setFieldValue,
                  uploadInfo,
                  prefix,
                  errors,
                  errorProps,
                })}

                {role === "Attorney" ? (
                  <>
                    <FormItem name="isLawFirm">
                      <Checkbox onChange={toggle} name="isLawFirm">
                        <Translation text="claimant.are_you_part_of_law_firm.text" />
                      </Checkbox>
                    </FormItem>

                    {values.isLawFirm ? (
                      <>
                        <Row gutter={8}>
                          {businessItems({
                            propogateValues: onKycUpdate,
                            values,
                            setFieldValue,
                            errors,
                            errorProps,
                          })}
                        </Row>
                        <Typography.Title level={5}>
                          <Translation text="general.upload_files.text" />
                        </Typography.Title>
                        {businessFileItems(
                          onKycUpdate,
                          values,
                          setFieldValue,
                          uploadInfo,
                          prefix
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}

                {onSubmit ? (
                  <Button onClick={submitForm} type="primary">
                    <Translation text="general.save_profile.button" />
                  </Button>
                ) : null}
              </Space>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

function businessItems({
  propogateValues,
  values,
  setFieldValue,
  errors,
  errorProps,
}) {
  return [
    { name: "lawFirmName", label: "general.firm_name.text" },
    { name: "lawFirmContact", label: "general.phone_or_email.text" },
    { name: "lawFirmAddress", label: "general.address.text" },
    { name: "lawFirmAddress2", label: "general.address2.text", optional: true },
    { name: "lawFirmState", label: "general.state.text" },
    { name: "lawFirmCity", label: "general.city.text" },
    { name: "lawFirmCountry", label: "general.country.text" },
  ]
    .map(
      toFormItem({ propogateValues, values, setFieldValue, errors, errorProps })
    )
    .map((item, index) => (
      <Col span={12} key={index}>
        {item}
      </Col>
    ));
}

function kycItems({
  propogateValues,
  values,
  setFieldValue,
  errors,
  errorProps,
  role,
}) {
  const isAttorney = role === "Attorney";
  const isLR = role === "Legal Representative";
  const isCompany = Boolean(values.isCompany);

  return [
    !isCompany && { name: "firstName", label: "general.first_name.text" },
    !isCompany && { name: "lastName", label: "general.last_name.text" },
    (isLR || isCompany) && {
      name: "companyName",
      label: "general.company_name.text",
    },
    isCompany && {
      name: "vat",
      label: "general.vat_id.text",
    },
    isLR && {
      name: "roleInCompany",
      label: "general.role_in_company.text",
    },
    isAttorney && {
      name: "barMembershipTitle",
      label: "general.bar_association.text",
    },
    isAttorney && {
      name: "barMembershipPlace",
      label: "general.bar_association_place.text",
    }, // NOTE In designs there is "No." insted of "Place"
    isAttorney && {
      name: "barMembershipDate",
      label: "general.bar_association_date.text",
      component: DatePicker,
      componentProps: {
        format: dateFormat,
        disabledDate: (current) => {
          return current && current > new Date();
        },
      },
    },
    { name: "phone", label: "general.phone.text" },
    { name: "address", label: "general.address.text" },
    { name: "address2", label: "general.address2.text", optional: true },
    { name: "city", label: "general.city.text" },
    { name: "state", label: "general.state.text" },
    { name: "zipCode", label: "general.zip.text" },
    { name: "country", label: "general.country.text" },
  ]
    .filter(Boolean)
    .map(
      toFormItem({ propogateValues, values, setFieldValue, errors, errorProps })
    )
    .map((item, index) => (
      <Col span={12} key={index}>
        {item}
      </Col>
    ));
}

function KycfileItems({
  propogateValues,
  values,
  setFieldValue,
  uploadInfo,
  prefix,
  errors,
  errorProps,
}) {
  return [
    {
      name: "files.0",
      index: 0,
      fileCategory: "Identity Proof",
      label: "general.identity_proof.text",
      tooltip: "form.identity_proof.description",
    },
    {
      name: "files.1",
      index: 1,
      fileCategory: "Address Proof",
      label: "general.address_proof.text",
      tooltip: "form.address_proof.description",
    },
    {
      name: "files.2",
      index: 2,
      fileCategory: "Recent Photograph",
      label: "general.recent_photograph.text",
      tooltip: "form.recent_photograph.description",
    },
  ].map(
    toUploadFormItem({
      propogateValues,
      values,
      setFieldValue,
      uploadInfo,
      prefix,
      errors,
      errorProps,
    })
  );
}

function businessFileItems(
  propogateValues,
  values,
  setFieldValue,
  uploadInfo,
  prefix
) {
  return [
    {
      name: "files.3",
      index: 3,
      fileCategory: "Certificate Of Incorporation",
      label: "general.certificate_of_incorporation.text",
    },
    {
      name: "files.4",
      index: 4,
      fileCategory: "Company Address Proof",
      label: "general.company_address_proof.text",
    },
  ].map(
    toUploadFormItem({
      propogateValues,
      values,
      setFieldValue,
      uploadInfo,
      prefix,
    })
  );
}

function scrollToLawFirmForm(kycRef) {
  setTimeout(() => {
    kycRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, 200);
}
