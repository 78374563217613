import React from "react";
import { Link } from "react-router-dom";

function JurLink({ onClick, to, href, children }) {
  const body = <span className="jur-link">{children}</span>;

  if (Boolean(onClick)) return <div onClick={onClick}>{body}</div>;

  if (Boolean(to)) return <Link to={to}>{body}</Link>;

  return (
    <span
      onClick={() => {
        window.open(href, "_blank");
      }}
    >
      {body}
    </span>
  );
}

export default JurLink;
