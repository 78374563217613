import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Table, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Formik } from "formik";
import { DatePicker, Form, Input } from "formik-antd";
import React, { useState } from "react";
import { datePickerProps, toEpoch } from "../../../helpers/dateHelpers";
import { toFormItem } from "../../../helpers/formHelpers";
import DateTime from "../../Common/DateTime";
import Translation from "../../Common/Translation";

const { TextArea } = Input;

function TaskTable({ tasks, onUpdateTasks, onSave, isModified }) {
  tasks = tasks?.map((p, i) => ({ ...p, index: i }));

  const [showForm, setShowForm] = useState();

  const onAddTask = (newTask) => {
    if (typeof newTask?.index === "number") {
      tasks[newTask?.index] = newTask;
      onUpdateTasks(tasks);
    } else onUpdateTasks(tasks?.concat(newTask));
    setShowForm(false);
  };

  const onDelete = (index) => {
    if (tasks[index]?.id) {
      tasks[index] = { ...tasks[index], isDeleted: true };
    } else {
      tasks = tasks.filter((x, i) => index !== i);
    }
    onUpdateTasks(tasks);
  };

  return (
    <Formik initialValues={{}}>
      {({ values, setFieldValue, resetForm }) => {
        const onClick = () => {
          onAddTask(values);
          resetForm({ values: {} });
        };

        const closeForm = () => {
          setShowForm(false);
          resetForm({ values: {} });
        };

        const buttonLabel = typeof values?.index === "number" ? "Update Task" : "Add Task";

        return (
          <>
            <Typography.Title level={5}>Tasks</Typography.Title>
            <br />
            <Table
              dataSource={tasks?.filter((p) => !p.isDeleted)}
              columns={columns(onDelete)}
              rowKey={(t) => t.id || t.title}
              pagination={false}
            />
            <br />
            <Space>
              <Button onClick={() => setShowForm(true)}>Add Task</Button>
              <Button type="primary" onClick={onSave}>
                Save Tasks
              </Button>
              {isModified && <Typography.Text type="danger">Unsaved</Typography.Text>}
            </Space>
            <AddTaskForm
              {...{
                closeForm,
                buttonLabel,
                onClick,
                showForm,
                values,
                setFieldValue,
              }}
            />
          </>
        );
      }}
    </Formik>
  );
}

export default TaskTable;

function columns(onDelete) {
  return [
    { label: "Name", dataIndex: "title" },
    { label: "Description", dataIndex: "description" },
    {
      label: "Deadline",
      dataIndex: "deadline",
      render: (d) => <DateTime epochSeconds={toEpoch(d)} />,
    },
    {
      label: "Status",
      dataIndex: "status",
    },
    {
      label: "Actions",
      dataIndex: "id",
      render: (_, row) => [
        <Popconfirm
          key="delete"
          onConfirm={() => onDelete(row.index)}
          title="Are you sure you want ot delete this task?"
        >
          <Button disabled={row.status === "Completed"} icon={<DeleteOutlined />} />
        </Popconfirm>,
      ],
    },
  ].map((col) => ({ ...col, title: <Translation text={col.label} /> }));
}

const AddTaskForm = ({ closeForm, buttonLabel, onClick, showForm, values, setFieldValue }) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  return (
    <Modal
      onCancel={closeForm}
      visible={showForm}
      maskClosable={false}
      footer={
        <Button type="primary" onClick={onClick} disabled={!values?.title || !values?.description || isDatePickerOpen}>
          {buttonLabel}
        </Button>
      }
    >
      <Form layout="vertical">
        {[
          { name: "title", label: "Task" },
          {
            name: "description",
            label: "Description",
            component: TextArea,
          },
          {
            name: "deadline",
            label: "Deadline",
            component: DatePicker,
            optional: true,
            componentProps: {
              onOpenChange: setIsDatePickerOpen,
              ...datePickerProps({ showTime: true }),
            },
          },
        ].map(toFormItem({ values, setFieldValue }))}
      </Form>
    </Modal>
  );
};
