import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { getIndex, putUpdate } from "../../redux/actions/hearingActions";
import { index, indexMeta,completedIndex, whole } from "../../redux/selectors/hearingsSelector";
import { fromMeta } from "../../transformers/paginationTransformer";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import IndexRender from "./IndexRender";
import "./styles.less";

export default function () {
  const dispatch = useDispatch();
  const hubSlug = useHubSlug();
  const history = useHistory();
  const canCreate = useAccess("create", "hearing");
  const [disputeId, dispute] = useDispute();
  const upcomingHearings = useSelector(index);
  const hearingsMeta = useSelector(indexMeta);
  const pastHearings = useSelector(completedIndex);
  const { isLoading } = useSelector(whole);

  useEffect(() => {
    dispatch(getIndex(disputeId,1,'upcoming'))
    dispatch(getIndex(disputeId,1,'completed'))
  } , [disputeId, dispatch]);

  const joinMeeting = (hearingUrl) => window.open(hearingUrl, "_blank");

  const cancel = (hearingId) =>
    dispatch(putUpdate(disputeId, hearingId, { status: "Cancelled" }));

  const canViewMemorandum = useAccess("show-memorandums", "hearing");

  const confirmCancellation = (hearingId) =>
    Modal.confirm({
      destroyOnClose: true,
      title: <Translation text="hearings.cancel_confirmation.title" />,
      cancelText: <Translation text="hearings.don't_cancel.button" />,
      okButtonProps: { danger: true, type: "ghost" },
      okText: <Translation text="hearings.cancel.button" />,
      onOk: () => void cancel(hearingId),
    });

  const handleViewMinutes = () => {
    history.push(`/${hubSlug}/disputes/${disputeId}/files/arbitrator/hearing`)
  }

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/hearings`,
      breadcrumbName: <Translation text="hearings.hearings.title" />,
    },
  ];

  return dispute && (upcomingHearings || pastHearings) ? (
    <IndexRender
      disputeParams={{ dispute, disputeId, hubSlug }}
      extraCrumbs={extraCrumbs}
      pastHearings={pastHearings}
      upcomingHearings={upcomingHearings}
      canCreate={canCreate}
      joinMeeting={joinMeeting}
      onCancel={confirmCancellation}
      pagination={(flow) => fromMeta(hearingsMeta, (page) => {
        dispatch(getIndex(disputeId, page, flow));
      })}
      routeOfMemorandums={routeOf(hubSlug, disputeId, "memorandums")}
      routeOfMinutes={routeOf(hubSlug, disputeId, "minutes")}
      canViewMemorandum={canViewMemorandum}
      isLoading={isLoading}
      handleViewMinutes={handleViewMinutes}
    />
  ) : (
    <Spinner />
  );
}

function routeOf(hubSlug, disputeId, entity) {
  return function (hearingId) {
    return `/${hubSlug}/disputes/${disputeId}/hearings/${hearingId}/${entity}`;
  };
}
