import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getShowClaimant,
  handleUpdateClaimantForm,
} from "../../redux/actions/disputeFilingActions";
import {
  selectClaimant,
  selectErrors,
  selectIsErrorHidden,
  selectUserRole,
} from "../../redux/selectors/disputeFilingSelector";
import Spinner from "../Common/Spinner";
import ClaimantRender from "./ClaimantRender";

function ClaimantHOC() {
  const dispatch = useDispatch();

  const { disputeId } = useParams();
  const userRole = useSelector(selectUserRole);
  const claimant = useSelector(selectClaimant);
  const isErrorHidden = useSelector(selectIsErrorHidden);
  const errors = useSelector(selectErrors);

  const errorProps = {
    isHidden: isErrorHidden,
  };

  const handleUpdateForm = (values) =>
    void dispatch(handleUpdateClaimantForm(values));

  useEffect(() => void dispatch(getShowClaimant(disputeId)), [
    disputeId,
    dispatch,
  ]);

  return claimant ? (
    <ClaimantRender
      userRole={userRole}
      claimant={claimant}
      errorProps={errorProps}
      errors={errors}
      onUpdateForm={handleUpdateForm}
    />
  ) : (
    <Spinner card={true} />
  );
}

export default ClaimantHOC;
