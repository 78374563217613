import { Select } from "formik-antd";
import React from "react";
import Translation from "./Translation";

// TODO: make function react to props, eg: only witnesses/experts

export default function (props) {
  return (
    <Select name="role" className="jur-user-role-select" {...props}>
      {Object.entries({
        // "users.claimant_party.text": "Claimant Party",
        // "users.claimant_attorney.text": "Claimant Attorney",
        // "users.claimant_legal_representative.text":
        //   "Claimant Legal Representative",
        "users.claimant_witness.text": "Claimant Witness",
        // "users.respondent_party.text": "Respondent Party",
        // "users.respondent_attorney.text": "Respondent Attorney",
        // "users.respondent_legal_representative.text":
        //   "Respondent Legal Representative",
        "users.respondent_witness.text": "Respondent Witness",
        "users.expert.text": "Expert",
      }).map(([label, value]) => (
        <Select.Option value={value} key={value}>
          <Translation text={label} />
        </Select.Option>
      ))}
    </Select>
  );
}
