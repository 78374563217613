import {  Button, Card, Col, Row, Table, Breadcrumb } from "antd";
import React, { useState } from "react";
import { COMPLETED } from "../../redux/constants/HearingStatuses";
import DateTime from "../Common/DateTime";
import Spinner from "../Common/Spinner";
import NoData from "../Common/NoData";
import Translation from "../Common/Translation";
import ScheduleRender from "./ScheduleRender";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import PageHeader from "../Common/PageHeader";
import Anchor from "../Common/Anchor";
import HearingDetails from "./HearingDetails";

export default function ({
  disputeParams,
  pagination,
  onCancel,
  canCreate,
  routeOfMemorandums,
  routeOfMinutes,
  joinMeeting,
  pastHearings,
  upcomingHearings,
  canViewMemorandum,
  isLoading,
  handleViewMinutes
}) {
  const [visible, setVisible] = useState(false);

  const Hearings = ({ hearings, isUpcomingHearings = false }) => (
    <>
      <Row>
        <Col className="gutter-row jur-case-files-table-overview" span={24}>
          <div className="jur-page-title-h2-wrapper">
            <span className="jur-page-title-h2">
              <Translation
                text={isUpcomingHearings ? "hearings.upcoming_hearings.subtitle" : "hearings.past_hearings.subtitle"}
              />
            </span>
          </div>
        </Col>
      </Row>
      <Card>
        {hearings && hearings.length > 0 ? (
          <Table
            columns={columns(
              routeOfMinutes,
              joinMeeting,
              isUpcomingHearings,
              handleViewMinutes
            )}
            dataSource={hearings}
            pagination={() => pagination(hearings[0].status === 'Completed' ? 'completed' : 'upcoming')}
            rowKey="id"
            expandedRowClassName={() => "bg-white"}
            expandable={{
              expandedRowRender: (record) => <HearingDetails record={record} />,
            }}
          />
        ) : (
          <NoData
            isWrappedInCard={false}
            description={
              <div className="jur-table-empty-content">
                <Translation text="hearings.table.empty-content" />
              </div>
            }
          />
        )}
      </Card>
    </>
  );
  return (
    <SliderMenuWrapper {...disputeParams}>
      <Row justify="center">
        <Col className="gutter-row" span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>{disputeParams?.dispute?.name}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Translation text="hearings.meetings.title" />
            </Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            title={"hearings.meetings.title"}
            cta={
              canCreate && (
                <Button type="primary" onClick={() => setVisible(true)}>
                  <Translation text="hearings.schedule_meeting.button" />
                </Button>
              )
            }
          />
        </Col>
      </Row>
      {!isLoading ? (
        <>
          <Hearings hearings={upcomingHearings} isUpcomingHearings={true} />
          {pastHearings?.length > 0 && <Hearings hearings={pastHearings} />}
        </>
      ) : (
        <div className="jur-tor-spinner">
          <Spinner />
        </div>
      )}

      {visible && <ScheduleRender visible={visible} setVisible={setVisible} />}  
    </SliderMenuWrapper>
  );
}

function columns(
  routeOfMinutes,
  joinMeeting,
  isUpcomingHearings,
  handleViewMinutes
) {
  return [
    {
      label: "hearings.title.column",
      dataIndex: ["attributes", "title"],
      render: (text) => <span className="font-weight-500">{text}</span>,
    },
    {
      label: "hearings.scheduled_at.column",
      dataIndex: ["attributes", "scheduledAt"],
      render: (epochSeconds) => <DateTime epochSeconds={epochSeconds} />,
    },
    {
      label: "hearings.action.column",
      dataIndex: ["attributes", "status"],
      render: (status, { id, attributes }) =>
        isUpcomingHearings ? (
          <Anchor onClick={() => (isUpcomingHearings ? joinMeeting(attributes?.hearingUrl) : routeOfMinutes(id))}>
            <Translation text={"hearings.join_meeting.column"} />
          </Anchor>
        ) : status === COMPLETED ? (
          <Anchor onClick={handleViewMinutes}>
            <Translation text="hearings.view_minutes.column" />
          </Anchor>
        ) : null,
    },
  ]
    .filter(Boolean)
    .map((col) => ({ ...col, title: <Translation text={col.label} /> }));
}
