import * as types from "../constants/constants";

export const getIndex = (only, page = 1) => ({
  type: types.DISPUTE_GET_INDEX,
  payload: { only, page },
});

export const getIndexRequested = (only = "requested", page = 1) => ({
  type: types.DISPUTE_GET_INDEX_REQUESTED,
  payload: { only, page },
});

export const getShow = (disputeId) => ({
  type: types.DISPUTE_GET_SHOW,
  payload: { disputeId },
});

export const handleUpdateLinkEnvelope = (payload) => ({
  type: types.DISPUTE_HANDLE_UPDATE_LINK_ENVELOPE,
  payload,
});

export const putUpdateAccept = (disputeId, status) => ({
  type: types.DISPUTE_PUT_UPDATE,
  payload: { disputeId, status, to: "Accept" },
});

export const putUpdateReject = (disputeId, status, rejectReason) => ({
  type: types.DISPUTE_PUT_UPDATE,
  payload: { disputeId, status, rejectReason, to: "Reject" },
});

export const handleIsOpen = (isOpen) => ({
  type: types.DISPUTE_HANDLE_IS_OPEN,
  payload: isOpen,
});

export const handleUpdateMotivation = (motivation) => ({
  type: types.DISPUTE_HANDLE_UPDATE_MOTIVATION,
  payload: motivation,
});

export const handleUpdate = (res) => ({
  type: types.DISPUTE_HANDLE_UPDATE,
  payload: res,
});

export const handleIndex = (disputes) => ({
  type: types.DISPUTE_HANDLE_INDEX,
  payload: disputes,
});

export const handleIndexRequested = (disputes) => ({
  type: types.DISPUTE_HANDLE_INDEX_REQUESTED,
  payload: disputes,
});

export const handleShow = (dispute) => ({
  type: types.DISPUTE_HANDLE_SHOW,
  payload: dispute,
});

export const putUpdateClose = (disputeId) => ({
  type: types.DISPUTE_PUT_UPDATE_CLOSE,
  payload: disputeId,
});

export const handleError = (e) => ({
  type: types.DISPUTE_HANDLE_ERROR,
  error: e,
});
