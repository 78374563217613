import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  ContainerOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Tag, Typography } from "antd";
import React from "react";
import { sanitizeURL } from "../../helpers/dataHelpers";
import Anchor from "../Common/Anchor";
import Translation from "../Common/Translation";

const { Text, Title } = Typography;

function StaticPanel({ profile, reviewContent }) {
  return (
    <div className="jur-arbitrator-profile-panel">
      <Card>
        <ProfileTitle profile={profile} reviewContent={reviewContent} />
      </Card>
    </div>
  );
}

export default StaticPanel;

const ProfileTitle = ({ profile, reviewContent }) => {
  if (typeof profile.secondaryLanguages === "string")
    profile.secondaryLanguages = profile.secondaryLanguages.split(",");
  return (
    <>
      <PanelSection>
        <BaseProfile reviewContent={reviewContent} profile={profile} />
      </PanelSection>
      <PanelSection>
        <DemographicItems profile={profile} />
      </PanelSection>
      <PanelSection>
        <Row gutter={[24, 16]}>
          {[{ label: "insurance_number", value: profile?.insuranceNumber }].map(
            (item) => (
              <Col>
                <Text className="secondary-color">
                  <Translation text={`roster.${item.label}.text`} />
                </Text>
                <Title className="primary-color" level={5} strong>
                  {item.value}
                </Title>
              </Col>
            )
          )}
        </Row>
      </PanelSection>
      {profile?.primaryLanguage?.length &&
      profile?.secondaryLanguages?.length ? (
        <PanelSection>
          {profile?.primaryLanguage?.length ? (
            <Text className="secondary-color">
              <Translation text={`roster.native_language.text`} />
            </Text>
          ) : null}
          <Title className="primary-color" level={5} strong>
            {profile?.primaryLanguage}
          </Title>
          <br />
          {profile?.secondaryLanguages?.length ? (
            <Text className="secondary-color">
              <Translation text={`roster.secondary_language.text`} />
            </Text>
          ) : null}
          {profile?.secondaryLanguages?.map((l) => (
            <Title className="primary-color" level={5} strong>
              {l}
            </Title>
          ))}
        </PanelSection>
      ) : null}
      <Websites profile={profile} />
    </>
  );
};

export const Websites = ({ profile }) => {
  const websites = [
    profile?.personalWebsiteUrl
      ? { icon: GlobalOutlined, link: profile?.personalWebsiteUrl }
      : null,
    profile?.linkedinUrl
      ? { icon: LinkedinOutlined, link: profile?.linkedinUrl }
      : null,
    profile?.directoryWebsiteUrl
      ? { icon: ContainerOutlined, link: profile?.directoryWebsiteUrl }
      : null,
  ].filter(Boolean);

  if (!websites?.length) return null;
  return (
    <PanelSection>
      <div>
        <Row justify="space-around">
          {websites.map((site) => {
            const SiteIcon = site?.icon;
            return (
              <Col>
                <Anchor
                  onClick={() => {
                    window.open(sanitizeURL(site?.link), "_blank");
                  }}
                >
                  <SiteIcon style={{ fontSize: 20 }} />
                </Anchor>
              </Col>
            );
          })}
        </Row>
      </div>
    </PanelSection>
  );
};

const PanelSection = ({ children }) => (
  <div className="jur-arbitrator-profile-panel-section">{children}</div>
);

const DemographicItems = ({ profile }) => {
  const address = () => {
    const add = profile?.primaryAddress;
    const combinedAddress = [
      add?.address,
      add?.address2,
      add?.city,
      add?.country,
      add?.state,
      add?.zipCode,
    ];
    return combinedAddress.join(", ");
  };
  const profileFields = [
    { icon: EnvironmentOutlined, value: address() },
    { icon: MailOutlined, value: profile?.email },
  ];

  return (
    <>
      {profileFields.map((item) => {
        const DataIcon = item.icon;
        return (
          <Row gutter={[0, 16]} className="jur-arbitrator-demographic-item">
            <Col span={4}>{<DataIcon className="secondary-color" />}</Col>
            <Col span={20}>
              <Title level={5} strong>
                {item.value}
              </Title>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

const BaseProfile = ({ profile, reviewContent = null }) => {
  if (!profile?.status) return null;
  const StatusIcon =
    {
      // [PROFILE_SUBMITTEd]: ClockCircleTwoTone,
      // [JUR_ASKED_FOR_REVISION]: ExclamationCircleTwoTone,
      [JUR_APPROVED]: CheckCircleTwoTone,
    }[JUR_APPROVED] || ClockCircleTwoTone;

  const statusColor = {
    // [PROFILE_SUBMITTED]: "#F2994A",
    // [JUR_ASKED_FOR_REVISION]: "#EB5757",
    [JUR_APPROVED]: "#69d195",
  }[JUR_APPROVED];

  const StatusTag = () => (
    <div className="jur-arbitrator-status">
      <StatusIcon style={{ fontSize: 20 }} twoToneColor={statusColor} />
      <Tag color={statusColor}>
        <Title level={5} strong style={{ color: "white" }}>
          {profile?.status}
        </Title>
      </Tag>
    </div>
  );

  return (
    <center>
      <img
        className="jur-profile-avatar"
        src={profile?.avatarUrl}
        alt="profileImage"
      />
      <br />
      <br />
      <Title level={3} className="primary-color" strong>
        <div className="jur-title">
          {profile?.firstName} {profile?.lastName}
        </div>
      </Title>
      <StatusTag />
      {reviewContent}
    </center>
  );
};

export const JUR_APPROVED = "Jur Approved";
