import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "dispute-filing";
const catcher = logAndThrow(tag);

export function create(body) {
  return Api.post(`/disputes`, body)
    .then((res) => res.data)
    .catch(catcher("create", "Failed to file a new claim"));
}

export function createDispute(body) {
  return Api.post(`/disputes`, body)
    .then((res) => res.data)
    .catch(catcher("create", "Failed to file a new claim"));
}

function showOf(crumb) {
  return function (disputeId) {
    return Api.get(`/disputes/${disputeId}/dispute-filing/${crumb}`)
      .then((res) => res.data)
      .catch(catcher(`${crumb} via show maker`));
  };
}

function updateOf(crumb) {
  return function (disputeId, body) {
    return Api.put(`/disputes/${disputeId}/dispute-filing/${crumb}`, body)
      .then((res) => res.data)
      .catch(
        catcher(
          `${crumb} via update maker`,
          `Failed to save. Please check inputs carefully`
        )
      );
  };
}

export const showClaimant = showOf("claimant");
export const updateClaimant = updateOf("claimant");

export const showKyc = showOf("kyc");
export const updateKyc = updateOf("kyc");

export const showClaimDetails = showOf("claim-details");
export const updateClaimDetails = updateOf("claim-details");

export const showClaimFiles = showOf("claim-files");
export const updateClaimFiles = updateOf("claim-files");

export const showInvites = showOf("invite");
export const updateInvites = updateOf("invite");

/**
 * It is okay to get 404 error here. Sometimes 404 may be shown
 * as a CORS error. Either way, the error is expected if DB has
 * no record of respondent of this disputeId yet.
 *
 * @param {Number} disputeId
 */
export const showAddRespondent = (disputeId) => {
  return Api.get(`/disputes/${disputeId}/dispute-filing/respondent`)
    .catch(() => {
      return { data: { data: { attributes: {} } } };
    })
    .then((res) => res.data);
};
export const updateAddRespondent = updateOf("respondent");

export const showPayment = showOf("payment");
export const updatePayment = updateOf("payment");

export const showSoc = showOf("review");
export const updateSoc = updateOf("soc");

export const updateCompleted = updateOf("completed");
