import { message, Modal } from "antd";
import { push, replace } from "connected-react-router";
import React from "react";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  errorMessage,
  successMessage,
} from "../../components/Common/Feedback/Message";
import ActivationMessage from "../../components/Signs/ActivationMessage";
import * as actions from "../actions/disputeFilingActions";
import { handleUpdate, handleUpdateSlug } from "../actions/hubActions";
import * as signActions from "../actions/signActions";
import * as apis from "../apis/disputeFilingApi";
import { index } from "../apis/hubsApi";
import * as signApis from "../apis/signApi";
import * as types from "../constants/constants";
import { selectCurrentSlug } from "../selectors/hubsSelector";

const tag = "dispute-filing";

export function* handleStart() {
  yield put(push("/region-us/dispute-filing"));
}

export function* postCreate(action) {
  const { body } = action.payload;
  try {
    const res = yield call(apis.create, body);
    yield put(actions.handleCreate(res));
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreateDispute(action) {
  const body = action.payload;
  try {
    const res = yield call(apis.createDispute, body);
    yield put(actions.handleCreateDispute(res));
    yield put(
      replace(
        `/${res.meta.hubSlug}/dispute-filing/${res.data.id}?step=claimant`
      )
    );
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    if (e.response.data?.errors?.profile) {
      message.error("Must complete the profile before continuing");
      yield put(push("/edit-profile"));
    } else {
      yield put(actions.handleError(e));
    }
  }
}

function showOf(crumb) {
  return function* (action) {
    const { disputeId } = action.payload;
    try {
      if (crumb === "Kyc") {
        const hubsResponse = yield call(index);
        yield put(
          handleUpdate(
            hubsResponse.data.find((h) => h?.attributes?.url === "region-us")
          )
        );
      }
      if (!disputeId) throw new TypeError("disputeId must be a valid integer");
      const res = yield call(apis[`show${crumb}`], disputeId);
      yield put(actions[`handleShow${crumb}`](res));
    } catch (e) {
      console.error(tag, `saga getShow${crumb} error:`, e);
      yield put(actions.handleError(e));
    }
  };
}

function updateOf(crumb) {
  return function* (action) {
    const { disputeId, body, goBack } = action.payload;
    try {
      const res = yield call(apis[`update${crumb}`], disputeId, body);
      yield put(
        actions[`handleUpdate${crumb}`]({
          res,
          isDraft: body?.isDraft,
          goBack: goBack,
        })
      );
      if (body?.isDraft) {
        successMessage("general.draft_saved_successfully.message");
      } else {
        successMessage("general.updated_successfully.message");
      }
    } catch (e) {
      console.error(tag, `saga getUpdate${crumb} error:`, e);
      yield put(actions.handleError(e));
    }
  };
}

export function* putUpdateSoc(action) {
  let { disputeId, body, redirectTo } = action.payload;
  const slug = yield select(selectCurrentSlug);
  redirectTo = redirectTo || `${slug}/dispute-filing/${disputeId}?step=sign`;
  const createEnvelopeBody = {
    fileIds: [].concat(body.fileId || body.fileIds),
    flow: "Statement Of Claim",
    signType: "Self Sign",
    signableId: disputeId,
  };

  try {
    const resConsent = yield call(signApis.consent);
    const { activationStatus, consentStatus } = resConsent?.data?.attributes;
    if (!activationStatus) {
      yield Modal.info({
        title: <ActivationMessage />,
      });
      throw new Error("Activation Missing");
    }
    if (!consentStatus) {
      const resConsentUrl = yield call(signApis.consentUrl, redirectTo);
      yield put(
        signActions.handleConsentUrl(
          resConsentUrl?.data?.attributes?.url,
          activationStatus
        )
      );
    } else {
      console.log("putUpdateSoc body", body);
      yield put(signActions.handleIsRedirecting(true));
      if (!body.envelopeId) {
        yield put(
          signActions.postCreateEnvelope(
            createEnvelopeBody,
            disputeId,
            null,
            slug
          )
        );
      } else {
        console.log("putUpdateSoc has envelopeId");
        window.location = body.redirectURL.url;
      }
    }
  } catch (e) {
    console.error(tag, `saga getUpdateSoc error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* handleUpdateSoc(action) {
  const { disputeId, disputeStatus, signatureStatus } = action.payload;
  try {
    yield put(
      actions.putUpdateCompleted(disputeId, disputeStatus, signatureStatus)
    );
  } catch (e) {
    console.error(tag, `saga handleUpdateSoc error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateCompleted(action) {
  const { disputeId, hubSlug } = action.payload?.meta;
  try {
    console.log("received hubSlug as", action.payload);
    yield put(handleUpdateSlug(hubSlug));
    // yield call(apis.updateCompleted, disputeId);
    yield put(push(`/${hubSlug}/dispute-filing/${disputeId}?step=completed`));
  } catch (e) {
    console.error(tag, `saga handleUpdateSoc error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* handleCreate(action) {
  const slug = yield select(selectCurrentSlug);
  yield put(
    push(`/${slug}/dispute-filing/${action.payload.data.id}?step=details`)
  );
}

export const getShowKyc = showOf("Kyc");
export const putUpdateKyc = updateOf("Kyc");

export const getShowClaimant = showOf("Claimant");
export const putUpdateClaimant = updateOf("Claimant");

export const getShowClaimDetails = showOf("ClaimDetails");
export const putUpdateClaimDetails = updateOf("ClaimDetails");

export const getShowClaimFiles = showOf("ClaimFiles");
export const putUpdateClaimFiles = updateOf("ClaimFiles");

export const getShowAddRespondent = showOf("AddRespondent");
export const putUpdateAddRespondent = updateOf("AddRespondent");

export const getShowInvites = showOf("Invites");
export const putUpdateInvites = updateOf("Invites");

export const getShowPayment = showOf("Payment");
export const putUpdatePayment = updateOf("Payment");

export const getShowSoc = showOf("Soc");

export function* handleSignatureError({ payload }) {
  const { disputeId, hubSlug } = payload?.params;
  errorMessage("general.signature_error.text");
  if (hubSlug && disputeId) {
    yield put(handleUpdateSlug(hubSlug));
    yield put(push(`/${hubSlug}/dispute-filing/${disputeId}?step=sign`));
  } else yield put(push(`/`));
}

export default function* () {
  yield takeLatest(types.DISPUTEFILING_HANDLE_START, handleStart);
  yield takeEvery(types.DISPUTEFILING_POST_CREATE, postCreate);
  yield takeLatest(types.DISPUTEFILING_HANDLE_CREATE, handleCreate);
  yield takeEvery(types.DISPUTEFILING_POST_CREATE_DISPUTE, postCreateDispute);
  yield takeLatest(types.DISPUTEFILING_PUT_UPDATE_KYC, putUpdateKyc);
  yield takeLatest(types.DISPUTEFILING_GET_SHOW_KYC, getShowKyc);
  yield takeLatest(types.DISPUTEFILING_PUT_UPDATE_CLAIMANT, putUpdateClaimant);
  yield takeLatest(types.DISPUTEFILING_GET_SHOW_CLAIMANT, getShowClaimant);

  yield takeLatest(
    types.DISPUTEFILING_PUT_UPDATE_CLAIMDETAILS,
    putUpdateClaimDetails
  );
  yield takeLatest(
    types.DISPUTEFILING_GET_SHOW_CLAIMDETAILS,
    getShowClaimDetails
  );

  yield takeLatest(
    types.DISPUTEFILING_PUT_UPDATE_CLAIMFILES,
    putUpdateClaimFiles
  );
  yield takeLatest(types.DISPUTEFILING_GET_SHOW_CLAIMFILES, getShowClaimFiles);

  yield takeLatest(
    types.DISPUTEFILING_PUT_UPDATE_ADDRESPONDENT,
    putUpdateAddRespondent
  );
  yield takeLatest(
    types.DISPUTEFILING_GET_SHOW_ADDRESPONDENT,
    getShowAddRespondent
  );

  yield takeLatest(types.DISPUTEFILING_GET_SHOW_INVITES, getShowInvites);
  yield takeLatest(types.DISPUTEFILING_PUT_UPDATE_INVITES, putUpdateInvites);

  yield takeLatest(types.DISPUTEFILING_PUT_UPDATE_PAYMENT, putUpdatePayment);
  yield takeLatest(types.DISPUTEFILING_GET_SHOW_PAYMENT, getShowPayment);

  yield takeLatest(types.DISPUTEFILING_PUT_UPDATE_SOC, putUpdateSoc);
  yield takeLatest(types.DISPUTEFILING_GET_SHOW_SOC, getShowSoc);
  yield takeEvery(types.DISPUTEFILING_PUT_UPDATE_COMPLETED, putUpdateCompleted);
  yield takeLatest(
    types.DISPUTEFILING_HANDLE_SIGNATURE_ERROR,
    handleSignatureError
  );
}
