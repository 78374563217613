import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import React from "react";
import { initialsOf } from "../../helpers/dataHelpers";
import { QuestionTitle } from "../Common/FormQuestion";
import Translation from "../Common/Translation";
import { inviteItems } from "../DisputeFiling/InviteRender";

export default function InviteRender({
  invites = [],
  t,
  errors = {},
  errorProps = {},
  onAddInviteItem,
  onRemoveInviteItem = () => {},
  onUpdateInvitesForm = () => {},
  inviteMeta,
}) {
  const defenceTeam = inviteMeta?.defenceTeam || [];
  return (
    <div className="jur-response-filing-content-wrapper">
      {defenceTeam.length !== 0 ? (
        <div>
          <Typography.Title level={5} className="primary-color">
            <Translation text="response_filing.you_defence_team.text" />
          </Typography.Title>
          <Row gutter={[16, 16]}>
            {defenceTeam?.map((user) => (
              <Col>
                <UserDisplay user={user} />
              </Col>
            ))}
          </Row>
        </div>
      ) : null}
      <div>
        <div className="jur-invite-user-list-heading">
          <QuestionTitle text="claimant.invite_your_team.text" />
        </div>
        <Button
          onClick={onAddInviteItem}
          icon={<PlusOutlined />}
          className="primary-color"
        >
          <Translation pad={true} text="claimant.add_new_invite.button" />
        </Button>

        {invites.map((invite, index) => (
          <Formik initialValues={invite}>
            {({ values, setFieldValue }) => {
              return (
                <div>
                  <Form layout="inline">
                    {inviteItems({
                      t,
                      propogateValues: onUpdateInvitesForm(index),
                      values,
                      setFieldValue,
                      onRemove: onRemoveInviteItem(index),
                      errors: errors[index],
                      errorProps,
                      index,
                      count: invites.length,
                    })}
                  </Form>
                </div>
              );
            }}
          </Formik>
        ))}
      </div>
    </div>
  );
}

export const UserDisplay = ({ user, party }) => {
  const initials = initialsOf(user.fullName ?? user?.email);
  return (
    <div className="jur-user-name-wrapper">
      <Typography.Text strong>
        <div
          className={
            party === "claimant"
              ? "jur-user-name-claimant-initials"
              : "jur-user-name-initials"
          }
        >
          {initials}
        </div>
      </Typography.Text>
      <div className="jur-user-name-details">
        <Typography.Text className="primary-color">
          {user.fullName ?? user?.email}
        </Typography.Text>
        <Typography.Text className="secondary-color">
          {user.role}
        </Typography.Text>
      </div>
    </div>
  );
};
