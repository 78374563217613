import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "dispute-files";
const catcher = logAndThrow(tag);

export function index(disputeId, group, page) {
  const endPoint = `/disputes/${disputeId}/case-files${group ? `?group=${group}&` : "?"}page=${page}`;
  return Api.get(endPoint)
    .then((res) => res.data)
    .catch(catcher("index"));
}

export function create(disputeId, fileCategory, body) {
  return Api.post(`/disputes/${disputeId}/case-files?fileCategory=${fileCategory}`, body).catch(catcher("create"));
}
