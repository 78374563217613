import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { labelToTitle } from "../../helpers/tableHelpers";
import SliderPreview from "../Arbitrator/SliderPreview";
import { toDateTime } from "../Common/DateTime";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import "./styles.less";

function IndexRender({
  disputeParams,
  panel,
  disputeAssignments,
  onUpdateStrikeList,
  strikeList,
  onConfirm,
  getArbitrator,
  arbitrator,
  showPanelCreation,
  availableArbitrators,
  createPanel,
  canAssignArbitrator,
  isAssignmentCompleted,
  disableSelection,
  canSeeAllStrikes,
  canStrikePanel,
}) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const previewArbitrator = (id) => {
    setIsPreviewOpen(true);
    getArbitrator(id);
  };

  return (
    <SliderMenuWrapper {...disputeParams}>
      <Row className={isPreviewOpen ? "jur-arbitrator-slider-opened" : ""}>
        <Col span={isPreviewOpen ? 17 : 24}>
          <Content
            {...{
              onUpdateStrikeList,
              panel,
              disputeAssignments,
              strikeList,
              onConfirm,
              setIsPreviewOpen,
              previewArbitrator,
              showPanelCreation,
              createPanel,
              availableArbitrators,
              disableSelection,
              canStrikePanel,
              canAssignArbitrator,
              canSeeAllStrikes,
              isAssignmentCompleted,
              disputeParams,
            }}
          />
        </Col>
        <Col span={isPreviewOpen ? 7 : 0}>
          <RightSlider
            arbitrator={arbitrator}
            setIsPreviewOpen={setIsPreviewOpen}
            disableSelection={disableSelection}
          />
        </Col>
      </Row>
    </SliderMenuWrapper>
  );
}

export default IndexRender;

const Content = ({
  disputeParams,
  panel,
  disputeAssignments,
  onUpdateStrikeList,
  strikeList = [],
  onConfirm,
  setIsPreviewOpen,
  previewArbitrator,
  showPanelCreation,
  availableArbitrators,
  createPanel,
  disableSelection,
  canStrikePanel,
  canAssignArbitrator,
  canSeeAllStrikes,
  isAssignmentCompleted,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      onUpdateStrikeList(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const handlePanelCreate = () => {
    createPanel(selectedRows.map((arb) => arb.id));
  };

  const selectedPanelCount = showPanelCreation && `(${strikeList.length} / 10 selected )`;

  const arbitratorList = showPanelCreation ? availableArbitrators : panel;
  const translationKey = isAssignmentCompleted
    ? "assignment_completed"
    : disableSelection
    ? "wait_for_strike_off"
    : showPanelCreation
    ? "create_the_panel"
    : canAssignArbitrator
    ? "assign_arbitrator"
    : "review_your_panel";

  return (
    <div className="jur-arbitration-panel-wrapper">
      <CTAHeader
        disputeParams={disputeParams}
        canAssignArbitrator={canAssignArbitrator}
        canStrikePanel={canStrikePanel}
        onPanelCreate={handlePanelCreate}
        showPanelCreation={showPanelCreation}
        strikeList={strikeList}
        onSave={() => setShowModal(true)}
        disableSelection={disableSelection}
      />
      <Card type="inner">
        <Typography.Title level={4}>
          <Translation text={`arbitrator_panel.${translationKey}.title`} />
        </Typography.Title>
        <Typography.Text className="secondary-color">
          <Translation
            text={`arbitrator_panel.${translationKey}.description`}
          />
        </Typography.Text>
        <Typography.Text>&nbsp; {selectedPanelCount}</Typography.Text>
      </Card>
      <Card type="inner">
        <Table
          pagination={false}
          loading={!Boolean(arbitratorList)}
          className={
            canStrikePanel &&
            "jur-arbitration-panel-wrapper-strike-off-checkbox"
          }
          rowSelection={
            disableSelection
              ? undefined
              : {
                  type: canAssignArbitrator ? "radio" : "checkbox",
                  ...rowSelection,
                  hideSelectAll: true,
                  getCheckboxProps: (record) => {
                    return canAssignArbitrator
                      ? {
                          disabled:
                            record?.isStruckByClaimant ||
                            record?.isStruckByRespondent,
                        }
                      : null;
                  },
                }
          }
          columns={columns(canSeeAllStrikes)}
          dataSource={arbitratorList}
          rowKey="id"
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                previewArbitrator(
                  !showPanelCreation ? record?.userId : record?.id
                );
                setIsPreviewOpen(true);
              },
            };
          }}
        />
      </Card>

      {canSeeAllStrikes ? (
        <Card type="inner">
          <Table
            title={() => (
              <Typography.Title level={4}>
                <Translation text="arbitrator_panel.assignments.title" />
              </Typography.Title>
            )}
            dataSource={disputeAssignments}
            columns={assignmentColumns()}
            rowKey="id"
            pagination={false}
            size="small"
          />
        </Card>
      ) : null}

      <ConfirmModal
        {...{
          setShowModal,
          showModal,
          selectedRows,
          onConfirm,
          canAssignArbitrator,
        }}
      />
    </div>
  );
};

const CTAHeader = ({
  canStrikePanel,
  strikeList,
  onSave,
  showPanelCreation,
  onPanelCreate,
  disableSelection,
  canAssignArbitrator,
  disputeParams,
}) => {
  const isCTADisabled = showPanelCreation
    ? strikeList.length !== 10
    : canStrikePanel
    ? strikeList.length > 3
    : canAssignArbitrator
    ? strikeList.length !== 1
    : false;
  return (
    <Row justify="space-between">
      <Col span={24} className="jur-panel-title-breadcrumb">
        <BreadcrumbRender dispute={disputeParams?.dispute} />
      </Col>
      <Col>
        <Typography.Title level={2}>
          <Translation text="arbitrator_panel.arbitration_panel.title" />
        </Typography.Title>
      </Col>
      <Col>
        <Space>
          {disableSelection ? null : (
            <Button
              disabled={isCTADisabled}
              size="small"
              type="primary"
              onClick={() => {
                if (showPanelCreation) onPanelCreate();
                else onSave();
              }}
            >
              <Translation
                text={
                  showPanelCreation
                    ? "arbitrator_panel.create_panel.action"
                    : canStrikePanel
                    ? "general.save.button"
                    : canAssignArbitrator
                    ? "dispute_assignment.assign.action"
                    : null
                }
              />
            </Button>
          )}
        </Space>
      </Col>
    </Row>
  );
};

const ConfirmModal = ({
  setShowModal,
  showModal,
  selectedRows,
  onConfirm,
  canAssignArbitrator,
}) => {
  const onOk = () =>
    onConfirm(canAssignArbitrator ? selectedRows[0]?.userId : null);

  return (
    <Modal
      title={
        <Typography.Title level={5} strong>
          {canAssignArbitrator ? (
            <Translation text="dispute_assignment.assign.action" />
          ) : (
            <Translation text="arbitrator_panel.strike_off.title" />
          )}
        </Typography.Title>
      }
      width={460}
      onCancel={() => {
        setShowModal(false);
      }}
      closable={false}
      visible={showModal}
      onOk={onOk}
      okText={<Translation text="general.confirm.action" />}
    >
      <Typography.Title level={5} className="primary-color">
        {canAssignArbitrator ? (
          <Translation text="arbitrator_panel.are_you_sure_you_want_to_assign.text" />
        ) : (
          <Translation text="arbitrator_panel.are_you_sure_you_want_to_remove.text" />
        )}
      </Typography.Title>
      <br />
      {selectedRows.map((arb) => (
        <div key={arb?.userId}>
          <img
            className="jur-arbitration-panel-avatar-confirmation"
            src={arb?.avatarUrl}
            alt="avatar"
          />
          <Typography.Text strong>{arb.fullName}</Typography.Text>
        </div>
      ))}
    </Modal>
  );
};

const RightSlider = ({ arbitrator, setIsPreviewOpen }) => {
  if (!arbitrator)
    return (
      <div className="jur-arbitrator-right-slider">
        <Spinner />
      </div>
    );
  return (
    <div>
      <div className="jur-arbitrator-right-slider-wrapper">
        <div className="jur-arbitrator-right-slider-body">
          <div>
            <Card className="jur-bottom-border">
              <Row justify="space-between">
                <Col>
                  <Link
                    to={`/region-us/disputes/1/arbitrator-panel/arbitrators/${arbitrator?.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button>Open in a new window</Button>
                  </Link>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      setIsPreviewOpen(false);
                    }}
                    type="link"
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            </Card>
          </div>
          <div className="jur-dispute-show-right-slider-section-title">
            <SliderPreview arbitrator={arbitrator} />
          </div>
        </div>
      </div>
    </div>
  );
};

function columns(canAssignArbitrator) {
  return [
    {
      label: "arbitrator_panel.name.column",
      render: (arb) => (
        <div>
          <img
            className="jur-arbitration-panel-avatar"
            src={arb?.avatarUrl}
            alt="avatar"
          />
          <Typography.Text strong>{arb.fullName}</Typography.Text>
        </div>
      ),
    },

    {
      label: "arbitrator_panel.experience.column",
      dataIndex: "experience",
      render: (exp) => (
        <Typography.Text className="secondary-color">
          {`${exp} years`}
        </Typography.Text>
      ),
    },
    {
      label: "arbitrator_panel.arbitral_disputes.column",
      dataIndex: "arbitralDispute",
      render: (arbitralDispute) => (
        <Typography.Text className="secondary-color">
          {arbitralDispute}
        </Typography.Text>
      ),
    },
    {
      label: "arbitrator_panel.areas_of_expertise.column",
      dataIndex: "specializations",
      render: (specializations) =>
        specializations?.map((s) => (
          <Tag>
            <Typography.Text className="primary-color">{s}</Typography.Text>
          </Tag>
        )),
    },
    canAssignArbitrator
      ? {
          label: "Struck Off By",
          dataIndex: "id",
          render: (_, { isStruckByRespondent, isStruckByClaimant }) => (
            <Space direction="vertical">
              {isStruckByClaimant ? <Tag> By Claimant</Tag> : null}
              {isStruckByRespondent ? <Tag> By Respondent</Tag> : null}
            </Space>
          ),
        }
      : false,
  ]
    .filter(Boolean)
    .map(labelToTitle);
}

function assignmentColumns() {
  return [
    {
      label: "general.name.text",
      dataIndex: "name",
    },
    {
      label: "general.email.text",
      dataIndex: "email",
    },
    {
      label: "general.status.text",
      dataIndex: "status",
    },
    {
      label: "general.updated_at.text",
      dataIndex: "updatedAt",
      render: toDateTime,
    },
  ].map(labelToTitle);
}

const BreadcrumbRender = ({ dispute }) => (
  <Breadcrumb>
    <Breadcrumb.Item>{dispute?.name}</Breadcrumb.Item>
    <Breadcrumb.Item>
      <Translation text="arbitrator_panel.arbitration_panel.title" />
    </Breadcrumb.Item>
  </Breadcrumb>
);
