import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {
  billingDetail: {},
  isLoading: true,
  isOpen: false,
  isSubmitting: false,
  form: {},
  errors: {},
};

export default function (state = initial, action) {
  switch (action.type) {
    case types.DISPUTE_GET_SHOW:
      return { ...state, isLoading: true };
    case types.BILLINGDETAILS_HANDLE_IS_OPEN:
      return { ...state, isOpen: action.payload };
    case types.BILLINGDETAILS_HANDLE_SHOW:
      return {
        ...state,
        isLoading: false,
        hasDetails: true,
        billingDetail: action.payload.data?.attributes || action.payload.data,
      };
    case types.BILLINGDETAILS_PUT_UPDATE:
      return { ...state, isSubmitting: true, errors: undefined };
    case types.BILLINGDETAILS_HANDLE_UPDATE:
      return { ...state, isOpen: false, isSubmitting: false };
    case types.BILLINGDETAILS_HANDLE_UPDATE_FORM:
      return { ...state, form: action.payload };
    case types.BILLINGDETAILS_HANDLE_ERROR:
      let hasDetails = state.hasDetails;
      if (action.error.response.status === 404) hasDetails = false;
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        errors: errorMessages(action.error),
        hasDetails,
      };
    default:
      return state;
  }
}
