import { Col, Row } from "antd";
import React from "react";
import LeftSlider from "../Disputes/LeftSlider";
import "./styles.less";

function SliderMenuWrapper({ disputeId, hubSlug, children }) {
  return (
    <Row>
      <Col span={4}>
        <LeftSlider disputeId={disputeId} hubSlug={hubSlug} />
      </Col>
      <Col span={20}>
        <div className="jur-dispute-page-content">{children}</div>
      </Col>
    </Row>
  );
}

export default SliderMenuWrapper;
