import * as types from "../constants/constants";

const getPrefixFromFeature = (feature) => {
  return {
    proceduralOrder: "PROCEDURALORDER",
  }[feature];
};

export const fetchIndex = (feature) => (disputeId, page = 1) => {
  return {
    type: types[`${getPrefixFromFeature(feature)}_FETCH_INDEX`],
    payload: { disputeId, page },
  };
};

export const fetchCurrent = (feature) => (disputeId, orderId, params = "") => {
  return {
    type: types[`${getPrefixFromFeature(feature)}_FETCH_CURRENT`],
    payload: { disputeId, orderId, params },
  };
};

export const actionsFor = (feature) => (fn) => fn(feature);
