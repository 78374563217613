export const index = (state) => state.hearing.index;
export const indexMeta = (state) => state.hearing.indexMeta;
export const completedIndex = (state) => state.hearing.completed;
export const completedIndexMeta = (state) => state.hearing.completedMeta;
export const current = (state) => state.hearing.current;
export const whole = (state) => state.hearing;
export const selectErrors = (state) => state.hearing.errors;
export const selectSignerIds = (state) =>
  state.hearing?.attributes?.participants?.map((p) => p.userId);
export const selectIndexTimeline = (state) => state.hearing.timeline;
export const indexMemorandum = (state) => state.hearing.indexMemorandum;
export const indexMetaMemorandum = (state) => state.hearing.indexMetaMemorandum;
export const currentMemorandum = (state) => state.hearing.memorandum;
export const selectShowMinutes = (state) => state.hearing.minutes;
export const participantUserIds = (state) =>
  state.hearing.current?.attributes?.participants
    ?.map((p) => p.userId)
    ?.filter(Boolean);
