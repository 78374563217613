import React from "react";
import { dateRender, dateTimeRender } from "../../helpers/dataHelpers";

function DateTime({ epochSeconds, onlyDate }) {
  if (!epochSeconds) return null;
  const date = new Date(epochSeconds * 1000);
  const complete = dateTimeRender(date);
  return (
    <span className="jur-data-format-text" title={complete}>
      {onlyDate ? dateRender(date) : complete}
    </span>
  );
}

export default DateTime;
export const toDateTime = (string) => (
  <DateTime epochSeconds={new Date(string).getTime() / 1000} />
);
