import React from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Typography, Button } from "antd";

import Viewer from "../Common/Editor/Viewer";
import Translation from "../Common/Translation";
import * as fileActions from "../../redux/actions/fileActions";

const { Title, Text } = Typography;

export default function CaseClosing({ dispute, userName }) {
  const dispatch = useDispatch();
  const awardId = dispute?.arbitralAward?.file?.id;
  const previewFile = () => dispatch(fileActions.getShow(awardId));

  const details = [
    {
      label: "case_closing.dispute_resolution_time.button",
      value: `${dispute?.disputeResolutionTime} Days`,
    },
    {
      label: "case_closing.dispute_value.button",
      value: `$${Intl.NumberFormat("en-US").format(dispute?.disputeValue)}`,
    },
    {
      label: "case_closing.subject_matter.button",
      value: <Viewer value={dispute?.subjectMatter} />,
    },
  ];

  const DisputeDetails = () => {
    return (
      <div>
        {details.map((detail) => {
          return (
            <Row className="jur-dispute-closing-details-row">
              <Col span={8}>
                <Text className="secondary-color">
                  <Translation text={detail.label} />
                </Text>
              </Col>
              <Col span={16}>{detail.value}</Col>
            </Row>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Greetings userName={userName} />
      <DisputeDetails />
      <PreviewAward previewFile={previewFile} />
    </div>
  );
}

const Greetings = ({ userName }) => {
  return (
    <>
      <div className="jur-dispute-show-greeting">
        <Title className="primary-color" level={4}>
          <Translation text="general.hello.text" />, {userName}!
        </Title>
        <Text className="secondary-color">
          <Translation text="dispute.closed.text" />
        </Text>
      </div>
    </>
  );
};

const PreviewAward = ({ previewFile }) => {
  return (
    <div>
      <Button type="primary" onClick={previewFile}>
        <Translation pad={true} text="case_closing.preview_award.button" />
      </Button>
    </div>
  );
};
