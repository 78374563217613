import { Row, Space, Spin } from "antd";
import React from "react";

function Loading({ loading, tip }) {
  return (
    <>
      {loading ? (
        <Row justify="center" align="middle" className="h-100 loader">
          <Space>
            <Spin size="large" tip={tip} />
          </Space>
        </Row>
      ) : null}
    </>
  );
}

export default Loading;
