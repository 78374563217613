import { RightOutlined } from "@ant-design/icons";
import { Avatar, Button, List } from "antd";
import React from "react";
import { toAvatar, toName } from "../../transformers/userTransformer";
import KycStatus from "../Common/KycStatus";
import UserRole from "../Common/UserRole";

export default function ParticipantListRender({
  participants = [],
  role,
  onClick,
}) {
  return (
    <List itemLayout="horizontal">
      {participants.filter(Boolean).map((user, index) => (
        <ParticipantItemRender
          user={user}
          role={role}
          key={index}
          onClick={() => onClick(user)}
        />
      ))}
    </List>
  );
}

ParticipantListRender.Item = ParticipantItemRender;
export function ParticipantItemRender({
  user,
  role,
  group,
  description,
  onClick,
  showGroup = false,
  navigation = true,
}) {
  if (!user) return null;
  return (
    <List.Item
      actions={actionsOf(onClick, navigation)}
      onClick={() => onClick && onClick(user)}
    >
      <List.Item.Meta
        avatar={<Avatar size={50} src={toAvatar(user)} />}
        title={toName(user)}
        description={
          description ||
          user.specialization?.join(", ") ||
          user.hubName || <KycStatus status={user.kycStatus} />
        }
      />
      <UserRole
        role={role || user.role}
        group={showGroup ? group || user.group || user.userGroup : undefined}
      />
    </List.Item>
  );
}

function actionsOf(onClick, navigation) {
  if (!navigation) return undefined;
  return [
    <Button onClick={onClick} type="text" key="action-right">
      <RightOutlined />
    </Button>,
  ];
}
