import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { getShow } from "../../redux/actions/disputeActions";
import { getIndex } from "../../redux/actions/disputeFileActions";
import { selectIndex } from "../../redux/selectors/disputeFilesSelector";
import PageHeader from "../Common/PageHeader";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import CaseFilesTableOverview from "./TableOverview";
import CaseFilesBreadcrumb from "../Common/CaseFilesBreadcrumb";

function Category() {
  let location = useLocation();
  console.log(
    "🚀 ~ file: Category.js ~ line 18 ~ Category ~ location",
    location.pathname.split("/")[5]
  );
  const categoryKey = location.pathname.split("/")[5];
  const data = {
    "statements-attachments": {
      name: "general.statements.text",
      apiParam: "Statement and Attachment",
    },
    exhibits: {
      name: "general.exhibits.text",
      apiParam: "Additional Exhibit",
    },
    "witness-statements": {
      name: "general.witness_statements.text",
      apiParam: "Witness Statement",
    },
    reports: {
      name: "general.reports.text",
      apiParam: "Report",
    },
  }[categoryKey];
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();
  const dispatch = useDispatch();
  const disputeFilesData = useSelector(selectIndex);

  useEffect(() => {
    if (!dispute) dispatch(getShow(disputeId));
    else dispatch(getIndex(disputeId, 1, data.apiParam));
  }, [dispatch, disputeId, dispute, data.apiParam]);

  return (
    <div>
      <SliderMenuWrapper {...{ dispute, disputeId, hubSlug }}>
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={24}>
            <CaseFilesBreadcrumb />
            <PageHeader title={data.name} />
          </Col>
          <Col className="gutter-row" span={24}>
            <CaseFilesTableOverview
              data={disputeFilesData}
              whiteHeader={true}
              isLoading={!disputeFilesData}
            />
          </Col>
        </Row>
      </SliderMenuWrapper>
    </div>
  );
}

export default Category;
