import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInlineShow } from "../../../redux/actions/fileActions";
import {
  selectFile,
  selectFileList,
} from "../../../redux/selectors/filesSelector";
import Spinner from "../Spinner";
import "./styles.less";

export default function ({ url, fileId, useList = false }) {
  const dispatch = useDispatch();
  const file = useSelector(selectFile);
  const fileList = useSelector(selectFileList);
  const downloadUrl =
    url ||
    (useList
      ? fileList[fileId]?.downloadUrl
      : file?.id && file?.id === fileId
      ? file?.downloadUrl
      : "");

  useEffect(() => {
    if (!url && fileId) dispatch(getInlineShow(fileId));
  }, [url, fileId, dispatch]);

  return downloadUrl ? (
    <object
      aria-label="alt"
      data={downloadUrl}
      type="application/pdf"
      className="jur-pdf-render-object"
    ></object>
  ) : (
    <Spinner card={true} />
  );
}
