import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIndex, getIndexRequested } from "../../redux/actions/disputeActions";
import { selectAccessor } from "../../redux/selectors/authSelector";
import * as selectors from "../../redux/selectors/disputesSelector";
import IndexRender from "./IndexRender";
import { getUserOnboardingStatus } from "../../helpers/authHelpers";

export default function () {
  const history = useHistory();
  const { isAcceptedLoading, isRequestedLoading } = useSelector(selectors.whole);
  const accepted = useSelector(selectors.accepted);
  const requested = useSelector(selectors.requested);
  const { role, group } = useSelector(selectAccessor);
  const dispatch = useDispatch();
  const isAcceptedEmpty = !(isAcceptedLoading || accepted?.data?.length);

  useEffect(() => {
    if (!getUserOnboardingStatus()) {
      history.push("/onboarding");
    }
  });

  useEffect(() => {
    if (group === "Hub User") {
      dispatch(getIndex("accepted"));
      dispatch(getIndexRequested());
    } else {
      dispatch(getIndex());
    }
  }, [dispatch, group]);

  const onPageChange = (page, type) => {
    if (group === "Hub User") {
      if (type === "requested") dispatch(getIndexRequested("requested", page));
      else dispatch(getIndex("accepted", page));
    } else {
      dispatch(getIndex(null, page));
    }
  };

  return (
    <IndexRender
      accepted={accepted}
      requested={requested}
      isAcceptedLoading={isAcceptedLoading}
      isRequestedLoading={isRequestedLoading}
      isAcceptedEmpty={isAcceptedEmpty}
      group={group}
      role={role}
      onPageChange={onPageChange}
    />
  );
}
