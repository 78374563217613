import { Typography } from "antd";
import React from "react";
import Translation from "../Common/Translation";

export default function () {
  return (
    <>
      <Typography.Paragraph>
        <Translation text="general.requires_signature.text" />
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Translation text="general.activate_docusign.text" />
      </Typography.Paragraph>
    </>
  );
}
