import { Tag } from "antd";
import React from "react";
import {
  SENT_TO_HUB,
  HUB_APPROVED,
  HUB_REJECTED,
  SENT_TO_PARTIES,
  SIGNATURE_COMPLETED,
} from "../../redux/constants/TORStatuses";

export default function ({ status }) {
  return <Tag color={color(status)}>{status}</Tag>;
}

function color(status) {
  return {
    [SENT_TO_HUB]: "yellow",
    [HUB_REJECTED]: "red",
    [HUB_APPROVED]: "yellow",
    [SENT_TO_PARTIES]: "yellow",
    [SIGNATURE_COMPLETED]: "green",
  }[status];
}

export function torStatusColor(status) {
  return color(status);
}
