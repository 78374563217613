import { Card } from "antd";
import React from "react";
import DisputeHeader from "../Common/DisputeHeader";
import TimelineHOC from "../Timeline/ShowHOC";

export default function ({ headerProps }) {
  return (
    <Card>
      <DisputeHeader {...headerProps}>
        <TimelineHOC isEntireHistory={true} />
      </DisputeHeader>
    </Card>
  );
}
