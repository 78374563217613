import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getShowSod } from "../../redux/actions/responseFilingActions";
import * as selectors from "../../redux/selectors/responseFilingSelector";
import Spinner from "../Common/Spinner";
import SodRender from "./SodRender";

export default function () {
  const { disputeId } = useParams();
  const sod = useSelector(selectors.selectSod);
  const dispatch = useDispatch();

  useEffect(() => void dispatch(getShowSod(disputeId)), [disputeId, dispatch]);

  return sod ? <SodRender fileUrl={sod.fileUrl} /> : <Spinner />;
}
