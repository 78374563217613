import { Card, Divider, Typography } from "antd";
import React from "react";
import Translation from "../../Common/Translation";
import LastUpdateForm from "./LastUpdateForm";
import TaskTable from "./TaskTable";

const { Title } = Typography;

export default function TaskListSection({
  group,
  lastUpdateProps,
  taskListProps,
  hasModifiedActivity,
  hasModifiedTask,
}) {
  return (
    <div>
      <Card>
        <Title level={4}>
          <Translation text={group} />
        </Title>
        <LastUpdateForm
          onSave={() => lastUpdateProps?.onSaveLastUpdate(group)}
          lastUpdate={lastUpdateProps[`${group.toLowerCase()}LastUpdate`]}
          onUpdate={(values) =>
            lastUpdateProps.onLastUpdateFormUpdate(group, values)
          }
          isModified={hasModifiedActivity}
        />
        <Divider />
        <TaskTable
          tasks={taskListProps[`${group.toLowerCase()}TaskList`]}
          onUpdateTasks={(tasks) =>
            taskListProps.onTaskListFormUpdate(group, tasks)
          }
          onSave={() => taskListProps.onSaveTaskList(group)}
          isModified={hasModifiedTask}
        />
      </Card>
      <br />
    </div>
  );
}
