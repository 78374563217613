import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  errorMessage,
  successMessage,
} from "../../components/Common/Feedback/Message";
import * as actions from "../actions/hubActions";
import * as apis from "../apis/hubsApi";
import * as types from "../constants/constants";

const tag = "hubs";

export function* getIndex(action) {
  try {
    const res = yield call(apis.index);
    yield put(actions.handleIndex(res));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getProfile(action) {
  console.log("Hub getProfile", action);
  try {
    if (!Boolean(action.payload?.attributes?.url))
      throw new Error("Hub Slug Missing");
    // const res = yield call(apis.profile, action.payload?.attributes?.url);
    // yield put(actions.handleProfile(res));
  } catch (e) {
    console.error(tag, "saga getProfile error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getArbitrators(action) {
  try {
    if (!Boolean(action.payload?.attributes?.url))
      throw new Error("Hub Slug Missing");
    const res = yield call(apis.arbitrators, action.payload?.attributes?.url);
    yield put(actions.handleArbitrators(res));
  } catch (e) {
    console.error(tag, "saga getArbitrators error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getRosterIndex(action) {
  try {
    const res = yield call(apis.rosterIndex, action.page);
    yield put(actions.handleRosterIndex(res));
  } catch (e) {
    console.error(tag, "saga getRosterIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getRosterProfile(action) {
  try {
    const res = yield call(apis.rosterProfile, action.payload.profileId);
    yield put(actions.handleRosterProfile(res));
  } catch (e) {
    console.error(tag, "saga getRosterProfile error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postInviteArbitrator(action) {
  try {
    const res = yield call(apis.inviteArbitrator, action.payload);
    successMessage("roster.invitation_sent.text");
    yield put(push(`/roster`));
    yield put(actions.handleInviteArbitrator(res));
  } catch (e) {
    console.error(tag, "saga postInviteArbitrator error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateRosterProfile(action) {
  const { profileId, body, resetForm = () => {} } = action.payload;
  try {
    yield call(apis.updateRosterProfile, profileId, body);
    resetForm();
    successMessage("general.updated.message");
    yield put(actions.getRosterProfile(profileId));
  } catch (e) {
    errorMessage("general.failed_to_update.message");
    console.error(tag, "saga putUpdateRosterProfile error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* () {
  yield takeLatest(types.HUB_GET_INDEX, getIndex);
  yield takeLatest(types.HUB_GET_ROSTER_INDEX, getRosterIndex);
  yield takeLatest(types.HUB_HANDLE_UPDATE, getProfile);
  yield takeLatest(types.HUB_GET_ARBITRATORS, getArbitrators);
  yield takeLatest(types.HUB_POST_INVITE_ARBITRATOR, postInviteArbitrator);
  yield takeLatest(types.HUB_GET_ROSTER_PROFILE, getRosterProfile);
  yield takeLatest(types.HUB_PUT_UPDATE_ROSTER_PROFILE, putUpdateRosterProfile);
}
