export const selectArbitrators = (state) => state.hub.arbitrators;
export const selectIndex = (state) => state.hub.hubs;
export const selectRosterIndex = (state) => state.hub.rosterIndex;
export const selectRosterIndexMeta = (state) => state.hub.rosterIndexMeta;
export const selectRosterProfile = (state) => state.hub.rosterProfile;
export const selectCurrent = (state) => state.hub.hub;
export const selectProfile = (state) => state.hub.profile;
export const selectCurrentSlug = (state) => state.hub.hub?.attributes?.url;
export const selectHubPaymentMethod = (state) =>
  state.hub.profile?.paymentMethod;
