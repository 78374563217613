import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Select } from "formik-antd";
import React from "react";
import { disputeRoleSelectOptions } from "../../helpers/disputeHelpers";
import { errorAsExtra } from "../../helpers/errorHelpers";
import { toFormItem } from "../../helpers/formHelpers";
import Anchor from "../Common/Anchor";
import FormQuestion, { QuestionTitle } from "../Common/FormQuestion";
import Translation from "../Common/Translation";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";

function InviteDefenceRender({
  invites = [],
  t,
  errors,
  errorProps,
  onUpdateForm,
  onAddInviteItem,
  onUpdateInvitesForm,
  onRemoveInviteItem,
  defenceTeam,
  defenderOptions,
  onSubmit,
  inviteeErrors,
}) {
  return (
    <Formik initialValues={defenceTeam || {}}>
      {({ values, setFieldValue }) => {
        const formItemProps = {
          values,
          setFieldValue,
          propogateValues: onUpdateForm,
          errors,
          errorProps,
        };

        return (
          <LeftSidebarLayout
            navbar={true}
            sidebarContent={<SidebarContent />}
            mainContent={
              <Form layout="vertical">
                <Row justify="space-around" align="middle">
                  <Col span={17}>
                    <br />
                    <br />
                    {toFormItem(formItemProps)({
                      optional: true,
                      name: "role",
                      customLabel: (
                        <FormQuestion
                          title={
                            <Translation text="response_filing.whats_your_role.title" />
                          }
                          description={
                            <>
                              <Translation text="response_filing.whats_your_role.description" />
                            </>
                          }
                        />
                      ),
                      component: Select,
                      componentProps: {
                        children: roleOptions().map((item, i) => (
                          <Select.Option key={i} value={item.value}>
                            {item.label}
                          </Select.Option>
                        )),
                        placeholder: "Select your role",
                      },
                    })}
                    <br />
                    <div className="jur-invite-user-list-heading">
                      <QuestionTitle text="claimant.invite_your_team.text" />
                    </div>
                    <Button
                      onClick={onAddInviteItem}
                      icon={<PlusOutlined />}
                      className="primary-color"
                    >
                      <Translation
                        pad={true}
                        text="claimant.add_new_invite.button"
                      />
                    </Button>
                    {invites.map((invite, index) => (
                      <Formik initialValues={invite}>
                        {({ values, setFieldValue }) => (
                          <div>
                            <Form layout="inline">
                              {inviteItems({
                                t,
                                propogateValues: onUpdateInvitesForm(index),
                                values,
                                setFieldValue,
                                onRemove: onRemoveInviteItem(index),
                                errors: inviteeErrors[index],
                                errorProps,
                                index,
                                count: invites.length,
                              })}
                            </Form>
                          </div>
                        )}
                      </Formik>
                    ))}
                    <br />
                    <br />
                    {toFormItem(formItemProps)({
                      optional: true,
                      name: "defender",
                      customLabel: (
                        <FormQuestion
                          title={
                            "Select the team member who will be responsible for filing the response to request for arbitration"
                          }
                          description={
                            <>
                              {
                                "A response should be filed within 14 days, mentioning the counterclaims, if any. Your team can prepare a more detailed statement of defence and provide arguments for counterclaims at a later stage."
                              }
                            </>
                          }
                        />
                      ),
                      component: Select,
                      componentProps: {
                        children: defenderOptions.map((item, i) => (
                          <Select.Option key={i} value={item.value}>
                            {item.label}
                          </Select.Option>
                        )),
                        placeholder: "Select your role",
                      },
                    })}
                  </Col>
                  <Col span={17}>
                    <Row align="middle" justify="end">
                      <Col>
                        <Button
                          type="primary"
                          onClick={onSubmit}
                          disabled={
                            values?.role === undefined ||
                            values?.defender === undefined
                          }
                        >
                          <Translation text="general.next.button" pad={true} />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            }
          />
        );
      }}
    </Formik>
  );
}

export default InviteDefenceRender;

const SidebarContent = () => (
  <>
    <Typography.Title level={1}>
      <Translation text="response_filing.lets_setup_the_defence_team.title" />
    </Typography.Title>
    <Typography.Title level={5}>
      <Translation text="response_filing.lets_setup_the_defence_team.text" />
      <br />
      <br />
      <Translation text="response_filing.more_information_about_roles.text" />
      <Anchor href="about:blank">here</Anchor>.
    </Typography.Title>
  </>
);

function roleOptions() {
  return [
    { label: "Legal Representative", value: "Legal Representative" },
    { label: "Team Member", value: "Team Member" },
  ];
}

export function inviteItems({
  t,
  propogateValues,
  values,
  setFieldValue,
  onRemove,
  errors,
  errorProps,
  index,
  count,
}) {
  const { isHidden } = errorProps;
  const apiErrorOf = errorAsExtra(errors, isHidden);
  function handleSelect(value) {
    const name = "role";
    setFieldValue(name, value);
    propogateValues({ ...values, [name]: value }, name, value);
  }

  return (
    <Row
      className={`jur-invite-user-list-item ${
        count === index + 1 ? "jur-invite-user-list-last-item" : ""
      }`}
    >
      <Col span={24}>
        <div className="jur-invite-user-list-item-label primary-color">
          <Typography.Text>
            <Translation text="general.member.text" /> {index + 1}
          </Typography.Text>
        </div>
      </Col>
      <Col span={22}>
        <Row gutter={[16, 16]}>
          {[{ name: "email", placeholder: "Email", type: "email" }]
            .map(
              toFormItem({
                values,
                setFieldValue,
                propogateValues,
                errors,
                errorProps,
                compact: true,
              })
            )
            .concat(
              [
                <FormItem key="last" name="role" {...apiErrorOf("role", true)}>
                  <Select
                    name="role"
                    placeholder={t("general.choose_role.text")}
                    onSelect={handleSelect}
                  >
                    {disputeRoleSelectOptions(t)}
                  </Select>
                </FormItem>,
              ].filter(Boolean)
            )
            .map((f) => (
              <Col span={12}>{f}</Col>
            ))}
        </Row>
      </Col>
      <Col span={2} className="jur-invite-user-list-delete-icon">
        <Button onClick={onRemove} type="text" key="remove-button">
          <DeleteOutlined />
        </Button>
      </Col>
    </Row>
  );
}
