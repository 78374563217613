import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  putUpdateEnvelope,
  putUpdateEnvelopeAt,
} from "../../redux/actions/signActions";
import Spinner from "../Common/Spinner";

export function RedirectConsent({ location }) {
  const route = new URLSearchParams(location.search).get("state");
  const history = useHistory();
  history.push(route);
  return "redirecting...";
}

export function AfterSign({ location }) {
  const params = new URLSearchParams(location.search);
  const envelopeGuid = params.get("envelopeGuid");
  const signerId = params.get("signerId");
  const event = params.get("event");
  const hubSlug = params.get("hubSlug");
  const disputeId = params.get("disputeId");
  const flow = params.get("flow");
  const signableId = params.get("signableId");
  const envelopeId = params.get("envelopeDatabaseId");
  const dispatch = useDispatch();

  useEffect(
    () =>
      void dispatch(
        putUpdateEnvelope(
          envelopeGuid,
          envelopeId,
          signerId,
          event,
          hubSlug,
          disputeId,
          flow,
          signableId
        )
      ),
    [
      dispatch,
      envelopeGuid,
      envelopeId,
      signerId,
      event,
      hubSlug,
      disputeId,
      flow,
      signableId,
    ]
  );

  return <Spinner />;
}

export function AfterSend({ location }) {
  const params = new URLSearchParams(location.search);
  const envelopeGuid = params.get("envelopeId");
  const envelopeId = params.get("envelopeDatabaseId");
  const dispatch = useDispatch();
  const hubSlug = params.get("hubSlug");
  const disputeId = params.get("disputeId");
  const flow = params.get("flow");
  const signableId = params.get("signableId");
  const event = params.get("event");

  useEffect(
    () =>
      void dispatch(
        putUpdateEnvelopeAt(
          envelopeGuid,
          envelopeId,
          hubSlug,
          disputeId,
          flow,
          signableId,
          event
        )
      ),
    [
      dispatch,
      envelopeGuid,
      envelopeId,
      flow,
      hubSlug,
      disputeId,
      signableId,
      event,
    ]
  );

  return <Spinner />;
}

export default { RedirectConsent, AfterSign, AfterSend };
