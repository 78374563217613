import {
  AWAITING_SUBMISSION_FROM_USER,
  REJECTED,
} from "../constants/KYCStatuses";

export const whole = (state) => state.disputeUser;
export const selectIndex = (state) => state.disputeUser.disputeUsers;
export const indexRequestPayload = (state) =>
  state.disputeUser.indexRequestPayload || {};
export const selectCurrent = (state) => state.disputeUser.disputeUser;
export const selectErrors = (state) => state.disputeUser.errors;

export const selectHubAdmin = (state) => selectIndex(state)?.hubAdmin;
export const selectArbitrator = (state) => selectIndex(state)?.arbitrator;
export const selectParticipants = (state) => selectIndex(state)?.participants;
export const selectExperts = (state) => selectIndex(state)?.experts;

export const selectClaimants = (state) =>
  (selectParticipants(state) || []).filter((p) => p.userGroup === "Claimant");

export const selectRespondents = (state) =>
  (selectParticipants(state) || []).filter((p) => p.userGroup === "Respondent");

export const selectIsKycPending = (state) =>
  [selectParticipants, selectArbitrator, selectExperts]
    .map((fn) => fn(state))
    .reduce((all, users) => all.concat(users), [])
    .filter(Boolean)
    .some(
      (user) => user.kycStatus === AWAITING_SUBMISSION_FROM_USER || REJECTED
    );
