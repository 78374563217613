import { call, put, takeLatest } from "redux-saga/effects";
import * as apis from "../apis/genericApi";
import { BaseService } from "../BaseService";
import * as types from "../constants/constants";

const actionList = Object.values(types);

const getApiString = (type) => {
  return {
    PROCEDURALORDER: "procedural-orders",
  }[type?.split(/_(.+)/)[0]];
};
const getFeature = (type) => {
  return {
    PROCEDURALORDER: "proceduralOrder",
  }[type?.split(/_(.+)/)[0]];
};

export function* fetchIndex(action) {
  const { disputeId, page } = action.payload;
  const feature = getApiString(action.type);
  const proceduralOrderService = new BaseService(getFeature(action.type));
  try {
    const res = yield call(apis.index, disputeId, feature, page);
    yield put(proceduralOrderService.setIndex(res.data));
  } catch (e) {
    console.error("saga fetchIndex error:", e);
    yield put(proceduralOrderService.handleError(e));
  }
}

export function* fetchCurrent(action) {
  const { disputeId, orderId, params } = action.payload;
  const feature = getApiString(action.type);
  const proceduralOrderService = new BaseService(getFeature(action.type));
  try {
    const res = yield call(apis.show, disputeId, feature, orderId, params);
    yield put(proceduralOrderService.setCurrent(res.data));
  } catch (e) {
    console.error("saga fetchIndex error:", e);
    yield put(proceduralOrderService.handleError(e));
  }
}

export default function* () {
  yield takeLatest(
    actionList.filter((x) => x.endsWith("FETCH_INDEX")),
    fetchIndex
  );

  yield takeLatest(
    actionList.filter((x) => x.endsWith("FETCH_CURRENT")),
    fetchCurrent
  );
}
