import { Button, Col, Row, Typography } from "antd";
import React from "react";
import Translation from "../../components/Common/Translation";
import PageHeader from "../Common/PageHeader";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import FileUploadForm from "./FileUploadForm";
import "./styles.less";
import TableOverview from "./TableOverview";
import NoData from "../Common/NoData";
import Spinner from "../Common/Spinner";
import CaseFilesBreadcrumb from "../Common/CaseFilesBreadcrumb";

export default function ({
  errors,
  pasthearings,
  completedHearings,
  isArbitrator,
  disputeParams,
  HearingFiles,
  isLoading,
  isUploading,
  isUploadFormVisible,
  pagination,
  handleSendDocuments,
  openSendDocuments,
  closeSendDocuments,
}) {
  return (
    <SliderMenuWrapper {...disputeParams}>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={24}>
          <CaseFilesBreadcrumb />
          <PageHeader
            title={"case_files.hearing.text"}
            cta={
              isArbitrator && (
                <Button type="primary" onClick={openSendDocuments}>
                  <Translation text="case_files.new_upload_hearing.text" />
                </Button>
              )
            }
          />
        </Col>
        <Col className="gutter-row" span={24}>
          {!HearingFiles ? (
            <div className="jur-tor-spinner">
              <Spinner />
            </div>
          ) : HearingFiles.length ? (
            <TableOverview data={HearingFiles} whiteHeader={true} isLoading={isLoading} pagination={pagination} />
          ) : (
            <NoData
              description={
                <Typography.Title level={5} strong>
                  <Translation text="case_files.no_files.text" />
                </Typography.Title>
              }
            />
          )}
        </Col>
      </Row>
      {isUploadFormVisible && (
        <FileUploadForm
          visible={isUploadFormVisible}
          isLoading={isUploading}
          completedHearings={completedHearings}
          closeSendDocuments={closeSendDocuments}
          handleSendDocuments={handleSendDocuments}
        />
      )}
    </SliderMenuWrapper>
  );
}
