import { Button, Card, Col, Popconfirm, Row, Space } from "antd";
import React from "react";
import CompletedStep from "../Common/CompletedStep";
import Translation from "../Common/Translation";
import FilingSteps from "../DisputeFiling/FilingSteps";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";
import SignatureButton from "../Signs/SignatureButton";
import Documents from "./DocumentsHOC";
import Invite from "./InviteHOC";
import Respondent from "./RespondentHOC";
import Response from "./ResponseHOC";
import ShowSidebar from "./ShowSidebar";
import Sod from "./SodHOC";
import "./styles.less";

export default function ({
  currentStep = 0,
  isNextDisabled,
  next,
  onSaveAndBack,
  onDiscardAndBack,
  onSaveDraft,
  goBack,
  disputeId,
  hubSlug,
  onSodClick,
  onCurrentStepChange,
}) {
  if (currentStep === 5) {
    return (
      <CompletedStep
        current={2}
        steps={completedPageSteps}
        disputeId={disputeId}
      />
    );
  }

  return (
    <LeftSidebarLayout
      navbar={true}
      sidebarContent={
        <ShowSidebar
          onSodClick={onSodClick}
          disputeId={disputeId}
          hubSlug={hubSlug}
          currentStep={currentStep}
        />
      }
      mainContent={
        <>
          <FilingSteps
            currentStep={currentStep}
            steps={steps}
            onCurrentStepChange={onCurrentStepChange}
          />
          <ResponseFilingContentWrapper currentStep={currentStep}>
            {steps[currentStep].content}
            {currentStep < 4 && (
              <BottomNavigation
                {...{
                  currentStep,
                  goBack,
                  disputeId,
                  isNextDisabled,
                  hubSlug,
                  next,
                  onSaveAndBack,
                  onDiscardAndBack,
                  onSaveDraft,
                }}
              />
            )}
          </ResponseFilingContentWrapper>
        </>
      }
    />
  );
}

const ResponseFilingContentWrapper = ({ children, currentStep }) => {
  if (currentStep === 4)
    return (
      <div className="jur-dispute-filing-signature-wrapper">{children}</div>
    );
  return (
    <div className="jur-dispute-filing-content">
      <Card>
        <Row justify="space-around" align="middle">
          <Col span={15}>{children}</Col>
        </Row>
      </Card>
    </div>
  );
};

const steps = [
  {
    title: <Translation text="response_filing.respondent.title" />,
    content: <Respondent />,
  },
  {
    title: (
      <Translation text="response_filing.response_and_counterclaims.title" />
    ),
    content: <Response />,
  },
  {
    title: <Translation text="response_filing.upload_documents.title" />,
    content: <Documents />,
  },
  {
    title: <Translation text="response_filing.invite.title" />,
    content: <Invite />,
  },
  // {
  //   title: <Translation text="response_filing.payment.title" />,
  //   content: <Payment />,
  // },
  {
    title: <Translation text="response_filing.sign.title" />,
    content: <Sod />,
  },
];

const NavigationDivider = () => <div className="jur-dispute-filing-divider" />;

const BottomNavigation = ({
  currentStep,
  goBack,
  disputeId,
  isNextDisabled,
  hubSlug,
  next,
  onSaveAndBack,
  onDiscardAndBack,
  onSaveDraft,
}) => {
  return (
    <>
      <NavigationDivider />
      <Row align="middle" justify="space-between">
        <Col>
          {[0, 1, 2, 3, 4, 5].filter(Boolean).includes(currentStep) ? (
            <Popconfirm
              title={
                <Translation text="general.do_you_want_to_save_your_changes.action" />
              }
              onConfirm={onSaveAndBack}
              onCancel={onDiscardAndBack}
              okText={<Translation text="general.save_and_back.action" />}
              cancelText={
                <Translation text="general.discard_and_back.action" />
              }
              disabled={currentStep === 0 || isNextDisabled}
            >
              <Button
                type="link"
                size="small"
                disabled={currentStep === 0 || isNextDisabled}
              >
                <Translation text="general.go_back.button" />
              </Button>
            </Popconfirm>
          ) : null}
        </Col>
        <Col>
          <Space>
            {currentStep < 4 ? (
              <Button
                loading={isNextDisabled}
                disabled={isNextDisabled}
                onClick={onSaveDraft}
              >
                <Translation text="general.save_draft.button" pad={true} />
              </Button>
            ) : null}
            {currentStep < 5 && (
              <Button
                type="primary"
                loading={isNextDisabled}
                disabled={isNextDisabled}
                onClick={next}
              >
                <Translation pad={true} text="general.next.button" />
              </Button>
            )}
            {currentStep === 5 && (
              <SignatureButton
                redirectUrl={`/${hubSlug}/response-filing/${disputeId}?step=sign`}
              >
                <Button type="primary" disabled={isNextDisabled} onClick={next}>
                  <Translation text="general.sign_now.button" pad={true} />
                </Button>
              </SignatureButton>
            )}
          </Space>
        </Col>
      </Row>
    </>
  );
};

const completedPageSteps = [
  {
    title: <Translation text="response_filing.processing.title" />,
    description: (
      <Translation text="response_filing.response_recieved.description" />
    ),
  },
  {
    title: <Translation text="response_filing.payment.title" />,
    description: (
      <Translation text="response_filing.invoice_will_be_sent.description" />
    ),
  },
  {
    title: <Translation text="response_filing.connect.title" />,
    description: (
      <Translation text="response_filing.schedule_a_call.description" />
    ),
  },
];
