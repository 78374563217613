import * as types from "../constants/constants";

export const getIndex = (disputeId) => ({
  type: types.PAYMENT_GET_INDEX,
  payload: { disputeId },
});

export const postCreate = (disputeId, body) => ({
  type: types.PAYMENT_POST_CREATE,
  payload: { disputeId, body },
});

export const postCreateTransaction = (disputeId, paymentId, body) => ({
  type: types.PAYMENT_POST_CREATE_TRANSACTION,
  payload: { disputeId, paymentId, body },
});

export const putUpdateTransaction = (
  disputeId,
  paymentId,
  transactionId,
  body
) => ({
  type: types.PAYMENT_PUT_UPDATE_TRANSACTION,
  payload: { disputeId, paymentId, transactionId, body },
});

export const handleIsOpen = (isOpen) => ({
  type: types.PAYMENT_HANDLE_IS_OPEN,
  payload: isOpen,
});

export const handleIndex = (evidenceRequests) => ({
  type: types.PAYMENT_HANDLE_INDEX,
  payload: evidenceRequests,
});

export const handleCreate = () => ({
  type: types.PAYMENT_HANDLE_CREATE,
});

export const handleCreateTransaction = () => ({
  type: types.PAYMENT_HANDLE_CREATE_TRANSACTION,
});

export const handleUpdateTransaction = () => ({
  type: types.PAYMENT_HANDLE_UPDATE_TRANSACTION,
});

export const handleError = (e) => ({
  type: types.PAYMENT_HANDLE_ERROR,
  error: e,
});
