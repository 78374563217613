export function readToken() {
  const payload = JSON.parse(localStorage.getItem("user-auth") || "{}");
  return payload.access_token;
}

export function readEmail() {
  return localStorage.getItem("signup-email");
}

export function storeEmail(email) {
  localStorage.setItem("signup-email", email);
}

export function readLastKnownEmail() {
  return localStorage.getItem("last-known-email");
}

export function storeLastKnownEmail(email) {
  localStorage.setItem("last-known-email", email);
}

export function readLoggedInUser() {
  const payload = JSON.parse(localStorage.getItem("user-auth") || "{}");
  return {
    email: payload.email,
    name: payload.name,
    user: payload,
  };
}

export function isUserActive() {
  return readLoggedInUser()?.user?.status === "Active";
}

export const passwordIconRender = (values, key) => (visible) => (values?.[key] ? (visible ? "Hide" : "Show") : null);

export const USER_AUTH = "user-auth";

function readUserAuth() {
  return JSON.parse(localStorage.getItem(USER_AUTH));
}

export function removeTokenOnLogout() {
  localStorage.removeItem(USER_AUTH);
}

export function saveToken({ access_token, token_type, access_rules, expires_in, ...rest }) {
  return localStorage.setItem(
    USER_AUTH,
    JSON.stringify({
      access_token,
      token_type,
      access_rules,
      expires_in,
      storedAt: new Date(),
      ...rest,
    })
  );
}

export function setConsentAndActivation(status = true) {
  const userAuth = readUserAuth();
  userAuth.signatureStatus.consentStatus = status;
  userAuth.signatureStatus.activationStatus = status;
  localStorage.setItem(USER_AUTH, JSON.stringify(userAuth));
}

export function saveUserProfile(properties = {}) {
  const userAuth = readUserAuth();
  saveToken({ ...userAuth, ...properties });
}

export const makeUserOnboardingCompleted = () => {
  localStorage.setItem("onboarded", true);
};

export const getUserOnboardingStatus = () => {
  if (localStorage.getItem("onboarded") === null) {
    const userAuth = JSON.parse(localStorage.getItem("user-auth"));
    localStorage.setItem("onboarded", userAuth?.status === "Active");
  }
  return JSON.parse(localStorage.getItem("onboarded"));
};
