import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "../../redux/actions/responseFilingActions";
import * as selectors from "../../redux/selectors/responseFilingSelector";
import Spinner from "../Common/Spinner";
import ResponseRender from "./ResponseRender";

export default function () {
  const dispatch = useDispatch();
  const { disputeId } = useParams();
  const errors = useSelector(selectors.selectErrors);
  const isErrorHidden = useSelector(selectors.selectIsErrorHidden);
  const responseDetails = useSelector(selectors.selectResponseDetails);
  const responseOptions = useSelector(selectors.selectResponseOptions);
  const handleUpdate = (values) =>
    dispatch(actions.handleUpdateResponseDetailsForm(values));

  useEffect(() => {
    if (disputeId) dispatch(actions.getShowResponseDetails(disputeId));
  }, [disputeId, dispatch]);

  return responseDetails ? (
    <ResponseRender
      response={responseDetails}
      errors={errors}
      errorProps={{ isHidden: isErrorHidden }}
      onUpdate={handleUpdate}
      {...responseOptions}
      {...responseOptions?.options}
    />
  ) : (
    <Spinner card={true} />
  );
}
