import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/disputeFilingActions";
import { getShowAddRespondent } from "../../redux/actions/disputeFilingActions";
import * as selectors from "../../redux/selectors/disputeFilingSelector";
import Spinner from "../Common/Spinner";
import RespondentRender from "./RespondentRender";

export default function () {
  const respondent = useSelector(selectors.selectRespondent);
  const disputeId = useSelector(selectors.selectDisputeId);
  const isErrorHidden = useSelector(selectors.selectIsErrorHidden);
  const respondentErrors = useSelector(selectors.selectErrors);
  const dispatch = useDispatch();

  useEffect(() => void dispatch(getShowAddRespondent(disputeId)), [
    disputeId,
    dispatch,
  ]);

  const handleUpdate = (values) =>
    dispatch(actions.handleUpdateAddRespondentForm(values));

  return respondent ? (
    <RespondentRender
      respondent={respondent}
      onUpdate={handleUpdate}
      errorProps={{ isHidden: isErrorHidden }}
      errors={respondentErrors}
    />
  ) : (
    <Spinner card={true} />
  );
}
