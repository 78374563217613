import { toKyc } from "../../transformers/formTransformer";
import * as types from "../constants/constants";

export const handleStartDisputeFiling = () => ({
  type: types.DISPUTEFILING_HANDLE_START,
});

export const handleStepBack = () => ({
  type: types.DISPUTEFILING_HANDLE_STEP_BACK,
});

export const handleShow = (disputeId, currentStep) => ({
  type: types.DISPUTEFILING_HANDLE_SHOW,
  payload: { disputeId, currentStep },
});

export const handleStepHub = () => ({
  type: types.DISPUTEFILING_HANDLE_STEP_HUB,
});

export const postCreate = (kyc, userInvites, mainClaimant) => {
  kyc.files = kyc.files?.filter(Boolean);
  return {
    type: types.DISPUTEFILING_POST_CREATE,
    payload: { body: { kyc, userInvites, mainClaimant } },
  };
};

export const putUpdateKyc = (disputeId, { kyc, userInvites, mainClaimant }) => {
  const sendableKyc = toKyc(kyc);
  if (sendableKyc) sendableKyc.files = sendableKyc.files?.filter(Boolean);
  return {
    type: types.DISPUTEFILING_PUT_UPDATE_KYC,
    payload: {
      disputeId,
      body: { kyc: sendableKyc, userInvites, mainClaimant },
    },
  };
};

export const handleCreate = (dispute) => ({
  type: types.DISPUTEFILING_HANDLE_CREATE,
  payload: dispute,
});

export const handleError = (e) => ({
  type: types.DISPUTEFILING_HANDLE_ERROR,
  error: e,
});

export const handleHub = (hub) => ({
  type: types.DISPUTEFILING_HANDLE_HUB,
  payload: hub,
});

// export const handleUpdateInvitesForm = (invites) => ({
//   type: types.DISPUTEFILING_HANDLE_UPDATE_INVITES_FORM,
//   payload: invites,
// });

export const handleUpdateMainClaimantForm = (mainClaimant) => ({
  type: types.DISPUTEFILING_HANDLE_UPDATE_MAINCLAIMANT_FORM,
  payload: mainClaimant,
});

export const handleUpdateClaimFilesStatus = (uid, file) => ({
  type: types.DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_STATUS,
  payload: { uid, file },
});

export const handleUpdateClaimFilesDescription = (file) => ({
  type: types.DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_DESCRIPTION,
  payload: { file },
});

function showOf(crumb) {
  return (disputeId) => ({
    type: types[`DISPUTEFILING_GET_SHOW_${crumb}`],
    payload: { disputeId },
  });
}

function updateOf(crumb) {
  return (disputeId, body, hubSlug, goBack) => {
    return {
      type: types[`DISPUTEFILING_PUT_UPDATE_${crumb}`],
      payload: { disputeId, body, hubSlug, goBack },
    };
  };
}

function handleOf(crumb) {
  return (data) => ({
    type: types[`DISPUTEFILING_HANDLE_${crumb}`],
    payload: data,
  });
}

function actionCreatorsOf(crumb) {
  const capitalizedCrumb = crumb.toUpperCase();
  return {
    [`getShow${crumb}`]: showOf(capitalizedCrumb),
    [`putUpdate${crumb}`]: updateOf(capitalizedCrumb),
    [`handleShow${crumb}`]: handleOf(`SHOW_${capitalizedCrumb}`),
    [`handleUpdate${crumb}`]: handleOf(`UPDATE_${capitalizedCrumb}`),
    [`handleUpdate${crumb}Form`]: handleOf(`UPDATE_${capitalizedCrumb}_FORM`),
  };
}

export const {
  getShowKyc,
  handleShowKyc,
  handleUpdateKyc,
  handleUpdateKycForm,
} = actionCreatorsOf("Kyc");

export const {
  getShowClaimant,
  putUpdateClaimant,
  handleShowClaimant,
  handleUpdateClaimant,
  handleUpdateClaimantForm,
} = actionCreatorsOf("Claimant");

export const {
  getShowClaimDetails,
  putUpdateClaimDetails,
  handleShowClaimDetails,
  handleUpdateClaimDetails,
  handleUpdateClaimDetailsForm,
} = actionCreatorsOf("ClaimDetails");

export const {
  getShowClaimFiles,
  putUpdateClaimFiles,
  handleShowClaimFiles,
  handleUpdateClaimFiles,
  handleUpdateClaimFilesForm,
} = actionCreatorsOf("ClaimFiles");

export const {
  getShowAddRespondent,
  putUpdateAddRespondent,
  handleShowAddRespondent,
  handleUpdateAddRespondent,
  handleUpdateAddRespondentForm,
} = actionCreatorsOf("AddRespondent");

export const {
  getShowInvites,
  putUpdateInvites,
  handleShowInvites,
  handleUpdateInvites,
  handleUpdateInvitesForm,
} = actionCreatorsOf("Invites");

export const {
  getShowPayment,
  handleShowPayment,
  handleUpdatePayment,
  handleUpdatePaymentForm,
} = actionCreatorsOf("Payment");

export const putUpdatePayment = (disputeId, totalAmount, payload) => {
  const body = {
    feeSplitPercentage: payload.feeSplitPercentage,
    transaction: {
      amount: {
        value: (payload.feeSplitPercentage / 100) * totalAmount.value,
        currency: totalAmount.currency,
      },
      reference: payload["transaction.reference"],
      receiptFileId: payload["transaction.receiptFileId"],
    },
  };

  return {
    type: types.DISPUTEFILING_PUT_UPDATE_PAYMENT,
    payload: { disputeId, body },
  };
};

export const {
  getShowSoc,
  putUpdateSoc,
  handleShowSoc,
  handleUpdateSoc,
} = actionCreatorsOf("Soc");

export const { handleUpdateCompleted } = actionCreatorsOf("Completed");

export const postCreateDispute = (body) => ({
  type: types.DISPUTEFILING_POST_CREATE_DISPUTE,
  payload: body,
});

export const handleCreateDispute = (data) => {
  return {
    type: types.DISPUTEFILING_HANDLE_CREATE_DISPUTE,
    payload: data,
  };
};

export const putUpdateCompleted = (disputeId, extras) => ({
  type: types.DISPUTEFILING_PUT_UPDATE_COMPLETED,
  payload: { disputeId, ...extras },
});

// export const putUpdateClaimDetails = (disputeId, claim) => {
//   return {
//     type: types.DISPUTEFILING_PUT_UPDATE_CLAIMDETAILS,
//     payload: { disputeId, body: claim },
//   };
// };

export const handleSignatureError = (disputeId, params) => ({
  type: types.DISPUTEFILING_HANDLE_SIGNATURE_ERROR,
  payload: { disputeId, params },
});

export const handleUpdateUserRole = (role) => ({
  type: types.DISPUTEFILING_HANDLE_UPDATE_USER_ROLE,
  payload: { userRole: role },
});

export const handleStepChange = (step) => ({
  type: types.DISPUTEFILING_HANDLE_STEP_CHANGE,
  payload: { step },
});
