import { Button, Card, Space, Table, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { fromMeta } from "../../transformers/paginationTransformer";
import DateTime from "../Common/DateTime";
import DisputeHeader from "../Common/DisputeHeader";
import Spinner from "../Common/Spinner";
import TimelineWrapper from "../Common/TimelineWrapper";
import Translation from "../Common/Translation";
import RequestNewDocumentForm from "./RequestNewDocumentForm";
import SendDocumentsForm from "./SendDocumentsForm";

export default function ({
  dispute,
  disputeId,
  hubSlug,
  evidenceRequests,
  canSendRequest,
  openRequestDocument,
  closeRequestDocument,
  isRequestDocumentVisible,
  handleDocumentRequest,
  canUpdateRequest,
  isSendDocumentsVisible,
  openSendDocuments,
  closeSendDocuments,
  handleSendDocuments,
  extraCrumbs,
  isLoading,
  isFetchingTor,
  individualRequestList,
  isWitness,
  errors,
  onPageChange,
  evidenceRequestsMeta,
  redirectUrl,
  deleteFile,
}) {
  const uploadableRequests = evidenceRequests?.filter(
    (r) => r?.attributes?.status === "Requested" && r?.attributes?.canUpload
  );
  const renderTable = (list) => {
    if (list && list?.length) {
      return (
        <Table
          columns={columns(disputeId, hubSlug, isWitness)}
          dataSource={list}
          rowKey="id"
          pagination={fromMeta(evidenceRequestsMeta, (page) => {
            onPageChange(page);
          })}
        />
      );
    } else {
      return (
        <center>
          <Typography.Paragraph type="secondary">
            <Translation text="evidence_request.not_uploaded.description" />
          </Typography.Paragraph>
        </center>
      );
    }
  };

  return (
    <TimelineWrapper
      activityId={disputeId}
      activityType="Evidence Request Index"
    >
      <Card>
        <DisputeHeader
          hubSlug={hubSlug}
          disputeId={disputeId}
          dispute={dispute}
          extraCrumbs={extraCrumbs}
        >
          <Space direction="vertical">
            <Typography.Title level={3}>
              <Translation text="evidence_request.evidence_request.title" />
            </Typography.Title>
            {canSendRequest ? (
              <>
                <Button type="primary" onClick={openRequestDocument}>
                  <Translation text="evidence_request.request_new_button.button" />
                </Button>
              </>
            ) : null}
            {canUpdateRequest && uploadableRequests.length ? (
              <Button type="primary" onClick={openSendDocuments}>
                <Translation text="evidence_request.send_documents.text" />
              </Button>
            ) : null}
          </Space>
          <br />
          <br />
          {isFetchingTor ? (
            <Spinner card={true} />
          ) : (
            renderTable(evidenceRequests)
          )}
        </DisputeHeader>
        <RequestNewDocumentForm
          isLoading={isLoading}
          witnessList={individualRequestList}
          handleDocumentRequest={handleDocumentRequest}
          visible={isRequestDocumentVisible}
          closeRequestDocument={closeRequestDocument}
          errors={errors}
        />
        <SendDocumentsForm
          redirectUrl={redirectUrl}
          errors={errors}
          isLoading={isLoading}
          visible={isSendDocumentsVisible}
          closeSendDocuments={closeSendDocuments}
          handleSendDocuments={handleSendDocuments}
          evidenceRequests={uploadableRequests}
          deleteFile={deleteFile}
        />
      </Card>
    </TimelineWrapper>
  );
}

const columns = (disputeId, hubSlug, isWitness) =>
  [
    {
      title: <Translation text="evidence_request.requested_id.column" />,
      dataIndex: "id",
    },
    !isWitness
      ? {
          title: <Translation text="evidence_request.requested_from.column" />,
          dataIndex: ["attributes", "sentTo"],
        }
      : null,
    {
      title: <Translation text="evidence_request.requested_on.column" />,
      render: (_, record) => {
        return <DateTime epochSeconds={record.attributes.createdAt} />;
      },
    },
    {
      title: <Translation text="evidence_request.deadline.column" />,
      render: (_, record) => {
        return <DateTime epochSeconds={record.attributes.deadline} />;
      },
    },
    {
      title: <Translation text="evidence_request.requires_signature.column" />,
      render: (_, record) =>
        record?.attributes?.requireSignature ? "Yes" : "No",
    },
    {
      title: <Translation text="evidence_request.last_update.column" />,
      dataIndex: ["attributes", "status"],
    },
    {
      title: <Translation text="evidence_request.actions.column" />,
      render: (_, record) => {
        return (
          <Link
            to={`/${hubSlug}/disputes/${disputeId}/evidence-requests/${record.id}`}
          >
            <Translation text="general.view.button" />
          </Link>
        );
      },
    },
  ].filter(Boolean);
