import { Button } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { errorAsExtra } from "../../helpers/errorHelpers";
import { forgotPassword } from "../../redux/actions/authenticateActions";
import { selectErrors } from "../../redux/selectors/authSelector";
import Translation from "../Common/Translation";
import AuthLayout from "../Layouts/AuthLayout";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";
import AuthFormItem from "./AuthFormItem";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const errors = useSelector(selectErrors);
  const [isHidden, setIsHidden] = useState(false);

  const extraProps = errorAsExtra(errors, isHidden);

  return (
    <LeftSidebarLayout
      mainContent={
        <AuthLayout
          header={
            <div className="jur-sign-up-text">
              <Link className="jur-link" to="/authenticate">
                <Translation text="authentication.sign_in.text" />
              </Link>
            </div>
          }
          content={
            <>
              <div className="jur-auth-title">
                <Translation text="general.forgot_password.title" />
              </div>
              <div className="jur-auth-description">
                Enter your email address and we will send you instructions on
                how to create a new password.
              </div>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={(values) => {
                  dispatch(forgotPassword(values));
                  setIsHidden(false);
                }}
              >
                {({ handleChange, values }) => {
                  const hideOnChange = (e) => {
                    setIsHidden(true);
                    handleChange(e);
                  };

                  const fields = [
                    {
                      name: "email",
                      label: (
                        <Translation text="authentication.email_address.text" />
                      ),
                      ...extraProps("email"),
                      component: <Input name="email" onChange={hideOnChange} />,
                    },
                  ];

                  return (
                    <Form layout="vertical">
                      {fields.map((formItem) => (
                        <AuthFormItem {...formItem} />
                      ))}
                      <Button
                        disabled={values.email === ""}
                        className="jur-auth-submit-button"
                        type="primary"
                        htmlType="submit"
                        size="large"
                      >
                        <Translation text="general.submit.button" />
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </>
          }
        />
      }
    />
  );
}
