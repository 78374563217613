import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/disputeFilingActions";
import * as selectors from "../../redux/selectors/disputeFilingSelector";
import Spinner from "../Common/Spinner";
import ClaimRender from "./ClaimRender";

export default function () {
  const disputeId = useSelector(selectors.selectDisputeId);
  const metaOptions = useSelector(selectors.selectClaimOptions);
  const isErrorHidden = useSelector(selectors.selectIsErrorHidden);
  const claim = useSelector(selectors.selectClaim);
  const dispatch = useDispatch();
  const errors = useSelector(selectors.selectErrors);

  const handleUpdate = (values) =>
    dispatch(actions.handleUpdateClaimDetailsForm(values));

  useEffect(() => void dispatch(actions.getShowClaimDetails(disputeId)), [
    disputeId,
    dispatch,
  ]);

  return disputeId && claim ? (
    <ClaimRender
      claim={claim}
      onUpdate={handleUpdate}
      errors={errors}
      errorProps={{ isHidden: isErrorHidden }}
      {...metaOptions}
    />
  ) : (
    <Spinner card={true} />
  );
}
