import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import monitorReducersEnhancer from "./enhancers/monitorReducers";
import logger from "./middlewares/logger";
import rootReducer from "./reducers/rootReducer";
import rootSaga from "./sagas/rootSaga";

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [logger, sagaMiddleware, routerMiddleware(history)];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(
    // persistedReducer,
    // preloadedState,
    rootReducer(history),
    composedEnhancers
  );
  // let persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store };
}
