import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {};

export default function (state = initial, action) {
  switch (action.type) {
    case types.DISPUTEFILES_GET_INDEX:
      return { ...state, isLoading: true, disputeFiles: null };
    case types.DISPUTEFILES_HANDLE_INDEX:
      return {
        ...state,
        disputeFiles: action.payload.data,
        disputeFilesMeta: action.payload.meta,
        isUploading: false,
        isLoading: false,
        error: {},
      };
    case types.DISPUTEFILES_HANDLE_ERROR:
      return {
        ...state,
        error: errorMessages(action.error),
        isUploading: false,
        isLoading: false,
      };
    case types.DISPUTEFILES_POST_CREATE:
      return {
        ...state,
        isUploading: true,
      };
    default:
      return state;
  }
}
