import { Tag } from "antd";
import React from "react";
import {
  SENT_TO_HUB,
  SENT_TO_PARTIES,
  EDITS_REQUESTED,
  HUB_APPROVED,
  HUB_REQUESTED_EDITS,
  HUB_APPROVED_WITHOUT_EDITS,
} from "../../redux/constants/ArbitralAwardStatuses";

export default function ({ status }) {
  return <Tag color={color(status)}>{status}</Tag>;
}

function color(status) {
  return {
    [EDITS_REQUESTED]: "red",
    [HUB_REQUESTED_EDITS]: "red",
    [SENT_TO_HUB]: "yellow",
    [HUB_APPROVED]: "green",
    [HUB_APPROVED_WITHOUT_EDITS]: "green",
    [SENT_TO_PARTIES]: "green",
    // [SIGNATURE_COMPLETED]: "green",
  }[status];
}

export function arbAwardStatusColor(status) {
  return color(status);
}
