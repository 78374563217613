import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {
  isFetching: false,
  isFetchingShow: false,
};

export default function arbitrator(state = initial, action) {
  switch (action.type) {
    case types.ARBITRATOR_GET_INDEX:
      return { ...state, list: null, isFetching: true };
    case types.ARBITRATOR_HANDLE_INDEX:
      return {
        ...state,
        list: action.payload?.data.map((a) => ({
          ...a?.attributes,
          id: a?.id,
        })),
        isFetching: false,
      };
    case types.ARBITRATOR_GET_SHOW:
      return { ...state, currentArbitrator: null, isFetchingShow: true };
    case types.ARBITRATOR_HANDLE_SHOW:
      return {
        ...state,
        currentArbitrator: {
          id: action.payload?.data?.id,
          ...action.payload?.data?.attributes,
        },
        isFetchingShow: false,
      };
    case types.ARBITRATOR_HANDLE_ERROR:
      return {
        ...state,
        error: errorMessages(action.error),
        isFetching: false,
        isFetchingShow: false,
      };
    default:
      return state;
  }
}
