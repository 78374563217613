import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { getInlineShow } from "../../redux/actions/fileActions";
import { fetchCurrent } from "../../redux/actions/genericActions";
import {
  postCreateRevision,
  putUpdate,
} from "../../redux/actions/proceduralOrderActions";
import { postCreateSignature } from "../../redux/actions/signActions";
import { BaseService } from "../../redux/BaseService";
import { selectFile } from "../../redux/selectors/filesSelector";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import ShowRender from "./ShowRender";

export default function () {
  const feature = "proceduralOrder";
  const proceduralOrderService = new BaseService(feature);

  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();
  const { orderId } = useParams();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const order = useSelector(proceduralOrderService.select("current"));
  const errors = useSelector(proceduralOrderService.select("errors"));

  const file = useSelector(selectFile);
  const fileId = order?.attributes?.file?.id;

  const {
    isShareOrderOpen,
    isUploadRevisedOrderOpen,
    isReviewOrderOpen,
  } = useSelector(proceduralOrderService.selectWhole());

  const handleShareOrderOpen = () => {
    void dispatch(
      postCreateSignature({
        fileIds: [order?.attributes?.file?.id],
        flow: "Procedural Order",
        signType: "Self Sign",
        uiRedirectUrl: `/${hubSlug}/disputes/${disputeId}/procedural-orders`,
        disputeId,
        hubSlug,
        signableId: order?.id,
      })
    );
  };

  const handleShareOrderClose = () =>
    void dispatch(proceduralOrderService.setForm({ isShareOrderOpen: false }));

  const handleShareOrderSubmit = (resetForm) => {
    void dispatch(
      postCreateSignature({
        fileIds: [order?.attributes?.file?.id],
        flow: "Procedural Order",
        signType: "Self Sign",
        uiRedirectUrl: `/${hubSlug}/disputes/${disputeId}/procedural-orders`,
        disputeId,
        hubSlug,
        signableId: order?.id,
      })
    );
  };

  const handleUploadRevisedOrderOpen = () =>
    void dispatch(
      proceduralOrderService.setForm({ isUploadRevisedOrderOpen: true })
    );

  const handleUploadRevisedOrderClose = () =>
    void dispatch(
      proceduralOrderService.setForm({ isUploadRevisedOrderOpen: false })
    );

  const handleUploadRevisedOrderSubmit = (body, resetForm) => {
    void dispatch(postCreateRevision(disputeId, body, resetForm));
  };

  const handleReviewOrderOpen = () =>
    void dispatch(proceduralOrderService.setForm({ isReviewOrderOpen: true }));

  const handleReviewOrderClose = () =>
    void dispatch(proceduralOrderService.setForm({ isReviewOrderOpen: false }));

  const handleReviewOrderSubmit = (body, resetForm) => {
    void dispatch(putUpdate(disputeId, body, resetForm));
  };

  const handleShowLatest = () => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete("version");
    history.replace({
      search: queryParams.toString(),
    });
    dispatch(fetchCurrent(feature)(disputeId, orderId));
  };

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/procedural-orders`,
      breadcrumbName: (
        <Translation text="procedural_order.procedural_order.title" />
      ),
    },
    {
      path: `/${hubSlug}/disputes/${disputeId}/procedural-orders/${orderId}`,
      breadcrumbName: `Procedural Order #${order?.attributes?.number}`,
    },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const version = queryParams.get("version");
    dispatch(
      fetchCurrent(feature)(
        disputeId,
        orderId,
        version ? `?version=${version}` : ""
      )
    );
  }, [disputeId, dispatch, orderId, location.search]);

  useEffect(() => {
    if (fileId) dispatch(getInlineShow(fileId));
  }, [fileId, dispatch]);

  return dispute && order ? (
    <ShowRender
      hubSlug={hubSlug}
      disputeId={disputeId}
      dispute={dispute}
      extraCrumbs={extraCrumbs}
      order={order}
      file={file}
      isShareOrderOpen={isShareOrderOpen}
      handleShareOrderOpen={handleShareOrderOpen}
      handleShareOrderClose={handleShareOrderClose}
      handleShareOrderSubmit={handleShareOrderSubmit}
      isUploadRevisedOrderOpen={isUploadRevisedOrderOpen}
      handleUploadRevisedOrderOpen={handleUploadRevisedOrderOpen}
      handleUploadRevisedOrderClose={handleUploadRevisedOrderClose}
      handleUploadRevisedOrderSubmit={handleUploadRevisedOrderSubmit}
      isReviewOrderOpen={isReviewOrderOpen}
      handleReviewOrderOpen={handleReviewOrderOpen}
      handleReviewOrderClose={handleReviewOrderClose}
      handleReviewOrderSubmit={handleReviewOrderSubmit}
      handleShowLatest={handleShowLatest}
      errors={errors}
    />
  ) : (
    <Spinner card={true} />
  );
}
