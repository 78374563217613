import * as types from "../constants/constants";

const initial = {
  isRedirecting: false,
  redirected: false,
  concentUrl: null,
};

export default function (state = initial, action) {
  switch (action.type) {
    case types.SIGN_HANDLE_CONSENT_URL:
      return { ...state, concentUrl: action.payload, redirected: true };
    case types.SIGN_HANDLE_CREATE_ENVELOPE:
      return { ...state, envelope: action.payload };
    case types.SIGN_HANDLE_IS_REDIRECTING:
      return { ...state, isRedirecting: action.payload };
    default:
      return state;
  }
}
