import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { readLastKnownEmail } from "../../helpers/authHelpers";
import { forgotPassword } from "../../redux/actions/authenticateActions";
import Translation from "../Common/Translation";
import AuthLayout from "../Layouts/AuthLayout";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";
import "./authenticate.less";

export default function ForgotPasswordConfirm() {
  const dispatch = useDispatch();

  const resendEmail = () => {
    const email = readLastKnownEmail();
    dispatch(forgotPassword({ email }));
  };

  const ConfirmForgotPassword = () => (
    <>
      <div className="jur-auth-title">
        <Translation text="authenticate.reset_password.title" />
      </div>
      <br />
      <div className="jur-auth-description">
        <Translation text="authenticate.reset_password.description" />
        <br />
        <Translation text="authenticate.haven't_received.description" />{" "}
        <Link onClick={resendEmail}>
          <Translation text="authenticate.click_here.button" />
        </Link>{" "}
        <Translation text="authenticate.resend.description" />
      </div>
    </>
  );

  return (
    <LeftSidebarLayout
      mainContent={
        <AuthLayout header={<div />} content={<ConfirmForgotPassword />} />
      }
    />
  );
}
