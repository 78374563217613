import * as types from "../constants/constants";

/**
 *
 * @param {Object} payload
 *
 * Use this action creator to start signature flow.
 * Documentation of payload's shape is in signSaga.
 */
export const postCreateSignature = (payload) => ({
  type: types.SIGN_POST_CREATE_SIGNATURE,
  payload,
});

export const handleError = (e) => ({
  type: types.SIGN_HANDLE_ERROR,
  error: e,
});

export const getConsent = (payload) => ({
  type: types.SIGN_GET_CONSENT,
  payload,
});

export const handleConsent = () => ({
  type: types.SIGN_HANDLE_CONSENT,
});

export const updateConsentStatus = () => ({
  type: types.SIGN_HANDLE_UPDATE_CONSENT,
});

export const getConsentUrl = () => ({
  type: types.SIGN_GET_CONSENT_URL,
});

export const handleConsentUrl = (url, activationStatus) => ({
  type: types.SIGN_HANDLE_CONSENT_URL,
  payload: { url, activationStatus },
});

export const handleCreateEnvelope = (res) => ({
  type: types.SIGN_HANDLE_CREATE_ENVELOPE,
  payload: res,
});

export const postCreateEnvelope = (
  body,
  disputeId,
  onEnvelopeCreated,
  hubSlug
) => ({
  type: types.SIGN_POST_CREATE_ENVELOPE,
  payload: { body, disputeId, onEnvelopeCreated, hubSlug },
});

export const putUpdateEnvelope = (
  envelopeGuid,
  envelopeId,
  signerId,
  event,
  hubSlug,
  disputeId,
  flow,
  signableId
) => ({
  type: types.SIGN_PUT_UPDATE_ENVELOPE,
  payload: {
    body: {
      envelopeGuid,
      envelopeId,
      signerId,
      event,
      hubSlug,
      disputeId,
      flow,
      signableId,
    },
  },
});

export const putUpdateEnvelopeAt = (
  envelopeGuid,
  envelopeId,
  hubSlug,
  disputeId,
  flow,
  signableId,
  event
) => ({
  type: types.SIGN_PUT_UPDATE_ENVELOPE_AT,
  payload: {
    body: {
      envelopeGuid,
      envelopeId,
      hubSlug,
      disputeId,
      flow,
      signableId,
      event,
    },
  },
});

export const handleUpdateEnvelope = (res) => ({
  type: types.SIGN_HANDLE_UPDATE_ENVELOPE,
  payload: res,
});

export const handleIsRedirecting = (payload) => ({
  type: types.SIGN_HANDLE_IS_REDIRECTING,
  payload: payload,
});
