import PropTypes from "prop-types";
import React from "react";
import { withNamespaces } from "react-i18next";
import "../../i18n";

function Translation({ t, text, pad = false }) {
  return (
    <>
      {pad ? " " : null}
      {t(text)}
    </>
  );
}

Translation.propTypes = {
  text: PropTypes.string.isRequired,
};

export default withNamespaces()(Translation);
