import { call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../actions/fileActions";
import * as apis from "../apis/filesApi";
import * as types from "../constants/constants";

const tag = "files";

export function* postCreate(action) {
  const { fileNames } = action.payload;
  try {
    const res = yield call(apis.createUploadUrls, fileNames);
    yield put(actions.handleCreate(res));
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdate(action) {
  const { id, body, onDone } = action.payload;
  try {
    const res = yield call(apis.update, id, body);
    yield put(actions.handleUpdate(res));
    if (onDone) yield onDone();
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getInlineShow(action) {
  const { id } = action.payload;
  try {
    const { data } = yield call(apis.show, id);
    yield put(actions.handleInlineShow({ ...data.attributes, id }));
  } catch (e) {
    console.error(tag, "saga getInlineShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShow(action) {
  const { id } = action.payload;
  try {
    const { data } = yield call(apis.show, id);
    yield window.open(data.attributes.downloadUrl, "_blank");
    // yield put(actions.handleShow(attributes));
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* deleteFile(action) {
  const { id } = action.payload;
  try {
    yield call(apis.remove, id);
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* () {
  yield takeEvery(types.FILE_POST_CREATE, postCreate);
  yield takeEvery(types.FILE_PUT_UPDATE, putUpdate);
  yield takeEvery(types.FILE_GET_SHOW, getShow);
  yield takeEvery(types.FILE_GET_INLINE_SHOW, getInlineShow);
  yield takeEvery(types.FILE_DELETE, deleteFile);
}
