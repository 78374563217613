import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as actions from "../actions/disputeFileActions";
import * as apis from "../apis/disputeFilesApi";
import * as types from "../constants/constants";
import { handleUpdateSlug } from "../actions/hubActions";
import { successMessage } from "../../components/Common/Feedback/Message";

const tag = "dispute-files";

export function* getIndex(action) {
  const { disputeId, group, page } = action.payload;
  try {
    const res = yield call(apis.index, disputeId, group, page);
    yield put(actions.handleIndex(res));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreate(action) {
  const { disputeId, fileCategory, body, currentPage } = action.payload;

  try {
    const res = yield call(apis.create, disputeId, fileCategory, body);
    const redirectURL = res?.data?.data?.attributes?.signatureInfo?.redirectURL?.url;
    if (redirectURL) {
      window.location = redirectURL;
    } else {
      yield put(actions.getIndex(disputeId, currentPage));
    }
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateCompleted(action) {
  const { disputeId, hubSlug } = action.payload?.params?.meta;
  try {
    successMessage("case_files.signature_updated.text");
    if (hubSlug && disputeId) {
      yield put(handleUpdateSlug(hubSlug));
      yield put(push(`/${hubSlug}/disputes/${disputeId}/files`));
    } else yield put(push(`/`));
  } catch (e) {
    console.error(tag, `saga putUpdateCompleted error:`, e);
    yield put(actions.handleError(e));
  }
}

export default function* () {
  yield takeLatest(types.DISPUTEFILES_GET_INDEX, getIndex);
  yield takeLatest(types.DISPUTEFILES_POST_CREATE, postCreate);
  yield takeLatest(types.DISPUTEFILES_PUT_UPDATE_COMPLETED, putUpdateCompleted);
}
