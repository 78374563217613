import { Card, Spin } from "antd";
import React from "react";
import "./styles.less";

export default function ({ card = false, extraProps }) {
  const Content = () => (
    <div className="jur-spinner">
      <Spin {...extraProps} />
    </div>
  );

  return card ? (
    <Card bordered={false}>
      <Content />
    </Card>
  ) : (
    <Content />
  );
}
