import { all, fork } from "redux-saga/effects";
import arbitralAwardSaga from "./arbitralAwardSaga";
import arbitratorPanelSaga from "./arbitratorPanelSaga";
import arbitratorSaga from "./arbitratorSaga";
import assistedDisputeSaga from "./assistedDisputeSaga";
import authenticateSaga from "./authenticateSaga";
import billingDetailsSaga from "./billingDetailsSaga";
import defensiveActsSaga from "./defensiveActsSaga";
import disputeAssignmentsSaga from "./disputeAssignmentsSaga";
import disputeFilesSaga from "./disputeFilesSaga";
import disputeFilingSaga from "./disputeFilingSaga";
import disputesSaga from "./disputesSaga";
import disputeTorSaga from "./disputeTorSaga";
import disputeUsersSaga from "./disputeUsersSaga";
import evidenceRequestsSaga from "./evidenceRequestsSaga";
import filesSaga from "./filesSaga";
import genericSaga from "./genericSaga";
import hearingsSaga from "./hearingsSaga";
import hubsSaga from "./hubsSaga";
import paymentsSaga from "./paymentsSaga";
import proceduralOrderSaga from "./proceduralOrderSaga";
import responseFilingSaga from "./responseFilingSaga";
import signSaga from "./signSaga";
import timelineSaga from "./timelineSaga";
import userProfileSaga from "./userProfileSaga";
import userSaga from "./userSaga";

export default function* rootSaga() {
  yield all(
    [
      arbitralAwardSaga,
      arbitratorSaga,
      arbitratorPanelSaga,
      assistedDisputeSaga,
      authenticateSaga,
      billingDetailsSaga,
      defensiveActsSaga,
      disputeFilingSaga,
      disputeTorSaga,
      disputeUsersSaga,
      disputeAssignmentsSaga,
      disputeFilesSaga,
      disputesSaga,
      evidenceRequestsSaga,
      genericSaga,
      hearingsSaga,
      hubsSaga,
      filesSaga,
      paymentsSaga,
      responseFilingSaga,
      proceduralOrderSaga,
      signSaga,
      timelineSaga,
      userSaga,
      userProfileSaga,
    ].map(fork)
  );
}
