import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import {
  getLastActivity,
  getPhases,
  getTaskList,
  handleUpdateLastActivityForm,
  handleUpdatePhasesForm,
  handleUpdateTaskListForm,
  putUpdateLastActivity,
  putUpdatePhases,
  putUpdateTaskList,
} from "../../redux/actions/assistedDisputeActions";
import {
  selectModifiedStates,
  selectPhases,
  selectWhole,
} from "../../redux/selectors/assistedDisputeSelector";
import IndexRender from "./IndexRender";

export default function IndexHOC() {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();
  const disputeParams = { disputeId, dispute, hubSlug };

  const dispatch = useDispatch();

  const hasModified = useSelector(selectModifiedStates);
  const phases = useSelector(selectPhases);
  const handleSavePhases = () =>
    void dispatch(putUpdatePhases(disputeId, phases));
  const handleUpdatePhaseList = (updatedPhases) =>
    void dispatch(handleUpdatePhasesForm(updatedPhases));
  const phaseParams = {
    phases,
    onSavePhases: handleSavePhases,
    onUpdatePhases: handleUpdatePhaseList,
  };

  const whole = useSelector(selectWhole);
  const {
    arbitratorLastUpdate,
    claimantLastUpdate,
    respondentLastUpdate,
    arbitratorTaskList,
    claimantTaskList,
    respondentTaskList,
  } = whole;
  const onSaveLastUpdate = (group) =>
    void dispatch(
      putUpdateLastActivity(
        disputeId,
        group,
        whole[`${group.toLowerCase()}LastUpdate`]
      )
    );
  const handleLastUpdateFormUpdate = (group, lastUpdate) =>
    void dispatch(
      handleUpdateLastActivityForm(
        `${group.toLowerCase()}LastUpdate`,
        lastUpdate
      )
    );
  const lastUpdateProps = {
    arbitratorLastUpdate,
    claimantLastUpdate,
    respondentLastUpdate,
    onSaveLastUpdate,
    onLastUpdateFormUpdate: handleLastUpdateFormUpdate,
  };

  const onSaveTaskList = (group) =>
    void dispatch(
      putUpdateTaskList(
        disputeId,
        group,
        whole[`${group.toLowerCase()}TaskList`]
      )
    );
  const handleTaskListFormUpdate = (group, tasks) => {
    void dispatch(
      handleUpdateTaskListForm(`${group.toLowerCase()}TaskList`, tasks)
    );
  };
  const taskListProps = {
    arbitratorTaskList,
    claimantTaskList,
    respondentTaskList,
    onSaveTaskList,
    onTaskListFormUpdate: handleTaskListFormUpdate,
  };

  useEffect(() => void dispatch(getPhases(disputeId)), [dispatch, disputeId]);

  useEffect(() => void dispatch(getLastActivity(disputeId, "Arbitrator")), [
    dispatch,
    disputeId,
  ]);
  useEffect(() => void dispatch(getLastActivity(disputeId, "Respondent")), [
    dispatch,
    disputeId,
  ]);
  useEffect(() => void dispatch(getLastActivity(disputeId, "Claimant")), [
    dispatch,
    disputeId,
  ]);

  useEffect(() => void dispatch(getTaskList(disputeId, "Arbitrator")), [
    dispatch,
    disputeId,
  ]);
  useEffect(() => void dispatch(getTaskList(disputeId, "Respondent")), [
    dispatch,
    disputeId,
  ]);
  useEffect(() => void dispatch(getTaskList(disputeId, "Claimant")), [
    dispatch,
    disputeId,
  ]);

  return (
    <IndexRender
      disputeParams={disputeParams}
      phaseParams={phaseParams}
      lastUpdateProps={lastUpdateProps}
      taskListProps={taskListProps}
      hasModified={hasModified}
    />
  );
}
