import { FileTextOutlined, FileTwoTone } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { Badge, Button, Space, Table } from "antd";
import React from "react";
import { PartyTag } from "../Common/UserRole";
import * as fileActions from "../../redux/actions/fileActions";

function CaseFilesTableOverview({ data, whiteHeader, pagination, isLoading }) {
  const dispatch = useDispatch();
  const previewFile = (fileId) => dispatch(fileActions.getShow(fileId));

  return (
    <div className="jur-case-files-table-overview">
      <Table
        loading={isLoading}
        className={whiteHeader && "jur-table-white-header"}
        columns={columns(previewFile)}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
}

export default CaseFilesTableOverview;

const columns = (previewFile) => {
  return [
    {
      title: <FileTextOutlined className="jur-cf-table-body-file-icon" />,
      key: "icon",
      width: "50px",
      render: () => <FileTwoTone className="jur-table-body-file-render-icon" twoToneColor=" #2777EF" />,
    },
    {
      title: "Files",
      dataIndex: ["attributes", "fileInfo", "name"],
      key: "name",
      render: renderFiles(previewFile),
    },
    {
      title: "Category",
      dataIndex: ["attributes", "fileInfo", "category"],
      key: "fileCategory",
    },
    {
      title: "Owner",
      key: "owner",
      dataIndex: ["attributes", "fileInfo", "OwnerOfTheFile"],
      render: (tag, row) => {
        return <PartyTag party={tag === "Hub User" ? tag : tag} />;
      },
    },
    {
      title: "Status",
      dataIndex: ["attributes", "fileInfo", "status"],
      render: (text) => renderStatus(text),
    },
  ];
};

const renderFiles = (previewFile) => (text, record) => (
  <Button type="link" onClick={() => previewFile(record?.id)}>
    {text}
  </Button>
);

const renderStatus = (text) => {
  return (
    <Space size="small">
      {text?.length > 1 ? <Badge status="success" /> : null}
      {text}
    </Space>
  );
};
