import * as types from "../constants/constants";

export const getIndex = (disputeId, page = 1) => ({
  type: types.EVIDENCEREQUEST_GET_INDEX,
  payload: { disputeId, page },
});

export const getShow = (disputeId, evidenceRequestId) => ({
  type: types.EVIDENCEREQUEST_GET_SHOW,
  payload: { disputeId, evidenceRequestId },
});

export const postCreate = (disputeId, body, resetForm) => ({
  type: types.EVIDENCEREQUEST_POST_CREATE,
  payload: { disputeId, body, resetForm },
});

export const putUpdate = (
  disputeId,
  evidenceRequestId,
  body,
  isShow = false,
  resetForm
) => ({
  type: types.EVIDENCEREQUEST_PUT_UPDATE,
  payload: { disputeId, evidenceRequestId, body, isShow, resetForm },
});

export const handleIndex = (evidenceRequests) => ({
  type: types.EVIDENCEREQUEST_HANDLE_INDEX,
  payload: evidenceRequests,
});

export const handleShow = (evidenceRequest) => ({
  type: types.EVIDENCEREQUEST_HANDLE_SHOW,
  payload: evidenceRequest,
});

export const handleCreate = () => ({
  type: types.EVIDENCEREQUEST_HANDLE_CREATE,
});

export const handleUpdate = () => ({
  type: types.EVIDENCEREQUEST_HANDLE_UPDATE,
});

export const handleIsRequestDocumentOpen = (isOpen) => ({
  type: types.EVIDENCEREQUEST_HANDLE_IS_REQUEST_DOCUMENT_OPEN,
  payload: isOpen,
});

export const handleIsSendDocumentsOpen = (isOpen) => ({
  type: types.EVIDENCEREQUEST_HANDLE_IS_SEND_DOCUMENTS_OPEN,
  payload: isOpen,
});

export const putUpdateCompleted = (disputeId, body) => ({
  type: types.EVIDENCEREQUEST_PUT_UPDATE_COMPLETED,
  payload: { disputeId, body },
});

export const handleError = (e) => ({
  type: types.EVIDENCEREQUEST_HANDLE_ERROR,
  error: e,
});

export const handleSignatureError = (disputeId, params) => ({
  type: types.EVIDENCEREQUEST_HANDLE_SIGNATURE_ERROR,
  payload: { disputeId, params },
});
