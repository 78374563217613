import React from "react";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import DownloadRender from "./DownloadRender";

export default function () {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();

  const fileId = dispute?.arbitralAward?.file?.id;
  const additionalAwardFileId = dispute?.additionalArbitralAward?.file?.id;

  return (
    <DownloadRender
      dispute={dispute}
      disputeId={disputeId}
      extraCrumbs={extraCrumbs(hubSlug, disputeId)}
      hubSlug={hubSlug}
      additionalAwardFileId={additionalAwardFileId}
      fileId={fileId}
    />
  );
}

const extraCrumbs = (hubSlug, disputeId) => [
  {
    path: `/${hubSlug}/disputes/${disputeId}/arbitral-award-download`,
    breadcrumbName: "Arbitral Award Download",
  },
];
