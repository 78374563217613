import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getShowClaimFiles,
  handleUpdateClaimFilesForm,
} from "../../redux/actions/disputeFilingActions";
import * as fileActions from "../../redux/actions/fileActions";
import * as selectors from "../../redux/selectors/disputeFilingSelector";
import { uploadInfoOf } from "../../redux/selectors/filesSelector";
import Spinner from "../Common/Spinner";
import DocumentsRender from "./DocumentsRender";

const fileCategory = "Claim Attachment";
const prefix = "disputeFiling.claimFiles.";
const fileNames = [prefix + fileCategory];

export default function () {
  const disputeId = useSelector(selectors.selectDisputeId);
  const files = useSelector(selectors.selectClaimFiles);
  const uploadInfo = useSelector(uploadInfoOf(fileNames));
  const dispatch = useDispatch();
  const errors = useSelector(selectors.selectErrors);
  const isErrorHidden = useSelector(selectors.selectIsErrorHidden);

  useEffect(() => {
    dispatch(fileActions.uploadUrlsFor(fileNames));
    dispatch(getShowClaimFiles(disputeId));
  }, [disputeId, dispatch]);

  const handleUpdateForm = (values) =>
    dispatch(handleUpdateClaimFilesForm(values));

  if (!uploadInfo) return <Spinner card={true} />;
  const itemInfo = uploadInfo[fileNames[0]];
  if (!itemInfo) return <Spinner card={true} />;

  itemInfo.fileCategory = fileCategory;

  return files ? (
    <DocumentsRender
      files={files}
      itemInfo={itemInfo}
      onUpdateForm={handleUpdateForm}
      errors={errors}
      errorProps={{ isHidden: isErrorHidden }}
    />
  ) : (
    <Spinner card={true} />
  );
}
