import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {
  index: [],
  current: null,
  meta: null,
  isSendToHubVisible: false,
  isSendToPartiesVisible: false,
  isExtendDeadlineVisible: false,
  isReviewAwardVisible: false,
  isRequestEditsVisible: false,
  isLoading: false,
  isSubmitting: false,
};

export default function (state = initial, action) {
  const payload = action.payload;
  switch (action.type) {
    case types.ARBITRALAWARD_HANDLE_ERROR:
      return {
        ...state,
        errors: errorMessages(action.error),
        isLoading: false,
        isSubmitting: false,
      };
    case types.ARBITRALAWARD_GET_INDEX:
      return { ...state, isLoading: true, index: [] };
    case types.ARBITRALAWARD_GET_SHOW:
      return { ...state, current: null };
    case types.ARBITRALAWARD_HANDLE_SHOW:
      return {
        ...state,
        current: action.payload?.data,
        isLoading: false,
      };
    case types.ARBITRALAWARD_HANDLE_INDEX:
      return {
        ...state,
        index: payload?.data,
        meta: payload?.meta,
        isLoading: false,
      };
    case types.ARBITRALAWARD_POST_CREATE:
      return { ...state, isSubmitting: true };
    case types.ARBITRALAWARD_HANDLE_CREATE:
      return { ...state, isSendToHubVisible: false, isSubmitting: false };
    case types.ARBITRALAWARD_PUT_UPDATE:
      return { ...state, isSubmitting: true };
    case types.ARBITRALAWARD_PUT_DEADLINE_UPDATE:
      return { ...state, isSubmitting: true };
    case types.ARBITRALAWARD_HANDLE_IS_REVIEW_OPEN:
      return { ...state, isReviewAwardVisible: action.payload };
    case types.ARBITRALAWARD_HANDLE_IS_SEND_TO_HUB_OPEN:
      return { ...state, isSendToHubVisible: payload };
    case types.ARBITRALAWARD_HANDLE_IS_SEND_TO_PARTIES_OPEN:
      return { ...state, isSendToPartiesVisible: action.payload };
    case types.ARBITRALAWARD_HANDLE_IS_EXTEND_DEADLINE_OPEN:
      return { ...state, isExtendDeadlineVisible: action.payload };
    case types.ARBITRALAWARD_HANDLE_IS_REQUEST_EDITS_OPEN:
      return { ...state, isRequestEditsVisible: action.payload };
    case types.ARBITRALAWARD_HANDLE_UPDATE:
      return {
        ...state,
        isReviewAwardVisible: false,
        isExtendDeadlineVisible: false,
        isSendToPartiesVisible: false,
        isRequestEditsVisible: false,
        isSubmitting: false,
      };
    default:
      return state;
  }
}
