import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { sanitizeHtml } from "../../../helpers/formHelpers";
import { defaultFormats, defaultModules } from "./config";
import "./styles.less";

let Link = Quill.import("formats/link");

class CustomLink extends Link {
  static sanitize(url) {
    let value = super.sanitize(url);
    if (value) {
      for (let i = 0; i < CustomLink.PROTOCOL_WHITELIST.length; i++)
        if (value.startsWith(CustomLink.PROTOCOL_WHITELIST[i])) return value;
      return `http://${value}`;
    }
    return value;
  }
}

Quill.register(CustomLink);

export default function Editor({
  modules = defaultModules,
  formats = defaultFormats,
  className = "jur-quill-editor",
  help = "",
  showCount = true,
  onChange,
  defaultValue,
  ...rest
}) {
  const [wordCount, setWordCount] = useState(0);
  return (
    <>
      <ReactQuill
        theme="snow"
        className={className}
        modules={modules}
        formats={formats}
        onChange={(html, _, __, editor) => {
          // eslint-disable-next-line
          const sanitizedHtml = new String(sanitizeHtml(html));
          const plain = words(editor.getText(sanitizedHtml));
          sanitizedHtml.wordLength = plain.length;
          setWordCount(plain.length);
          return onChange(sanitizedHtml);
        }}
        defaultValue={defaultValue?.toString() ?? defaultValue}
        {...rest}
      />
      {showCount && wordCount > 20 ? (
        <div className="ant-form-item-explain ant-form-item-explain-help">
          You have typed {wordCount} words
        </div>
      ) : null}
      {help ? (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          {help}
        </div>
      ) : null}
    </>
  );
}

export const words = (text) => {
  return text
    .replace(/(,;\?\/'"\.)/, " ")
    .replace(/(^\s*)|(\s*$)/gi, "")
    .replace(/\n/g, " ")
    .replace(/[ ]{2,}/gi, " ")
    .split(" ");
};
