import { Formik } from "formik";
import { Form } from "formik-antd";
import React from "react";
import { DocumentFields } from "../DisputeFiling/DocumentsRender";

export default function ({ files = {}, errors, errorProps, onUpdateForm }) {
  return (
    <Formik initialValues={files}>
      {({ values, setFieldValue, resetForm }) => {
        const uploaderProps = {
          name: "responseFilesUploader",
          fileCategory: "Response Attachment",
          allowDocumentOnly: true,
          multiple: false,
          listType: "",
          onRemove: (file) => {
            const updatedFiles = {
              ...files,
              files: files?.files.filter((x) => x.id !== file?.id),
            };
            resetForm({ values: updatedFiles });
          },
          onDone: (file) => {
            const updatedFiles = {
              ...files,
              files: files?.files?.concat({
                id: file?.id,
                name: file?.name,
                fileCategory: "Response Attachment",
              }),
            };
            resetForm({ values: updatedFiles });
            onUpdateForm(updatedFiles);
          },
          defaultFileList: values?.files?.map((f) => ({
            id: f?.id,
            name: f.name,
          })),
        };

        const formItemProps = {
          values,
          setFieldValue,
          propogateValues: onUpdateForm,
          errors,
          errorProps,
        };

        return (
          <Form layout="vertical" className="jur-dispute-filing-form">
            <DocumentFields
              isResponse={true}
              uploaderProps={uploaderProps}
              formItemProps={formItemProps}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
