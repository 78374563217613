import React from "react";
import JurLogo from "../Common/Logos/JurLogo";
import Translation from "../Common/Translation";
import "./illustrations.less";

export default function DiscoverJustice() {
  return (
    <div>
      <JurLogo />
      <div className="sidebar-text">
        <Translation text="general.discover_justice.text" />
      </div>
      <center>
        <img
          className="sidebar-illustration"
          src={require("../../assets/illustrations/illustration-1.png")}
          alt="illustration"
        />
      </center>
    </div>
  );
}
