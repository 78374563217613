import React from "react";
import { useDispatch } from "react-redux";
import { readLastKnownEmail } from "../../helpers/authHelpers";
import { postResendRegister } from "../../redux/actions/authenticateActions";
import Translation from "../Common/Translation";
import AuthLayout from "../Layouts/AuthLayout";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";
import "./authenticate.less";

export default function RegisterConfirm() {
  const dispatch = useDispatch();

  const resendEmail = () => {
    const email = readLastKnownEmail();
    dispatch(postResendRegister({ email }));
  };

  const ConfirmRegistration = () => (
    <>
      <div className="jur-auth-title">
        <Translation text="authenticate.email_verification.title" />
      </div>
      <br />
      <div className="jur-auth-description">
        <Translation text="authenticate.email_verification.description" />
        <br />
        <Translation text="authenticate.haven't_received.description" />
        <span className="jur-link" onClick={resendEmail}>
          <Translation text="authenticate.click_here.button" />
        </span>
        <Translation text="authenticate.resend.description" />
      </div>
    </>
  );

  return (
    <LeftSidebarLayout
      mainContent={
        <AuthLayout header={<div />} content={<ConfirmRegistration />} />
      }
    />
  );
}
