import * as types from "../constants/constants";

export const getIndex = (disputeId, page = 1) => ({
  type: types.DISPUTETOR_GET_INDEX,
  payload: { disputeId, page },
});

export const getShow = (disputeId, torId) => ({
  type: types.DISPUTETOR_GET_SHOW,
  payload: { disputeId, torId },
});

export const handleIsSendToHubOpen = (isOpen) => ({
  type: types.DISPUTETOR_HANDLE_IS_SEND_TO_HUB_OPEN,
  payload: isOpen,
});

export const handleIsSendToPartiesOpen = (isOpen) => ({
  type: types.DISPUTETOR_HANDLE_IS_SEND_TO_PARTIES_OPEN,
  payload: isOpen,
});

export const handleIsReviewOpen = (isOpen) => ({
  type: types.DISPUTETOR_HANDLE_IS_REVIEW_OPEN,
  payload: isOpen,
});

export const handleIsSignTOROpen = (isOpen) => ({
  type: types.DISPUTETOR_HANDLE_IS_SIGN_TOR_OPEN,
  payload: isOpen,
});

export const postCreate = (disputeId, body, clearForm) => {
  return {
    type: types.DISPUTETOR_POST_CREATE,
    payload: { disputeId, body, clearForm },
  };
};

export const putUpdate = (disputeId, torId, body, resetForm) => ({
  type: types.DISPUTETOR_PUT_UPDATE,
  payload: { disputeId, torId, body, resetForm },
});

export const handleIndex = (disputeTors) => ({
  type: types.DISPUTETOR_HANDLE_INDEX,
  payload: disputeTors,
});

export const handleShow = (disputeTor) => ({
  type: types.DISPUTETOR_HANDLE_SHOW,
  payload: disputeTor,
});

export const handleCreate = () => ({
  type: types.DISPUTETOR_HANDLE_CREATE,
});

export const handleUpdate = () => ({
  type: types.DISPUTETOR_HANDLE_UPDATE,
});

export const putEnvelopeCreate = (disputeId, torId, body) => ({
  type: types.DISPUTETOR_PUT_ENVELOPE_CREATE,
  payload: { disputeId, torId, body },
});

export const putUpdateCompleted = (disputeId, params) => ({
  type: types.DISPUTETOR_PUT_UPDATE_COMPLETED,
  payload: { disputeId, params },
});

export const handleError = (e) => ({
  type: types.DISPUTETOR_HANDLE_ERROR,
  error: e,
});

export const handleSignatureError = (disputeId, params) => ({
  type: types.DISPUTETOR_HANDLE_SIGNATURE_ERROR,
  payload: { disputeId, params },
});
