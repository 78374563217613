import { Card, Empty } from "antd";
import React, { createElement, Fragment } from "react";

const NoData = ({
  image,
  imageStyle,
  description = false,
  isWrappedInCard = true,
  ...props
}) => {
  const cardProps = isWrappedInCard
    ? {
        className: "jur-vertical-center",
      }
    : {};

  const renderEmptyComponent = () => {
    return (
      <Empty
        image={
          image || require("../../../assets/illustrations/empty-state.png")
        }
        imageStyle={
          imageStyle || {
            height: 100,
            margin: "8px 8px 24px",
          }
        }
        description={description}
        {...props}
      />
    );
  };

  return createElement(
    isWrappedInCard ? Card : Fragment,
    cardProps,
    renderEmptyComponent()
  );
};

export default NoData;
