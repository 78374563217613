import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCEEDED,
} from "../constants/constants";

let initialState = {
  data: { role: "party" },
  isLoggedIn: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        isLoggedIn: true,
      };
    case LOGIN_USER_FAILED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
