import { Button, List } from "antd";
import React from "react";
import { ATTORNEY } from "../../redux/constants/UserRoleConstants";
import Anchor from "../Common/Anchor";
import Sidebar from "../Common/Sidebar";
import Translation from "../Common/Translation";
import JurPointer from "../DataDisplay/JurPointer";
import SignatureButton from "../Signs/SignatureButton";

export default function DisputeFilingSidebar({
  currentStep,
  userRole,
  hubSlug,
  disputeId,
  onClick,
  onSocClick,
  claimant,
}) {
  const sidebarContent = sidebarInfo({
    ...{ hubSlug, disputeId, onClick, onSocClick },
  });
  if (!userRole)
    return (
      <Sidebar
        title={"Before we start, can you help us with some information?"}
        description={
          "We understand that filing can be cumbersome and would like to guide you better based on your experience"
        }
      />
    );
  if (currentStep === 0) {
    if (!Boolean(claimant?.role)) return null;
    else if (claimant?.role === ATTORNEY) {
      return (
        <Sidebar
          title={"Let’s setup some basic information, to represent you better"}
          description={
            <div>
              Jur recommends consultation of an attorney. Please refer to{" "}
              <Anchor to="/">Jur Arbitration Rules</Anchor> for more information
              about the procedural steps of the dispute.
            </div>
          }
        />
      );
    } else return <Sidebar title={"Could you tell us more about the party?"} />;
  }
  return (
    <Sidebar
      title={sidebarContent[currentStep]?.title}
      description={sidebarContent[currentStep]?.description}
      content={sidebarContent[currentStep]?.content}
    />
  );
}

const sidebarInfo = ({ hubSlug, disputeId, onClick, onSocClick }) => {
  return {
    1: {
      title: "Could you tell us more about the claim?",
      description: (
        <p>
          Please provide a summary that will be used to evaluate your case.
          <br />
          <br />
          Refer to the arbitration agreement for more information. The
          counter-party will also be asked to mention their preference of seat
          of arbitration and substantive law. In case of disparity, the final
          decision will be determined by the arbitrator.
        </p>
      ),
    },
    2: {
      title: "What are the documents supporting your claim?",
      description:
        "These documents will be attached to the Request for Arbitration and jur will also share them with the counter-party",
      content: (
        <List
          itemLayout="horizontal"
          dataSource={[
            "The original agreement(s) which is under dispute",
            "In case there is a separate arbitration agreement, please upload that too",
            "The power of attorney granted to you by the claimant",
            "Any other relevant document you would like to attach",
          ]}
          renderItem={(item) => <JurPointer label={item} />}
        />
      ),
    },
    3: {
      title: "Who are you filing the request against?",
      description:
        "The respondent will be notified once you have successfully submitted the request for arbitration",
    },
    4: {
      title: "Do you want to invite your team to represent the claim?",
      description:
        "Invited attorney, legal representative or other party representatives will be able to access the dispute once the request has been successfully submitted. Invitations cannot be sent after submission.",
    },
    5: {
      title: "Please sign the request for arbitration",
      description:
        "Jur transposed all the information you provided in the previous steps. All that's left is for you to sign it and make it official",
      content: (
        <SignatureButton
          redirectUrl={`/${hubSlug}/dispute-filing/${disputeId}?step=sign`}
        >
          <Button type="primary" size="large" onClick={() => onSocClick()}>
            <Translation text="general.sign_now.button" pad={true} />
          </Button>
        </SignatureButton>
      ),
    },
  };
};
