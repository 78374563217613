import { Space, Table, Tag, Typography } from "antd";
import React from "react";
import { toAddressLine } from "../../helpers/dataHelpers";
import DateTime from "../Common/DateTime";
import Files from "../Common/Files";
import KycStatus from "../Common/KycStatus";
import Translation from "../Common/Translation";
import { ParticipantItemRender } from "./ParticipantListRender";

export default function ({ user, disputeId }) {
  const profile = profileOf(user);
  const isHubAdmin = user?.role === "Hub Admin";

  return (
    <>
      <ParticipantItemRender
        user={user}
        description={descriptionOf(user)}
        disputeId={disputeId}
        showGroup={true}
        navigation={false}
      />
      {!isHubAdmin && <KycStatus status={user?.kycStatus} />}
      {profile.length ? (
        <Table
          pagination={false}
          bordered={false}
          size="small"
          dataSource={profileOf(user)}
          columns={profileColumns(isHubAdmin)}
          showHeader={true}
          rowKey="label"
        />
      ) : null}
      {user.files?.length ? (
        <Files files={user.files} columnsFilter={columnsFilter} />
      ) : null}
    </>
  );
}

function descriptionOf({ email = "", phone = "", ...rest }) {
  return (
    <>
      {Boolean(email || phone) ? (
        <>
          {email} {phone} <br />
        </>
      ) : null}
      {toAddressLine(rest)}
    </>
  );
}

function columnsFilter(column) {
  return ["id", "name", "fileCategory"].includes(column.dataIndex);
}

function profileColumns(isHubAdmin) {
  const tableHeader = isHubAdmin
    ? "general.hub_details.title"
    : "general.profile.title";
  return [
    {
      dataIndex: "label",
      render: (l) => {
        return <Translation text={l} />;
      },
      title: (
        <strong>
          <Translation text={tableHeader} />
        </strong>
      ),
    },
    {
      dataIndex: "value",
      render: (l, row) => {
        if (
          row?.label === "general.web_url.text" ||
          row?.label === "general.linkedin.text"
        )
          return (
            <Typography.Link href={l} target="_blank">
              {l}
            </Typography.Link>
          );
        return l;
      },
    },
  ];
}

// const contactAttributes = [
//   "email",
//   "phone",
//   "address",
//   "address2",
//   "city",
//   "state",
//   "country",
//   "zipCode",
// ];

function profileOf(user) {
  if (!user) return [];

  const attrs = user.isCompany ? ["companyName", "vatId"] : [];

  if (user.role === "Hub Admin") attrs.push("country");

  if (user.role === "Attorney")
    attrs.push("barMembershipTitle", "barMembershipPlace");

  if (user.role === "Legal Representative")
    attrs.push("roleInCompany", "companyName");

  if (user.role === "Arbitrator")
    attrs.push("yearsOfExperience", "linkedIn", "webUrl");

  if (user.isPartOfLawFirm) attrs.push("lawFirmName", "lawFirmContact");

  const dataSource = attrs.map((key) => ({
    label: labelFrom(key),
    value: user[key],
  }));

  if (user.role === "Attorney")
    dataSource.push({
      label: "general.bar_association_date.text",
      value: <DateTime epochSeconds={user.barMembershipDate} onlyDate />,
    });

  if (user.role === "Arbitrator")
    dataSource.push({
      label: "general.specializations.text",
      value: (
        <Space>
          {user.specializations?.map((s) => (
            <Tag key={s}>{s}</Tag>
          ))}
        </Space>
      ),
    });

  if (user.isPartOfLawFirm)
    dataSource.push({
      label: "general.law_firm_address.text",
      value: toAddressLine(user, { prefix: "lawFirm" }),
    });

  return dataSource;
}

function labelFrom(key) {
  return {
    firstName: "general.first_name.text",
    lastName: "general.last_name.text",
    email: "general.email.text",
    phone: "general.phone.text",
    address: "general.address.text",
    address2: "general.address2.text",
    city: "general.city.text",
    state: "general.state.text",
    country: "general.country.text",
    zipCode: "general.zip.text",
    roleInCompany: "general.role_in_company.text",
    kycStatus: "general.kyc_status.text",
    role: "general.role.text",
    barMembershipDate: "general.bar_association_date.text",
    barMembershipTitle: "general.bar_association.text",
    barMembershipNumber: "general.bar_association_number.text",
    barMembershipPlace: "general.bar_association_place.text",
    lawFirmName: "general.law_firm_name.text",
    lawFirmContact: "general.law_firm_contact.text",
    isPartOfLawFirm: "general.is_part_of_law_firm.text",
    companyName: "general.company_name.text",
    vatId: "general.vat_id.text",
    yearsOfExperience: "general.years_of_experience.text",
    linkedIn: "general.linkedin.text",
    webUrl: "general.web_url.text",
  }[key];
}
