import { FileOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import Translation from "../Common/Translation";
// import "../styles.less";
import ProfileSection from "./ProfileSection";
import JurLink from "../Common/JurLink";

const { Title, Text } = Typography;

const GeneralTab = ({ profile }) => {
  return (
    <div>
      {profile.qualifications?.length ? (
        <ProfileSection
          title={<Translation text="roster.academic_information.text" />}
          content={profile?.qualifications
            ?.map((item) => (
              <Row gutter={[0, 24]}>
                <Col span={12}>
                  <Title className="primary-color" level={5} strong>
                    {item?.institution}
                  </Title>
                  <div className="jur-profile-building-information-subtitle">
                    <Title level={5} className="secondary-color">
                      {item?.degree}, {item?.fieldOfStudy}
                    </Title>
                  </div>
                </Col>
                <Col span={6}>
                  <Text className="secondary-color">
                    <Translation text={`roster.graduated_year.text`} />
                  </Text>
                  <Title className="primary-color" level={5}>
                    {item?.graduatedYear}
                  </Title>
                </Col>
                <Col span={6}>
                  <Text className="secondary-color">
                    <Translation text={`roster.location.text`} />
                  </Text>
                  <Title className="primary-color" level={5}>
                    {item?.location}
                  </Title>
                </Col>
              </Row>
            ))
            .reduce((prev, curr) => [prev, <Divider />, curr])}
        />
      ) : null}
      <ProfileSection
        title={<Translation text="roster.certifications.text" />}
        content={
          <Row gutter={[16, 16]}>
            {profile?.certificates.map((item) => (
              <Col span={8}>
                <div className="jur-profile-card-item">
                  <FileOutlined />
                  <Title level={5} strong className="primary-color">
                    {item.title}
                  </Title>
                  <div>
                    <JurLink href={item?.fileUrl}>{item?.fileName}</JurLink>
                  </div>
                  <div className="jur-profile-section-list-item-description">
                    <Text strong> {item.year}</Text>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        }
      />
    </div>
  );
};

export default GeneralTab;
