import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postCreateDispute } from "../../redux/actions/disputeFilingActions";
import { ATTORNEY, TEAM_MEMBER } from "../../redux/constants/UserRoleConstants";
import Translation from "../Common/Translation";
import CenteredContent from "../Layouts/CenteredContent";

export default function RoleIdentifier() {
  const [token, setToken] = useState("");
  const dispatch = useDispatch();

  const createDisputeWithRole = (role) => {
    if (!token) {
      message.error("Please enter the authentication token to proceed");
    } else {
      void dispatch(postCreateDispute({ role, token }));
    }
  };

  return (
    <CenteredContent contentWidth={15}>
      <Typography.Title level={3}>
        <Translation text="claim_filing.are_you_the_attorney.text" />
      </Typography.Title>
      <NavigationDivider />
      <Row justify="end">
        <Col>
          <Space>
            <Button
              size="small"
              onClick={() => {
                createDisputeWithRole(TEAM_MEMBER);
              }}
            >
              <Translation text="response_filing.no_i_am_the_claimant.text" />
            </Button>
            <Button
              size="small"
              onClick={() => {
                createDisputeWithRole(ATTORNEY);
              }}
              type="primary"
            >
              <Translation text="response_filing.yes_i_am_the_attorney.text" />
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Input
          required
          onChange={(e) => {
            setToken(e.target.value);
          }}
          placeholder="Enter the authentication token to proceed"
        />
      </Row>
    </CenteredContent>
  );
}

const NavigationDivider = () => <div className="jur-dispute-filing-divider" />;
