import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { APP_HANDLE_RESET } from "../constants/constants";
import arbitralAward from "./arbitralAwardReducer";
import arbitratorPanel from "./arbitratorPanelReducer";
import arbitrator from "./arbitratorReducer";
import assistedDispute from "./assistedDisputeReducer";
import authenticate from "./authenticateReducer";
import billingDetail from "./billingDetailReducer";
import defensiveActs from "./defensiveActsReducer";
import disputeAssignment from "./disputeAssignmentReducer";
import disputeFile from "./disputeFileReducer";
import disputeFiling from "./disputeFilingReducer";
import dispute from "./disputeReducer";
import disputeTor from "./disputeTorReducer";
import disputeUser from "./disputeUserReducer";
import errorReducer from "./errorReducer";
import evidenceRequest from "./evidenceRequestReducer";
import file from "./fileReducer";
import genericReducer from "./genericReducer";
import hearing from "./hearingReducer";
import hub from "./hubReducer";
import loadingReducer from "./loadingReducer";
import payment from "./paymentReducer";
import proceduralOrder from "./proceduralOrderReducer";
import responseFiling from "./responseFilingReducer";
import sign from "./signReducer";
import timeline from "./timelineReducer";
import userProfile from "./userProfileReducer";
import user from "./userReducer";

const rootReducer = (history) => (state, action) => {
  const intermediateState = combineReducers({
    user,
    error: errorReducer,
    loading: loadingReducer,
    arbitralAward,
    arbitrator,
    arbitratorPanel,
    assistedDispute,
    authenticate,
    hearing,
    dispute,
    hub,
    defensiveActs,
    disputeFiling,
    disputeUser,
    disputeAssignment,
    evidenceRequest,
    responseFiling,
    proceduralOrder,
    file,
    sign,
    billingDetail,
    payment,
    disputeFile,
    disputeTor,
    timeline,
    userProfile,
    router: connectRouter(history),
  })(action.type === APP_HANDLE_RESET ? undefined : state, action);
  const finalState = genericReducer(intermediateState, action);
  return finalState;
};

export default rootReducer;
