import { push } from "connected-react-router";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  errorMessage,
  successMessage,
} from "../../components/Common/Feedback/Message";
import * as actions from "../actions/disputeTorActions";
import { handleUpdateSlug } from "../actions/hubActions";
import * as timelineActions from "../actions/timelineActions";
import * as disputeTorApi from "../apis/disputeTorApi";
import * as types from "../constants/constants";

const tag = "disputeTor";

const indexTLParams = (disputeId) => {
  return {
    activityId: disputeId,
    activityType: "Terms Of Reference Index",
  };
};

export function* getIndex(action) {
  const { disputeId, page } = action.payload;
  try {
    const res = yield call(disputeTorApi.index, disputeId, page);
    yield put(actions.handleIndex(res));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShow(action) {
  const { disputeId, torId } = action.payload;
  try {
    const res = yield call(disputeTorApi.show, disputeId, torId);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreate(action) {
  const { disputeId, body, clearForm = () => {} } = action.payload;
  try {
    const res = yield call(disputeTorApi.create, disputeId, body);
    yield put(actions.handleCreate(res));
    yield put(actions.getIndex(disputeId));
    yield put(timelineActions.getShow(disputeId, indexTLParams(disputeId)));
    clearForm();
    successMessage("terms_of_reference.terms_of_reference_created.text");
  } catch (e) {
    errorMessage("terms_of_reference.terms_of_reference_signature_error.text");
    console.error(tag, "saga postCreate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdate(action) {
  const { disputeId, torId, body, resetForm = () => {} } = action.payload;
  try {
    const res = yield call(disputeTorApi.update, disputeId, torId, body);
    yield put(actions.handleUpdate(res));
    resetForm();
    successMessage("terms_of_reference.terms_of_reference_updated.text");
    yield put(timelineActions.getShow(disputeId, indexTLParams(disputeId)));
    yield put(actions.getIndex(disputeId));
    yield put(actions.getShow(disputeId, torId));
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateCompleted(action) {
  const { disputeId, hubSlug } = action.payload?.params?.meta;
  try {
    successMessage(
      "terms_of_reference.terms_of_reference_signature_updated.text"
    );
    if (hubSlug && disputeId) {
      yield put(handleUpdateSlug(hubSlug));
      yield put(push(`/${hubSlug}/disputes/${disputeId}/tor`));
    } else yield put(push(`/`));
  } catch (e) {
    console.error(tag, `saga putUpdateCompleted error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* putEnvelopeCreate(action) {
  const { disputeId, torId, body } = action.payload;
  try {
    const res = yield call(disputeTorApi.update, disputeId, torId, body);
    yield put(actions.handleUpdate(res));
    yield put(actions.getIndex(disputeId));
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* handleSignatureError({ payload }) {
  const { disputeId, hubSlug } = payload?.params;
  errorMessage("general.signature_error.text");
  if (hubSlug && disputeId) {
    yield put(handleUpdateSlug(hubSlug));
    yield put(push(`/${hubSlug}/disputes/${disputeId}/tor`));
  } else yield put(push(`/`));
}

export default function* disputeTorSaga() {
  yield takeLatest(types.DISPUTETOR_GET_INDEX, getIndex);
  yield takeLatest(types.DISPUTETOR_GET_SHOW, getShow);
  yield takeEvery(types.DISPUTETOR_POST_CREATE, postCreate);
  yield takeLatest(types.DISPUTETOR_PUT_UPDATE, putUpdate);
  yield takeLatest(types.DISPUTETOR_PUT_ENVELOPE_CREATE, putEnvelopeCreate);
  yield takeLatest(types.DISPUTETOR_PUT_UPDATE_COMPLETED, putUpdateCompleted);
  yield takeLatest(
    types.DISPUTETOR_HANDLE_SIGNATURE_ERROR,
    handleSignatureError
  );
}
