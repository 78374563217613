import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React from "react";
import { errorAsExtra, useErrorHider } from "../../helpers/errorHelpers";
import DisputeHeader from "../Common/DisputeHeader";
import Editor from "../Common/Editor";
import Translation from "../Common/Translation";
import Uploader from "../Common/Uploader";
import { handleChange } from "../DisputeFiling/helpers";

const { confirm } = Modal;

export default function SodRender({
  hubSlug,
  dispute,
  disputeId,
  extraCrumbs,
  onUpdateDefence,
  isSubmitting,
  errors,
}) {
  const errorProps = useErrorHider();
  const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);
  return (
    <Card>
      <DisputeHeader
        dispute={dispute}
        disputeId={disputeId}
        hubSlug={hubSlug}
        extraCrumbs={extraCrumbs}
      >
        <Typography.Title level={3}>
          <Translation text="defensive_actions.statement_of_defence.title" />
        </Typography.Title>
        <Typography.Paragraph>
          <Translation text="defensive_actions.submit_statement_of_defence.description" />
        </Typography.Paragraph>
        <Formik initialValues={{}}>
          {({ values, setFieldValue }) => {
            const uploaderProps = {
              name: "socFilesUploader",
              fileCategory: "Defence Statement",
              onDone: (file) => {
                errorProps.hide();
                const files = [file].concat(values.files);
                setFieldValue("files", files);
              },
              onRemove: (file) => {
                const files = values.files?.filter((f) => f?.uid !== file.uid);
                setFieldValue("files", files);
              },
              useDragger: true,
            };
            const handleChangeOf = handleChange(values, setFieldValue);

            const showConfirm = () => {
              confirm({
                title: (
                  <Translation text="defensive_actions.statement_of_defence_confirm.text" />
                ),
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  values.files = values?.files?.filter(Boolean);
                  onUpdateDefence(values);
                },
                okText: <Translation text="general.submit.button" />,
              });
            };

            return (
              <Form layout="vertical">
                <FormItem
                  {...apiErrorOf("statement")}
                  required
                  name="statement"
                >
                  <Editor
                    onChange={errorProps.hideAndCall(
                      handleChangeOf("statement")
                    )}
                    defaultValue={values.claim}
                  ></Editor>
                </FormItem>
                <FormItem {...apiErrorOf("files")} name="files">
                  <Uploader {...uploaderProps}>
                    <Translation text="general.or_drop_files.button" />
                  </Uploader>
                </FormItem>
                <Button
                  type="primary"
                  onClick={showConfirm}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  <Translation text="general.submit.button" pad={true} />
                </Button>
              </Form>
            );
          }}
        </Formik>
      </DisputeHeader>
    </Card>
  );
}
