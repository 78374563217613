import { Col, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Radio, Select } from "formik-antd";
import React from "react";
import {
  AddressFields,
  toFormItem,
} from "../../helpers/formHelpers";
import FormQuestion, { QuestionTitle } from "../Common/FormQuestion";
import JurLink from "../Common/JurLink";
import Translation from "../Common/Translation";

export default function ClaimantRender({
  userRole,
  onUpdateForm,
  errors,
  errorProps,
  claimant,
}) {
  return (
    <Formik initialValues={claimant || {}}>
      {({ values, setFieldValue }) => {
        const formItemProps = {
          values,
          setFieldValue,
          propogateValues: onUpdateForm,
          errors,
          errorProps,
        };

        const isUserAttorney = userRole === "Attorney";
        const isPartyBusiness = values.entityType === "Business";
        const labelValue = isPartyBusiness ? "business" : "claimant";

        const EntitySelector = () =>
          toFormItem({ ...formItemProps })({
            name: "entityType",
            customLabel: isUserAttorney ? (
              <QuestionTitle text="claimant.is_claimant_business_or_individual.text" />
            ) : (
              <QuestionTitle text="claimant.filing_as_business_or_individual.text" />
            ),
            helpText: isUserAttorney ? (
              <Translation text="claimant.this_will_help_decide_cost_split.text" />
            ) : (
              <Translation text="This will help us customise the procedures better for you and your team" />
            ),
            component: Radio.Group,
            componentProps: {
              options: behalfOptions(),
            },
          });

        return (
          <Form layout="vertical" className="jur-dispute-filing-form">
            <EntitySelector />
            {Boolean(values?.entityType) ? (
              <>
                {isUserAttorney || isPartyBusiness ? (
                  <>
                    {isPartyBusiness ? (
                      toFormItem({ ...formItemProps })({
                        name: "firstName",
                        customLabel: (
                          <FormQuestion
                            title={
                              <Translation
                                text={`claimant.complete_name_of_${labelValue}.text`}
                              />
                            }
                          />
                        ),
                      })
                    ) : (
                      <>
                        <FormItem
                          label={
                            <FormQuestion
                              title={
                                <Translation
                                  text={`claimant.complete_name_of_${labelValue}.text`}
                                />
                              }
                            />
                          }
                          name="name"
                          required
                        >
                          <Row gutter={24}>
                            <Col span={12}>
                              {toFormItem({ ...formItemProps, compact: true })({
                                name: "firstName",
                                placeholder: "First Name",
                              })}
                            </Col>
                            <Col span={12}>
                              {toFormItem({ ...formItemProps, compact: true })({
                                name: "lastName",
                                placeholder: "Last Name",
                              })}
                            </Col>
                          </Row>
                        </FormItem>
                      </>
                    )}
                    {toFormItem({ ...formItemProps })({
                      name: "email",
                      customLabel: (
                        <FormQuestion
                          title={
                            <Translation text={"general.email_address.text"} />
                          }
                        />
                      ),
                    })}
                  </>
                ) : null}
                <FormItem
                  required={true}
                  name="claimantType"
                  label={
                    <FormQuestion
                      title={
                        <Translation text="claimant.mailing_address.text" />
                      }
                    />
                  }
                >
                  <AddressFields {...formItemProps} />
                </FormItem>
              </>
            ) : null}
            {Boolean(values?.entityType) && !isUserAttorney
              ? toFormItem({ ...formItemProps })({
                  name: "role",
                  customLabel: (
                    <FormQuestion
                      title={
                        <Translation text="claimant.role_in_the_dispute.text" />
                      }
                    />
                  ),
                  helpText: (
                    <>
                      <Translation text="general.see_jur_faq_on.text" />
                      <JurLink>
                        <Translation text="claimant.how_to_select_role.text" />
                      </JurLink>
                    </>
                  ),
                  component: Select,
                  componentProps: {
                    children: roleOptions().map((item, i) => (
                      <Select.Option key={i} value={item.value}>
                        {item.label}
                      </Select.Option>
                    )),
                    placeholder: "Select your role",
                  },
                })
              : null}
          </Form>
        );
      }}
    </Formik>
  );
}

function behalfOptions() {
  return [
    { label: "Business", value: "Business" },
    { label: "Individual", value: "Individual" },
  ];
}

function roleOptions() {
  return [
    { label: "Legal Representative", value: "Legal Representative" },
    { label: "Team Member", value: "Team Member" },
  ];
}
