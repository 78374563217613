import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import configVariables from "../../locales/en/translation.json";
import { getShow } from "../../redux/actions/disputeActions";
import { getIndex, postCreate } from "../../redux/actions/disputeFileActions";
import {
  selectError,
  selectGroup,
  selectIndex,
  selectMeta,
  whole,
} from "../../redux/selectors/disputeFilesSelector";
import { fromMeta } from "../../transformers/paginationTransformer";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import IndexRender from "./IndexRender";

export default function () {
  const history = useHistory();
  const [disputeId, dispute] = useDispute();
  const disputeFilesData = useSelector(selectIndex);
  const meta = useSelector(selectMeta);
  const wholeData = useSelector(whole);
  const isUploading = wholeData?.isUploading;
  const errors = useSelector(selectError);
  const userGroup = useSelector(selectGroup);
  const dispatch = useDispatch();
  const hubSlug = useHubSlug();
  const [isUploadFormVisible, setIsUploadFormVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const isArbitrator = !userGroup;
  const isClaimant = userGroup === configVariables["general.claimant.title"];
  const isRespondent =
    userGroup === configVariables["general.respondent.title"];
  const isWitness = useAccess("individual-update", "evidence-request");

  useEffect(() => {
    dispatch(getIndex(disputeId));
    if (!dispute) dispatch(getShow(disputeId));
  }, [dispatch, disputeId, dispute]);

  useEffect(() => {
    if (!isUploading && Object.keys(errors || {}).length === 0) {
      setIsUploadFormVisible(false);
    }
  }, [isUploading, errors]);

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/files`,
      breadcrumbName: <Translation text="general.case_files.title" />,
    },
  ];

  const folders = [
    {
      label: "general.claimant.title",
      onClick: () => history.push("files/claimant"),
      condition: isArbitrator || isRespondent,
    },
    {
      label: "general.respondent.title",
      onClick: () => history.push("files/respondent"),
      condition: isArbitrator || isClaimant,
    },
    {
      label: "general.arbitrator.text",
      onClick: () => history.push("files/arbitrator"),
      condition: isClaimant || isRespondent,
    },
    {
      label: "general.statements.text",
      onClick: () => history.push("files/statements-attachments"),
      condition: isClaimant || isRespondent,
    },
    {
      label: "general.exhibits.text",
      onClick: () => history.push("files/exhibits"),
      condition: isClaimant || isRespondent,
    },
    {
      label: "general.witness_statements.text",
      onClick: () => history.push("files/witness-statements"),
      condition: isClaimant || isRespondent,
    },
    {
      label: "general.reports.text",
      onClick: () => history.push("files/reports"),
      condition: isClaimant || isRespondent,
    },
    {
      label: "general.cost.text",
      onClick: () => alert("clicked"),
    },
    {
      label: "case_files.procedural_orders.text",
      onClick: () => history.push("procedural-orders"),
      condition: isArbitrator,
    },
    {
      label: "case_files.hearing.text",
      onClick: () => history.push("files/hearing"),
      condition: isArbitrator,
    },
    {
      label: "general.arbitral_award.title",
      onClick: () => history.push("arbitral-awards"),
      condition: isArbitrator,
    },
    {
      label: "general.terms_of_reference.button",
      onClick: () => history.push("tor"),
      condition: isArbitrator,
    },
  ];

  const handleSendDocuments = (
    fileId,
    fileCategory,
    signatureRequired = false
  ) => {
    dispatch(
      postCreate(
        disputeId,
        fileId,
        fileCategory,
        { fileId, enableDigitalSign: signatureRequired },
        currentPage
      )
    );
  };

  const onPageChange = (page, type) => {
    dispatch(getIndex(disputeId, page));
    setCurrentPage(page);
  };

  return dispute ? (
    <IndexRender
      disputeParams={{ dispute, disputeId, hubSlug }}
      folders={folders}
      templates={[meta?.TermsOfReferenceTemplate, meta?.arbitralAwardTemplate]}
      isArbitrator={isArbitrator}
      extraCrumbs={extraCrumbs}
      pagination={fromMeta(meta, onPageChange)}
      disputeFiles={disputeFilesData}
      errors={errors}
      isWitness={isWitness}
      isUploading={isUploading}
      isLoading={wholeData?.isLoading}
      isUploadFormVisible={isUploadFormVisible}
      handleSendDocuments={handleSendDocuments}
      openSendDocuments={() => setIsUploadFormVisible(true)}
      closeSendDocuments={() => setIsUploadFormVisible(false)}
    />
  ) : (
    <Spinner />
  );
}
