import { message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "../../redux/actions/errorActions";
import { errorMessage } from "../../redux/selectors/errorSelector";

function Error() {
  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const errorMsg = useSelector((state) => errorMessage(state));

  useEffect(() => {
    if (errorMsg !== null) {
      message.error(errorMsg, 2, () => void dispatch(clearError()));
    }
  }, [errorMsg, dispatch]);

  return <></>;
}

export default Error;
