import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import {
  getShow,
  getShowMinutes,
  postCreateMinutes,
} from "../../redux/actions/hearingActions";
import { postCreateSignature } from "../../redux/actions/signActions";
import { deleteFile } from "../../redux/actions/fileActions";
import { PENDING } from "../../redux/constants/SignStatuses";
import {
  current,
  participantUserIds,
  selectShowMinutes,
  whole,
} from "../../redux/selectors/hearingsSelector";
import Spinner from "../Common/Spinner";
import ShowMinutesRender from "./ShowMinutesRender";

export default function () {
  const hubSlug = useHubSlug();
  const [disputeId, dispute] = useDispute();
  const { hearingId } = useParams();
  const hearing = useSelector(current);
  const minutes = useSelector(selectShowMinutes);
  const { isLoading } = useSelector(whole);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [fileID, setFileID] = useState(false);
  const canUpload =
    useAccess("update-minutes", "hearing") && !minutes?.file?.id;
  const canAddFields =
    useAccess("update-minutes", "hearing") &&
    minutes?.signatureStatus === "Envelope Created";
  const canSign =
    useAccess("show-minutes", "hearing") &&
    minutes?.userSignatureStatus === PENDING;
  const signerIds = useSelector(participantUserIds);

  const dispatch = useDispatch();

  useEffect(() => void dispatch(getShowMinutes(disputeId, hearingId)), [
    disputeId,
    hearingId,
    dispatch,
  ]);

  useEffect(() => {
    if (hearing?.id !== Number(hearingId) && canUpload)
      dispatch(getShow(disputeId, hearingId));
  }, [dispatch, disputeId, hearingId, hearing, canUpload]);

  const handleUpload = () => {
    dispatch(
      postCreateMinutes(disputeId, hubSlug, hearingId, fileID, signerIds)
    );
  };

  const uiRedirectUrl = `/${hubSlug}/disputes/${disputeId}/hearings/${hearingId}/minutes`;

  const handleSignStart = () =>
    dispatch(
      postCreateSignature({
        uiRedirectUrl,
        envelopeId: minutes.envelopeId,
        envelopeRedirectUrl: minutes.redirectURL,
      })
    );

  const handleDelete = () => {
    if (fileID) {
      dispatch(deleteFile(fileID));
      setFileID(null);
    }
  };

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/hearings`,
      breadcrumbName: "Hearings",
    },
    {
      path: "#",
      breadcrumbName: "Minutes",
    },
  ];

  return dispute && !isLoading ? (
    <ShowMinutesRender
      minutes={minutes}
      canUpload={canUpload}
      canAddFields={canAddFields}
      canSign={canSign}
      onUpload={handleUpload}
      onSignStart={handleSignStart}
      fileId={minutes?.file?.id}
      fileName={minutes?.file?.name}
      dispute={dispute}
      disputeId={disputeId}
      hubSlug={hubSlug}
      extraCrumbs={extraCrumbs}
      hearingId={hearingId}
      uiRedirectUrl={uiRedirectUrl}
      isUploadOpen={isUploadOpen}
      setIsUploadOpen={setIsUploadOpen}
      fileID={fileID}
      setFileID={setFileID}
      handleDelete={handleDelete}
    />
  ) : (
    <Spinner />
  );
}
