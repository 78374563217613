import { Col, Row, Typography } from "antd";
import React from "react";
import Translation from "../../components/Common/Translation";
import FolderOverview from "../Common/FolderOverview/index";
import PageHeader from "../Common/PageHeader";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import CaseFilesBreadcrumb from "../Common/CaseFilesBreadcrumb";
import "./styles.less";

const { Title } = Typography;

export default function ({ folders, isArbitrator, disputeParams, disputeFiles }) {
  const renderFoldersList = () => {
    return (
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Title level={5} className='jur-case-files-folders-title'>
            <Translation text="folders.folders.title" />
          </Title>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            {folders.map((folder, index) => {
              return (
                <Col className="gutter-row" span={8} key={index}>
                  <FolderOverview
                    folderLabel={folder.label}
                    onClick={folder.onClick}
                    toolTip={"folders.folders-tooltip-for-case-files.message"}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <SliderMenuWrapper {...disputeParams}>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={24}>
          <CaseFilesBreadcrumb />
        </Col>
        <Col className="gutter-row" span={24}>
          <PageHeader title={"arbitrator_files.arbitrator.text"} />
        </Col>
        <Col className="gutter-row" span={24}>
          {renderFoldersList()}
        </Col>
        {/* <Col className="gutter-row" span={24}>
          <Title level={5}>
            <Translation text="case_files.files.text" />
          </Title>
          <TableOverview data={disputeFiles} whiteHeader={true} />
        </Col> */}
      </Row>
    </SliderMenuWrapper>
  );
}
