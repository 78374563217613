import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHubSlug } from "../../helpers/hubHelpers";
import { useTimelineParams } from "../../helpers/timelineHelpers";
import { getShow } from "../../redux/actions/timelineActions";
import {
  selectEvents,
  selectIsFetching,
} from "../../redux/selectors/timelineSelector";
import Spinner from "../Common/Spinner";
import ShowRender from "./ShowRender";

export default function ({
  isEntireHistory = false,
  activityType,
  activityId,
}) {
  const { disputeId } = useParams();
  const hubSlug = useHubSlug();
  const dispatch = useDispatch();
  const timelineParams = useTimelineParams({
    isEntireHistory,
    activityType,
    activityId,
  });

  const events = useSelector(selectEvents);
  const isFetching = useSelector(selectIsFetching);
  const allDetailsLink = `/${hubSlug}/disputes/${disputeId}/history`;

  useEffect(() => void dispatch(getShow(disputeId, timelineParams)), [
    dispatch,
    disputeId,
    timelineParams,
  ]);

  if (isFetching) return <Spinner card={true} />;
  return (
    <ShowRender
      events={events}
      allDetailsLink={allDetailsLink}
      isEntireHistory={isEntireHistory}
    />
  );
}
