import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "disputeTor";
const catcher = logAndThrow(tag);

export function index(disputeId, page) {
  return Api.get(`/disputes/${disputeId}/tor?page=${page}`).catch(
    catcher("index")
  );
}

export function show(disputeId, torId) {
  return Api.get(`/disputes/${disputeId}/tor/${torId}`).catch(catcher("show"));
}

export function create(disputeId, body) {
  return Api.post(`/disputes/${disputeId}/tor`, body).catch(catcher("create"));
}

export function update(disputeId, torId, body) {
  return Api.put(`/disputes/${disputeId}/tor/${torId}`, body).catch(
    catcher("update")
  );
}
