export function fromAttributes(source = {}, extras = {}) {
  return { ...source, ...extras };
}

export function toName({
  salutation = "",
  name,
  fullName,
  companyName,
  firstName,
  lastName,
  isCompany = false,
}) {
  if (Boolean(isCompany)) return companyName;
  if (name) return `${name}`;

  if (!fullName) {
    return `${firstName} ${lastName} ${companyName ? `(${companyName})` : ""}`;
  }
  return name || fullName || companyName || firstName + " " + lastName;
}

export function toAvatar({ avatar, ...rest }) {
  return avatar || "https://i.imgur.com/ukhS2vu.jpg";
}

/**
 * Note: this is a temperory code to set an avatar to user
 */
// const avatarStore = new Map();
// function pixCache(user) {
//   const key = toName(user).length;
//   if (!avatarStore.has(key))
//     avatarStore.set(key, `https://picsum.photos/${100 + key}`);
//   return avatarStore.get(key);
// }
