import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "payments";
const catcher = logAndThrow(tag);

export function index(disputeId) {
  return Api.get(`/disputes/${disputeId}/payments`)
    .then((r) => r.data)
    .catch(catcher("index"));
}

export function create(disputeId, body) {
  return Api.post(`/disputes/${disputeId}/payments`, body).catch(
    catcher("create", "Failed to create payment")
  );
}

export function update(disputeId, paymentId, body) {
  return Api.put(`/disputes/${disputeId}/payments/${paymentId}`, body).catch(
    catcher("update", "Failed to update on payment")
  );
}

export function createTransaction(disputeId, paymentId, body) {
  return Api.post(
    `/disputes/${disputeId}/payments/${paymentId}/transactions`,
    body
  ).catch(
    catcher("createTransaction", "Failed to create a payment transaction")
  );
}

export function updateTransaction(disputeId, paymentId, transactionId, body) {
  return Api.put(
    `/disputes/${disputeId}/payments/${paymentId}/transactions/${transactionId}`,
    body
  ).catch(
    catcher("updateTransaction", "Failed to update a payment transaction")
  );
}
