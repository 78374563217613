import { Button, Space, Typography } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import React from "react";
import { toFormItem } from "../../../helpers/formHelpers";
import Spinner from "../../Common/Spinner";

export default function LastUpdateForm({
  lastUpdate,
  onSave,
  onUpdate,
  isModified,
}) {
  if (typeof lastUpdate === "undefined") return <Spinner card={true} />;
  return (
    <Formik initialValues={lastUpdate}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <Typography.Title level={5}>Last Update</Typography.Title>
            <Form layout="vertical">
              {toFormItem({ values, setFieldValue, propogateValues: onUpdate })(
                {
                  name: "title",
                  label: "Title",
                  optional: true,
                }
              )}
              {toFormItem({ values, setFieldValue, propogateValues: onUpdate })(
                {
                  name: "description",
                  label: "Description",
                  component: Input.TextArea,
                  componentProps: { autoSize: true },
                  optional: true,
                }
              )}
            </Form>
            <Space>
              <Button type="primary" onClick={onSave}>
                Save and Update
              </Button>
              {isModified && (
                <Typography.Text type="danger">Unsaved</Typography.Text>
              )}
            </Space>
          </>
        );
      }}
    </Formik>
  );
}
