export const fromMeta = (meta, onPageChange) =>
  meta?.pagination?.total >= 10
    ? {
        current: meta?.pagination?.current_page,
        total: meta?.pagination?.total,
        onChange: onPageChange,
        size: "small",
        showSizeChanger: false,
      }
    : false;
