import { Card, Divider, Table, Typography, Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import { Link } from "react-router-dom";
import DateTime from "../Common/DateTime";
import DisputeHeader from "../Common/DisputeHeader";
import TimelineWrapper from "../Common/TimelineWrapper";
import Translation from "../Common/Translation";
import Uploader, { UploadButtonRender } from "../Common/Uploader";

export default function ({
  dispute,
  disputeId,
  hubSlug,
  extraCrumbs,
  memorandums,
  pagination,
  canUpload,
  onUpload,
  hearingId,
  isUploadOpen,
  setIsUploadOpen,
  fileID,
  setFileID,
  handleDelete,
}) {
  return (
    <TimelineWrapper
      activityType="Hearing Memorandum Index"
      activityId={hearingId}
    >
      <Card>
        <DisputeHeader
          dispute={dispute}
          disputeId={disputeId}
          hubSlug={hubSlug}
          extraCrumbs={extraCrumbs}
        >
          <Typography.Title level={3}>
            <Translation text="hearings.memorandums.title" />
          </Typography.Title>
          {canUpload ? (
            <>
              <Button type="primary" onClick={() => setIsUploadOpen(true)}>
                <Translation text="hearings.upload_memorandum.button" />
              </Button>

              <Divider />
            </>
          ) : null}
          <Table
            columns={columns()}
            dataSource={memorandums}
            pagination={pagination}
            rowKey="id"
          />
        </DisputeHeader>
        <Modal
          destroyOnClose={true}
          visible={isUploadOpen}
          title={<Translation text="hearings.upload_memorandum.button" />}
          onCancel={() => {
            setIsUploadOpen(false);
            setFileID(null);
          }}
          footer={[
            <Button type="primary" disabled={!fileID} onClick={onUpload}>
              <Translation text="general.sign.button" />
            </Button>,
          ]}
        >
          <Uploader
            allowDocumentOnly={true}
            multiple={false}
            fileCategory="Hearing Minutes"
            onDone={(file) => setFileID(file?.id)}
            maxCount={1}
            name="hearingMinutes"
            onRemove={handleDelete}
          >
            <UploadButtonRender label="general.choose_file.button" />
          </Uploader>
        </Modal>
      </Card>
    </TimelineWrapper>
  );
}

function columns() {
  return [
    { label: "general.name.text", dataIndex: ["attributes", "fileName"] },
    {
      label: "hearings.submitted_by.column",
      dataIndex: ["attributes", "submittedBy"],
    },
    {
      label: "hearings.submitted_on.column",
      dataIndex: ["attributes", "submittedOn"],
      render: (epochSeconds) => <DateTime epochSeconds={epochSeconds} />,
    },
    {
      label: "general.action.column",
      dataIndex: "id",
      render: (id) => (
        <Link to={`memorandums/${id}`}>
          <Translation text="general.view.button" />
        </Link>
      ),
    },
  ].map((col) => ({ ...col, title: <Translation text={col.label} /> }));
}
