import React, { createElement, Fragment } from "react";
import Translation from "../Common/Translation";
import { Typography } from "antd";

const { Title } = Typography;

const AsteriskInput = ({ text, isWrappedInTypography = false }) => {
  const TypographyProps = isWrappedInTypography
    ? {
        level: 5,
      }
    : {};

  const renderQuestionTitle = () => {
    return (
      <>
        <Translation text={text} />
        <span className="jur-auth-required-asterisk">*</span>
      </>
    );
  };

  return createElement(isWrappedInTypography ? Title : Fragment, TypographyProps, renderQuestionTitle());
};

export default AsteriskInput;
