import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { fromMeta } from "../../transformers/paginationTransformer";
import ActionTitle from "../Common/ActionTitle";
import Anchor from "../Common/Anchor";
import Translation from "../Common/Translation";
import JurContentWrapper from "../Layouts/JurContentWrapper";

export default function IndexRender({ list, meta, onPageChange }) {
  return (
    <JurContentWrapper>
      <Card type="inner">
        <br />
        <ActionTitle
          title={<Translation text="roster.invited_arbitrators.title" />}
          actions={
            <Link to="/roster/invite-arbitrator">
              <Button type="primary" icon={<PlusOutlined />}>
                <Translation
                  text="roster.invite_arbitrator.action"
                  pad={true}
                />
              </Button>
            </Link>
          }
        />
        <Table
          columns={columns()}
          dataSource={list}
          rowKey="id"
          pagination={fromMeta(meta, onPageChange)}
        />
      </Card>
    </JurContentWrapper>
  );
}

const columns = () => [
  {
    title: <Translation text="roster.name.column" />,
    render: (text, record) =>
      `${record?.attributes?.firstName} ${record?.attributes?.lastName}`,
  },
  {
    title: <Translation text="roster.bar_admission.column" />,
    dataIndex: ["attributes", "barAdmission"],
  },
  {
    title: <Translation text="roster.email.column" />,
    dataIndex: ["attributes", "email"],
  },
  {
    title: <Translation text="roster.gender.column" />,
    dataIndex: ["attributes", "gender"],
  },
  {
    title: <Translation text="roster.linkedin_url.column" />,
    dataIndex: ["attributes", "linkedinUrl"],
  },
  {
    title: <Translation text="roster.status.column" />,
    dataIndex: ["attributes", "status"],
  },
  {
    title: <Translation text="roster.panels.column" />,
    dataIndex: ["attributes", "panels"],
  },
  {
    title: <Translation text="roster.actions.column" />,
    render: (text, record) => (
      <Anchor to={`/roster/profile/${record.id}`} text="general.view.button" />
    ),
  },
];
