import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Translation from "../Translation";
import { defaultFormats } from "./config";
import "./styles.less";

export default function Viewer({
  modules = {},
  formats = defaultFormats,
  label = "",
  value = "",
  hideToggle = false,
  ...rest
}) {
  const showLengthToggler = false;
  const [viewEntireText, setViewEntireText] = useState(!showLengthToggler);
  const toggleView = () => {
    setViewEntireText(!viewEntireText);
  };
  const buttonText = viewEntireText
    ? "general.view_less.button"
    : "general.view_more.button";
  const buttonIcon = viewEntireText ? <MinusOutlined /> : <PlusOutlined />;

  return (
    <div className={!viewEntireText ? "jur-claim-info-collapsed" : null}>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={5}>
            <Translation text={label} />
          </Typography.Title>
        </Col>
        <Col>
          {showLengthToggler ? (
            <Button onClick={toggleView} type="link" icon={buttonIcon}>
              <Translation text={buttonText} pad={true} />
            </Button>
          ) : null}
        </Col>
      </Row>
      <ReactQuill
        className={
          viewEntireText ? "jur-quill-viewer" : "jur-quill-viewer-collapsed"
        }
        theme={null}
        readOnly={true}
        modules={modules}
        formats={formats}
        value={value}
        {...rest}
      />
    </div>
  );
}
