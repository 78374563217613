import { call, put, takeLatest } from "redux-saga/effects";
import { handleError, handleIndex } from "../actions/timelineActions";
import * as api from "../apis/timelineApi";
import { TIMELINE_GET_SHOW } from "../constants/constants";

export function* getShow(action) {
  const { disputeId, body } = action.payload;
  try {
    const events = yield call(api.show, disputeId, body);
    yield put(handleIndex(events));
  } catch (e) {
    console.error("stats saga getShow error:", e);
    yield put(handleError(e));
  }
}

export default function* timelineSaga() {
  yield takeLatest(TIMELINE_GET_SHOW, getShow);
}
