import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import React from "react";
import Translation from "../Translation";

export function successNotification(text) {
  notification.open({
    message: "Notification",
    description: <Translation text={text} />,
    icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
  });
}
