import { Tabs } from "antd";
import React from "react";
import AchievementTab from "./AchievementTab";
import GeneralTab from "./GeneralTab";
import ProfessionalTab from "./ProfessionalTab";

const { TabPane } = Tabs;

function ProfileTabs({ profile }) {
  return (
    <Tabs
      defaultActiveKey="1"
      tabBarStyle={{ backgroundColor: "white", paddingLeft: 10 }}
    >
      <TabPane tab="Professional" key="1">
        <ProfessionalTab professionalInformation={profile} />
      </TabPane>
      <TabPane tab="Academic" key="2">
        <GeneralTab profile={profile} />
      </TabPane>
      <TabPane tab="Achievements" key="3">
        <AchievementTab achievements={profile} />
      </TabPane>
    </Tabs>
  );
}

export default ProfileTabs;
