export const selectIsNextDisabled = (state) =>
  state.responseFiling.isNextDisabled;

export const selectDisputeId = (state) => state.responseFiling.disputeId;
export const selectCurrentStep = (state) => state.responseFiling.currentStep;
export const selectIsLoadingKYC = (state) => state.responseFiling.isLoadingKYC;
export const selectErrors = (state) => state.responseFiling.errors;
export const selectIsErrorHidden = (state) =>
  state.responseFiling.isErrorHidden;

export const selectDefenceTeam = (state) => state.responseFiling.defenceTeam;

export const selectKyc = (state) => state.responseFiling.kyc;
export const selectKycErrors = (state) =>
  Object.entries(selectErrors(state) || {}).reduce((acc, [key, value]) => {
    if (key.startsWith("kyc.")) acc[key.replace("kyc.", "")] = value;
    return acc;
  }, {});

export const selectInvites = (state) => state.responseFiling.userInvites;
export const selectInvitesMeta = (state) =>
  state.responseFiling.userInvitesMeta;
export const selectUserInvitesErrors = (state) =>
  Object.entries(selectErrors(state) || {}).reduce((acc, [key, value]) => {
    if (key === "mainRespondent") acc[key] = value;
    if (key.startsWith("userInvites.")) {
      const [, index, fieldName] = key.split(".");
      acc[index] = acc[index] || {};
      acc[index][fieldName] = value;
    }
    return acc;
  }, {});
export const selectMainRespondent = (state) =>
  state.responseFiling.mainRespondent;

export const selectRespondent = (state) => state.responseFiling.respondent;

export const selectResponseDetails = (state) =>
  state.responseFiling.responseDetails;
export const selectResponseOptions = (state) =>
  state.responseFiling.responseOptions;

export const selectResponseFiles = (state) =>
  state.responseFiling.responseFiles;

export const selectPayment = (state) => state.responseFiling.payment;
export const selectPaymentMeta = (state) => state.responseFiling.paymentMeta;
export const selectSod = (state) => state.responseFiling.sod;
