import { CLEAR_ERROR, SET_ERROR } from "../constants/constants";

const initialState = {
  error: null,
};

export default (state = initialState, action) => {
  const { error, type } = action;

  if (error && type === SET_ERROR) {
    return {
      error: {
        code: error.code,
        message: error.message,
      },
    };
  }

  if (type === CLEAR_ERROR) {
    return {
      error: null,
    };
  }

  return state;
};
