import * as types from "../constants/constants";

export const postCreate = (disputeId, body, resetForm) => {
  return {
    type: types.PROCEDURALORDER_POST_CREATE,
    payload: { disputeId, body, resetForm },
  };
};

export const handleCreate = () => ({
  type: types.PROCEDURALORDER_HANDLE_CREATE,
});

export const putUpdate = (disputeId, body, resetForm) => {
  return {
    type: types.PROCEDURALORDER_PUT_UPDATE,
    payload: { disputeId, body, resetForm },
  };
};

export const handleUpdate = () => ({
  type: types.PROCEDURALORDER_HANDLE_UPDATE,
});

export const postCreateRevision = (disputeId, body, resetForm) => {
  return {
    type: types.PROCEDURALORDER_POST_CREATE_REVISION,
    payload: { disputeId, body, resetForm },
  };
};

export const getFlowList = (disputeId, flow) => {
  return {
    type: types.PROCEDURALORDER_GET_FLOW_LIST,
    payload: { disputeId, flow },
  };
};

export const handleFlowList = (data) => ({
  type: types.PROCEDURALORDER_HANDLE_FLOW_LIST,
  payload: data,
});

export const getVersions = (disputeId, orderId) => {
  return {
    type: types.PROCEDURALORDER_GET_VERSIONS,
    payload: { disputeId, orderId },
  };
};

export const handleVersions = (data, orderId) => ({
  type: types.PROCEDURALORDER_HANDLE_VERSIONS,
  payload: { data, orderId },
});

export const putUpdateCompleted = (disputeId, params) => ({
  type: types.PROCEDURALORDER_PUT_UPDATE_COMPLETED,
  payload: { disputeId, params },
});

export const handleSignatureError = (disputeId, params) => ({
  type: types.PROCEDURALORDER_HANDLE_SIGNATURE_ERROR,
  payload: { disputeId, params },
});
