import { Modal } from "antd";
import React from "react";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import ActivationMessage from "../../components/Signs/ActivationMessage";
import { logAndPass } from "../../helpers/sagaHelpers";
import * as arbitralAwardActions from "../actions/arbitralAwardActions";
import * as disputeAssignmentActions from "../actions/disputeAssignmentActions";
import * as disputeFileActions from "../actions/disputeFileActions";
import * as disputeFilingActions from "../actions/disputeFilingActions";
import * as disputeTorActions from "../actions/disputeTorActions";
import * as evidenceRequestActions from "../actions/evidenceRequestActions";
import * as hearingActions from "../actions/hearingActions";
import * as proceduralOrderActions from "../actions/proceduralOrderActions";
import * as responseFilingActions from "../actions/responseFilingActions";
import * as actions from "../actions/signActions";
import * as apis from "../apis/signApi";
import * as types from "../constants/constants";

const USER_AUTH = "user-auth";

const tag = "sign";
/**
 *
 * @param {Object} action = { type, payload }
 * payload contains all the information needed to complete signature flow
 *
 * Use this single function to complete a sinature flow
 */
export function* postCreateSignature({ payload }) {
  const {
    envelopeId, // int?: backend can provide this
    envelopeRedirectUrl, // url?: backend can provide this
    fileIds, // int[]?: file's ids after upload completed <- file service
    flow, // str: UI specific flow for the signature
    signType, // str: either Self Sign or Group Sign
    disputeId, // int
    hubSlug, // str
    uiRedirectUrl, // url: frontend path to come back from DocuSign
    onEnvelopeCreated, // fn?: an API call to bind signature envelope with disputeId
    signerIds, // int[]: user ids for users required to sign (in case of group sign)
    signableId, // int[]: id of dispute or signable item eg. TOR
  } = payload;

  try {
    yield put(actions.handleIsRedirecting(true));
    const resConsent = yield call(apis.consent);

    if (!resConsent?.data?.attributes?.activationStatus) {
      yield Modal.info({
        title: <ActivationMessage />,
      });
      throw new Error("Activation Missing");
    }

    if (!resConsent?.data?.attributes?.consentStatus) {
      yield getConsent(uiRedirectUrl);
    } else if (envelopeId) {
      window.location = envelopeRedirectUrl;
    } else {
      yield put(
        actions.postCreateEnvelope(
          { fileIds, flow, signType, signerIds, signableId },
          disputeId,
          onEnvelopeCreated,
          hubSlug
        )
      );
    }
  } catch (e) {
    yield put(actions.handleIsRedirecting(false));
    yield put(logAndPass(e, tag, actions));
  }
}

export function* getConsentStatus(action) {
  const { redirectUrl } = action.payload;
  const resConsent = yield call(apis.consent);
  yield put(actions.handleConsent());
  if (!resConsent?.data?.attributes?.activationStatus) {
    yield Modal.info({
      title: <ActivationMessage />,
    });
  } else if (!resConsent?.data?.attributes?.consentStatus) {
    yield getConsent(redirectUrl);
  } else {
    yield put(actions.updateConsentStatus());
    let userAuth = localStorage.getItem(USER_AUTH);
    userAuth = JSON.parse(userAuth);
    userAuth.signatureStatus.consentStatus = true;
    userAuth.signatureStatus.activationStatus = true;
    localStorage.setItem(USER_AUTH, JSON.stringify(userAuth));
  }
}

export function* getConsent(redirectTo) {
  const resConsentUrl = yield call(apis.consentUrl, redirectTo);
  yield put(actions.handleConsentUrl(resConsentUrl?.data?.attributes?.url));
}

export function* redirectToConsentUrl(action) {
  const { url } = action.payload;

  // yield false;
  try {
    yield Modal.confirm({
      title: "Please allow OJP to connect with your Docusign account.",
      okText: "OK",
      cancelText: "Cancel",
      onOk: () => (window.location = url),
    });
    throw new Error("Consent Denied");
  } catch (e) {
    console.error(tag, "saga redirectToConsentUrl error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreateEnvelope(action) {
  const { body, disputeId, onEnvelopeCreated, hubSlug } = action.payload;
  try {
    const resCreated = yield call(apis.createEnvelope, disputeId, hubSlug, body);

    if (onEnvelopeCreated)
      yield call(onEnvelopeCreated, disputeId, {
        moduleName: "DocuSign",
        envelopeId: resCreated?.data?.id,
      });

    if (resCreated?.data?.attributes?.redirectURL) window.location = resCreated?.data?.attributes?.redirectURL;
    yield false;
  } catch (e) {
    console.error(tag, "saga postCreateEnvelope error:", e);
    yield put(actions.handleIsRedirecting(false));
    yield put(actions.handleError(e));
  }
}

export function* putUpdateEnvelope(action) {
  const { body } = action.payload;
  try {
    if (body?.event === "signing_complete") {
      const res = yield call(apis.updateEnvelope, body);
      yield put(actions.handleUpdateEnvelope(res));
      const actionObject = onUpdateEnvelope(res);
      if (actionObject) yield put(actionObject);
    } else {
      const errorObject = handleEnvelopeError(body);
      if (errorObject) yield put(errorObject);
    }
  } catch (e) {
    console.error(tag, "saga putUpdateEnvelope error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateEnvelopeAt(action) {
  const { body } = action.payload;
  try {
    if (body?.event === "Send") {
      let res = yield call(apis.updateEnvelopeAt, body);
      yield put(actions.handleUpdateEnvelope(res));
      const actionObject = onUpdateEnvelope(res);
      if (actionObject) yield put(actionObject);
    } else {
      const errorObject = handleEnvelopeError(body);
      if (errorObject) yield put(errorObject);
    }
  } catch (e) {
    console.error(tag, "saga putUpdateEnvelopeAt error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* () {
  yield takeEvery(types.SIGN_GET_CONSENT, getConsentStatus);
  yield takeEvery(types.SIGN_GET_CONSENT_URL, getConsent);
  yield takeLatest(types.SIGN_HANDLE_CONSENT_URL, redirectToConsentUrl);
  yield takeEvery(types.SIGN_POST_CREATE_ENVELOPE, postCreateEnvelope);
  yield takeEvery(types.SIGN_PUT_UPDATE_ENVELOPE, putUpdateEnvelope);
  yield takeEvery(types.SIGN_PUT_UPDATE_ENVELOPE_AT, putUpdateEnvelopeAt);
  yield takeLatest(types.SIGN_POST_CREATE_SIGNATURE, postCreateSignature);
}

function onUpdateEnvelope(params) {
  const { disputeId, flow } = params?.data?.attributes;

  const documentTypes = [
    "Opening Statement",
    "Closing Statement",
    "Reply to order",
    "Evidentiary Document",
    "Witness Statement",
    "Expert Report",
  ];

  if (flow === "Appointment Document" || flow === "Statement Of Acceptance" || flow === "Arbitrator Acceptance") {
    return disputeAssignmentActions.handleSignCompleted(disputeId, params);
  }

  if (flow === "Hearing Memorandum") {
    return hearingActions.handleSignMemorandumCompleted(disputeId, params);
  }

  if (flow === "Hearing Minutes") {
    return hearingActions.handleSignMinutesCompleted(disputeId, params);
  }

  if (flow === "Statement Of Claim") {
    return disputeFilingActions.putUpdateCompleted(disputeId, params);
  }

  if (documentTypes.includes(flow)) {
    return disputeFileActions.putUpdateCompleted(disputeId, params);
  }

  if (flow === "Statement Of Defence") {
    return responseFilingActions.putUpdateCompleted(disputeId, params);
  }
  if (flow === "Terms Of Reference") {
    return disputeTorActions.putUpdateCompleted(disputeId, params);
  }
  if (flow === "Evidence Request") {
    return evidenceRequestActions.putUpdateCompleted(disputeId, params);
  }

  if (flow === "Arbitral Award" || flow === "Additional Arbitral Award") {
    return arbitralAwardActions.putUpdateCompleted(disputeId, params);
  }

  if (flow === "Procedural Order") {
    return proceduralOrderActions.putUpdateCompleted(disputeId, params);
  }
}

function handleEnvelopeError(params) {
  const { disputeId, flow } = params;

  if (flow === "Appointment Document" || flow === "Statement Of Acceptance" || flow === "Arbitrator Acceptance") {
    return disputeAssignmentActions.handleSignatureError(disputeId, params);
  }

  if (flow === "Hearing Memorandum" || flow === "Hearing Minutes") {
    return hearingActions.handleSignatureError(disputeId, params);
  }

  if (flow === "Statement Of Claim") {
    return disputeFilingActions.handleSignatureError(disputeId, params);
  }

  if (flow === "Statement Of Defence") {
    return responseFilingActions.handleSignatureError(disputeId, params);
  }

  if (flow === "Terms Of Reference") {
    return disputeTorActions.handleSignatureError(disputeId, params);
  }

  if (flow === "Evidence Request") {
    return evidenceRequestActions.handleSignatureError(disputeId, params);
  }

  if (flow === "Arbitral Award" || flow === "Additional Arbitral Award") {
    return arbitralAwardActions.handleSignatureError(disputeId, params);
  }

  if (flow === "Procedural Order") {
    return proceduralOrderActions.handleSignatureError(disputeId, params);
  }
}
