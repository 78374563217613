import { logAndThrow } from "../../helpers/apiHelpers";
import { hubless } from "./Api";

const tag = "user-profile";
const catcher = logAndThrow(tag);

export function show() {
  return hubless
    .get(`users/me`)
    .then((res) => res.data)
    .catch(catcher("show"));
}

export function update(body) {
  return hubless
    .put(`users/me`, body)
    .catch(catcher("update"))
    .then((res) => res.data);
}
