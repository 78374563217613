import { Col, Row } from "antd";
import React from "react";

function CenteredContent({ children, contentWidth = 17, colProps = {} }) {
  return (
    <div className="jur-centered-layout">
      <Row justify="space-around" align="middle">
        <Col {...colProps} span={contentWidth}>
          {children}
        </Col>
      </Row>
    </div>
  );
}

export default CenteredContent;
