import React from "react";
import "../styles.less";

export default function JurCopyright() {
  return (
    <div className="jur-copyright-text">
      Copyright ©2021 Product by Jur Inc.
    </div>
  );
}
