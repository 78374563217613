import { FormItem, Input, Select } from "formik-antd";
import React from "react";
import { errorAsExtra } from "../../helpers/errorHelpers";
import { inputProps } from "../../helpers/formHelpers";
import Translation from "../Common/Translation";

export function userRoleSelect(
  name,
  onChange,
  label = "general.role.text",
  ...props
) {
  return (
    <FormItem name={name} label={<Translation text={label} />}>
      <Select name={name} onSelect={onChange(name)} {...props}>
        {[
          {
            value: "Legal Representative",
            label: "general.legal_representative.text",
          },
          { value: "Attorney", label: "general.attorney.text" },
        ].map((option) => (
          <Select.Option value={option.value} key={option.value}>
            <Translation text={option.label} />
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
}

export const handleChange = (values, setFieldValue, propogateValues) => (
  name
) => (e) => {
  const value = e?.target ? e.target?.value : e;
  if (setFieldValue) setFieldValue(name, value);
  if (propogateValues)
    propogateValues({ ...values, [name]: value }, name, value);
};

export function toFormItem({
  propogateValues,
  values,
  setFieldValue,
  errors,
  errorProps = {},
  compact = false,
  t = null,
} = {}) {
  const { isHidden, hideAndCall } = errorProps;
  const handleChangeOf = handleChange(values, setFieldValue, propogateValues);
  const apiErrorOf = errorAsExtra(errors, isHidden);

  return (item, index) => {
    const Field = item.component ? item.component : Input;
    const componentProps = item.component ? item.componentProps : inputProps();
    const onChangeHandler = hideAndCall
      ? hideAndCall(handleChangeOf(item.name))
      : handleChangeOf(item.name);

    return (
      <FormItem
        label={compact ? undefined : <Translation text={item.label} />}
        name={item.name}
        key={index}
        style={compact ? { maxWidth: 140 } : undefined}
        required={!item.optional}
        {...apiErrorOf(item.name, true)}
      >
        <Field
          name={item.name}
          type={item.type}
          className="jur-input-width-fix"
          placeholder={compact ? t(item.label) : undefined}
          onChange={onChangeHandler}
          {...componentProps}
        />
      </FormItem>
    );
  };
}
