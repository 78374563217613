import React from "react";
import { NamespacesConsumer } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { readLoggedInUser } from "../../helpers/authHelpers";
import { getInviteeErrors } from "../../helpers/formHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import {
  handleUpdateDefenceTeamForm,
  handleUpdateInvitesForm,
  putUpdateDefenceTeam,
} from "../../redux/actions/responseFilingActions";
import { GUEST } from "../../redux/constants/UserRoleConstants";
import {
  selectDefenceTeam,
  selectErrors,
  selectInvites,
  selectIsErrorHidden,
} from "../../redux/selectors/responseFilingSelector";
import InviteDefenceRender from "./InviteDefenceRender";

function InviteDefenceHOC() {
  const hubSlug = useHubSlug();
  const { disputeId } = useParams();
  const dispatch = useDispatch();
  const errors = useSelector(selectErrors);

  const defenceTeam = useSelector(selectDefenceTeam);
  const isErrorHidden = useSelector(selectIsErrorHidden);
  const invites = useSelector(selectInvites);
  const currentUserEmail = readLoggedInUser()?.email;

  const handleUpdateForm = (values) => {
    dispatch(handleUpdateDefenceTeamForm(values));
  };

  const addInviteItem = () => {
    dispatch(handleUpdateInvitesForm(addOne(invites)));
  };

  const handleUpdateInvites = (index) => (values) => {
    const resultant = [...invites];
    resultant[index] = values;
    dispatch(handleUpdateInvitesForm(resultant));
  };

  const removeInviteItem = (index) => () => {
    const updatedInvites = [...(invites || [])];
    updatedInvites.splice(index, 1);
    dispatch(handleUpdateInvitesForm(updatedInvites));
  };

  const defenderOptions = (
    invites
      ?.filter((x) => Boolean(x.email) && Boolean(x.role))
      .filter((u) => u.role !== GUEST)
      .map((invite, i) => ({
        value: invite?.email,
        label: `${invite?.email} - ${invite?.role}`,
      })) ?? []
  ).concat(
    defenceTeam?.role
      ? {
          value: currentUserEmail,
          label: `${currentUserEmail} - ${defenceTeam?.role}`,
        }
      : []
  );

  const handleSubmit = () => {
    dispatch(
      putUpdateDefenceTeam(
        disputeId,
        { ...defenceTeam, invitees: invites },
        hubSlug
      )
    );
  };

  const inviteeErrors = getInviteeErrors(errors);

  return (
    <NamespacesConsumer>
      {(t) => (
        <InviteDefenceRender
          onUpdateForm={handleUpdateForm}
          onAddInviteItem={addInviteItem}
          invites={invites}
          onUpdateInvitesForm={handleUpdateInvites}
          onRemoveInviteItem={removeInviteItem}
          t={t}
          errors={errors}
          errorProps={{ isHidden: isErrorHidden }}
          inviteeErrors={inviteeErrors}
          defenceTeam={defenceTeam}
          defenderOptions={defenderOptions}
          onSubmit={handleSubmit}
        />
      )}
    </NamespacesConsumer>
  );
}
export default InviteDefenceHOC;

function addOne(array = []) {
  const maxCuid = array?.length ? Math.max(...array.map((i) => i.cuid)) : 0;
  return [].concat(array).concat({
    cuid: maxCuid * 2 + 1,
  });
}
