import { call, put, takeLatest } from "redux-saga/effects";
import { toDisputeUpdateBody } from "../../transformers/payloadTransformer";
import * as actions from "../actions/disputeActions";
import * as apis from "../apis/disputesApi";
import * as types from "../constants/constants";

const tag = "disputes";

export function* getIndex(action) {
  try {
    const disputes = yield call(apis.index, action?.payload);
    yield put(
      actions[
        action?.payload?.only === "requested"
          ? "handleIndexRequested"
          : "handleIndex"
      ](disputes)
    );
  } catch (e) {
    console.error("disputeSaga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShow(action) {
  const { disputeId } = action.payload;
  try {
    const res = yield call(apis.show, disputeId);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateClose(action) {
  const { disputeId } = action.payload;
  try {
    const res = yield call(apis.updateClose, disputeId);
    yield put(actions.handleUpdate(res));
    yield put(actions.getShow(disputeId));
  } catch (e) {
    console.error(tag, "saga putUpdateClose error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdate(action) {
  const { disputeId } = action.payload;
  try {
    const res = yield call(
      apis.update,
      disputeId,
      toDisputeUpdateBody(action.payload)
    );
    yield put(actions.handleUpdate(res));
    yield put(actions.getShow(disputeId));
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* disputeSaga() {
  yield takeLatest(types.DISPUTE_GET_INDEX, getIndex);
  yield takeLatest(types.DISPUTE_GET_INDEX_REQUESTED, getIndex);
  yield takeLatest(types.DISPUTE_GET_SHOW, getShow);
  yield takeLatest(types.DISPUTE_PUT_UPDATE, putUpdate);
  yield takeLatest(types.DISPUTE_PUT_UPDATE_CLOSE, putUpdateClose);
}
