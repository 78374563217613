import React from "react";
import TaskListSection from "./TaskListSection";

const groups = ["Claimant", "Respondent", "Arbitrator"];

export default function TaskLists({
  lastUpdateProps,
  taskListProps,
  hasModified,
}) {
  return (
    <div>
      <br />
      {groups.map((g) => (
        <TaskListSection
          key={g}
          group={g}
          lastUpdateProps={lastUpdateProps}
          taskListProps={taskListProps}
          hasModifiedActivity={hasModified[`${g.toLocaleLowerCase()}Activity`]}
          hasModifiedTask={hasModified[`${g.toLocaleLowerCase()}Task`]}
        />
      ))}
    </div>
  );
}
