import { Col, Row, Typography } from "antd";
import React from "react";
import {
  HUB_REJECTED,
  ARBITRATION_CLOSED_BY_HUB,
  AWAITING_ARBITRATOR_ACCEPTANCE
} from "../../redux/constants/DisputeStatuses";
import Translation from "../Common/Translation";
import NoData from "../Common/NoData";
import LeftSlider from "./LeftSlider";
import LastUpdate from "./Show/AssistedDispute/LastUpdate";
import { TaskList } from "./Show/AssistedDispute/TaskList";
import TimePhase from "./Show/AssistedDispute/TimePhase";
import NotificationBanner from "./Show/NotificationBanner";
import RightSlider from "./Show/RightSlider";
import CaseClosing from "./CaseClosing";

const { Title } = Typography;

export default function ({
  disputeParams,
  userName,
  disputeMeta,
  onUpdateTaskStatus,
}) {
  const isDisputeClosed = disputeParams.dispute?.status === ARBITRATION_CLOSED_BY_HUB;
  const isAwaitingArbitratorAcceptance = disputeParams.dispute?.status === AWAITING_ARBITRATOR_ACCEPTANCE;

  return (
    <Row>
      <NotificationBanner disputeMeta={disputeMeta} {...disputeParams} />
      <Col span={4}>
        <LeftSlider {...disputeParams} />
      </Col>
      <Col span={14}>
        <MainContent
          {...disputeParams}
          userName={userName}
          isDisputeClosed={isDisputeClosed}
          onUpdateTaskStatus={onUpdateTaskStatus}
        />
      </Col>
      <Col span={6}>
        <RightSlider {...disputeParams} isDisputeClosed={isDisputeClosed} isAwaitingArbitratorAcceptance={isAwaitingArbitratorAcceptance} />
      </Col>
    </Row>
  );
}  

const MainContent = ({ dispute, userName, isDisputeClosed, onUpdateTaskStatus }) => {
  const isStatusHubRejected = dispute.status === HUB_REJECTED;
  const { assistedDispute } = dispute;

  return (
    <div className="jur-dispute-show-main-content">
      <Title level={2}>
        <Translation text="dispute.overview.title" />{" "}
      </Title>
      {isDisputeClosed ? (
        <CaseClosing dispute={dispute} userName={userName} />
      ) : assistedDispute?.phases?.length ? (
        <>
          <TimePhase dispute={dispute} userName={userName} />
          <LastUpdate condition={!isStatusHubRejected} assistedDispute={assistedDispute} />
          <TaskList
            condition={!isStatusHubRejected}
            assistedDispute={assistedDispute}
            onUpdateTaskStatus={onUpdateTaskStatus}
          />
        </>
      ) : (
        <NoData
          description={
            <Title level={5} strong>
              <Translation text="dispute.jur_reviewing.text" />
            </Title>
          }
        />
      )}
    </div>
  );
};
