import * as types from "../constants/constants";

export const getShow = (disputeId, body) => ({
  type: types.TIMELINE_GET_SHOW,
  payload: { disputeId, body },
});

export const handleIndex = (events) => ({
  type: types.TIMELINE_HANDLE_SHOW,
  payload: events,
});

export const handleError = (e) => ({
  type: types.TIMELINE_HANDLE_ERROR,
  error: e,
});
