import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { getShowMemorandum } from "../../redux/actions/hearingActions";
import { currentMemorandum } from "../../redux/selectors/hearingsSelector";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import ShowMemorandumRender from "./ShowMemorandumRender";

export default function () {
  const hubSlug = useHubSlug();
  const [disputeId, dispute] = useDispute();
  const { hearingId, memorandumId } = useParams();
  const memo = useSelector(currentMemorandum);

  const dispatch = useDispatch();

  useEffect(
    () => void dispatch(getShowMemorandum(disputeId, hearingId, memorandumId)),
    [disputeId, hearingId, memorandumId, dispatch]
  );

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/hearings`,
      breadcrumbName: <Translation text="hearings.hearings.title" />,
    },
    {
      path: `/${hubSlug}/disputes/${disputeId}/hearings/${hearingId}/memorandums`,
      breadcrumbName: <Translation text="hearings.memorandums.title" />,
    },
  ];

  return dispute ? (
    <ShowMemorandumRender
      memo={memo}
      memorandumId={memorandumId}
      fileId={memo?.attributes?.file?.id}
      fileName={memo?.attributes?.file?.name}
      dispute={dispute}
      disputeId={disputeId}
      hubSlug={hubSlug}
      extraCrumbs={extraCrumbs}
    />
  ) : (
    <Spinner />
  );
}
