import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { isAuthenticated } from "../redux/selectors/authSelector";

function PrivateRoute({ location, children, ...rest }) {
  let element = React.createElement(children.type, { location: location });
  let _isAuthenticated = useSelector(isAuthenticated);

  // let isAuthenticated = window.store.getState().authenticate.isAuthenticated;

  if (!_isAuthenticated) {
    // message.error("You must be logged in to view this page");
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        _isAuthenticated ? (
          element
        ) : (
          <Redirect
            to={{
              pathname: "/authenticate",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
