import { Formik } from "formik";
import { Form, FormItem, Select } from "formik-antd";
import { Typography } from "antd";
import React, { useEffect } from "react";
import * as yup from "yup";
import { errorAsExtra } from "../../helpers/errorHelpers";
import { toFormItem, validateHtmlLength } from "../../helpers/formHelpers";
import Editor from "../Common/Editor";
import { QuestionHelpText, QuestionTitle } from "../Common/FormQuestion";
import JurLink from "../Common/JurLink";
import Translation from "../Common/Translation";
import { handleChange } from "./helpers";
import InputFormatter from "../Common/InputFormatter";
import { currencyFormatter } from "../../helpers/dataHelpers";
import AsteriskInput from "../Common/AsteriskInput";

function optionsFrom(list) {
  return list?.map((entity, index) => (
    <Select.Option key={index} value={entity}>
      {entity}
    </Select.Option>
  ));
}

export default function Claim({
  onUpdate,
  claim = {},
  seatsOfArbitration,
  applicableLaws,
  specializations,
  errors,
  errorProps,
}) {
  useEffect(() => {
    //TODO: Make a common hook for focus on errors like useErrorFocus
    const keys = errors && Object.keys(errors);
    if (keys?.length > 0) {
      const selector = `[name="${keys[0]}"]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) errorElement.focus();
    }
  }, [errors]);

  return (
    <Formik initialValues={claim} errors={errors} validationSchema={claimValidate}>
      {({ values, setFieldValue, errors: formikErrors }) => {
        const formItemProps = {
          values,
          setFieldValue,
          propogateValues: onUpdate,
          errors,
          errorProps,
          formikErrors,
        };

        const handleChangeOf = handleChange(values, setFieldValue, onUpdate);
        const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);

        const seatOfArbitration = () => (
          <>
            {toFormItem(formItemProps)({
              name: "seatOfArbitration",
              customLabel: <QuestionTitle text="claimant.select_seat_of_arbitration.text" />,
              helpText: (
                <>
                  <Translation text="general.see_jur_faq_on.text" />
                  <JurLink to="/faq">
                    <Translation text="claimant.how_to_select_seat_of_arbitration.text" />
                  </JurLink>
                </>
              ),
              component: Select,
              componentProps: {
                showSearch: true,
                children: optionsFrom(seatsOfArbitration),
              },
              placeholder: "Select",
            })}
          </>
        );

        const ApplicableLaw = () => (
          <>
            {toFormItem(formItemProps)({
              name: "applicableLaw",
              customLabel: <QuestionTitle text="claimant.select_applicable_law.text" />,
              helpText: (
                <>
                  <Translation text="general.see_jur_faq_on.text" />
                  <JurLink to="/faq">
                    <Translation text="claimant.how_to_select_the_applicable_law.text" />
                  </JurLink>
                </>
              ),
              component: Select,
              componentProps: {
                showSearch: true,
                children: optionsFrom(applicableLaws),
              },
              placeholder: "Select",
            })}
          </>
        );

        const Specializations = () => (
          <>
            {toFormItem(formItemProps)({
              name: "specializations",
              customLabel: <QuestionTitle text="claimant.what_area_of_law_is_applicable.text" />,
              component: Select,
              componentProps: {
                showSearch: true,
                children: optionsFrom(specializations),
                mode: "tags",
              },
              placeholder: "Select",
            })}
          </>
        );

        const claimValue = () => (
          <InputFormatter
            prefix="USD"
            name="claimValue"
            label="claimant.what_is_claim_value.text"
            errors={errors}
            defaultValue={values?.claimValue}
            onChange={handleChangeOf("claimValue")}
            formatter={(value) => currencyFormatter(value)}
          />
        );

        const textFields = [
          {
            name: "subjectMatter",
            question: <QuestionTitle text="claimant.short_indication_of_subject.text" />,
            helpText: (
              <QuestionHelpText>
                <Translation text="claimant.short_indication_of_subject.description" />{" "}
                <JurLink>
                  <Translation text="general.see_a_sample.text" />
                </JurLink>
              </QuestionHelpText>
            ),
          },
          {
            name: "shortDescription",
            question: <QuestionTitle text="claimant.short_indication_of_claim.text" />,
            helpText: (
              <QuestionHelpText>
                <Translation text="claimant.short_indication_of_claim.description" />{" "}
                <JurLink>
                  <Translation text="general.see_a_sample.text" />
                </JurLink>
              </QuestionHelpText>
            ),
          },
          {
            name: "natureDescription",
            question: <AsteriskInput text="claimant.description_of_nature_of_claim.text" />,
            helpText: (
              <QuestionHelpText>
                <Translation text="claimant.description_of_nature_of_claim.description" />{" "}
                <JurLink>
                  <Translation text="general.see_a_sample.text" />
                </JurLink>
              </QuestionHelpText>
            ),
            isOptional: true,
          },
          {
            name: "reliefSought",
            question: <QuestionTitle text="claimant.relief_sought.text" />,
            helpText: (
              <QuestionHelpText>
                <Translation text="claimant.relief_sought.description" />
              </QuestionHelpText>
            ),
          },
        ];

        const richTextField = ({ item }) => (
          <FormItem
            {...apiErrorOf(item.name, true)}
            label={
              <Typography.Title level={5} className="primary-color">
                {item.question}
              </Typography.Title>
            }
            name={item.name}
            required={!item.isOptional}
          >
            {item.helpText}
            <Editor
              defaultValue={values?.[item?.name]}
              help={formikErrors[item?.name]}
              placeholder="Describe in 300 words only"
              onChange={(html) => handleChangeOf(item.name)(html)}
            />
          </FormItem>
        );

        return (
          <Form layout="vertical" className="jur-dispute-filing-form">
            {seatOfArbitration()}
            <ApplicableLaw />
            <Specializations />
            {claimValue()}
            {textFields.map((item, i) => richTextField({ item: item, key: i }))}
          </Form>
        );
      }}
    </Formik>
  );
}

export const claimValidate = yup.object().shape({
  subjectMatter: validateHtmlLength(300),
  shortDescription: validateHtmlLength(300),
  natureDescription: validateHtmlLength(300),
  reliefSought: validateHtmlLength(300),
});
