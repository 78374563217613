import { message } from "antd";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/assistedDisputeActions";
import * as apis from "../apis/assistedDisputeApi";
import * as types from "../constants/constants";

const tag = "assisted-disputel";

export function* getPhases(action) {
  const { disputeId } = action.payload;
  try {
    const res = yield call(apis.phasesIndex, disputeId);
    yield put(actions.handlePhases(res));
  } catch (e) {
    console.error(tag, "saga getPhases error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdatePhases(action) {
  const { disputeId, phases } = action.payload;
  try {
    const res = yield call(apis.updatePhases, disputeId, phases);
    yield put(actions.handleUpdatePhases(res));
    message.success(`Phases Updated!`);
  } catch (e) {
    console.error(tag, "saga putUpdatePhases error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getLastActivity(action) {
  const { disputeId, group } = action.payload;
  try {
    const res = yield call(apis.showLastActivity, disputeId, group);
    yield put(actions.handleLastActivity(res, group));
  } catch (e) {
    console.error(tag, "saga getLastActivity error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateLastActivity(action) {
  const { disputeId, group, lastUpdate } = action.payload;
  try {
    const res = yield call(
      apis.updateLastActivity,
      disputeId,
      group,
      lastUpdate
    );
    yield put(actions.handleUpdateLastActivity(res, group));
    message.success(`Last Update Saved for ${group}`);
  } catch (e) {
    console.error(tag, "saga putUpdateLastActivity error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getTaskList(action) {
  const { disputeId, group } = action.payload;
  try {
    const res = yield call(apis.showTaskList, disputeId, group);
    yield put(actions.handleTaskList(res, group));
  } catch (e) {
    console.error(tag, "saga getTaskList error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateTaskList(action) {
  const { disputeId, group, tasks } = action.payload;
  try {
    const res = yield call(apis.updateTaskList, disputeId, group, tasks);
    yield put(actions.handleUpdateTaskList(res, group));
    message.success(`Task List Saved for ${group}`);
  } catch (e) {
    console.error(tag, "saga putUpdateTaskList error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateTaskStatus(action) {
  const { disputeId, taskId, status, onSuccess } = action.payload;
  try {
    const res = yield call(apis.updateTaskStatus, disputeId, taskId, status);
    yield put(actions.handleUpdateTaskStatus(res));
    onSuccess();
    message.success(`Task Status Updated!`);
  } catch (e) {
    console.error(tag, "saga putUpdateTaskStatus error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* assistedDisputeSaga() {
  yield takeEvery(types.ASSISTEDDISPUTE_GET_PHASES_INDEX, getPhases);
  yield takeLatest(types.ASSISTEDDISPUTE_PUT_UPDATE_PHASES, putUpdatePhases);
  yield takeEvery(types.ASSISTEDDISPUTE_GET_LASTACTIVITY_SHOW, getLastActivity);
  yield takeEvery(
    types.ASSISTEDDISPUTE_PUT_UPDATE_LASTACTIVITY,
    putUpdateLastActivity
  );
  yield takeEvery(types.ASSISTEDDISPUTE_GET_TASKLIST, getTaskList);
  yield takeEvery(types.ASSISTEDDISPUTE_PUT_UPDATE_TASKLIST, putUpdateTaskList);
  yield takeEvery(
    types.ASSISTEDDISPUTE_PUT_UPDATE_TASKSTATUS,
    putUpdateTaskStatus
  );
}
