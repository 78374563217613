export function toKyc(kyc) {
  if (!kyc) return kyc;
  return {
    ...kyc,
    barMembershipDate: toDate(kyc?.barMembershipDate),
    files: orderedFilesOfKyc(kyc?.files),
  };
}

export function toDate(datelike) {
  if (typeof datelike === "number") return new Date(datelike * 1000);
  return datelike;
}

const orderedCategories = [
  "Identity Proof",
  "Address Proof",
  "Recent Photograph",
  "Certificate Of Incorporation",
  "Company Address Proof",
];

function orderedFilesOfKyc(files) {
  if (!files) return [];
  return orderedCategories.map(
    (category) =>
      files
        .filter((file) => file?.fileCategory === category)
        .reduce(
          (acc, item) => {
            return item?.id && item.id > acc.max
              ? { max: item.id, value: item }
              : acc;
          },
          { max: 0, value: null }
        ).value
  );
}

export function toUploaderFiles(filable) {
  if (!filable) return undefined;
  return (!filable.id ? filable : [filable])
    .filter(Boolean)
    .map((file, index) => ({
      uid: file.id || index,
      name: file.name,
      ...file,
    }));
}
