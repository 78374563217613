import React, { useState } from "react";
import { Alert, Button, Modal, Space } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Select } from "formik-antd";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { useErrorHider } from "../../helpers/errorHelpers";
import { modalProps } from "../../helpers/modalHelpers";
import Translation from "../Common/Translation";
import Uploader, { UploadButtonRender } from "../Common/Uploader";
import ModalWrapper from "../Common/ModalWrapper";
import { getShow } from "../../redux/actions/hearingActions";

export default function FileUploadForm({
  visible,
  completedHearings,
  closeSendDocuments,
  handleSendDocuments,
  isLoading,
}) {
  const [fileId, setFileId] = useState(null);
  const { disputeId } = useParams();
  const errorProps = useErrorHider();
  const dispatch = useDispatch();

  const uploaderProps = {
    name: "hearingMinutes",
    fileCategory: "Hearing Minutes",
    maxCount: 1,
    multiple: false,
    onRemove: () => {
      setFileId(null);
    },
    onDone: (file) => {
      setFileId(file.id);
    },
    allowDocumentOnly: true,
  };

  const footer = (values) => {
    return [
      <Button
        type="primary"
        loading={isLoading}
        disabled={!Boolean(fileId) || isLoading || !values.hearingId}
        onClick={() => handleSendDocuments(values?.hearingId, fileId)}
      >
        <Translation text="general.sign.button" pad={true} />
      </Button>,
    ];
  };

  const onCancel = () => {
    closeSendDocuments();
    setFileId(null);
    errorProps.hide();
  };

  const handleOnchange = (hearingId) => {
    dispatch(getShow(disputeId, hearingId));
  };

  return (
    <Formik initialValues={{}}>
      {({ values }) => (
        <Modal
          {...modalProps()}
          maskClosable={false}
          width={768}
          visible={visible}
          onCancel={onCancel}
          footer={footer(values)}
          title={<Translation text="case_files.new_upload_minute.text" />}
        >
          <ModalWrapper>
            <Form layout="vertical">
              <FormItem label={<Translation text="case_files.select_a_meeting.text" />} name="hearingId">
                <Select placeholder="Select" name="hearingId" onChange={handleOnchange}>
                  {completedHearings?.map((h) => (
                    <Select.Option value={h?.id}>{h?.attributes?.title}</Select.Option>
                  ))}
                </Select>
              </FormItem>
              <Space direction="vertical" size="large" className="jur-width-full">
                <Alert
                  type="info"
                  banner={true}
                  message={<Translation text="case_files.hearing_signature-required.text" />}
                />

                <FormItem name="file">
                  <Uploader {...uploaderProps}>
                    <UploadButtonRender
                      buttonProps={{ disabled: Boolean(fileId) }}
                      optional={true}
                      label="general.choose_file.button"
                    />
                  </Uploader>
                </FormItem>
              </Space>
            </Form>
          </ModalWrapper>
        </Modal>
      )}
    </Formik>
  );
}
