import { Col, Row, Typography } from "antd";
import { Input } from "formik-antd";
import React from "react";
import "./styles.less";
import Translation from "./Translation";

export default function FormQuestion({ title, description }) {
  return (
    <div className="jur-form-question-wrapper">
      <div>
        <Typography.Title level={5}>{title}</Typography.Title>
      </div>
      {description ? (
        <div>
          <Typography.Text className="secondary-color">
            {description}
          </Typography.Text>
        </div>
      ) : null}
    </div>
  );
}

export const QuestionTitle = ({ text }) => (
  <Typography.Title level={5}>
    <Translation text={text} />
  </Typography.Title>
);

export const QuestionHelpText = ({ children }) => (
  <div style={{ marginBottom: 8 }}>
    <Typography.Text className="secondary-color">{children}</Typography.Text>
  </div>
);

export const AddressFields = () => (
  <>
    {" "}
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Input placeholder="Room/Floor/Building" name="address" />
      </Col>
      <Col span={24}>
        <Input placeholder="Street/Area(Optional)" name="address2" />
      </Col>
      <Col span={12}>
        <Input placeholder="Postal Code" name="zipCode" />
      </Col>
      <Col span={12}>
        <Input placeholder="City" name="city" />
      </Col>
      <Col span={12}>
        <Input placeholder="State" name="state" />
      </Col>
      <Col span={12}>
        <Input placeholder="Country" name="country" />
      </Col>
    </Row>
  </>
);
