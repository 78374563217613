import React from "react";
import { Row, Col } from "antd";
import { FileTextTwoTone } from "@ant-design/icons";

// internal imports
import Translation from "../Common/Translation";

const Template = ({ template }) => {
  const previewTemplate = () =>
    window.open(
      template.fileUrl,
      "_blank"
    );

  return (
    <Row gutter={8} className="jur-template-col" onClick={previewTemplate}>
      <Col className="gutter-row" span={24}>
        <div className="jur-flex-justify-start jur-template-header">
          <div className="jur-template-icon">
            <div className="jur-table-body-file-render-icon">
              <FileTextTwoTone twoToneColor="#2777EF" />
            </div>
          </div>
          <div className="jur-template-title">
            <div className="jur-folder-label">
              <Translation text="case_files.template.text" />
            </div>
            <div className="jur-folder-label">{template.fileName}</div>
          </div>
        </div>
      </Col>
      <Col className="gutter-row" span={24}>
        <div className="jur-template-preview"></div>
      </Col>
    </Row>
  );
};

export default Template;
