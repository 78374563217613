import { Button, Col, Row } from "antd";
import React from "react";
import PageHeader from "../Common/PageHeader";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import OrderList from "./OrderList";
import ReviewOrderForm from "./ReviewOrderForm";
import ShareOrderForm from "./ShareOrderForm";
import UploadOrderForm from "./UploadOrderForm";
import ShowPoReview from "./ShowPoReview";
import UploadRevisedOrderForm from "./UploadRevisedOrderForm";
import CaseFilesBreadcrumb from "../Common/CaseFilesBreadcrumb";

export default function ({
  selectedPO,
  disputeParams,
  proceduralOrders,
  isUploadOrderOpen,
  handleUploadOrderOpen,
  handleUploadOrderClose,
  handleUploadOrderSubmit,
  isShareOrderOpen,
  handleShareOrderOpen,
  handleShareOrderClose,
  handleShareOrderSubmit,
  isUploadRevisedOrderOpen,
  handleUploadRevisedOrderOpen,
  handleUploadRevisedOrderClose,
  handleUploadRevisedOrderSubmit,
  isReviewOrderOpen,
  handleShowReview,
  handleReviewOrderOpen,
  handleReviewOrderClose,
  handleReviewOrderSubmit,
  participants,
  revisableOrders,
  reviewableOrders,
  sharableOrders,
  handleGetVersions,
  versions,
  errors,
  isFetchingIndex,
  indexMeta,
  onPageChange,
  canCreate,
  canReview,
  handlePoAction,
  handleActionText,
}) {
  return (
    <SliderMenuWrapper {...disputeParams}>
      <Row gutter={[16, 16]}>
        <POHeader {...{ disputeParams, canCreate, handleUploadOrderOpen }} />
        <Col className="gutter-row" span={24}>
          {!isFetchingIndex && proceduralOrders ? (
            <OrderList
              orders={proceduralOrders}
              {...disputeParams}
              handleGetVersions={handleGetVersions}
              versions={versions}
              indexMeta={indexMeta}
              onPageChange={onPageChange}
              canCreate={canCreate}
              canReview={canReview}
              handlePoAction={handlePoAction}
              handleShowReview={handleShowReview}
              handleActionText={handleActionText}
              handleUploadRevisedOrderOpen={handleUploadRevisedOrderOpen}
              handleReviewOrderOpen={handleReviewOrderOpen}
            />
          ) : (
            <div className="jur-tor-spinner">
              <Spinner />
            </div>
          )}
        </Col>
        {canCreate && (
          <Col className="gutter-row" span={24}>
            <ShowPoReview rejectedPO={selectedPO} />
          </Col>
        )}
      </Row>
      {participants && (
        <UploadOrderForm
          visible={isUploadOrderOpen}
          handleUploadOrderSubmit={handleUploadOrderSubmit}
          handleUploadOrderClose={handleUploadOrderClose}
          participants={participants}
          errors={errors}
        />
      )}
      <ShareOrderForm
        visible={isShareOrderOpen}
        selectedPO={selectedPO}
        handleShareOrderSubmit={handleShareOrderSubmit}
        handleShareOrderClose={handleShareOrderClose}
        orders={sharableOrders}
      />
      <UploadRevisedOrderForm
        selectedPO={selectedPO}
        visible={isUploadRevisedOrderOpen}
        handleUploadRevisedOrderSubmit={handleUploadRevisedOrderSubmit}
        handleUploadRevisedOrderClose={handleUploadRevisedOrderClose}
        orders={revisableOrders}
        errors={errors}
      />
      <ReviewOrderForm
        selectedPO={selectedPO}
        visible={isReviewOrderOpen}
        handleReviewOrderSubmit={handleReviewOrderSubmit}
        handleReviewOrderClose={handleReviewOrderClose}
        orders={reviewableOrders}
        errors={errors}
      />
    </SliderMenuWrapper>
  );
}

const POHeader = ({ disputeParams, canCreate, handleUploadOrderOpen }) => (
  <>
    <Col className="gutter-row" span={24}>
      <CaseFilesBreadcrumb />
    </Col>
    <Col className="gutter-row" span={24}>
      <PageHeader
        title="procedural_order.procedural_order.title"
        cta={
          canCreate && (
            <Button type="primary" onClick={handleUploadOrderOpen}>
              <Translation text="case_files.new_upload.text" />
            </Button>
          )
        }
      />
    </Col>
  </>
);
