import { Table, Typography } from "antd";
import React, { useState } from "react";
import DateTime from "../../../Common/DateTime";
import NoData from "../../../Common/NoData";
import Translation from "../../../Common/Translation";

const { Title, Text } = Typography;

export const TaskList = ({
  condition,
  assistedDispute,
  onUpdateTaskStatus,
}) => {
  const [tasks, setTasks] = useState(assistedDispute?.tasks || []);
  if (!condition) return null;

  const columns = [
    {
      title: "Task(s) for this phase",
      dataIndex: "title",
      key: "title",
      render: (item, row) => {
        return (
          <>
            <Text className="primary-color" strong>
              {item}
            </Text>
            {row.description ? (
              <>
                <br />
                <Text className="secondary-color">{row.description}</Text>
              </>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
      render: (item) => {
        return <DateTime epochSeconds={item} />;
      },
    },
  ];

  const hideTask = (id) => setTasks(tasks.filter((t) => t?.id !== id));

  return (
    <div>
      <div>
        <Title className="primary-color" level={4}>
          <Translation text="assisted_dispute.your_task_list.text" />
        </Title>
      </div>
      <div>
        {tasks.length ? (
          <Table
            lassName="jur-width-full"
            columns={columns}
            rowSelection={{
              hideSelectAll: true,
              onSelect: (row, value) => {
                onUpdateTaskStatus(row?.id, value, () => {
                  hideTask(row.id);
                });
              },
            }}
            dataSource={tasks.map((t) => ({
              ...t,
              ...t.attributes,
            }))}
            rowKey="id"
            pagination={false}
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};
