export const selectPhases = (state) => state.assistedDispute.phases;
export const selectWhole = (state) => state.assistedDispute;
export const selectHasModifiedPhase = (state) =>
  state.assistedDispute.hasModifiedPhase;
export const selectHasModifiedClaimantTask = (state) =>
  state.assistedDispute["hasModified-claimantTaskList"] || false;
export const selectHasModifiedRespondentTask = (state) =>
  state.assistedDispute["hasModified-respondentTaskList"] || false;
export const selectHasModifiedArbitratorTask = (state) =>
  state.assistedDispute["hasModified-arbitratorTaskList"] || false;
export const selectHasModifiedClaimantActivity = (state) =>
  state.assistedDispute["hasModified-claimantLastUpdate"] || false;
export const selectHasModifiedRespondentActivity = (state) =>
  state.assistedDispute["hasModified-respondentLastUpdate"] || false;
export const selectHasModifiedArbitratorActivity = (state) =>
  state.assistedDispute["hasModified-arbitratorLastUpdate"] || false;

export const selectModifiedStates = (state) => ({
  phase: selectHasModifiedPhase(state),
  claimantTask: selectHasModifiedClaimantTask(state),
  respondentTask: selectHasModifiedRespondentTask(state),
  arbitratorTask: selectHasModifiedArbitratorTask(state),
  claimantActivity: selectHasModifiedClaimantActivity(state),
  respondentActivity: selectHasModifiedRespondentActivity(state),
  arbitratorActivity: selectHasModifiedArbitratorActivity(state),
});
