import { Typography } from "antd";
import React from "react";
import "./styles.less";

const { Title } = Typography;

function Sidebar({ title, description, content }) {
  return (
    <div className="primary-color">
      <Title level={1}>{title}</Title>
      <div className="jur-sidebar-description">
        <Title level={5}>{description}</Title>
      </div>
      {content}
    </div>
  );
}

export default Sidebar;
