import { ReadOutlined, TeamOutlined, TrophyOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import React from "react";
import Translation from "../Common/Translation";
import ProfileSection from "./ProfileSection";

const { Title, Text } = Typography;

export default function AchievementTab({ achievements }) {
  return (
    <div>
      <ProfileSection
        title={<Translation text="roster.special_honours.text" />}
        content={
          <CardLayout
            list={achievements?.appointments}
            itemRender={(item) => (
              <>
                <TeamOutlined />
                <Title level={5} strong className="primary-color">
                  {item.title}
                </Title>
                <div>
                  <Text className="secondary-color">{item.organization}</Text>
                </div>
                <div className="jur-profile-section-list-item-description">
                  <Text strong> {item.year}</Text>
                </div>
              </>
            )}
          />
        }
      />
      <ProfileSection
        title={<Translation text="roster.publications.text" />}
        content={
          <CardLayout
            list={achievements?.publications}
            itemRender={(item) => (
              <>
                <ReadOutlined />
                <Title level={5} strong className="primary-color">
                  {item.title}
                </Title>
                <div>
                  <Text className="secondary-color">{item.year}</Text>
                </div>
              </>
            )}
          />
        }
      />
      <ProfileSection
        title={<Translation text="roster.awards_and_honours.text" />}
        content={
          <CardLayout
            list={achievements?.awards}
            itemRender={(item) => (
              <>
                <TrophyOutlined />
                <Title level={5} strong className="primary-color">
                  {item.title}
                </Title>
                <div>
                  <Text className="secondary-color">{item.organization}</Text>
                </div>
                <div className="jur-profile-section-list-item-description">
                  <Text strong> {item.year}</Text>
                </div>
              </>
            )}
          />
        }
      />
    </div>
  );
}

export const CardLayout = ({ list, itemRender }) => {
  return (
    <Row gutter={[16, 16]}>
      {list?.map((item) => (
        <Col span={8} sm={24}>
          <div className="jur-profile-card-item">{itemRender(item)}</div>
        </Col>
      ))}
    </Row>
  );
};
