import { Card, Col, Row, Space, Typography } from "antd";
import React from "react";
import Amount from "./Amount";
import ArbitratorCard from "./ArbitratorCard";
import FileOpener from "./FileOpener";
import Spinner from "./Spinner";
import Translation from "./Translation";

export default function HubInfo({ hubProfile, arbitrators = [] }) {
  if (!hubProfile)
    return (
      <Card>
        <Spinner />
      </Card>
    );
  return (
    <Card
      title={<Typography.Title level={4}>{hubProfile?.name}</Typography.Title>}
    >
      <Space direction="vertical">
        <Typography.Paragraph>{hubProfile?.description}</Typography.Paragraph>
        <HubSection
          title="hub.general_information.title"
          items={generalInfoItems(hubProfile?.general)}
        />
        <HubSection
          title="hub.dispute_settings.title"
          items={disputeSettingsInfo(hubProfile?.dispute, hubProfile?.general)}
        />
        <HubSection
          title="hub.tentative_time_alloted.title"
          items={deadlines(hubProfile?.deadlines)}
        />
        <Card
          title={
            <Typography.Title level={5}>
              <Translation text="general.arbitrators.title" />
            </Typography.Title>
          }
        >
          <Row gutter={[16, 16]}>
            {arbitrators?.map((a) => {
              return (
                <Col span={12}>
                  <ArbitratorCard arbitrator={a} />
                </Col>
              );
            })}
          </Row>
        </Card>
      </Space>
    </Card>
  );
}

const HubSection = ({ title, items }) => (
  <Card
    title={
      <Typography.Title level={5}>
        <Translation text={title} />
      </Typography.Title>
    }
  >
    {items.map((item, i) => (
      <Typography.Paragraph>
        <Row gutter={[16, 16]}>
          <Col md={8}>
            <Typography.Text strong>
              <Translation text={item.label} />
            </Typography.Text>
            {item.description && (
              <Typography.Paragraph type="secondary">
                <Translation text={item.description} />
              </Typography.Paragraph>
            )}
          </Col>
          <Col md={16}>
            <Typography.Paragraph>{item.value}</Typography.Paragraph>
          </Col>
        </Row>
      </Typography.Paragraph>
    ))}
  </Card>
);

const generalInfoItems = (generalInfo) => [
  {
    label: "hub.jurisdiction_legal_address.text",
    value: generalInfo?.jurisdictionLegalAddress,
  },
  {
    label: "hub.entity_legal_name.text",
    value: generalInfo?.entityLegalName,
  },
  {
    label: "hub.entity_legal_address.text",
    value: generalInfo?.entityLegalAddress,
  },
  {
    label: "hub.entity_certificate.text",
    value: (
      <FileRender
        file={generalInfo?.entityCertificate}
        label={generalInfo?.entityCertificate?.name}
      />
    ),
  },
];

const disputeSettingsInfo = (settings, generalInfo) => [
  {
    label: "hub.specialisations.text",
    value: settings?.specializations?.join(", "),
  },
  {
    label: "hub.dispute_size.text",
    value: (
      <>
        <Amount {...settings?.disputeSizeMin} />
        {" - "}
        <Amount {...settings?.disputeSizeMax} />
      </>
    ),
  },
  {
    label: "hub.dispute_fee_percentage.text",
    value: settings?.disputeFee,
    description: "hub.dispute_fee.description",
  },
  {
    label: "hub.hub_rules.text",
    value: (
      <FileRender
        file={generalInfo?.hubRules}
        label={generalInfo?.hubRules?.name}
      />
    ),
  },
  {
    label: "hub.languages_spoken.text",
    value: settings?.languages?.join(", "),
  },
  {
    label: "hub.seats_of_arbitration.text",
    value: settings?.seatsOfArbitration?.join(", "),
  },
  {
    label: "hub.applicable_laws.text",
    value: settings?.applicableLaws?.join(", "),
  },
  {
    label: "hub.award_scrutiny.text",
    value: Boolean(settings?.awardScrutinyRequired) ? "Yes" : "No",
  },
];

const deadlines = (deadlines) => [
  {
    label: "hub.filing_response_to_claim.text",
    value: <DeadlineRender count={deadlines?.respondToClaim} />,
    description: "hub.filing_response_to_claim.description",
  },
  {
    label: "hub.filing_statement_of_defence.text",
    value: <DeadlineRender count={deadlines?.defenceToClaim} />,
    description: "hub.filing_statement_of_defence.description",
  },
  {
    label: "hub.filing_the_counterclaim.text",
    value: <DeadlineRender count={deadlines?.counterClaim} />,
    description: "hub.filing_statement_of_defence.description",
  },
  {
    label: "hub.filing_a_reply_to_the_counterclaim.text",
    value: <DeadlineRender count={deadlines?.counterClaimReply} />,
    description: "hub.filing_a_reply_to_the_counterclaim.description",
  },
  {
    label: "hub.issuing_arbitral_award.text",
    value: <DeadlineRender count={deadlines?.arbitralAwardIssue} />,
    description: "hub.issuing_arbitral_award.description",
  },
  {
    label: "hub.requesting_edits_in_the_arbitral_award.text",
    value: <DeadlineRender count={deadlines?.arbitralAwardEdit} />,
    description: "hub.requesting_edits_in_the_arbitral_award.description",
  },
  {
    label: "hub.issuing_additional_arbitral_award.text",
    value: <DeadlineRender count={deadlines?.additionalArbitralAwardIssue} />,
    description: "hub.issuing_additional_arbitral_award.description",
  },
  {
    label: "hub.requesting_edits_in_the_additional_arbitral_award.text",
    value: <DeadlineRender count={deadlines?.additionalArbitralAwardEdit} />,
    description:
      "hub.requesting_edits_in_the_additional_arbitral_award.description",
  },
];

const FileRender = ({ file, label }) => {
  if (!Boolean(file)) return null;
  return (
    <FileOpener link={true} fileId={file?.id}>
      {label}
    </FileOpener>
  );
};

const DeadlineRender = ({ count }) => {
  return `${count} days`;
};
