import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {
  payments: [],
  isSubmitting: false,
  isOpen: false,
  isLoading: true,
};

export default function (state = initial, action) {
  switch (action.type) {
    case types.PAYMENT_HANDLE_INDEX:
      return {
        ...state,
        payments: action.payload.data,
        paymentsMeta: action.payload.meta,
      };
    case types.PAYMENT_HANDLE_IS_OPEN:
      return { ...state, isOpen: action.payload };
    case types.PAYMENT_POST_CREATE:
    case types.PAYMENT_POST_CREATE_TRANSACTION:
    case types.PAYMENT_PUT_UPDATE:
    case types.PAYMENT_PUT_UPDATE_TRANSACTION:
      return { ...state, isSubmitting: true };
    case types.PAYMENT_HANDLE_CREATE:
    case types.PAYMENT_HANDLE_CREATE_TRANSACTION:
    case types.PAYMENT_HANDLE_UPDATE:
    case types.PAYMENT_HANDLE_UPDATE_TRANSACTION:
      return { ...state, isSubmitting: false, isOpen: false };
    case types.PAYMENT_HANDLE_ERROR:
      return {
        ...state,
        errors: errorMessages(action.error),
        isSubmitting: false,
      };
    default:
      return state;
  }
}
