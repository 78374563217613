import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Translation from "./Translation";

function Anchor({ text, to, href, onClick, children, ...extras }) {
  const content = children ? children : <Translation text={text} />;

  if (to)
    return (
      <Link to={to} {...extras}>
        {content}
      </Link>
    );

  if (onClick)
    return (
      <Button onClick={onClick} type="link" {...extras}>
        {content}
      </Button>
    );

  if (href)
    return (
      <a href={href} {...extras}>
        {content}
      </a>
    );
}

export default Anchor;
