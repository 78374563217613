import { Button, Modal, Typography } from "antd";
import { Formik } from "formik";
import { DatePicker, Form, FormItem, Input, Select } from "formik-antd";
import React from "react";
import { dateTimeFormat } from "../../helpers/dateHelpers";
import {
  errorAsExtra,
  ErrorDisplay,
  useErrorHider,
} from "../../helpers/errorHelpers";
import Translation from "../Common/Translation";
import "./styles.less";

export default function ExtendDeadlineForm({
  currentDeadlineAction,
  closeExtendDeadline,
  visible,
  onSubmit,
  isLoading = false,
  errors,
  meta,
}) {
  const requestMode = currentDeadlineAction?.startsWith("request");
  const forEdits = currentDeadlineAction?.endsWith("edits");
  const errorProps = useErrorHider();
  const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);

  const extensionForList = forEdits
    ? [
        {
          label: "arbitral_award.submitting_corrections_in_arbitral_award.text",
          value: "Arbitral Award Edit",
        },
        {
          label:
            "arbitral_award.submitting_corrections_in_additional_arbitral_award.text",
          value: "Additional Arbitral Award Edit",
        },
      ]
    : [
        {
          label: "arbitral_award.issuance_of_arbitral_award.text",
          value: "Arbitral Award Issue",
        },
        {
          label: "arbitral_award.issuance_of_additional_arbitral_award.text",
          value: "Additional Arbitral Award Issue",
        },
      ];

  return (
    <Formik initialValues={initFormValues}>
      {({ values, resetForm }) => {
        const disableSubmit = !(
          values?.newDeadline &&
          values?.extensionFor &&
          values?.motivation
        );
        const deadline =
          {
            "Arbitral Award Issue": meta?.arbitralAwardDeadline,
            "Additional Arbitral Award Issue": meta?.additionalAwardDeadline,
            "Arbitral Award Edit": meta?.arbitralAwardEditDeadline,
            "Additional Arbitral Award Edit": meta?.additionalAwardEditDeadline,
          }[values?.extensionFor] || null;
        return (
          <Modal
            visible={visible}
            destroyOnClose={true}
            onCancel={() => {
              closeExtendDeadline();
              resetForm();
              errorProps.hide();
            }}
            title={
              <Typography.Title level={5}>
                <Translation
                  text={
                    requestMode
                      ? "arbitral_award.request_deadlines.text"
                      : "arbitral_award.extend_deadlines.text"
                  }
                />
              </Typography.Title>
            }
            footer={
              <Button
                loading={isLoading}
                disabled={disableSubmit || isLoading}
                type="primary"
                onClick={() =>
                  errorProps.hideAndCall(onSubmit(values, resetForm))
                }
              >
                <Translation pad={true} text="general.send.button" />
              </Button>
            }
          >
            <Form layout="vertical">
              <FormItem
                name="extensionFor"
                label={
                  <Translation
                    text={
                      requestMode
                        ? "arbitral_award.request_extension_for.text"
                        : "arbitral_award.extend_deadline_for.text"
                    }
                  />
                }
              >
                <Select className="jur-width-full" name="extensionFor">
                  {optionsFrom(extensionForList)}
                </Select>
              </FormItem>
              <FormItem
                name="newDeadline"
                label={
                  <Translation text="arbitral_award.new_deadline_for.text" />
                }
                {...apiErrorOf("newDeadline", true)}
              >
                <DatePicker
                  format={dateTimeFormat}
                  disabledDate={(current) => {
                    return current < new Date(deadline * 1000);
                  }}
                  showTime={{ format: "HH:mm" }}
                  name="newDeadline"
                />
              </FormItem>
              <FormItem
                label={<Translation text="arbitral_award.motivation.text" />}
                name="motivation"
              >
                <Input.TextArea name="motivation" />
              </FormItem>
              <ErrorDisplay
                errors={errors}
                name="deadline"
                isHidden={errorProps.isHidden}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}

const initFormValues = {
  extensionFor: null,
  newDeadline: null,
  motivation: null,
};

function optionsFrom(list) {
  return list?.map((o, index) => {
    return (
      <Select.Option key={index} value={o.value}>
        {<Translation text={o.label} />}
      </Select.Option>
    );
  });
}
