import { Alert, Button, Modal, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Select } from "formik-antd";
import React from "react";
import Translation from "../Common/Translation";

export default function SendToPartiesForm({
  sharableAwards,
  isSendToPartiesVisible,
  handleSendToParties,
  closeSendToParties,
  isLoading,
  selectedAward = null,
}) {
  return (
    <Formik
      initialValues={{
        awardId: selectedAward?.id,
      }}
    >
      {({ values, resetForm }) => {
        const disableSend = !Boolean(values.awardId);

        const handleChange = () => {
          handleSendToParties(values?.awardId);
        };
        return (
          <Modal
            destroyOnClose={true}
            visible={isSendToPartiesVisible}
            onCancel={() => {
              closeSendToParties();
              resetForm();
            }}
            title={
              <Typography.Title level={5}>
                <Translation text="arbitral_award.share_with_parties.text" />
              </Typography.Title>
            }
            footer={[
              <Button
                loading={isLoading}
                type="primary"
                onClick={handleChange}
                disabled={disableSend || isLoading}
              >
                <Translation text="general.send.button" pad={true} />
              </Button>,
            ]}
          >
            <Form layout="vertical">
              <FormItem
                label={
                  <Typography.Text>
                    <Translation text="arbitral_award.select_a_file_version.text" />
                  </Typography.Text>
                }
                name="awardId"
              >
                <Select
                  disabled={selectedAward}
                  name="awardId"
                  className="jur-width-full"
                >
                  {optionsFrom(
                    selectedAward ? [selectedAward] : sharableAwards
                  )}
                </Select>
                <br />
                <br />
                <Alert
                  type="info"
                  message={
                    <Translation text="arbitral_award.send_to_parties_signature.text" />
                  }
                />
              </FormItem>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}

function optionsFrom(list) {
  return list?.map((entity, index) => {
    const isAdditional = entity?.attributes?.isAdditionalAward;
    return (
      <Select.Option key={index} value={entity?.id}>
        {`${
          isAdditional ? "Additional Arbitral Award" : "Arbitral Award"
        } - Version ${entity?.attributes?.version}`}
      </Select.Option>
    );
  });
}
