import { Card, PageHeader } from "antd";
import React from "react";
import DisputeStatus from "../Common/DisputeStatus";

export default function ({ dispute, breadcrumb, disputeAssignment }) {
  return (
    <Card>
      <PageHeader
        title={dispute.name}
        tags={<DisputeStatus status={dispute.status} />}
        breadcrumb={breadcrumb}
      >
        wait
      </PageHeader>
    </Card>
  );
}
