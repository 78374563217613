import { Alert, Button, Modal, Space, Typography } from "antd";
import React, { useState } from "react";
import Translation from "../Common/Translation";
import Uploader, { UploadButtonRender } from "../Common/Uploader";
import { modalProps } from "../../helpers/modalHelpers";
import ModalWrapper from "../Common/ModalWrapper";

export default function SendToHubForm({ visible, handleSendToHub, closeSendToHub, isLoading, deleteTOR }) {
  const [fileId, setFileId] = useState(null);
  const uploaderProps = {
    allowDocumentOnly: true,
    maxCount: 1,
    name: "torUploader",
    multiple: false,
    fileCategory: "Terms Of Reference",
    onDone: (file) => {
      setFileId(file.id);
    },
    onRemove: (file) => {
      deleteTOR(fileId);
      setFileId(null);
    },
  };
  return (
    <Modal
      width={768}
      {...modalProps()}
      destroyOnClose={true}
      visible={visible}
      maskClosable={false}
      onCancel={() => {
        closeSendToHub();
        setFileId(null);
      }}
      title={
        <Typography.Title level={5}>
          <Translation text="case_files.upload_new_doc.button" />
        </Typography.Title>
      }
      footer={[
        <Button
          disabled={!Boolean(fileId) || isLoading}
          loading={isLoading}
          type="primary"
          onClick={() => {
            handleSendToHub(fileId, () => {
              setFileId(null);
            });
          }}
        >
          <Translation text="general.done.title" pad={true} />
        </Button>,
      ]}
    >
      <ModalWrapper>
        <Space direction="vertical" size="large">
          <Alert
            type="info"
            banner={true}
            message={<Translation text="terms_of_reference.send_to_hub.description" />}
          />
          <Uploader {...uploaderProps}>
            <UploadButtonRender optional={true} label="general.choose_file.button" />
          </Uploader>
        </Space>
      </ModalWrapper>
    </Modal>
  );
}
