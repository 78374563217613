/**
 * Conventions for app specific action constants naming
 *
 * # Name for API calls:
 * <Module>_<HTTP Method>_<Controller Action>
 * Module could be DISPUTE, USER etc
 * HTTP Method could be GET, POST, PUT etc
 * Controller Action could be INDEX, SHOW, UPDATE, CREATE etc
 *
 * # Name for Redux store updates:
 * <Module>_HANDLE_<Controller Action>
 * Module is same as mentioned above
 * HANDLE is constant that indicates it's for UI updates handling
 * Controller Action is same as mentioned above
 */

// Module: APP
export const APP_HANDLE_RESET = "APP_HANDLE_RESET";

// Module: Dispute
export const DISPUTE_GET_INDEX = "DISPUTE_GET_INDEX";
export const DISPUTE_GET_INDEX_REQUESTED = "DISPUTE_GET_INDEX_REQUESTED";
export const DISPUTE_GET_SHOW = "DISPUTE_GET_SHOW";
export const DISPUTE_PUT_UPDATE = "DISPUTE_PUT_UPDATE";
export const DISPUTE_PUT_UPDATE_CLOSE = "DISPUTE_PUT_UPDATE_CLOSE";
export const DISPUTE_PUT_UPDATE_LINK_ENVELOPE =
  "DISPUTE_PUT_UPDATE_LINK_ENVELOPE";

export const DISPUTE_HANDLE_ERROR = "DISPUTE_HANDLE_ERROR";
export const DISPUTE_HANDLE_INDEX = "DISPUTE_HANDLE_INDEX";
export const DISPUTE_HANDLE_INDEX_REQUESTED = "DISPUTE_HANDLE_INDEX_REQUESTED";
export const DISPUTE_HANDLE_SHOW = "DISPUTE_HANDLE_SHOW";
export const DISPUTE_HANDLE_UPDATE = "DISPUTE_HANDLE_UPDATE";
export const DISPUTE_HANDLE_IS_OPEN = "DISPUTE_HANDLE_IS_OPEN";
export const DISPUTE_HANDLE_UPDATE_MOTIVATION =
  "DISPUTE_HANDLE_UPDATE_MOTIVATION";
export const DISPUTE_HANDLE_UPDATE_LINK_ENVELOPE =
  "DISPUTE_HANDLE_UPDATE_LINK_ENVELOPE";

// Module: Timeline
export const TIMELINE_GET_SHOW = "TIMELINE_GET_SHOW";
export const TIMELINE_HANDLE_SHOW = "TIMELINE_HANDLE_SHOW";
export const TIMELINE_HANDLE_ERROR = "TIMELINE_HANDLE_ERROR";

// Module: Stats
export const STATS_GET_INDEX = "STATS_GET_INDEX";

export const STATS_HANDLE_ERROR = "STATS_HANDLE_ERROR";
export const STATS_HANDLE_INDEX = "STATS_HANDLE_INDEX";

// Module: Sign

export const SIGN_HANDLE_ERROR = "SIGN_HANDLE_ERROR";
export const SIGN_POST_CREATE_SIGNATURE = "SIGN_POST_CREATE_SIGNATURE";
export const SIGN_GET_CONSENT = "SIGN_GET_CONSENT";
export const SIGN_HANDLE_CONSENT = "SIGN_HANDLE_CONSENT";
export const SIGN_GET_CONSENT_URL = "SIGN_GET_CONSENT_URL";
export const SIGN_HANDLE_CONSENT_URL = "SIGN_HANDLE_CONSENT_URL";
export const SIGN_HANDLE_UPDATE_CONSENT = "SIGN_HANDLE_UPDATE_CONSENT";

export const SIGN_POST_CREATE_ENVELOPE = "SIGN_POST_CREATE_ENVELOPE";
export const SIGN_HANDLE_CREATE_ENVELOPE = "SIGN_HANDLE_CREATE_ENVELOPE";
export const SIGN_PUT_UPDATE_ENVELOPE = "SIGN_PUT_UPDATE_ENVELOPE";
export const SIGN_HANDLE_UPDATE_ENVELOPE = "SIGN_HANDLE_UPDATE_ENVELOPE";
export const SIGN_PUT_UPDATE_ENVELOPE_AT = "SIGN_PUT_UPDATE_ENVELOPE_AT";

export const SIGN_HANDLE_IS_REDIRECTING = "SIGN_HANDLE_IS_REDIRECTING";

// Module: User Profile
export const USERPROFILE_HANDLE_ERROR = "USERPROFILE_HANDLE_ERROR";
export const USERPROFILE_GET_SHOW = "USERPROFILE_GET_SHOW";
export const USERPROFILE_HANDLE_SHOW = "USERPROFILE_HANDLE_SHOW";
export const USERPROFILE_PUT_UPDATE = "USERPROFILE_PUT_UPDATE";
export const USERPROFILE_HANDLE_UPDATE = "USERPROFILE_HANDLE_UPDATE";
export const USERPROFILE_HANDLE_UPDATE_FORM = "USERPROFILE_HANDLE_UPDATE_FORM";

// Module: Billing Details
export const BILLINGDETAILS_HANDLE_ERROR = "BILLINGDETAILS_HANDLE_ERROR";
export const BILLINGDETAILS_GET_SHOW = "BILLINGDETAILS_GET_SHOW";
export const BILLINGDETAILS_HANDLE_SHOW = "BILLINGDETAILS_HANDLE_SHOW";
export const BILLINGDETAILS_PUT_UPDATE = "BILLINGDETAILS_PUT_UPDATE";
export const BILLINGDETAILS_HANDLE_UPDATE = "BILLINGDETAILS_HANDLE_UPDATE";
export const BILLINGDETAILS_HANDLE_UPDATE_FORM =
  "BILLINGDETAILS_HANDLE_UPDATE_FORM";
export const BILLINGDETAILS_HANDLE_IS_OPEN = "BILLINGDETAILS_HANDLE_IS_OPEN";

// Module: Dispute Files
export const DISPUTEFILES_HANDLE_ERROR = "DISPUTEFILES_HANDLE_ERROR";
export const DISPUTEFILES_GET_INDEX = "DISPUTEFILES_GET_INDEX";
export const DISPUTEFILES_HANDLE_INDEX = "DISPUTEFILES_HANDLE_INDEX";
export const DISPUTEFILES_POST_CREATE = "DISPUTEFILES_POST_CREATE";
export const DISPUTEFILES_PUT_UPDATE_COMPLETED = "DISPUTEFILES_PUT_UPDATE_COMPLETED";

// Module: DisputeTor
export const DISPUTETOR_GET_INDEX = "DISPUTETOR_GET_INDEX";
export const DISPUTETOR_GET_SHOW = "DISPUTETOR_GET_SHOW";
export const DISPUTETOR_POST_CREATE = "DISPUTETOR_POST_CREATE";
export const DISPUTETOR_PUT_UPDATE = "DISPUTETOR_PUT_UPDATE";
export const DISPUTETOR_PUT_ENVELOPE_CREATE = "DISPUTETOR_PUT_ENVELOPE_CREATE";
export const DISPUTETOR_PUT_UPDATE_COMPLETED =
  "DISPUTETOR_PUT_UPDATE_COMPLETED";

export const DISPUTETOR_HANDLE_IS_REVIEW_OPEN =
  "DISPUTETOR_HANDLE_IS_REVIEW_OPEN";
export const DISPUTETOR_HANDLE_IS_SEND_TO_HUB_OPEN =
  "DISPUTETOR_HANDLE_IS_SEND_TO_HUB_OPEN";
export const DISPUTETOR_HANDLE_IS_SEND_TO_PARTIES_OPEN =
  "DISPUTETOR_HANDLE_IS_SEND_TO_PARTIES_OPEN";
export const DISPUTETOR_HANDLE_IS_SIGN_TOR_OPEN =
  "DISPUTETOR_HANDLE_IS_SIGN_TOR_OPEN";

export const DISPUTETOR_HANDLE_ERROR = "DISPUTETOR_HANDLE_ERROR";
export const DISPUTETOR_HANDLE_SIGNATURE_ERROR =
  "DISPUTETOR_HANDLE_SIGNATURE_ERROR";
export const DISPUTETOR_HANDLE_INDEX = "DISPUTETOR_HANDLE_INDEX";
export const DISPUTETOR_HANDLE_SHOW = "DISPUTETOR_HANDLE_SHOW";
export const DISPUTETOR_HANDLE_CREATE = "DISPUTETOR_HANDLE_CREATE";
export const DISPUTETOR_HANDLE_UPDATE = "DISPUTETOR_HANDLE_UPDATE";

// Module: Hub

export const HUB_GET_INDEX = "HUB_GET_INDEX";
export const HUB_GET_SHOW = "HUB_GET_SHOW";
export const HUB_PUT_UPDATE = "HUB_PUT_UPDATE";

export const HUB_GET_ARBITRATORS = "HUB_GET_ARBITRATORS";
export const HUB_HANDLE_ARBITRATORS = "HUB_HANDLE_ARBITRATORS";

export const HUB_GET_ROSTER_INDEX = "HUB_GET_ROSTER_INDEX";
export const HUB_HANDLE_ROSTER_INDEX = "HUB_HANDLE_ROSTER_INDEX";

export const HUB_GET_ROSTER_PROFILE = "HUB_GET_ROSTER_PROFILE";
export const HUB_HANDLE_ROSTER_PROFILE = "HUB_HANDLE_ROSTER_PROFILE";

export const HUB_POST_INVITE_ARBITRATOR = "HUB_POST_INVITE_ARBITRATOR";
export const HUB_HANDLE_INVITE_ARBITRATOR = "HUB_HANDLE_INVITE_ARBITRATOR";

export const HUB_PUT_UPDATE_ROSTER_PROFILE = "HUB_PUT_UPDATE_ROSTER_PROFILE";
export const HUB_HANDLE_UPDATE_ROSTER_PROFILE =
  "HUB_HANDLE_UPDATE_ROSTER_PROFILE";

export const HUB_HANDLE_ERROR = "HUB_HANDLE_ERROR";
export const HUB_HANDLE_INDEX = "HUB_HANDLE_INDEX";
export const HUB_HANDLE_SHOW = "HUB_HANDLE_SHOW";
export const HUB_HANDLE_UPDATE = "HUB_HANDLE_UPDATE";
export const HUB_HANDLE_PROFILE = "HUB_HANDLE_PROFILE";

// Module: File

export const FILE_GET_INDEX = "FILE_GET_INDEX";
export const FILE_GET_SHOW = "FILE_GET_SHOW";
export const FILE_GET_INLINE_SHOW = "FILE_GET_INLINE_SHOW";
export const FILE_PUT_UPDATE = "FILE_PUT_UPDATE";
export const FILE_POST_CREATE = "FILE_POST_CREATE";
export const FILE_DELETE = "FILE_DELETE";

export const FILE_HANDLE_ERROR = "FILE_HANDLE_ERROR";
export const FILE_HANDLE_INDEX = "FILE_HANDLE_INDEX";
export const FILE_HANDLE_SHOW = "FILE_HANDLE_SHOW";
export const FILE_HANDLE_INLINE_SHOW = "FILE_HANDLE_INLINE_SHOW";
export const FILE_HANDLE_UPDATE = "FILE_HANDLE_UPDATE";
export const FILE_HANDLE_CREATE = "FILE_HANDLE_CREATE";
export const FILE_HANDLE_RESET = "FILE_HANDLE_RESET";

// Module: Hearing
export const HEARING_GET_INDEX = "HEARING_GET_INDEX";
export const HEARING_GET_SHOW = "HEARING_GET_SHOW";
export const HEARING_POST_CREATE = "HEARING_POST_CREATE";
export const HEARING_POST_CREATE_SUCCESS = "HEARING_POST_CREATE_SUCCESS";
export const HEARING_PUT_UPDATE = "HEARING_PUT_UPDATE";

export const HEARING_HANDLE_ERROR = "HEARING_HANDLE_ERROR";
export const HEARING_HANDLE_MEMORANDUM_SIGNATURE_ERROR =
  "HEARING_HANDLE_MEMORANDUM_SIGNATURE_ERROR";
export const HEARING_HANDLE_INDEX = "HEARING_HANDLE_INDEX";
export const HEARING_HANDLE_SHOW = "HEARING_HANDLE_SHOW";
export const HEARING_HANDLE_CREATE = "HEARING_HANDLE_CREATE";
export const HEARING_HANDLE_UPDATE = "HEARING_HANDLE_UPDATE";

export const HEARING_GET_INDEX_MEMORANDUM = "HEARING_GET_INDEX_MEMORANDUM";
export const HEARING_GET_SHOW_MEMORANDUM = "HEARING_GET_SHOW_MEMORANDUM";
export const HEARING_HANDLE_INDEX_MEMORANDUM =
  "HEARING_HANDLE_INDEX_MEMORANDUM";
export const HEARING_HANDLE_SHOW_MEMORANDUM = "HEARING_HANDLE_SHOW_MEMORANDUM";
export const HEARING_HANDLE_SIGN_MEMORANDUM_COMPLETED =
  "HEARING_HANDLE_SIGN_MEMORANDUM_COMPLETED";
export const HEARING_HANDLE_UPDATE_MEMORANDUM =
  "HEARING_HANDLE_UPDATE_MEMORANDUM";
export const HEARING_PUT_UPDATE_MEMORANDUM = "HEARING_PUT_UPDATE_MEMORANDUM";

export const HEARING_GET_SHOW_MINUTES = "HEARING_GET_SHOW_MINUTES";
export const HEARING_HANDLE_SHOW_MINUTES = "HEARING_HANDLE_SHOW_MINUTES";
export const HEARING_POST_CREATE_MINUTES = "HEARING_POST_CREATE_MINUTES";
export const HEARING_HANDLE_UPDATE_MINUTES = "HEARING_HANDLE_UPDATE_MINUTES";
export const HEARING_HANDLE_SIGN_MINUTES_COMPLETED =
  "HEARING_HANDLE_SIGN_MINUTES_COMPLETED";

// Module: Evidence Request
export const EVIDENCEREQUEST_GET_INDEX = "EVIDENCEREQUEST_GET_INDEX";
export const EVIDENCEREQUEST_GET_SHOW = "EVIDENCEREQUEST_GET_SHOW";
export const EVIDENCEREQUEST_POST_CREATE = "EVIDENCEREQUEST_POST_CREATE";
export const EVIDENCEREQUEST_PUT_UPDATE = "EVIDENCEREQUEST_PUT_UPDATE";
export const EVIDENCEREQUEST_PUT_UPDATE_COMPLETED =
  "EVIDENCEREQUEST_PUT_UPDATE_COMPLETED";

export const EVIDENCEREQUEST_HANDLE_ERROR = "EVIDENCEREQUEST_HANDLE_ERROR";
export const EVIDENCEREQUEST_HANDLE_SIGNATURE_ERROR =
  "EVIDENCEREQUEST_HANDLE_SIGNATURE_ERROR";
export const EVIDENCEREQUEST_HANDLE_INDEX = "EVIDENCEREQUEST_HANDLE_INDEX";
export const EVIDENCEREQUEST_HANDLE_SHOW = "EVIDENCEREQUEST_HANDLE_SHOW";
export const EVIDENCEREQUEST_HANDLE_CREATE = "EVIDENCEREQUEST_HANDLE_CREATE";
export const EVIDENCEREQUEST_HANDLE_UPDATE = "EVIDENCEREQUEST_HANDLE_UPDATE";

export const EVIDENCEREQUEST_HANDLE_IS_REQUEST_DOCUMENT_OPEN =
  "EVIDENCEREQUEST_HANDLE_IS_REQUEST_DOCUMENT_OPEN";
export const EVIDENCEREQUEST_HANDLE_IS_SEND_DOCUMENTS_OPEN =
  "EVIDENCEREQUEST_HANDLE_IS_SEND_DOCUMENTS_OPEN";

// Module: Arbitrator Panel
export const ARBITRATORPANEL_HANDLE_ERROR = "ARBITRATORPANEL_HANDLE_ERROR";
export const ARBITRATORPANEL_GET_INDEX = "ARBITRATORPANEL_GET_INDEX";
export const ARBITRATORPANEL_HANDLE_INDEX = "ARBITRATORPANEL_HANDLE_INDEX";
export const ARBITRATORPANEL_POST_CREATE = "ARBITRATORPANEL_POST_CREATE";
export const ARBITRATORPANEL_HANDLE_CREATE = "ARBITRATORPANEL_HANDLE_CREATE";
export const ARBITRATORPANEL_HANDLE_UPDATE_STRIKELIST =
  "ARBITRATORPANEL_HANDLE_UPDATE_STRIKELIST";
export const ARBITRATORPANEL_PUT_UPDATE_STRIKE =
  "ARBITRATORPANEL_PUT_UPDATE_STRIKE";
export const ARBITRATORPANEL_HANDLE_UPDATE_STRIKE =
  "ARBITRATORPANEL_HANDLE_UPDATE_STRIKE";

// Module: Arbitrator

export const ARBITRATOR_GET_INDEX = "ARBITRATOR_GET_INDEX";
export const ARBITRATOR_HANDLE_INDEX = "ARBITRATOR_HANDLE_INDEX";
export const ARBITRATOR_GET_SHOW = "ARBITRATOR_GET_SHOW";
export const ARBITRATOR_HANDLE_SHOW = "ARBITRATOR_HANDLE_SHOW";
export const ARBITRATOR_HANDLE_ERROR = "ARBITRATOR_HANDLE_ERROR";

// Module: Dispute Assignment
export const DISPUTEASSIGNMENT_GET_INDEX = "DISPUTEASSIGNMENT_GET_INDEX";
export const DISPUTEASSIGNMENT_GET_SHOW = "DISPUTEASSIGNMENT_GET_SHOW";
export const DISPUTEASSIGNMENT_GET_SHOW_DIRECT =
  "DISPUTEASSIGNMENT_GET_SHOW_DIRECT";
export const DISPUTEASSIGNMENT_POST_CREATE = "DISPUTEASSIGNMENT_POST_CREATE";
export const DISPUTEASSIGNMENT_PUT_UPDATE = "DISPUTEASSIGNMENT_PUT_UPDATE";
export const DISPUTEASSIGNMENT_PUT_UPDATE_FILE =
  "DISPUTEASSIGNMENT_PUT_UPDATE_FILE";
export const DISPUTEASSIGNMENT_PUT_UPDATE_DIRECT =
  "DISPUTEASSIGNMENT_PUT_UPDATE_DIRECT";
export const DISPUTEASSIGNMENT_GET_INDEX_ARBITRATORS =
  "DISPUTEASSIGNMENT_GET_INDEX_ARBITRATORS";
export const DISPUTEASSIGNMENT_GET_INDEX_EXPERTS =
  "DISPUTEASSIGNMENT_GET_INDEX_EXPERTS";

export const DISPUTEASSIGNMENT_HANDLE_RESET = "DISPUTEASSIGNMENT_HANDLE_RESET";
export const DISPUTEASSIGNMENT_HANDLE_ERROR = "DISPUTEASSIGNMENT_HANDLE_ERROR";
export const DISPUTEASSIGNMENT_HANDLE__SIGNATURE_ERROR =
  "DISPUTEASSIGNMENT_HANDLE__SIGNATURE_ERROR";
export const DISPUTEASSIGNMENT_HANDLE_SIGN_COMPLETED =
  "DISPUTEASSIGNMENT_HANDLE_SIGN_COMPLETED";
export const DISPUTEASSIGNMENT_HANDLE_INDEX = "DISPUTEASSIGNMENT_HANDLE_INDEX";
export const DISPUTEASSIGNMENT_HANDLE_RESULT =
  "DISPUTEASSIGNMENT_HANDLE_RESULT";
export const DISPUTEASSIGNMENT_HANDLE_SHOW = "DISPUTEASSIGNMENT_HANDLE_SHOW";
export const DISPUTEASSIGNMENT_HANDLE_CREATE =
  "DISPUTEASSIGNMENT_HANDLE_CREATE";
export const DISPUTEASSIGNMENT_HANDLE_UPDATE =
  "DISPUTEASSIGNMENT_HANDLE_UPDATE";

// Module: Payment

export const PAYMENT_GET_INDEX = "PAYMENT_GET_INDEX";
export const PAYMENT_GET_SHOW = "PAYMENT_GET_SHOW";
export const PAYMENT_POST_CREATE = "PAYMENT_POST_CREATE";
export const PAYMENT_PUT_UPDATE = "PAYMENT_PUT_UPDATE";
export const PAYMENT_POST_CREATE_TRANSACTION =
  "PAYMENT_POST_CREATE_TRANSACTION";
export const PAYMENT_PUT_UPDATE_TRANSACTION = "PAYMENT_PUT_UPDATE_TRANSACTION";

export const PAYMENT_HANDLE_ERROR = "PAYMENT_HANDLE_ERROR";
export const PAYMENT_HANDLE_INDEX = "PAYMENT_HANDLE_INDEX";
export const PAYMENT_HANDLE_SHOW = "PAYMENT_HANDLE_SHOW";
export const PAYMENT_HANDLE_CREATE = "PAYMENT_HANDLE_CREATE";
export const PAYMENT_HANDLE_UPDATE = "PAYMENT_HANDLE_UPDATE";
export const PAYMENT_HANDLE_CREATE_TRANSACTION =
  "PAYMENT_HANDLE_CREATE_TRANSACTION";
export const PAYMENT_HANDLE_UPDATE_TRANSACTION =
  "PAYMENT_HANDLE_UPDATE_TRANSACTION";
export const PAYMENT_HANDLE_IS_OPEN = "PAYMENT_HANDLE_IS_OPEN";

// Module: Dispute Users (People)
export const DISPUTEUSER_GET_INDEX = "DISPUTEUSER_GET_INDEX";
export const DISPUTEUSER_GET_SHOW = "DISPUTEUSER_GET_SHOW";
export const DISPUTEUSER_PUT_UPDATE = "DISPUTEUSER_PUT_UPDATE";
export const DISPUTEUSER_PUT_UPDATE_KYC = "DISPUTEUSER_PUT_UPDATE_KYC";
export const DISPUTEUSER_PUT_UPDATE_STATUS = "DISPUTEUSER_PUT_UPDATE_STATUS";

export const DISPUTEUSER_HANDLE_ERROR = "DISPUTEUSER_HANDLE_ERROR";
export const DISPUTEUSER_HANDLE_INDEX = "DISPUTEUSER_HANDLE_INDEX";
export const DISPUTEUSER_HANDLE_SHOW = "DISPUTEUSER_HANDLE_SHOW";
export const DISPUTEUSER_HANDLE_UPDATE = "DISPUTEUSER_HANDLE_UPDATE";
export const DISPUTEUSER_HANDLE_UPDATE_KYC = "DISPUTEUSER_HANDLE_UPDATE_KYC";
export const DISPUTEUSER_HANDLE_UPDATE_STATUS =
  "DISPUTEUSER_HANDLE_UPDATE_STATUS";
export const DISPUTEUSER_HANDLE_IS_OPEN = "DISPUTEUSER_HANDLE_IS_OPEN";
export const DISPUTEUSER_HANDLE_MOTIVATION_FORM =
  "DISPUTEUSER_HANDLE_MOTIVATION_FORM";
export const DISPUTEUSER_HANDLE_IS_MOTIVATION_OPEN =
  "DISPUTEUSER_HANDLE_IS_MOTIVATION_OPEN";

// Module: Dispute Filing
export const DISPUTEFILING_HANDLE_ERROR = "DISPUTEFILING_HANDLE_ERROR";
export const DISPUTEFILING_HANDLE_SIGNATURE_ERROR =
  "DISPUTEFILING_HANDLE_SIGNATURE_ERROR";

export const DISPUTEFILING_HANDLE_START = "DISPUTEFILING_HANDLE_START";
export const DISPUTEFILING_HANDLE_SHOW = "DISPUTEFILING_HANDLE_SHOW";
export const DISPUTEFILING_POST_CREATE = "DISPUTEFILING_POST_CREATE";
export const DISPUTEFILING_HANDLE_CREATE = "DISPUTEFILING_HANDLE_CREATE";
export const DISPUTEFILING_HANDLE_STEP_CHANGE =
  "DISPUTEFILING_HANDLE_STEP_CHANGE";

export const DISPUTEFILING_HANDLE_HUB = "DISPUTEFILING_HANDLE_HUB";
export const DISPUTEFILING_HANDLE_UPDATE_INVITES_FORM =
  "DISPUTEFILING_HANDLE_UPDATE_INVITES_FORM";
export const DISPUTEFILING_HANDLE_UPDATE_MAINCLAIMANT_FORM =
  "DISPUTEFILING_HANDLE_UPDATE_MAINCLAIMANT_FORM";
export const DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_STATUS =
  "DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_STATUS";
export const DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_DESCRIPTION =
  "DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_DESCRIPTION";

export const DISPUTEFILING_HANDLE_STEP_BACK = "DISPUTEFILING_HANDLE_STEP_BACK";
export const DISPUTEFILING_HANDLE_STEP_HUB = "DISPUTEFILING_HANDLE_STEP_HUB";
export const DISPUTEFILING_HANDLE_UPDATE_HUB_FORM =
  "DISPUTEFILING_HANDLE_UPDATE_HUB_FORM";
export const DISPUTEFILING_HANDLE_UPDATE_USER_ROLE =
  "DISPUTEFILING_HANDLE_UPDATE_USER_ROLE";

export const DISPUTEFILING_POST_CREATE_DISPUTE =
  "DISPUTEFILING_POST_CREATE_DISPUTE";
export const DISPUTEFILING_HANDLE_CREATE_DISPUTE =
  "DISPUTEFILING_HANDLE_CREATE_DISPUTE";

export const DISPUTEFILING_GET_SHOW_KYC = "DISPUTEFILING_GET_SHOW_KYC";
export const DISPUTEFILING_PUT_UPDATE_KYC = "DISPUTEFILING_PUT_UPDATE_KYC";
export const DISPUTEFILING_HANDLE_SHOW_KYC = "DISPUTEFILING_HANDLE_SHOW_KYC";
export const DISPUTEFILING_HANDLE_UPDATE_KYC =
  "DISPUTEFILING_HANDLE_UPDATE_KYC";
export const DISPUTEFILING_HANDLE_UPDATE_KYC_FORM =
  "DISPUTEFILING_HANDLE_UPDATE_KYC_FORM";

export const DISPUTEFILING_GET_SHOW_CLAIMANT =
  "DISPUTEFILING_GET_SHOW_CLAIMANT";
export const DISPUTEFILING_PUT_UPDATE_CLAIMANT =
  "DISPUTEFILING_PUT_UPDATE_CLAIMANT";
export const DISPUTEFILING_HANDLE_SHOW_CLAIMANT =
  "DISPUTEFILING_HANDLE_SHOW_CLAIMANT";
export const DISPUTEFILING_HANDLE_UPDATE_CLAIMANT =
  "DISPUTEFILING_HANDLE_UPDATE_CLAIMANT";
export const DISPUTEFILING_HANDLE_UPDATE_CLAIMANT_FORM =
  "DISPUTEFILING_HANDLE_UPDATE_CLAIMANT_FORM";

export const DISPUTEFILING_GET_SHOW_CLAIMDETAILS =
  "DISPUTEFILING_GET_SHOW_CLAIMDETAILS";
export const DISPUTEFILING_PUT_UPDATE_CLAIMDETAILS =
  "DISPUTEFILING_PUT_UPDATE_CLAIMDETAILS";
export const DISPUTEFILING_HANDLE_SHOW_CLAIMDETAILS =
  "DISPUTEFILING_HANDLE_SHOW_CLAIMDETAILS";
export const DISPUTEFILING_HANDLE_UPDATE_CLAIMDETAILS =
  "DISPUTEFILING_HANDLE_UPDATE_CLAIMDETAILS";
export const DISPUTEFILING_HANDLE_UPDATE_CLAIMDETAILS_FORM =
  "DISPUTEFILING_HANDLE_UPDATE_CLAIMDETAILS_FORM";

export const DISPUTEFILING_GET_SHOW_CLAIMFILES =
  "DISPUTEFILING_GET_SHOW_CLAIMFILES";
export const DISPUTEFILING_PUT_UPDATE_CLAIMFILES =
  "DISPUTEFILING_PUT_UPDATE_CLAIMFILES";
export const DISPUTEFILING_HANDLE_SHOW_CLAIMFILES =
  "DISPUTEFILING_HANDLE_SHOW_CLAIMFILES";
export const DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES =
  "DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES";
export const DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_FORM =
  "DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_FORM";

export const DISPUTEFILING_GET_SHOW_ADDRESPONDENT =
  "DISPUTEFILING_GET_SHOW_ADDRESPONDENT";
export const DISPUTEFILING_PUT_UPDATE_ADDRESPONDENT =
  "DISPUTEFILING_PUT_UPDATE_ADDRESPONDENT";
export const DISPUTEFILING_HANDLE_SHOW_ADDRESPONDENT =
  "DISPUTEFILING_HANDLE_SHOW_ADDRESPONDENT";
export const DISPUTEFILING_HANDLE_UPDATE_ADDRESPONDENT =
  "DISPUTEFILING_HANDLE_UPDATE_ADDRESPONDENT";
export const DISPUTEFILING_HANDLE_UPDATE_ADDRESPONDENT_FORM =
  "DISPUTEFILING_HANDLE_UPDATE_ADDRESPONDENT_FORM";

export const DISPUTEFILING_GET_SHOW_INVITES = "DISPUTEFILING_GET_SHOW_INVITES";
export const DISPUTEFILING_PUT_UPDATE_INVITES =
  "DISPUTEFILING_PUT_UPDATE_INVITES";
export const DISPUTEFILING_HANDLE_SHOW_INVITES =
  "DISPUTEFILING_HANDLE_SHOW_INVITES";
export const DISPUTEFILING_HANDLE_UPDATE_INVITES =
  "DISPUTEFILING_HANDLE_UPDATE_INVITES";

export const DISPUTEFILING_GET_SHOW_PAYMENT = "DISPUTEFILING_GET_SHOW_PAYMENT";
export const DISPUTEFILING_PUT_UPDATE_PAYMENT =
  "DISPUTEFILING_PUT_UPDATE_PAYMENT";
export const DISPUTEFILING_HANDLE_SHOW_PAYMENT =
  "DISPUTEFILING_HANDLE_SHOW_PAYMENT";
export const DISPUTEFILING_HANDLE_UPDATE_PAYMENT =
  "DISPUTEFILING_HANDLE_UPDATE_PAYMENT";
export const DISPUTEFILING_HANDLE_UPDATE_PAYMENT_FORM =
  "DISPUTEFILING_HANDLE_UPDATE_PAYMENT_FORM";

export const DISPUTEFILING_GET_SHOW_SOC = "DISPUTEFILING_GET_SHOW_SOC";
export const DISPUTEFILING_PUT_UPDATE_SOC = "DISPUTEFILING_PUT_UPDATE_SOC";
export const DISPUTEFILING_HANDLE_SHOW_SOC = "DISPUTEFILING_HANDLE_SHOW_SOC";
export const DISPUTEFILING_HANDLE_UPDATE_SOC =
  "DISPUTEFILING_HANDLE_UPDATE_SOC";

export const DISPUTEFILING_PUT_UPDATE_COMPLETED =
  "DISPUTEFILING_PUT_UPDATE_COMPLETED";
export const DISPUTEFILING_HANDLE_UPDATE_COMPLETED =
  "DISPUTEFILING_HANDLE_UPDATE_COMPLETED";

// Module: Response Filing
export const RESPONSEFILING_HANDLE_START = "RESPONSEFILING_HANDLE_START";
export const RESPONSEFILING_HANDLE_ERROR = "RESPONSEFILING_HANDLE_ERROR";
export const RESPONSEFILING_HANDLE_SIGNATURE_ERROR =
  "RESPONSEFILING_HANDLE_SIGNATURE_ERROR";
export const RESPONSEFILING_HANDLE_STEP_BACK =
  "RESPONSEFILING_HANDLE_STEP_BACK";
export const RESPONSEFILING_HANDLE_STEP_CHANGE =
  "RESPONSEFILING_HANDLE_STEP_CHANGE";

export const RESPONSEFILING_GET_SHOW_DEFENCETEAM =
  "RESPONSEFILING_GET_SHOW_DEFENCETEAM";
export const RESPONSEFILING_HANDLE_UPDATE_DEFENCETEAM_FORM =
  "RESPONSEFILING_HANDLE_UPDATE_DEFENCETEAM_FORM";
export const RESPONSEFILING_PUT_UPDATE_DEFENCETEAM =
  "RESPONSEFILING_PUT_UPDATE_DEFENCETEAM";
export const RESPONSEFILING_HANDLE_SHOW_DEFENCETEAM =
  "RESPONSEFILING_HANDLE_SHOW_DEFENCETEAM";
export const RESPONSEFILING_HANDLE_UPDATE_DEFENCETEAM =
  "RESPONSEFILING_HANDLE_UPDATE_DEFENCETEAM";

export const RESPONSEFILING_POST_CREATE = "RESPONSEFILING_POST_CREATE";
export const RESPONSEFILING_HANDLE_CREATE = "RESPONSEFILING_HANDLE_CREATE";

export const RESPONSEFILING_GET_SHOW_KYC = "RESPONSEFILING_GET_SHOW_KYC";
export const RESPONSEFILING_PUT_UPDATE_KYC = "RESPONSEFILING_PUT_UPDATE_KYC";
export const RESPONSEFILING_HANDLE_SHOW_KYC = "RESPONSEFILING_HANDLE_SHOW_KYC";
export const RESPONSEFILING_HANDLE_UPDATE_KYC =
  "RESPONSEFILING_HANDLE_UPDATE_KYC";
export const RESPONSEFILING_HANDLE_UPDATE_KYC_FORM =
  "RESPONSEFILING_HANDLE_UPDATE_KYC_FORM";
export const RESPONSEFILING_HANDLE_UPDATE_INVITES_FORM =
  "RESPONSEFILING_HANDLE_UPDATE_INVITES_FORM";
export const RESPONSEFILING_HANDLE_UPDATE_MAINRESPONDENT_FORM =
  "RESPONSEFILING_HANDLE_UPDATE_MAINRESPONDENT_FORM";

export const RESPONSEFILING_GET_SHOW_RESPONDENT =
  "RESPONSEFILING_GET_SHOW_RESPONDENT";
export const RESPONSEFILING_HANDLE_UPDATE_RESPONDENT_FORM =
  "RESPONSEFILING_HANDLE_UPDATE_RESPONDENT_FORM";
export const RESPONSEFILING_PUT_UPDATE_RESPONDENT =
  "RESPONSEFILING_PUT_UPDATE_RESPONDENT";
export const RESPONSEFILING_HANDLE_SHOW_RESPONDENT =
  "RESPONSEFILING_HANDLE_SHOW_RESPONDENT";
export const RESPONSEFILING_HANDLE_UPDATE_RESPONDENT =
  "RESPONSEFILING_HANDLE_UPDATE_RESPONDENT";

export const RESPONSEFILING_GET_SHOW_RESPONSEDETAILS =
  "RESPONSEFILING_GET_SHOW_RESPONSEDETAILS";
export const RESPONSEFILING_HANDLE_UPDATE_RESPONSEDETAILS_FORM =
  "RESPONSEFILING_HANDLE_UPDATE_RESPONSEDETAILS_FORM";
export const RESPONSEFILING_PUT_UPDATE_RESPONSEDETAILS =
  "RESPONSEFILING_PUT_UPDATE_RESPONSEDETAILS";
export const RESPONSEFILING_HANDLE_SHOW_RESPONSEDETAILS =
  "RESPONSEFILING_HANDLE_SHOW_RESPONSEDETAILS";
export const RESPONSEFILING_HANDLE_UPDATE_RESPONSEDETAILS =
  "RESPONSEFILING_HANDLE_UPDATE_RESPONSEDETAILS";

export const RESPONSEFILING_GET_SHOW_RESPONSEFILES =
  "RESPONSEFILING_GET_SHOW_RESPONSEFILES";
export const RESPONSEFILING_PUT_UPDATE_RESPONSEFILES =
  "RESPONSEFILING_PUT_UPDATE_RESPONSEFILES";
export const RESPONSEFILING_HANDLE_SHOW_RESPONSEFILES =
  "RESPONSEFILING_HANDLE_SHOW_RESPONSEFILES";
export const RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES =
  "RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES";
export const RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_STATUS =
  "RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_STATUS";
export const RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_DESCRIPTION =
  "RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_DESCRIPTION";
export const RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_FORM =
  "RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_FORM";

export const RESPONSEFILING_GET_SHOW_INVITES =
  "RESPONSEFILING_GET_SHOW_INVITES";
export const RESPONSEFILING_PUT_UPDATE_INVITES =
  "RESPONSEFILING_PUT_UPDATE_INVITES";
export const RESPONSEFILING_HANDLE_SHOW_INVITES =
  "RESPONSEFILING_HANDLE_SHOW_INVITES";
export const RESPONSEFILING_HANDLE_UPDATE_INVITES =
  "RESPONSEFILING_HANDLE_UPDATE_INVITES";

export const RESPONSEFILING_GET_SHOW_PAYMENT =
  "RESPONSEFILING_GET_SHOW_PAYMENT";
export const RESPONSEFILING_PUT_UPDATE_PAYMENT =
  "RESPONSEFILING_PUT_UPDATE_PAYMENT";
export const RESPONSEFILING_HANDLE_SHOW_PAYMENT =
  "RESPONSEFILING_HANDLE_SHOW_PAYMENT";
export const RESPONSEFILING_HANDLE_UPDATE_PAYMENT =
  "RESPONSEFILING_HANDLE_UPDATE_PAYMENT";
export const RESPONSEFILING_HANDLE_UPDATE_PAYMENT_FORM =
  "RESPONSEFILING_HANDLE_UPDATE_PAYMENT_FORM";

export const RESPONSEFILING_GET_SHOW_SOD = "RESPONSEFILING_GET_SHOW_SOD";
export const RESPONSEFILING_PUT_UPDATE_SOD = "RESPONSEFILING_PUT_UPDATE_SOD";
export const RESPONSEFILING_HANDLE_SHOW_SOD = "RESPONSEFILING_HANDLE_SHOW_SOD";
export const RESPONSEFILING_HANDLE_UPDATE_SOD =
  "RESPONSEFILING_HANDLE_UPDATE_SOD";

export const RESPONSEFILING_PUT_UPDATE_COMPLETED =
  "RESPONSEFILING_PUT_UPDATE_COMPLETED";
export const RESPONSEFILING_HANDLE_UPDATE_COMPLETED =
  "RESPONSEFILING_HANDLE_UPDATE_COMPLETED";

// Module: Assisted Dispute
export const ASSISTEDDISPUTE_GET_PHASES_INDEX =
  "ASSISTEDDISPUTE_GET_SHOW_PHASES";
export const ASSISTEDDISPUTE_HANDLE_PHASES_INDEX =
  "ASSISTEDDISPUTE_HANDLE_PHASES_INDEX";
export const ASSISTEDDISPUTE_PUT_UPDATE_PHASES =
  "ASSISTEDDISPUTE_PUT_UPDATE_PHASES";
export const ASSISTEDDISPUTE_HANDLE_UPDATE_PHASES =
  "ASSISTEDDISPUTE_HANDLE_UPDATE_PHASES";
export const ASSISTEDDISPUTE_HANDLE_UPDATE_PHASES_FORM =
  "ASSISTEDDISPUTE_HANDLE_UPDATE_PHASES_FORM";
export const ASSISTEDDISPUTE_GET_LASTACTIVITY_SHOW =
  "ASSISTEDDISPUTE_GET_LASTACTIVITY";
export const ASSISTEDDISPUTE_HANDLE_LASTACTIVITY_SHOW =
  "ASSISTEDDISPUTE_HANDLE_LASTACTIVITY_SHOW";
export const ASSISTEDDISPUTE_PUT_UPDATE_LASTACTIVITY =
  "ASSISTEDDISPUTE_PUT_UPDATE_LASTACTIVITY";
export const ASSISTEDDISPUTE_HANDLE_UPDATE_LASTACTIVITY =
  "ASSISTEDDISPUTE_HANDLE_UPDATE_LASTACTIVITY";
export const ASSISTEDDISPUTE_HANDLE_UPDATE_LASTACTIVITY_FORM =
  "ASSISTEDDISPUTE_HANDLE_UPDATE_LASTACTIVITY_FORM";
export const ASSISTEDDISPUTE_GET_TASKLIST =
  "ASSISTEDDISPUTE_GET_TASKLIST_INDEX";
export const ASSISTEDDISPUTE_HANDLE_TASKLIST =
  "ASSISTEDDISPUTE_HANDLE_TASKLIST";
export const ASSISTEDDISPUTE_PUT_UPDATE_TASKLIST =
  "ASSISTEDDISPUTE_PUT_UPDATE_TASKLIST";
export const ASSISTEDDISPUTE_HANDLE_UPDATE_TASKLIST =
  "ASSISTEDDISPUTE_HANDLE_UPDATE_TASKLIST";
export const ASSISTEDDISPUTE_HANDLE_UPDATE_TASKLIST_FORM =
  "ASSISTEDDISPUTE_HANDLE_UPDATE_TASKLIST_FORM";
export const ASSISTEDDISPUTE_PUT_UPDATE_TASKSTATUS =
  "ASSISTEDDISPUTE_PUT_UPDATE_TASKSTATUS";
export const ASSISTEDDISPUTE_HANDLE_UPDATE_TASKSTATUS =
  "ASSISTEDDISPUTE_HANDLE_UPDATE_TASKSTATUS";
export const ASSISTEDDISPUTE_HANDLE_ERROR = "ASSISTEDDISPUTE_HANDLE_ERROR";

// Module: Defensive Actions
export const DEFENSIVEACTIONS_HANDLE_ERROR = "DEFENSIVEACTIONS_HANDLE_ERROR";
export const DEFENSIVEACTIONS_PUT_UPDATE_DEFENCE =
  "DEFENSIVEACTIONS_PUT_UPDATE_DEFENCE";
export const DEFENSIVEACTIONS_HANDLE_UPDATE_DEFENCE =
  "DEFENSIVEACTIONS_HANDLE_UPDATE_DEFENCE";
export const DEFENSIVEACTIONS_POST_CREATE_COUNTERCLAIM =
  "DEFENSIVEACTIONS_POST_CREATE_COUNTERCLAIM";
export const DEFENSIVEACTIONS_HANDLE_CREATE_COUNTERCLAIM =
  "DEFENSIVEACTIONS_HANDLE_CREATE_COUNTERCLAIM";
export const DEFENSIVEACTIONS_PUT_UPDATE_COUNTERCLAIMREPLY =
  "DEFENSIVEACTIONS_PUT_UPDATE_COUNTERCLAIMREPLY";
export const DEFENSIVEACTIONS_HANDLE_UPDATE_COUNTERCLAIMREPLY =
  "DEFENSIVEACTIONS_HANDLE_UPDATE_COUNTERCLAIMREPLY";
export const DEFENSIVEACTIONS_HANDLE_IS_REQUEST_DEADLINE_OPEN =
  "DEFENSIVEACTIONS_HANDLE_IS_REQUEST_DEADLINE_OPEN";
export const DEFENSIVEACTIONS_PUT_UPDATE_DEADLINE =
  "DEFENSIVEACTIONS_PUT_UPDATE_DEADLINE";
export const DEFENSIVEACTIONS_HANDLE_UPDATE_DEADLINE =
  "DEFENSIVEACTIONS_HANDLE_UPDATE_DEADLINE";

// Module: Arbitral Award
export const ARBITRALAWARD_GET_INDEX = "ARBITRALAWARD_GET_INDEX";
export const ARBITRALAWARD_GET_SHOW = "ARBITRALAWARD_GET_SHOW";
export const ARBITRALAWARD_POST_CREATE = "ARBITRALAWARD_POST_CREATE";
export const ARBITRALAWARD_PUT_UPDATE = "ARBITRALAWARD_PUT_UPDATE";
export const ARBITRALAWARD_PUT_DEADLINE_UPDATE =
  "ARBITRALAWARD_PUT_DEADLINE_UPDATE";
export const ARBITRALAWARD_PUT_UPDATE_COMPLETED =
  "ARBITRALAWARD_PUT_UPDATE_COMPLETED";

export const ARBITRALAWARD_HANDLE_IS_REVIEW_OPEN =
  "ARBITRALAWARD_HANDLE_IS_REVIEW_OPEN";
export const ARBITRALAWARD_HANDLE_IS_SEND_TO_HUB_OPEN =
  "ARBITRALAWARD_HANDLE_IS_SEND_TO_HUB_OPEN";
export const ARBITRALAWARD_HANDLE_IS_SEND_TO_PARTIES_OPEN =
  "ARBITRALAWARD_HANDLE_IS_SEND_TO_PARTIES_OPEN";
export const ARBITRALAWARD_HANDLE_IS_REQUEST_EDITS_OPEN =
  "ARBITRALAWARD_HANDLE_IS_REQUEST_EDITS_OPEN";
export const ARBITRALAWARD_HANDLE_IS_EXTEND_DEADLINE_OPEN =
  "ARBITRALAWARD_HANDLE_IS_EXTEND_DEADLINE_OPEN";

export const ARBITRALAWARD_HANDLE_ERROR = "ARBITRALAWARD_HANDLE_ERROR";
export const ARBITRALAWARD_HANDLE_SIGNATURE_ERROR =
  "ARBITRALAWARD_HANDLE_SIGNATURE_ERROR";
export const ARBITRALAWARD_HANDLE_INDEX = "ARBITRALAWARD_HANDLE_INDEX";
export const ARBITRALAWARD_HANDLE_SHOW = "ARBITRALAWARD_HANDLE_SHOW";
export const ARBITRALAWARD_HANDLE_CREATE = "ARBITRALAWARD_HANDLE_CREATE";
export const ARBITRALAWARD_HANDLE_UPDATE = "ARBITRALAWARD_HANDLE_UPDATE";

// Module: Procedural Order
export const PROCEDURALORDER_SET_IS_FORM_VISIBLE =
  "PROCEDURALORDER_SET_IS_FORM_VISIBLE";
export const PROCEDURALORDER_FETCH_INDEX = "PROCEDURALORDER_FETCH_INDEX";
export const PROCEDURALORDER_SET_INDEX = "PROCEDURALORDER_SET_INDEX";
export const PROCEDURALORDER_FETCH_CURRENT = "PROCEDURALORDER_FETCH_CURRENT";
export const PROCEDURALORDER_SET_CURRENT = "PROCEDURALORDER_SET_CURRENT";

export const PROCEDURALORDER_GET_FLOW_LIST = "PROCEDURALORDER_GET_FLOW_LIST";
export const PROCEDURALORDER_HANDLE_FLOW_LIST =
  "PROCEDURALORDER_HANDLE_FLOW_LIST";

export const PROCEDURALORDER_HANDLE_ERROR = "PROCEDURALORDER_HANDLE_ERROR";
export const PROCEDURALORDER_HANDLE_SIGNATURE_ERROR =
  "PROCEDURALORDER_HANDLE_SIGNATURE_ERROR";

export const PROCEDURALORDER_POST_CREATE = "PROCEDURALORDER_POST_CREATE";
export const PROCEDURALORDER_HANDLE_CREATE = "PROCEDURALORDER_HANDLE_CREATE";

export const PROCEDURALORDER_POST_CREATE_REVISION =
  "PROCEDURALORDER_POST_CREATE_REVISION";
export const PROCEDURALORDER_HANDLE_CREATE_REVISION =
  "PROCEDURALORDER_HANDLE_CREATE_REVISION";

export const PROCEDURALORDER_PUT_UPDATE = "PROCEDURALORDER_PUT_UPDATE";
export const PROCEDURALORDER_HANDLE_UPDATE = "PROCEDURALORDER_HANDLE_UPDATE";

export const PROCEDURALORDER_GET_VERSIONS = "PROCEDURALORDER_GET_VERSIONS";
export const PROCEDURALORDER_HANDLE_VERSIONS =
  "PROCEDURALORDER_HANDLE_VERSIONS";

export const PROCEDURALORDER_PUT_UPDATE_COMPLETED =
  "PROCEDURALORDER_PUT_UPDATE_COMPLETED";

/**
 * Please follow conventions for new types as mentioned above
 * ADD NEW ACTION TYPES ABOVE THIS COMMENT LINE!
 * Legacy action type names are kept below as is.
 */

// User login
export const LOGIN_USER = "login_user";
export const LOGIN_USER_SUCCEEDED = "login_user_succeeded";
export const LOGIN_USER_FAILED = "login_user_failed";

// User logout
export const LOGOUT_USER = "logout_user";
export const LOGOUT_USER_SUCCEEDED = "logout_user_succeeded";
export const LOGOUT_USER_FAILED = "logout_user_failed";

// Authenticate user
export const AUTHENTICATE_USER = "autheticate_user";
export const AUTHENTICATE_USER_SUCCEEDED = "autheticate_user_succeeded";
export const AUTHENTICATE_USER_FAILED = "autheticate_user_failed";

export const AUTH_GET_CURRENT_USER = "AUTH_GET_CURRENT_USER";
export const AUTH_HANDLE_CURRENT_USER = "AUTH_HANDLE_CURRENT_USER";

// Forgot password
export const FORGOT_PASSWORD = "forgot_password";
export const FORGOT_PASSWORD_SUCCEEDED = "forgot_password_succeeded";
export const FORGOT_PASSWORD_FAILED = "forgot_password_failed";

// Reset password
export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_SUCCEEDED = "reset_password_succeeded";
export const RESET_PASSWORD_FAILED = "reset_password_failed";

// Register user
export const REGISTER_USER = "register_user";
export const REGISTER_USER_SUCCEEDED = "register_user_succeeded";
export const REGISTER_USER_FAILED = "register_user_failed";
export const AUTH_POST_ACTIVATE = "AUTH_POST_ACTIVATE";
export const AUTH_HANDLE_ACTIVATE = "AUTH_HANDLE_ACTIVATE";
export const AUTH_POST_ACCEPT_INVITE = "AUTH_POST_ACCEPT_INVITE";
export const AUTH_POST_RESEND_REGISTER = "AUTH_POST_RESEND_REGISTER";

// Error
export const SET_ERROR = "set_error";
export const CLEAR_ERROR = "clear_error";
export const AUTH_HANDLE_ERROR = "AUTH_HANDLE_ERROR";

/**
 * NOTE: Please do not add new action types below this line
 */
