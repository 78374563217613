import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { canAccess, useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import * as disputeUserActions from "../../redux/actions/disputeUserActions";
import * as actions from "../../redux/actions/evidenceRequestActions";
import * as fileActions from "../../redux/actions/fileActions";
import { selectParticipants } from "../../redux/selectors/disputeUsersSelector";
import * as selectors from "../../redux/selectors/evidenceRequestSelector";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import IndexRender from "./IndexRender";

export default function () {
  const hubSlug = useHubSlug();
  const [disputeId, dispute] = useDispute();

  const evidenceRequests = useSelector(selectors.selectEvidenceRequests);
  const evidenceRequestsMeta = useSelector(
    selectors.selectEvidenceRequestsMeta
  );
  const {
    isRequestDocumentVisible,
    isLoading,
    isSendDocumentsVisible,
    isFetchingTor,
  } = useSelector(selectors.whole);
  const errors = useSelector(selectors.selectError);
  const canSendRequest = useAccess("create", "evidence-request");
  const canUpdateRequest = useAccess("update", "evidence-request");
  const participants = useSelector(selectParticipants);
  const individualRequestList = participants?.filter((u) =>
    canAccess(u, "individual-update", "evidence-request")
  );

  const isWitness = useAccess("individual-update", "evidence-request");

  const dispatch = useDispatch();

  const openRequestDocument = () => {
    dispatch(actions.handleIsRequestDocumentOpen(true));
  };

  const closeRequestDocument = () => {
    dispatch(actions.handleIsRequestDocumentOpen(false));
  };

  const handleDocumentRequest = (body, resetForm) => {
    dispatch(actions.postCreate(disputeId, body, resetForm));
  };

  const openSendDocuments = () => {
    dispatch(actions.handleIsSendDocumentsOpen(true));
  };

  const closeSendDocuments = () => {
    dispatch(actions.handleIsSendDocumentsOpen(false));
  };

  const handleSendDocuments = (body, resetForm) => {
    dispatch(
      actions.putUpdate(
        disputeId,
        body.id,
        { fileId: body.fileId },
        false,
        resetForm
      )
    );
  };

  const deleteFile = (id) => void dispatch(fileActions.deleteFile(id));

  const handlePageChange = (page) => {
    dispatch(actions.getIndex(disputeId, page));
  };

  useEffect(() => {
    dispatch(actions.getIndex(disputeId));
    dispatch(disputeUserActions.getIndex(disputeId));
  }, [disputeId, dispatch]);

  return dispute ? (
    <IndexRender
      hubSlug={hubSlug}
      disputeId={disputeId}
      dispute={dispute}
      evidenceRequests={evidenceRequests}
      canSendRequest={canSendRequest}
      canUpdateRequest={canUpdateRequest}
      isRequestDocumentVisible={isRequestDocumentVisible}
      openRequestDocument={openRequestDocument}
      closeRequestDocument={closeRequestDocument}
      handleDocumentRequest={handleDocumentRequest}
      errors={errors}
      individualRequestList={individualRequestList}
      extraCrumbs={extraCrumbs(hubSlug, disputeId)}
      isLoading={isLoading}
      isSendDocumentsVisible={isSendDocumentsVisible}
      openSendDocuments={openSendDocuments}
      closeSendDocuments={closeSendDocuments}
      handleSendDocuments={handleSendDocuments}
      isFetchingTor={isFetchingTor}
      isWitness={isWitness}
      evidenceRequestsMeta={evidenceRequestsMeta}
      onPageChange={handlePageChange}
      redirectUrl={`${hubSlug}/disputes/${disputeId}/evidence-requests`}
      deleteFile={deleteFile}
    />
  ) : (
    <Spinner />
  );
}

const extraCrumbs = (hubSlug, disputeId) => [
  {
    path: `/${hubSlug}/disputes/${disputeId}/evidence-requests`,
    breadcrumbName: (
      <Translation text="evidence_request.evidence_requests.title" />
    ),
  },
];
