import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCEEDED,
} from "../constants/constants";

export const loginUser = (payload) => {
  return {
    type: LOGIN_USER,
    payload: payload,
  };
};

export const loginUserSucceeded = (payload) => {
  return {
    type: LOGIN_USER_SUCCEEDED,
    payload: payload,
  };
};

export const loginUserFailed = (error) => {
  return {
    type: LOGIN_USER_FAILED,
    error: error,
  };
};
