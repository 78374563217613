import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/actions/authenticateActions";
import { getArbitrators, handleUpdate } from "../../redux/actions/hubActions";
import { userDetails } from "../../redux/selectors/authSelector";
import {
  selectArbitrators,
  selectProfile,
} from "../../redux/selectors/hubsSelector";
import HubSettingsRender from "./HubSettingsRender";

export default function () {
  const dispatch = useDispatch();
  const user = useSelector(userDetails);
  const hubProfile = useSelector(selectProfile);
  const arbitrators = useSelector(selectArbitrators);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      const hub = {
        attributes: {
          url: user?.attributes?.hub?.slug,
        },
      };
      dispatch(handleUpdate(hub));
      dispatch(getArbitrators(hub));
    }
  }, [dispatch, user]);

  return (
    <HubSettingsRender
      user={user}
      hubProfile={hubProfile}
      arbitrators={arbitrators}
    />
  );
}
