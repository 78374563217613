import { push } from "connected-react-router";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  errorMessage,
  successMessage,
} from "../../components/Common/Feedback/Message";
import * as actions from "../actions/evidenceRequestActions";
import { handleUpdateSlug } from "../actions/hubActions";
import * as signActions from "../actions/signActions";
import * as timelineActions from "../actions/timelineActions";
import * as apis from "../apis/evidenceRequestsApi";
import * as types from "../constants/constants";

const tag = "evidence-requests";

const indexTLParams = (disputeId) => {
  return {
    activityId: disputeId,
    activityType: "Evidence Request Index",
  };
};

const showTLParams = (docId) => {
  return {
    activityId: docId,
    activityType: "Evidence Request Show",
  };
};

export function* getIndex(action) {
  const { disputeId, page } = action.payload;
  try {
    const res = yield call(apis.index, disputeId, page);
    yield put(actions.handleIndex(res));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShow(action) {
  const { disputeId, evidenceRequestId } = action.payload;
  try {
    const res = yield call(apis.show, disputeId, evidenceRequestId);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreate(action) {
  const { disputeId, body, resetForm = () => {} } = action.payload;
  try {
    const res = yield call(apis.create, disputeId, body);
    yield put(actions.handleCreate(res));
    successMessage("evidence_request.document_requested_successfully.text");
    yield put(timelineActions.getShow(disputeId, indexTLParams(disputeId)));
    yield put(actions.getIndex(disputeId));
    resetForm();
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdate(action) {
  const {
    disputeId,
    evidenceRequestId,
    body,
    isShow,
    resetForm = () => {},
  } = action.payload;
  try {
    const res = yield call(apis.update, disputeId, evidenceRequestId, body);
    yield put(actions.handleUpdate(res));
    if (res?.data?.attributes.redirectURL) {
      yield put(signActions.handleIsRedirecting(true));
      window.location = res?.data?.attributes.redirectURL;
    } else {
      successMessage("evidence_request.document_sent_successfully.text");
      if (isShow) {
        yield put(
          timelineActions.getShow(disputeId, showTLParams(evidenceRequestId))
        );
        yield put(actions.getShow(disputeId, evidenceRequestId));
      } else {
        yield put(timelineActions.getShow(disputeId, indexTLParams(disputeId)));
        yield put(actions.getIndex(disputeId));
      }
      resetForm();
    }
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateCompleted({ payload }) {
  const { disputeId, hubSlug } = payload?.body?.meta;
  try {
    successMessage("evidence_request.updated_evidence_request_signature.text");
    if (hubSlug && disputeId) {
      yield put(handleUpdateSlug(hubSlug));
      yield put(push(`/${hubSlug}/disputes/${disputeId}/evidence-requests`));
    } else yield put(push(`/`));
  } catch (e) {
    console.error(tag, `saga putUpdateCompleted error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* handleSignatureError({ payload }) {
  const { disputeId, hubSlug } = payload?.params;
  errorMessage("general.signature_error.text");
  if (hubSlug && disputeId) {
    yield put(handleUpdateSlug(hubSlug));
    yield put(push(`/${hubSlug}/disputes/${disputeId}/evidence-requests`));
  } else yield put(push(`/`));
}

export default function* evidenceRequestsSaga() {
  yield takeLatest(types.EVIDENCEREQUEST_GET_INDEX, getIndex);
  yield takeLatest(types.EVIDENCEREQUEST_GET_SHOW, getShow);
  yield takeEvery(types.EVIDENCEREQUEST_POST_CREATE, postCreate);
  yield takeLatest(types.EVIDENCEREQUEST_PUT_UPDATE, putUpdate);
  yield takeLatest(
    types.EVIDENCEREQUEST_PUT_UPDATE_COMPLETED,
    putUpdateCompleted
  );
  yield takeLatest(
    types.EVIDENCEREQUEST_HANDLE_SIGNATURE_ERROR,
    handleSignatureError
  );
}
