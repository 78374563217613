import { useSelector } from "react-redux";
import { accessRules, selectAccessor } from "../redux/selectors/authSelector";
import { readLoggedInUser } from "../helpers/authHelpers";

export function useAccess(actions, entity, options) {
  const rules = useSelector(accessRules);
  const accessor = useSelector(selectAccessor);
  return readLoggedInUser().name && canAccess(accessor, actions, entity, rules, options);
}

export function canAccess(
  accessor,
  actions,
  entity,
  rules = false,
  options = { all: true }
) {
  let accessorRules = rules;
  if (!rules) {
    const state = window.store.getState();
    accessorRules = state.authenticate.user.access_rules;
  }
  if (!accessorRules) return null;
  const rulesToEvaluate = rulesOfActor(
    accessorRules,
    accessor?.group || accessor?.userGroup,
    accessor?.role
  );

  const available = actionsOfEntity(rulesToEvaluate, entity);
  const asked = Array.isArray(actions) ? actions : [actions];
  return asked[options?.all ? "every" : "some"]((action) =>
    available.includes(action)
  );
}

export function rulesOfActor(rules, group, role) {
  return rules[actorIdentifier(role, group)] || {};
}

export function keysOf(prefix, suffix, connector = ":") {
  return [prefix, suffix].join(connector);
}

export function actorIdentifier(role, group) {
  return `${keysOf("G", group)},${keysOf("R", role)}`;
}

export function actionsOfEntity(rules, entity) {
  return rules[entity] || [];
}
