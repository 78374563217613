import { Button, Modal } from "antd";
import { DatePicker, Form, Input } from "formik-antd";
import React from "react";
import { datePickerProps } from "../../../helpers/dateHelpers";
import { toFormItem } from "../../../helpers/formHelpers";

export default function AddPhaseForm({
  isAddItemVisible,
  setIsAddItemVisible,
  onAddPhase,
  values,
  setFieldValue,
  resetForm,
}) {
  const onClick = () => {
    onAddPhase(values);
    resetForm({ values: {} });
  };

  const buttonLabel =
    typeof values?.index === "number" ? "Update Phase" : "Add Phase";

  return (
    <Modal
      destroyOnClose={true}
      onCancel={() => setIsAddItemVisible(false)}
      footer={
        <Button
          type="primary"
          onClick={onClick}
          disabled={
            !values?.stateName || !values?.stateDescription || !values?.deadline
          }
        >
          {buttonLabel}
        </Button>
      }
      visible={isAddItemVisible}
      closeIcon={null}
      maskClosable={false}
    >
      <Form layout="vertical">{fields({ values, setFieldValue })}</Form>
    </Modal>
  );
}

const fields = ({ values, setFieldValue }) =>
  [
    {
      name: "stateName",
      label: "Name",
    },

    {
      name: "stateDescription",
      label: "Description",
      component: Input.TextArea,
      componentProps: { autoSize: true },
    },

    {
      name: "deadline",
      label: "Deadline",
      component: DatePicker,
      componentProps: {
        ...datePickerProps({ showTime: true }),
      },
    },
  ].map(toFormItem(values, setFieldValue));
