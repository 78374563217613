import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {
  disputeTors: [],
  disputeTor: null,
  isSendToHubVisible: false,
  isSendToPartiesVisible: false,
  isReviewTermsVisible: false,
  isSignTORVisible: false,
  isLoading: false,
};

export default function (state = initial, action) {
  switch (action.type) {
    case types.DISPUTETOR_GET_INDEX:
      return { ...state, disputeTors: [] };
    case types.DISPUTETOR_HANDLE_INDEX:
      return { ...state, disputeTors: action.payload };
    case types.DISPUTETOR_HANDLE_ERROR:
      return { ...state, error: errorMessages(action.error), isLoading: false };
    case types.DISPUTETOR_HANDLE_CREATE:
      return { ...state, isSendToHubVisible: false, isLoading: false };
    case types.DISPUTETOR_POST_CREATE:
      return { ...state, isLoading: true };
    case types.DISPUTETOR_PUT_UPDATE:
      return { ...state, isLoading: true };
    case types.DISPUTETOR_HANDLE_IS_REVIEW_OPEN:
      return { ...state, isReviewTermsVisible: action.payload };
    case types.DISPUTETOR_HANDLE_IS_SIGN_TOR_OPEN:
      return { ...state, isSignTORVisible: action.payload };
    case types.DISPUTETOR_HANDLE_IS_SEND_TO_HUB_OPEN:
      return { ...state, isSendToHubVisible: action.payload };
    case types.DISPUTETOR_HANDLE_IS_SEND_TO_PARTIES_OPEN:
      return { ...state, isSendToPartiesVisible: action.payload };
    case types.DISPUTETOR_HANDLE_UPDATE:
      return {
        ...state,
        isReviewTermsVisible: false,
        isSendToPartiesVisible: false,
        isLoading: false,
      };
    case types.DISPUTETOR_GET_SHOW:
      return {
        ...state,
        disputeTor: null,
      };
    case types.DISPUTETOR_HANDLE_SHOW:
      return {
        ...state,
        disputeTor: action.payload?.data?.data,
      };
    default:
      return state;
  }
}
