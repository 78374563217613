import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "billing-details";
const catcher = logAndThrow(tag);

export function show(disputeId) {
  return Api.get(`/disputes/${disputeId}/billing-details`)
    .then((res) => res.data)
    .catch(catcher("show"));
}

export function update(disputeId, body) {
  return Api.put(`/disputes/${disputeId}/billing-details`, body).catch(
    catcher("update")
  );
}
