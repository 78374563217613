import * as types from "../constants/constants";

export const getIndex = (disputeId, page = 1) => ({
  type: types.ARBITRALAWARD_GET_INDEX,
  payload: { disputeId, page },
});

export const handleIndex = (awards) => ({
  type: types.ARBITRALAWARD_HANDLE_INDEX,
  payload: awards,
});

export const getShow = (disputeId, awardId) => ({
  type: types.ARBITRALAWARD_GET_SHOW,
  payload: { disputeId, awardId },
});

export const handleIsSendToHubOpen = (isOpen) => ({
  type: types.ARBITRALAWARD_HANDLE_IS_SEND_TO_HUB_OPEN,
  payload: isOpen,
});

export const handleIsSendToPartiesOpen = (isOpen) => ({
  type: types.ARBITRALAWARD_HANDLE_IS_SEND_TO_PARTIES_OPEN,
  payload: isOpen,
});

export const handleIsExtendDeadlineOpen = (isOpen) => ({
  type: types.ARBITRALAWARD_HANDLE_IS_EXTEND_DEADLINE_OPEN,
  payload: isOpen,
});

export const handleIsReviewOpen = (isOpen) => ({
  type: types.ARBITRALAWARD_HANDLE_IS_REVIEW_OPEN,
  payload: isOpen,
});

export const handleIsRequestEditsOpen = (isOpen) => ({
  type: types.ARBITRALAWARD_HANDLE_IS_REQUEST_EDITS_OPEN,
  payload: isOpen,
});

export const postCreate = (disputeId, body, resetForm) => {
  return {
    type: types.ARBITRALAWARD_POST_CREATE,
    payload: { disputeId, body, resetForm },
  };
};

export const handleCreate = () => ({
  type: types.ARBITRALAWARD_HANDLE_CREATE,
});

export const putUpdate = (
  disputeId,
  hubSlug,
  awardId,
  fileId,
  body,
  isShow = false,
) => ({
  type: types.ARBITRALAWARD_PUT_UPDATE,
  payload: { disputeId, hubSlug, awardId, fileId, body, isShow },
});

export const handleShow = (award) => ({
  type: types.ARBITRALAWARD_HANDLE_SHOW,
  payload: award,
});

export const handleUpdate = () => ({
  type: types.ARBITRALAWARD_HANDLE_UPDATE,
});

export const putDeadlineUpdate = (disputeId, body, resetForm) => ({
  type: types.ARBITRALAWARD_PUT_DEADLINE_UPDATE,
  payload: { disputeId, body, resetForm },
});

export const putUpdateCompleted = (disputeId, params) => ({
  type: types.ARBITRALAWARD_PUT_UPDATE_COMPLETED,
  payload: { disputeId, params },
});

export const handleError = (e) => ({
  type: types.ARBITRALAWARD_HANDLE_ERROR,
  error: e,
});

export const handleSignatureError = (disputeId, params) => ({
  type: types.ARBITRALAWARD_HANDLE_SIGNATURE_ERROR,
  payload: { disputeId, params },
});
