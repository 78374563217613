import { Button, Col, Modal, Row, Space, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Input, Radio } from "formik-antd";
import React from "react";
import ProfileTabs from "../Arbitrator/ProfileTabs";
import StaticPanel from "../Arbitrator/StaticPanel";
import Translation from "../Common/Translation";
import { handleChange } from "../DisputeFiling/helpers";
import JurContentWrapper from "../Layouts/JurContentWrapper";
import "./styles.less";

const canUpdateStatus = (status) => {
  return status === "Profile Submitted";
};

export default function ShowRender({
  profile,
  isUpdateStatusVisible,
  setIsUpdateStatusVisible,
  onReviewProfile,
}) {
  const reviewButton = () =>
    canUpdateStatus(profile?.status) ? (
      <>
        <br />
        <Button
          onClick={() => {
            setIsUpdateStatusVisible(true);
          }}
          type="primary"
        >
          Review Profile
        </Button>
      </>
    ) : null;

  return (
    <JurContentWrapper>
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <StaticPanel reviewContent={reviewButton()} profile={profile} />
        </Col>
        <Col span={18}>
          <ProfileTabs profile={profile} />
        </Col>
      </Row>
      <UpdateForm
        isUpdateStatusVisible={isUpdateStatusVisible}
        setIsUpdateStatusVisible={setIsUpdateStatusVisible}
        onSubmit={onReviewProfile}
      />
    </JurContentWrapper>
  );
}

const UpdateForm = ({
  setIsUpdateStatusVisible,
  isUpdateStatusVisible,
  onSubmit,
}) => (
  <Formik
    initialValues={{
      status: null,
      reason: "",
    }}
  >
    {({ values, setFieldValue, resetForm }) => {
      const handler = handleChange(values, setFieldValue);
      const disableSubmit =
        values?.status === null ||
        (values?.status === "Jur Asked for Revision" && values?.reason === "");
      return (
        <Modal
          onCancel={() => setIsUpdateStatusVisible(false)}
          visible={isUpdateStatusVisible}
          footer={
            <Button
              disabled={disableSubmit}
              onClick={() => {
                onSubmit(values, resetForm);
              }}
            >
              <Translation text="general.submit.button" />
            </Button>
          }
          destroyOnClose={true}
          title={
            <Typography.Title level={5}>Send Your Review</Typography.Title>
          }
        >
          <Form layout="vertical">
            <Space>
              <FormItem name="status">
                <Radio.Group onChange={handler("status")} name="status">
                  <Radio value={"Jur Approved"}>Accept Profile</Radio>
                  <Radio value={"Jur Asked for Revision"}>Reject Profile</Radio>
                </Radio.Group>
              </FormItem>
            </Space>
            <br />
            {values.status === "Jur Asked for Revision" && (
              <FormItem
                style={{ minHeight: 80 }}
                label={"Reason"}
                name="reason"
              >
                <Input onChange={handler("reason")} name="reason" />
              </FormItem>
            )}
          </Form>
        </Modal>
      );
    }}
  </Formik>
);
