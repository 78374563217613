import { Button, Modal, Typography } from "antd";
import { Formik } from "formik";
import {
  Checkbox,
  DatePicker,
  Form,
  FormItem,
  Input,
  Radio,
} from "formik-antd";
import React from "react";
import { datePickerProps } from "../../helpers/dateHelpers";
import { errorAsExtra } from "../../helpers/errorHelpers";
import Translation from "../Common/Translation";
import { handleChange } from "../DisputeFiling/helpers";

const StyledRadio = (props) => (
  <Radio className="jur-vertical-radio" {...props} />
);

export default function RequestNewDocumentForm({
  visible,
  handleDocumentRequest,
  closeRequestDocument,
  isLoading,
  witnessList,
  errors,
}) {
  const errorProps = errorAsExtra(errors);
  const initialForm = {
    sentToUser: null,
    description: "",
    deadline: null,
    requiresSignature: false,
  };
  return (
    <Formik initialValues={initialForm}>
      {({ values, setFieldValue, resetForm }) => {
        const handler = handleChange(values, setFieldValue);
        return (
          <Modal
            width={768}
            visible={visible}
            onCancel={() => {
              closeRequestDocument();
              resetForm(initialForm);
            }}
            title={
              <Typography.Title level={5}>
                <Translation
                  text="evidence_request.request_new_button.button"
                  pad={true}
                />
              </Typography.Title>
            }
            footer={[
              <Button
                disabled={
                  !(
                    values.deadline &&
                    values.description !== "" &&
                    values.sentToUser
                  ) || isLoading
                }
                loading={isLoading}
                type="primary"
                onClick={() => {
                  if (
                    values.sentToUser === "Claimant" ||
                    values.sentToUser === "Respondent"
                  ) {
                    values.sendToGroup = values.sentToUser;
                  } else {
                    values.sentToDisputeUserId = values.sentToUser;
                  }
                  handleDocumentRequest(values, () => {
                    resetForm(initialForm);
                  });
                }}
              >
                <Translation text="evidence_request.send.button" pad={true} />
              </Button>,
            ]}
          >
            <Form layout="vertical">
              <FormItem
                name="sentToUser"
                required={true}
                {...errorProps("sentToUser")}
                label={
                  <Translation text="evidence_request.select_party.text" />
                }
              >
                <Radio.Group name="sentToUser" onChange={handler("sentToUser")}>
                  <StyledRadio value="Claimant">
                    <Translation text="evidence_request.claimant.text" />
                  </StyledRadio>
                  <StyledRadio value="Respondent">
                    <Translation text="evidence_request.respondent.text" />
                  </StyledRadio>
                  {witnessList?.map((w) => (
                    <StyledRadio
                      value={w?.disputeUserId}
                    >{`${w?.userGroup} - ${w?.fullName}`}</StyledRadio>
                  ))}
                </Radio.Group>
              </FormItem>
              <FormItem
                required={true}
                name="description"
                label={
                  <Translation text="evidence_request.context_of_the_request.text" />
                }
                {...errorProps("description")}
              >
                <Input.TextArea name="description" />
              </FormItem>
              <FormItem name="requiresSignature">
                <Checkbox name="requiresSignature">
                  <Typography.Text>
                    <Translation text="evidence_request.document_should_be_digitally_signed.text" />
                  </Typography.Text>
                </Checkbox>
              </FormItem>
              <FormItem
                name="deadline"
                label={
                  <Translation text="evidence_request.please_mention_the_deadline.text" />
                }
                required={true}
                {...errorProps("deadline")}
              >
                <DatePicker
                  {...datePickerProps({ showTime: true })}
                  name="deadline"
                />
              </FormItem>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
