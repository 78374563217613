import React from "react";
import JurLogo from "../Common/Logos/JurLogo";
import Translation from "../Common/Translation";
import "./styles.less";

export default function OnboardingSidebar({
  userName,
  currentStep,
  isOnboarding,
}) {
  const labelKey = currentStep === 2 ? "user_details" : "onboarding";

  const title = isOnboarding
    ? `onboarding.${labelKey}.title`
    : "profile.edit_profile.title";
  const description = isOnboarding
    ? `onboarding.${labelKey}.description`
    : "profile.edit_profile.description";

  return (
    <div>
      {isOnboarding ? <JurLogo /> : null}
      <div className="sidebar-title">
        <Translation text={title} />
        <br />
        {labelKey === "onboarding" && isOnboarding ? userName : null}
      </div>
      <div className="sidebar-description">
        <Translation text={description} />
      </div>
      <center>
        <img
          className="jur-onboarding-sidebar-illustration"
          src={require("../../assets/illustrations/illustration-1.png")}
          alt="illustration"
        />
      </center>
    </div>
  );
}
