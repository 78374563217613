import React from "react";
import { useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import IndexRender from "./IndexRender";

export default function () {
  const hubSlug = useHubSlug();
  const [disputeId, dispute] = useDispute();

  const assignExpert = useAccess("assign-expert", "dispute-assignment");
  const canAssign = useAccess(
    ["assign-arbitrator", "assign-expert"],
    "dispute-assignment",
    { all: false }
  );

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/dispute-assignment`,
      breadcrumbName: (
        <Translation text="dispute_assignment.assign_arbitrator.title" />
      ),
    },
  ];

  return canAssign && dispute ? (
    <IndexRender
      extraCrumbs={extraCrumbs}
      hubSlug={hubSlug}
      entity={assignExpert ? "expert" : "arbitrator"}
      disputeId={disputeId}
      dispute={dispute}
    />
  ) : (
    <Spinner />
  );
}
