import { Card, Typography } from "antd";
import React from "react";
import DisputeHeader from "../Common/DisputeHeader";
import PDFRender from "../Common/PDFRender";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";

export default function ({
  dispute,
  disputeId,
  extraCrumbs,
  hubSlug,
  fileId,
  additionalAwardFileId,
}) {
  return (
    <Card>
      {dispute && fileId ? (
        <DisputeHeader
          dispute={dispute}
          disputeId={disputeId}
          hubSlug={hubSlug}
          extraCrumbs={extraCrumbs}
        >
          <Typography.Title level={3}>
            <Translation text="arbitral_award.arbitral_award.title" />
          </Typography.Title>
          {dispute?.arbitralAward && (
            <PDFRender fileId={fileId} useList={true} />
          )}
          {additionalAwardFileId && (
            <>
              <br />
              <br />
              <Typography.Title level={3}>
                <Translation text="arbitral_award.additional_arbitral_award.title" />
              </Typography.Title>
              <PDFRender fileId={additionalAwardFileId} useList={true} />
            </>
          )}
        </DisputeHeader>
      ) : (
        <Spinner />
      )}
    </Card>
  );
}
