import { Layout } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isAuthenticated } from "../../redux/selectors/authSelector";
import JurLogo from "../Common/Logos/JurLogo";
import NavbarActionButtons from "./NavbarActionButtons";
import "./styles.less";

const { Header } = Layout;

function Navbar() {
  const _isAuthenticated = useSelector(isAuthenticated);
  const currentRoute = useLocation();
  const path = currentRoute?.pathname;

  if (path.includes("/onboarding") || !_isAuthenticated) return null;
  return (
    <>
      <NavbarWrapper>
        <BrandLogo />
        <NavbarActionButtons />
      </NavbarWrapper>
      <div style={{ marginTop: 64 }} />
      {/* To avoid margin dependency on Content for screens without Navbar */}
    </>
  );
}

export default Navbar;

const NavbarWrapper = ({ children }) => (
  <Header className="jur-navbar-wrapper">{children} </Header>
);

const BrandLogo = () => (
  <div className="jur-navbar-logo">
    <JurLogo />
  </div>
);
