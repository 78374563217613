import { Button, Form, Input, Modal, Radio } from "antd";
import React, { useState } from "react";
import { NamespacesConsumer } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  handleIsOpen,
  handleUpdateMotivation,
  putUpdateReject,
} from "../../redux/actions/disputeActions";
import { putUpdate } from "../../redux/actions/disputeAssignmentActions";
import { AWAITING_ARBITRATOR_ACCEPTANCE } from "../../redux/constants/DisputeStatuses";
import { whole } from "../../redux/selectors/disputesSelector";
import Translation from "../Common/Translation";

export default function ({ disputeId, status, isAssignment }) {
  const { isOpen, motivation } = useSelector(whole);
  const [selected, setSelected] = useState("");
  const [text, setText] = useState("");
  const dispatch = useDispatch();

  const reject = () =>
    void dispatch(
      isAssignment
        ? putUpdate(disputeId, "reject", {
            accepted: false,
            reasonForRejection: motivation,
          })
        : putUpdateReject(disputeId, status, motivation)
    );

  const open = () => void dispatch(handleIsOpen(true));
  const close = () => void dispatch(handleIsOpen(false));

  const handleSelect = ({ target }) => {
    const { value } = target;
    dispatch(handleUpdateMotivation(value?.startsWith("Other") ? text : value));
    setSelected(value);
  };

  const handleText = ({ target }) => {
    const { value } = target;
    dispatch(handleUpdateMotivation(value));
    setText(value);
  };

  return (
    <>
      <Button onClick={open} key="action-reject" danger={true}>
        <Translation text="dispute.reject.button" />
      </Button>
      <Modal
        width={1100}
        visible={isOpen}
        onCancel={close}
        onOk={reject}
        okButtonProps={{ danger: true, disabled: !motivation }}
        okText={<Translation text="dispute.reject.button" />}
      >
        <Form layout="vertical">
          <Form.Item
            name="motivation"
            label={<Translation text="dispute.reject_motivation.text" />}
          >
            <NamespacesConsumer>
              {(t) => (
                <Radio.Group onChange={handleSelect}>
                  {options(status, t)}
                </Radio.Group>
              )}
            </NamespacesConsumer>
          </Form.Item>
          <Form.Item>
            <Input.TextArea
              onChange={handleText}
              disabled={!selected?.startsWith("Other")}
            ></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function options(status, t) {
  const fields =
    status === AWAITING_ARBITRATOR_ACCEPTANCE
      ? [
          "dispute_assignment.not_specialized.text",
          "dispute_assignment.conflict_of_interest.text",
          "dispute_assignment.not_available.text",
          "dispute.other.text",
        ]
      : [
          "dispute.hub_not_specialized.text",
          "dispute.hub_not_in_law.text",
          "dispute.claim_value_too_high.text",
          "dispute.claim_value_too_low.text",
          "dispute.other.text",
        ];

  return fields.map((label) => (
    <Radio value={t(label)} key={label} className="jur-width-full">
      <Translation text={label} />
    </Radio>
  ));
}
