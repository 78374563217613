import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import * as HearingActions from "../../redux/actions/hearingActions";
import { getIndex } from "../../redux/actions/disputeFileActions";
import { COMPLETED } from "../../redux/constants/HearingStatuses";
import { selectError, selectGroup, selectIndex, selectMeta, whole } from "../../redux/selectors/disputeFilesSelector";
import { postCreateMinutes } from "../../redux/actions/hearingActions";
import * as HearingsSelector from "../../redux/selectors/hearingsSelector";
import { fromMeta } from "../../transformers/paginationTransformer";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import IndexRender from "./IndexRender";

export default function () {
  const [disputeId, dispute] = useDispute();
  const HearingsData = useSelector(selectIndex);
  const meta = useSelector(selectMeta);
  const wholeData = useSelector(whole);
  const errors = useSelector(selectError);
  const userGroup = useSelector(selectGroup);
  const hearings = useSelector(HearingsSelector.completedIndex);
  const signerIds = useSelector(HearingsSelector.participantUserIds);
  const dispatch = useDispatch();
  const hubSlug = useHubSlug();
  const isArbitrator = !userGroup;
  const isUploading = wholeData?.isUploading;
  const [isUploadFormVisible, setIsUploadFormVisible] = useState(false);

  useEffect(() => {
    dispatch(getIndex(disputeId, 1, "Hearing"));
    if (isArbitrator) dispatch(HearingActions.getIndex(disputeId));
  }, [dispatch, disputeId, dispute, isArbitrator]);

  useEffect(() => {
    if (!isUploading && Object.keys(errors || {}).length === 0) {
      setIsUploadFormVisible(false);
    }
  }, [isUploading, errors]);

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/files`,
      breadcrumbName: <Translation text="general.case_files.title" />,
    },
  ];

  const handleSendDocuments = (hearingId, fileId) => {
    const uiRedirectUrl = `/${hubSlug}/disputes/${disputeId}/files/arbitrator/hearing`;
    dispatch(postCreateMinutes(disputeId, hubSlug, hearingId, fileId, signerIds, uiRedirectUrl));
  };

  const onPageChange = (page, type) => {
    dispatch(getIndex(disputeId, page, "Hearing"));
  };

  return dispute ? (
    <IndexRender
      disputeParams={{ dispute, disputeId, hubSlug }}
      isArbitrator={isArbitrator}
      extraCrumbs={extraCrumbs}
      pagination={fromMeta(meta, onPageChange)}
      HearingFiles={HearingsData}
      errors={errors}
      pasthearings={hearings}
      completedHearings={hearings?.filter((h) => h.attributes.status === COMPLETED)}
      isUploading={isUploading}
      isLoading={wholeData?.isLoading}
      isUploadFormVisible={isUploadFormVisible}
      handleSendDocuments={handleSendDocuments}
      openSendDocuments={() => setIsUploadFormVisible(true)}
      closeSendDocuments={() => setIsUploadFormVisible(false)}
    />
  ) : (
    <Spinner />
  );
}
