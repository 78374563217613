export const DRAFT = "Draft";

export const HUB_REJECTED = "Jur rejected";
export const HUB_APPROVAL_PENDING = "Request for arbitration sent to Jur";
export const HUB_ACCEPTED = "Awaiting arbitrator assignment";

export const ARBITRATOR_ASSIGNMENT_PENDING = "Awaiting arbitrator assignment";
export const AWAITING_ARBITRATOR_ACCEPTANCE = "Awaiting arbitrator acceptance";

export const ONGOING = "Ongoing";
export const AWARD_ISSUED = "Award issued";

export const ARBITRATION_CLOSED_BY_ARBITRATOR =
  "Arbitration closed by the Arbitrator";
export const ARBITRATION_CLOSED_BY_HUB = "Arbitration closed by Jur";
