import { Col, Row } from "antd";
import React from "react";
import ProfileTabs from "../Arbitrator/ProfileTabs";
import StaticPanel from "../Arbitrator/StaticPanel";
import Spinner from "../Common/Spinner";

function ShowRender({ profile }) {
  if (!profile) return <Spinner />;
  return (
    <div className="jur-arbitrator-profile-view-wrapper">
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <StaticPanel profile={profile} />
        </Col>
        <Col span={18}>
          <ProfileTabs profile={profile} />
        </Col>
      </Row>
    </div>
  );
}

export default ShowRender;
