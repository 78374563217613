import { Button, Col, List, Row, Space } from "antd";
import React from "react";
import Translation from "../Common/Translation";
import JurPointer from "../DataDisplay/JurPointer";
import "../Layouts/layouts.less";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";
import JurStepsNavigation from "../Navigation/JurStepsNavigation";
import OnboardingSidebar from "./OnboardingSidebar";
import ProfileUpdateForm from "./ProfileUpdateForm";

export default function OnboardingRender({ userName, currentStep, onNext, onBack, onUpdate, isOnboarding }) {
  const steps = {
    0: <Step1 />,
    1: <Step2 />,
    2: <ProfileUpdateForm userName={userName} onUpdate={onUpdate} />,
  };

  return (
    <LeftSidebarLayout
      navbar={true}
      sidebarContent={<OnboardingSidebar isOnboarding={isOnboarding} userName={userName} currentStep={currentStep} />}
      mainContent={
        <div className={`onboarding-grid-layout ${isOnboarding ? "layout-wrapper-without-navbar" : "layout-wrapper"}`}>
          <Row justify="space-around" align="middle">
            <Col span={17}>
              <>
                <div className="onboarding-content">{steps[currentStep]}</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ float: "left" }}>
                    {/* {currentStep === 2 ? (
                      <Anchor onClick={handleSkip}>
                        <Translation text="onboarding.fill_details_later.text" />
                      </Anchor>
                    ) : null} */}
                  </div>
                  <div
                    style={{
                      float: "right",
                    }}
                  >
                    <Space>
                      <JurStepsNavigation steps={3} currentStep={currentStep} onBack={onBack} />
                      <Button type="primary" size="small" onClick={onNext}>
                        <Translation text="general.next.button" />
                      </Button>
                    </Space>
                  </div>
                </div>
              </>
            </Col>
          </Row>
        </div>
      }
    />
  );
}

const Step1 = () => {
  const steps = [
    {
      text: <Translation text="onboarding.about_us_line_1.text" />,
    },
    {
      text: (
        <>
          <Translation text="onboarding.about_us_line_2.text" />
          <span className="jur-link">
            <Translation text="onboarding.digital_arbitration.text" />
          </span>
          .
        </>
      ),
    },
    {
      text: (
        <>
          <Translation text="onboarding.about_us_line_3.text" />
          <span className="jur-link">
            <Translation text="onboarding.notifications.text" />
          </span>
          .
        </>
      ),
    },
    {
      text: (
        <>
          <Translation text="onboarding.about_us_line_4.text" />
          <span className="jur-link">
            <Translation text="onboarding.audit_trial.text" />
          </span>
          .
        </>
      ),
    },
  ];
  return <OnboardingInformationStep title="onboarding.about_us.title" steps={steps} />;
};

const Step2 = () => {
  const steps = [1, 2, 3].map((item) => ({
    text: <Translation text={`onboarding.information_use_line_${item}.text`} />,
  }));
  return <OnboardingInformationStep title="onboarding.information_use.title" steps={steps} />;
};

const OnboardingInformationStep = ({ title, steps }) => (
  <div className="information-step">
    <div className="information-title">
      <Translation text={title} />
    </div>
    <List itemLayout="horizontal" dataSource={steps} renderItem={(item) => <JurPointer label={item.text} />} />
  </div>
);
