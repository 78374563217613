import { Tag, Typography } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { Formik } from "formik";
import React from "react";
import { AddressFields, toFormItem } from "../../helpers/formHelpers";
import { QuestionHelpText, QuestionTitle } from "../Common/FormQuestion";
import JurLink from "../Common/JurLink";
import Translation from "../Common/Translation";

export default function RespondentRender({
  errors,
  errorProps,
  onUpdateForm,
  respondent,
}) {
  const respondentType =
    respondent?.entityType === "Business" ? "business" : "individual";
  return (
    <Formik initialValues={respondent}>
      {({ values, setFieldValue }) => {
        const formItemProps = {
          values,
          setFieldValue,
          propogateValues: onUpdateForm,
          errors,
          errorProps,
        };
        return (
          <div className="jur-response-filing-content-wrapper">
            <Name
              {...{
                respondentType,
                name: [respondent?.firstName, respondent?.lastName]
                  .filter(Boolean)
                  .join(" "),
              }}
            />
            {email(formItemProps)}
            {address(formItemProps)}
          </div>
        );
      }}
    </Formik>
  );
}

const Name = ({ respondentType, name }) => (
  <div className="jur-response-filing-content-section">
    <QuestionTitle text="response_filing.respondent.title" />
    <QuestionHelpText>
      <Translation text="response_filing.respondent_is_registered.text" />{" "}
      <Translation text={`general.${respondentType}.text`} />{" "}
      <Translation text="response_filing.on_the_platform.text" />.{" "}
      <Translation text="response_filing.see_how_this_affects.text" />{" "}
      <JurLink>
        <Translation text="response_filing.dispute_fee.text" />
      </JurLink>
      {". "}
      <Translation text="response_filing.respondent_type.description" />{" "}
      <JurLink>support@jur.io</JurLink>
    </QuestionHelpText>
    <div className="jur-response-filing-respondent-name">
      <Typography.Text strong>{name}</Typography.Text>
      <Tag color={color(respondentType)}>
        <Translation text={`general.${respondentType}.text`} />
      </Tag>
    </div>
  </div>
);

const email = (formItemProps) => (
  <div className="jur-response-filing-content-section">
    {toFormItem(formItemProps)({
      name: "email",
      customLabel: <QuestionTitle text="general.email.text" />,
      helpText: (
        <Translation text="response_filing.respondent_email.description" />
      ),
      componentProps: { type: "email" },
    })}
  </div>
);

const address = (formItemProps) => (
  <div className="jur-response-filing-content-section">
    <FormItem
      name="address"
      required={true}
      label={<QuestionTitle text="claimant.mailing_address.text" />}
    >
      <QuestionHelpText>
        <Translation text="response_filing.respondent_address.description" />
      </QuestionHelpText>
      <AddressFields {...formItemProps} />
    </FormItem>
  </div>
);

function color(type) {
  return {
    business: "blue",
    individual: "purple",
  }[type];
}
