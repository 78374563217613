import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "hearings";
const catcher = logAndThrow(tag);

export function index(disputeId, page, flow) {
  return Api.get(`/disputes/${disputeId}/hearings?flow=${flow}&page=${page}`)
    .then((res) => res.data)
    .catch(catcher("index"));
}

export function show(disputeId, hearingId) {
  return Api.get(`/disputes/${disputeId}/hearings/${hearingId}`)
    .then((res) => res.data)
    .catch(catcher("show"));
}

export function create(disputeId, body) {
  return Api.post(`/disputes/${disputeId}/hearings`, body).catch(
    catcher("create", "Failed to schedule hearing")
  );
}

export function update(disputeId, hearingId, body) {
  return Api.put(`/disputes/${disputeId}/hearings/${hearingId}`, body).catch(
    catcher("update")
  );
}

export function indexMemorandum(disputeId, hearingId) {
  return Api.get(`/disputes/${disputeId}/hearings/${hearingId}/memorandums`)
    .then((res) => res.data)
    .catch(catcher("indexMemorandum"));
}

export function showMemorandum(disputeId, hearingId, memorandumId) {
  return Api.get(
    `/disputes/${disputeId}/hearings/${hearingId}/memorandums/${memorandumId}`
  )
    .then((res) => res.data)
    .catch(catcher("showMemorandum"));
}

export function updateMemorandum(disputeId, hearingId, body) {
  return Api.post(
    `/disputes/${disputeId}/hearings/${hearingId}/memorandums`,
    body
  )
    .then((res) => res.data)
    .catch(catcher("updateMemorandum"));
}

export function showMinutes(disputeId, hearingId) {
  return Api.get(`/disputes/${disputeId}/hearings/${hearingId}/minutes`)
    .catch((e) => {
      /** NOTE: Treating 404 as a success case.
       * Minutes is not yet uploaded hence 404  */
      if (e.isAxiosError && e.response.status === 404)
        return Promise.resolve({ data: { data: {} } });
      return Promise.reject(e);
    })
    .then((res) => res.data)
    .catch(catcher("showMinutes"));
}

export function postMinutes(disputeId, hearingId, body) {
  return Api.post(`/disputes/${disputeId}/hearings/${hearingId}/minutes`, body)
    .then((res) => res.data)
    .catch(catcher("putMinutes", "Failed to update hearing minutes"));
}
