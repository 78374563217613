import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import IndexHOC from ".";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { handleShow } from "../../redux/actions/disputeFilingActions";
import Spinner from "../Common/Spinner";

export default function () {
  const dispatch = useDispatch();
  const [disputeId, dispute] = useDispute();
  const { search } = useLocation();
  const step = getStep(dispute, new URLSearchParams(search).get("step"));

  useHubSlug();

  useEffect(() => void dispatch(handleShow(disputeId, step)), [
    disputeId,
    step,
    dispatch,
  ]);

  return dispute ? <IndexHOC initialStep={step} /> : <Spinner />;
}

function getStep(dispute, name) {
  if (name) {
    if (name === "details") return 1;
    if (name === "sign") return 5;
    if (name === "completed") return 6;
    return 0;
  }
  const step = dispute?.step;
  if (step) {
    if (step === "Claimant") return 0;
    if (step === "Claim Details") return 1;
    if (step === "Claim Files") return 2;
    if (step === "Respondent") return 3;
    if (step === "Invite") return 4;
    if (step === "Review") return 5;
    return 0;
  }
  return 0;
}
