import { Card, Table, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { readLoggedInUser } from "../../helpers/authHelpers";
import { DRAFT } from "../../redux/constants/DisputeStatuses";
import { fromMeta } from "../../transformers/paginationTransformer";
import Translation from "../Common/Translation";
import NoData from "../Common/NoData";
import JurContentWrapper from "../Layouts/JurContentWrapper";
import "./styles.less";
import { acceptedColumns, requestedColumns } from "./tableHelpers";
import UpdateProfileReminder from "./UpdateProfileReminder";

const { Title, Text } = Typography;

export default function ({
  accepted,
  requested,
  isAcceptedLoading,
  isRequestedLoading,
  isAcceptedEmpty,
  group,
  role,
  onPageChange,
}) {
  const isHubUser = group === "Hub User";

  const history = useHistory();
  const onRow = (e) => {
    return {
      onClick: () => {
        if (e.attributes.status === DRAFT)
          history.push(`/${e.attributes.hubUrl}/dispute-filing/${e.id}`);
        else history.push(`/${e.attributes.hubUrl}/disputes/${e.id}`);
      },
    };
  };

  const authUser = readLoggedInUser();
  const name = authUser.name;
  const isProfileUpdated = authUser.user.status === "Active";

  const NewClaims = () => (
    <Card className="jur-new-claims-card">
      <Title level={4}>
        <Translation text="dashboard.new_claims.title" />
      </Title>
      <Table
        className="jur-dashboard-table-wrapper"
        onRow={onRow}
        dataSource={requested?.data}
        columns={requestedColumns(role)}
        loading={isRequestedLoading}
        rowKey="id"
        pagination={fromMeta(requested?.meta, (page) => {
          onPageChange(page, "requested");
        })}
      />
    </Card>
  );

  const OngoingClaims = () => (
    <Card>
      <Title level={4}>
        <Translation text={title(group)} />
      </Title>
      {isAcceptedEmpty ? (
        <NoData
          isWrappedInCard={false}
          className="jur-dashboard-empty-state-wrapper"
          imageStyle={{
            height: 100,
            marginBottom: 16,
          }}
          description={
            <div>
              <Title level={5} className="h5-medium jur-dashboard-empty-state-heading">
                <Translation text={`dashboard.no_disputes${isHubUser ? "_assigned" : ""}.text`} />
              </Title>
              <Text className="jur-dashboard-empty-state-subheading">
                {isHubUser ? (
                  "You will receive a notification when your services are requested"
                ) : (
                  <>
                    Let’s <span className="jur-link">file a claim</span> or Browse{" "}
                    <span className="jur-link">Arbitration Rules</span>
                  </>
                )}
              </Text>
            </div>
          }
        />
      ) : (
        <Table
          className="jur-dashboard-table-wrapper"
          onRow={onRow}
          dataSource={accepted?.data}
          columns={acceptedColumns(role)}
          loading={isAcceptedLoading}
          rowKey="id"
          pagination={fromMeta(accepted?.meta, onPageChange)}
        />
      )}
    </Card>
  );

  return (
    <JurContentWrapper>
      {!isProfileUpdated ? <UpdateProfileReminder name={name} /> : null}
      {isHubUser && (requested?.data || []).length > 0 ? <NewClaims /> : null}
      <OngoingClaims />
    </JurContentWrapper>
  );
}

function title(group, hide) {
  if (hide) return null;
  return {
    "Hub User": "dashboard.hub_cases.title",
    General: "dashboard.my_disputes.title",
    Claimant: "dashboard.my_disputes.title",
    Respondent: "dashboard.my_disputes.title",
  }[group];
}
