import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { uploadUrlsFor } from "../../redux/actions/fileActions";
import * as actions from "../../redux/actions/responseFilingActions";
import { uploadInfoOf } from "../../redux/selectors/filesSelector";
import * as selectors from "../../redux/selectors/responseFilingSelector";
import Spinner from "../Common/Spinner";
import DocumentsRender from "./DocumentsRender";

const prefix = "responseFiling.responseFiles.";
const fileCategory = "Response Attachment";
const fileNames = [prefix + fileCategory];

export default function () {
  const { disputeId } = useParams();
  const files = useSelector(selectors.selectResponseFiles);
  const uploadInfo = useSelector(uploadInfoOf(fileNames));
  const dispatch = useDispatch();
  const errors = useSelector(selectors.selectErrors);
  const isErrorHidden = useSelector(selectors.selectIsErrorHidden);

  useEffect(() => {
    dispatch(uploadUrlsFor(fileNames));
    dispatch(actions.getShowResponseFiles(disputeId));
    window.scrollTo(0, 0);
  }, [disputeId, dispatch]);

  const handleUpdateForm = (values) =>
    dispatch(actions.handleUpdateResponseFilesForm(values));

  if (!uploadInfo) return <Spinner card={true} />;
  const itemInfo = uploadInfo[fileNames[0]];
  if (!itemInfo) return <Spinner card={true} />;

  itemInfo.fileCategory = fileCategory;

  return files ? (
    <DocumentsRender
      files={files}
      itemInfo={itemInfo}
      onUpdateForm={handleUpdateForm}
      errors={errors}
      errorProps={{ isHidden: isErrorHidden }}
    />
  ) : (
    <Spinner card={true} />
  );
}
