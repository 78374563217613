import * as types from "../constants/constants";

export const getCurrentUser = () => ({
  type: types.AUTH_GET_CURRENT_USER,
});

export const handleCurrentUser = (user) => ({
  type: types.AUTH_HANDLE_CURRENT_USER,
  payload: user,
});

export const postActivate = (payload) => ({
  type: types.AUTH_POST_ACTIVATE,
  payload,
});

export const postAcceptInvite = (payload) => ({
  type: types.AUTH_POST_ACCEPT_INVITE,
  payload,
});

export const postResendRegister = (payload) => ({
  type: types.AUTH_POST_RESEND_REGISTER,
  payload,
});

export const handleActivate = () => ({ type: types.AUTH_HANDLE_ACTIVATE });
export const handleError = (e) => ({ type: types.AUTH_HANDLE_ERROR, error: e });

export const authenticateUser = (payload) => {
  return {
    type: types.AUTHENTICATE_USER,
    payload: payload,
  };
};

export const authenticateUserSucceeded = (payload) => {
  return {
    type: types.AUTHENTICATE_USER_SUCCEEDED,
    payload: payload,
  };
};

export const authenticateUserFailed = (error) => {
  return {
    type: types.AUTHENTICATE_USER_FAILED,
    error: error,
  };
};

export const logoutUser = ({ isRedirectRequired = true }) => {
  return {
    type: types.LOGOUT_USER,
    payload: { isRedirectRequired },
  };
};

export const logoutUserSucceeded = () => {
  return {
    type: types.LOGOUT_USER_SUCCEEDED,
  };
};

export const logoutUserFailed = (error) => {
  return {
    type: types.LOGOUT_USER_FAILED,
  };
};

export const forgotPassword = (payload) => {
  return {
    type: types.FORGOT_PASSWORD,
    payload: payload,
  };
};

export const forgotPasswordSucceeded = (payload) => {
  return {
    type: types.FORGOT_PASSWORD_SUCCEEDED,
    payload: payload,
  };
};

export const forgotPasswordFailed = (error) => {
  return {
    type: types.FORGOT_PASSWORD_FAILED,
    error: error,
  };
};

export const registerUser = (payload) => {
  return {
    type: types.REGISTER_USER,
    payload: payload,
  };
};

export const registerUserSucceeded = () => {
  return {
    type: types.REGISTER_USER_SUCCEEDED,
  };
};

export const registerUserFailed = (error) => {
  return {
    type: types.REGISTER_USER_FAILED,
    error: error,
  };
};

export const resetPassword = (payload) => {
  return {
    type: types.RESET_PASSWORD,
    payload: payload,
  };
};

export const resetPasswordSucceeded = (payload) => {
  return {
    type: types.RESET_PASSWORD_SUCCEEDED,
  };
};

export const resetPasswordFailed = (error) => {
  return {
    type: types.RESET_PASSWORD_FAILED,
    error: error,
  };
};
