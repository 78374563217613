import React from "react";

function Footnote({ children, strong = false, className = "" }) {
  return (
    <div className={`${className} footnote ${strong ? "footnote-medium" : ""}`}>
      {children}
    </div>
  );
}

export default Footnote;
