import { Tag } from "antd";
import React from "react";
import {
  ARBITRATION_CLOSED_BY_ARBITRATOR,
  ARBITRATION_CLOSED_BY_HUB,
  ARBITRATOR_ASSIGNMENT_PENDING,
  AWAITING_ARBITRATOR_ACCEPTANCE,
  AWARD_ISSUED,
  DRAFT,
  HUB_APPROVAL_PENDING,
  HUB_REJECTED,
  ONGOING,
} from "../../redux/constants/DisputeStatuses";

export default function ({ status }) {
  return <Tag color={color(status)}>{status}</Tag>;
}

function color(status) {
  return {
    [DRAFT]: "yellow",
    [HUB_APPROVAL_PENDING]: "yellow",
    [HUB_REJECTED]: "red",
    [ARBITRATOR_ASSIGNMENT_PENDING]: "yellow",
    [AWAITING_ARBITRATOR_ACCEPTANCE]: "yellow",
    [ONGOING]: "green",
    [AWARD_ISSUED]: "green",
    [ARBITRATION_CLOSED_BY_ARBITRATOR]: "blue",
    [ARBITRATION_CLOSED_BY_HUB]: "blue",
  }[status];
}

export function disputeStatusColor(status) {
  return color(status);
}
