import * as types from "../constants/constants";

export const getIndex = () => ({ type: types.HUB_GET_INDEX });

export const getArbitrators = (hub) => ({
  type: types.HUB_GET_ARBITRATORS,
  payload: hub,
});

export const handleArbitrators = (arbitrators) => ({
  type: types.HUB_HANDLE_ARBITRATORS,
  payload: arbitrators,
});

export const getRosterIndex = (page = 1) => ({
  type: types.HUB_GET_ROSTER_INDEX,
  page,
});

export const handleRosterIndex = (data) => ({
  type: types.HUB_HANDLE_ROSTER_INDEX,
  payload: data,
});

export const getRosterProfile = (profileId) => ({
  type: types.HUB_GET_ROSTER_PROFILE,
  payload: { profileId },
});

export const handleRosterProfile = (data) => ({
  type: types.HUB_HANDLE_ROSTER_PROFILE,
  payload: data,
});

export const putUpdateRosterProfile = (profileId, body, resetForm) => ({
  type: types.HUB_PUT_UPDATE_ROSTER_PROFILE,
  payload: { profileId, body, resetForm },
});

export const handleUpdateRosterProfile = (data) => ({
  type: types.HUB_HANDLE_UPDATE_ROSTER_PROFILE,
  payload: data,
});

export const postInviteArbitrator = (body) => ({
  type: types.HUB_POST_INVITE_ARBITRATOR,
  payload: body,
});

export const handleInviteArbitrator = (data) => ({
  type: types.HUB_HANDLE_INVITE_ARBITRATOR,
  payload: data,
});

export const handleUpdate = (hub) => ({
  type: types.HUB_HANDLE_UPDATE,
  payload: hub,
});

export const handleUpdateSlug = (slug) => ({
  type: types.HUB_HANDLE_UPDATE,
  payload: { attributes: { url: slug } },
});

export const handleIndex = (hubs) => ({
  type: types.HUB_HANDLE_INDEX,
  payload: hubs,
});

export const handleProfile = (hubProfile) => ({
  type: types.HUB_HANDLE_PROFILE,
  payload: hubProfile,
});

export const handleError = (e) => ({
  type: types.HUB_HANDLE_ERROR,
  error: e,
});
