import { Select } from "formik-antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getIndex } from "../redux/actions/disputeUserActions";
import {
  indexRequestPayload,
  selectParticipants,
} from "../redux/selectors/disputeUsersSelector";

export function useParticipants() {
  const { disputeId } = useParams();
  const { disputeId: loadedDisputeId } = useSelector(indexRequestPayload);
  const participants = useSelector(selectParticipants);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Boolean(disputeId) && disputeId !== loadedDisputeId)
      dispatch(getIndex(disputeId));
  }, [dispatch, disputeId, loadedDisputeId]);

  if (!disputeId) console.warn("Trying to useParticipants on non-dipute page");

  return [participants, loadedDisputeId];
}

export function toSelectOptions(participants) {
  return participants?.map((p) => (
    <Select.Option key={p.userId} value={p.userId}>
      {p.fullName} ({p.userGroup})
    </Select.Option>
  ));
}

export function toScheduleParticipants(participants) {
  return participants
    ?.filter((p) => p.isInvited)
    ?.map((a) => ({ name: a.fullName, ...a }));
}
