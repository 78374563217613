import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {
  evidenceRequests: [],
  evidenceRequest: null,
  isLoading: false,
  isFetchingTor: false,
  isRequestDocumentVisible: false,
  isSendDocumentsVisible: false,
};

export default function (state = initial, action) {
  const payload = action.payload;
  switch (action.type) {
    case types.EVIDENCEREQUEST_GET_INDEX:
      return { ...state, isFetchingTor: true };
    case types.EVIDENCEREQUEST_GET_SHOW:
      return { ...state, evidenceRequest: null };
    case types.EVIDENCEREQUEST_HANDLE_INDEX:
      return {
        ...state,
        evidenceRequests: payload?.data,
        evidenceRequestsMeta: payload?.meta,
        isFetchingTor: false,
      };
    case types.EVIDENCEREQUEST_HANDLE_ERROR:
      return {
        ...state,
        error: errorMessages(action.error),
        isLoading: false,
        isFetchingTor: false,
      };
    case types.EVIDENCEREQUEST_HANDLE_SHOW:
      return {
        ...state,
        evidenceRequest: payload?.data,
      };
    case types.EVIDENCEREQUEST_POST_CREATE:
      return { ...state, isLoading: true };
    case types.EVIDENCEREQUEST_HANDLE_CREATE:
      return {
        ...state,
        isSendDocumentsVisible: false,
        isRequestDocumentVisible: false,
        isLoading: false,
      };
    case types.EVIDENCEREQUEST_PUT_UPDATE:
      return { ...state, isLoading: true };
    case types.EVIDENCEREQUEST_HANDLE_UPDATE:
      return {
        ...state,
        isSendDocumentsVisible: false,
        isRequestDocumentVisible: false,
        isLoading: false,
      };
    case types.EVIDENCEREQUEST_HANDLE_IS_REQUEST_DOCUMENT_OPEN:
      return { ...state, isRequestDocumentVisible: payload };
    case types.EVIDENCEREQUEST_HANDLE_IS_SEND_DOCUMENTS_OPEN:
      return { ...state, isSendDocumentsVisible: payload };
    default:
      return state;
  }
}
