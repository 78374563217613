import { Button, Col, List, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { handleStartDisputeFiling } from "../../redux/actions/disputeFilingActions";
import Translation from "../Common/Translation";
import JurPointer from "../DataDisplay/JurPointer";
import CenteredContent from "../Layouts/CenteredContent";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";
import JurStepsNavigation from "../Navigation/JurStepsNavigation";

export default function ClaimFilingIntro() {
  return (
    <div>
      <LeftSidebarLayout
        sidebarContent={<SidebarContent />}
        mainContent={<MainContent />}
      />
    </div>
  );
}

function MainContent() {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const steps = {
    0: <Step1 />,
    1: <Step2 />,
    2: <Step3 />,
  };

  const IntroContent = ({ currentStep }) => {
    return <div className="onboarding-content">{steps[currentStep]}</div>;
  };

  const IntroNavigation = ({ currentStep }) => {
    return (
      <Row justify="end">
        <Col>
          <Space>
            <JurStepsNavigation
              steps={3}
              currentStep={currentStep}
              onBack={() => {
                setCurrentStep(Math.max(currentStep - 1, 0));
              }}
            />
            <Button
              type="primary"
              size="small"
              onClick={() => {
                if (currentStep === 0 || currentStep === 1)
                  setCurrentStep(currentStep + 1);
                if (currentStep === 2) {
                  void dispatch(handleStartDisputeFiling());
                }
              }}
            >
              <Translation
                text={`general.${
                  currentStep === 2 ? "lets_start" : "next"
                }.button`}
              />
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  return (
    <CenteredContent>
      <IntroContent currentStep={currentStep} />
      <IntroNavigation currentStep={currentStep} />
    </CenteredContent>
  );
}

const Step1 = () => {
  const steps = [1, 2, 3, 4].map((item) => ({
    text: (
      <Translation
        text={`claim_filing.introduction_what_do_you_need_step_${item}.text`}
      />
    ),
  }));

  return <IntroStep step={1} steps={steps} />;
};

const Step2 = () => {
  const steps = [
    {
      text:
        "Jur will use the information to understand and assign a relevant arbitrator to your case",
    },
    {
      text:
        "Jur will also contact the counter party and share the claim with them",
    },
    {
      text: (
        <>
          Electronic signatures are required to make the request legally binding
          in the United States. You can read more about{" "}
          <span className="jur-link">ESIGN</span>
        </>
      ),
    },

    {
      text: (
        <>
          The process relies on the{" "}
          <span className="jur-link">Jur Arbitration Rules</span> for a seamless{" "}
          <span className="jur-link">claim filing</span>
        </>
      ),
    },
  ];
  return <IntroStep step={2} steps={steps} />;
};

const Step3 = () => {
  const steps = [
    {
      text: (
        <>
          You can read more about Jur’s data confidentiality and privacy{" "}
          <span
            onClick={() => {
              window.open("https://jur.io", "_blank");
            }}
            className="jur-link"
          >
            here
          </span>
        </>
      ),
    },
  ];
  return <IntroStep step={3} steps={steps} />;
};

const IntroStep = ({ step, steps }) => {
  return (
    <div className="information-step">
      <div className="information-title">
        <Translation text={`claim_filing.introduction_step_${step}.title`} />
      </div>
      <List
        itemLayout="horizontal"
        dataSource={steps}
        renderItem={(item) => <JurPointer label={item.text} />}
      />
    </div>
  );
};

const SidebarContent = () => (
  <>
    <Typography.Title level={1}>
      We will now begin drafting the request for arbitration
    </Typography.Title>
    <Typography.Title level={5}>
      This process should take around thirty minutes. The platform saves every
      page submitted. You can come back and complete a page anytime
    </Typography.Title>
  </>
);
