import { Button, List } from "antd";
import React from "react";
import Sidebar from "../Common/Sidebar";
import Translation from "../Common/Translation";
import JurPointer from "../DataDisplay/JurPointer";
import SignatureButton from "../Signs/SignatureButton";

export default function ShowSidebar({
  currentStep,
  disputeId,
  hubSlug,
  onSodClick,
}) {
  const sidebarSteps = steps({ disputeId, hubSlug, onSodClick });

  return (
    <Sidebar
      title={
        <Translation
          text={`response_filing.${sidebarSteps[currentStep].title}.title`}
        />
      }
      description={sidebarSteps[currentStep].description}
      content={sidebarSteps[currentStep].content}
    />
  );
}

const documentInformationSteps = [1, 2, 3, 4].map((i) => (
  <Translation text={`response_filing.document_information_step_${i}.text`} />
));

const steps = ({ hubSlug, disputeId, onSodClick }) => {
  return {
    0: {
      title: "what_we_know_about_the_respondent_so_far",
      description: (
        <Translation text="response_filing.what_we_know_about_the_respondent_so_far.description" />
      ),
    },
    1: {
      title: "lets_prepare_the_response",
      description: (
        <Translation text="response_filing.lets_prepare_the_response.description" />
      ),
    },
    2: {
      title: "what_are_the_documents_supporting_your_response",
      description: (
        <Translation text="response_filing.what_are_the_documents_supporting_your_response.description" />
      ),
      content: (
        <List
          itemLayout="horizontal"
          dataSource={documentInformationSteps}
          renderItem={(item) => <JurPointer label={item} />}
        />
      ),
    },
    3: {
      title: "invite_your_team_to_represent_the_respondent",
      description: (
        <Translation text="response_filing.invite_your_team_to_represent_the_respondent.description" />
      ),
    },
    4: {
      title: "please_sign_the_request_for_arbitration",
      description: (
        <Translation text="response_filing.please_sign_the_request_for_arbitration.description" />
      ),
      content: (
        <SignatureButton
          redirectUrl={`/${hubSlug}/response-filing/${disputeId}?step=sign`}
        >
          <Button type="primary" size="large" onClick={onSodClick}>
            <Translation text="general.sign_now.button" pad={true} />
          </Button>
        </SignatureButton>
      ),
    },
  };
};
