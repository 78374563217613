import * as types from "../constants/constants";

const initial = {
  accepted: [],
  requested: [],
  dispute: null,
  isShowLoading: true,
  isAcceptedLoading: true,
  isRequestedLoading: true,
  isOpen: false,
};

export default function (state = initial, action) {
  switch (action.type) {
    case types.DISPUTE_HANDLE_IS_OPEN:
      return { ...state, isOpen: action.payload };
    case types.DISPUTE_HANDLE_UPDATE_MOTIVATION:
      return { ...state, motivation: action.payload };
    case types.DISPUTE_GET_INDEX:
      return { ...state, isAcceptedLoading: true };
    case types.DISPUTE_GET_INDEX_REQUESTED:
      return { ...state, isRequestedLoading: true };
    case types.DISPUTE_HANDLE_INDEX:
      return { ...state, isAcceptedLoading: false, accepted: action.payload };
    case types.DISPUTE_HANDLE_INDEX_REQUESTED:
      return { ...state, isRequestedLoading: false, requested: action.payload };
    case types.DISPUTE_GET_SHOW:
      return {
        ...state,
        isShowLoading: true,
        dispute: null,
        disputeMeta: null,
      };
    case types.DISPUTE_HANDLE_SHOW:
      return {
        ...state,
        dispute: action.payload.data?.attributes,
        disputeMeta: action.payload.meta,
        isShowLoading: false,
      };
    case types.DISPUTE_HANDLE_ERROR:
      return { ...state, error: action.error, isShowLoading: false };
    default:
      return state;
  }
}
