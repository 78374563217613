import * as DisputeStatuses from "../redux/constants/DisputeStatuses";

export const toDisputeUpdateBody = ({ status, to, rejectReason }) => {
  if (status === DisputeStatuses.HUB_APPROVAL_PENDING) {
    return {
      disputeStatus:
        to === "Accept"
          ? DisputeStatuses.HUB_ACCEPTED
          : DisputeStatuses.HUB_REJECTED,
      rejectReason,
    };
  }
};
