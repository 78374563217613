import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import * as actions from "../../redux/actions/disputeAssignmentActions";
import * as fileActions from "../../redux/actions/fileActions";
import { getShow } from "../../redux/actions/fileActions";
import { postCreateSignature } from "../../redux/actions/signActions";
import { AWAITING_ARBITRATOR_ACCEPTANCE } from "../../redux/constants/DisputeStatuses";
import {
  current,
  meta,
  whole,
} from "../../redux/selectors/disputeAssignmentSelectors";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import AcceptRender from "./AcceptRender";

export default function () {
  const history = useHistory();
  const hubSlug = useHubSlug();
  const [disputeId, dispute] = useDispute();
  const [signable, setSignable] = useState({});
  const disputeAssignment = useSelector(current);
  const disputeAssignmentMeta = useSelector(meta);
  const { isLoading } = useSelector(whole);
  const dispatch = useDispatch();
  const statementOfAcceptance =
    disputeAssignment?.attributes?.statementOfAcceptance;
  const appointmentDocument =
    disputeAssignment?.attributes?.appointmentDocument;
  const currentStep =
    statementOfAcceptance?.signatureStatus !== "Signature Completed"
      ? 0
      : appointmentDocument?.signatureStatus !== "Signature Completed"
      ? 1
      : 1;

  useEffect(() => void dispatch(actions.getShow(disputeId, "latest")), [
    dispatch,
    disputeId,
  ]);

  useEffect(() => {
    if (dispute && dispute?.status !== AWAITING_ARBITRATOR_ACCEPTANCE) {
      history.push(`/${hubSlug}/disputes/${disputeId}/`);
    }
  }, [dispute, disputeId, hubSlug, history]);

  const download = (fileName) =>
    dispatch(getShow(disputeAssignment.attributes[fileName].id));

  const handleSubmit = (values) =>
    void dispatch(
      actions.putUpdate(disputeId, "accept", { ...values, accepted: true })
    );

  const addToSign = (file) => {
    const files = { ...signable, [file.fileCategory]: file };
    setSignable(files);
    dispatch(actions.putUpdateFile(disputeId, file.fileCategory, file.id));
  };

  const startSign = (name) => {
    if (disputeAssignment?.attributes[name]?.redirectUrl) {
      window.location = disputeAssignment?.attributes[name]?.redirectUrl;
    } else {
      const fileCategory = {
        appointmentDocument: "Appointment Document",
        statementOfAcceptance: "Statement Of Acceptance",
      }[name];

      void dispatch(
        postCreateSignature({
          fileIds: [disputeAssignment?.attributes[name]?.id],
          flow: fileCategory,
          signType: "Self Sign",
          envelopeRedirectUrl: `/${hubSlug}/disputes/${disputeId}/dispute-assignments/accept`,
          uiRedirectUrl: `/${hubSlug}/disputes/${disputeId}/dispute-assignments/accept`,
          disputeId,
          hubSlug,
          signableId: disputeAssignment?.id,
        })
      );
    }
  };

  const deleteFile = (id) => {
    dispatch(fileActions.deleteFile(id));
  };

  return isLoading || !dispute ? (
    <Spinner card />
  ) : (
    <AcceptRender
      hubSlug={hubSlug}
      dispute={dispute}
      disputeId={disputeId}
      currentStep={currentStep}
      onSubmit={handleSubmit}
      addToSign={addToSign}
      statementOfAcceptance={statementOfAcceptance?.id && statementOfAcceptance}
      appointmentDocument={appointmentDocument?.id && appointmentDocument}
      isSubmissionDisabled={
        disputeAssignment?.attributes?.status === "Requested"
      }
      download={download}
      onSign={startSign}
      meta={disputeAssignmentMeta}
      hubTOS={disputeAssignment?.attributes?.hubTermsOfService?.id}
      url={`/${hubSlug}/disputes/${disputeId}/dispute-assignments/accept`}
      extraCrumbs={extraCrumbs(hubSlug, disputeId)}
      deleteFile={deleteFile}
    />
  );
}

const extraCrumbs = (hubSlug, disputeId) => [
  {
    path: `/${hubSlug}/disputes/${disputeId}/dispute-assignments/accept`,
    breadcrumbName: <Translation text="arbitrator.accept_appointment.button" />,
  },
];
