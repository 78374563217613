import { logAndThrow } from "../../helpers/apiHelpers";
import { hubless } from "./Api";

const tag = "arbitrator";
const catcher = logAndThrow(tag);

export function index() {
  return hubless
    .get(`arbitrators?perPage=-1`)
    .then((res) => res.data)
    .catch(catcher("index"));
}

export function show(userId) {
  return hubless
    .get(`arbitrators/${userId}`)
    .then((res) => res.data)
    .catch(catcher("index"));
}
