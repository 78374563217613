import { Button, Modal, Table, Typography } from "antd";
import { Formik } from "formik";
import { DatePicker, Form, FormItem } from "formik-antd";
import React from "react";
import { datePickerProps } from "../../helpers/dateHelpers";
import { modalProps } from "../../helpers/modalHelpers";
import Translation from "../Common/Translation";
import ModalWrapper from "../Common/ModalWrapper";
import UserRole from "../Common/UserRole";

export default function SendToPartiesForm({
  tors = [],
  selectedTOR,
  isSendToPartiesVisible,
  handleSendToParties,
  closeSendToParties,
  participants,
  isLoading,
}) {
  const columns = [
    { title: "Name", dataIndex: "fullName", key: "fullName" },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (_, record) => <UserRole role={record?.role} group={record?.userGroup} />,
    },
  ];

  return (
    <Formik
      initialValues={{
        torId: selectedTOR.id,
        deadline: undefined,
        userIds: [],
        torFileId: tors.length === 1 ? tors[0].attributes?.file?.id : null,
      }}
    >
      {({ values, resetForm, setFieldValue }) => {
        const disableSend = !(
          Boolean(values.deadline) &&
          Boolean(values.torId) &&
          values.userIds.length
        );

        const handleSubmit = () => {
          const tor = tors.find((t) => t.id === values.torId);
          values.torFileId = tor?.attributes?.file?.id;
          handleSendToParties(values, resetForm);
        };
        const rowSelection = {
          columnWidth: "110px",
          columnTitle: "Invite?",
          hideSelectAll: true,
          onChange: (selectedRowKeys, selectedRows) => {
            setFieldValue("userIds", selectedRowKeys);
          },
        };

        return (
          <Modal
            width={768}
            destroyOnClose={true}
            {...modalProps()}
            maskClosable={false}
            visible={isSendToPartiesVisible}
            onCancel={() => {
              closeSendToParties();
              resetForm();
            }}
            title={
              <Typography.Title level={5}>
                <Translation text="terms_of_reference.send_terms_of_reference_to_parties.title" /> (
                {selectedTOR?.attributes?.file?.name})
              </Typography.Title>
            }
            footer={[
              <Button
                loading={isLoading}
                type="primary"
                onClick={handleSubmit}
                disabled={disableSend || isLoading}
              >
                <Translation text="terms_of_reference.send.button" pad={true} />
              </Button>,
            ]}
          >
            <ModalWrapper>
              <Form layout="vertical">
                <FormItem
                  label={
                    <Typography.Text>
                      <Translation text="terms_of_reference.people_who_should_select_the_tor.text" />
                    </Typography.Text>
                  }
                  name="userIds"
                >
                  <Table
                    name="userIds"
                    columns={columns}
                    rowSelection={rowSelection}
                    rowKey="userId"
                    dataSource={participants}
                  />
                </FormItem>
                <FormItem
                  name="deadline"
                  label={
                    <Translation text="terms_of_reference.deadline_for_signing_the_file_for_parties.text" />
                  }
                >
                  <DatePicker
                    {...datePickerProps({ showTime: true })}
                    name="deadline"
                    className="jur-width-full"
                  />
                </FormItem>
              </Form>
            </ModalWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
}
