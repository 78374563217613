import { Button, Card, Col, Popconfirm, Row, Space } from "antd";
import React from "react";
import CompletedStep from "../Common/CompletedStep";
import Section from "../Common/Section";
import Translation from "../Common/Translation";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";
import SignatureButton from "../Signs/SignatureButton";
import Claimant from "./ClaimantHOC";
import Claim from "./ClaimHOC";
import Documents from "./DocumentsHOC";
import DisputeFilingSteps from "./FilingSteps";
import Invite from "./InviteHOC";
import Respondent from "./RespondentHOC";
import RoleIdentifier from "./RoleIdentifier";
import Sidebar from "./Sidebar";
import Soc from "./SocHOC";
import "./styles.less";

export default function ({
  isNextDisabled,
  currentStep = 0,
  goBack,
  onSaveAndBack,
  onDiscardAndBack,
  onSaveDraft,
  next,
  onClickDashboard,
  disputeId,
  hubSlug,
  onUpdateUserRole,
  onSocClick,
  onCurrentStepChange,
  claimant,
}) {
  const BottomNavigation = () => {
    return (
      <div style={{ paddingBottom: 16 }}>
        <NavigationDivider />
        <Row align="middle" justify="space-between">
          <Col>
            {[!disputeId ? 0 : null, 1, 2, 3, 4]
              .filter(Boolean)
              .includes(currentStep) ? (
              <Popconfirm
                title={
                  <Translation text="general.do_you_want_to_save_your_changes.action" />
                }
                onConfirm={onSaveAndBack}
                onCancel={onDiscardAndBack}
                okText={<Translation text="general.save_and_back.action" />}
                cancelText={
                  <Translation text="general.discard_and_back.action" />
                }
                // disabled={currentStep === 0 || isNextDisabled}
              >
                <Button
                  type="link"
                  size="small"
                  disabled={currentStep === 0 || isNextDisabled}
                >
                  <Translation text="general.go_back.button" />
                </Button>
              </Popconfirm>
            ) : null}
          </Col>
          <Col>
            <Space>
              {currentStep < 5 ? (
                <Button
                  loading={currentStep === 0 ? undefined : isNextDisabled}
                  disabled={isNextDisabled}
                  onClick={onSaveDraft}
                >
                  <Translation text="general.save_draft.button" pad={true} />
                </Button>
              ) : null}
              {currentStep < 5 ? (
                <Button
                  type="primary"
                  loading={currentStep === 0 ? undefined : isNextDisabled}
                  disabled={isNextDisabled}
                  onClick={next}
                >
                  <Translation text="general.next.button" pad={true} />
                </Button>
              ) : null}
              {currentStep === 5 ? (
                <SignatureButton
                  redirectUrl={`/${hubSlug}/dispute-filing/${disputeId}?step=sign`}
                >
                  <Button
                    type="primary"
                    // loading={isNextDisabled}
                    onClick={next}
                  >
                    <Translation text="general.sign_now.button" pad={true} />
                  </Button>
                </SignatureButton>
              ) : null}
              {currentStep === 5 ? (
                <Button
                  type="primary"
                  disabled={isNextDisabled}
                  onClick={onClickDashboard}
                >
                  <Translation text="general.go_to_dashboard.button" />
                </Button>
              ) : null}
            </Space>
          </Col>
        </Row>
      </div>
    );
  };

  if (currentStep === 6) {
    return (
      <CompletedStep
        current={2}
        steps={completedPageSteps}
        disputeId={disputeId}
      />
    );
  }

  return (
    <LeftSidebarLayout
      sidebarContent={
        <Sidebar
          onSocClick={onSocClick}
          userRole={Boolean(disputeId)}
          currentStep={currentStep}
          hubSlug={hubSlug}
          disputeId={disputeId}
          claimant={claimant}
        />
      }
      navbar={true}
      mainContent={
        disputeId ? (
          <>
            <DisputeFilingSteps
              onCurrentStepChange={onCurrentStepChange}
              currentStep={currentStep}
              steps={steps}
            />
            <DisputeFilingContentWrapper currentStep={currentStep}>
              <Section>{steps[currentStep]?.content}</Section>
              {currentStep < 5 && <BottomNavigation />}
            </DisputeFilingContentWrapper>
          </>
        ) : (
          <RoleIdentifier onUpdateUserRole={onUpdateUserRole} />
        )
      }
    />
  );
}

const DisputeFilingContentWrapper = ({ children, currentStep }) => {
  if (currentStep === 5)
    return (
      <div className="jur-dispute-filing-signature-wrapper">{children}</div>
    );
  return (
    <div className="jur-dispute-filing-content">
      <Card>
        <Row justify="space-around" align="middle">
          <Col span={15}>{children}</Col>
        </Row>
      </Card>
    </div>
  );
};

const NavigationDivider = () => <div className="jur-dispute-filing-divider" />;

const completedPageSteps = [
  {
    title: <Translation text="claim_filing.processing.title" />,
    description: (
      <Translation text="claim_filing.request_recieved.description" />
    ),
  },
  {
    title: <Translation text="general.payment.title" />,
    description: (
      <Translation text="claim_filing.invoice_will_be_sent.description" />
    ),
  },
  {
    title: <Translation text="claim_filing.connect.title" />,
    description: (
      <Translation text="claim_filing.schedule_a_call.description" />
    ),
  },
];

const steps = [
  // {
  //   title: <Translation text="general.hub.title" />,
  //   content: <Hub />,
  // },
  {
    title: <Translation text="general.claimant.title" />,
    content: <Claimant />,
  },
  {
    title: <Translation text="general.claim_information.title" />,
    content: <Claim />,
  },
  {
    title: <Translation text="general.documents.title" />,
    content: <Documents />,
  },
  {
    title: <Translation text="general.respondent.title" />,
    content: <Respondent />,
  },
  {
    title: <Translation text="general.invite.title" />,
    content: <Invite />,
  },
  {
    title: <Translation text="general.review.title" />,
    content: <Soc />,
  },
  // {
  //   title: <Translation text="general.done.title" />,
  //   content: <Finish />,
  // },
];
