import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "dispute-assignments";
const catcher = logAndThrow(tag);

export function index(disputeId) {
  return Api.get(`/disputes/${disputeId}/dispute-assignments`).catch(
    catcher("index")
  );
}

export function indexExperts() {
  return Api.get(`/arbitrators?flow="Arbitrator Assignment"`)
    .then((res) => res.data)
    .catch(catcher("indexExperts"));
}

export function indexArbitrators() {
  return Api.get(`/hub/arbitrators?flow=Arbitrator Assignment`)
    .then((res) => res.data)
    .catch(catcher("indexArbitrators"));
}

export function show(disputeId, disputeAssignmentId) {
  return Api.get(
    `/disputes/${disputeId}/dispute-assignments/${disputeAssignmentId}`
  )
    .then((res) => res.data)
    .catch(catcher("show"));
}

export function showDirect(disputeAssignmentId) {
  return Api.get(`/dispute-assignments/${disputeAssignmentId}`).catch(
    catcher("showDirect")
  );
}

export function create(disputeId, body) {
  return Api.post(`/disputes/${disputeId}/dispute-assignments`, body).catch(
    catcher("create", "Failed to assign")
  );
}

export function update(disputeId, disputeAssignmentId, body) {
  return Api.put(
    `/disputes/${disputeId}/dispute-assignments/${disputeAssignmentId}`,
    body
  ).catch(catcher("update", "Failed to submit"));
}

export function updateFile(disputeId, disputeAssignmentId, body) {
  return Api.put(
    `/disputes/${disputeId}/dispute-assignments/${disputeAssignmentId}/file`,
    body
  ).catch(catcher("update", "Failed to submit"));
}

export function updateDirect(disputeAssignmentId, body) {
  return Api.put(`/dispute-assignments/${disputeAssignmentId}`, body).catch(
    catcher("updateDirect")
  );
}
