import { Button, Divider, Form, Select, Space } from "antd";
import React from "react";
import Translation from "../Common/Translation";
import ProfileRender from "../Disputes/ProfileRender";

export default function ({
  labelPrefix,
  selected,
  results,
  onSelect,
  onSubmit,
  isSubmitting,
}) {
  return (
    <>
      <Form layout="vertical">
        <Form.Item
          name="assignTo"
          label={
            <Translation
              text={`dispute_assignment.select_${labelPrefix}.text`}
            />
          }
        >
          <Select onSelect={onSelect} showSearch={true}>
            {optionsFromResults(results)}
          </Select>
        </Form.Item>
      </Form>
      <Divider />
      <Space direction="vertical">
        {selected ? <ProfileRender user={selected} /> : null}
        <Button
          onClick={onSubmit}
          type="primary"
          disabled={!selected || isSubmitting}
          loading={isSubmitting}
        >
          <Translation text="dispute_assignment.assign.button" />
        </Button>
      </Space>
    </>
  );
}

function optionsFromResults(results) {
  return results.map((r) => (
    <Select.Option key={r.id} value={r.id}>
      {r?.attributes?.name}
    </Select.Option>
  ));
}
