export const dateFormat = "DD/MM/YYYY";
export const dateTimeFormat = "DD/MM/YYYY HH:mm";
const miliSecondDay = 24 * 60 * 60 * 1000;

export function noPast(current) {
  return current && current < new Date() - miliSecondDay;
}

export function noFuture(current) {
  return current && current > new Date();
}

export function datePickerProps({ showTime = false }) {
  return {
    format: showTime ? dateTimeFormat : dateFormat,
    disabledDate: noPast,
    showTime: showTime ? { format: "HH:mm" } : undefined,
  };
}

export const toEpoch = (date) => new Date(date).getTime() / 1000;
