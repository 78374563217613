import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React from "react";
import { toEditorFormItem } from "../../helpers/formHelpers";
import { QuestionTitle } from "../Common/FormQuestion";
import Translation from "../Common/Translation";
import Uploader from "../Common/Uploader";
import "./styles.less";
import AsteriskInput from "../Common/AsteriskInput";

export default function ({ files, errors, errorProps, onUpdateForm }) {
  return (
    <Formik initialValues={files}>
      {({ values, setFieldValue, resetForm }) => {
        const uploaderProps = {
          name: "claimFilesUploader",
          fileCategory: "Claim Attachment",
          allowDocumentOnly: true,
          multiple: false,
          listType: "",
          onRemove: (file) => {
            const updatedFiles = {
              ...files,
              files: files?.files.filter((x) => x.id !== file?.id),
            };
            resetForm({ values: updatedFiles });
            onUpdateForm(updatedFiles);
          },
          onDone: (file) => {
            const updatedFiles = {
              ...files,
              files: files?.files?.concat({
                id: file?.id,
                name: file?.name,
                fileCategory: "Claim Attachment",
              }),
            };
            resetForm({ values: updatedFiles });
            onUpdateForm(updatedFiles);
          },
          defaultFileList: files?.files.map((f) => ({
            id: f?.id,
            name: f.name,
          })),
        };

        const formItemProps = {
          values: { files: files?.files, ...values },
          setFieldValue,
          propogateValues: onUpdateForm,
          errors,
          errorProps,
        };
        console.log("🚀 ~ file: DocumentsRender.js ~ line 51 ~ files", files);

        return (
          <Form layout="vertical" className="jur-dispute-filing-form">
            <DocumentFields
              uploaderProps={uploaderProps}
              formItemProps={formItemProps}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export const DocumentFields = ({
  uploaderProps,
  formItemProps,
  isResponse,
}) => (
  <>
    <FormItem
      name="files"
      label={<QuestionTitle text="claimant.upload_documents.text" />}
    >
      <Uploader {...uploaderProps}>
        <Button icon={<UploadOutlined />} style={{ margin: 0 }}>
          <Translation pad={true} text="general.upload_file.text" />
        </Button>
      </Uploader>
    </FormItem>
    {toEditorFormItem(formItemProps)({
      name: "keyProvisionsOfAgreement",
      optional: true,
      customLabel: (
        <AsteriskInput
          isWrappedInTypography={true}
          text={
            isResponse
              ? "respondent.paste_text_of_arbitration_clause.text"
              : "claimant.paste_text_of_arbitration_clause.text"
          }
        />
      ),
    })}
  </>
);
