import { FileTextOutlined, FileTwoTone } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { Button, Table } from "antd";
import React from "react";
import * as fileActions from "../../redux/actions/fileActions";
import DateTime from "../Common/DateTime";

function TableOverview({ data, whiteHeader, pagination, isLoading }) {
  const dispatch = useDispatch();
  const previewFile = (fileId) => dispatch(fileActions.getShow(fileId));

  return (
    <div className="jur-case-files-table-overview">
      <Table
        loading={isLoading}
        className={whiteHeader && "jur-table-white-header"}
        columns={columns(previewFile)}
        dataSource={data}
        pagination={pagination}
      />
    </div>
  );
}

export default TableOverview;

const columns = (previewFile) => {
  return [
    {
      title: <FileTextOutlined className="jur-cf-table-body-file-icon" />,
      key: "icon",
      width: "50px",
      render: () => <FileTwoTone className="jur-table-body-file-render-icon" twoToneColor=" #2777EF" />,
    },
    {
      title: "Files",
      dataIndex: ["attributes", "fileInfo", "name"],
      key: "name",
      render: renderFiles(previewFile),
    },
    {
      title: "Meeting",
      dataIndex: ["attributes", "hearingMinuteInfo", "title"],
    },
    {
      title: "Submitted On",
      dataIndex: ["attributes", "fileInfo", "SubmittedOn"],
      render: (text) => <DateTime epochSeconds={text} onlyDate={true} />,
    },
  ];
};

const renderFiles = (previewFile) => (text, record) => (
  <Button type="link" onClick={() => previewFile(record?.id)}>
    {text}
  </Button>
);
