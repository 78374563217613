import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {
  isLoading: false,
};

export default function (state = initial, action) {
  switch (action.type) {
    case types.ARBITRATORPANEL_GET_INDEX:
      return { ...state, arbitratorPanel: null, isLoading: true };
    case types.ARBITRATORPANEL_HANDLE_INDEX:
      return {
        ...state,
        arbitratorPanel: action.payload?.data?.attributes,
        isLoading: true,
      };
    case types.ARBITRATORPANEL_HANDLE_UPDATE_STRIKELIST:
      return {
        ...state,
        strikeList: action.payload?.list || [],
        isLoading: true,
      };
    case types.ARBITRATORPANEL_HANDLE_ERROR:
      return { ...state, error: errorMessages(action.error), isLoading: false };
    default:
      return state;
  }
}
