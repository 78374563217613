import { Button, Modal, Space, Typography, Alert, Radio } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React, { useState } from "react";
import Editor from "../Common/Editor/Editor";
import Translation from "../Common/Translation";
import Uploader, { UploadButtonRender } from "../Common/Uploader";
import { handleChange } from "../DisputeFiling/helpers";
import { modalProps } from "../../helpers/modalHelpers";
import ModalWrapper from "../Common/ModalWrapper";

export default function SendToHubForm({ visible, onSubmit, closeSendToHub, isLoading, isAwardIssued, deleteAward }) {
  const [fileId, setFileId] = useState(null);

  return (
    <Formik initialValues={{ isAdditionalAward: false, kind: "Arbitral Award" }}>
      {({ values, setFieldValue, resetForm }) => {
        const handler = handleChange(values, setFieldValue);
        const uploaderProps = {
          allowDocumentOnly: true,
          maxCount: 1,
          name: "arbitralAwardUploader",
          multiple: false,
          fileCategory: "Arbitral Award",
          onRemove: () => {
            deleteAward(values?.file?.id);
            setFieldValue("file", null);
            setFileId(null);
          },
          onDone: (file) => {
            setFieldValue("file", file);
            setFileId(file.id);
          },
        };
        return (
          <Modal
            width={768}
            destroyOnClose={true}
            {...modalProps()}
            maskClosable={false}
            visible={visible}
            onCancel={() => {
              closeSendToHub();
              resetForm();
            }}
            title={
              <Typography.Title level={5}>
                <Translation text="arbitral_award.share_arbitral_award_with_the_hub.text" />
              </Typography.Title>
            }
            footer={[
              <Button
                disabled={!values?.file || !values?.highlights?.length || isLoading}
                loading={isLoading}
                type="primary"
                onClick={() => {
                  onSubmit({ ...values, fileId }, resetForm);
                }}
              >
                <Translation text="general.done.title" pad={true} />
              </Button>,
            ]}
          >
            <ModalWrapper>
              <Form layout="vertical">
                <Space direction="vertical">
                  <Alert type="info" banner message={<Translation text="arbitral_award.upload_award.description" />} />
                  <FormItem name="file">
                    <Uploader {...uploaderProps}>
                      <UploadButtonRender
                        optional={true}
                        label="general.choose_file.button"
                        buttonProps={{ disabled: Boolean(values?.file) }}
                      />
                    </Uploader>
                  </FormItem>
                  <FormItem name="kind">
                    <Radio.Group onChange={(e) => setFieldValue("kind", e.target.value)} value={values?.kind}>
                      <Space direction="vertical">
                        <Radio value="Arbitral Award">
                          <Translation text="arbitral_award.arbitral_award.title" />
                        </Radio>
                        <Radio value="Additional Arbitral Award">
                          <Translation text="arbitral_award.additional_award.text" />
                        </Radio>
                        <Radio value="Interpretation of the Award">
                          <Translation text="arbitral_award.interpretation_award.column" />
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </FormItem>
                  <FormItem
                    required
                    name="highlights"
                    label={<Translation text="arbitral_award.award_highlights.label" />}
                  >
                    <Editor onChange={handler("highlights")} className="jur-quill-editor-small" />
                  </FormItem>
                </Space>
              </Form>
            </ModalWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
}
