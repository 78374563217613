import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "evidence-requests";
const catcher = logAndThrow(tag);

export function index(disputeId, page) {
  return Api.get(`/disputes/${disputeId}/evidence-requests?page=${page}`)
    .then((res) => res.data)
    .catch(catcher("index"));
}

export function show(disputeId, evidenceRequestId) {
  return Api.get(
    `/disputes/${disputeId}/evidence-requests/${evidenceRequestId}`
  )
    .then((res) => res.data)
    .catch(catcher("show"));
}

export function create(disputeId, body) {
  return Api.post(`/disputes/${disputeId}/evidence-requests`, body).catch(
    catcher("create")
  );
}

export function update(disputeId, evidenceRequestId, body) {
  return Api.put(
    `/disputes/${disputeId}/evidence-requests/${evidenceRequestId}`,
    body
  )
    .then((res) => res.data)
    .catch(catcher("update"));
}
