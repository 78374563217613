import { Card } from "antd";
import React from "react";
import DisputeHeader from "../Common/DisputeHeader";
import CreateAssignment from "./CreateAssignmentHOC";

export default function ({ entity, disputeId, dispute, extraCrumbs, hubSlug }) {
  return (
    <Card>
      <DisputeHeader
        dispute={dispute}
        disputeId={disputeId}
        hubSlug={hubSlug}
        extraCrumbs={extraCrumbs}
      >
        <CreateAssignment disputeId={disputeId} entity={entity} />
      </DisputeHeader>
    </Card>
  );
}
