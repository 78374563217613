import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "arbitral-award";
const catcher = logAndThrow(tag);

export function index(disputeId, page) {
  return Api.get(`/disputes/${disputeId}/arbitral-award?sortBy=-updatedAt&page=${page}`)
    .then((res) => res.data)
    .catch(catcher("index"));
}

export function show(disputeId, awardId) {
  return Api.get(`/disputes/${disputeId}/arbitral-award/${awardId}`)
    .then((res) => res.data)
    .catch(catcher("show"));
}

export function create(disputeId, body) {
  return Api.post(`/disputes/${disputeId}/arbitral-award?kind=${body.kind}`, body).catch(
    catcher("create")
  );
}

export function update(disputeId, awardId, body) {
  return Api.put(
    `/disputes/${disputeId}/arbitral-award/${awardId}`,
    body
  ).catch(catcher("update"));
}

export function updateDeadline(disputeId, body) {
  return Api.put(
    `/disputes/${disputeId}/arbitral-award/1/extend-deadline`,
    body
  ).catch(catcher("update"));
}
