import React from "react";
import { Breadcrumb } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { selectGroup } from "../../redux/selectors/disputeFilesSelector";
import Translation from "./Translation";

const CaseFilesBreadcrumb = () => {
  const hubSlug = useHubSlug();
  const location = useLocation();
  const [disputeId, dispute] = useDispute();
  const userGroup = useSelector(selectGroup);
  const pages = [
    "/arbitrator",
    "/arbitral-awards",
    "/tor",
    "/procedural-orders",
    "/hearing",
    "/claimant",
    "/respondent",
    "/exhibits",
    "/reports",
    "/witness-statements",
    "/statements-attachments",
  ];
  const arbitratorFilePages = ["/arbitrator", "/arbitral-awards", "/tor", "/procedural-orders", "/hearing"];

  const checkLocation = (param) => location.pathname.includes(param);
  const isNavigatedFurtherIntoCaseFiles = pages.find((page) => checkLocation(page));
  const isNavigatedFurtherIntoArbitratorFiles = userGroup && arbitratorFilePages.find((page) => checkLocation(page));

  const crumbs = [
    {
      condition: isNavigatedFurtherIntoArbitratorFiles,
      text: "general.arbitrator.text",
      href: `/${hubSlug}/disputes/${disputeId}/files/arbitrator`,
    },
    {
      condition: checkLocation("/arbitral-awards"),
      text: "general.arbitral_award.title",
    },
    {
      condition: checkLocation("/tor"),
      text: "terms_of_reference.terms_of_reference.title",
    },
    {
      condition: checkLocation("/procedural-orders"),
      text: "procedural_order.procedural_order.title",
    },
    {
      condition: checkLocation("/hearing"),
      text: "case_files.hearing.text",
    },
    {
      condition: checkLocation("/claimant"),
      text: "general.claimant.title",
    },
    {
      condition: checkLocation("/respondent"),
      text: "general.respondent.title",
    },
    {
      condition: checkLocation("/statements-attachments"),
      text: "general.statement_and_attachment.title",
    },
    {
      condition: checkLocation("/exhibits"),
      text: "general.exhibits.text",
    },
    {
      condition: checkLocation("/witness-statements"),
      text: "general.witness_statements.text",
    },
    {
      condition: checkLocation("/reports"),
      text: "general.reports.text",
    },
  ];

  const DisputeNameCrumb = () => (
    <Breadcrumb.Item href={`/${hubSlug}/disputes/${disputeId}`}>{dispute?.name}</Breadcrumb.Item>
  );

  const DisputeFilesCrumb = () => (
    <Breadcrumb.Item href={`/${hubSlug}/disputes/${disputeId}/files`}>
      <Translation text="case_files.case_files.title" />
    </Breadcrumb.Item>
  );

  return (
    <Breadcrumb>
      <DisputeNameCrumb />
      <DisputeFilesCrumb />
      {isNavigatedFurtherIntoCaseFiles &&
        crumbs.map(
          ({ condition, text, href }) =>
            condition && (
              <Breadcrumb.Item className="jur-capitalize" href={href || "#/"}>
                <Translation text={text} />
              </Breadcrumb.Item>
            )
        )}
    </Breadcrumb>
  );
};
export default CaseFilesBreadcrumb;
