import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/disputeAssignmentActions";
import * as selectors from "../../redux/selectors/disputeAssignmentSelectors";
import { fromAttributes } from "../../transformers/userTransformer";
import CreateAssignmentRender from "./CreateAssignmentRender";

export default function ({ disputeId, entity }) {
  const selected = useSelector(selectors.current);
  const results = useSelector(selectors.results);
  const isSubmitting = useSelector(selectors.isSubmitting);
  const dispatch = useDispatch();

  const handleSelect = (id) =>
    void dispatch(
      actions.handleShow({ data: results.find((r) => r.id === id) })
    );

  const handleSubmit = () =>
    void dispatch(
      actions.postCreate(disputeId, {
        hubUserId: selected.id,
        allowToUpload: true,
      })
    );

  useEffect(() => {
    dispatch(indexOf(entity));
    return () => dispatch(actions.handleReset());
  }, [dispatch, entity]);

  return (
    <CreateAssignmentRender
      labelPrefix={entity}
      onSelect={handleSelect}
      results={results}
      selected={
        selected &&
        fromAttributes(selected.attributes, { role: roleOf(entity) })
      }
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  );
}

function indexOf(label) {
  return label === "arbitrator"
    ? actions.getIndexArbitrators()
    : actions.getIndexExperts();
}

function roleOf(entity) {
  return entity === "arbitrator" ? "Arbitrator" : "Expert";
}
