import { logAndThrow } from "../../helpers/apiHelpers";
import { hubless } from "./Api";

const tag = "sign";
const catcher = logAndThrow(tag);

export function consent(sendEmail = true) {
  if (sendEmail) {
    return hubless
      .post(`/signature/consent`)
      .then((res) => res.data)
      .catch(catcher("consent"));
  } else
    return hubless
      .get(`/signature/consent`)
      .then((res) => res.data)
      .catch(catcher("consent"));
}

export function consentUrl(route) {
  return hubless
    .get(`/signature/consent/url?route=${route}`)
    .then((res) => res.data)
    .catch(catcher("concentUrl"));
}

export function createEnvelope(disputeId, hubSlug, body) {
  return hubless
    .post(`${hubSlug}/disputes/${disputeId}/signature/envelope`, body)
    .then((res) => res.data)
    .catch(catcher("createEnvelope"));
}

export function updateEnvelope(body) {
  return hubless
    .put(
      `${body.hubSlug}/disputes/${body.disputeId}/signature/envelope/signed/${body.envelopeId}`,
      body
    )
    .then((res) => res.data)
    .catch(catcher("updateEnvelope"));
}

export function updateEnvelopeAt(body) {
  return hubless
    .put(
      `${body.hubSlug}/disputes/${body.disputeId}/signature/envelope/sent/${body.envelopeId}`,
      body
    )
    .then((res) => res.data)
    .catch(catcher("update", "Failed to update signature"));
}
