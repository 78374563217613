import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { postInviteArbitrator } from "../../redux/actions/hubActions";
import { selectErrors } from "../../redux/selectors/authSelector";
import InviteArbitratorRender from "./InviteArbitratorRender";

function InviteArbitratorHOC() {
  const dispatch = useDispatch();
  const errors = useSelector(selectErrors);

  const handleSubmit = (body) => dispatch(postInviteArbitrator(body));

  return <InviteArbitratorRender onSubmit={handleSubmit} errors={errors} />;
}

export default InviteArbitratorHOC;
