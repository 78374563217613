import {
  LinkedinOutlined,
  LinkOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Avatar, Card, Col, Row, Typography } from "antd";
import React from "react";
import { toAvatar, toName } from "../../transformers/userTransformer";
import Translation from "./Translation";

export default function ArbitratorCard({ arbitrator }) {
  const user = arbitrator?.attributes;
  return (
    <Card>
      <Card.Meta
        avatar={<Avatar size={50} src={toAvatar(user)} />}
        title={toName(user)}
        description={<ProfileLinks user={user} />}
      />
      <ProfileDetails items={profileItems(user)} />
    </Card>
  );
}

const ProfileDetails = ({ items = [] }) => {
  return (
    <>
      <br />
      {items.map((item) => (
        <Typography.Paragraph>
          <Row>
            <Col span={10}>
              <Typography.Text strong>
                <Translation text={item.label} />
              </Typography.Text>
            </Col>
            <Col span={14}>{item.value}</Col>
          </Row>
        </Typography.Paragraph>
      ))}
    </>
  );
};

const profileItems = (user) => [
  {
    label: "general.specialisations.text",
    value: user?.specializations?.join(", "),
  },
  { label: "general.country.text", value: user?.country },
  { label: "general.years_of_experience.text", value: user?.yearsOfExperience },
];

const ProfileLinks = ({ user }) => {
  const links = [
    {
      icon: <LinkOutlined />,
      url: user?.webUrl,
    },
    {
      icon: <TwitterOutlined />,
      url: user?.twitter,
    },
    {
      icon: <LinkedinOutlined />,
      url: user?.linkedIn,
    },
  ];
  return (
    <>
      {links
        .filter((l) => Boolean(l.url))
        .map((link) => {
          return (
            <>
              {React.cloneElement(link.icon, {
                style: { fontSize: 20, paddingRight: 5 },
                onClick: () => window.open(link.url, "_blank"),
              })}
            </>
          );
        })}
    </>
  );
};
