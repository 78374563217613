import { Typography } from "antd";
import React, { useState } from "react";

export function errorMessages(res) {
  if (res?.isAxiosError) {
    return res?.response?.data?.errors;
  }
  return res;
}

export function errorAsExtra(errors, isHidden = false) {
  return function (fieldName, controlValidationStatus = false) {
    const errorText = errors && errors[fieldName];
    if (!errorText || isHidden) return {};

    const returnProps = {
      extra: <Typography.Text type="danger">{errorText}</Typography.Text>,
    };
    if (controlValidationStatus) {
      returnProps.validateStatus = "error";
      returnProps.hasFeedback = null;
    }

    return returnProps;
  };
}

/**
 * Generic error display component for antd components
 * with no error label eg. Uploader
 */
export const ErrorDisplay = ({ errors, name, isHidden }) =>
  isHidden ? null : (
    <Typography.Text type="danger">{errors && errors[name]}</Typography.Text>
  );

export function useErrorHider(initialIsHidden = false) {
  const [isHidden, setIsHidden] = useState(initialIsHidden);
  const hide = () => setIsHidden(true);
  const unhide = () => setIsHidden(false);
  const hideAndCall = (fn) => (...params) => {
    hide();
    fn(...params);
  };
  const callAndUnhide = (fn) => (...params) => {
    fn(...params);
    unhide();
  };

  return {
    isHidden,
    setIsHidden,
    hideAndCall,
    callAndUnhide,
    hide,
    unhide,
  };
}
