import { Button, Col, Row, Typography } from "antd";
import { Formik } from "formik";
import { Checkbox, Form, FormItem, Input } from "formik-antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { passwordIconRender, storeEmail } from "../../helpers/authHelpers";
import { errorAsExtra } from "../../helpers/errorHelpers";
import { toFormItem } from "../../helpers/formHelpers";
import { registerUser } from "../../redux/actions/authenticateActions";
import {
  authLoading,
  isAuthenticated,
  selectErrors,
} from "../../redux/selectors/authSelector";
import Translation from "../Common/Translation";
import AuthLayout from "../Layouts/AuthLayout";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";
import AuthFormItem from "./AuthFormItem";
import registrationValidate from "./registerValidate";

const termsLink = "https://jur.io/privacy-policy/";

export default function Register() {
  const dispatch = useDispatch();
  const history = useHistory();
  const _isAuthenticated = useSelector(isAuthenticated);
  const loading = useSelector(authLoading);
  const errors = useSelector(selectErrors);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (_isAuthenticated) {
      history.push("/");
    }
    setIsHidden(true);
  }, [_isAuthenticated, history]);

  const extraProps = errorAsExtra(errors, isHidden);

  return (
    <LeftSidebarLayout
      mainContent={
        <AuthLayout
          header={<SignInHeader />}
          content={
            <>
              <div className="jur-auth-title">
                <Translation text="general.sign_up.title" />
              </div>
              <div className="jur-auth-description">
                <Translation text="general.lets_get_started.title" />
              </div>
              <Formik
                onSubmit={(values) => {
                  setIsHidden(false);
                  storeEmail(values.email);
                  dispatch(registerUser(values));
                }}
                initialValues={{}}
                // validateOnChange={false}
                // validateOnBlur={false}
                validationSchema={registrationValidate}
              >
                {({ handleChange, values, setFieldValue, isValid }) => {
                  const hideOnChange = (e) => {
                    setIsHidden(true);
                    handleChange(e);
                  };

                  const fields = [
                    {
                      name: "email",
                      label: (
                        <Translation text="authentication.email_address.text" />
                      ),
                      ...extraProps("email"),
                      component: <Input name="email" onChange={hideOnChange} />,
                    },
                    {
                      name: "password",
                      label: (
                        <Translation text="authentication.password.text" />
                      ),
                      ...extraProps("password"),
                      component: (
                        <Input.Password
                          name="password"
                          onChange={hideOnChange}
                          iconRender={passwordIconRender(values, "password")}
                        />
                      ),
                    },
                    {
                      name: "passwordConfirmation",
                      label: (
                        <Translation text="authentication.verify_password.text" />
                      ),
                      ...extraProps("confirm_password"),
                      component: (
                        <Input.Password
                          iconRender={passwordIconRender(
                            values,
                            "passwordConfirmation"
                          )}
                          name="passwordConfirmation"
                          onChange={hideOnChange}
                        />
                      ),
                    },
                    {
                      name: "terms_and_conditions",
                      ...extraProps("terms_and_conditions"),
                      component: (
                        <Checkbox
                          name="terms_and_conditions"
                          onChange={hideOnChange}
                        >
                          <Typography.Title level={5}>
                            I agree to the{" "}
                            <span
                              className="jur-link"
                              onClick={() => {
                                window.open(termsLink, "_blank");
                              }}
                            >
                              terms
                            </span>{" "}
                            and{" "}
                            <span
                              className="jur-link"
                              onClick={() => {
                                window.open(termsLink, "_blank");
                              }}
                            >
                              conditions
                            </span>
                          </Typography.Title>
                        </Checkbox>
                      ),
                    },
                  ];

                  return (
                    <Form layout="vertical">
                      <FormItem
                        name="name"
                        label={
                          <Typography.Title level={5}>
                            <Translation text="general.full_name.text" />
                          </Typography.Title>
                        }
                        required={true}
                      >
                        <Row gutter={[24, 0]}>
                          {[
                            {
                              name: "firstName",
                              placeholder: "First Name",
                            },
                            {
                              name: "lastName",
                              placeholder: "Last Name",
                            },
                          ]
                            .map(
                              toFormItem({
                                setFieldValue,
                                values,
                                compact: true,
                              })
                            )
                            .map((component, i) => (
                              <Col key={i} span={12}>
                                {component}
                              </Col>
                            ))}
                        </Row>
                      </FormItem>
                      {fields.map((formItem) => (
                        <AuthFormItem {...formItem} />
                      ))}
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={!isValid || Object.keys(values).length === 0}
                      >
                        <Translation
                          text="authentication.sign_up.button"
                          pad={true}
                        />
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </>
          }
        />
      }
    />
  );
}

const SignInHeader = () => (
  <div className="jur-sign-up-text">
    <Translation text="authentication.already_a_member.text" />
    {"     "}
    <Link className="jur-link" to="/authenticate">
      <Translation text="authentication.sign_in.text" />
    </Link>
  </div>
);
