import { Button, Modal, Space, Typography, Alert } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React from "react";
import { errorAsExtra, useErrorHider } from "../../helpers/errorHelpers";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import ModalWrapper from "../Common/ModalWrapper";
import Uploader, { UploadButtonRender } from "../Common/Uploader";

export default function UploadRevisedOrderForm({
  orders,
  visible,
  selectedPO,
  handleUploadRevisedOrderSubmit,
  handleUploadRevisedOrderClose,
  selectedOrder = null,
  errors,
}) {
  const errorProps = useErrorHider();
  const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);

  return (
    <Formik initialValues={{ orderId: selectedOrder }}>
      {({ values, setFieldValue, resetForm }) => {
        const uploaderProps = {
          allowDocumentOnly: true,
          maxCount: 1,
          name: "proceduralOrderUploader",
          multiple: false,
          fileCategory: "Procedural Order",
          onRemove: () => {
            setFieldValue("file", null);
            setFieldValue("fileId", null);
          },
          onDone: (file) => {
            setFieldValue("file", file);
            setFieldValue("fileId", file?.id);
          },
        };
        return (
          <Modal
            width={768}
            visible={visible}
            destroyOnClose={true}
            maskClosable={false}
            title={
              <Typography.Title level={5}>
                <Translation text="procedural_order.upload_revised_order.title" />
              </Typography.Title>
            }
            onCancel={() => {
              setFieldValue("file", null);
              setFieldValue("fileId", null);
              resetForm();
              handleUploadRevisedOrderClose();
            }}
            footer={[
              <Button
                disabled={!Boolean(values?.fileId) || !selectedPO?.id}
                type="primary"
                onClick={() => {
                  handleUploadRevisedOrderSubmit({ ...values, orderId: selectedPO?.id }, resetForm);
                }}
              >
                <Translation text="general.send.button" />
              </Button>,
            ]}
          >
            <ModalWrapper>
              {selectedOrder || orders ? (
                <Form layout="vertical">
                  <Space direction="vertical" size="large">
                    <Alert
                      type="info"
                      banner={true}
                      message={`You are uploading a revised version for Order #${selectedPO?.id}`}
                    />
                    <FormItem name="file" {...apiErrorOf("fileId", true)}>
                      <Uploader {...uploaderProps}>
                        <UploadButtonRender
                          optional={true}
                          label="general.choose_file.button"
                          buttonProps={{ disabled: Boolean(values?.file) }}
                        />
                      </Uploader>
                    </FormItem>
                  </Space>
                </Form>
              ) : (
                <Spinner />
              )}
            </ModalWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
}
