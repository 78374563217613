import { Col, Row } from "antd";
import { FormItem, Input } from "formik-antd";
import _ from "lodash";
import React from "react";
import * as yup from "yup";
import Editor from "../components/Common/Editor";
import { QuestionHelpText } from "../components/Common/FormQuestion";
import JurLink from "../components/Common/JurLink";
import Translation from "../components/Common/Translation";
import { errorAsExtra } from "./errorHelpers";

export function inputProps() {
  return {
    maxLength: 255,
  };
}

export const TipText = ({
  text,
  sampleLink,
  moreDetailsLink,
  hideTip = false,
}) => {
  return (
    <>
      {hideTip ? (
        <>
          <Translation text="general.tip.text" />:{" "}
        </>
      ) : null}
      <Translation text={text} />{" "}
      {sampleLink && (
        <JurLink to={sampleLink}>
          <Translation text="general.see_a_sample.text" />
        </JurLink>
      )}
      {moreDetailsLink && (
        <JurLink to={moreDetailsLink}>
          <Translation text="general.see_more_details_here.text" />
        </JurLink>
      )}
    </>
  );
};

export const AddressFields = (formItemProps) => {
  return (
    <>
      <Row gutter={[24, 0]}>
        {[
          {
            name: "primaryAddress.address",
            placeholder: "Address 1",
          },
          {
            name: "primaryAddress.address2",
            placeholder: "Address 2",
          },
        ]
          .map(toFormItem({ ...formItemProps, compact: true }))
          .map((component, i) => (
            <Col key={`2${i}`} span={24}>
              {component}
            </Col>
          ))}
        {[
          {
            name: "primaryAddress.zipCode",
            placeholder: "Zip Code",
          },
          {
            name: "primaryAddress.city",
            placeholder: "City",
          },
          {
            name: "primaryAddress.state",
            placeholder: "State",
          },
          {
            name: "primaryAddress.country",
            placeholder: "Country",
          },
        ]
          .map(toFormItem({ ...formItemProps, compact: true }))
          .map((component, i) => (
            <Col key={`1${i}`} span={12}>
              {component}
            </Col>
          ))}
      </Row>
    </>
  );
};

export const handleChange = (values, setFieldValue, propogateValues) => (
  name
) => (e) => {
  const value = e?.target ? e.target?.value : e;
  if (setFieldValue) setFieldValue(name, value);
  if (propogateValues) {
    const newValues = values;
    _.set(newValues, name, value);
    propogateValues(newValues, name, value);
  }
};

export function toFormItem({
  propogateValues,
  values,
  setFieldValue,
  errors,
  errorProps = {},
  compact = false,
  t = (val) => val,
} = {}) {
  const { isHidden, hideAndCall } = errorProps;
  const handleChangeOf = handleChange(values, setFieldValue, propogateValues);
  const apiErrorOf = errorAsExtra(errors, isHidden);

  return (item, index) => {
    const Field = item.component ? item.component : Input;
    const componentProps = item.component
      ? item.componentProps
      : { ...item.componentProps, ...inputProps() };
    const onChangeHandler = hideAndCall
      ? hideAndCall(handleChangeOf(item.name))
      : handleChangeOf(item.name);

    const itemLabel = Boolean(item?.customLabel) ? (
      item?.customLabel
    ) : compact ? undefined : (
      <Translation text={item.label} />
    );

    return (
      <FormItem
        label={itemLabel}
        name={item.name}
        key={index}
        required={!item.optional}
        {...apiErrorOf(item.name, true)}
      >
        {item?.helpText ? (
          <QuestionHelpText>{item?.helpText}</QuestionHelpText>
        ) : null}
        <Field
          name={item.name}
          type={item.type}
          placeholder={item.placeholder}
          onChange={onChangeHandler}
          {...componentProps}
        />
      </FormItem>
    );
  };
}

export function toEditorFormItem({
  propogateValues,
  values,
  setFieldValue,
  errors,
  errorProps = {},
  compact = false,
  t = null,
  formikErrors = {} /** take from Formik context */,
} = {}) {
  const { isHidden, hideAndCall } = errorProps;
  const handleChangeOf = handleChange(values, setFieldValue, propogateValues);
  const apiErrorOf = errorAsExtra(errors, isHidden);

  return (item, index) => {
    const componentProps = item?.component
      ? item?.componentProps
      : inputProps();
    const onChangeHandler = hideAndCall
      ? hideAndCall(handleChangeOf(item?.name))
      : handleChangeOf(item?.name);

    return (
      <FormItem
        label={
          Boolean(item?.customLabel) ? (
            item?.customLabel
          ) : compact ? undefined : (
            <Translation text={item.label} />
          )
        }
        name={item?.name}
        key={index}
        required={!item?.optional}
        {...apiErrorOf(item?.name, true)}
      >
        <Editor
          defaultValue={values?.[item?.name]}
          name={item?.name}
          type={item?.type}
          help={formikErrors[item?.name]}
          placeholder={compact ? t(item?.label) : undefined}
          onChange={(html) => onChangeHandler(sanitizeHtml(html))}
          {...componentProps}
        />
      </FormItem>
    );
  };
}

export const sanitizeHtml = (html) => {
  if (html.replace(/<(.|\n)*?>/g, "").trim().length === 0) return "";
  return html;
};

export const getInviteeErrors = (errors) =>
  Object.entries(errors || {}).reduce((acc, [key, value]) => {
    if (key.startsWith("invitees.")) {
      const [, index, fieldName] = key.split(".");
      acc[index] = acc[index] || {};
      acc[index][fieldName] = value;
    }
    return acc;
  }, {});

export const validateHtmlLength = (wordLimit) =>
  yup
    .string()
    .nullable()
    .test(
      "length-validation",
      `This field should not exceed ${wordLimit} words`,
      (value = "") => {
        if (!value) return true;
        if (!value?.wordLength) return true;
        return value?.wordLength <= wordLimit;
      }
    );
