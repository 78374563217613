import * as types from "../constants/constants";

export const putUpdateDefence = (disputeId, body) => {
  return {
    type: types.DEFENSIVEACTIONS_PUT_UPDATE_DEFENCE,
    payload: { disputeId, body },
  };
};

export const handleUpdateDefence = () => ({
  type: types.DEFENSIVEACTIONS_HANDLE_UPDATE_DEFENCE,
});

export const postCreateCounterclaim = (disputeId, body) => ({
  type: types.DEFENSIVEACTIONS_POST_CREATE_COUNTERCLAIM,
  payload: { disputeId, body },
});

export const handleCreateCounterclaim = () => ({
  type: types.DEFENSIVEACTIONS_HANDLE_CREATE_COUNTERCLAIM,
});

export const putUpdateCounterclaimreply = (disputeId, counterClaimId, body) => {
  return {
    type: types.DEFENSIVEACTIONS_PUT_UPDATE_COUNTERCLAIMREPLY,
    payload: { disputeId, counterClaimId, body },
  };
};

export const handleUpdateCounterclaimreply = () => ({
  type: types.DEFENSIVEACTIONS_HANDLE_UPDATE_COUNTERCLAIMREPLY,
});

export const putUpdateDeadline = (disputeId, body, resetForm) => {
  return {
    type: types.DEFENSIVEACTIONS_PUT_UPDATE_DEADLINE,
    payload: { disputeId, body, resetForm },
  };
};

export const handleUpdateDeadline = () => ({
  type: types.DEFENSIVEACTIONS_HANDLE_UPDATE_DEADLINE,
});

export const handleIsRequestDeadlineOpen = (isOpen) => ({
  type: types.DEFENSIVEACTIONS_HANDLE_IS_REQUEST_DEADLINE_OPEN,
  payload: isOpen,
});

export const handleError = (e) => ({
  type: types.DEFENSIVEACTIONS_HANDLE_ERROR,
  error: e,
});
