import * as types from "../constants/constants";

export const getPhases = (disputeId) => ({
  type: types.ASSISTEDDISPUTE_GET_PHASES_INDEX,
  payload: { disputeId },
});

export const handlePhases = (data) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_PHASES_INDEX,
  payload: data,
});

export const putUpdatePhases = (disputeId, phases) => ({
  type: types.ASSISTEDDISPUTE_PUT_UPDATE_PHASES,
  payload: { disputeId, phases },
});

export const handleUpdatePhases = (data) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_UPDATE_PHASES,
  payload: data,
});

export const handleUpdatePhasesForm = (phases) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_UPDATE_PHASES_FORM,
  payload: phases,
});

export const getLastActivity = (disputeId, group) => ({
  type: types.ASSISTEDDISPUTE_GET_LASTACTIVITY_SHOW,
  payload: { disputeId, group },
});

export const handleLastActivity = (data, group) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_LASTACTIVITY_SHOW,
  payload: { data, group },
});

export const putUpdateLastActivity = (disputeId, group, lastUpdate) => ({
  type: types.ASSISTEDDISPUTE_PUT_UPDATE_LASTACTIVITY,
  payload: { disputeId, group, lastUpdate },
});

export const handleUpdateLastActivity = (disputeId, group) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_UPDATE_LASTACTIVITY,
  payload: { disputeId, group },
});

export const handleUpdateLastActivityForm = (key, value) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_UPDATE_LASTACTIVITY_FORM,
  payload: { key, value },
});

export const getTaskList = (disputeId, group) => ({
  type: types.ASSISTEDDISPUTE_GET_TASKLIST,
  payload: { disputeId, group },
});

export const handleTaskList = (data, group) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_TASKLIST,
  payload: { data, group },
});

export const putUpdateTaskList = (disputeId, group, tasks) => ({
  type: types.ASSISTEDDISPUTE_PUT_UPDATE_TASKLIST,
  payload: { disputeId, group, tasks },
});

export const handleUpdateTaskList = (disputeId, group) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_UPDATE_TASKLIST,
  payload: { disputeId, group },
});

export const handleUpdateTaskListForm = (group, tasks) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_UPDATE_TASKLIST_FORM,
  payload: { group, tasks },
});

export const putUpdateTaskStatus = (disputeId, taskId, status, onSuccess) => ({
  type: types.ASSISTEDDISPUTE_PUT_UPDATE_TASKSTATUS,
  payload: { disputeId, taskId, status, onSuccess },
});

export const handleUpdateTaskStatus = (data) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_UPDATE_TASKSTATUS,
  payload: { data },
});

export const handleError = (e) => ({
  type: types.ASSISTEDDISPUTE_HANDLE_ERROR,
  error: e,
});
