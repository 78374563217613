import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getShow } from "../../redux/actions/arbitratorActions";
import { selectCurrentArbitrator } from "../../redux/selectors/arbitratorSelector";
import ShowRender from "./ShowRender";

function ShowHOC() {
  const dispatch = useDispatch();
  const arbitrator = useSelector(selectCurrentArbitrator);

  const { arbitratorId } = useParams();

  useEffect(() => {
    dispatch(getShow(arbitratorId));
  }, [dispatch, arbitratorId]);

  return <ShowRender profile={arbitrator} />;
}

export default ShowHOC;
