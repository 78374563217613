import React from "react";
import { Link } from "react-router-dom";
import "./styles.less";

export default function JurLogo() {
  return (
    <Link to="/">
      <img
        className="brand-logo"
        src={require("../../../assets/logo/jur-logo.png")}
        alt="logo"
      />
    </Link>
  );
}
