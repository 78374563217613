import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { canAccess, useAccess } from "../../helpers/accessHelpers";
import { torFileName } from "../../helpers/dataHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import * as actions from "../../redux/actions/disputeTorActions";
import * as fileActions from "../../redux/actions/fileActions";
import { postCreateSignature } from "../../redux/actions/signActions";
import { HUB_APPROVED, SENT_TO_HUB } from "../../redux/constants/TORStatuses";
import {
  selectDisputeTor,
  whole,
} from "../../redux/selectors/disputeTorSelector";
import { selectParticipants } from "../../redux/selectors/disputeUsersSelector";
import { selectFile } from "../../redux/selectors/filesSelector";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import ShowRender from "./ShowRender";

export default function () {
  const [disputeId, dispute] = useDispute();
  const {
    isReviewTermsVisible,
    isSendToPartiesVisible,
    isLoading,
  } = useSelector(whole);
  const participants = useSelector(selectParticipants)?.filter((u) =>
    canAccess(u, "view", "tor")
  );
  const { torId } = useParams();
  const dispatch = useDispatch();
  const tor = useSelector(selectDisputeTor);
  const file = useSelector(selectFile);
  const fileId = tor?.attributes?.file?.id;
  const hubSlug = useHubSlug();
  const hasUrl = Boolean(tor?.attributes?.redirectURL);
  const canSendToParties = useAccess("create", "tor");
  const isSharable =
    tor?.attributes?.status === HUB_APPROVED && canSendToParties;
  const canReview = useAccess("review", "tor");
  const isReviewable = tor?.attributes?.status === SENT_TO_HUB && canReview;

  const openSendToParties = () => {
    dispatch(actions.handleIsSendToPartiesOpen(true));
  };

  const closeSendToParties = () => {
    dispatch(actions.handleIsSendToPartiesOpen(false));
  };

  const handleReviewTerms = (body, resetForm) => {
    dispatch(actions.putUpdate(disputeId, body.torId, body, resetForm));
  };

  const openReviewTerms = () => {
    dispatch(actions.handleIsReviewOpen(true));
  };

  const closeReviewTerms = () => {
    dispatch(actions.handleIsReviewOpen(false));
  };

  const uiRedirectUrl = `/${hubSlug}/disputes/${disputeId}/tor`;

  const signTor = () =>
    dispatch(
      postCreateSignature({
        envelopeId: tor?.attributes?.envelopeId,
        envelopeRedirectUrl: tor?.attributes?.redirectURL,
        disputeId,
        uiRedirectUrl,
        flow: "Terms of Reference",
      })
    );

  const handleSendToParties = (body, resetForm) =>
    void dispatch(
      postCreateSignature({
        signerIds: body.userIds,
        fileIds: [body.torFileId],
        flow: "Terms of Reference",
        signType: "Group Sign",
        envelopeRedirectUrl: uiRedirectUrl,
        uiRedirectUrl,
        disputeId,
        onEnvelopeCreated: (res, { envelopeId }) => {
          dispatch(
            actions.putUpdate(
              disputeId,
              body.torId,
              {
                envelopeId: envelopeId,
                deadline: body.deadline,
              },
              resetForm
            )
          );
        },
      })
    );

  useEffect(() => void dispatch(actions.getShow(disputeId, torId)), [
    disputeId,
    torId,
    dispatch,
  ]);

  useEffect(() => {
    if (fileId) dispatch(fileActions.getInlineShow(fileId));
  }, [fileId, dispatch]);

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/tor`,
      breadcrumbName: (
        <Translation text="terms_of_reference.terms_of_reference.title" />
      ),
    },
    {
      path: `/${hubSlug}/disputes/${disputeId}/tor/${torId}`,
      breadcrumbName: torFileName(tor?.attributes),
    },
  ];

  return tor && file ? (
    <ShowRender
      tor={tor}
      file={file}
      dispute={dispute}
      disputeId={disputeId}
      signFile={signTor}
      hasUrl={hasUrl}
      hubSlug={hubSlug}
      extraCrumbs={extraCrumbs}
      canSendToParties={isSharable}
      openSendToParties={openSendToParties}
      closeSendToParties={closeSendToParties}
      isLoading={isLoading}
      isSendToPartiesVisible={isSendToPartiesVisible}
      participants={participants}
      handleSendToParties={handleSendToParties}
      isReviewTermsVisible={isReviewTermsVisible}
      handleReviewTerms={handleReviewTerms}
      openReviewTerms={openReviewTerms}
      closeReviewTerms={closeReviewTerms}
      canReviewTOR={isReviewable}
      uiRedirectUrl={uiRedirectUrl}
    />
  ) : (
    <Spinner />
  );
}
