export class BaseService {
  // Class to generate feature wise
  // actions,
  // function to generate action type,
  // setters,
  // selectors,
  // future: sagas & apis

  constructor(feature) {
    this.feature = feature;
  }

  // Actions

  actionTypeOf(suffix) {
    return (
      {
        proceduralOrder: "PROCEDURALORDER",
      }[this.feature] + `_${suffix}`
    );
  }

  actionObjectOf(payload, suffix) {
    return {
      type: this.actionTypeOf(suffix),
      payload,
    };
  }

  fetchIndex(disputeId, page = 1) {
    return {
      type: this.actionTypeOf("FETCH_INDEX"),
      payload: { disputeId, page },
    };
  }

  setIndex(data) {
    return {
      type: this.actionTypeOf("SET_INDEX"),
      payload: data,
    };
  }

  setCurrent(data) {
    return {
      type: this.actionTypeOf("SET_CURRENT"),
      payload: data,
    };
  }

  fetchCurrent(disputeId, orderId, params = "") {
    return {
      type: this.actionTypeOf("FETCH_CURRENT"),
      payload: { disputeId, orderId, params },
    };
  }

  handleError(e) {
    return { type: this.actionTypeOf("HANDLE_ERROR"), error: e };
  }

  // Selectors

  select(reducerKey) {
    return (state) => state?.[this.feature]?.[reducerKey];
  }

  selectWhole() {
    return (state) => state?.[this.feature];
  }

  setForm(payload) {
    return this.actionObjectOf(payload, "SET");
  }
}
