import { Typography } from "antd";
import React from "react";
import { HUB_REJECTED } from "../../../../redux/constants/DisputeStatuses";
import Translation from "../../../Common/Translation";
import JurStepsProgress from "../../../Feedback/JurStepsProgress";
const { Title, Text } = Typography;

export default function TimePhase({ dispute, userName }) {
  const { assistedDispute, status } = dispute;
  const isStatusHubRejected = status === HUB_REJECTED;

  const currentPhaseIndex = (assistedDispute?.phases ?? []).findIndex(
    (p) => p.isCurrentPhase
  );

  return (
    <div>
      <Greetings
        isStatusHubRejected={isStatusHubRejected}
        userName={userName}
      />
      {!isStatusHubRejected && assistedDispute?.phases?.length ? (
        <>
          <JurStepsProgress
            total={assistedDispute?.phases?.length}
            currentStep={currentPhaseIndex + 1}
            phases={assistedDispute?.phases}
          />
          <CurrentStage
            currentPhaseIndex={currentPhaseIndex}
            currentPhase={assistedDispute?.phases[currentPhaseIndex]}
          />
        </>
      ) : null}
    </div>
  );
}

const Greetings = ({ isStatusHubRejected, userName }) => {
  const greetingTitle = (
    <>
      <Translation text="general.hello.text" />, {userName}!
    </>
  );
  const greetingDescription = isStatusHubRejected
    ? "dispute.jur_cannot_accept_your_dispute.text"
    : "dispute.here_is_your_timeline.text";

  return (
    <>
      <div className="jur-dispute-show-greeting">
        <Title className="primary-color" level={4}>
          {greetingTitle}
        </Title>
        <Text className="secondary-color">
          <Translation text={greetingDescription} />
        </Text>
      </div>
    </>
  );
};

const CurrentStage = ({ currentPhase, currentPhaseIndex }) => {
  return (
    <div>
      <Text className="secondary-color">
        <Translation text="dispute.you_are_currently_on.text" />
      </Text>
      <Title level={5} className="primary-color h5-medium">
        {`Phase ${currentPhaseIndex + 1}: ${currentPhase?.stateName}`}
      </Title>
    </div>
  );
};
