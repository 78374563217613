import React from "react";
import { Route, Switch } from "react-router-dom";
import ArbitralAwards from "../components/ArbitralAward";
import ArbitratorFiles from "../components/ArbitratorFiles";
import ArbitratorPanel from "../components/ArbitratorPanel";
import AssistedDispute from "../components/AssistedDispute";
import Auth from "../components/Authenticate";
import Authenticate from "../components/Authenticate/Authenticate";
import ForgotPassword from "../components/Authenticate/ForgotPassword";
import ForgotPasswordConfirm from "../components/Authenticate/ForgotPasswordConfirm";
import Register from "../components/Authenticate/Register";
import RegisterConfirm from "../components/Authenticate/RegisterConfirm";
import ResetPassword from "../components/Authenticate/ResetPassword";
import Translation from "../components/Common/Translation";
import DefensiveActions from "../components/DefensiveActions";
import DisputeAssignment from "../components/DisputeAssignment";
import DisputeFiles from "../components/DisputeFiles";
import DisputeFilesCategory from "../components/DisputeFiles/Category";
import DisputeFilesClaimant from "../components/DisputeFiles/Claimant";
import DisputeFilesRespondent from "../components/DisputeFiles/Respondent";
import DisputeFiling from "../components/DisputeFiling";
import Disputes from "../components/Disputes";
import EvidenceRequest from "../components/EvidenceRequest";
import HearingFiles from "../components/HearingFiles";
import Hearings from "../components/Hearings";
import HubSettings from "../components/HubAdmin/HubSettingsHOC";
import InviteArbitratorHOC from "../components/HubAdmin/InviteArbitratorHOC";
import Library from "../components/Library";
import ProceduralOrder from "../components/ProceduralOrder";
import Profile from "../components/Profile";
import ResponseFiling from "../components/ResponseFiling";
import IndexHOC from "../components/Roster/IndexHOC";
import ShowHOC from "../components/Roster/ShowHOC";
import Signs from "../components/Signs";
import History from "../components/Timeline/HistoryHOC";
import TOR from "../components/TOR";
import PrivateRoute from "./PrivateRoute";

function Routes() {
  return (
    <section className="jur-page">
      <Switch>
        <Route path="/component-library">
          <Library />
        </Route>
        <Route exact path="/authenticate">
          <Authenticate />
        </Route>

        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>

        <Route exact path="/users/accept-invite/:token">
          <Auth.AcceptInvite />
        </Route>

        <Route exact path="/users/reset-password/:token">
          <ResetPassword />
        </Route>

        <Route exact path="/users/confirm/:token">
          <Auth.ActivateAccount />
        </Route>

        <Route exact path="/register">
          <Register />
        </Route>

        <Route exact path="/confimation">
          <RegisterConfirm />
        </Route>
        <Route exact path="/forgot-password/confirmation">
          <ForgotPasswordConfirm />
        </Route>

        <PrivateRoute exact path="/hub-settings">
          <HubSettings />
        </PrivateRoute>

        <PrivateRoute exact path="/">
          <Disputes.Index />
        </PrivateRoute>

        <PrivateRoute exact path="/roster">
          <IndexHOC />
        </PrivateRoute>

        <PrivateRoute exact path="/roster/profile/:arbitratorId">
          <ShowHOC />
        </PrivateRoute>

        <PrivateRoute exact path="/roster/invite-arbitrator">
          <InviteArbitratorHOC />
        </PrivateRoute>

        <PrivateRoute path="/onboarding">
          <Profile.Onboarding />
        </PrivateRoute>

        <PrivateRoute path="/edit-profile">
          <Profile.Onboarding />
        </PrivateRoute>

        <PrivateRoute exact path="/handle-docusign-consent">
          <Signs.RedirectConsent />
        </PrivateRoute>

        <PrivateRoute exact path="/handle-docusign-sign">
          <Signs.AfterSign />
        </PrivateRoute>

        <PrivateRoute exact path="/handle-docusign-send">
          <Signs.AfterSend />
        </PrivateRoute>

        <PrivateRoute exact path="/dispute-filing/:disputeId">
          <DisputeFiling.Show />
        </PrivateRoute>

        <PrivateRoute exact path="/dispute-filing-introduction">
          <Disputes.ClaimFilingIntro />
        </PrivateRoute>

        <PrivateRoute exact path="/region-us/dispute-filing">
          <DisputeFiling />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/dispute-filing/:disputeId">
          <DisputeFiling.Show />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId">
          <Disputes.Show />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/arbitrator-panel"
        >
          <ArbitratorPanel.Index />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/arbitrator-panel/arbitrators/:arbitratorId"
        >
          <ArbitratorPanel.Show />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/assisted-dispute"
        >
          <AssistedDispute.Index />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/files">
          <DisputeFiles />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/files/claimant">
          <DisputeFilesClaimant />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/files/respondent"
        >
          <DisputeFilesRespondent />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/files/statements-attachments"
        >
          <DisputeFilesCategory />
        </PrivateRoute>
        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/files/exhibits">
          <DisputeFilesCategory />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/files/witness-statements"
        >
          <DisputeFilesCategory />
        </PrivateRoute>
        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/files/reports">
          <DisputeFilesCategory />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/files/hearing">
          <HearingFiles />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/files/arbitrator/hearing"
        >
          <HearingFiles />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/files/arbitrator"
        >
          <ArbitratorFiles />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/dispute-assignments/wait"
        >
          <DisputeAssignment.Wait />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/dispute-assignments/accept"
        >
          <DisputeAssignment.Accept />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/dispute-assignments/respond"
        >
          <DisputeAssignment.Respond />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/dispute-assignment"
        >
          <DisputeAssignment />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/edit-profile">
          <Disputes.ProfileEdit />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/invite-defence">
          <ResponseFiling.InviteDefence />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/response-filing/:disputeId/intro">
          <ResponseFiling.Intro />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/response-filing/:disputeId">
          <ResponseFiling.Show />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/evidence-requests"
        >
          <EvidenceRequest.Index />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/evidence-requests/:evidenceRequestId"
        >
          <EvidenceRequest.Show />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/statement-of-defence"
        >
          <DefensiveActions.SOD />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/counterclaim">
          <DefensiveActions.Counterclaim />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/reply-to-counterclaim"
        >
          <DefensiveActions.ReplyToCounterclaim />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/hearings">
          <Hearings.Index />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/hearings/:hearingId/memorandums/:memorandumId"
        >
          <Hearings.ShowMemorandum />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/hearings/:hearingId/memorandums"
        >
          <Hearings.IndexMemorandum />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/hearings/:hearingId/minutes"
        >
          <Hearings.ShowMinutes />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/tor">
          <TOR.Index />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/tor/:torId">
          <TOR.Show />
        </PrivateRoute>

        {/* Arbitral Award */}

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/arbitral-awards"
        >
          <ArbitralAwards.Index />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/arbitral-awards/:awardId"
        >
          <ArbitralAwards.Show />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/arbitral-award-download"
        >
          <ArbitralAwards.Download />
        </PrivateRoute>

        {/* Procedural Order */}

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/procedural-orders"
        >
          <ProceduralOrder.Index />
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/:hubSlug/disputes/:disputeId/procedural-orders/:orderId"
        >
          <ProceduralOrder.Show />
        </PrivateRoute>

        <PrivateRoute exact path="/:hubSlug/disputes/:disputeId/history">
          <History />
        </PrivateRoute>

        <Route path="*">
          <Translation text="general.page_not_found.text" />
        </Route>
      </Switch>
    </section>
  );
}

export default Routes;
