import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getRosterProfile,
  putUpdateRosterProfile,
} from "../../redux/actions/hubActions";
import { selectRosterProfile } from "../../redux/selectors/hubsSelector";
import Spinner from "../Common/Spinner";
import ShowRender from "./ShowRender";

export default function ShowHOC() {
  const dispatch = useDispatch();
  const { arbitratorId } = useParams();
  const [isUpdateStatusVisible, setIsUpdateStatusVisible] = useState(false);
  const arbitrator = useSelector(selectRosterProfile);

  const handleReviewProfile = (body, resetForm) => {
    void dispatch(
      putUpdateRosterProfile(arbitratorId, body, () => {
        setIsUpdateStatusVisible(false);
        resetForm();
      })
    );
  };

  useEffect(() => {
    dispatch(getRosterProfile(arbitratorId));
  }, [dispatch, arbitratorId]);

  return arbitrator ? (
    <ShowRender
      profile={arbitrator?.attributes}
      isUpdateStatusVisible={isUpdateStatusVisible}
      setIsUpdateStatusVisible={setIsUpdateStatusVisible}
      onReviewProfile={handleReviewProfile}
    />
  ) : (
    <Spinner />
  );
}
