import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/billingDetailActions";
import * as apis from "../apis/billingDetailsApi";
import * as types from "../constants/constants";

const tag = "billing-details";

export function* getShow(action) {
  const { disputeId } = action.payload;
  try {
    const res = yield call(apis.show, disputeId);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdate(action) {
  const { disputeId, body } = action.payload;
  try {
    const res = yield call(apis.update, disputeId, body);
    yield put(actions.handleUpdate(res));
    yield put(actions.getShow(disputeId));
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* () {
  yield takeLatest(types.BILLINGDETAILS_GET_SHOW, getShow);
  yield takeLatest(types.BILLINGDETAILS_PUT_UPDATE, putUpdate);
}
