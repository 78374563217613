import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShowSoc } from "../../redux/actions/disputeFilingActions";
import * as selectors from "../../redux/selectors/disputeFilingSelector";
import SocRender from "./SocRender";

export default function () {
  const disputeId = useSelector(selectors.selectDisputeId);
  const soc = useSelector(selectors.selectSoc);
  const dispatch = useDispatch();

  useEffect(() => void dispatch(getShowSoc(disputeId)), [disputeId, dispatch]);

  return <SocRender fileUrl={soc.fileUrl} />;
}
