import * as types from "../constants/constants";

const initial = { events: [], isFetching: false };

export default function (state = initial, action) {
  switch (action.type) {
    case types.TIMELINE_GET_SHOW:
      return { ...state, isFetching: true };
    case types.TIMELINE_HANDLE_SHOW:
      return { ...state, events: action.payload?.data, isFetching: false };
    case types.TIMELINE_HANDLE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
