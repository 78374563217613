import { Card, Radio, Typography } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Translation from "../Common/Translation";
import AcceptHOC from "./AcceptHOC";
import ArbitratorRejectForm from "./ArbitratorRejectForm";

export default function ArbitratorResponse({ disputeParams }) {
  const { search } = useLocation();
  const state = new URLSearchParams(search).get("state");
  const [responseBoolean, setResponseBoolean] = useState(
    state === "SOASigned" ? ACCEPT : null
  );

  return (
    <Card>
      <ResponseRadio {...{ responseBoolean, setResponseBoolean }} />
      {responseBoolean === ACCEPT ? <AcceptHOC /> : null}
      {responseBoolean === REJECT ? (
        <ArbitratorRejectForm disputeParams={disputeParams} />
      ) : null}
    </Card>
  );
}

const ResponseRadio = ({ responseBoolean, setResponseBoolean }) => (
  <>
    <Typography.Title level={5}>
      <Translation text="arbitrator.would_you_accept_or_reject.text" />
    </Typography.Title>
    <Radio.Group
      onChange={(e) => setResponseBoolean(e.target.value)}
      value={responseBoolean}
    >
      <Radio value={ACCEPT}>
        <Translation text="arbitrator.accept.text" />
      </Radio>
      <Radio value={REJECT}>
        <Translation text="arbitrator.refuse.text" />
      </Radio>
    </Radio.Group>
  </>
);

const ACCEPT = "Accept";
const REJECT = "Refuse";
