import React from "react";
import { Row, Col, Card, Typography, Space } from "antd";
import { initialsOf } from "../../helpers/dataHelpers";
import Translation from "../Common/Translation";
import DateTime from "../Common/DateTime";
import Viewer from "../Common/Editor/Viewer";

const ShowPoReview = ({ rejectedPO }) => {
  const initials = initialsOf(rejectedPO?.attributes?.issuedBy);

  const senderDetails = () => {
    return (
      <Row gutter={[0, 16]} justify="space-between">
        <Col>
          <span className="jur-navbar-user-icon">{initials}</span>
          {rejectedPO?.attributes?.issuedBy}
        </Col>
        <Col>
          <DateTime epochSeconds={rejectedPO?.attributes?.file?.uploadedAt} onlyDate={true} />
        </Col>
      </Row>
    );
  };

  const PoReview = () => (
    <Row gutter={[0, 16]}>
      <Col span={24}>{senderDetails()}</Col>
      <Col>
        <Viewer value={rejectedPO?.attributes?.reasonForRejection} />
      </Col>
    </Row>
  );

  return initials && rejectedPO?.attributes?.reasonForRejection ? (
    <Card>
      <Typography.Title level={3}>{rejectedPO?.attributes?.file?.name}</Typography.Title>
      <Space direction="vertical" size="large">
        <Typography.Text className="secondary-color">
          <Translation text="terms_of_reference.reviews.button" />
        </Typography.Text>
        <PoReview />
      </Space>
    </Card>
  ) : null;
};

export default ShowPoReview;
