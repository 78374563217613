import { Button, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { readEmail } from "../../helpers/authHelpers";
import {
  logoutUser,
  postAcceptInvite,
} from "../../redux/actions/authenticateActions";
import { authLoading, selectErrors } from "../../redux/selectors/authSelector";
import Spinner from "../Common/Spinner";

export default function () {
  const { token } = useParams();
  const email = readEmail();
  const dispatch = useDispatch();
  const errors = useSelector(selectErrors);
  const isLoading = useSelector(authLoading);

  useEffect(() => void dispatch(postAcceptInvite({ email, token })), [
    token,
    email,
    dispatch,
  ]);

  const LogoutAndContinue = () => {
    return (
      <div className="jur-auth-retry-wrapper">
        <div>
          <img
            className="jur-auth-retry-illustration"
            src={require("../../assets/illustrations/exit.png")}
            alt="logo"
          />
          <Typography.Title
            className="h3-medium jur-auth-retry-heading"
            level={3}
          >
            Please try again.
          </Typography.Title>
          <Typography.Title className="jur-auth-retry-subheading" level={5}>
            You may have to logout
          </Typography.Title>
          <Button
            size="large"
            type="primary"
            className="jur-auth-retry-button"
            onClick={() => {
              dispatch(logoutUser({ isRedirectRequired: false }));
            }}
          >
            {"Logout & Continue"}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="jur-auth-retry-wrapper">
      {isLoading ? (
        <Spinner tip="Accepting Invite..." />
      ) : errors?.token ? (
        <LogoutAndContinue />
      ) : null}
    </div>
  );
}
