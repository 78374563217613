import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAccess } from "../../helpers/accessHelpers";
import { requestDisplayName } from "../../helpers/dataHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import * as actions from "../../redux/actions/evidenceRequestActions";
import * as fileActions from "../../redux/actions/fileActions";
import {
  selectEvidenceRequest,
  whole,
} from "../../redux/selectors/evidenceRequestSelector";
import { selectFile } from "../../redux/selectors/filesSelector";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import ShowRender from "./ShowRender";

export default function () {
  const hubSlug = useHubSlug();
  const [disputeId, dispute] = useDispute();

  const canUpdateRequest = useAccess("update", "evidence-request");
  const { evidenceRequestId } = useParams();
  const dispatch = useDispatch();
  const evidenceRequest = useSelector(selectEvidenceRequest);
  const { isSendDocumentsVisible, isLoading } = useSelector(whole);
  const file = useSelector(selectFile);
  const fileId = evidenceRequest?.attributes?.file?.id;
  const hasFile = Boolean(fileId);
  const canUpload =
    evidenceRequest?.attributes?.status === "Requested" &&
    evidenceRequest?.attributes?.canUpload &&
    canUpdateRequest;

  useEffect(() => {
    if (fileId) dispatch(fileActions.getInlineShow(fileId));
  }, [fileId, dispatch]);

  const openSendDocuments = () => {
    dispatch(actions.handleIsSendDocumentsOpen(true));
  };

  const closeSendDocuments = () => {
    dispatch(actions.handleIsSendDocumentsOpen(false));
  };

  const handleSendDocuments = (id) => {
    dispatch(
      actions.putUpdate(disputeId, evidenceRequestId, { fileId: id }, true)
    );
  };

  useEffect(
    () => void dispatch(actions.getShow(disputeId, evidenceRequestId)),
    [disputeId, evidenceRequestId, dispatch]
  );

  const deleteFile = (id) => void dispatch(fileActions.deleteFile(id));

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/evidence-requests`,
      breadcrumbName: (
        <Translation text="evidence_request.evidence_request.title" />
      ),
    },
    {
      path: `/${hubSlug}/disputes/${disputeId}/evidence-requests/${evidenceRequestId}`,
      breadcrumbName: requestDisplayName(evidenceRequest),
    },
  ];

  return evidenceRequest ? (
    <ShowRender
      evidenceRequest={evidenceRequest}
      dispute={dispute}
      disputeId={disputeId}
      hubSlug={hubSlug}
      extraCrumbs={extraCrumbs}
      isSendDocumentsVisible={isSendDocumentsVisible}
      openSendDocuments={openSendDocuments}
      closeSendDocuments={closeSendDocuments}
      handleSendDocuments={handleSendDocuments}
      canUpdateRequest={canUpdateRequest}
      hasFile={hasFile}
      file={file}
      isLoading={isLoading}
      canUpload={canUpload}
      redirectUrl={`${hubSlug}/disputes/${disputeId}/evidence-requests`}
      deleteFile={deleteFile}
    />
  ) : (
    <Spinner />
  );
}
