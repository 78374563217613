import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { breadcrumbRender } from "../../helpers/routeHelpers";
import * as actions from "../../redux/actions/disputeAssignmentActions";
import { current } from "../../redux/selectors/disputeAssignmentSelectors";
import Spinner from "../Common/Spinner";
import WaitRender from "./WaitRender";

export default function () {
  const hubSlug = useHubSlug();
  const [disputeId, dispute] = useDispute();
  const disputeAssignment = useSelector(current);
  const dispatch = useDispatch();

  useEffect(() => void dispatch(actions.getShow(disputeId, "latest")), [
    dispatch,
    disputeId,
  ]);

  return dispute && disputeAssignment ? (
    <WaitRender
      dispute={dispute}
      disputeAssignment={disputeAssignment}
      breadcrumb={crumbs(hubSlug, disputeId, dispute.name)}
    />
  ) : (
    <Spinner />
  );
}

function crumbs(hubSlug, disputeId, disputeName) {
  return {
    routes: [
      {
        path: `/`,
        breadcrumbName: "Dashboard",
      },
      {
        path: `/${hubSlug}/disputes/${disputeId}`,
        breadcrumbName: disputeName,
      },
      {
        path: `/${hubSlug}/disputes/${disputeId}/dispute-assignments/wait`,
        breadcrumbName: "Awaiting",
      },
    ],
    itemRender: breadcrumbRender,
  };
}
