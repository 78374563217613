import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getShowRespondent,
  handleUpdateRespondentForm,
} from "../../redux/actions/responseFilingActions";
import {
  selectErrors,
  selectIsErrorHidden,
  selectRespondent,
} from "../../redux/selectors/responseFilingSelector";
import Spinner from "../Common/Spinner";
import RespondentRender from "./RespondentRender";

function RespondentHOC() {
  const dispatch = useDispatch();

  const { disputeId } = useParams();
  const respondent = useSelector(selectRespondent);
  const isErrorHidden = useSelector(selectIsErrorHidden);
  const errors = useSelector(selectErrors);

  const errorProps = {
    isHidden: isErrorHidden,
  };

  const handleUpdateForm = (values) =>
    void dispatch(handleUpdateRespondentForm(values));

  useEffect(() => void dispatch(getShowRespondent(disputeId)), [
    disputeId,
    dispatch,
  ]);
  return respondent ? (
    <RespondentRender
      respondent={respondent}
      errorProps={errorProps}
      errors={errors}
      onUpdateForm={handleUpdateForm}
    />
  ) : (
    <Spinner card={true} />
  );
}

export default RespondentHOC;
