import * as types from "../constants/constants";

const initial = {
  results: [],
  isLoadingResults: false,
  current: null,
  isLoading: true,
  index: [],
  isSubmitting: false,
};

export default function (state = initial, action) {
  switch (action.type) {
    case types.DISPUTEASSIGNMENT_HANDLE_INDEX:
      return { ...state, index: action.payload, isLoading: false };
    case types.DISPUTEASSIGNMENT_POST_CREATE:
      return { ...state, isSubmitting: true };
    case types.DISPUTEASSIGNMENT_HANDLE_CREATE:
      return { ...state, isSubmitting: false };
    case types.DISPUTEASSIGNMENT_HANDLE_ERROR:
      return {
        ...state,
        error: action.error,
        isSubmitting: false,
        isLoading: false,
      };
    case types.DISPUTEASSIGNMENT_HANDLE_RESET:
      return initial;
    case types.DISPUTEASSIGNMENT_HANDLE_SHOW:
      return {
        ...state,
        current: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
      };
    case types.DISPUTEASSIGNMENT_PUT_UPDATE_FILE:
      return { ...state, isLoading: true };
    case types.DISPUTEASSIGNMENT_GET_SHOW:
      return { ...state, isLoading: true };
    case types.DISPUTEASSIGNMENT_GET_INDEX_ARBITRATORS:
    case types.DISPUTEASSIGNMENT_GET_INDEX_EXPERTS:
      return { ...state, isLoadingResults: true, isLoading: true };
    case types.DISPUTEASSIGNMENT_HANDLE_RESULT:
      return {
        ...state,
        results: action.payload.data,
        isLoadingResults: false,
      };
    default:
      return state;
  }
}
