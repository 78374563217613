import { Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Radio, Select } from "formik-antd";
import React from "react";
import { errorAsExtra } from "../../helpers/errorHelpers";
import { TipText } from "../../helpers/formHelpers";
import Editor from "../Common/Editor";
import { QuestionHelpText, QuestionTitle } from "../Common/FormQuestion";
import JurLink from "../Common/JurLink";
import Translation from "../Common/Translation";
import InputFormatter from "../Common/InputFormatter";
import { currencyFormatter } from "../../helpers/dataHelpers";
import { handleChange } from "../DisputeFiling/helpers";

function optionsFrom(list) {
  return list?.map((entity, index) => (
    <Select.Option key={index} value={entity}>
      {entity}
    </Select.Option>
  ));
}

const selectProps = {
  optionFilterProp: "children",
  showSearch: true,
  filterOption: (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  style: { width: "100%" },
};

export default function ({
  onUpdate,
  response = {},
  seatsOfArbitration,
  applicableLaws,
  errors,
  errorProps,
  claimantDetails,
  currency,
}) {
  return (
    <Formik initialValues={response} errors={errors}>
      {({ values, setFieldValue, errors: formikErrors }) => {
        const handleChangeOf = handleChange(values, setFieldValue, onUpdate);
        const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);

        const richTextFormItem = ({ field }) => (
          <FormItem
            {...apiErrorOf(field.name, true)}
            label={
              <Typography.Title level={5} className="primary-color">
                {field.question}
              </Typography.Title>
            }
            name={field.name}
            required={field.required}
          >
            <QuestionHelpText>{field.helpText}</QuestionHelpText>
            <Editor
              // placeholder="Describe in 300 words only"
              onChange={handleChangeOf(field.name)}
              defaultValue={values[field.name]}
            />
          </FormItem>
        );

        return (
          <Form
            className="jur-response-filing-content-wrapper"
            layout="vertical"
          >
            {responseDropdowns(
              claimantDetails,
              seatsOfArbitration,
              applicableLaws
            ).map((field) => (
              <FormItem
                required={!field.isOptional}
                {...apiErrorOf(field.name, true)}
                label={
                  <Typography.Title level={5} className="primary-color">
                    {field.question}
                  </Typography.Title>
                }
                name={field.name}
              >
                <QuestionHelpText>{field.helpText}</QuestionHelpText>
                <Select
                  placeholder="Select"
                  name={field.name}
                  onSelect={handleChangeOf(field.name)}
                  {...selectProps}
                >
                  {optionsFrom(field.options)}
                </Select>
              </FormItem>
            ))}
            {responseTextFields.map((field) => {
              return <>{richTextFormItem({ field })}</>;
            })}
            <FormItem
              required={true}
              {...apiErrorOf("isCounterClaimFiled", true)}
              label={
                <QuestionTitle text="response_filing.do_you_want_file_any_counterclaims.text" />
              }
              name={"isCounterClaimFiled"}
            >
              <QuestionHelpText>
                <TipText
                  text="response_filing.do_you_want_file_any_counterclaims.description"
                  moreDetailsLink={"/faq"}
                />
              </QuestionHelpText>
              <Radio.Group
                className="primary-color"
                name="isCounterClaimFiled"
                options={counterclaimOptions()}
              />
            </FormItem>
            {Boolean(values?.isCounterClaimFiled) && (
              <>
                {counterclaimTextFields.map((field) => {
                  return <>{richTextFormItem({ field })}</>;
                })}
                <InputFormatter
                    prefix="USD"
                    name="claimValue"
                    errors={errors}
                    label="response_filing.counterclaim_value.text"
                    defaultValue={values?.claimValue}
                    onChange={handleChangeOf("claimValue")}
                    formatter={(value) => currencyFormatter(value)}
                  />
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

const responseDropdowns = (
  { name, seatsOfArbitration, applicableLaw },
  seatsOfArbitrationList,
  applicableLawsList
) => [
  {
    question: (
      <>
        {`${name} prefers ${seatsOfArbitration} as the seat of Arbitration.`}{" "}
        Select your preference.
        <span className="jur-auth-required-asterisk">*</span>
      </>
    ),
    helpText: (
      <>
        <JurLink to="/faq">
          <Translation text="claimant.how_to_select_seat_of_arbitration.text" />
        </JurLink>
      </>
    ),
    isOptional: true,
    name: "seatOfArbitration",
    options: seatsOfArbitrationList,
  },
  {
    question: (
      <>
        {` ${name} prefers the state of ${applicableLaw}`} as the applicable
        law. Select your preference.
        <span className="jur-auth-required-asterisk">*</span>
      </>
    ),
    helpText: (
      <>
        <JurLink to="/faq">
          <Translation text="claimant.how_to_select_the_applicable_law.text" />
        </JurLink>
      </>
    ),
    isOptional: true,
    name: "applicableLaw",
    options: applicableLawsList,
  },
];

const responseTextFields = [
  {
    question: (
      <Translation text="response_filing.subject_matter_short_indication.text" />
    ),
    helpText: (
      <TipText
        text="response_filing.subject_matter_short_indication.description"
        sampleLink="/faq"
      />
    ),
    name: "subjectMatter",
    required: true,
  },
  // {
  //   question: (
  //     <Translation text="response_filing.claim_short_indication.text" />
  //   ),
  //   helpText: (
  //     <TipText
  //       text="response_filing.claim_short_indication.description"
  //       sampleLink="/faq"
  //     />
  //   ),
  //   name: "claim",
  // },
];

const counterclaimTextFields = [
  {
    question: (
      <Translation text="response_filing.counterclaim_indication.text" />
    ),
    helpText: (
      <>
        <TipText text="response_filing.counterclaim_indication.description" />
        {". "}
      </>
    ),
    required: true,
    name: "shortDescription",
  },
  {
    question: (
      <Translation text="response_filing.counterclaim_nature_of_circumstances.text" />
    ),
    helpText: (
      <TipText
        text="response_filing.counterclaim_nature_of_circumstances.description"
        sampleLink="/faq"
      />
    ),
    required: true,
    name: "natureDescription",
  },
  {
    question: (
      <Translation text="response_filing.counterclaim_statement_of_relief_sought.text" />
    ),
    required: true,
    name: "reliefSought",
  },
];

function counterclaimOptions() {
  return [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
}
