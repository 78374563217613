import { logAndThrow } from "../../helpers/apiHelpers";
import { hubless } from "./Api";

const tag = "auth";
const catcher = logAndThrow(tag);

export function currentUser() {
  return hubless
    .get(`/auth/me`)
    .then((res) => res?.data)
    .catch(catcher("currentUser", "Failed to login"));
}

export function createLogin(body) {
  return hubless
    .post(`/auth/login`, body)
    .catch(catcher("createLogin", "Failed to login"));
}

export function forgotPassword(body) {
  return hubless
    .post(`/auth/password/email`, body)
    .catch(
      catcher("forgotPassword", "Failed to complete forgot password request")
    );
}

export function resetPassword(body) {
  return hubless
    .post(`/auth/password/reset`, body)
    .catch(catcher("resetPassword", "Failed to reset password"));
}

export function createRegister(body) {
  return hubless
    .post("/auth/register", body)
    .catch(catcher("createRegister", "Failed to sign up"));
}

export function createResendRegister(body) {
  return hubless
    .post("/auth/resend-confirmation", body)
    .catch(catcher("createResendRegister", "Failed to resend email"));
}

export function activate(body) {
  return hubless
    .post("/auth/activate", body)
    .then((res) => res?.data)
    .catch(catcher("activate", "Failed to activate"));
}

export function acceptInvite(token) {
  return hubless
    .post(`/auth/accept-invite/${token}`)
    .catch(catcher("acceptInvite", "Failed to accept invite"));
}
