import { Alert, Button, Modal, Space, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React from "react";
import { errorAsExtra, useErrorHider } from "../../helpers/errorHelpers";
import { modalProps } from "../../helpers/modalHelpers";
import Editor from "../Common/Editor/Editor";
import ModalWrapper from "../Common/ModalWrapper";
import Translation from "../Common/Translation";
import Uploader, { UploadButtonRender } from "../Common/Uploader";
import { handleChange } from "../DisputeFiling/helpers";

export default function UploadOrderForm({
  visible,
  participants,
  handleUploadOrderSubmit,
  handleUploadOrderClose,
  errors,
}) {
  const errorProps = useErrorHider();
  const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);
  return (
    <Formik initialValues={{}}>
      {({ values, setFieldValue, resetForm }) => {
        const handler = handleChange(values, setFieldValue);
        const uploaderProps = {
          allowDocumentOnly: true,
          maxCount: 1,
          name: "proceduralOrderUploader",
          multiple: false,
          fileCategory: "Procedural Order",
          onRemove: () => {
            setFieldValue("fileId", null);
            setFieldValue("file", null);
          },
          onDone: (file) => {
            setFieldValue("fileId", file?.id);
            setFieldValue("file", file);
          },
        };
        return (
          <Modal
            width={768}
            {...modalProps()}
            visible={visible}
            maskClosable={false}
            destroyOnClose={true}
            title={
              <Typography.Title level={5}>
                <Translation text="procedural_order.upload_order.title" />
              </Typography.Title>
            }
            onCancel={handleUploadOrderClose}
            footer={[
              <Button
                key="submit-order"
                type="primary"
                disabled={!values?.fileId || !values?.highlights?.length}
                onClick={() => {
                  handleUploadOrderSubmit(values, resetForm);
                }}
              >
                <Translation text="procedural_order.upload_order.button" />
              </Button>,
            ]}
          >
            <ModalWrapper>
              <Form layout="vertical">
                <Space direction="vertical">
                  <Alert
                    type="info"
                    banner={true}
                    message={<Translation text="procedural_order.upload_order.description" />}
                  />
                  <FormItem name="file" {...apiErrorOf("fileId", true)}>
                    <Uploader {...uploaderProps}>
                      <UploadButtonRender
                        optional={true}
                        label="general.choose_file.button"
                        buttonProps={{ disabled: Boolean(values?.file) }}
                      />
                    </Uploader>
                  </FormItem>
                  <FormItem
                    name="highlights"
                    required
                    {...apiErrorOf("highlights", true)}
                    label={<Translation text="procedural_order.highlights.description" />}
                  >
                    <Editor onChange={handler("highlights")} className="jur-quill-editor-small" />
                  </FormItem>
                </Space>
              </Form>
            </ModalWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
}

// function participantsOptions(list) {
//   return list?.map((user, index) => {
//     return (
//       <Select.Option key={index} value={user?.disputeUserId}>
//         {user?.fullName} ({user?.userGroup} - {user?.role})
//       </Select.Option>
//     );
//   });
// }
