import React from "react";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import RespondRender from "./RespondRender";

function RespondHOC() {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();

  return <RespondRender disputeParams={{ disputeId, dispute, hubSlug }} />;
}

export default RespondHOC;
