import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import {
  getIndexMemorandum,
  putUpdateMemorandum,
} from "../../redux/actions/hearingActions";
import {
  indexMemorandum,
  indexMetaMemorandum,
} from "../../redux/selectors/hearingsSelector";
import { fromMeta } from "../../transformers/paginationTransformer";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import IndexMemorandumRender from "./IndexMemorandumRender";
import { deleteFile } from "../../redux/actions/fileActions";

export default function () {
  const hubSlug = useHubSlug();
  const [disputeId, dispute] = useDispute();
  const { hearingId } = useParams();
  const memos = useSelector(indexMemorandum);
  const memosMeta = useSelector(indexMetaMemorandum);
  const canUpload = memosMeta?.allowMemorandum;
  const dispatch = useDispatch();
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [fileID, setFileID] = useState(false);

  useEffect(() => void dispatch(getIndexMemorandum(disputeId, hearingId)), [
    disputeId,
    hearingId,
    dispatch,
  ]);

  const uiRedirectUrl = `/${hubSlug}/disputes/${disputeId}/hearings/${hearingId}/memorandums`;

  const handleUpload = () => {
    dispatch(putUpdateMemorandum(disputeId, hubSlug, hearingId, fileID));
  };

  const handleDelete = () => {
    if (fileID) {
      dispatch(deleteFile(fileID));
      setFileID(null);
    }
  };

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/hearings`,
      breadcrumbName: <Translation text="hearings.hearings.title" />,
    },
    {
      path: `/${hubSlug}/disputes/${disputeId}/hearings/${hearingId}/memorandums`,
      breadcrumbName: <Translation text="hearings.memorandums.title" />,
    },
  ];

  return dispute && memos ? (
    <IndexMemorandumRender
      dispute={dispute}
      disputeId={disputeId}
      hubSlug={hubSlug}
      extraCrumbs={extraCrumbs}
      memorandums={memos}
      pagination={fromMeta(memosMeta)}
      canUpload={canUpload}
      onUpload={handleUpload}
      hearingId={hearingId}
      uiRedirectUrl={uiRedirectUrl}
      isUploadOpen={isUploadOpen}
      setIsUploadOpen={setIsUploadOpen}
      fileID={fileID}
      setFileID={setFileID}
      handleDelete={handleDelete}
    />
  ) : (
    <Spinner />
  );
}
