import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { getShow } from "../../redux/actions/disputeActions";
import { getIndex } from "../../redux/actions/disputeFileActions";
import { selectIndex } from "../../redux/selectors/disputeFilesSelector";
import PageHeader from "../Common/PageHeader";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import CaseFilesTableOverview from "./TableOverview";
import CaseFilesBreadcrumb from "../Common/CaseFilesBreadcrumb";

function Respondent() {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();
  const dispatch = useDispatch();
  const disputeFilesData = useSelector(selectIndex);

  useEffect(() => {
    if (!dispute) dispatch(getShow(disputeId));
    else {
      dispatch(getIndex(disputeId, 1, "Respondent"));
    }
  }, [dispatch, disputeId, dispute]);

  return (
    <div>
      <SliderMenuWrapper {...{ dispute, disputeId, hubSlug }}>
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={24}>
            <CaseFilesBreadcrumb />
            <PageHeader title="general.respondent.title" />
          </Col>
          <Col className="gutter-row" span={24}>
            <CaseFilesTableOverview
              data={disputeFilesData}
              whiteHeader={true}
              isLoading={!disputeFilesData}
            />
          </Col>
        </Row>
      </SliderMenuWrapper>
    </div>
  );
}

export default Respondent;
