import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readLoggedInUser } from "../../helpers/authHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { putUpdateTaskStatus } from "../../redux/actions/assistedDisputeActions";
import { getShow } from "../../redux/actions/disputeActions";
import * as selectors from "../../redux/selectors/disputesSelector";
import Spinner from "../Common/Spinner";
import ShowRender from "./ShowRender";

export default function () {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();

  const disputeMeta = useSelector(selectors.selectDisputeMeta);
  const userName = readLoggedInUser().name;

  const { isShowLoading } = useSelector(selectors.whole);
  const dispatch = useDispatch();

  const handleUpdateTaskStatus = (taskId, isComplete, onSuccess) =>
    void dispatch(
      putUpdateTaskStatus(
        disputeId,
        taskId,
        isComplete ? "Completed" : "Pending",
        onSuccess
      )
    );

  useEffect(() => void dispatch(getShow(disputeId)), [disputeId, dispatch]);

  if (isShowLoading && !dispute) return <Spinner />;
  return (
    <ShowRender
      disputeParams={{
        dispute,
        disputeId,
        hubSlug,
      }}
      disputeMeta={disputeMeta}
      userName={userName}
      onUpdateTaskStatus={handleUpdateTaskStatus}
    />
  );
}
