import { Button, Modal, Space, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem, Select } from "formik-antd";
import React from "react";
import Translation from "../Common/Translation";
import { modalProps } from "../../helpers/modalHelpers";

export default function ShareOrderForm({
  orders,
  visible,
  handleShareOrderSubmit,
  handleShareOrderClose,
  selectedOrder = null,
}) {
  return (
    <Formik initialValues={{ orderId: selectedOrder }}>
      {({ values, setFieldValue, resetForm }) => {
        return (
          <Modal
            visible={visible}
            {...modalProps()}
            maskClosable={false}
            destroyOnClose={true}
            title={
              <Typography.Title level={5}>
                <Translation text="procedural_order.share_order.title" />
              </Typography.Title>
            }
            onCancel={handleShareOrderClose}
            footer={[
              <Button
                disabled={!Boolean(values?.orderId)}
                // loading={isLoading}
                type="primary"
                onClick={() => {
                  handleShareOrderSubmit(
                    selectedOrder
                      ? selectedOrder
                      : orders.find((o) => o.id === values?.orderId),
                    resetForm
                  );
                }}
              >
                <Translation text="procedural_order.sign_and_send.button" />
              </Button>,
            ]}
          >
            <Form layout="vertical">
              <Space direction="vertical">
                {!selectedOrder && (
                  <Typography.Text>
                    <Translation text="procedural_order.share_order.description" />
                  </Typography.Text>
                )}
                {selectedOrder ? (
                  <FormItem name="orderId">Order</FormItem>
                ) : (
                  <FormItem
                    name="orderId"
                    label={
                      <Translation text="procedural_order.select_an_order.description" />
                    }
                  >
                    <Select name="orderId" className="jur-width-full">
                      {orders?.map((o) => {
                        return (
                          <Select.Option value={o?.id} key={o?.id}>
                            {"Procedural Order #"}
                            {o?.attributes?.number}
                            {" - Version "}
                            {o?.attributes?.version}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </FormItem>
                )}
              </Space>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
