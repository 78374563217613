import { push } from "connected-react-router";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  errorMessage,
  successMessage,
} from "../../components/Common/Feedback/Message";
import {
  isUserActive,
  removeTokenOnLogout,
  saveToken,
  setConsentAndActivation,
  storeLastKnownEmail,
} from "../../helpers/authHelpers";
import {
  authenticateUserFailed,
  authenticateUserSucceeded,
  forgotPasswordFailed,
  forgotPasswordSucceeded,
  handleCurrentUser,
  handleError,
  logoutUserFailed,
  logoutUserSucceeded,
  registerUserFailed,
  registerUserSucceeded,
  resetPasswordFailed,
  resetPasswordSucceeded,
} from "../actions/authenticateActions";
import * as signActions from "../actions/signActions";
import * as apis from "../apis/authApi";
import * as signApis from "../apis/signApi";
import * as types from "../constants/constants";
import { consentStatus } from "../selectors/authSelector";

function* getCurrentUser() {
  try {
    let response = yield call(apis.currentUser);
    yield put(handleCurrentUser(response));
  } catch (error) {
    yield put(handleError(error));
  }
}

function* authenticateUserSaga(action) {
  try {
    let response = yield call(apis.createLogin, action.payload);
    console.log(response);
    if (response.status < 300) {
      const storable = response.data.data;
      saveToken(storable);
      yield put(authenticateUserSucceeded(storable));
      if (isUserActive()) {
        yield put(push("/")); // TODO: Auto update role
      } else {
        yield put(push("/onboarding"));
      }
    } else {
      throw new Error("No user found");
    }
  } catch (error) {
    yield put(authenticateUserFailed(error));
  }
}

function* postAuthenticateUserSucceeded() {
  try {
    const currentStatus = yield select(consentStatus);
    if (!currentStatus) {
      const resConsent = yield call(signApis.consent, false);
      if (resConsent?.data?.attributes?.consentStatus) {
        yield put(signActions.updateConsentStatus());
        setConsentAndActivation();
      }
    }
  } catch (error) {
    console.log("error: ", error);
  }
}

function* logoutUserSaga(action) {
  const { isRedirectRequired = true } = action.payload;
  try {
    removeTokenOnLogout();
    if (isRedirectRequired) {
      yield put({ type: types.APP_HANDLE_RESET });
      yield put(push("/authenticate"));
    } else {
      window.location.reload();
    }
    yield put(logoutUserSucceeded());
  } catch (error) {
    console.log("error: ", error);
    yield put(logoutUserFailed(error));
  }
}

function* forgotPasswordSaga(action) {
  try {
    let response = yield call(apis.forgotPassword, action.payload);
    console.log("data: ", response.data);
    if (response.status < 300) {
      successMessage("authentication.reset_password_email_sent.text");
      storeLastKnownEmail(action.payload.email);
      yield put(forgotPasswordSucceeded(response.data.data));
      yield put(push("/forgot-password/confirmation"));
    }
  } catch (error) {
    errorMessage("general.something_went_wrong_please_try_again.text");
    yield put(forgotPasswordFailed(error));
  }
}

function* registerUserSaga(action) {
  try {
    let response = yield call(apis.createRegister, action.payload);

    if (response.status < 300) {
      storeLastKnownEmail(action.payload.email);
      yield put(registerUserSucceeded());
      yield put(push("/confimation"));
    }
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

function* postResendRegister(action) {
  try {
    yield call(apis.createResendRegister, action.payload);
    successMessage("authentication.email_sent.text");
  } catch (error) {
    yield put(handleError(error));
  }
}

function* resetPasswordSaga(action) {
  try {
    console.log(action.payload);
    let response = yield call(apis.resetPassword, action.payload);
    if (response.status < 300) {
      yield put(resetPasswordSucceeded());
      successMessage("authentication.password_has_been_reset.text");
      yield put(push("/authenticate"));
    }
  } catch (error) {
    yield put(resetPasswordFailed(error));
  }
}

export function* postActivate(action) {
  try {
    let response = yield call(apis.activate, action.payload);
    successMessage("authentication.account_activated.text");
    if (Boolean(response?.passwordSet)) {
      yield put(push("/"));
    } else {
      yield put(push(`/users/reset-password/${response?.resetPasswordToken}`));
    }
  } catch (e) {
    yield put(handleError(e));
  }
}

export function* postAcceptInvite(action) {
  try {
    const { data } = yield call(apis.acceptInvite, action.payload.token);
    successMessage("authentication.invite_accepted.text");

    yield put(
      push(
        data.passwordSet
          ? "/"
          : `/users/reset-password/${data.resetPasswordToken}`
      )
    );
  } catch (e) {
    yield put(handleError(e));
  }
}

export default function* authenticateSaga() {
  yield takeLatest(types.AUTH_POST_ACCEPT_INVITE, postAcceptInvite);
  yield takeLatest(
    types.AUTHENTICATE_USER_SUCCEEDED,
    postAuthenticateUserSucceeded
  );
  yield takeLatest(types.AUTH_POST_RESEND_REGISTER, postResendRegister);
  yield takeLatest(types.AUTHENTICATE_USER, authenticateUserSaga);
  yield takeLatest(types.LOGOUT_USER, logoutUserSaga);
  yield takeLatest(types.FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLatest(types.REGISTER_USER, registerUserSaga);
  yield takeLatest(types.RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(types.AUTH_POST_ACTIVATE, postActivate);
  yield takeLatest(types.AUTH_GET_CURRENT_USER, getCurrentUser);
}
