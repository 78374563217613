import {
  ControlOutlined,
  FolderOpenOutlined,
  FolderViewOutlined,
  MessageOutlined,
  VideoCameraOutlined
} from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { useAccess } from "../../helpers/accessHelpers";
import Footnote from "../Typography/Footnote";
import "./styles.less";
import Translation from "../Common/Translation";

export default function LeftSlider({ disputeId, hubSlug }) {
  const canViewPanel = useAccess("create", "panels");

  const history = useHistory();

  return (
    <div className="jur-dispute-show-left-slider">
      {menuSections(history, disputeId, hubSlug, canViewPanel).map(
        (section, index) => (
          <div key={index}>
            <Footnote strong className="primary-color">
              {section?.title}
            </Footnote>
            {section?.items.map((item, i) => {
              return (
                <div
                  key={i}
                  className="primary-color jur-dispute-show-left-slider-menu-item"
                  onClick={item.onClick}
                >
                  <div>{item.icon}</div>
                  <Typography.Text>{item.label}</Typography.Text>
                </div>
              );
            })}
          </div>
        )
      )}
      <div>
        <div
          className="primary-color jur-dispute-show-left-slider-menu-item"
          onClick={() => {}}
        >
          <div>
            <MessageOutlined />
          </div>
          <Typography.Text>{"Helpline"}</Typography.Text>
        </div>
      </div>
    </div>
  );
}

const menuSections = (history, disputeId, hubSlug, canViewPanel) => [
  // {
  //   title: "Arbitration Pad",
  //   items: [
  //     {
  //       label: "Overview",
  //       icon: <DashboardOutlined />,
  //       condition: true,
  //     },
  //     {
  //       label: "See More",
  //       icon: <EllipsisOutlined />,
  //       condition: true,
  //     },
  //   ],
  // },
  {
    title: "Quick Access",
    items: [
      {
        label:  <Translation text="case_files.case_files_overview.title" /> ,
        icon: <FolderOpenOutlined />,
        condition: true,
        onClick: () => history.push(`/${hubSlug}/disputes/${disputeId}/`),
      },
      {
        label: <Translation text="dispute.arbitration_panel.title" />  ,
        icon: <ControlOutlined />,
        condition: canViewPanel,
        onClick: () =>
          history.push(`/${hubSlug}/disputes/${disputeId}/arbitrator-panel`),
      },
      {
        label:  <Translation text="assisted_dispute.assisted_dispute_dashboard.title" /> ,
        icon: <FolderViewOutlined />,
        condition: canViewPanel,
        onClick: () =>
          history.push(`/${hubSlug}/disputes/${disputeId}/assisted-dispute`),
      },
      {
        label: <Translation text="hearings.meetings.title" /> ,
        icon: <VideoCameraOutlined />,
        condition: true,
        onClick: () =>
          history.push(`/${hubSlug}/disputes/${disputeId}/hearings`),
      },
      // {
      //   label: "Case Files",
      //   icon: <FolderOpenOutlined />,
      //   condition: true,
      // },
      // {
      //   label: "History",
      //   icon: <HistoryOutlined />,
      //   condition: true,
      // },
      // {
      //   label: "Payment",
      //   icon: <WalletOutlined />,
      //   condition: true,
      // },
    ].filter((i) => i.condition),
  },
];
