import { push } from "connected-react-router";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  errorMessage,
  successMessage,
} from "../../components/Common/Feedback/Message";
import { handleUpdateSlug } from "../actions/hubActions";
import * as actions from "../actions/proceduralOrderActions";
import * as timelineActions from "../actions/timelineActions";
import * as proceduralOrderApi from "../apis/proceduralOrderApi";
import { BaseService } from "../BaseService";
import * as types from "../constants/constants";

const tag = "proceduralOrder";
const proceduralOrderService = new BaseService("proceduralOrder");

const indexTLParams = (disputeId) => {
  return {
    activityId: disputeId,
    activityType: "Procedural Order Index",
  };
};

export function* postCreate(action) {
  const { disputeId, body, resetForm = () => {} } = action.payload;

  try {
    const res = yield call(proceduralOrderApi.create, disputeId, body);
    yield put(actions.handleCreate(res));
    yield put(proceduralOrderService.fetchIndex(disputeId));
    yield put(proceduralOrderService.setForm({ isUploadOrderOpen: false }));
    yield put(timelineActions.getShow(disputeId, indexTLParams(disputeId)));
    resetForm();
    successMessage("procedural_order.procedural_order_created.text");
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(proceduralOrderService.handleError(e));
  }
}

export function* putUpdate(action) {
  const { disputeId, body, resetForm = () => {} } = action.payload;
  try {
    const res = yield call(proceduralOrderApi.update, disputeId, body);
    yield put(actions.handleUpdate(res));
    yield put(proceduralOrderService.fetchIndex(disputeId));
    yield put(proceduralOrderService.fetchCurrent(disputeId, body?.orderId));
    yield put(proceduralOrderService.setForm({ isReviewOrderOpen: false }));
    yield put(timelineActions.getShow(disputeId, indexTLParams(disputeId)));
    yield put(actions.getFlowList(disputeId, "hub-actions"));
    resetForm();
    successMessage("procedural_order.procedural_order_updated.text");
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(proceduralOrderService.handleError(e));
  }
}

export function* postCreateRevision(action) {
  const { disputeId, body, resetForm = () => {} } = action.payload;

  try {
    const res = yield call(proceduralOrderApi.createRevision, disputeId, body);
    yield put(actions.handleCreate(res));
    yield put(proceduralOrderService.fetchIndex(disputeId));
    yield put(proceduralOrderService.fetchCurrent(disputeId, body?.orderId));
    yield put(
      proceduralOrderService.setForm({ isUploadRevisedOrderOpen: false })
    );
    yield put(timelineActions.getShow(disputeId, indexTLParams(disputeId)));
    yield put(actions.getFlowList(disputeId, "revisions"));
    resetForm();
    successMessage("procedural_order.procedural_order_updated.text");
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(proceduralOrderService.handleError(e));
  }
}

export function* getFlowList(action) {
  const { disputeId, flow } = action.payload;
  try {
    const res = yield call(proceduralOrderApi.flowList, disputeId, flow);
    res.data.flow = flow;
    yield put(actions.handleFlowList(res?.data));
  } catch (e) {
    console.error(tag, "saga getFlowList error:", e);
    yield put(proceduralOrderService.handleError(e));
  }
}

export function* getVersions(action) {
  const { disputeId, orderId } = action.payload;
  try {
    const res = yield call(proceduralOrderApi.versions, disputeId, orderId);
    yield put(actions.handleVersions(res?.data, orderId));
  } catch (e) {
    console.error(tag, "saga getVersions error:", e);
    yield put(proceduralOrderService.handleError(e));
  }
}

export function* putUpdateCompleted(action) {
  const { disputeId, hubSlug } = action.payload?.params?.meta;
  try {
    successMessage("procedural_order.procedural_order_updated.text");
    if (hubSlug && disputeId) {
      yield put(handleUpdateSlug(hubSlug));
      yield put(push(`/${hubSlug}/disputes/${disputeId}/procedural-orders`));
    } else yield put(push(`/`));
  } catch (e) {
    console.error(tag, `saga putUpdateCompleted error:`, e);
    yield put(proceduralOrderService.handleError(e));
  }
}

export function* handleSignatureError(action) {
  const { disputeId, hubSlug } = action.payload?.params;
  try {
    errorMessage("general.signature_error.text");
    if (hubSlug && disputeId) {
      yield put(handleUpdateSlug(hubSlug));
      yield put(push(`/${hubSlug}/disputes/${disputeId}/procedural-orders`));
    } else yield put(push(`/`));
  } catch (e) {
    console.error(tag, `saga putUpdateCompleted error:`, e);
    yield put(proceduralOrderService.handleError(e));
  }
}

export default function* proceduralOrderSaga() {
  yield takeEvery(types.PROCEDURALORDER_POST_CREATE, postCreate);
  yield takeEvery(types.PROCEDURALORDER_GET_VERSIONS, getVersions);
  yield takeEvery(
    types.PROCEDURALORDER_POST_CREATE_REVISION,
    postCreateRevision
  );
  yield takeEvery(types.PROCEDURALORDER_PUT_UPDATE, putUpdate);
  yield takeEvery(types.PROCEDURALORDER_GET_FLOW_LIST, getFlowList);
  yield takeEvery(
    types.PROCEDURALORDER_PUT_UPDATE_COMPLETED,
    putUpdateCompleted
  );
  yield takeEvery(
    types.PROCEDURALORDER_HANDLE_SIGNATURE_ERROR,
    handleSignatureError
  );
}
