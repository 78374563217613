import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useHubSlug } from "../../helpers/hubHelpers";
import * as actions from "../../redux/actions/disputeFilingActions";
import * as selectors from "../../redux/selectors/disputeFilingSelector";
import IndexRender from "./IndexRender";

export default function () {
  const history = useHistory();
  const currentStep = useSelector(selectors.selectCurrentStep);
  const userRole = useSelector(selectors.selectUserRole);
  const userInvites = useSelector(selectors.selectInvites);
  const { disputeId } = useParams();
  const claimant = useSelector(selectors.selectClaimant);
  const claim = useSelector(selectors.selectClaim);
  const files = useSelector(selectors.selectClaimFiles);
  const respondent = useSelector(selectors.selectRespondent);
  const soc = useSelector(selectors.selectSoc);
  const isNextDisabled = useSelector(selectors.selectIsNextDisabled);
  const dispatch = useDispatch();

  const hubSlug = useHubSlug({ noWarning: false, slug: "region-us" });

  const handleUpdateUserRole = (role) =>
    dispatch(actions.handleUpdateUserRole(role));

  const handleSaveAndBack = () => {
    handleSaveDraft({ goBack: true });
  };

  const handleDiscardAndBack = () => {
    dispatch(actions.handleStepBack());
  };

  const next = () => {
    console.log("onClick next", currentStep);
    const step = currentStep + 1; // Hubless Edit
    if (step === 0) dispatch(actions.handleStepHub());
    if (step === 1) dispatch(actions.putUpdateClaimant(disputeId, claimant));
    if (step === 2) dispatch(actions.putUpdateClaimDetails(disputeId, claim));
    if (step === 3) dispatch(actions.putUpdateClaimFiles(disputeId, files));
    if (step === 4)
      dispatch(actions.putUpdateAddRespondent(disputeId, respondent));
    if (step === 5) dispatch(actions.putUpdateInvites(disputeId, userInvites));
    if (step === 6) dispatch(actions.putUpdateSoc(disputeId, soc, hubSlug));
  };

  const handleSaveDraft = ({ goBack = false }) => {
    const step = currentStep + 1;
    if (step === 0) dispatch(actions.handleStepHub());
    if (step === 1)
      dispatch(
        actions.putUpdateClaimant(
          disputeId,
          addDraftKey(claimant),
          hubSlug,
          goBack
        )
      );
    if (step === 2)
      dispatch(
        actions.putUpdateClaimDetails(
          disputeId,
          addDraftKey(claim),
          hubSlug,
          goBack
        )
      );
    if (step === 3)
      dispatch(
        actions.putUpdateClaimFiles(
          disputeId,
          addDraftKey(files),
          hubSlug,
          goBack
        )
      );
    if (step === 4)
      dispatch(
        actions.putUpdateAddRespondent(
          disputeId,
          addDraftKey(respondent),
          hubSlug,
          goBack
        )
      );
    if (step === 5)
      dispatch(
        actions.putUpdateInvites(
          disputeId,
          addDraftKey({ invitees: userInvites }),
          hubSlug,
          goBack
        )
      );
    if (step === 6)
      dispatch(actions.putUpdateSoc(disputeId, soc, hubSlug, goBack));
  };

  const onSocClick = () =>
    dispatch(actions.putUpdateSoc(disputeId, soc, hubSlug));

  const goBack = () => dispatch(actions.handleStepBack());

  const onClickDashboard = () => history.push("/");

  const handleCurrentStepChange = (step) => {
    dispatch(actions.handleStepChange(step));
  };

  return (
    <IndexRender
      currentStep={currentStep}
      goBack={goBack}
      onSaveAndBack={handleSaveAndBack}
      onDiscardAndBack={handleDiscardAndBack}
      onSaveDraft={handleSaveDraft}
      next={next}
      isNextDisabled={isNextDisabled}
      onClickDashboard={onClickDashboard}
      disputeId={disputeId}
      hubSlug={hubSlug}
      userRole={userRole}
      onUpdateUserRole={handleUpdateUserRole}
      onSocClick={onSocClick}
      onCurrentStepChange={handleCurrentStepChange}
      claimant={claimant}
    />
  );
}

export const addDraftKey = (data) => {
  return { ...data, isDraft: true };
};
