import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { putUpdateDefence } from "../../redux/actions/defensiveActsActions";
import { whole } from "../../redux/selectors/defensiveActsSelector";
import Spinner from "../Common/Spinner";
import SodRender from "./SodRender";

export default function SodHOC() {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();

  const dispatch = useDispatch();

  const handleUpdateDefence = (body) => {
    dispatch(putUpdateDefence(disputeId, body));
  };

  const { isSubmitting, errors } = useSelector(whole);

  return dispute ? (
    <SodRender
      hubSlug={hubSlug}
      dispute={dispute}
      disputeId={disputeId}
      extraCrumbs={extraCrumbs(hubSlug, disputeId)}
      onUpdateDefence={handleUpdateDefence}
      isSubmitting={isSubmitting}
      errors={errors}
    />
  ) : (
    <Spinner />
  );
}

const extraCrumbs = (hubSlug, disputeId) => [
  {
    path: `/${hubSlug}/disputes/${disputeId}/statement-of-defence`,
    breadcrumbName: "Statement Of Defence",
  },
];
