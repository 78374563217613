import { message } from "antd";
import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";
import { successMessage } from "../../components/Common/Feedback/Message";
import * as actions from "../actions/disputeUserActions";
import * as timelineActions from "../actions/timelineActions";
import * as apis from "../apis/disputeUsersApi";
import * as types from "../constants/constants";

const tag = "dispute-users";

export function* getIndex({ payload }) {
  const { disputeId } = payload;
  try {
    const res = yield call(apis.index, disputeId);
    yield put(actions.handleIndex(res, { disputeId }));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShow(action) {
  const { disputeId, disputeUserId, isHubUser } = action.payload;
  try {
    const method = isHubUser ? apis.showHubUser : apis.show;
    const res = yield call(method, disputeId, disputeUserId);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdate(action) {
  const { disputeId, disputeUserId, body } = action.payload;
  try {
    const res = yield call(apis.update, disputeId, disputeUserId, body);
    yield put(actions.handleUpdate(res));
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateKyc(action) {
  const { hubSlug, disputeId, body } = action.payload;
  try {
    const res = yield call(apis.updateKyc, disputeId, body);
    successMessage("kyc.submitted_profile.text");
    yield put(actions.handleUpdateKyc(res));
    yield put(push(`/${hubSlug}/disputes/${disputeId}`));
  } catch (e) {
    console.error(tag, "saga putUpdateKyc error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateStatus(action) {
  const { disputeId, disputeUserId, body } = action.payload;
  try {
    const res = yield call(apis.updateStatus, disputeId, disputeUserId, body);
    message.success(`Profile ${body.kycStatus}`);
    yield put(actions.handleUpdateStatus(res));
    yield put(timelineActions.getShow(disputeId, {}));
    yield put(actions.getIndex(disputeId));
  } catch (e) {
    console.error(tag, "saga putUpdateStatus error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* hearingsSaga() {
  yield takeLatest(types.DISPUTEUSER_GET_INDEX, getIndex);
  yield takeLatest(types.DISPUTEUSER_GET_SHOW, getShow);
  yield takeLatest(types.DISPUTEUSER_PUT_UPDATE, putUpdate);
  yield takeLatest(types.DISPUTEUSER_PUT_UPDATE_KYC, putUpdateKyc);
  yield takeLatest(types.DISPUTEUSER_PUT_UPDATE_STATUS, putUpdateStatus);
}
