import ClaimFilingIntro from "./ClaimFilingIntro";
import IndexHOC from "./IndexHOC";
import ProfileEditHOC from "./ProfileEditHOC";
import ShowHOC from "./ShowHOC";

export default {
  Index: IndexHOC,
  Show: ShowHOC,
  ProfileEdit: ProfileEditHOC,
  ClaimFilingIntro: ClaimFilingIntro,
};
