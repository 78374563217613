import { Button, Space, Timeline, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import DateTime from "../Common/DateTime";
import Viewer from "../Common/Editor/Viewer";
import Translation from "../Common/Translation";

export default function ({
  events = [],
  allDetailsLink,
  isEntireHistory = false,
}) {
  if (!events.length)
    return (
      <>
        <Typography.Title level={3}>
          <Translation text="general.history.text" />
        </Typography.Title>
        <center>
          <Typography.Paragraph type="secondary">
            <Translation text="timeline.no_events.text" />
          </Typography.Paragraph>
        </center>
      </>
    );
  return (
    <Space direction="vertical">
      <Typography.Title level={3}>
        <Translation text="general.history.text" />
      </Typography.Title>
      <Timeline>
        {events.map((e, i) => {
          const event = e?.attributes;
          const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i)(
            event.description
          );
          return (
            <Timeline.Item color={itemColor(event?.status)} key={i}>
              {event?.title}{" "}
              <small>
                <DateTime epochSeconds={event?.createdAt} />{" "}
              </small>
              {Boolean(event?.description) && (
                <Typography.Paragraph type="secondary">
                  {isHTML ? (
                    <Viewer value={event?.description} />
                  ) : (
                    event?.description
                  )}
                </Typography.Paragraph>
              )}
            </Timeline.Item>
          );
        })}
      </Timeline>
      {!isEntireHistory && <SeeAllDetails allDetailsLink={allDetailsLink} />}
    </Space>
  );
}

const SeeAllDetails = ({ allDetailsLink }) => (
  <center>
    <Link to={allDetailsLink}>
      <Button type="link">
        <Translation text="general.see_all_details.link" />
      </Button>
    </Link>
  </center>
);

const itemColor = (status) => {
  return {
    Positive: "green",
    Negative: "red",
    Neutral: "blue",
  }[status];
};
