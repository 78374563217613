import { Col, Row } from "antd";
import React from "react";
import PageTitle from "../Typography/PageTitle";
import "./styles.less";

export default function PageHeader({ title, cta }) {
  return (
    <Row className="jur-page-header-wrapper" justify="space-between">
      <Col>
        <PageTitle label={title} />
      </Col>
      <Col>{cta}</Col>
    </Row>
  );
}
