import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

export default function (state = initial, action) {
  switch (action.type) {
    case types.USERPROFILE_GET_SHOW:
      return {
        ...state,
        isLoading: true,
      };
    case types.USERPROFILE_HANDLE_SHOW:
      return {
        ...state,
        isLoading: false,
        profile: action.payload.data?.attributes || action.payload.data,
      };
    case types.USERPROFILE_PUT_UPDATE:
      return {
        ...state,
        isSubmitting: true,
        errors: undefined,
        isErrorHidden: false,
      };
    case types.USERPROFILE_HANDLE_UPDATE:
      return { ...state, isOpen: false, isSubmitting: false };
    case types.USERPROFILE_HANDLE_UPDATE_FORM:
      return { ...state, profile: action.payload, isErrorHidden: true };
    case types.USERPROFILE_HANDLE_ERROR:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        errors: errorMessages(action.error),
      };
    default:
      return state;
  }
}

const initial = {
  isLoading: true,
  isSubmitting: false,
  profile: {},
  errors: {},
  isErrorHidden: false,
};
