import { push } from "connected-react-router";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { successMessage } from "../../components/Common/Feedback/Message";
import * as actions from "../actions/defensiveActsActions";
import * as disputeActions from "../actions/disputeActions";
import * as apis from "../apis/defensiveActsApi";
import * as types from "../constants/constants";
import { selectCurrentSlug } from "../selectors/hubsSelector";

const tag = "defensive-acts";

export function* putUpdateDefence(action) {
  const { disputeId, body } = action.payload;
  try {
    const res = yield call(apis.updateDefence, disputeId, body);
    successMessage("defensive_actions.statement_of_defence_submitted.text");
    yield put(actions.handleUpdateDefence(res));
    const hubSlug = yield select(selectCurrentSlug);
    yield put(push(`/${hubSlug}/disputes/${disputeId}`));
  } catch (e) {
    console.error(tag, "saga putUpdateDefence error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreateCounterclaim(action) {
  const { disputeId, body } = action.payload;
  try {
    const res = yield call(apis.createCounterclaim, disputeId, body);
    successMessage("defensive_actions.counter_claim_submitted.text");
    yield put(actions.handleCreateCounterclaim(res));
    const hubSlug = yield select(selectCurrentSlug);
    yield put(push(`/${hubSlug}/disputes/${disputeId}`));
  } catch (e) {
    console.error(tag, "saga postCreateCounterclaim error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateCounterclaimreply(action) {
  const { disputeId, counterClaimId, body } = action.payload;
  try {
    const res = yield call(
      apis.updateCounterclaimreply,
      disputeId,
      counterClaimId,
      body
    );
    successMessage("defensive_actions.reply_to_counter_claim_submitted.text")
    yield put(actions.handleUpdateDefence(res));
    const hubSlug = yield select(selectCurrentSlug);
    yield put(push(`/${hubSlug}/disputes/${disputeId}`));
  } catch (e) {
    console.error(tag, "saga putUpdateDefence error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateDeadline(action) {
  const { disputeId, body, resetForm } = action.payload;
  try {
    const res = yield call(apis.updateDeadline, disputeId, body);
    successMessage("defensive_actions.update_deadline.text")
    yield put(actions.handleUpdateDeadline(res));
    yield put(disputeActions.getShow(disputeId));
    const hubSlug = yield select(selectCurrentSlug);
    yield put(push(`/${hubSlug}/disputes/${disputeId}`));
    resetForm();
  } catch (e) {
    console.error(tag, "saga putUpdateDeadline error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* defensiveActsSaga() {
  yield takeEvery(types.DEFENSIVEACTIONS_PUT_UPDATE_DEFENCE, putUpdateDefence);
  yield takeEvery(
    types.DEFENSIVEACTIONS_POST_CREATE_COUNTERCLAIM,
    postCreateCounterclaim
  );
  yield takeEvery(
    types.DEFENSIVEACTIONS_PUT_UPDATE_COUNTERCLAIMREPLY,
    putUpdateCounterclaimreply
  );
  yield takeEvery(
    types.DEFENSIVEACTIONS_PUT_UPDATE_DEADLINE,
    putUpdateDeadline
  );
}
