import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Tooltip, Typography, Upload } from "antd";
import { FormItem } from "formik-antd";
import React from "react";
import { useDispatch } from "react-redux";
import { errorAsExtra } from "../../helpers/errorHelpers";
import { deleteFile, putUpdate } from "../../redux/actions/fileActions";
import { upload } from "../../redux/apis/filesApi";
import { UPLOADED, UPLOADING } from "../../redux/constants/FileStatuses";
import { toUploaderFiles } from "../../transformers/formTransformer";
import Translation from "./Translation";

export default function Uploader({
  name,
  onDone,
  onAdd,
  children,
  fileCategory,
  defaultFileList,
  useDragger = false,
  required = false,
  index,
  multiple = false,
  maxCount = undefined,
  allowDocumentOnly = false,
  onRemove,
  listType = "picture",
  ...extras
}) {
  const dispatch = useDispatch();

  const UploadComponent = useDragger ? Upload.Dragger : Upload;
  const acceptedExtensions = allowDocumentOnly ? ".doc,.docx,.pdf" : undefined;

  return (
    <UploadComponent
      accept={acceptedExtensions}
      maxCount={maxCount}
      multiple={multiple}
      listType={listType}
      onRemove={(file) => {
        if (file?.id || file?.originFileObj?.id) {
          dispatch(deleteFile(file?.id || file?.originFileObj?.id));
        }
        if (onRemove) onRemove(file);
      }}
      {...extras}
      defaultFileList={toUploaderFiles(defaultFileList, index)}
      name={name}
      method="PUT"
      customRequest={customUpload({ onAdd, onDone, dispatch, fileCategory })}
    >
      {children}
    </UploadComponent>
  );
}

export function toUploadFormItem({
  dispatch,
  propogateValues,
  values,
  setFieldValue,
  errors,
  errorProps = {},
}) {
  const { isHidden, hide } = errorProps;
  const apiErrorOf = errorAsExtra(errors, isHidden);

  return (item, index) => {
    const handleDone = (value) => {
      hide && hide();
      const files = values.files;
      files[item.index] = value;
      setFieldValue(item.name, value);
      if (propogateValues)
        propogateValues({ ...values, files }, item.name, value);
    };

    return (
      <FormItem name={item.name} key={index} {...apiErrorOf(item.name)}>
        <Uploader
          required={!item.optional}
          name={item.name}
          onDone={handleDone}
          index={item.index}
          defaultFileList={values.files[item.index]}
          fileCategory={item.fileCategory}
          maxCount={1}
        >
          <UploadButtonRender {...item} />
        </Uploader>
      </FormItem>
    );
  };
}

export function UploadButtonRender({
  optional,
  label,
  tooltip,
  buttonProps = {},
}) {
  return (
    <>
      <Button {...buttonProps}>
        <UploadOutlined />
        {!optional ? <Typography.Text type="danger">*</Typography.Text> : null}
        &ensp;
        <Translation text={label} />
      </Button>
      {tooltip ? (
        <>
          &emsp;
          <Tooltip title={<Translation text={tooltip} />}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ) : null}
    </>
  );
}

export function customUpload({ onAdd, onDone, dispatch, fileCategory }) {
  return function ({ onSuccess, onError, file, onProgress }) {
    console.log("customUpload onAdd", file);
    file.status = UPLOADING;
    if (onAdd) onAdd(file);

    return upload(file, { onProgress, onSuccess, onError }).then(
      ([uploadInfo, ...extras]) => {
        console.log("customUpload onDone", uploadInfo, file, extras);
        file.status = UPLOADED;
        file.id = uploadInfo.id;
        file.version = uploadInfo.attributes?.version;
        file.fileCategory = fileCategory;

        dispatch(
          putUpdate(uploadInfo.id, file, () =>
            onDone(file, uploadInfo, ...extras)
          )
        );
        return file;
      }
    );
  };
}
