export const selectCurrentStep = (state) => state.disputeFiling.currentStep;
export const selectIsNextDisabled = (state) =>
  state.disputeFiling.isNextDisabled;
export const selectErrors = (state) => state.disputeFiling.errors;
export const selectIsErrorHidden = (state) => state.disputeFiling.isErrorHidden;
export const selectKycErrors = (state) =>
  Object.entries(selectErrors(state) || {}).reduce((acc, [key, value]) => {
    if (key.startsWith("kyc.")) acc[key.replace("kyc.", "")] = value;
    return acc;
  }, {});

export const selectUserInvitesErrors = (state) =>
  Object.entries(selectErrors(state) || {}).reduce((acc, [key, value]) => {
    if (key === "mainClaimant") acc[key] = value;
    if (key.startsWith("userInvites.")) {
      const [, index, fieldName] = key.split(".");
      acc[index] = acc[index] || {};
      acc[index][fieldName] = value;
    }
    return acc;
  }, {});

export const selectDisputeId = (state) => state.disputeFiling.disputeId;
export const selectUserRole = (state) => state.disputeFiling.claimant?.role;
export const selectInvites = (state) => state.disputeFiling.userInvites;
export const selectMainClaimant = (state) => state.disputeFiling.mainClaimant;
export const selectKyc = (state) => state.disputeFiling.kyc;
export const selectClaimant = (state) => state.disputeFiling.claimant;
export const selectClaimOptions = (state) => state.disputeFiling.claimOptions;
export const selectClaim = (state) => state.disputeFiling.claimDetails;
export const selectClaimFiles = (state) => state.disputeFiling.claimFiles;
export const selectRespondent = (state) => state.disputeFiling.respondent;
export const selectPayment = (state) => state.disputeFiling.payment;
export const selectPaymentMeta = (state) => state.disputeFiling.paymentMeta;
export const selectSoc = (state) => state.disputeFiling.soc;
