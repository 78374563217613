import { Breadcrumb, Card } from "antd";
import React from "react";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import DisputeDetails from "../Disputes/Show/DisputeDetails";
import PageTitle from "../Typography/PageTitle";
import ArbitratorResponse from "./ArbitratorResponse";

export default function RespondRender({ disputeParams }) {
  return (
    <SliderMenuWrapper {...disputeParams}>
      <BreadcrumbHeader />
      <DisputeDetailsCard {...disputeParams} />
      <br />
      <ArbitratorResponse disputeParams={disputeParams} />
    </SliderMenuWrapper>
  );
}

const BreadcrumbHeader = () => (
  <div className="jur-breadcrumb-header">
    <Breadcrumb>
      <Breadcrumb.Item>Overview</Breadcrumb.Item>
      <Breadcrumb.Item>Respond To Appointment</Breadcrumb.Item>
    </Breadcrumb>
    <PageTitle label="Respond To Appointment" />
  </div>
);

const DisputeDetailsCard = (props) => (
  <Card>
    <DisputeDetails {...props} />
  </Card>
);
