import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { canAccess } from "../../helpers/accessHelpers";
import * as actions from "../actions/paymentActions";
import * as timelineActions from "../actions/timelineActions";
import * as apis from "../apis/paymentsApi";
import * as types from "../constants/constants";
import { accessRules, selectAccessor } from "../selectors/authSelector";
import { selectMyDisputeUser } from "../selectors/disputesSelector";
import { selectHubPaymentMethod } from "../selectors/hubsSelector";

const tag = "payments";

export function* getIndex(action) {
  const { disputeId } = action.payload;
  try {
    const res = yield call(apis.index, disputeId);
    yield put(actions.handleIndex(res));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

function makeBody(
  body,
  disputeUser,
  paymentMethod,
  shouldCreateTransaction = false
) {
  return {
    ...body,
    currency: paymentMethod?.currency,
    disputeUserId: body.disputeUserId || disputeUser?.disputeUserId,
    userGroup: body.userGroup || disputeUser?.group,
    motivation: Boolean(body?.motivation)
      ? body.motivation
      : shouldCreateTransaction
      ? "Self Initiated Payment"
      : null,
  };
}

export function* postCreate(action) {
  const { disputeId, body } = action.payload;
  const disputeUser = yield select(selectMyDisputeUser);
  const paymentMethod = yield select(selectHubPaymentMethod);

  const rules = yield select(accessRules);
  const accessor = yield select(selectAccessor);
  const shouldCreateTransaction = canAccess(
    accessor,
    "create-transaction",
    "payments",
    rules
  );
  const requestBody = makeBody(
    body,
    disputeUser,
    paymentMethod,
    shouldCreateTransaction
  );

  try {
    const res = yield call(apis.create, disputeId, requestBody);

    if (shouldCreateTransaction)
      yield put(
        actions.postCreateTransaction(
          disputeId,
          res.data.data?.id || res.data.id,
          {
            amount: requestBody.amount,
            currency: requestBody.currency,
            reference: body.reference,
            receiptFileId: Boolean(body?.receiptFileId)
              ? body.receiptFileId
              : null,
          }
        )
      );
    yield put(actions.handleCreate(res));
    yield put(timelineActions.getShow(disputeId, {}));
    if (!shouldCreateTransaction) yield put(actions.getIndex(disputeId));
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreateTransaction(action) {
  const { disputeId, paymentId, body } = action.payload;
  const disputeUser = yield select(selectMyDisputeUser);
  const paymentMethod = yield select(selectHubPaymentMethod);
  const requestBody = makeBody(body, disputeUser, paymentMethod);

  try {
    const res = yield call(
      apis.createTransaction,
      disputeId,
      paymentId,
      requestBody
    );
    yield put(actions.handleCreateTransaction(res));
    yield put(actions.getIndex(disputeId));
    yield put(timelineActions.getShow(disputeId, {}));
  } catch (e) {
    console.error(tag, "saga postCreateTransaction error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateTransaction(action) {
  const { disputeId, paymentId, transactionId, body } = action.payload;
  try {
    const res = yield call(
      apis.updateTransaction,
      disputeId,
      paymentId,
      transactionId,
      body
    );
    yield put(actions.handleUpdateTransaction(res));
    yield put(timelineActions.getShow(disputeId, {}));
    yield put(actions.getIndex(disputeId));
  } catch (e) {
    console.error(tag, "saga putUpdateTransaction error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* paymentsSaga() {
  yield takeLatest(types.PAYMENT_GET_INDEX, getIndex);
  yield takeEvery(types.PAYMENT_POST_CREATE, postCreate);
  yield takeLatest(types.PAYMENT_PUT_UPDATE_TRANSACTION, putUpdateTransaction);
  yield takeLatest(
    types.PAYMENT_POST_CREATE_TRANSACTION,
    postCreateTransaction
  );
}
