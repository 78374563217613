import React from "react";
import { successMessage } from "../components/Common/Feedback/Message";
import Translation from "../components/Common/Translation";
import { dateOptions, datetimeOptions, locale } from "../config";

export function copyToClipboard(text) {
  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  successMessage("general.successfully_copied.text");
}

export function dateRender(date) {
  return date.toLocaleString(locale(), dateOptions());
}

export function dateTimeRender(date) {
  return date.toLocaleString(locale(), datetimeOptions());
}

export function symbolOf(currency) {
  return (
    {
      USD: "$",
      GBP: "£",
      INR: "₹",
      EUR: "€",
      AED: "د.إ",
    }[currency] || currency
  );
}

export function formatAmount(amount) {
  if (typeof amount === "undefined" || amount === null) return null;
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toAddressLine(
  { address, address2, city, state, country, zipCode, ...rest },
  config
) {
  if (config?.prefix) {
    const { prefix } = config;
    address = rest[prefix + "Address"];
    address2 = rest[prefix + "Address2"];
    city = rest[prefix + "City"];
    state = rest[prefix + "State"];
    country = rest[prefix + "Country"];
    zipCode = rest[prefix + "ZipCode"];
  }

  return [address, address2, city, state, country, zipCode]
    .filter(Boolean)
    .join(", ");
}

export function addressFormAttrs() {
  return [
    { name: "address", label: "general.address.text" },
    { name: "address2", label: "general.address2.text", optional: true },
    { name: "city", label: "general.city.text" },
    { name: "state", label: "general.state.text" },
    { name: "country", label: "general.country.text" },
    { name: "zipCode", label: "general.zip.text" },
  ];
}

export const torFileName = (tor) => `v${tor?.torVersion} - ${tor?.file?.name}`;

export function requestDisplayName(evidenceRequest) {
  return (
    <>
      <Translation text="evidence_request.request.text" />
      {" #"}
      {evidenceRequest?.id}
    </>
  );
}

export const initialsOf = (name = "") =>
  name
    .split(" ")
    .map((a) => a[0])
    .slice(0, 2)
    .join("");

export function sanitizeURL(url) {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = "http://" + url;
  }
  return url;
}

export function romanize(num) {
  if (isNaN(num)) return NaN;
  var digits = String(+num).split(""),
    key = [
      "",
      "C",
      "CC",
      "CCC",
      "CD",
      "D",
      "DC",
      "DCC",
      "DCCC",
      "CM",
      "",
      "X",
      "XX",
      "XXX",
      "XL",
      "L",
      "LX",
      "LXX",
      "LXXX",
      "XC",
      "",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
    ],
    roman = "",
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
}

export const currencyFormatter = (currency) => {
  return `${currency}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const phoneNumberFormatter = (phoneNumber) => {
  return phoneNumber.replace(
    /(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/,
    (_, p1, p2, p3, p4) => {
      let output = "";
      if (p1) output = `(${p1}`;
      if (p2) output += `${p2})`;
      if (p3) output += ` ${p3}`;
      if (p4) output += `-${p4}`;
      return output;
    }
  );
};
