import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";
import { errorMessage, successMessage } from "../../components/Common/Feedback/Message";
import { saveUserProfile, makeUserOnboardingCompleted } from "../../helpers/authHelpers";
import * as actions from "../actions/userProfileActions";
import * as apis from "../apis/userProfileApi";
import * as types from "../constants/constants";

const tag = "user-profile";

export function* getShow() {
  try {
    const res = yield call(apis.show);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdate(action) {
  const { body } = action.payload;
  try {
    const res = yield call(apis.update, body);
    const userUpdatedProperties = res.data.attributes;
    yield put(actions.handleUpdate(userUpdatedProperties));
    successMessage("general.updated.message");
    saveUserProfile(userUpdatedProperties);
    makeUserOnboardingCompleted();
    yield put(push("/"));
  } catch (e) {
    errorMessage("general.failed_to_update.message");
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* () {
  yield takeLatest(types.USERPROFILE_GET_SHOW, getShow);
  yield takeLatest(types.USERPROFILE_PUT_UPDATE, putUpdate);
}
