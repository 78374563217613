import * as types from "../constants/constants";

export const getIndex = (disputeId) => ({
  type: types.DISPUTEUSER_GET_INDEX,
  payload: { disputeId },
});

export const getShow = (disputeId, disputeUserId, isHubUser = false) => ({
  type: types.DISPUTEUSER_GET_SHOW,
  payload: { disputeId, disputeUserId, isHubUser },
});

export const putUpdate = (disputeId, disputeUserId, body) => ({
  type: types.DISPUTEUSER_PUT_UPDATE,
  payload: { disputeId, disputeUserId, body },
});

export const putUpdateKyc = (hubSlug, disputeId, body) => {
  body.files = body?.files?.filter(Boolean);
  return {
    type: types.DISPUTEUSER_PUT_UPDATE_KYC,
    payload: { hubSlug, disputeId, body },
  };
};

export const putUpdateStatus = (disputeId, disputeUserId, body) => ({
  type: types.DISPUTEUSER_PUT_UPDATE_STATUS,
  payload: { disputeId, disputeUserId, body },
});

export const handleIndex = (disputeUsers, requestExtras) => ({
  type: types.DISPUTEUSER_HANDLE_INDEX,
  payload: disputeUsers,
  requestExtras,
});

export const handleIsOpen = (isOpen) => ({
  type: types.DISPUTEUSER_HANDLE_IS_OPEN,
  payload: isOpen,
});

export const handleMotivaitonForm = (motivation) => ({
  type: types.DISPUTEUSER_HANDLE_MOTIVATION_FORM,
  payload: motivation,
});

export const handleIsMotivationOpen = (isOpen) => ({
  type: types.DISPUTEUSER_HANDLE_IS_MOTIVATION_OPEN,
  payload: isOpen,
});

export const handleShow = (disputeUser) => ({
  type: types.DISPUTEUSER_HANDLE_SHOW,
  payload: disputeUser,
});

export const handleUpdate = () => ({
  type: types.DISPUTEUSER_HANDLE_UPDATE,
});

export const handleUpdateKyc = () => ({
  type: types.DISPUTEUSER_HANDLE_UPDATE_KYC,
});

export const handleUpdateStatus = (body) => ({
  type: types.DISPUTEUSER_HANDLE_UPDATE_STATUS,
  payload: body,
});

export const handleError = (e) => ({
  type: types.DISPUTEUSER_HANDLE_ERROR,
  error: e,
});
