import { FolderTwoTone, RightOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CloseIcon } from "../../../helpers/modalHelpers";
import Anchor from "../../Common/Anchor";
import JurLink from "../../Common/JurLink";
import Translation from "../../Common/Translation";
import Footnote from "../../Typography/Footnote";
import "../styles.less";
import DisputeDetails from "./DisputeDetails";
import RecentlyUploadedFileList from "./RecentlyUploadedFileList";

const { Text } = Typography;

export default function RightSlider({
  dispute,
  disputeId,
  hubSlug,
  isDisputeClosed,
  isAwaitingArbitratorAcceptance,
}) {
  return (
    <div className="jur-dispute-show-right-slider">
      <CaseDetails dispute={dispute} />
      {!isDisputeClosed && !isAwaitingArbitratorAcceptance && (
        <>
          <div>
            <Row
              justify="space-between"
              className="jur-dispute-show-right-slider-section-title"
            >
              <Col>
                <Text strong={true}>
                  <Translation text="dispute.case_files.text" />
                </Text>
              </Col>
              <Col>
                <Anchor to={`/${hubSlug}/disputes/${disputeId}/files`}>
                  <Translation text="dispute.view_all.text" />
                </Anchor>
              </Col>
            </Row>
            <CaseFiles disputeId={disputeId} hubSlug={hubSlug} />
          </div>
          <div>
            <Text
              strong={true}
              className="jur-dispute-show-right-slider-section-title"
            >
              <Translation text="dispute.recently_uploaded.text" />
            </Text>
            <RecentlyUploadedFileList disputeId={disputeId} />
          </div>
        </>
      )}
    </div>
  );
}

const CaseDetails = ({ dispute }) => {
  const [showDisputeDetails, setShowDisputeDetails] = useState(false);
  return (
    <div>
      <Row
        className="jur-dispute-show-right-slider-section-title"
        justify="space-between"
        align="middle"
      >
        <Col>
          <Text strong={true}>
            <Translation text="dispute.case_number.text" />{" "}
            {dispute?.caseNumber}
          </Text>
        </Col>
        <Col>
          <JurLink onClick={() => setShowDisputeDetails(true)}>
            <Translation text="dispute.show_more.text" />
          </JurLink>
        </Col>
      </Row>
      <br />
      <Footnote>{dispute?.name}</Footnote>
      <Modal
        width={676}
        maskClosable={false}
        onCancel={() => setShowDisputeDetails(false)}
        title={
          <>
            <Translation text="dispute.case_number.text" />{" "}
            {dispute?.caseNumber}
          </>
        }
        closeIcon={<CloseIcon />}
        visible={showDisputeDetails}
        footer={null}
      >
        <DisputeDetails dispute={dispute} />
      </Modal>
    </div>
  );
};

const CaseFiles = ({ disputeId, hubSlug }) => {
  const fileTypes = ["Respondent", "Claimant", "Arbitrator"];
  return (
    <>
      {fileTypes.map((item) => {
        return (
          <Link
            to={`/${hubSlug}/disputes/${disputeId}/files/${item.toLowerCase()}`}
          >
            <div className="jur-dispute-show-case-file-folder">
              <div className="jur-dispute-show-case-file-folder-icon">
                <FolderTwoTone twoToneColor="#F2994A" />
              </div>
              <span className="primary-color">{`${item} Folder`}</span>
              <div>
                <RightOutlined />
              </div>
            </div>
          </Link>
        );
      })}
    </>
  );
};
