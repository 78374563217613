import ResponseFilingIntro from "./Intro";
import InviteDefenceHOC from "./InviteDefenceHOC";
import ShowHOC from "./ShowHOC";
import ShowRender from "./ShowRender";

export default {
  Intro: ResponseFilingIntro,
  Show: ShowHOC,
  ShowRender: ShowRender,
  InviteDefence: InviteDefenceHOC,
};
