import { Typography } from "antd";
import React from "react";
import {
  ACCEPTED,
  APPROVED,
  AWAITING_HUB_APPROVAL,
  AWAITING_SUBMISSION_FROM_USER,
  NOT_SUBMITTED,
  PENDING,
  REJECTED,
  UNKNOWN,
} from "../../redux/constants/KYCStatuses";
import Translation from "../Common/Translation";

export default function ({ status }) {
  status = status || "Unknown";
  return (
    <Typography.Paragraph>
      <Translation text="general.kyc_status.text" />{" "}
      <Typography.Text type={color(status)}>{status}</Typography.Text>
    </Typography.Paragraph>
  );
}

function color(status) {
  return {
    [PENDING]: "warning",
    [AWAITING_HUB_APPROVAL]: "warning",
    [APPROVED]: "success",
    [ACCEPTED]: "success",
    [NOT_SUBMITTED]: "danger",
    [REJECTED]: "danger",
    [UNKNOWN]: "secondary",
    [AWAITING_SUBMISSION_FROM_USER]: "danger",
  }[status];
}
