import React from "react";
import { Col, Row, Typography } from "antd";
import { FormItem, InputNumber } from "formik-antd";
import { useSelector } from "react-redux";

import { QuestionTitle } from "../../Common/FormQuestion";
import { errorAsExtra } from "../../../helpers/errorHelpers";
import { selectIsErrorHidden } from "../../../redux/selectors/userProfileSelector";

function InputFormatter({ prefix, name, label, formatter, onChange, errors, ...props }) {
  const isErrorHidden = useSelector(selectIsErrorHidden);

  const errorProps = { isHidden: isErrorHidden };
  const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);

  return (
    <FormItem
      name={name}
      required={true}
      {...apiErrorOf(name, true)}
      label={<QuestionTitle text={label} />}
    >
      <Row justify="start" align="middle">
        <Col className="jur-input-formatter-prefix" flex="none">
          <Typography.Title level={5}>{prefix}</Typography.Title>
        </Col>
        <Col flex="auto">
          <InputNumber
            name={name}
            formatter={formatter}
            onChange={onChange}
            className='jur-width-full'
            parser={(value) => value.replace(/\D/g, "")}
            {...props}
          />
        </Col>
      </Row>
    </FormItem>
  );
}

export default InputFormatter;
