import { Button, Card, Col, Divider, Row, Tag, Typography } from "antd";
import React from "react";
import { torFileName } from "../../helpers/dataHelpers";
import DisputeHeader from "../Common/DisputeHeader";
import Viewer from "../Common/Editor/Viewer";
import PDFRender from "../Common/PDFRender";
import TimelineWrapper from "../Common/TimelineWrapper";
import Translation from "../Common/Translation";
import SignatureButton from "../Signs/SignatureButton";
import ReviewTermsForm from "./ReviewTermsForm";
import SendToPartiesForm from "./SendToPartiesForm";

export default function ({
  file,
  dispute,
  disputeId,
  hubSlug,
  extraCrumbs,
  signFile,
  hasUrl,
  tor,
  canSendToParties,
  openSendToParties,
  closeSendToParties,
  isLoading,
  isSendToPartiesVisible,
  participants,
  handleSendToParties,
  isReviewTermsVisible,
  handleReviewTerms,
  openReviewTerms,
  closeReviewTerms,
  canReviewTOR,
  uiRedirectUrl,
}) {
  const displayName = torFileName(tor?.attributes);

  return (
    <TimelineWrapper
      activityId={tor?.id}
      activityType="Terms Of Reference Show"
    >
      <Card>
        <DisputeHeader
          dispute={dispute}
          disputeId={disputeId}
          hubSlug={hubSlug}
          extraCrumbs={extraCrumbs}
        >
          <Typography.Title level={3}>
            <Translation text="terms_of_reference.terms_of_reference.title" />
          </Typography.Title>

          {canSendToParties && (
            <Button type="primary" onClick={openSendToParties}>
              <Translation text="terms_of_reference.send_to_parties.button" />
            </Button>
          )}
          {canReviewTOR && (
            <Button type="primary" onClick={openReviewTerms}>
              <Translation text="terms_of_reference.review_terms.button" />
            </Button>
          )}
          {hasUrl && (
            <SignatureButton redirectUrl={uiRedirectUrl}>
              <Button type="primary" onClick={signFile}>
                <Translation text="terms_of_reference.sign.button" />
              </Button>
            </SignatureButton>
          )}
          <Divider />
          <Card
            type="inner"
            title={
              <Row justify="space-between">
                <Col>{displayName}</Col>
                <Col>
                  <Tag>{tor?.attributes?.status}</Tag>
                </Col>
              </Row>
            }
          >
            {tor?.attributes?.reasonForRejection && (
              <>
                <Typography.Text strong={true}>
                  <Translation text="arbitral_award.hubs_edits.text" />
                </Typography.Text>
                <Viewer value={tor?.attributes?.reasonForRejection} />
              </>
            )}
            <PDFRender url={file.downloadUrl} />
          </Card>
        </DisputeHeader>
      </Card>
      <ReviewTermsForm
        // errors={errors}
        closeReviewTerms={closeReviewTerms}
        visible={isReviewTermsVisible}
        handleReviewSubmit={handleReviewTerms}
        isLoading={isLoading}
        pendingTors={[tor]}
      />
      <SendToPartiesForm
        participants={participants}
        isSendToPartiesVisible={isSendToPartiesVisible}
        handleSendToParties={handleSendToParties}
        closeSendToParties={closeSendToParties}
        tors={[tor]}
        isLoading={isLoading}
      />
    </TimelineWrapper>
  );
}
