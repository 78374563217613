import { CheckCircleOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import React from "react";
import { romanize } from "../../helpers/dataHelpers";
import DateTime from "../Common/DateTime";
import Translation from "../Common/Translation";
import Footnote from "../Typography/Footnote";

const { Paragraph } = Typography;

export default function JurStepsProgress({ currentStep, total, phases }) {
  const renderTooltip = (phase) => {
    return (
      <div className="p-12">
        <Paragraph className="jur-steps-progress-tooltip-title">
          {phase?.stateName}
        </Paragraph>

        <Translation text={phase?.stateDescription} />

        <div className="jur-steps-progress-tooltip-deadline">
          <DateTime epochSeconds={phase?.deadline} onlyDate={true} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="jur-steps-progress-wrapper">
        {[...Array(total).keys()].map((x) => {
          return (
            <div
              className={
                x + 1 < currentStep
                  ? "jur-steps-progress-completed-step"
                  : x + 1 === currentStep
                  ? "jur-steps-progress-current-step"
                  : ""
              }
            >
              <Tooltip color={"#151515"} title={renderTooltip(phases[x])}>
                <div>
                  {x + 1 > currentStep ? (
                    <div className="jur-steps-progress-touchpoint" />
                  ) : (
                    <div>
                      <CheckCircleOutlined
                        style={{ color: "#2777ef", fontSize: 18 }}
                      />
                    </div>
                  )}
                </div>
              </Tooltip>
            </div>
          );
        })}
      </div>
      <div className="jur-steps-progress-description-wrapper">
        {[...Array(total).keys()].map((x) => {
          return (
            <div>
              <Footnote strong>Phase</Footnote>
              <Typography.Text strong>{romanize(x + 1)}</Typography.Text>
            </div>
          );
        })}
      </div>
    </div>
  );
}
