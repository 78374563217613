import {
  ContainerOutlined,
  DownOutlined,
  LogoutOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useAccess } from "../../helpers/accessHelpers";
import { readLoggedInUser } from "../../helpers/authHelpers";
import { initialsOf } from "../../helpers/dataHelpers";
import { logoutUser } from "../../redux/actions/authenticateActions";
import { handleStartDisputeFiling } from "../../redux/actions/disputeFilingActions";
import { accepted } from "../../redux/selectors/disputesSelector";
import Translation from "../Common/Translation";

function NavbarActionButtons() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentRoute = useLocation();
  const path = currentRoute?.pathname;
  const disputes = useSelector(accepted);
  const hasNoDisputes = disputes?.data?.length === 0;

  const onDisputeFilingClick = () => {
    if (hasNoDisputes) history.push("/dispute-filing-introduction");
    else dispatch(handleStartDisputeFiling());
  };
  const handleLogout = () => void dispatch(logoutUser({}));

  const canCreateDispute = useAccess("create", "dispute");
  const canInvite = useAccess("invite", "roster");

  const name = readLoggedInUser().name;
  const initials = initialsOf(name);

  const UserName = () => (
    <span>
      <span className="jur-navbar-user-icon">{initials}</span>
      {name}
    </span>
  );

  const menuItems = [
    {
      label: "Profile",
      icon: <UserOutlined />,
      onClick: () => history.push("/edit-profile"),
    },
    // removing this for time being
    // {
    //   label: "FAQ",
    //   icon: <CommentOutlined />,
    //   onClick: () => {},
    // },
    {
      label: "Arbitration Rules",
      icon: <ContainerOutlined />,
      onClick: () =>
        window.open("https://drive.google.com/file/d/1wvbcuBjJL6ks_ZP0J6W6JDyQVf5CfuaM/view?usp=sharing", "_blank"),
    },
    {
      label: "Log Out",
      icon: <LogoutOutlined />,
      onClick: handleLogout,
    },
  ];

  const menu = (
    <Menu>
      {menuItems.map((item, i) => (
        <Menu.Item onClick={item.onClick} key={i}>
          <span className="jur-navbar-dropdown-item">
            {item.icon} <Typography.Text>{item.label}</Typography.Text>
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const actions = [
    {
      visible: canCreateDispute && !path.includes("dispute-filing"),
      component: (
        <Button onClick={onDisputeFilingClick} type="primary" key="new_claim">
          <PlusOutlined />
          <Translation text="dashboard.new_claim.button" pad={true} />
        </Button>
      ),
    },
    {
      visible: canInvite,
      component: (
        <Link key="roster" to="/roster">
          <Button type="text">
            <Translation text="roster.roster_admin.action" />
          </Button>
        </Link>
      ),
    },

    {
      visible: true,
      component: (
        <Link to="/" key="dashboard">
          <Button type="text">
            <Translation text="dashboard.dashboard.title" />
          </Button>
        </Link>
      ),
    },
    {
      visible: true,
      component: (
        <Dropdown overlay={menu} key="dropdown">
          <span onClick={(e) => e.preventDefault()}>
            <UserName /> <DownOutlined />
          </span>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="jur-navbar-action-buttons">
      {actions.filter((a) => a.visible).map((a) => a.component)}
    </div>
  );
}

export default NavbarActionButtons;
