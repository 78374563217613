import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";
import { UPLOADED } from "../constants/FileStatuses";

const initial = {
  disputeId: null,
  currentStep: 0,
  userInvites: [],
  mainClaimant: null,
  kyc: { files: [] },
  isErrorHidden: false,
  paymentMeta: {},
  soc: {},
  isNextDisabled: true,
};

export default function (state = initial, action) {
  const payload = action.payload;

  if (action.type === types.DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_FORM)
    return {
      ...state,
      isErrorHidden: true,
      [stateNameOf(action.type)]: payload,
      isNextDisabled: false,
    };
  /** handle form updates */
  if (
    action.type.startsWith("DISPUTEFILING_HANDLE_UPDATE_") &&
    action.type.endsWith("_FORM")
  )
    return {
      ...state,
      isErrorHidden: true,
      [stateNameOf(action.type)]: payload,
    };

  /** disable Next button on start of a API call */
  if (
    action.type.startsWith("DISPUTEFILING_PUT_UPDATE_") ||
    action.type === types.DISPUTEFILING_POST_CREATE
  )
    return { ...state, errors: {}, isErrorHidden: false, isNextDisabled: true };

  /** handle other action types */
  switch (action.type) {
    // Disable Save while uploading files
    case types.FILE_PUT_UPDATE:
    case types.FILE_HANDLE_UPDATE:
      return {
        ...state,
        isNextDisabled: true,
      };
    case types.FILE_HANDLE_ERROR:
      return { ...state, isNextDisabled: false };

    case types.DISPUTEFILING_HANDLE_ERROR:
      return {
        ...state,
        errors: errorMessages(action.error),
        isNextDisabled: false,
      };
    case types.DISPUTEFILING_HANDLE_START:
      return initial;
    case types.DISPUTEFILING_HANDLE_SHOW:
      return {
        ...state,
        currentStep: payload.currentStep,
        disputeId: payload.disputeId,
        isNextDisabled: false,
      };
    case types.DISPUTEFILING_HANDLE_STEP_BACK:
      return {
        ...state,
        isNextDisabled: false,
        currentStep: state.currentStep === 0 ? 0 : state.currentStep - 1,
      };
    case types.DISPUTEFILING_HANDLE_STEP_CHANGE:
      return {
        ...state,
        currentStep: Math.max(0, payload?.step),
      };
    case types.DISPUTEFILING_HANDLE_UPDATE_USER_ROLE:
      return {
        ...state,
        userRole: payload.userRole,
      };
    case types.DISPUTEFILING_HANDLE_STEP_HUB:
      if (!state.hub) return { ...state, currentStep: 0, isNextDisabled: true };
      return { ...state, currentStep: 1, isNextDisabled: false };
    case types.HUB_HANDLE_UPDATE:
      return {
        ...state,
        hub: payload,
        isNextDisabled: !payload?.attributes?.url,
      };
    case types.DISPUTEFILING_HANDLE_CREATE:
      return {
        ...state,
        ...payload,
        disputeId: payload.data.disputeId,
        isNextDisabled: false,
      };
    case types.DISPUTEFILING_HANDLE_CREATE_DISPUTE:
      return {
        ...state,
        disputeId: payload.data.disputeId,
        isNextDisabled: false,
      };
    case types.DISPUTEFILING_HANDLE_HUB:
      return { ...state, hub: payload, isNextDisabled: false, currentStep: 1 };
    case types.DISPUTEFILING_HANDLE_SHOW_CLAIMANT:
      return {
        ...state,
        isNextDisabled: false,
        claimant: payload.data.attributes,
      };
    case types.DISPUTEFILING_HANDLE_UPDATE_CLAIMANT:
      return {
        ...state,
        isNextDisabled: false,
        currentStep:
          payload?.goBack === true
            ? 0
            : payload?.isDraft === true
            ? state.currentStep
            : 1,
      };
    case types.DISPUTEFILING_HANDLE_SHOW_KYC:
      return {
        ...state,
        isNextDisabled: false,
        kyc: payload.data.attributes.kyc || state.kyc,
        userInvites: payload.data.attributes.userInvites || state.userInvites,
        mainClaimant:
          payload.data.attributes.mainClaimant || state.mainClaimant,
      };
    case types.DISPUTEFILING_HANDLE_UPDATE_KYC:
      return {
        ...state,
        isNextDisabled: false,
        currentStep: 1,
      };
    case types.DISPUTEFILING_HANDLE_SHOW_CLAIMDETAILS:
      return {
        ...state,
        isNextDisabled: false,
        claimDetails: payload.data.attributes,
        claimOptions: payload.meta.options,
      };
    case types.DISPUTEFILING_HANDLE_UPDATE_CLAIMDETAILS:
      return {
        ...state,
        isNextDisabled: false,
        currentStep:
          payload?.goBack === true
            ? state.currentStep - 1
            : payload?.isDraft === true
            ? state.currentStep
            : state.currentStep + 1,
      };
    case types.DISPUTEFILING_GET_SHOW_CLAIMFILES:
      return { ...state, claimFiles: undefined };
    case types.DISPUTEFILING_HANDLE_SHOW_CLAIMFILES:
      const claimDocuments = payload.data.attributes;
      claimDocuments.files.forEach((f) => (f.status = UPLOADED)); // NOTE: Server may give wrong status
      return { ...state, isNextDisabled: false, claimFiles: claimDocuments };
    case types.DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_STATUS:
      return {
        ...state,
        isNextDisabled: false,
        claimFiles: state.claimFiles.map((f) =>
          f.uid === payload.uid ? payload.file : f
        ),
      };
    case types.DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_DESCRIPTION:
      return {
        ...state,
        isNextDisabled: false,
      };
    case types.DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES:
      return {
        ...state,
        isNextDisabled: false,
        currentStep:
          payload?.goBack === true
            ? state.currentStep - 1
            : payload?.isDraft === true
            ? state.currentStep
            : state.currentStep + 1,
      };
    case types.DISPUTEFILING_HANDLE_SHOW_ADDRESPONDENT:
      const respondent = payload.data.attributes;
      if (typeof respondent.isCompany === "undefined")
        respondent.isCompany = true;
      return { ...state, isNextDisabled: false, respondent };
    case types.DISPUTEFILING_HANDLE_UPDATE_ADDRESPONDENT:
      return {
        ...state,
        isNextDisabled: false,
        currentStep:
          payload?.goBack === true
            ? state.currentStep - 1
            : payload?.isDraft === true
            ? state.currentStep
            : state.currentStep + 1,
      };
    case types.DISPUTEFILING_GET_SHOW_PAYMENT:
      return {
        ...state,
        payment: null,
        paymentMeta: null,
      };
    case types.DISPUTEFILING_HANDLE_SHOW_PAYMENT:
      const payment = payload.data?.attributes || { transaction: {} };
      payment.feeSplitPercentage = payload.meta.feeSplitPercentage;
      if (!payment.transaction.amount)
        payment.transaction.amount = payment.meta?.totalAmount;
      if (payment.transaction?.reference)
        payment["transaction.reference"] = payment.transaction.reference;
      if (payment.transaction?.receiptFile?.id) {
        payment["transaction.receiptFileId"] =
          payment.transaction.receiptFile.id;
        payment.transaction.receiptFileId = payment.transaction.receiptFile.id;
      }
      return {
        ...state,
        isNextDisabled: false,
        payment,
        paymentMeta: payload.meta,
      };
    case types.DISPUTEFILING_HANDLE_UPDATE_PAYMENT:
      return { ...state, isNextDisabled: false, currentStep: 5 };
    case types.DISPUTEFILING_HANDLE_SHOW_INVITES:
      return {
        ...state,
        isNextDisabled: false,
        userInvites: payload.data.attributes.invitees || [],
      };
    case types.DISPUTEFILING_HANDLE_UPDATE_INVITES:
      return {
        ...state,
        isNextDisabled: false,
        currentStep:
          payload?.goBack === true
            ? state.currentStep - 1
            : payload?.isDraft === true
            ? state.currentStep
            : state.currentStep + 1,
      };
    case types.DISPUTEFILING_HANDLE_SHOW_SOC:
      return { ...state, isNextDisabled: false, soc: payload.data.attributes };
    case types.SIGN_HANDLE_ERROR:
      return { ...state, isNextDisabled: false };
    case types.DISPUTEFILING_PUT_UPDATE_COMPLETED:
      return { ...state, isNextDisabled: true, disputeId: payload.disputeId };
    case types.DISPUTEFILING_HANDLE_UPDATE_COMPLETED:
      return { ...state, isNextDisabled: false, currentStep: 6 };
    default:
      return state;
  }
}

function stateNameOf(type) {
  return {
    DISPUTEFILING_HANDLE_UPDATE_HUB_FORM: "hub",
    DISPUTEFILING_HANDLE_UPDATE_KYC_FORM: "kyc",
    DISPUTEFILING_HANDLE_UPDATE_CLAIMDETAILS_FORM: "claimDetails",
    DISPUTEFILING_HANDLE_UPDATE_CLAIMANT_FORM: "claimant",
    DISPUTEFILING_HANDLE_UPDATE_CLAIMFILES_FORM: "claimFiles",
    DISPUTEFILING_HANDLE_UPDATE_ADDRESPONDENT_FORM: "respondent",
    DISPUTEFILING_HANDLE_UPDATE_PAYMENT_FORM: "payment",
    DISPUTEFILING_HANDLE_UPDATE_INVITES_FORM: "userInvites",
    DISPUTEFILING_HANDLE_UPDATE_MAINCLAIMANT_FORM: "mainClaimant",
  }[type];
}
