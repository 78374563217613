import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import Translation from "./Translation";

export default function showConfirmModal({ label, onConfirm }) {
  Modal.confirm({
    title: <Translation text={label} />,
    icon: <ExclamationCircleOutlined />,
    onOk() {
      onConfirm();
    },
    okText: <Translation text="general.submit.button" />,
  });
}
