import { Typography } from "antd";
import React from "react";
import Translation from "../Common/Translation";
import "./styles.less";

function PageTitle({ label, customLabel }) {
  return (
    <div>
      <Typography.Title level={2} className="jur-page-title">
        {customLabel ? customLabel : <Translation text={label} />}
      </Typography.Title>
    </div>
  );
}

export default PageTitle;
