import { Button, Col, Row, Typography } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import React from "react";
import Translation from "../Common/Translation";
import AuthLayout from "../Layouts/AuthLayout";
import "./styles.less";

function InviteArbitratorRender({ loading = false, onSubmit }) {
  return (
    <div className="jur-invite-arbitrator-wrapper">
      <AuthLayout
        header={<div />}
        footer={<div />}
        content={
          <div>
            <div className="jur-auth-title">
              <Translation text="roster.invite_arbitrator.title" />
            </div>
            <br />

            <Formik
              onSubmit={(values) => {
                onSubmit(values);
              }}
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ handleChange, values }) => {
                const hideOnChange = (e) => {
                  handleChange(e);
                };

                return (
                  <Form layout="vertical">
                    <Row gutter={[24, 0]}>
                      <Col span={12}>
                        <Form.Item
                          required={true}
                          label={
                            <Typography.Title level={5}>
                              <Translation text="roster.first_name.text" />
                            </Typography.Title>
                          }
                          name="firstName"
                          className="jur-auth-form-item"
                        >
                          <Input name="firstName" onChange={hideOnChange} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          required={true}
                          name="lastName"
                          className="jur-auth-form-item"
                          label={
                            <Typography.Title level={5}>
                              <Translation text="roster.last_name.text" />
                            </Typography.Title>
                          }
                        >
                          <Input name="lastName" onChange={hideOnChange} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          required={true}
                          label={
                            <Typography.Title level={5}>
                              <Translation text="authentication.email_address.text" />
                            </Typography.Title>
                          }
                          name="email"
                          className="jur-auth-form-item"
                        >
                          <Input name="email" onChange={hideOnChange} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={
                        values.email === "" ||
                        values.lastName === "" ||
                        values.firstName === ""
                      }
                    >
                      <Translation text="roster.invite.action" pad={true} />
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        }
      />
    </div>
  );
}

export default InviteArbitratorRender;

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
};
