import { Col, Row, Typography } from "antd";
import React from "react";

function ActionTitle({ title, actions }) {
  return (
    <Row justify="space-between">
      <Col>
        <Typography.Title level={2} strong={true}>
          {title}
        </Typography.Title>
      </Col>
      <Col>{actions}</Col>
    </Row>
  );
}

export default ActionTitle;
