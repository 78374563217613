import { message } from "antd";
import React from "react";
import Translation from "../Translation";

export function successMessage(text) {
  message.success(<Translation text={text} />);
}

export function errorMessage(text) {
  message.error(<Translation text={text} />);
}
