import { Alert, Card, Space, Steps, Typography } from "antd";
import { Formik } from "formik";
import { Input, Radio, Select } from "formik-antd";
import React from "react";
import Editor from "./Common/Editor/Editor";
import JurContentWrapper from "./Layouts/JurContentWrapper";
import JurStepsNavigation from "./Navigation/JurStepsNavigation";
const { Step } = Steps;
const { Option } = Select;

function Library() {
  return (
    <JurContentWrapper>
      <Card>
        <center>
          <div>
            <Typography.Title>Form</Typography.Title>
            <Formik initialValues={{}}>
              {() => {
                return (
                  <Space direction="vertical" style={{ width: "600px" }}>
                    Input
                    <Input name="input" defaultValue="Name" />
                    Passsword Input
                    <Input.Password
                      name="password"
                      defaultValue="12345678"
                      iconRender={(visible) => (visible ? "Hide" : "Show")}
                    />
                    Addon Input
                    <Input
                      // type="number"
                      addonBefore="+01"
                      name="addon"
                      // defaultValue="12345678"
                      iconRender={(visible) => (visible ? "Hide" : "Show")}
                    />
                    Radio Group
                    <RadioGroup />
                    Select
                    <Select
                      name="Hey"
                      defaultValue="lucy"
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      <Option value="jack">Jack</Option>
                      <Option value="lucy">Lucy</Option>
                      <Option value="Yiminghe">yiminghe</Option>
                    </Select>
                    Rich Text Editor
                    <Editor
                      style={{ textAlign: "left" }}
                      // onChange={handleChangeOf("claim")}
                      // defaultValue={values?.claim}
                    />
                  </Space>
                );
              }}
            </Formik>
          </div>
        </center>
      </Card>
      <br />
      <Card>
        <center>
          <Typography.Title>Navigation</Typography.Title>
          <div>
            {"Custom Steps with Count"}
            <br />
            <JurStepsNavigation steps={3} currentStep={1} />
            <br />
            <br />
          </div>
          <div>
            Styled Ant Steps
            <Steps current={1}>
              <Step title="Finished" />
              <Step title="In Progress" />
              <Step title="In Progress" />
              <Step title="In Progress" />
              <Step title="In Progress" />
              <Step title="In Progress" />
              <Step title="In Progress" />
              <Step title="Waiting" />
            </Steps>
          </div>
          <div>
            Vertical Steps
            <Steps direction="vertical" current={1}>
              <Step title="Finished" description="This is a description." />
              <Step title="In Progress" description="This is a description." />
              <Step title="Waiting" description="This is a description." />
            </Steps>
          </div>
        </center>
      </Card>
      <br />
      <Card>
        <center>
          <Typography.Title>Feedback</Typography.Title>
          <div>
            Alert
            <br />
            <br />
            <div style={{ textAlign: "left", width: "400px" }}>
              <Alert
                banner
                message="Informational Notes"
                type="info"
                showIcon
              />
            </div>
          </div>
        </center>
      </Card>
    </JurContentWrapper>
  );
}

export default Library;

const RadioGroup = () => {
  const [value, setValue] = React.useState(1);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <Radio.Group onChange={onChange} value={value}>
      <Radio value={1}>A</Radio>
      <Radio value={2}>B</Radio>
      <Radio value={3}>C</Radio>
      <Radio value={4}>D</Radio>
    </Radio.Group>
  );
};
