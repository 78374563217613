import * as types from "../constants/constants";

export const getShow = (disputeId) => ({
  type: types.BILLINGDETAILS_GET_SHOW,
  payload: { disputeId },
});

export const handleShow = (billingDetails) => ({
  type: types.BILLINGDETAILS_HANDLE_SHOW,
  payload: billingDetails,
});

export const putUpdate = (disputeId, body) => ({
  type: types.BILLINGDETAILS_PUT_UPDATE,
  payload: { disputeId, body },
});

export const handleUpdate = (billingDetails) => ({
  type: types.BILLINGDETAILS_HANDLE_UPDATE,
  payload: billingDetails,
});

export const handleIsOpen = (isOpen) => ({
  type: types.BILLINGDETAILS_HANDLE_IS_OPEN,
  payload: isOpen,
});

export const handleError = (e) => ({
  type: types.BILLINGDETAILS_HANDLE_ERROR,
  error: e,
});
