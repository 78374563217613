import { Col, Divider, Row, Tag, Typography } from "antd";
import React from "react";
import Viewer from "../Common/Editor/Viewer";
import Translation from "../Common/Translation";
import DisputeRecord from "./DisputeRecord";
import ProfileSection from "./ProfileSection";

const { Title } = Typography;
const { Text } = Typography;

export default function ProfessionalTab({ professionalInformation }) {
  return (
    <>
      <ProfessionalBackground
        professionalInformation={professionalInformation}
      />
      <AlternativeExperience
        professionalInformation={professionalInformation}
      />
      <DisputeTrackRecord professionalInformation={professionalInformation} />
      {professionalInformation?.isLicensedAttorney ? (
        <LegalExperience professionalInformation={professionalInformation} />
      ) : null}
    </>
  );
}

const ProfessionalBackground = ({ professionalInformation }) => (
  <ProfileSection
    title={<Translation text="roster.professional_background.text" />}
    content={
      <div>
        <InputLabel label="roster.headline.text" />
        <Viewer value={professionalInformation?.headline} />
        <Divider />
        {professionalInformation?.experiences?.map((item, i) => {
          return <ProfessionalExperience experience={item} key={i} />;
        })}
      </div>
    }
  />
);

export const ProfessionalExperience = ({ experience, key }) => (
  <div key={key}>
    <Title className="primary-color" level={5} strong>
      {experience.title}
    </Title>
    <Title level={5} className="primary-color">
      {experience.companyName}
    </Title>
    <Title className="secondary-color" level={5}>
      {experience?.startMonth} {experience?.startYear} - {experience?.endMonth}{" "}
      {experience?.endYear} • {experience.location}
    </Title>
    <br />
    <InputLabel label="roster.headline.text" />
    <Viewer value={experience?.description} />
  </div>
);

const AlternativeExperience = ({ professionalInformation }) => {
  const arbitrationExperience = [
    {
      label: "roster.appointments_as_arbitrator.text",
      points: professionalInformation?.experienceAsArbitrator,
      color: "blue",
    },
    {
      label: "roster.appointments_as_counsel.text",
      points: professionalInformation?.experienceAsCounsel,
      color: "green",
    },
    {
      label: "roster.appointments_as_secretary.text",
      points: professionalInformation?.experienceAsSecretary,
      color: "violet",
    },
    {
      label: "roster.appointments_as_mediator.text",
      points: professionalInformation?.experienceAsMediator,
      color: "purple",
    },
  ];

  return (
    <ProfileSection
      title={<Translation text="roster.arbitration_experience.text" />}
      content={
        <div>
          <Row gutter={[80, 0]}>
            {arbitrationExperience.map((item) => (
              <Col span={6}>
                <InputLabel label={item?.label} />
                <br />
              </Col>
            ))}
            {arbitrationExperience.map((item) => (
              <Col span={6}>
                <div
                  className={`jur-arbitration-experience-${item.color}-wrapper`}
                >
                  <span
                    className={`jur-title jur-arbitration-experience-${item.color}`}
                  >
                    {zeroPad(item?.points ?? 0)}
                  </span>
                </div>
              </Col>
            ))}
          </Row>
          <Divider />
          <InputLabel label="roster.areas_of_practice.text" />
          <div className="jur-profile-list-item-tags-wrapper">
            {professionalInformation?.practiceAreas?.map((area) => (
              <span className="jur-profile-list-item-tag">
                <Tag>
                  <Title level={5}>{area}</Title>
                </Tag>
              </span>
            ))}
          </div>
          <br />
          <InputLabel label="roster.about_area_of_expertise.text" />
          <div className="jur-profile-list-item-tags-wrapper">
            {professionalInformation?.specializations?.map((area) => (
              <div className="jur-profile-list-item-tag">
                <Tag>
                  <Title level={5}>{area}</Title>
                </Tag>
              </div>
            ))}
          </div>
          <br />
          <InputLabel label="roster.areas_with_most_experience.text" />
          <Viewer value={professionalInformation?.specializationDescription} />
          <Divider />
          <InputLabel label="roster.panels.text" />
          <div className="jur-profile-list-item-tags-wrapper">
            {professionalInformation?.panels?.map((area) => (
              <div className="jur-profile-list-item-tag">
                <Tag>
                  <Title level={5}>{area}</Title>
                </Tag>
              </div>
            ))}
          </div>
        </div>
      }
    />
  );
};

const DisputeTrackRecord = ({ professionalInformation }) => {
  return (
    <ProfileSection
      title={<Translation text="roster.dispute_track_record.text" />}
      content={
        <div>
          {professionalInformation?.trackRecords?.map((item, index) => (
            <DisputeRecord item={item} index={index} />
          ))}
        </div>
      }
    />
  );
};

const LegalExperience = ({ professionalInformation }) => {
  return (
    <ProfileSection
      title={<Translation text="roster.legal_experience.text" />}
      content={
        <div>
          <InputLabel label="roster.areas_of_practice.text" />
          <div className="jur-profile-list-item-tags-wrapper">
            {professionalInformation?.licensedPracticeAreas?.map((area) => (
              <div className="jur-profile-list-item-tag">
                <Tag>
                  <Title level={5}>{area}</Title>
                </Tag>
              </div>
            ))}
          </div>
          <br />
          <InputLabel label="roster.year_admitted_to_bar.text" />
          <div>{professionalInformation?.barAdmissionYear}</div>
          <br />
          <InputLabel label="roster.serving_states.text" />
          <div className="jur-profile-list-item-tags-wrapper">
            {professionalInformation?.servingStates?.map((area) => (
              <div className="jur-profile-list-item-tag">
                <Tag>
                  <Title level={5}>{area}</Title>
                </Tag>
              </div>
            ))}
          </div>
        </div>
      }
    />
  );
};

export const zeroPad = (num) => String(num).padStart(2, "0");

export function InputLabel({ label }) {
  return (
    <Text className="secondary-color">
      <Translation text={label} />
    </Text>
  );
}
