import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import * as actions from "../../redux/actions/arbitralAwardActions";
import * as disputeUserActions from "../../redux/actions/disputeUserActions";
import * as fileActions from "../../redux/actions/fileActions";
import * as selectors from "../../redux/selectors/arbitralAwardSelector";
import { selectArbitralAwards, selectMeta } from "../../redux/selectors/arbitralAwardSelector";
import { selectParticipants } from "../../redux/selectors/disputeUsersSelector";
import { selectArbitralAward } from "../../redux/selectors/arbitralAwardSelector";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import IndexRender from "./IndexRender";
import {
  SENT_TO_HUB,
  SENT_TO_PARTIES,
  EDITS_REQUESTED,
  HUB_APPROVED,
  HUB_REQUESTED_EDITS,
} from "../../redux/constants/ArbitralAwardStatuses";

export default function () {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();

  const arbitralAwards = useSelector(selectArbitralAwards);
  const meta = useSelector(selectMeta);
  const rejectedAA = useSelector(selectArbitralAward);

  const [selectedAward, setSelectedAward] = useState(null);
  const [rejectedAward, setRejectedAward] = useState(null);

  const isAwardIssued = arbitralAwards.some((a) => a?.attributes?.status === SENT_TO_PARTIES);

  const editableAwards = arbitralAwards?.filter((a) => a.attributes?.status === SENT_TO_PARTIES);

  const sharableAwards = arbitralAwards.filter((a) => a?.attributes?.eligibleForPartySharing);

  const draftAwards = arbitralAwards.filter((a) => !Boolean(a.attributes?.hubAction?.status));

  const [currentDeadlineAction, setCurrentDeadlineAction] = useState(null);

  const participants = useSelector(selectParticipants);
  const {
    isReviewAwardVisible,
    isSendToHubVisible,
    isSendToPartiesVisible,
    isExtendDeadlineVisible,
    isRequestEditsVisible,
    isLoading,
    isSubmitting,
  } = useSelector(selectors.whole);
  const errors = useSelector(selectors.selectErrors);

  const dispatch = useDispatch();

  const downloadFile = (id) => dispatch(fileActions.getShow(id));
  const downloadTemplate = () => downloadFile(meta?.template?.id);

  const handleSendToHub = (body, resetForm) => {
    dispatch(actions.postCreate(disputeId, body, resetForm));
    setRejectedAward(null);
  };

  const handleReviewTerms = (body, resetForm) => {
    setRejectedAward(null);
    dispatch(
      actions.putUpdate(
        disputeId,
        hubSlug,
        selectedAward?.id,
        null, // fileId not required for updating review status
        body,
        false,
        resetForm
      )
    );
  };

  const handleEditRequest = (body) => {
    setRejectedAward(null);
    dispatch(
      actions.putUpdate(
        disputeId,
        hubSlug,
        body.awardId,
        null, // fileId not required for edits
        body
      )
    );
  };

  const handleExtendDeadline = (body, resetForm) => {
    dispatch(actions.putDeadlineUpdate(disputeId, body, resetForm));
  };

  const handleSendToParties = (award) => {
    setRejectedAward(null);
    dispatch(
      actions.putUpdate(
        disputeId,
        hubSlug,
        award.id,
        award?.attributes?.file?.id,
        {
          status: SENT_TO_PARTIES,
        },
        false
      )
    );
  };

  const openSendToHub = () => {
    dispatch(actions.handleIsSendToHubOpen(true));
  };

  const closeSendToHub = () => {
    dispatch(actions.handleIsSendToHubOpen(false));
  };

  const openReviewTerms = () => {
    dispatch(actions.handleIsReviewOpen(true));
  };

  const closeReviewTerms = () => {
    dispatch(actions.handleIsReviewOpen(false));
  };

  const openExtendDeadline = () => {
    dispatch(actions.handleIsExtendDeadlineOpen(true));
  };

  const closeExtendDeadline = () => {
    dispatch(actions.handleIsExtendDeadlineOpen(false));
  };

  const openSendToParties = () => {
    dispatch(actions.handleIsSendToPartiesOpen(true));
  };

  const closeSendToParties = () => {
    dispatch(actions.handleIsSendToPartiesOpen(false));
  };

  const openRequestEdits = () => {
    dispatch(actions.handleIsRequestEditsOpen(true));
  };

  const closeRequestEdits = () => {
    dispatch(actions.handleIsRequestEditsOpen(false));
  };

  useEffect(() => void dispatch(disputeUserActions.getIndex(disputeId)), [disputeId, dispatch]);

  useEffect(() => {
    dispatch(actions.getIndex(disputeId));
  }, [disputeId, dispatch]);

  useEffect(() => {
    setRejectedAward(rejectedAA);
  }, [rejectedAA]);

  const canCreateAward = useAccess("create", "arbitral-award");
  const canReviewAward = useAccess("review", "arbitral-award");
  const canRequestEdits = useAccess("request-edits", "arbitral-award");

  const actionList = [
    {
      label: "arbitral_award.upload_new_award.button",
      action: openSendToHub,
      condition: canCreateAward,
    },
    {
      label: "arbitral_award.download_template.button",
      action: downloadTemplate,
      condition: canCreateAward,
    },
    sharableAwards.length
      ? {
          label: "arbitral_award.share_award_with_parties.button",
          action: openSendToParties,
          condition: canCreateAward,
        }
      : null,
    draftAwards.length
      ? {
          label: "arbitral_award.review_draft_award.button",
          action: openReviewTerms,
          condition: canReviewAward,
        }
      : null,
    editableAwards.length
      ? {
          label: "arbitral_award.request_edits.button",
          action: openRequestEdits,
          condition: canRequestEdits,
        }
      : null,
  ].filter(Boolean);

  const availableActions = actionList.filter((a) => a.condition);

  const onChangeDeadlineDropdown = (val) => {
    setCurrentDeadlineAction(val.key);
    openExtendDeadline();
  };

  const deadlineExtensionDropdownList = [
    {
      label: "arbitral_award.extend_issue_deadlines.text",
      key: "extend-issue",
      condition: canReviewAward,
    },
    {
      label: "arbitral_award.request_issue_deadlines.text",
      key: "request-issue",
      condition: canCreateAward,
    },
    {
      label: "arbitral_award.extend_edit_deadlines.text",
      key: "extend-edits",
      condition: canCreateAward,
    },
    {
      label: "arbitral_award.request_edit_deadlines.text",
      key: "request-edits",
      condition: canRequestEdits,
    },
  ].filter((a) => a.condition);

  const handlePageChange = (page) => {
    dispatch(actions.getIndex(disputeId, page));
  };

  const deleteAward = (id) => void dispatch(fileActions.deleteFile(id));
  
  const handleShowReview = (award) => {
    dispatch(actions.getShow(disputeId, award?.id));
  };

  const getAwardStatus = (award) => {
    const status = award?.attributes?.status;
    const hubAction = award?.attributes?.hubAction;
    const isEligibleForSharing = award?.attributes?.eligibleForPartySharing;
    const awardStatus =
      status === EDITS_REQUESTED
        ? EDITS_REQUESTED
        : isEligibleForSharing
        ? HUB_APPROVED
        : status !== SENT_TO_PARTIES && hubAction
        ? hubAction
        : status;

    return awardStatus;
  };

  const handleAwardAction = (award) => {
    const status = getAwardStatus(award);
    setSelectedAward(award);

    if (STATUSES_AND_ACTIONS[status]?.hasAccess) STATUSES_AND_ACTIONS[status].action(award);
  };

  const handleActionText = (award) => {
    const status = getAwardStatus(award);

    if (STATUSES_AND_ACTIONS[status]?.hasAccess) {
      return STATUSES_AND_ACTIONS[status]?.text;
    } else {
      return "-";
    }
  };

  const STATUSES_AND_ACTIONS = {
    [SENT_TO_HUB]: {
      hasAccess: canReviewAward,
      action: openReviewTerms,
      text: <Translation text="terms_of_reference.review.action" />,
    },
    [HUB_APPROVED]: {
      hasAccess: canCreateAward,
      action: handleSendToParties,
      text: <Translation text="arbitral_award.sign_and_share.action" />,
    },
    [HUB_REQUESTED_EDITS]: {
      hasAccess: canCreateAward,
      action: handleShowReview,
      text: <Translation text="arbitral_award.see_reviews.action" />,
    },
    [EDITS_REQUESTED]: {
      hasAccess: true,
      action: handleShowReview,
      text: <Translation text="arbitral_award.see_reviews.action" />,
    },
    [SENT_TO_PARTIES]: {
      hasAccess: !canCreateAward && !canReviewAward,
      action: handleShowReview,
      text: <Translation text="arbitral_award.request_edits.action" />,
    },
  };

  return dispute ? (
    <IndexRender
      isAwardIssued={isAwardIssued}
      onPageChange={handlePageChange}
      hubSlug={hubSlug}
      disputeId={disputeId}
      dispute={dispute}
      participants={participants}
      errors={errors}
      selectedAward={selectedAward}
      rejectedAward={rejectedAward}
      downloadTemplate={downloadTemplate}
      downloadFile={downloadFile}
      closeSendToHub={closeSendToHub}
      isSendToHubVisible={isSendToHubVisible}
      isReviewAwardVisible={isReviewAwardVisible}
      openReviewTerms={openReviewTerms}
      closeReviewTerms={closeReviewTerms}
      handleReviewTerms={handleReviewTerms}
      isSendToPartiesVisible={isSendToPartiesVisible}
      openSendToParties={openSendToParties}
      closeSendToParties={closeSendToParties}
      handleSendToParties={handleSendToParties}
      isLoading={isLoading}
      actionList={availableActions}
      deadlineList={deadlineList(meta)}
      arbitralAwards={arbitralAwards}
      handleSendToHub={handleSendToHub}
      isSubmitting={isSubmitting}
      isExtendDeadlineVisible={isExtendDeadlineVisible}
      closeExtendDeadline={closeExtendDeadline}
      handleExtendDeadline={handleExtendDeadline}
      isRequestEditsVisible={isRequestEditsVisible}
      handleEditRequest={handleEditRequest}
      closeRequestEdits={closeRequestEdits}
      onChangeDeadlineDropdown={onChangeDeadlineDropdown}
      currentDeadlineAction={currentDeadlineAction}
      deadlineExtensionDropdownList={deadlineExtensionDropdownList}
      sharableAwards={sharableAwards}
      meta={meta}
      canCreateAward={canCreateAward}
      canReviewAward={canReviewAward}
      extraCrumbs={extraCrumbs(hubSlug, disputeId)}
      getAwardStatus={getAwardStatus}
      deleteAward={deleteAward}
      openSendToHub={openSendToHub}
      handleAwardAction={handleAwardAction}
      handleActionText={handleActionText}
    />
  ) : (
    <Spinner />
  );
}

const deadlineList = (meta) => [
  {
    label: "arbitral_award.arbitral_award_edit_deadline.title",
    date: meta?.arbitralAwardEditDeadline,
    editDeadline: true,
  },
  {
    label: "arbitral_award.arbitral_award_deadline.title",
    date: meta?.arbitralAwardDeadline,
  },
  {
    label: "arbitral_award.additional_award_edit_deadline.title",
    date: meta?.additionalAwardEditDeadline,
    editDeadline: true,
  },
  {
    label: "arbitral_award.additional_award_deadline.title",
    date: meta?.additionalAwardDeadline,
  },
];

const extraCrumbs = (hubSlug, disputeId) => [
  {
    path: `/${hubSlug}/disputes/${disputeId}/arbitral-awards`,
    breadcrumbName: <Translation text="arbitral_award.arbitral_award.title" />,
  },
];
