import React from "react";
import { useDispatch } from "react-redux";
import { Table, Typography, Badge, Space } from "antd";
import { FileTwoTone } from "@ant-design/icons";

import { fromMeta } from "../../transformers/paginationTransformer";
import DateTime from "../Common/DateTime";
import NoData from "../Common/NoData";
import Spinner from "../Common/Spinner";
import Anchor from "../Common/Anchor";
import Translation from "../Common/Translation";
import * as fileActions from "../../redux/actions/fileActions";
import { torStatusColor } from "../Common/ProceduralOrderStatus";
import { HUB_REQUESTED_EDITS } from "../../redux/constants/ProceduralOrderStatuses";

export default function OrderList({
  orders,
  disputeId,
  hubSlug,
  onPageChange,
  versions,
  indexMeta,
  canCreate,
  canReview,
  handlePoAction,
  handleShowReview,
  handleActionText,
  handleGetVersions,
  handleUploadRevisedOrderOpen,
}) {
  const dispatch = useDispatch();
  const previewFile = (fileId) => dispatch(fileActions.getShow(fileId));

  if (orders?.length) {
    return (
      <div className="jur-case-files-table-overview">
        <Table
          columns={columns(
            canCreate,
            previewFile,
            handlePoAction,
            handleActionText,
            handleUploadRevisedOrderOpen,
            handleShowReview
          )}
          expandable={{
            onExpand: (_, record) => {
              handleGetVersions(record?.id);
            },
            rowExpandable: (record) => record?.attributes?.version > 1,
            expandedRowRender: (record) => {
              if (record?.attributes?.version === 1) return null;
              return Boolean(versions[record?.id]) ? (
                versionTable(
                  canCreate,
                  versions[record?.id],
                  hubSlug,
                  disputeId,
                  previewFile,
                  handleShowReview,
                  handleUploadRevisedOrderOpen,
                  handlePoAction,
                  handleActionText
                )
              ) : (
                <Spinner />
              );
            },
          }}
          className="jur-table-white-header"
          dataSource={orders}
          rowKey="id"
          pagination={fromMeta(indexMeta, (page) => {
            onPageChange(page);
          })}
        />
      </div>
    );
  } else {
    return (
      <NoData
        description={
          <Typography.Title level={5} strong>
            <Translation text="case_files.no_files.text" />
          </Typography.Title>
        }
      />
    );
  }
}

const columns = (
  canCreate,
  previewFile,
  handlePoAction,
  handleActionText,
  handleUploadRevisedOrderOpen,
  handleShowReview
) => [
  {
    title: <Translation text="procedural_order.name.column" />,
    dataIndex: ["attributes", "file", "name"],
    render: (text, record) => {
      return (
        <a href="#/" onClick={() => previewFile(record?.attributes?.file?.id)}>
          <Space size="large">
            <FileTwoTone className="jur-table-body-file-render-icon" twoToneColor=" #2777EF" />
            {text}
          </Space>
        </a>
      );
    },
  },
  {
    title: <Translation text="procedural_order.status.column" />,
    dataIndex: ["attributes", "status"],
    render: (s) => <Badge color={torStatusColor(s)} text={<span className="jur-case-status">{s}</span>} />,
  },
  {
    title: <Translation text="procedural_order.order.column" />,
    dataIndex: ["attributes", "number"],
    render: (text, record) => (
      <div>
        {text}(V.{record?.attributes?.version})
      </div>
    ),
  },
  {
    title: <Translation text="procedural_order.issued_on.column" />,
    render: (text, record) => {
      return <DateTime epochSeconds={record?.attributes?.createdAt} onlyDate />;
    },
  },
  {
    title: <Translation text="procedural_order.actions.column" />,
    dataIndex: ["attributes", "status"],
    render: (status, record) =>
      status === HUB_REQUESTED_EDITS && canCreate ? (
        <Space size="large">
          <a href="#/" onClick={() => handleShowReview(record)}>
            <Translation text="procedural_order.see_reviews.column" />
          </a>
          <a href="#/" onClick={() => handleUploadRevisedOrderOpen(record)}>
            <Translation text="procedural_order.upload_revised_version.column" />
          </a>
        </Space>
      ) : (
        <a href="#/" onClick={() => handlePoAction(record)}>{handleActionText(status)}</a>
      ),
  },
];

export const versionTable = (
  canCreate,
  orders,
  hubSlug,
  disputeId,
  previewFile,
  handleShowReview,
  handleUploadRevisedOrderOpen,
  handlePoAction,
  handleActionText
) => {
  const columns = [
    {
      title: <Translation text="procedural_order.name.column" />,
      dataIndex: ["attributes", "file", "name"],
      render: (text, record) => {
        return (
          <Anchor onClick={() => previewFile(record?.attributes?.file?.id)}>
            <FileTwoTone className="jur-table-body-file-render-icon" twoToneColor=" #2777EF" />
            {text}
          </Anchor>
        );
      },
    },
    {
      title: <Translation text="procedural_order.status.column" />,
      dataIndex: ["attributes", "status"],
      render: (s) => <Badge color={torStatusColor(s)} text={<span className="jur-case-status">{s}</span>} />,
    },
    {
      title: <Translation text="procedural_order.order.column" />,
      dataIndex: ["attributes", "number"],
      render: (text, record) => (
        <div>
          {text}(V.{record?.attributes?.version})
        </div>
      ),
    },
    {
      title: <Translation text="procedural_order.issued_on.column" />,
      render: (text, record) => {
        return <DateTime epochSeconds={record?.attributes?.createdAt} onlyDate />;
      },
    },
    {
      title: <Translation text="procedural_order.actions.column" />,
      dataIndex: ["attributes", "status"],
      render: (status, record) =>
        status === HUB_REQUESTED_EDITS && canCreate ? (
          <Space size="large">
            <Anchor onClick={() => handleShowReview(record)}>
              <Translation text="procedural_order.see_reviews.column" />
            </Anchor>
          </Space>
        ) : (
          <Anchor onClick={() => handlePoAction(record)}>{handleActionText(status)}</Anchor>
        ),
    },
  ];
  return <Table columns={columns} dataSource={orders} pagination={false} showHeader={false} />;
};
