import React, { useState } from "react";
import { Alert, Button, Modal, Space } from "antd";
import { Formik } from "formik";
import { Checkbox, Form, FormItem, Select } from "formik-antd";

import { useErrorHider } from "../../helpers/errorHelpers";
import { modalProps } from "../../helpers/modalHelpers";
import Translation from "../Common/Translation";
import Uploader, { UploadButtonRender } from "../Common/Uploader";
import ConfigVariables from "../../locales/en/translation.json";
import ModalWrapper from "../Common/ModalWrapper";

export default function FileUploadForm({ visible, closeSendDocuments, handleSendDocuments, isLoading, isWitness }) {
  const [fileId, setFileId] = useState(null);
  const errorProps = useErrorHider();

  const documentTypes = [
    "case_files.opening_statment.text",
    "case_files.closing.text",
    "case_files.reply_to_order.text",
    "case_files.evidentiary_document.text",
    isWitness ? "case_files.witness_statment.text" : null,
    "case_files.expert_report.text",
  ].map((c) => ConfigVariables[c]);

  const uploaderProps = {
    maxCount: 1,
    multiple: false,
    onRemove: () => {
      setFileId(null);
    },
    onDone: (file) => {
      setFileId(file.id);
    },
    allowDocumentOnly: true,
  };

  const footer = (values) => {
    return [
      <Button
        type="primary"
        loading={isLoading}
        disabled={!Boolean(fileId) || isLoading || !values.documentType}
        onClick={() => handleSendDocuments(fileId, values.documentType, values.signatureRequired || false)}
      >
        <Translation text="evidence_request.send.button" pad={true} />
      </Button>,
    ];
  };

  const onCancel = () => {
    closeSendDocuments();
    setFileId(null);
    errorProps.hide();
  };

  return (
    <Formik initialValues={{}}>
      {({ values }) => (
        <Modal
          {...modalProps()}
          maskClosable={false}
          width={768}
          visible={visible}
          onCancel={onCancel}
          footer={footer(values)}
          title={<Translation text="evidence_request.send_documents.text" />}
        >
          <ModalWrapper>
            <Form layout="vertical">
              <FormItem
                label={<Translation text="evidence_request.select_type_of_document.text" />}
                name="documentType"
              >
                <Select placeholder="Document Type" name="documentType">
                  {documentTypes.map((e) => e && <Select.Option value={e}>{e}</Select.Option>)}
                </Select>
              </FormItem>
              <Space direction="vertical" size="large" className="jur-width-full">
                <FormItem name="signatureRequired">
                  <Checkbox name="signatureRequired">
                    <Translation text="evidence_request.do_you_want_to_digitally_sign_the_file.text" />
                  </Checkbox>
                  {values?.signatureRequired ? (
                    <Alert
                      type="info"
                      banner={true}
                      message={<Translation text="evidence_request.sign_step.description" />}
                    />
                  ) : null}
                </FormItem>

                <FormItem name="file">
                  <Uploader {...uploaderProps}>
                    <UploadButtonRender
                      buttonProps={{ disabled: Boolean(fileId) }}
                      optional={true}
                      label="general.choose_file.button"
                    />
                  </Uploader>
                </FormItem>
              </Space>
            </Form>
          </ModalWrapper>
        </Modal>
      )}
    </Formik>
  );
}
