import { Col, Row } from "antd";
import React from "react";
import JurCopyright from "../Common/Jur/JurCopyright";
import "./layouts.less";

export default function AuthLayout({
  header,
  content,
  footer = <JurCopyright />,
}) {
  return (
    <div className="auth-grid-layout">
      <Row align="top" justify="end">
        {header}
      </Row>
      <Row justify="center" align="middle">
        <Col span={10}>{content}</Col>
      </Row>
      <Row justify="start">
        <Col offset={7} span={10}>
          {footer}
        </Col>
      </Row>
    </div>
  );
}
