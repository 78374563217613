import "@jurteam/justice-common-react/dist/styles/arbitrator-profile.less";
import "@jurteam/justice-common-react/dist/styles/main.less";
import { ConnectedRouter, push } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/less/main.less";
import { authenticateUserSucceeded } from "./redux/actions/authenticateActions";
// import { PersistGate } from "redux-persist/integration/react";
import configureStore, { history } from "./redux/store";
import * as serviceWorker from "./serviceWorker";

const { store } = configureStore();

let calledInit = false;
function init() {
  const payload = JSON.parse(localStorage.getItem("user-auth") || "{}");
  if (payload.access_token) {
    store.dispatch(authenticateUserSucceeded(payload));
    store.dispatch(push("/"));
  }
}
if (!calledInit) init();
window.store = store;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
