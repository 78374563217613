const logger = (store) => (next) => (action) => {
  console.group(action.type);
  console.info("Dispatching action: ", action);

  let result = next(action);

  console.info("Next state: ", store.getState());

  console.groupEnd();

  return result;
};

export default logger;
