import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {
  isSubmitting: false,
  isDeadlineRequestOpen: false,
};

export default function (state = initial, action) {
  switch (action.type) {
    case types.DEFENSIVEACTIONS_PUT_UPDATE_DEFENCE:
      return { ...state, isSubmitting: true };
    case types.DEFENSIVEACTIONS_HANDLE_UPDATE_DEFENCE:
      return { ...state, isSubmitting: false, errors: null };
    case types.DEFENSIVEACTIONS_POST_CREATE_COUNTERCLAIM:
      return { ...state, isSubmitting: true };
    case types.DEFENSIVEACTIONS_HANDLE_CREATE_COUNTERCLAIM:
      return { ...state, isSubmitting: false, errors: null };
    case types.DEFENSIVEACTIONS_PUT_UPDATE_COUNTERCLAIMREPLY:
      return { ...state, isSubmitting: true };
    case types.DEFENSIVEACTIONS_HANDLE_UPDATE_COUNTERCLAIMREPLY:
      return { ...state, isSubmitting: false, errors: null };
    case types.DEFENSIVEACTIONS_PUT_UPDATE_DEADLINE:
      return { ...state, isSubmitting: true };
    case types.DEFENSIVEACTIONS_HANDLE_UPDATE_DEADLINE:
      return {
        ...state,
        isSubmitting: false,
        isDeadlineRequestOpen: false,
        errors: null,
      };
    case types.DEFENSIVEACTIONS_HANDLE_IS_REQUEST_DEADLINE_OPEN:
      return { ...state, isDeadlineRequestOpen: action.payload };
    case types.DEFENSIVEACTIONS_HANDLE_ERROR:
      return {
        ...state,
        errors: errorMessages(action.error),
        isSubmitting: false,
      };
    default:
      return state;
  }
}
