import { selectMyDisputeUser } from "./disputesSelector";

export const authLoading = (state) => state.authenticate.loading;
export const selectErrors = (state) => state.authenticate.errors;
export const isAuthenticated = (state) => state.authenticate.isAuthenticated;
export const accessRules = (state) => state.authenticate.user.access_rules;
export const user = (state) => state.authenticate.user;
export const userDetails = (state) => state.authenticate.userDetails;
export const consentStatus = (state) => state.authenticate.consentStatus;
export const isFetchingConsent = (state) =>
  state.authenticate.isFetchingConsent;

export const selectAccessor = (state) =>
  selectMyDisputeUser(state) || state.authenticate.user;

export const selectRole = (state) => selectAccessor(state).role;
export const forgotPasswordData = (state) =>
  state.authenticate.forgotPasswordData;
