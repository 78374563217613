import { PlusOutlined } from "@ant-design/icons/lib/icons";
import { Button, Card, Col, Row, Typography, Space } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import Translation from "../../Common/Translation";
import AddPhaseForm from "./AddPhaseForm";
import PhaseListTable from "./PhaseListTable";

const { Title } = Typography;

export default function PhaseList({
  phases,
  onSavePhases,
  onUpdatePhases,
  isModified,
}) {
  const [isAddItemVisible, setIsAddItemVisible] = useState(false);

  phases = phases?.map((p, i) => ({ ...p, index: i }));

  const onAddPhase = (newPhase) => {
    if (phases.length === 0) newPhase.isCurrentPhase = true;
    if (typeof newPhase?.index === "number") {
      phases[newPhase?.index] = newPhase;
      onUpdatePhases(phases);
    } else onUpdatePhases(phases.concat(newPhase));
    setIsAddItemVisible(false);
  };

  const onDelete = (index) => {
    onUpdatePhases(phases.filter((x, i) => index !== i));
  };

  return (
    <Formik initialValues={{}}>
      {({ values, setFieldValue, resetForm }) => {
        const commonProps = {
          phases,
          onUpdatePhases,
          setIsAddItemVisible,
          resetForm,
          values,
          setFieldValue,
          isAddItemVisible,
          onAddPhase,
          onDelete,
        };

        return (
          <Card>
            <CTAHeader onSavePhases={onSavePhases} isModified={isModified} />
            <br />
            <PhaseListTable {...commonProps} />
            <br />
            <Space>
              <Button
                onClick={() => {
                  resetForm({ values: {} });
                  setIsAddItemVisible(true);
                }}
                icon={<PlusOutlined />}
              >
                Add Phase
              </Button>
              <Button onClick={onSavePhases} size="small" type="primary">
                Update Changes
              </Button>
              {isModified && <Typography.Text type="danger">Unsaved</Typography.Text>}
            </Space>
            <AddPhaseForm {...commonProps} />
          </Card>
        );
      }}
    </Formik>
  );
}

const CTAHeader = () => (
  <Row justify="space-between">
    <Col>
      <Title level={4}>
        <Translation text="phases.dispute_phases.title" />
      </Title>
    </Col>
  </Row>
);
