import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "assisted-dispute";
const catcher = logAndThrow(tag);

// Phases

export function phasesIndex(disputeId) {
  return Api.get(`/disputes/${disputeId}/assisted-dispute/phases`)
    .then((res) => res.data)
    .catch(catcher("phasesIndex"));
}

export function updatePhases(disputeId, phases) {
  return Api.put(`/disputes/${disputeId}/assisted-dispute/phases`, {
    phases,
  })
    .then((res) => res.data)
    .catch(catcher("updatePhases"));
}

// Last Update

export function showLastActivity(disputeId, group) {
  return Api.get(
    `/disputes/${disputeId}/assisted-dispute/last-activity?group=${group}`
  )
    .then((res) => res.data)
    .catch(catcher("showLastActivity"));
}

export function updateLastActivity(disputeId, group, lastUpdate) {
  return Api.put(
    `/disputes/${disputeId}/assisted-dispute/last-activity?group=${group}`,
    lastUpdate
  )
    .then((res) => res.data)
    .catch(catcher("updateLastActivity"));
}

// Task List

export function showTaskList(disputeId, group) {
  return Api.get(`/disputes/${disputeId}/assisted-dispute/tasks?group=${group}`)
    .then((res) => res.data)
    .catch(catcher("showTaskList"));
}

export function updateTaskList(disputeId, group, tasks) {
  return Api.put(
    `/disputes/${disputeId}/assisted-dispute/tasks?group=${group}`,
    {
      tasks,
    }
  )
    .then((res) => res.data)
    .catch(catcher("updateTaskList"));
}

export function updateTaskStatus(disputeId, taskId, status) {
  return Api.put(`/disputes/${disputeId}/assisted-dispute/tasks/${taskId}`, {
    status,
  })
    .then((res) => res.data)
    .catch(catcher("updateTaskStatus"));
}
