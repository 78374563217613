import { errorMessages } from "../../helpers/errorHelpers";
import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_FAILED,
  AUTHENTICATE_USER_SUCCEEDED,
  AUTH_HANDLE_CURRENT_USER,
  AUTH_HANDLE_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCEEDED,
  LOGOUT_USER,
  LOGOUT_USER_FAILED,
  LOGOUT_USER_SUCCEEDED,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCEEDED,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCEEDED,
  SIGN_GET_CONSENT,
  SIGN_HANDLE_CONSENT,
  SIGN_HANDLE_UPDATE_CONSENT,
  USERPROFILE_HANDLE_UPDATE,
} from "../constants/constants";

const initialState = {
  user: {},
  isAuthenticated: false,
  forgotPasswordData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
    case REGISTER_USER:
      return { ...state, loading: true, errors: undefined };
    case AUTH_HANDLE_ERROR:
      return { ...state, loading: false, errors: errorMessages(action.error) };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, forgotPasswordData: action.payload };
    case AUTHENTICATE_USER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isAuthenticated: true,
        consentStatus: action.payload?.signatureStatus?.consentStatus,
      };

    case SIGN_HANDLE_UPDATE_CONSENT:
      return {
        ...state,
        consentStatus: true,
      };
    case SIGN_GET_CONSENT:
      return {
        ...state,
        isFetchingConsent: true,
      };
    case SIGN_HANDLE_CONSENT:
      return {
        ...state,
        isFetchingConsent: false,
      };

    case AUTHENTICATE_USER_FAILED:
      return { ...state, loading: false, errors: errorMessages(action.error) };
    case RESET_PASSWORD:
      return { ...state, loading: true };
    case AUTH_HANDLE_CURRENT_USER:
      return { ...state, userDetails: action.payload?.data };
    case RESET_PASSWORD_FAILED:
      return { ...state, loading: false, errors: errorMessages(action.error) };
    case RESET_PASSWORD_SUCCEEDED:
      return { ...state, loading: false };
    case LOGOUT_USER:
      return { ...state, loading: true };
    case LOGOUT_USER_SUCCEEDED:
      return { ...state, loading: false, isAuthenticated: false, user: {} };
    case LOGOUT_USER_FAILED:
      return {
        ...state,
        loading: false,
        user: {},
        isAuthenticated: false,
        errors: errorMessages(action.error),
      };
    case FORGOT_PASSWORD_SUCCEEDED:
      return { ...state, loading: false };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPasswordData: {},
        loading: false,
        errors: errorMessages(action.error),
      };
    case REGISTER_USER_SUCCEEDED:
      return { ...state, loading: false };
    case REGISTER_USER_FAILED:
      return { ...state, loading: false, errors: errorMessages(action.error) };
    case USERPROFILE_HANDLE_UPDATE:
      return { ...state, user: { ...state.user, ...action.payload } };
    default:
      return state;
  }
};
