import { Typography } from "antd";
import { Form } from "formik-antd";
import React from "react";
import "./authenticate.less";

export default function AuthFormItem({
  label,
  name,
  rules = [],
  component,
  customLabel,
  ...otherProps
}) {
  return (
    <Form.Item
      required={true}
      label={
        customLabel ? (
          customLabel
        ) : label ? (
          <Typography.Title level={5}>{label}</Typography.Title>
        ) : null
      }
      name={name}
      rules={rules}
      className="jur-auth-form-item"
      {...otherProps}
    >
      {component}
    </Form.Item>
  );
}
