import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IndexRender from "./IndexRender";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import { useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { getIndex } from "../../redux/actions/disputeUserActions";
import { fetchIndex } from "../../redux/actions/genericActions";
import { postCreateSignature } from "../../redux/actions/signActions";
import {
  getFlowList,
  getVersions,
  postCreate,
  postCreateRevision,
  putUpdate,
} from "../../redux/actions/proceduralOrderActions";
import { BaseService } from "../../redux/BaseService";
import { selectParticipants } from "../../redux/selectors/disputeUsersSelector";
import {
  selectReviewable,
  selectRevisable,
  selectSharable,
  selectVersions,
} from "../../redux/selectors/proceduralOrderSelector";
import {
  HUB_APPROVED,
  SENT_TO_HUB,
  SENT_TO_PARTIES,
  HUB_REQUESTED_EDITS,
} from "../../redux/constants/ProceduralOrderStatuses";

const feature = "proceduralOrder";
const proceduralOrderService = new BaseService(feature);
export default function () {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();

  const canCreate = useAccess("create", "procedural-order");
  const canReview = useAccess("update-approval", "procedural-order");

  const dispatch = useDispatch();

  const proceduralOrders = useSelector(proceduralOrderService.select("index"));
  const indexMeta = useSelector(proceduralOrderService.select("indexMeta"));
  const errors = useSelector(proceduralOrderService.select("errors"));
  const {
    isFetchingIndex,
    isUploadOrderOpen,
    isShareOrderOpen,
    isUploadRevisedOrderOpen,
    isReviewOrderOpen,
    // isSubmitting,
  } = useSelector(proceduralOrderService.selectWhole());

  const revisableOrders = useSelector(selectRevisable);
  const sharableOrders = useSelector(selectSharable);
  const reviewableOrders = useSelector(selectReviewable);
  const participants = useSelector(selectParticipants);
  const versions = useSelector(selectVersions);
  const [selectedPO, setSelectedPO] = useState(null);

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/procedural-orders`,
      breadcrumbName: <Translation text="procedural_order.procedural_order.title" />,
    },
  ];

  const handleUploadOrderOpen = () => void dispatch(proceduralOrderService.setForm({ isUploadOrderOpen: true }));

  const handleUploadOrderClose = () => void dispatch(proceduralOrderService.setForm({ isUploadOrderOpen: false }));

  const handleUploadOrderSubmit = (body, resetForm) => {
    void dispatch(
      postCreate(
        disputeId,
        { ...body, receivers: [...participants].map((participant) => participant.disputeAssignmentId) },
        resetForm
      )
    );
  };

  const handleShareOrderOpen = () => {
    handleGetFlowList("sharing");
    void dispatch(proceduralOrderService.setForm({ isShareOrderOpen: true }));
  };

  const handleShareOrderClose = () => void dispatch(proceduralOrderService.setForm({ isShareOrderOpen: false }));

  const handleShareOrderSubmit = (body, resetForm) => {
    void dispatch(
      postCreateSignature({
        fileIds: [body?.attributes?.file?.id],
        flow: "Procedural Order",
        signType: "Self Sign",
        uiRedirectUrl: `/${hubSlug}/disputes/${disputeId}/procedural-orders`,
        disputeId,
        hubSlug,
        signableId: body?.id,
      })
    );
  };

  const handleUploadRevisedOrderOpen = (record) => {
    handleGetFlowList("revisions");
    setSelectedPO(record);
    dispatch(proceduralOrderService.setForm({ isUploadRevisedOrderOpen: true }));
  };

  const handleUploadRevisedOrderClose = () =>
    void dispatch(proceduralOrderService.setForm({ isUploadRevisedOrderOpen: false }));

  const handleUploadRevisedOrderSubmit = (body, resetForm) => {
    void dispatch(postCreateRevision(disputeId, body, resetForm));
  };

  const handleReviewOrderOpen = () => {
    handleGetFlowList("hub-actions");
    dispatch(proceduralOrderService.setForm({ isReviewOrderOpen: true }));
  };

  const handleReviewOrderClose = () => void dispatch(proceduralOrderService.setForm({ isReviewOrderOpen: false }));

  const handleReviewOrderSubmit = (body, resetForm) => void dispatch(putUpdate(disputeId, body, resetForm));

  const handleGetFlowList = (flow) => void dispatch(getFlowList(disputeId, flow));

  const handleGetVersions = (orderId) => void dispatch(getVersions(disputeId, orderId));

  const handlePageChange = (page) => {
    dispatch(fetchIndex(feature)(disputeId, page));
  };

  const handlePoAction = (record) => {
    const torStatus = record?.attributes?.status;
    setSelectedPO(record);
    if (STATUSES_AND_ACTIONS[torStatus]?.hasAccess) STATUSES_AND_ACTIONS[torStatus].action(record);
  };

  const handleShowReview = (record) => {
    setSelectedPO(record);
  };

  const handleActionText = (torStatus) => {
    if (STATUSES_AND_ACTIONS[torStatus]?.hasAccess) {
      if (torStatus === SENT_TO_PARTIES) {
        return "-";
      } else if (torStatus === HUB_APPROVED) {
        return <Translation text="terms_of_reference.sign_and_share.action" />;
      } else if (torStatus === SENT_TO_HUB) {
        return <Translation text="procedural_order.review.column" />;
      } else {
        return torStatus;
      }
    } else {
      return "-";
    }
  };

  const STATUSES_AND_ACTIONS = {
    [SENT_TO_HUB]: {
      hasAccess: canReview,
      action: handleReviewOrderOpen,
    },
    [HUB_APPROVED]: {
      hasAccess: canCreate,
      action: (record) => handleShareOrderSubmit(record),
    },
    [HUB_REQUESTED_EDITS]: {
      hasAccess: canCreate,
      action: () => null,
    },
    [SENT_TO_PARTIES]: {
      hasAccess: !canCreate && !canReview,
      action: () => null,
    },
  };

  useEffect(() => {
    dispatch(fetchIndex(feature)(disputeId));
    if (canCreate) {
      dispatch(getFlowList(disputeId, "sharing"));
      dispatch(getFlowList(disputeId, "revisions"));
    }
    if (canReview) dispatch(getFlowList(disputeId, "hub-actions"));
  }, [disputeId, dispatch, canCreate, canReview]);

  useEffect(() => void dispatch(getIndex(disputeId)), [disputeId, dispatch]);

  return dispute ? (
    <IndexRender
      selectedPO={selectedPO}
      disputeParams={{ disputeId, dispute, hubSlug }}
      extraCrumbs={extraCrumbs}
      canCreate={canCreate}
      canReview={canReview}
      isUploadOrderOpen={isUploadOrderOpen}
      handleUploadOrderOpen={handleUploadOrderOpen}
      handleUploadOrderClose={handleUploadOrderClose}
      handleUploadOrderSubmit={handleUploadOrderSubmit}
      isShareOrderOpen={isShareOrderOpen}
      handleShareOrderOpen={handleShareOrderOpen}
      handleShareOrderClose={handleShareOrderClose}
      handleShareOrderSubmit={handleShareOrderSubmit}
      isUploadRevisedOrderOpen={isUploadRevisedOrderOpen}
      handleUploadRevisedOrderOpen={handleUploadRevisedOrderOpen}
      handleUploadRevisedOrderClose={handleUploadRevisedOrderClose}
      handleUploadRevisedOrderSubmit={handleUploadRevisedOrderSubmit}
      isReviewOrderOpen={isReviewOrderOpen}
      handleReviewOrderOpen={handleReviewOrderOpen}
      handleReviewOrderClose={handleReviewOrderClose}
      handleReviewOrderSubmit={handleReviewOrderSubmit}
      proceduralOrders={proceduralOrders}
      participants={participants}
      handleGetFlowList={handleGetFlowList}
      revisableOrders={revisableOrders}
      reviewableOrders={reviewableOrders}
      sharableOrders={sharableOrders}
      handleGetVersions={handleGetVersions}
      versions={versions}
      errors={errors}
      isFetchingIndex={isFetchingIndex}
      indexMeta={indexMeta}
      onPageChange={handlePageChange}
      handlePoAction={handlePoAction}
      handleShowReview={handleShowReview}
      handleActionText={handleActionText}
    />
  ) : (
    <Spinner />
  );
}
