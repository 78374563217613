import { Card } from "antd";
import React from "react";
import DisputeHeader from "../Common/DisputeHeader";
import KycProfileRender from "../Common/Kyc/KycProfileRender";
import Spinner from "../Common/Spinner";

export default function ({
  profile,
  dispute,
  role,
  onSubmit,
  errors,
  errorProps,
  disputeId,
  hubSlug,
  extraCrumbs,
}) {
  return (
    <Card>
      <DisputeHeader
        dispute={dispute}
        disputeId={disputeId}
        hubSlug={hubSlug}
        extraCrumbs={extraCrumbs}
      >
        {profile ? (
          <KycProfileRender
            role={role}
            kyc={profile}
            title="general.edit_profile.title"
            titleLevel={3}
            onSubmit={onSubmit}
            errors={errors}
            errorProps={errorProps}
          />
        ) : (
          <Spinner />
        )}
      </DisputeHeader>
    </Card>
  );
}
