import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";
import { UPLOADED } from "../constants/FileStatuses";

const InitialKyc = { files: [] };
const InitialUserInvites = [];

const initial = {
  disputeId: null,
  disputeInfo: null,
  currentStep: 0,
  isErrorHidden: false,
  kyc: InitialKyc,
  mainRespondent: null,
  isNextDisabled: true,
  isLoadingKYC: true,
};

export default function (state = initial, action) {
  const payload = action.payload;

  if (action.type === types.RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_FORM)
    return {
      ...state,
      isErrorHidden: true,
      [stateNameOf(action.type)]: payload,
      isNextDisabled: false,
    };

  // handle form updates
  if (
    action.type.startsWith("RESPONSEFILING_HANDLE_UPDATE_") &&
    action.type.endsWith("_FORM")
  )
    return {
      ...state,
      isErrorHidden: true,
      [stateNameOf(action.type)]: payload,
    };

  // disable Next button on start of a API call
  if (action.type.startsWith("RESPONSEFILING_PUT_UPDATE_"))
    return { ...state, isErrorHidden: false, errors: {}, isNextDisabled: true };

  switch (action.type) {
    // Disable Save while uploading files
    case types.FILE_PUT_UPDATE:
    case types.FILE_HANDLE_UPDATE:
      return {
        ...state,
        isNextDisabled: true,
      };
    case types.FILE_HANDLE_ERROR:
      return { ...state, isNextDisabled: false };

    case types.RESPONSEFILING_HANDLE_START:
      return {
        ...initial,
        isNextDisabled: false,
        currentStep: Boolean(state.currentStep)
          ? state?.currentStep
          : initial.currentStep,
      };
    case types.RESPONSEFILING_HANDLE_ERROR:
      let isLoadingKYC = state.isLoadingKYC;
      if (action.error?.config?.url.endsWith("/kyc")) {
        isLoadingKYC = false;
      }
      return {
        ...state,
        errors: errorMessages(action.error),
        isNextDisabled: false,
        isLoadingKYC,
      };
    case types.RESPONSEFILING_HANDLE_STEP_BACK:
      return {
        ...state,
        isNextDisabled: false,
        currentStep: state.currentStep === 0 ? 0 : state.currentStep - 1,
      };
    case types.RESPONSEFILING_HANDLE_STEP_CHANGE:
      return {
        ...state,
        currentStep: Math.max(0, payload?.step),
      };

    case types.RESPONSEFILING_HANDLE_CREATE:
      return { ...state, ...action.payload, disputeId: action.payload.id };
    case types.DISPUTE_GET_SHOW:
      return {
        ...state,
        currentStep: payload.currentStep,
        disputeId: payload.disputeId,
        isNextDisabled: false,
      };
    case types.DISPUTE_HANDLE_SHOW:
      return {
        ...state,
        currentStep:
          stepFromName(payload.data?.attributes?.step) || state.currentStep,
        disputeInfo: payload.attributes,
        isNextDisabled: false,
      };
    case types.RESPONSEFILING_HANDLE_SHOW_DEFENCETEAM:
      const defenceTeam = payload.data.attributes;
      return {
        ...state,
        defenceTeam,
      };
    case types.RESPONSEFILING_HANDLE_UPDATE_DEFENCETEAM:
      return {
        ...state,
      };
    case types.RESPONSEFILING_GET_SHOW_KYC:
      return { ...state, isLoadingKYC: true, kyc: InitialKyc };
    case types.RESPONSEFILING_HANDLE_SHOW_KYC:
      const ui = payload.data.attributes.userInvites || state.userInvites;
      return {
        ...state,
        kyc: payload.data.attributes.kyc || state.kyc,
        userInvites: ui?.length ? ui : InitialUserInvites,
        mainRespondent:
          payload.data.attributes.mainRespondent || state.mainRespondent,
        isLoadingKYC: false,
      };
    case types.RESPONSEFILING_HANDLE_UPDATE_KYC:
      return {
        ...state,
        isNextDisabled: false,
        currentStep: 1,
      };
    case types.RESPONSEFILING_HANDLE_SHOW_RESPONDENT:
      const respondent = payload.data.attributes;
      return {
        ...state,
        respondent,
      };
    case types.RESPONSEFILING_HANDLE_UPDATE_RESPONDENT:
      return {
        ...state,
        isNextDisabled: false,
        currentStep:
          payload?.goBack === true
            ? 0
            : payload?.isDraft === true
            ? state.currentStep
            : state.currentStep + 1,
      };
    case types.RESPONSEFILING_HANDLE_SHOW_RESPONSEDETAILS:
      const responseDetails = payload.data.attributes;
      return {
        ...state,
        responseOptions: payload.meta,
        responseDetails,
      };
    case types.RESPONSEFILING_HANDLE_UPDATE_RESPONSEDETAILS:
      return {
        ...state,
        isNextDisabled: false,
        currentStep:
          payload?.goBack === true
            ? state.currentStep - 1
            : payload?.isDraft === true
            ? state.currentStep
            : state.currentStep + 1,
      };
    case types.RESPONSEFILING_GET_SHOW_RESPONSEFILES:
      return { ...state, responseFiles: undefined };
    case types.RESPONSEFILING_HANDLE_SHOW_RESPONSEFILES:
      const claimDocuments = payload.data.attributes;
      claimDocuments.files.forEach((f) => (f.status = UPLOADED)); // NOTE: Server may give wrong status
      return { ...state, isNextDisabled: false, responseFiles: claimDocuments };
    case types.RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES:
      return {
        ...state,
        isNextDisabled: false,
        currentStep:
          payload?.goBack === true
            ? state.currentStep - 1
            : payload?.isDraft === true
            ? state.currentStep
            : state.currentStep + 1,
      };
    case types.RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_STATUS:
      return {
        ...state,
        isNextDisabled: false,
        responseFiles: state.responseFiles.map((f) =>
          f.uid === payload.uid ? payload.file : f
        ),
      };
    case types.RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_DESCRIPTION:
      return {
        ...state,
        isNextDisabled: false,
      };
    case types.RESPONSEFILING_HANDLE_SHOW_PAYMENT:
      const payment = payload.data?.attributes || { transaction: {} };
      if (typeof payment.feeSplitPercentage === "undefined")
        payment.feeSplitPercentage = 100;
      if (!payment.transaction.amount)
        payment.transaction.amount = payment.meta?.totalAmount;
      if (payment.transaction?.receiptFile?.id) {
        payment["transaction.receiptFileId"] =
          payment.transaction.receiptFile.id;
        payment.transaction.receiptFileId = payment.transaction.receiptFile.id;
      }
      return {
        ...state,
        isNextDisabled: false,
        payment,
        paymentMeta: payload.meta,
      };
    case types.RESPONSEFILING_HANDLE_UPDATE_PAYMENT:
      return { ...state, isNextDisabled: false, currentStep: 4 };
    case types.RESPONSEFILING_HANDLE_SHOW_INVITES:
      return {
        ...state,
        isNextDisabled: false,
        userInvites: payload.data.attributes.invitees || [],
        userInvitesMeta: payload.meta,
      };
    case types.RESPONSEFILING_HANDLE_UPDATE_INVITES:
      return {
        ...state,
        isNextDisabled: false,
        currentStep:
          payload?.goBack === true
            ? state.currentStep - 1
            : payload?.isDraft === true
            ? state.currentStep
            : state.currentStep + 1,
      };
    case types.RESPONSEFILING_HANDLE_SHOW_SOD:
      return { ...state, isNextDisabled: false, sod: payload.data.attributes };
    case types.SIGN_HANDLE_ERROR:
      return { ...state, isNextDisabled: false };
    default:
      return state;
  }
}

function stateNameOf(type) {
  return {
    RESPONSEFILING_HANDLE_UPDATE_DEFENCETEAM_FORM: "defenceTeam",
    RESPONSEFILING_HANDLE_UPDATE_KYC_FORM: "kyc",
    RESPONSEFILING_HANDLE_UPDATE_INVITES_FORM: "userInvites",
    RESPONSEFILING_HANDLE_UPDATE_MAINRESPONDENT_FORM: "mainRespondent",
    RESPONSEFILING_HANDLE_UPDATE_RESPONSEDETAILS_FORM: "responseDetails",
    RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_FORM: "responseFiles",
    RESPONSEFILING_HANDLE_UPDATE_PAYMENT_FORM: "payment",
  }[type];
}

function stepFromName(name) {
  if (name === "KYC") return 0;
  if (name === "Response Details") return 1;
  if (name === "Response Files") return 2;
  if (name === "Invite") return 3;
  if (name === "Review") return 4;
  return 0;
}
