import { Breadcrumb } from "antd";
import React from "react";
import PageHeader from "../Common/PageHeader";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import Translation from "../Common/Translation";
import PhaseList from "./DisputePhases/PhaseList";
import "./styles.less";
import TaskLists from "./TaskList/TaskLists";

export default function IndexRender({
  disputeParams,
  phaseParams,
  lastUpdateProps,
  taskListProps,
  hasModified,
}) {
  return (
    <SliderMenuWrapper {...disputeParams}>
      <div className="jur-assisted-dispute-title-section">
        <Breadcrumb>
          <Breadcrumb.Item>{disputeParams?.dispute?.name}</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Translation text="assisted_dispute.assisted_dispute.title" />
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title="assisted_dispute.assisted_dispute.title" />
      </div>
      <br />
      <PhaseList {...phaseParams} isModified={hasModified.phase} />
      <TaskLists
        lastUpdateProps={lastUpdateProps}
        taskListProps={taskListProps}
        hasModified={hasModified}
      />
    </SliderMenuWrapper>
  );
}
