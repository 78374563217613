import AcceptHOC from "./AcceptHOC";
import IndexHOC from "./IndexHOC";
import RespondHOC from "./RespondHOC";
import WaitHOC from "./WaitHOC";

IndexHOC.Wait = WaitHOC;
IndexHOC.Accept = AcceptHOC;
IndexHOC.Respond = RespondHOC;

export default IndexHOC;
