import { logAndThrow } from "../../helpers/apiHelpers";
import { hubless } from "./Api";

const tag = "hubs";
const catcher = logAndThrow(tag);

export function index() {
  return hubless
    .get(`/hubs`)
    .then((res) => res.data)
    .catch(catcher("index"));
}

export function profile(hubSlug) {
  return hubless
    .get(`/${hubSlug}/hub/profile`)
    .then((res) => res.data)
    .catch(catcher("profile"));
}

export function arbitrators(hubSlug) {
  return hubless
    .get(`/${hubSlug}/hub/arbitrators?flow=Hub Profile`)
    .then((res) => res.data)
    .catch(catcher("arbitrators"));
}

export function inviteArbitrator(body) {
  return hubless
    .post(`/roster/invite-arbitrator`, body)
    .then((res) => res.data)
    .catch(catcher("invite arbitrator"));
}

export function rosterIndex(page) {
  return hubless
    .get(`/roster/arbitrators?page=${page}`)
    .then((res) => res.data)
    .catch(catcher("rosterIndex"));
}

export function rosterProfile(id) {
  return hubless
    .get(`/roster/arbitrators/${id}`)
    .then((res) => res.data)
    .catch(catcher("rosterProfile"));
}

export function updateRosterProfile(profileId, body) {
  return hubless
    .put(`/roster/arbitrators/${profileId}`, body)
    .catch(catcher("updateRosterProfile"));
}
