import React from "react";

function Section({ horizontal, vertical, children, ...rest }) {
  let style = {
    ...rest.style,
  };

  if (horizontal) {
    style = {
      ...style,
      ...rest.style,
      marginLeft: horizontal + "rem",
      marginRight: horizontal + "rem",
    };
  }

  if (vertical) {
    style = {
      ...style,
      ...rest.style,
      marginTop: vertical + "rem",
      marginBottom: vertical + "rem",
    };
  }

  return (
    <div {...rest} style={style}>
      {children}
    </div>
  );
}

export default Section;
