import { Card, Typography } from "antd";
import React from "react";
import "./styles.less";

const { Title } = Typography;

export default function ProfileSection({ title, content }) {
  return (
    <div className="jur-profile-section-wrapper">
      <Card>
        <Title level={4} strong>
          <div className="jur-title">{title}</div>
        </Title>
        <br />
        {content}
      </Card>
    </div>
  );
}
