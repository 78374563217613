import { Button, Modal, Radio, Space, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React from "react";
import { errorAsExtra, useErrorHider } from "../../helpers/errorHelpers";
import Editor from "../Common/Editor/Editor";
import Translation from "../Common/Translation";
import { handleChange } from "../DisputeFiling/helpers";
import "./styles.less";
import { modalProps } from "../../helpers/modalHelpers";
import ModalWrapper from "../Common/ModalWrapper";

export default function ReviewTermsForm({
  closeReviewTerms,
  visible,
  selectedTOR,
  handleReviewSubmit,
  errors,
  isLoading,
}) {
  const errorProps = useErrorHider();
  const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);
  
  return (
    <Formik
      initialValues={{
        torId: selectedTOR.id,
        approved: null,
        reasonforRejection: "",
      }}
    >
      {({ values, setFieldValue, resetForm }) => {
        const handler = handleChange(values, setFieldValue);
        const disableSubmit =
          values.approved === null || isLoading || (!values.approved && !values.reasonforRejection.length);
        return (
          <Modal
            width={768}
            bodyStyle={{ height: "50%" }}
            visible={visible}
            maskClosable={false}
            destroyOnClose={true}
            {...modalProps()}
            title={
              <Typography.Title level={5}>
                <Translation text="terms_of_reference.send_your_review.title" /> to{" "}
                {selectedTOR?.attributes?.file?.name}
              </Typography.Title>
            }
            onCancel={() => {
              resetForm();
              closeReviewTerms();
              errorProps.hide();
            }}
            footer={
              <Button
                loading={isLoading}
                disabled={disableSubmit}
                type="primary"
                onClick={() => errorProps.hideAndCall(handleReviewSubmit(values, resetForm))}
              >
                <Translation pad={true} text="terms_of_reference.send.button" />
              </Button>
            }
          >
            <ModalWrapper>
              <Form layout="vertical">
                <Space>
                  <FormItem name="approved">
                    <Radio.Group onChange={handler("approved")}>
                      <Radio value={true}>
                        <Translation text="terms_of_reference.accept_tor.text" />
                      </Radio>
                      <Radio value={false}>
                        <Translation text="terms_of_reference.hold_signature_request_edits.text" />
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                </Space>
                {values.approved === false && (
                  <FormItem
                    style={{ minHeight: 80 }}
                    label={<Translation text="terms_of_reference.mention_the_edits.text" />}
                    name="reasonforRejection"
                    {...apiErrorOf("reasonforRejection", true)}
                  >
                    <Editor onChange={handler("reasonforRejection")} className="jur-quill-editor-small" />
                  </FormItem>
                )}
              </Form>
            </ModalWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
}
