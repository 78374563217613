import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Typography } from "antd";
import { initialsOf } from "../../helpers/dataHelpers";
import Translation from "../Common/Translation";
import DateTime from "../Common/DateTime";
import Viewer from "../Common/Editor/Viewer";
import RequestEditsForm from "./RequestEditsForm";
import { selectGroup } from "../../redux/selectors/disputeFilesSelector";

const ShowAwardReview = ({
  rejectedAward,
  errors,
  selectedAward,
  canCreateAward,
  canReviewAward,
  onSubmit,
  closeRequestEdits,
  isLoading,
}) => {
  const userGroup = useSelector(selectGroup);

  const canClaimantRequestEdits = userGroup === "Claimant" && !rejectedAward?.attributes?.claimantAction?.description;
  const canRespondentRequestEdits =
    userGroup === "Respondent" && !rejectedAward?.attributes?.respondentAction?.description;

  const senderDetails = (requestedBy) => {
    return (
      <Row gutter={[16, 16]} justify="space-between">
        <Col>
          <span className="jur-navbar-user-icon">{initialsOf(requestedBy)}</span>
          <span className="jur-rejected-review-header">{requestedBy}</span>
        </Col>
        <Col>
          <DateTime epochSeconds={rejectedAward?.attributes?.createdAt} onlyDate={true} />
        </Col>
      </Row>
    );
  };

  const AwardReview = ({ reviews }) =>
    reviews.map((review) => {
      return (
        review &&
        review?.description && (
          <Row gutter={[8, 16]} className="jur-award-review-row">
            <Col span={24}>{senderDetails(review?.requestedBy)}</Col>
            <Col span={24}>
              <Viewer value={review?.description} />
            </Col>
          </Row>
        )
      );
    });

  return (
    <Card>
      <Typography.Title level={3}>{rejectedAward?.attributes?.file?.name}</Typography.Title>
      <Row>
        <Col span={24}>
          <Typography.Text className="secondary-color">
            <Translation text="terms_of_reference.reviews.button" />
          </Typography.Text>
        </Col>
        <Col span={24}>
          <AwardReview
            reviews={[
              rejectedAward?.attributes?.hubAction,
              rejectedAward?.attributes?.claimantAction,
              rejectedAward?.attributes?.respondentAction,
            ]}
          />
        </Col>
      </Row>
      {(canClaimantRequestEdits || canRespondentRequestEdits) && !canCreateAward && (
        <RequestEditsForm
          errors={errors}
          selectedAward={selectedAward}
          onSubmit={onSubmit}
          closeRequestEdits={closeRequestEdits}
          isLoading={isLoading}
        />
      )}
    </Card>
  );
};

export default ShowAwardReview;
