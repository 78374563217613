import * as types from "../constants/constants";

export const getShow = () => ({
  type: types.USERPROFILE_GET_SHOW,
});

export const handleShow = (data) => ({
  type: types.USERPROFILE_HANDLE_SHOW,
  payload: data,
});

export const putUpdate = (body) => ({
  type: types.USERPROFILE_PUT_UPDATE,
  payload: { body },
});

export const handleUpdate = (USERPROFILE) => ({
  type: types.USERPROFILE_HANDLE_UPDATE,
  payload: USERPROFILE,
});

export const handleUpdateForm = (body) => ({
  type: types.USERPROFILE_HANDLE_UPDATE_FORM,
  payload: body,
});

export const handleError = (e) => ({
  type: types.USERPROFILE_HANDLE_ERROR,
  error: e,
});
