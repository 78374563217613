import { logAndThrow, uploadSafeUrl } from "../../helpers/apiHelpers";
import Api, { hubless } from "./Api";

const tag = "files";
const catcher = logAndThrow(tag);

export function upload(file, { onProgress, onSuccess, onError }) {
  return createUploadUrl(file.name).then((uploadInfo) => {
    const uploadUrl = uploadSafeUrl(uploadInfo[file.name]);
    return hubless
      .put(uploadUrl, file, {
        onUploadProgress: (e) => {
          onProgress({ percent: (e.loaded / e.total) * 100 });
        },
        headers: {
          "Content-Type": file.type,
        },
      })
      .then((storageRes) => {
        onSuccess(storageRes);
        return [uploadInfo[file.name], file, storageRes];
      })
      .catch((e) => {
        onError(e);
        return catcher("upload")(e);
      });
  });
}

export function update(id, body) {
  return Api.put(`/files/${id}`, body).catch(catcher("upload"));
}

export function remove(id, hubSlug) {
  return Api.delete(`/files/${id}`).catch(catcher("remove"));
}

export function show(id) {
  return Api.get(`/files/${id}`)
    .then((r) => r.data)
    .catch(catcher("show"));
}

export function createUploadUrl(fileName) {
  return Api.post(`/files/upload`, { fileName })
    .then((res) => ({ [fileName]: res.data.data }))
    .catch(catcher(`createUploadUrl identifier: ${fileName}`));
}

export function createUploadUrls(fileNames) {
  const calls = fileNames.map(createUploadUrl);
  return Promise.all(calls).then((values) => values.reduce(objectConcat, {}));
}

function objectConcat(acc, object) {
  return { ...acc, ...object };
}
