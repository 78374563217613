import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {
  disputeUsers: [],
  disputeUser: null,
  isUpdating: false,
  isFetching: false,
  isOpen: false,
  isMotivaionOpen: false,
  indexRequestPayload: {},
};

export default function (
  state = initial,
  { payload, type, error, requestPayload }
) {
  switch (type) {
    case types.DISPUTEUSER_HANDLE_INDEX:
      return {
        ...state,
        disputeUsers: payload.data?.attributes,
        indexRequestPayload: requestPayload,
      };
    case types.DISPUTEUSER_HANDLE_ERROR:
      return { ...state, errors: errorMessages(error), isUpdating: false };
    case types.DISPUTEUSER_PUT_UPDATE:
      return { ...state, isUpdating: true, errors: {} };
    case types.DISPUTEUSER_GET_SHOW:
      return { ...state, isFetching: true };
    case types.DISPUTEUSER_HANDLE_SHOW:
      const attrs = payload.data?.attributes;
      return {
        ...state,
        disputeUser: { ...attrs, files: attrs.files || [] },
        isFetching: false,
      };
    case types.DISPUTEUSER_HANDLE_IS_OPEN:
      return {
        ...state,
        isOpen: payload,
        isMotivaionOpen: false,
        motivation: "",
      };
    case types.DISPUTEUSER_PUT_UPDATE_STATUS:
      return { ...state, isUpdating: true };
    case types.DISPUTEUSER_HANDLE_UPDATE_STATUS:
      return { ...state, isOpen: false, isUpdating: false };
    case types.DISPUTEUSER_HANDLE_MOTIVATION_FORM:
      return { ...state, motivation: payload };
    case types.DISPUTEUSER_HANDLE_IS_MOTIVATION_OPEN:
      return { ...state, isMotivaionOpen: payload };
    default:
      return state;
  }
}
