import { CLEAR_ERROR, SET_ERROR } from "../constants/constants";

export const setError = (error) => {
  return {
    type: SET_ERROR,
    error: {
      code: error.code,
      message: error.message,
    },
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};
