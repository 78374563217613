import { push } from "connected-react-router";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  errorMessage,
  successMessage,
} from "../../components/Common/Feedback/Message";
import { successNotification } from "../../components/Common/Feedback/Notification";
import { handleReset } from "../actions/fileActions";
import * as actions from "../actions/hearingActions";
import { handleUpdateSlug } from "../actions/hubActions";
import * as signActions from "../actions/signActions";
import * as timelineActions from "../actions/timelineActions";
import * as apis from "../apis/hearingsApi";
import * as types from "../constants/constants";
import { CANCELLED } from "../constants/HearingStatuses";

const tag = "hearings";

export function* getIndex(action) {
  const { disputeId, page, flow } = action.payload;
  try {
    const res = yield call(apis.index, disputeId, page, flow);
    yield put(actions.handleIndex(res, flow));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShow(action) {
  const { disputeId, hearingId } = action.payload;
  try {
    const res = yield call(apis.show, disputeId, hearingId);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreate(action) {
  const { disputeId, body } = action.payload;
  try {
    const res = yield call(apis.create, disputeId, body);
    yield put(actions.handleCreate(res));
    successNotification("hearings.scheduled_successfully.text");
    yield put(actions.getIndex(disputeId,1,'upcoming'));
    yield put(actions.handlePostCreateSuccess());
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdate(action) {
  const { disputeId, hearingId, body } = action.payload;
  try {
    const res = yield call(apis.update, disputeId, hearingId, body);
    body?.status === CANCELLED
      ? successMessage("hearings.cancelled_successfully.text")
      : successMessage("hearings.updated_successfully.text");
    yield put(actions.handleUpdate(res));
    yield put(actions.getIndex(disputeId));
    if (body?.status === CANCELLED) {
      yield put(
        timelineActions.getShow(disputeId, {
          activityId: disputeId,
          activityType: "Hearing Index",
        })
      );
    }
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getIndexMemorandum(action) {
  const { disputeId, hearingId } = action.payload;
  try {
    const res = yield call(apis.indexMemorandum, disputeId, hearingId);
    yield put(actions.handleIndexMemorandum(res));
  } catch (e) {
    console.error(tag, "saga getIndexMemorandum error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShowMemorandum(action) {
  const { disputeId, hearingId, memorandumId } = action.payload;
  yield put(handleReset());
  try {
    const res = yield call(
      apis.showMemorandum,
      disputeId,
      hearingId,
      memorandumId
    );
    yield put(actions.handleShowMemorandum(res));
  } catch (e) {
    console.error(tag, "saga getShowMemorandum error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShowMinutes({ payload }) {
  const { disputeId, hearingId } = payload;
  yield put(handleReset());
  try {
    const res = yield call(apis.showMinutes, disputeId, hearingId);
    yield put(actions.handleShowMinutes(res));
  } catch (e) {
    console.error(tag, "saga getShowMinutes error:", e);
    yield put(actions.handleError(e));
  }
}

export function* handleSignMinutesCompleted({ payload }) {
  const { disputeId, hubSlug } = payload.params?.meta;

  try {
    successMessage("hearings.updated_minutes_signature_status.text");
    if (hubSlug && disputeId) {
      yield put(handleUpdateSlug(hubSlug));
      yield put(
        push(`/${hubSlug}/disputes/${disputeId}/files/arbitrator/hearing`)
      );
    } else yield put(push(`/`));
  } catch (e) {
    console.error(tag, `saga handleSignMinutesCompleted error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* handleSignMemorandumCompleted({ payload }) {
  const { disputeId, hubSlug } = payload.params?.meta;
  const { hearingId } = payload.params?.data?.attributes;
  try {
    successMessage("hearings.updated_memorandum_signature_status.text");
    if (hubSlug && disputeId && hearingId) {
      yield put(handleUpdateSlug(hubSlug));
      yield put(
        push(
          `/${hubSlug}/disputes/${disputeId}/hearings/${hearingId}/memorandums`
        )
      );
    } else if (hubSlug && disputeId) {
      yield put(handleUpdateSlug(hubSlug));
      yield put(push(`/${hubSlug}/disputes/${disputeId}/hearings`));
    } else yield put(push(`/`));
  } catch (e) {
    console.error(tag, `saga handleSignMemorandumCompleted error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* postCreateMinutes({ payload }) {
  const { disputeId, hubSlug, hearingId, fileId, signerIds, uiRedirectUrl } = payload;
  try {
    const res = yield call(apis.postMinutes, disputeId, hearingId, { fileId });
    yield put(
      signActions.postCreateSignature({
        fileIds: [fileId],
        signerIds,
        flow: "Hearing Minutes",
        signType: "Self Sign",
        envelopeRedirectUrl: uiRedirectUrl,
        uiRedirectUrl,
        disputeId,
        hubSlug,
        signableId: res?.data?.id,
      })
    );
    yield put(actions.handleUpdateMinutes(res));
  } catch (e) {
    console.error(tag, "saga postCreateMinutes error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateMemorandum({ payload }) {
  const { disputeId, hubSlug, hearingId, fileId } = payload;
  try {
    const res = yield call(apis.updateMemorandum, disputeId, hearingId, {
      fileId,
    });
    const uiRedirectUrl = `/${hubSlug}/disputes/${disputeId}/hearings/${hearingId}/minutes`;
    yield put(
      signActions.postCreateSignature({
        fileIds: [fileId],
        flow: "Hearing Memorandum",
        signType: "Self Sign",
        uiRedirectUrl,
        disputeId,
        hubSlug,
        signableId: res?.data?.id,
      })
    );
    yield put(actions.handleUpdateMemorandum(res));
  } catch (e) {
    console.error(tag, "saga putUpdateMemorandum error:", e);
    yield put(actions.handleError(e));
  }
}

export function* handleSignatureError({ payload }) {
  const { disputeId, hubSlug } = payload?.params;
  errorMessage("general.signature_error.text");
  if (hubSlug && disputeId) {
    yield put(handleUpdateSlug(hubSlug));
    yield put(push(`/${hubSlug}/disputes/${disputeId}/hearings`));
  } else yield put(push(`/`));
}

export default function* () {
  yield takeEvery(types.HEARING_GET_INDEX, getIndex);
  yield takeLatest(types.HEARING_GET_SHOW, getShow);
  yield takeEvery(types.HEARING_POST_CREATE, postCreate);
  yield takeLatest(types.HEARING_PUT_UPDATE, putUpdate);
  yield takeLatest(types.HEARING_GET_INDEX_MEMORANDUM, getIndexMemorandum);
  yield takeLatest(types.HEARING_GET_SHOW_MEMORANDUM, getShowMemorandum);
  yield takeLatest(types.HEARING_PUT_UPDATE_MEMORANDUM, putUpdateMemorandum);
  yield takeLatest(types.HEARING_GET_SHOW_MINUTES, getShowMinutes);
  yield takeEvery(types.HEARING_POST_CREATE_MINUTES, postCreateMinutes);
  yield takeLatest(
    types.HEARING_HANDLE_SIGN_MINUTES_COMPLETED,
    handleSignMinutesCompleted
  );
  yield takeLatest(
    types.HEARING_HANDLE_SIGN_MEMORANDUM_COMPLETED,
    handleSignMemorandumCompleted
  );
  yield takeLatest(
    types.HEARING_HANDLE_MEMORANDUM_SIGNATURE_ERROR,
    handleSignatureError
  );
}
