import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/arbitratorActions";
import * as apis from "../apis/arbitratorApi";
import * as types from "../constants/constants";

const tag = "arbitrator-panel";

export function* getIndex() {
  try {
    const res = yield call(apis.index);
    yield put(actions.handleIndex(res));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShow(action) {
  const { arbitratorId } = action.payload;
  try {
    const res = yield call(apis.show, arbitratorId);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export default function* arbitratorSaga() {
  yield takeLatest(types.ARBITRATOR_GET_INDEX, getIndex);
  yield takeLatest(types.ARBITRATOR_GET_SHOW, getShow);
}
