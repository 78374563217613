import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "timeline";
const catcher = logAndThrow(tag);

export function show(disputeId, body = {}) {
  const esc = encodeURIComponent;
  const query = Object.keys(body)
    .map((k) => `${esc(k)}=${esc(body[k])}`)
    .join("&");
  return Api.get(`/disputes/${disputeId}/timeline?${query}`)
    .then((res) => res.data)
    .catch(catcher("show", "Failed to fetch timeline history"));
}
