import { errorMessages } from "../../helpers/errorHelpers";
import { toDate } from "../../transformers/formTransformer";
import * as types from "../constants/constants";

const initial = {};

export default function (state = initial, action) {
  switch (action.type) {
    case types.ASSISTEDDISPUTE_GET_PHASES_INDEX:
      return { ...state, phases: null, isFetching: true };
    case types.ASSISTEDDISPUTE_HANDLE_PHASES_INDEX:
      return {
        ...state,
        phases: (action.payload?.data ?? []).map((p) => ({
          ...p,
          deadline: toDate(p.deadline),
        })),
        isFetching: false,
      };
    case types.ASSISTEDDISPUTE_PUT_UPDATE_PHASES:
      return { ...state, isSubmitting: true };
    case types.ASSISTEDDISPUTE_HANDLE_UPDATE_PHASES:
      return { ...state, isSubmitting: false, hasModifiedPhase: false };
    case types.ASSISTEDDISPUTE_HANDLE_UPDATE_PHASES_FORM:
      return { ...state, phases: action.payload, hasModifiedPhase: true };
    case types.ASSISTEDDISPUTE_HANDLE_LASTACTIVITY_SHOW:
      return {
        ...state,
        [`${action.payload.group.toLowerCase()}LastUpdate`]: action.payload
          ?.data?.data?.attributes,
      };
    case types.ASSISTEDDISPUTE_HANDLE_TASKLIST:
      const taskList = (action.payload?.data?.data ?? []).map((t) => ({
        ...t,
        ...t.attributes,
        deadline: toDate(t.attributes.deadline),
      }));
      return {
        ...state,
        [`${action.payload.group.toLowerCase()}TaskList`]: taskList,
      };
    case types.ASSISTEDDISPUTE_HANDLE_UPDATE_LASTACTIVITY_FORM:
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
        [`hasModified-${key}`]: true,
      };
    case types.ASSISTEDDISPUTE_HANDLE_UPDATE_LASTACTIVITY:
      return {
        ...state,
        [`hasModified-${groupToKey(action, "LastUpdate")}`]: false,
      };
    case types.ASSISTEDDISPUTE_HANDLE_UPDATE_TASKLIST_FORM:
      const { group, tasks } = action.payload;
      return {
        ...state,
        [group]: tasks,
        [`hasModified-${group}`]: true,
      };
    case types.ASSISTEDDISPUTE_HANDLE_UPDATE_TASKLIST:
      return {
        ...state,
        [`hasModified-${groupToKey(action, "TaskList")}`]: false,
      };
    case types.ASSISTEDDISPUTE_HANDLE_ERROR:
      return {
        ...state,
        error: errorMessages(action.error),
        isFetching: false,
        isSubmitting: false,
      };
    default:
      return state;
  }
}

const groupToKey = ({ payload }, suffix) =>
  `${payload.group.toLowerCase()}${suffix}`;
