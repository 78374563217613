import * as types from "../constants/constants";

export const getIndex = () => ({
  type: types.ARBITRATOR_GET_INDEX,
});

export const handleIndex = (data) => ({
  type: types.ARBITRATOR_HANDLE_INDEX,
  payload: data,
});
export const getShow = (arbitratorId) => ({
  type: types.ARBITRATOR_GET_SHOW,
  payload: { arbitratorId },
});

export const handleShow = (data) => ({
  type: types.ARBITRATOR_HANDLE_SHOW,
  payload: data,
});

export const handleError = (e) => ({
  type: types.ARBITRATOR_HANDLE_ERROR,
  error: e,
});
