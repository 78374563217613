import { Button, Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccess } from "../../../helpers/accessHelpers";
import {
  AWAITING_ARBITRATOR_ACCEPTANCE,
  ARBITRATOR_ASSIGNMENT_PENDING,
  HUB_APPROVAL_PENDING,
} from "../../../redux/constants/DisputeStatuses";
import Anchor from "../../Common/Anchor";
import Translation from "../../Common/Translation";
import AcceptRejectDispute from "../AcceptRejectDispute";
import "./styles.less";
import showConfirmModal from "../../Common/confirmModal";
import { putUpdateClose } from "../../../redux/actions/disputeActions";
import { selectPanel } from "../../../redux/selectors/arbitratorPanelSelector";
import { STRUCK_BY_BOTH_PARTIES } from "../../../redux/constants/ArbitratorPanelStatuses";
import { getIndex } from "../../../redux/actions/arbitratorPanelActions";

function NotificationBanner({ disputeMeta, hubSlug, disputeId, dispute }) {
  const dispatch = useDispatch();
  const panel = useSelector(selectPanel);
  const canCreatePanel = useAccess("create", "panels");

  const closeDisputeModal = () => {
    showConfirmModal({
      label: "closed_dispute.confirm_close.text",
      onConfirm: () => dispatch(putUpdateClose({ disputeId })),
    });
  }

  const bannerProps = {
    arbitratorAcceptance: {
      label: <Translation text="dispute_assignment.send_your_decision.title" />,
      button: (
        <Anchor
          to={`/${hubSlug}/disputes/${disputeId}/dispute-assignments/respond`}
        >
          <Button type="primary" size="small">
            <Translation text="dispute_assignment.respond.action" />
          </Button>
        </Anchor>
      ),
    },
    strikePanel: {
      label: (
        <Translation text="arbitrator_panel.jur_has_created_a_panel.text" />
      ),
      button: (
        <Anchor to={`/${hubSlug}/disputes/${disputeId}/arbitrator-panel`}>
          <Button type="primary" size="small">
            <Translation text="arbitrator_panel.review_panel.action" />
          </Button>
        </Anchor>
      ),
    },
    assignArbitrator: {
      label: (
        <Translation text="arbitrator_panel.assign_arbitrator.description" />
      ),
      button: (
        <Anchor to={`/${hubSlug}/disputes/${disputeId}/arbitrator-panel`}>
          <Button type="primary" size="small">
            <Translation text="arbitrator_panel.assign_arbitrator.title" />
          </Button>
        </Anchor>
      ),
    },
    inviteDefence: {
      label: (
        <Translation text="response_filing.invite_team_review_request.text" />
      ),
      button: (
        <Anchor to={`/${hubSlug}/disputes/${disputeId}/invite-defence`}>
          <Button type="primary" size="small">
            <Translation text="response_filing.invite_team.action" />
          </Button>
        </Anchor>
      ),
    },
    fileResponse: {
      label: (
        <Translation text="response_filing.response_to_request_for_arbitration.text" />
      ),
      button: (
        <Anchor to={`/${hubSlug}/response-filing/${disputeId}/intro`}>
          <Button type="primary" size="small">
            <Translation text="response_filing.file_response.action" />
          </Button>
        </Anchor>
      ),
    },
    caseClosing: {
      label: (
        <Translation text="general.close_dispute.label" />
      ),
      button: (
          <Button type="danger" size="small" onClick={closeDisputeModal}>
            <Translation text="general.close_dispute.button" />
          </Button>
      ),
    }
  };

  const canAcceptHub = useAccess("update-hub-acceptance", "dispute");
  const canAcceptAssignment = useAccess("update", "dispute-assignment");
  const canCloseDispute = disputeMeta?.canCloseDispute;
  const {
    canStrikePanel,
    canFileResponse,
    showInviteDefenseTeamButton,
  } = disputeMeta;
  const canAcceptRejectDispute =
    canAcceptHub && HUB_APPROVAL_PENDING === dispute.status;
  const canRespondToCurrentDispute =
    canAcceptAssignment && AWAITING_ARBITRATOR_ACCEPTANCE === dispute.status;
  const canAssignArbitrator =
    canCreatePanel && panel?.status === STRUCK_BY_BOTH_PARTIES && dispute?.status === ARBITRATOR_ASSIGNMENT_PENDING;

  React.useEffect(() => {
    dispatch(getIndex(disputeId));
  }, [dispatch, disputeId]);

  return (
    <>
      {showInviteDefenseTeamButton ? (
        <CTANotificationBanner {...bannerProps["inviteDefence"]} />
      ) : canFileResponse ? (
        <CTANotificationBanner {...bannerProps["fileResponse"]} />
      ) : canStrikePanel ? (
        <CTANotificationBanner {...bannerProps["strikePanel"]} />
      ) : canAcceptRejectDispute ? (
        <AcceptRejectDispute
          hubSlug={hubSlug}
          disputeId={disputeId}
          status={dispute.status}
        />
      ) : canRespondToCurrentDispute ? (
        <CTANotificationBanner {...bannerProps["arbitratorAcceptance"]} />
      ) : canCloseDispute ? (
        <CTANotificationBanner {...bannerProps["caseClosing"]} />
      ) : canAssignArbitrator ? (
        <CTANotificationBanner {...bannerProps["assignArbitrator"]} />
      ) : null}
    </>
  );
}

export default NotificationBanner;

const CTANotificationBanner = ({ label, button }) => (
  <Row justify="space-between" className="jur-dispute-invite-defence-wrapper">
    <Col>
      <img
        className="jur-dispute-invite-defence-badge"
        src={require("../../../assets/illustrations/badge.png")}
        alt="badge"
      />
      {label}
    </Col>
    <Col>{button}</Col>
  </Row>
);
