const initialState = {
  isLoading: false,
};
export default function loadingReducer(state = initialState, action) {
  const { loading } = action;

  if (loading) return { isLoading: loading };

  return state;
}
