import { Card, Col, Row, Typography } from "antd";
import React from "react";
import Anchor from "../Common/Anchor";
import Translation from "../Common/Translation";
import "./styles.less";

function UpdateProfileReminder({ name }) {
  return (
    <>
      <Card className="jur-update-profile-reminder-card">
        <Row>
          <Col>
            <img
              className="jur-update-profile-reminder-illustration"
              src={require("../../assets/illustrations/profile-illustration.png")}
              alt="logo"
            />
          </Col>
          <Col className="jur-update-profile-reminder-content">
            <Typography.Title
              className="jur-update-profile-reminder-heading"
              level={4}
            >
              <Translation text="dashboard.hi.text" />
              {", "}
              {name}
            </Typography.Title>
            <Typography.Title
              level={5}
              className="jur-update-profile-reminder-subheading"
            >
              <Translation text="dashboard.completre_profile.description" />
            </Typography.Title>
            <div className="jur-update-profile-reminder-link">
              <Anchor className="jur-link" to="/onboarding">
                <Translation text="dispute.update_profile.button" />
              </Anchor>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default UpdateProfileReminder;
