import * as types from "../constants/constants";

const initial = { file: null, fileList: {} };

export default function (state = initial, action) {
  switch (action.type) {
    case types.DISPUTEFILING_HANDLE_ERROR:
      return { ...state, error: action.error };
    case types.FILE_HANDLE_CREATE:
      return { ...state, ...action.payload };
    case types.FILE_HANDLE_INLINE_SHOW:
      const list = state.fileList;
      list[action?.payload?.id] = action?.payload;
      return { ...state, file: action.payload, fileList: list };
    case types.FILE_HANDLE_RESET:
      return initial;
    default:
      return state;
  }
}
