import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { postCreateCounterclaim } from "../../redux/actions/defensiveActsActions";
import { whole } from "../../redux/selectors/defensiveActsSelector";
import Spinner from "../Common/Spinner";
import CounterclaimRender from "./CounterclaimRender";

export default function CounterclaimHOC() {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();

  const dispatch = useDispatch();

  const handleSubmit = (body) => {
    dispatch(postCreateCounterclaim(disputeId, body));
  };

  const { isSubmitting, errors } = useSelector(whole);
  return dispute ? (
    <CounterclaimRender
      hubSlug={hubSlug}
      dispute={dispute}
      disputeId={disputeId}
      extraCrumbs={extraCrumbs(hubSlug, disputeId)}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      errors={errors}
    />
  ) : (
    <Spinner />
  );
}

const extraCrumbs = (hubSlug, disputeId) => [
  {
    path: `/${hubSlug}/disputes/${disputeId}/counterclaim`,
    breadcrumbName: "Counter Claim",
  },
];
