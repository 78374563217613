import * as types from "../constants/constants";

const initial = {
  hubs: [],
  hub: null,
};

export default function (state = initial, action) {
  switch (action.type) {
    case types.HUB_HANDLE_ERROR:
      return { ...state, error: action.error };
    case types.DISPUTEFILING_HANDLE_START:
      return { ...state, hub: null };
    case types.HUB_HANDLE_INDEX:
      return { ...state, hubs: action.payload.data };
    case types.HUB_HANDLE_PROFILE:
      return { ...state, profile: action.payload.data?.attributes };
    case types.HUB_HANDLE_UPDATE:
      console.log("Hub Slug Set", action.payload);
      return { ...state, hub: action.payload, profile: null };
    case types.HUB_GET_ARBITRATORS:
      return { ...state, arbitrators: null };
    case types.HUB_HANDLE_ARBITRATORS:
      return { ...state, arbitrators: action.payload?.data };
    case types.HUB_HANDLE_ROSTER_INDEX:
      return {
        ...state,
        rosterIndex: action.payload.data,
        rosterIndexMeta: action.payload.meta,
      };
    case types.HUB_HANDLE_ROSTER_PROFILE:
      return {
        ...state,
        rosterProfile: action.payload.data,
      };
    default:
      return state;
  }
}
