import { UserOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import React from "react";
import Viewer from "../Common/Editor/Viewer";
import Translation from "../Common/Translation";

const { Title, Text } = Typography;

export default function DisputeRecord(props) {
  return (
    <div className="jur-dispute-record-wrapper">
      <div>
        <DisputeGraphic />
      </div>
      <div>
        <DisputeDetails {...props} />
      </div>
    </div>
  );
}

const DisputeGraphic = () => (
  <img
    className="jur-dispute-record-graphic"
    src={require("../../assets/illustrations/dispute-graphic.png")}
    alt="dispute-graphic"
  />
);

const DisputeDetails = ({ item, index }) => (
  <div>
    <div>
      <Title className="primary-color" level={5} strong>
        Dispute {index + 1}
      </Title>
      <div className="jur-profile-building-information-subtitle">
        <Text className="secondary-color">{item.role}</Text>
      </div>
    </div>
    <div>
      <Row gutter={[48, 24]}>
        {[
          {
            label: "year_of_procedure",
            value: item?.yearOfProcedure,
          },
          {
            label: "dispute_value",
            value: `$${item?.disputeValue}`,
          },
          {
            label: "industry_matter",
            value: item?.industryMatter,
          },
          {
            label: "type_of_procedure",
            value: item?.typeOfProcedure,
          },
          {
            label: "arbitration_rules_applied",
            value: item?.arbitrationRulesApplied,
          },
        ].map((i) => (
          <Col>
            <ListItemInput value={i.value} label={i.label} />
          </Col>
        ))}
        <Col span={24}>
          <div>
            <Text className="secondary-color">
              <Translation text={`roster.other_details.text`} />
            </Text>
          </div>
          <Title level={5}>
            <Viewer value={item.additionalDetails} />
          </Title>
        </Col>
        {item?.references?.length > 0 ? (
          <Col span={24}>
            <div>
              <Text className="secondary-color">
                <Translation text={`roster.references.text`} />
              </Text>
            </div>
            {item?.references.map((reference) => (
              <div className="jur-dispute-reference-wrapper">
                <UserOutlined />
                <Title level={5}>
                  {reference.firstName} {reference.lastName}
                </Title>
                <Text className="secondary-color">{reference.email}</Text>
                <br />
                <Text className="primary-color" strong>
                  {reference.role}
                </Text>
                <br />
              </div>
            ))}
          </Col>
        ) : null}
      </Row>
    </div>
  </div>
);

export const ListItemInput = ({ label, value }) => (
  <>
    <Text className="secondary-color">
      <Translation text={`roster.${label}.text`} />
    </Text>
    <Title className="primary-color" level={5}>
      {value}
    </Title>
  </>
);
