import { Alert, Button, Col, Row, Steps, Typography } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import React from "react";
import Translation from "../Common/Translation";
import Uploader, { UploadButtonRender } from "../Common/Uploader";
import SignatureButton from "../Signs/SignatureButton";

const { Step } = Steps;

export default function ({
  dispute,
  onSubmit,
  addToSign,
  download,
  onSign,
  meta,
  hubTOS,
  statementOfAcceptance,
  appointmentDocument,
  currentStep,
  url,
  extraCrumbs,
  hubSlug,
  disputeId,
  deleteFile,
}) {
  const StepTitle = ({ label, step }) => (
    <Typography.Title
      level={4}
      type={currentStep !== step ? "secondary" : null}
    >
      <Translation text={label} />
    </Typography.Title>
  );

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={{ statementOfAcceptance, appointmentDocument }}
      >
        {({ submitForm, setFieldValue, values }) => {
          const commonProps = { values, onSign, url };
          const acceptanceProps = {
            ...commonProps,
            labelPrefix: "statement_of_acceptance",
            onDownload: () => download("statementOfAcceptanceTemplate"),
            name: "statementOfAcceptance",
            uploadProps: {
              maxCount: 1,
              allowDocumentOnly: true,
              name: "statementOfAcceptance",
              multiple: false,
              fileCategory: "Statement Of Acceptance",
              defaultFileList: [values?.statementOfAcceptance],
              onRemove: () => {
                deleteFile(values["statementOfAcceptance"]?.id);
                setFieldValue("statementOfAcceptance", null);
              },
              onDone: (file) => {
                setFieldValue("statementOfAcceptance", file);
                addToSign(file);
              },
            },
          };

          const appointmentProps = {
            ...commonProps,
            labelPrefix: "appointment_document",
            onDownload: () => download("appointmentDocumentTemplate"),
            name: "appointmentDocument",
            uploadProps: {
              maxCount: 1,
              allowDocumentOnly: true,
              name: "appointmentDocument",
              multiple: false,
              fileCategory: "Appointment Document",
              defaultFileList: [values?.appointmentDocument],
              onRemove: () => {
                deleteFile(values["appointmentDocument"]?.id);
                setFieldValue("appointmentDocument", null);
              },
              onDone: (file) => {
                setFieldValue("appointmentDocument", null);
                setFieldValue("appointmentDocument", file);
                addToSign(file);
              },
            },
          };

          return (
            <Form layout="vertical">
              <Alert
                banner={true}
                type="info"
                message={
                  <Translation text="dispute_assignment.documents_upload.text" />
                }
              />
              <br />
              <Steps direction="vertical" current={currentStep}>
                <Step
                  title={
                    <StepTitle
                      step={0}
                      label={`dispute_assignment.${acceptanceProps.labelPrefix}.title`}
                    />
                  }
                  description={
                    currentStep === 0 ? (
                      <StepRender {...acceptanceProps} />
                    ) : (
                      <br />
                    )
                  }
                />
                <Step
                  title={
                    <StepTitle
                      step={1}
                      label={`dispute_assignment.${appointmentProps.labelPrefix}.title`}
                    />
                  }
                  description={
                    currentStep === 1 ? (
                      <StepRender {...appointmentProps} />
                    ) : (
                      <br />
                    )
                  }
                />
              </Steps>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export function AcceptButton({ onClick }) {
  return (
    <Button onClick={onClick} type="primary">
      <Translation text="dispute.accept.button" />
    </Button>
  );
}

function StepRender({
  onDownload,
  uploadProps,
  labelPrefix,
  name,
  uploadButtonProps,
  values,
  onSign,
  url,
}) {
  return (
    <>
      <Row justify="space-between">
        <Col>
          <Typography.Paragraph>
            <Translation
              text={`dispute_assignment.${labelPrefix}.description`}
            />
          </Typography.Paragraph>
        </Col>
        <Col>
          {/* <Button type="link" icon={<DownloadOutlined />} onClick={onDownload}>
            <Translation
              pad={true}
              text="dispute_assignment.download_template.button"
            />
          </Button> */}
        </Col>
      </Row>
      <Form.Item name={name}>
        <Uploader {...uploadProps}>
          <UploadButtonRender
            buttonProps={uploadButtonProps}
            label={`dispute_assignment.${labelPrefix}_upload.button`}
            tooltip={"dispute_assignment.upload_file.description.text"}
          />
        </Uploader>
      </Form.Item>
      <SignatureButton redirectUrl={url}>
        <Button
          onClick={() => {
            onSign(name);
          }}
          type="primary"
          disabled={!values[name]}
        >
          <Translation pad={true} text="general.sign.button" />
        </Button>
      </SignatureButton>
    </>
  );
}
