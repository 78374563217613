import { Layout } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import Error from "./components/Error/Error";
import Loading from "./components/Loading/Loading";
import Navbar from "./components/Navbar/Navbar";
import { isLoading } from "./redux/selectors/loadingSelector";
import { selectIsRedirecting } from "./redux/selectors/signSelector";
import Routes from "./routes/routes";

const { Content } = Layout;
// Test comment
function App() {
  const loading = useSelector((state) => isLoading(state));
  const isRedirecting = useSelector(selectIsRedirecting);

  return (
    <>
      <Loading
        loading={loading || isRedirecting}
        tip={isRedirecting ? "Initiating Signature Process..." : undefined}
      />
      <Layout>
        <Navbar />
        <Content>
          <Error />
          <Routes />
        </Content>
      </Layout>
    </>
  );
}

export default App;
