import React, { Component } from "react";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBounday caught", error, errorInfo);
  }

  render() {
    if (this.state.hasError) return <span>failed to render</span>;
    return this.props.children || null;
  }
}
