import { Button, Space, Tooltip } from "antd";
import React from "react";
import { useAccess } from "../../helpers/accessHelpers";
import {
  EDITS_REQUESTED,
  HUB_APPROVED,
  SENT_TO_HUB,
} from "../../redux/constants/ProceduralOrderStatuses";
import Translation from "../Common/Translation";

export default function ShowActions({
  handleShareOrderOpen,
  handleUploadRevisedOrderOpen,
  handleReviewOrderOpen,
  handleShowLatest,
  order,
}) {
  const canCreate = useAccess("create", "procedural-order");
  const canReview = useAccess("update-approval", "procedural-order");

  const actions = [
    {
      label: "procedural_order.share_order.button",
      onClick: handleShareOrderOpen,
      access:
        canCreate &&
        order?.attributes?.status === HUB_APPROVED &&
        Boolean(order?.attributes?.isLatest),
      tooltip: "procedural_order.send_to_parties_signature.text",
    },
    {
      label: "procedural_order.upload_revised_order.button",
      onClick: handleUploadRevisedOrderOpen,
      access:
        canCreate &&
        order?.attributes?.status === EDITS_REQUESTED &&
        Boolean(order?.attributes?.isLatest),
    },
    {
      label: "procedural_order.review_order.button",
      onClick: handleReviewOrderOpen,
      access:
        canReview &&
        order?.attributes?.status === SENT_TO_HUB &&
        Boolean(order?.attributes?.isLatest),
    },
    {
      label: "procedural_order.show_latest_order.button",
      subText: `  (Version ${Math.max(
        ...order?.attributes?.versions.map((x) => x?.version)
      )})`,
      onClick: handleShowLatest,
      access: !Boolean(order?.attributes?.isLatest),
    },
  ].filter((a) => a.access);

  if (!actions.length) return null;

  return (
    <>
      <Space>
        {actions.map((a, i) => {
          const actionButton = (
            <Button type={i === 0 ? "primary" : null} onClick={a.onClick}>
              <Translation text={a.label} />
              {a?.subText}
            </Button>
          );
          if (a?.tooltip) {
            return (
              <Tooltip
                title={
                  <center>
                    <Translation text={a?.tooltip} />
                  </center>
                }
              >
                {actionButton}
              </Tooltip>
            );
          }
          return actionButton;
        })}
      </Space>
      <br />
      <br />
    </>
  );
}
