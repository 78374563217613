import { Button, Space } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React from "react";
import { errorAsExtra, useErrorHider } from "../../helpers/errorHelpers";
import Editor from "../Common/Editor/Editor";
import Translation from "../Common/Translation";
import { handleChange } from "../DisputeFiling/helpers";
import "./styles.less";

export default function RequestEditsForm({ awards = [], onSubmit, errors, selectedAward = null, isLoading }) {
  const errorProps = useErrorHider();
  const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);
  return (
    <Formik initialValues={initReviewObject(selectedAward)}>
      {({ values, resetForm, setFieldValue }) => {
        return (
          values.open && (
            <Form layout="vertical">
              <FormItem name="edits" {...apiErrorOf("edits", true)}>
                <Editor onChange={handleChange(values, setFieldValue)("edits")} className="jur-quill-editor-small" />
              </FormItem>

              <Space size="middle" align="end">
                <Button onClick={() => setFieldValue("open", false)}>
                  <Translation text="general.cancel.action" pad={true} />
                </Button>
                <Button
                  disabled={!values?.edits?.length}
                  // loading={isLoading}
                  type="primary"
                  onClick={() => onSubmit(values)}
                >
                  <Translation text="general.done.title" pad={true} />
                </Button>
              </Space>
            </Form>
          )
        );
      }}
    </Formik>
  );
}

const initReviewObject = (selectedAward) => {
  return {
    awardId: selectedAward ? selectedAward?.id : null,
    edits: "",
    open: true,
  };
};
