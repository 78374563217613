import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDispute } from "../../helpers/disputeHelpers";
import { useErrorHider } from "../../helpers/errorHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { getShow, putUpdateKyc } from "../../redux/actions/disputeUserActions";
import { selectMyDisputeUser } from "../../redux/selectors/disputesSelector";
import {
  selectCurrent,
  selectErrors,
} from "../../redux/selectors/disputeUsersSelector";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import ProfileEditRender from "./ProfileEditRender";

export default function () {
  const hubSlug = useHubSlug();
  const [disputeId, dispute] = useDispute();
  const kyc = useSelector(selectCurrent);
  const me = useSelector(selectMyDisputeUser);
  const dispatch = useDispatch();
  const errors = useSelector(selectErrors);
  const errorProps = useErrorHider();

  const handleSubmit = (values) =>
    void dispatch(putUpdateKyc(hubSlug, disputeId, values));

  useEffect(() => {
    if (me) dispatch(getShow(disputeId, me?.disputeUserId));
  }, [dispatch, disputeId, me]);

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/edit-profile`,
      breadcrumbName: <Translation text="general.edit_profile.title" />,
    },
  ];

  return dispute ? (
    <ProfileEditRender
      dispute={dispute}
      disputeId={disputeId}
      hubSlug={hubSlug}
      onSubmit={errorProps.callAndUnhide(handleSubmit)}
      profile={kyc}
      role={me?.role}
      extraCrumbs={extraCrumbs}
      errors={errors}
      errorProps={errorProps}
    />
  ) : (
    <Spinner />
  );
}
