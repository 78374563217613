import { Tag } from "antd";
import React from "react";
import "./styles.less";

export default function ({ role, group }) {
  return (
    <Tag color={color(group)}>
      {group ? group + ":" : null} {role}
    </Tag>
  );
}

function color(group) {
  return {
    Claimant: "geekblue",
    Respondent: "green",
  }[group];
}

export const PartyTag = ({ party }) => <Tag color={color(party)}>{party}</Tag>;
