import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import {
  getIndex as getArbitratorIndex,
  getShow,
} from "../../redux/actions/arbitratorActions";
import {
  getIndex,
  handleUpdateStrikeList,
  postCreate,
  putUpdateStrike,
} from "../../redux/actions/arbitratorPanelActions";
import { postCreate as postCreateAssignment } from "../../redux/actions/disputeAssignmentActions";
import {
  ASSEMBLED,
  STRUCK_BY_BOTH_PARTIES,
  STRUCK_BY_CLAIMANT,
  STRUCK_BY_RESPONDENT,
} from "../../redux/constants/ArbitratorPanelStatuses";
import {
  ARBITRATOR_ASSIGNMENT_PENDING,
  AWAITING_ARBITRATOR_ACCEPTANCE,
  ONGOING,
} from "../../redux/constants/DisputeStatuses";
import {
  selectPanel,
  selectStrikeList,
} from "../../redux/selectors/arbitratorPanelSelector";
import {
  selectAllArbitrators,
  selectCurrentArbitrator,
} from "../../redux/selectors/arbitratorSelector";
import { selectDisputeMeta } from "../../redux/selectors/disputesSelector";
import Spinner from "../Common/Spinner";
import IndexRender from "./IndexRender";

export default function IndexHOC() {
  const [disputeId, dispute] = useDispute();
  const dispatch = useDispatch();
  const hubSlug = useHubSlug();
  const panel = useSelector(selectPanel);
  const strikeList = useSelector(selectStrikeList);
  const arbitrator = useSelector(selectCurrentArbitrator);
  const availableArbitrators = useSelector(selectAllArbitrators);
  const disputeMeta = useSelector(selectDisputeMeta);

  const canCreatePanel = useAccess("create", "panels");
  const { canStrikePanel } = disputeMeta ?? {};

  const isPanelAssembled = [
    ASSEMBLED,
    STRUCK_BY_CLAIMANT,
    STRUCK_BY_RESPONDENT,
    STRUCK_BY_BOTH_PARTIES,
  ].includes(panel?.status);
  const showPanelCreation = canCreatePanel && !isPanelAssembled;
  const canSeeAllStrikes = canCreatePanel && isPanelAssembled;

  const canAssignArbitrator =
    canCreatePanel &&
    panel?.status === STRUCK_BY_BOTH_PARTIES &&
    dispute?.status === ARBITRATOR_ASSIGNMENT_PENDING;
  const isAssignmentCompleted = [
    ONGOING,
    AWAITING_ARBITRATOR_ACCEPTANCE,
  ].includes(dispute?.status);

  const disableSelection =
    isAssignmentCompleted ||
    (canCreatePanel &&
      [ASSEMBLED, STRUCK_BY_CLAIMANT, STRUCK_BY_RESPONDENT].includes(
        panel?.status
      )) ||
    (!canCreatePanel && !canStrikePanel);

  const getArbitrator = (arbitratorId) => void dispatch(getShow(arbitratorId));

  const handleConfirm = (userId) => {
    if (canAssignArbitrator)
      dispatch(
        postCreateAssignment(disputeId, {
          userId,
          allowToUpload: true,
        })
      );
    else dispatch(putUpdateStrike(hubSlug, disputeId, strikeList));
  };

  const onUpdateStrikeList = (list) =>
    void dispatch(handleUpdateStrikeList(list));

  const createPanel = (list) => void dispatch(postCreate(disputeId, list));

  useEffect(() => {
    dispatch(getIndex(disputeId));
  }, [dispatch, disputeId]);

  useEffect(() => {
    if (showPanelCreation) dispatch(getArbitratorIndex());
  }, [dispatch, disputeId, showPanelCreation]);

  useEffect(() => {
    dispatch(handleUpdateStrikeList([]));
  }, [dispatch]);

  if (!disputeMeta) return <Spinner />;

  return (
    <IndexRender
      disputeParams={{ disputeId, dispute, hubSlug }}
      panel={panel?.panelArbitrators}
      disputeAssignments={panel?.disputeAssignments}
      onUpdateStrikeList={onUpdateStrikeList}
      strikeList={strikeList}
      onConfirm={handleConfirm}
      getArbitrator={getArbitrator}
      arbitrator={arbitrator}
      availableArbitrators={availableArbitrators}
      showPanelCreation={showPanelCreation}
      createPanel={createPanel}
      canAssignArbitrator={canAssignArbitrator}
      disableSelection={disableSelection}
      canStrikePanel={canStrikePanel}
      canSeeAllStrikes={canSeeAllStrikes}
      isAssignmentCompleted={isAssignmentCompleted}
    />
  );
}
