import { Button } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { passwordIconRender } from "../../helpers/authHelpers";
import { errorAsExtra } from "../../helpers/errorHelpers";
import { resetPassword } from "../../redux/actions/authenticateActions";
import { authLoading, selectErrors } from "../../redux/selectors/authSelector";
import Translation from "../Common/Translation";
import AuthLayout from "../Layouts/AuthLayout";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";
import AuthFormItem from "./AuthFormItem";
import resetPasswordValidate from "./resetPasswordValidate";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const errors = useSelector(selectErrors);
  const isLoading = useSelector(authLoading);
  const [isHidden, setIsHidden] = useState(false);
  const extraProps = errorAsExtra(errors, isHidden);

  return (
    <LeftSidebarLayout
      mainContent={
        <AuthLayout
          header={<div />}
          content={
            <>
              <div className="jur-auth-title">
                <Translation text="general.reset_password.title" />
              </div>
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                onSubmit={(values) => {
                  const data = {
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                    token,
                  };
                  setIsHidden(false);
                  dispatch(resetPassword(data));
                }}
                validationSchema={resetPasswordValidate}
              >
                {({ values, handleChange }) => {
                  const hideOnChange = (e) => {
                    setIsHidden(true);
                    handleChange(e);
                  };
                  const fields = [
                    {
                      name: "password",
                      label: (
                        <Translation text="authentication.password.text" />
                      ),
                      ...extraProps("password"),
                      component: (
                        <Input.Password
                          name="password"
                          onChange={hideOnChange}
                          iconRender={passwordIconRender(values, "password")}
                        />
                      ),
                    },
                    {
                      name: "confirmPassword",
                      label: (
                        <Translation text="authentication.verify_password.text" />
                      ),
                      ...extraProps("confirmPassword"),
                      component: (
                        <Input.Password
                          iconRender={passwordIconRender(
                            values,
                            "confirmPassword"
                          )}
                          name="confirmPassword"
                          onChange={hideOnChange}
                        />
                      ),
                    },
                  ];

                  return (
                    <Form layout="vertical">
                      {fields.map((formItem) => (
                        <AuthFormItem {...formItem} />
                      ))}
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={isLoading}
                        className="jur-auth-submit-button"
                        disabled={
                          values.confirmPassword === "" ||
                          values.password === ""
                        }
                      >
                        <Translation text="general.submit.button" pad={true} />
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </>
          }
        />
      }
    />
  );
}
