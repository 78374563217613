import { Button, Col, Row, Space, Table, Typography, Badge, Tag } from "antd";
import { FileTextOutlined, FileTwoTone } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";

import { fromMeta } from "../../transformers/paginationTransformer";
import DateTime from "../Common/DateTime";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import ExtendDeadlineForm from "./ExtendDeadlineForm";
import ReviewAwardForm from "./ReviewAwardForm";
import SendToHubForm from "./SendToHubForm";
import SendToPartiesForm from "./SendToPartiesForm";
import NoData from "../Common/NoData";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import CaseFilesBreadcrumb from "../Common/CaseFilesBreadcrumb";
import PageHeader from "../Common/PageHeader";
import { arbAwardStatusColor } from "../Common/ArbitralAward";
import ShowAwardReview from "./ShowAwardReview";
import * as fileActions from "../../redux/actions/fileActions";

export default function ({
  dispute,
  disputeId,
  hubSlug,
  isRequestEditsVisible,
  isSendToHubVisible,
  closeSendToHub,
  handleSendToHub,
  isReviewAwardVisible,
  closeReviewTerms,
  handleReviewTerms,
  isSendToPartiesVisible,
  closeSendToParties,
  handleSendToParties,
  errors,
  isLoading,
  actionList,
  deadlineList,
  selectedAward,
  rejectedAward,
  arbitralAwards,
  isSubmitting,
  isExtendDeadlineVisible,
  closeExtendDeadline,
  handleExtendDeadline,
  handleEditRequest,
  closeRequestEdits,
  onChangeDeadlineDropdown,
  currentDeadlineAction,
  deadlineExtensionDropdownList,
  sharableAwards,
  meta,
  onPageChange,
  canCreateAward,
  canReviewAward,
  extraCrumbs,
  isAwardIssued,
  deleteAward,
  getAwardStatus,
  openSendToHub,
  handleAwardAction,
  handleActionText,
}) {
  const dispatch = useDispatch();
  const previewFile = (fileId) => dispatch(fileActions.getShow(fileId));

  const renderAwards = (awards = []) => {
    return awards.length ? (
      <Table
        className="jur-width-full jur-table-white-header"
        columns={columns(previewFile, getAwardStatus, handleActionText, handleAwardAction)}
        dataSource={awards}
        rowKey="id"
        pagination={fromMeta(meta, (page) => {
          onPageChange(page);
        })}
      />
    ) : (
      <NoData
        description={
          <Typography.Title level={5} strong>
            <Translation text="dispute.jur_reviewing.text" />
          </Typography.Title>
        }
      />
    );
  };

  return (
    <SliderMenuWrapper hubSlug={hubSlug} disputeId={disputeId} dispute={dispute}>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={24}>
          <CaseFilesBreadcrumb />
        </Col>
        <Col className="gutter-row" span={24}>
          <PageHeader
            title="general.arbitral_award.title"
            cta={
              canCreateAward && (
                <Button type="primary" onClick={openSendToHub}>
                  <Translation text="case_files.new_upload.text" />
                </Button>
              )
            }
          />
        </Col>
        <Col className="gutter-row" span={24}>
          {isLoading ? (
            <div className="jur-tor-spinner">
              <Spinner />
            </div>
          ) : (
            renderAwards(arbitralAwards)
          )}
        </Col>
        {rejectedAward && (
          <Col className="gutter-row" span={24}>
            <ShowAwardReview
              rejectedAward={rejectedAward}
              errors={errors}
              visible={isRequestEditsVisible}
              selectedAward={selectedAward}
              onSubmit={handleEditRequest}
              awards={arbitralAwards}
              closeRequestEdits={closeRequestEdits}
              isLoading={isSubmitting}
              canCreateAward={canCreateAward}
              canReviewAward={canReviewAward}
            />
          </Col>
        )}
      </Row>
      <SendToHubForm
        isAwardIssued={isAwardIssued}
        visible={isSendToHubVisible}
        onSubmit={handleSendToHub}
        closeSendToHub={closeSendToHub}
        isLoading={isSubmitting}
        errors={errors}
        deleteAward={deleteAward}
      />
      <ReviewAwardForm
        errors={errors}
        selectedAward={selectedAward}
        awards={arbitralAwards}
        closeReviewTerms={closeReviewTerms}
        visible={isReviewAwardVisible}
        onSubmit={handleReviewTerms}
        isLoading={isSubmitting}
      />
      <SendToPartiesForm
        sharableAwards={sharableAwards}
        selectedAward={selectedAward}
        isSendToPartiesVisible={isSendToPartiesVisible}
        handleSendToParties={handleSendToParties}
        closeSendToParties={closeSendToParties}
        isLoading={isSubmitting}
      />
      <ExtendDeadlineForm
        meta={meta}
        errors={errors}
        currentDeadlineAction={currentDeadlineAction}
        visible={isExtendDeadlineVisible}
        closeExtendDeadline={closeExtendDeadline}
        onSubmit={handleExtendDeadline}
        isLoading={isSubmitting}
      />
    </SliderMenuWrapper>
  );
}

function columns(previewFile, getAwardStatus, handleActionText, handleAwardAction) {
  return [
    {
      title: <FileTextOutlined className="jur-cf-table-body-file-icon" />,
      key: "icon",
      width: "50px",
      render: () => <FileTwoTone className="jur-table-body-file-render-icon" twoToneColor=" #2777EF" />,
    },
    {
      title: <Translation text="arbitral_award.files.column" />,
      dataIndex: ["attributes", "file", "name"],
      render: renderFiles(previewFile),
    },
    {
      title: <Translation text="arbitral_award.status.column" />,
      dataIndex: ["attributes", "status"],
      render: (_, award) => (
        <Badge
          color={arbAwardStatusColor(getAwardStatus(award))}
          text={<span className="jur-case-status">{getAwardStatus(award)}</span>}
        />
      ),
    },
    {
      title: <Translation text="arbitral_award.issued_on.column" />,
      dataIndex: ["attributes", "createdAt"],
      render: (epochSeconds) => <DateTime epochSeconds={epochSeconds} onlyDate={true} />,
    },
    {
      title: <Translation text="arbitral_award.actions.column" />,
      dataIndex: ["attributes", "status"],
      render: (_, record) => {
        return (
          <a href="#/" onClick={() => handleAwardAction(record)}>
            {handleActionText(record)}
          </a>
        );
      },
    },
  ];
}

const renderFiles = (previewFile) => (text, record) => (
  <Space>
    <a href="#/" onClick={() => previewFile(record?.attributes?.file?.id)}>
      {text}
    </a>
    <Tag>{record?.attributes?.kind}</Tag>
  </Space>
);
