import { Alert, Button, Modal, Space } from "antd";
import { Formik } from "formik";
import { Checkbox, Form, FormItem, Select } from "formik-antd";
import React, { useState } from "react";
import { ErrorDisplay, useErrorHider } from "../../helpers/errorHelpers";
import { modalProps } from "../../helpers/modalHelpers";
import Translation from "../Common/Translation";
import Uploader, { UploadButtonRender } from "../Common/Uploader";
import SignatureButton from "../Signs/SignatureButton";

const documentTypes = [
  "Opening Statement",
  "Closing Statement",
  "Reply to order",
  "Evidentiary Document",
  "Witness Statement",
  "Expert Report",
];

export default function SendDocumentsForm({
  visible,
  closeSendDocuments,
  handleSendDocuments,
  preselectedRequest = false,
  isLoading,
  errors,
  redirectUrl,
  deleteFile,
}) {
  const [fileId, setFileId] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const uploaderProps = {
    maxCount: 1,
    name: "evidenceRequestUploader",
    multiple: false,
    fileCategory: "Evidence Request",
    onRemove: () => {
      deleteFile(fileId);
      setFileId(null);
    },
    onDone: (file) => {
      setFileId(file.id);
    },
  };

  const errorProps = useErrorHider();

  const footer = [
    false ? (
      <SignatureButton redirectUrl={redirectUrl}>
        <Button
          type="primary"
          loading={isLoading}
          disabled={!Boolean(fileId) || isLoading}
          onClick={() => {
            if (preselectedRequest) {
              handleSendDocuments(fileId);
            } else {
              handleSendDocuments({ id: requestId, fileId: fileId }, () => {
                setFileId(null);
                setRequestId(null);
                errorProps.hide();
              });
            }
          }}
        >
          <Translation text="evidence_request.send.button" pad={true} />
        </Button>
      </SignatureButton>
    ) : (
      <Button
        type="primary"
        loading={isLoading}
        disabled={!Boolean(fileId) || isLoading}
        onClick={() => {
          if (preselectedRequest) {
            handleSendDocuments(fileId);
          } else {
            handleSendDocuments({ id: requestId, fileId: fileId }, () => {
              setFileId(null);
              setRequestId(null);
              errorProps.hide();
            });
          }
        }}
      >
        <Translation text="evidence_request.send.button" pad={true} />
      </Button>
    ),
  ];

  const onCancel = () => {
    closeSendDocuments();
    setFileId(null);
    setRequestId(null);
    errorProps.hide();
  };

  return (
    <Formik initialValues={{}}>
      {({ values }) => (
        <Modal
          {...modalProps()}
          width={768}
          visible={visible}
          onCancel={onCancel}
          footer={footer}
          title={<Translation text="evidence_request.send_documents.text" />}
        >
          <Form layout="vertical">
            <FormItem
              label={
                <Translation text="evidence_request.select_type_of_document.text" />
              }
              name="documentType"
            >
              <Select placeholder="Document Type" name="documentType">
                {documentTypes.map((e) => (
                  <Select.Option value={e}>{e}</Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem name="signatureRequired">
              <Checkbox name="signatureRequired">
                <Translation text="evidence_request.do_you_want_to_digitally_sign_the_file.text" />
              </Checkbox>
            </FormItem>
            <FormItem name="file">
              <Space direction="vertical">
                <Uploader {...uploaderProps}>
                  <UploadButtonRender
                    buttonProps={{ disabled: Boolean(fileId) }}
                    optional={true}
                    label="general.choose_file.button"
                  />
                </Uploader>
              </Space>
            </FormItem>
            <ErrorDisplay
              errors={errors}
              name="deadline"
              isHidden={errorProps.isHidden}
            />
          </Form>
          {values?.signatureRequired ? (
            <Alert
              type="info"
              banner={true}
              message={
                <Translation text="evidence_request.sign_step.description" />
              }
            />
          ) : null}
        </Modal>
      )}
    </Formik>
  );
}
