import * as types from "../constants/constants";

const initial = {
  index: [],
  current: null,
  meta: null,
  flows: {},
  versions: {},
};

export default function (state = initial, action) {
  const { payload } = action;
  switch (action.type) {
    case types.PROCEDURALORDER_HANDLE_FLOW_LIST:
      const flow = payload?.flow;
      return { ...state, [flow]: payload?.data };
    case types.PROCEDURALORDER_HANDLE_VERSIONS:
      return {
        ...state,
        versions: {
          ...state.versions,
          [payload?.orderId]: payload?.data?.data,
        },
      };
    default:
      return state;
  }
}
