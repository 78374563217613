import { message } from "antd";
import { envBaseAppUrl } from "../config";

export const logAndThrow = (tag) => (consoleMessage, uiMessage) => (error) => {
  if (consoleMessage !== "testing reject")
    console.error(tag, consoleMessage, error);
  if (uiMessage) message.error(uiMessage);
  return Promise.reject(error);
};

export function uploadSafeUrl(fileOrUrl) {
  const url =
    typeof fileOrUrl === "string"
      ? fileOrUrl
      : fileOrUrl?.attributes?.uploadUrl;
  return (
    envBaseAppUrl().replace(/\/api$/, "") +
    "/gcp-cs" +
    url?.replace("https://storage.googleapis.com", "")
  );
}
