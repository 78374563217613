import { errorMessages } from "../../helpers/errorHelpers";
import * as types from "../constants/constants";

const initial = {
  index: [],
  current: null,
  isLoading: false,
  timeline: [],
};

export default function (state = initial, { type, payload, error }) {
  switch (type) {
    case types.HEARING_GET_INDEX:
    case types.HEARING_GET_SHOW_MINUTES:
    case types.HEARING_PUT_UPDATE:
    case types.HEARING_POST_CREATE:
    case types.HEARING_GET_INDEX_MEMORANDUM:
    case types.HEARING_GET_SHOW_MEMORANDUM:
      return { ...state, isLoading: true, errors: {} };
    case types.HEARING_GET_SHOW:
      return { ...state, isShowLoading: true, errors: {} };
    case types.HEARING_HANDLE_INDEX:
      return payload.flow === 'upcoming' ? {
        ...state,
        isLoading: false,
        index: payload.hearings.data,
        indexMeta: payload.hearings.meta,
      } :  {
        ...state,
        isLoading: false,
        completed: payload.hearings.data,
        completedMeta:payload.hearings.meta, 
      };
    case types.HEARING_HANDLE_ERROR:
      return { ...state, isLoading: false, errors: errorMessages(error) };
    case types.HEARING_POST_CREATE_SUCCESS:
      return { ...state, isLoading: false, errors: {} };
    case types.HEARING_HANDLE_SHOW:
      return {
        ...state,
        isShowLoading: false,
        current: payload.data,
      };
    case types.HEARING_HANDLE_INDEX_MEMORANDUM:
      return {
        ...state,
        isLoading: false,
        indexMemorandum: payload.data,
        indexMetaMemorandum: payload.meta,
      };
    case types.HEARING_HANDLE_SHOW_MEMORANDUM:
      return { ...state, memorandum: payload.data, isLoading: false };
    case types.HEARING_HANDLE_SHOW_MINUTES:
      return { ...state, minutes: payload.data.attributes, isLoading: false };
    default:
      return state;
  }
}
