import { FolderTwoTone, RightOutlined } from "@ant-design/icons";
import React from "react";
import Translation from "../../Common/Translation";
import "./index.less";

const FolderOverview = ({ folderLabel, onClick, isOutlined, toolTip }) => {
  return (
    <div
      onClick={onClick}
      className={`jur-flex-justify-space-between jur-folder-list-main-div ${isOutlined ? "jur-outlined-folder" : ""}`}
    >
      <div className="jur-folder-icon">
        <FolderTwoTone twoToneColor="#f3aa69" />
      </div>
      <div className="jur-folder-label">
        <Translation text={folderLabel} />
      </div>
      <RightOutlined />
    </div>
  );
};

export default FolderOverview;
