import { Card, Col, Row, Tag, Typography } from "antd";
import React from "react";
import DisputeHeader from "../Common/DisputeHeader";
import Viewer from "../Common/Editor/Viewer";
import PDFRender from "../Common/PDFRender";
import TimelineWrapper from "../Common/TimelineWrapper";
import Translation from "../Common/Translation";
import { versionTable } from "./OrderList";
import ReviewOrderForm from "./ReviewOrderForm";
import ShareOrderForm from "./ShareOrderForm";
import ShowActions from "./ShowActions";
import UploadRevisedOrderForm from "./UploadRevisedOrderForm";

export default function ({
  dispute,
  disputeId,
  hubSlug,
  extraCrumbs,
  order,
  file,
  isShareOrderOpen,
  handleShareOrderOpen,
  handleShareOrderClose,
  handleShareOrderSubmit,
  isUploadRevisedOrderOpen,
  handleUploadRevisedOrderOpen,
  handleUploadRevisedOrderClose,
  handleUploadRevisedOrderSubmit,
  isReviewOrderOpen,
  handleReviewOrderOpen,
  handleReviewOrderClose,
  handleReviewOrderSubmit,
  handleShowLatest,
  errors,
}) {
  const versions = order?.attributes?.versions?.map((v) => {
    return {
      ...v,
      id: order?.id,
      attributes: { ...v, id: order?.id },
    };
  });
  return (
    <TimelineWrapper
      activityId={order?.id}
      activityType={"Procedural Order Show"}
    >
      <Card>
        <DisputeHeader
          dispute={dispute}
          disputeId={disputeId}
          hubSlug={hubSlug}
          extraCrumbs={extraCrumbs}
        >
          <Typography.Title level={3}>
            <Translation text="procedural_order.procedural_order.title" /> #
            {order?.attributes?.number} - Version {order?.attributes?.version}
          </Typography.Title>
          <ShowActions
            order={order}
            handleShareOrderOpen={handleShareOrderOpen}
            handleUploadRevisedOrderOpen={handleUploadRevisedOrderOpen}
            handleReviewOrderOpen={handleReviewOrderOpen}
            handleShowLatest={handleShowLatest}
          />
          <Card
            type="inner"
            title={
              <Row justify="space-between">
                <Col>
                  <Typography.Title level={5}>{file?.name}</Typography.Title>
                </Col>
                <Col>
                  <Tag>{order?.attributes?.status}</Tag>
                </Col>
              </Row>
            }
          >
            <OrderDetails order={order} />
            <PDFRender url={file?.downloadUrl} />
          </Card>
          <br />
          <Card
            type="inner"
            title={
              <Typography.Title level={5}>
                <Translation text="procedural_order.order_versions.title" />
              </Typography.Title>
            }
          >
            {versionTable(
              versions,
              hubSlug,
              disputeId,
              Math.max(...versions.map((v) => v?.attributes?.version))
            )}
          </Card>
        </DisputeHeader>
        <ShareOrderForm
          selectedOrder={order?.id}
          visible={isShareOrderOpen}
          handleShareOrderSubmit={handleShareOrderSubmit}
          handleShareOrderClose={handleShareOrderClose}
        />
        <UploadRevisedOrderForm
          selectedOrder={order?.id}
          visible={isUploadRevisedOrderOpen}
          handleUploadRevisedOrderSubmit={handleUploadRevisedOrderSubmit}
          handleUploadRevisedOrderClose={handleUploadRevisedOrderClose}
        />
        <ReviewOrderForm
          selectedOrder={order?.id}
          visible={isReviewOrderOpen}
          handleReviewOrderSubmit={handleReviewOrderSubmit}
          handleReviewOrderClose={handleReviewOrderClose}
          errors={errors}
        />
      </Card>
    </TimelineWrapper>
  );
}

const OrderDetails = ({ order }) => (
  <div>
    {order?.attributes?.highlights && (
      <>
        <Typography.Text strong={true}>
          <Translation text="procedural_order.order_highlights.title" />
        </Typography.Text>
        <Viewer value={order?.attributes?.highlights} />
      </>
    )}
    <br />
    {Boolean(order?.attributes?.receivers?.length) && (
      <>
        <Typography.Text strong={true}>
          <Translation text="procedural_order.shared_with.title" />
        </Typography.Text>
        <Viewer
          value={order?.attributes?.receivers

            ?.map((u) => `${u?.name} (${u?.group} - ${u.role})`)
            .join(", ")}
        />
      </>
    )}
    <br />
    {order?.attributes?.reasonForRejection && (
      <>
        <Typography.Text strong={true}>
          <Translation text="procedural_order.hub_requested_edits.title" />
        </Typography.Text>
        <Viewer value={order?.attributes?.reasonForRejection} />
      </>
    )}
  </div>
);
