import { Button, Divider, Form, Input, Radio, Typography } from "antd";
import React, { useState } from "react";
import { NamespacesConsumer } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { handleUpdateMotivation } from "../../redux/actions/disputeActions";
import { putUpdate } from "../../redux/actions/disputeAssignmentActions";
import { whole } from "../../redux/selectors/disputesSelector";
import Translation from "../Common/Translation";
import "./styles.less";

function ArbitratorRejectForm({ disputeParams }) {
  const dispatch = useDispatch();
  const { motivation } = useSelector(whole);
  const [selected, setSelected] = useState("");
  const [text, setText] = useState("");
  const handleSelect = ({ target }) => {
    const { value } = target;
    dispatch(handleUpdateMotivation(value?.startsWith("Other") ? text : value));
    setSelected(value);
  };

  const reject = () =>
    void dispatch(
      putUpdate(disputeParams.disputeId, "reject", {
        accepted: false,
        reasonForRejection: motivation,
      })
    );

  const handleText = ({ target }) => {
    const { value } = target;
    dispatch(handleUpdateMotivation(value));
    setText(value);
  };

  return (
    <div>
      <br />
      <Form layout="vertical">
        <Form.Item
          name="motivation"
          label={
            <Typography.Text strong>
              <Translation text="dispute.reject_motivation.text" />
            </Typography.Text>
          }
        >
          <NamespacesConsumer>
            {(t) => (
              <Radio.Group onChange={handleSelect}>{options(t)}</Radio.Group>
            )}
          </NamespacesConsumer>
        </Form.Item>
        <Form.Item>
          <Input.TextArea
            onChange={handleText}
            disabled={!selected?.startsWith("Other")}
          ></Input.TextArea>
        </Form.Item>
      </Form>
      <Divider />

      <Button onClick={reject} size="small" type="primary">
        <Translation text="general.done.action" />
      </Button>
    </div>
  );
}

export default ArbitratorRejectForm;

function options(t) {
  const fields = [
    "dispute.not_enough_knowledge.text",
    "dispute.exisiting_relationship.text",
    "dispute.unavailability.text",
    "dispute.other.text",
  ];

  return fields.map((label) => (
    <Radio
      value={t(label)}
      key={label}
      className="jur-width-full jur-rejection-options"
    >
      <Translation text={label} />
    </Radio>
  ));
}
