import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { isUserActive, readLoggedInUser } from "../../helpers/authHelpers";
import { getShow, handleUpdateForm, putUpdate } from "../../redux/actions/userProfileActions";
import { selectProfile, selectWhole } from "../../redux/selectors/userProfileSelector";
import Anchor from "../Common/Anchor";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import OnboardingRender from "./OnboardingRender";

export default function OnboardingHOC() {
  const currentRoute = useLocation();
  const path = currentRoute?.pathname;
  const isOnboarding = path === "/onboarding";
  const userName = readLoggedInUser().name;
  const isBasicProfileComplete = isUserActive();
  const profile = useSelector(selectProfile);
  const { isLoading } = useSelector(selectWhole);

  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleNext = () => {
    if (currentStep === 0 || currentStep === 1) setCurrentStep(currentStep + 1);
    if (currentStep === 2) {
      dispatch(putUpdate(profile));
    }
  };

  const handleUpdate = (values) => {
    void dispatch(handleUpdateForm(values));
  };

  const handleBack = () => {
    if (currentStep === 2 || currentStep === 1) setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    dispatch(getShow());
  }, [dispatch]);

  if (!isOnboarding && isBasicProfileComplete) return isLoading ? <Spinner /> : <ProfileDisplay profile={profile} />;

  return (
    <OnboardingRender
      currentStep={currentStep}
      onNext={handleNext}
      onBack={handleBack}
      isDrawerOpen={isDrawerOpen}
      setDrawerOpen={setDrawerOpen}
      onUpdate={handleUpdate}
      isLoading={isLoading}
      isOnboarding={isOnboarding}
      userName={userName}
    />
  );
}

const ProfileDisplay = ({ profile }) => {
  return (
    <ProfileDisplayWrapper>
      <center>
        <Typography.Title level={3}>Your Profile</Typography.Title>
        <Typography.Title level={5} className="secondary-color">
          Please contact <Anchor href="mailto:support@jur.io">support@jur.io</Anchor> to request updates
        </Typography.Title>
      </center>
      <div className="jur-profile-show-card">
        {[
          {
            label: <Translation text="general.full_name.text" />,
            value: `${profile?.firstName} ${profile?.lastName}`,
          },
          {
            label: <Translation text="general.mobile_number.text" />,
            value: profile?.phone,
          },
          {
            label: <Translation text="general.address.text" />,
            value: Object.values(profile?.primaryAddress).filter(Boolean).join(", "),
          },
        ].map((a) => (
          <div>
            <Typography.Title level={5}>{a.label}</Typography.Title>
            <Typography.Text className="secondary-color">{a.value}</Typography.Text>
          </div>
        ))}
      </div>
    </ProfileDisplayWrapper>
  );
};

const ProfileDisplayWrapper = ({ children }) => (
  <div className="jur-profile-show-wrapper">
    <Row>
      <Col span={10} offset={7}>
        {children}
      </Col>
    </Row>
  </div>
);
