import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { handleUpdateSlug } from "../redux/actions/hubActions";

export function useHubSlug({ slug = null, noWarning = false } = {}) {
  const { hubSlug } = useParams();
  const effectiveSlug = slug || hubSlug;

  if (!effectiveSlug && !noWarning) {
    console.warn("useHubSlug couldn't get effective slug");
  }

  const dispatch = useDispatch();

  useEffect(() => void dispatch(handleUpdateSlug(effectiveSlug)), [
    effectiveSlug,
    dispatch,
  ]);

  return effectiveSlug;
}
