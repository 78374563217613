import { errorMessages } from "../../helpers/errorHelpers";

export const getStringFromFeature = {
  PROCEDURALORDER: "proceduralOrder",
};

export default function genericReducer(state, action) {
  const actionFeature = action.type?.split(/_(.+)/)[0];
  const actionType = action.type?.split(/_(.+)/)[1];
  const feature = getStringFromFeature[actionFeature];

  switch (actionType) {
    case "SET_IS_FORM_VISIBLE": {
      const feat = { ...state[feature], ...action.payload, errors: null };
      return { ...state, [feature]: feat };
    }

    case "SET": {
      const feat = { ...state[feature], ...action.payload, errors: null };
      return { ...state, [feature]: feat };
    }

    case "FETCH_INDEX": {
      const feat = state[feature];
      feat.index = [];
      feat.isFetchingIndex = true;
      state[feature] = feat;
      return state;
    }

    case "SET_INDEX": {
      const feat = state[feature];
      feat.index = action?.payload?.data;
      feat.indexMeta = action?.payload?.meta;
      feat.isFetchingIndex = false;
      state[feature] = feat;
      return state;
    }

    case "FETCH_CURRENT": {
      return { ...state, [feature]: { ...state[feature], current: null } };
    }

    case "SET_CURRENT": {
      const feat = state[feature];
      feat.current = action?.payload?.data;
      state[feature] = feat;
      return state;
    }

    case "HANDLE_ERROR": {
      return {
        ...state,
        [feature]: {
          ...state[feature],
          isSubmitting: false,
          isFetchingIndex: false,
          errors: errorMessages(action.error),
        },
      };
    }

    default:
      return state;
  }
}
