import IndexHOC from "./IndexHOC";
import IndexMemorandumHOC from "./IndexMemorandumHOC";
import ShowMemorandumHOC from "./ShowMemorandumHOC";
import ShowMinutesHOC from "./ShowMinutesHOC";

export default {
  Index: IndexHOC,
  IndexMemorandum: IndexMemorandumHOC,
  ShowMemorandum: ShowMemorandumHOC,
  ShowMinutes: ShowMinutesHOC,
};
