import { toScheduleParticipants } from "../../helpers/participantHelpers";
import * as types from "../constants/constants";

export const getIndex = (disputeId, page = 1, flow = 'completed') => ({
  type: types.HEARING_GET_INDEX,
  payload: { disputeId, page, flow },
});

export const getShow = (disputeId, hearingId) => ({
  type: types.HEARING_GET_SHOW,
  payload: { disputeId, hearingId },
});

export const postCreate = (disputeId, body) => ({
  type: types.HEARING_POST_CREATE,
  payload: {
    disputeId,
    body: {
      ...body,
      participants: toScheduleParticipants(body.participants),
    },
  },
});

export const handlePostCreateSuccess = () => ({
  type: types.HEARING_POST_CREATE_SUCCESS,
});

export const putUpdate = (disputeId, hearingId, body) => ({
  type: types.HEARING_PUT_UPDATE,
  payload: { disputeId, hearingId, body },
});

export const handleIndex = (hearings, flow) => ({
  type: types.HEARING_HANDLE_INDEX,
  payload: {hearings, flow},
});

export const handleShow = (hearing) => ({
  type: types.HEARING_HANDLE_SHOW,
  payload: hearing,
});

export const handleCreate = () => ({
  type: types.HEARING_HANDLE_CREATE,
});

export const handleUpdate = () => ({
  type: types.HEARING_HANDLE_UPDATE,
});

export const handleError = (e) => ({
  type: types.HEARING_HANDLE_ERROR,
  error: e,
});

export const getIndexMemorandum = (disputeId, hearingId) => ({
  type: types.HEARING_GET_INDEX_MEMORANDUM,
  payload: { disputeId, hearingId },
});

export const handleIndexMemorandum = (memorandums) => ({
  type: types.HEARING_HANDLE_INDEX_MEMORANDUM,
  payload: memorandums,
});

export const getShowMemorandum = (disputeId, hearingId, memorandumId) => ({
  type: types.HEARING_GET_SHOW_MEMORANDUM,
  payload: { disputeId, hearingId, memorandumId },
});

export const handleShowMemorandum = (memorandum) => ({
  type: types.HEARING_HANDLE_SHOW_MEMORANDUM,
  payload: memorandum,
});

export const putUpdateMemorandum = (disputeId, hubSlug, hearingId, fileId) => ({
  type: types.HEARING_PUT_UPDATE_MEMORANDUM,
  payload: { disputeId, hubSlug, hearingId, fileId },
});

export const handleUpdateMemorandum = () => ({
  type: types.HEARING_HANDLE_UPDATE_MEMORANDUM,
});

export const handleSignMemorandumCompleted = (disputeId, params) => ({
  type: types.HEARING_HANDLE_SIGN_MEMORANDUM_COMPLETED,
  payload: { disputeId, params },
});

export const getShowMinutes = (disputeId, hearingId) => ({
  type: types.HEARING_GET_SHOW_MINUTES,
  payload: { disputeId, hearingId },
});

export const handleShowMinutes = (memorandums) => ({
  type: types.HEARING_HANDLE_SHOW_MINUTES,
  payload: memorandums,
});

export const postCreateMinutes = (
  disputeId,
  hubSlug,
  hearingId,
  fileId,
  signerIds,
  uiRedirectUrl
) => ({
  type: types.HEARING_POST_CREATE_MINUTES,
  payload: { disputeId, hubSlug, hearingId, fileId, signerIds, uiRedirectUrl },
});

export const handleUpdateMinutes = () => ({
  type: types.HEARING_HANDLE_UPDATE_MINUTES,
});

export const handleSignMinutesCompleted = (disputeId, params) => ({
  type: types.HEARING_HANDLE_SIGN_MINUTES_COMPLETED,
  payload: { disputeId, params },
});

export const handleSignatureError = (disputeId, params) => ({
  type: types.HEARING_HANDLE_MEMORANDUM_SIGNATURE_ERROR,
  payload: { disputeId, params },
});
