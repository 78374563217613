import { logAndThrow } from "../../helpers/apiHelpers";
import Api, { hubless } from "./Api";

const tag = "disputes";
const catcher = logAndThrow(tag);

export function index({ only, page = 1 }) {
  const params = only ? `&only=${only}` : "";
  return hubless
    .get(`/disputes?page=${page}${params}`)
    .then((res) => res.data)
    .catch(catcher("index"));
}

export function show(disputeId) {
  return Api.get(`/disputes/${disputeId}`)
    .then((res) => res.data)
    .catch(catcher("show"));
}

export function update(disputeId, body) {
  return Api.put(`/disputes/${disputeId}`, body)
    .then((res) => res.data)
    .catch(catcher("update", "Failed to update"));
}

export function updateLinkEnvelope(disputeId, body) {
  return Api.put(`/disputes/${disputeId}/link-signature`, body)
    .then((res) => res.data)
    .catch(
      catcher(
        "updateLinkEnvelope",
        "Failed to link signature envelope to dispute"
      )
    );
}

export function updateClose(disputeId) {
  return Api.put(`/disputes/${disputeId}/close-dispute`)
    .then((res) => res.data)
    .catch(catcher("update", "Failed to close dispute"));
}
