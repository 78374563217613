import Axios from "axios";
import { appEnv, baseAPIUrl } from "../../config";
import { readToken } from "../../helpers/authHelpers";
import { selectCurrentSlug } from "../selectors/hubsSelector";

let activeInterceptors = {};

function AuthInterceptor(config) {
  MultitenantInterceptor(config);
  const token = readToken();
  if (token) config.headers["Authorization"] = `Bearer ${token}`;
  return config;
}

function MultitenantInterceptor(config) {
  if (config.baseURL.endsWith("hub-slug")) {
    const hubSlug = selectCurrentSlug(window.store.getState());
    config.baseURL = config.baseURL.replace("hub-slug", hubSlug);
  }
  return config;
}

function createAxios(slug) {
  const returnable = Axios.create({
    baseURL: baseAPIUrl(slug),
    headers: { "X-OJP-Env": appEnv() },
  });

  if (activeInterceptors[slug])
    returnable.interceptors.request.eject(activeInterceptors[slug]);
  activeInterceptors[slug] = returnable.interceptors.request.use(
    AuthInterceptor
  );

  return returnable;
}

export const hubless = createAxios("");
export default createAxios("hub-slug");
