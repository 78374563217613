import { Button, Card, Typography } from "antd";
import React from "react";
import DisputeHeader from "../Common/DisputeHeader";
import PDFRender from "../Common/PDFRender";
import TimelineWrapper from "../Common/TimelineWrapper";
import Translation from "../Common/Translation";
import SendDocumentsForm from "./SendDocumentsForm";

export default function ({
  dispute,
  disputeId,
  hubSlug,
  extraCrumbs,
  evidenceRequest,
  isSendDocumentsVisible,
  openSendDocuments,
  closeSendDocuments,
  handleSendDocuments,
  hasFile,
  file,
  isLoading,
  canUpload,
  redirectUrl,
  deleteFile,
}) {
  return (
    <TimelineWrapper
      activityId={evidenceRequest?.id}
      activityType="Evidence Request Show"
    >
      <Card>
        <DisputeHeader
          dispute={dispute}
          disputeId={disputeId}
          hubSlug={hubSlug}
          extraCrumbs={extraCrumbs}
        >
          <Typography.Title level={3}>
            <Translation text="evidence_request.evidence_requests.title" />
          </Typography.Title>
          {canUpload && (
            <>
              <Button type="primary" onClick={openSendDocuments}>
                <Translation text="evidence_request.send_documents.text" />
              </Button>
              <br />
              <br />
            </>
          )}
          <Card
            type="inner"
            title={
              <Typography.Title level={4}>
                {`Request ${evidenceRequest?.id} ${
                  hasFile ? `- ${file?.name}` : ""
                }`}
              </Typography.Title>
            }
          >
            <Typography.Title level={5}>
              <Translation text="evidence_request.context_of_the_request.text" />
            </Typography.Title>
            <Typography.Text>
              {evidenceRequest?.attributes?.context}
            </Typography.Text>
            <br />
            <br />
            {hasFile && file?.downloadUrl ? (
              <PDFRender url={file?.downloadUrl} />
            ) : null}
          </Card>
        </DisputeHeader>
        <SendDocumentsForm
          redirectUrl={redirectUrl}
          isLoading={isLoading}
          visible={isSendDocumentsVisible}
          closeSendDocuments={closeSendDocuments}
          handleSendDocuments={handleSendDocuments}
          preselectedRequest={evidenceRequest}
          deleteFile={deleteFile}
        />
      </Card>
    </TimelineWrapper>
  );
}
