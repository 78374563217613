import { Button, Card, Col, Row } from "antd";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useHubSlug } from "../../helpers/hubHelpers";
import TimelineHOC from "../Timeline/ShowHOC";
import Translation from "./Translation";

export default function TimelineWrapper({
  children,
  isDisputeClosedByHub = false,
  ...otherProps
}) {
  const { disputeId } = useParams();
  const hubSlug = useHubSlug();

  const ArbitralAwardDownload = () => (
    <center>
      <Link to={`/${hubSlug}/disputes/${disputeId}/arbitral-award-download`}>
        <Button type="primary">
          <Translation text="general.download_arbitral_award.button" />
        </Button>
      </Link>
    </center>
  );

  let tlProps = {};
  if (isDisputeClosedByHub) tlProps.title = <ArbitralAwardDownload />;

  return (
    <Row gutter={8}>
      <Col span={16}>{children}</Col>
      <Col span={8}>
        <Card {...tlProps}>
          <TimelineHOC {...otherProps} />
        </Card>
      </Col>
    </Row>
  );
}
