import { Modal } from "antd";
import { push } from "connected-react-router";
import React from "react";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  errorMessage,
  successMessage,
} from "../../components/Common/Feedback/Message";
import ActivationMessage from "../../components/Signs/ActivationMessage";
import { handleUpdateSlug } from "../actions/hubActions";
import * as actions from "../actions/responseFilingActions";
import * as signActions from "../actions/signActions";
import * as apis from "../apis/responseFilingApi";
import * as signApis from "../apis/signApi";
import * as types from "../constants/constants";
import { selectCurrentSlug } from "../selectors/hubsSelector";

const tag = "response-filing";

export function* postCreate(action) {
  const { body } = action.payload;
  try {
    const res = yield call(apis.create, body);
    yield put(actions.handleCreate(res));
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(actions.handleError(e));
  }
}

function showOf(crumb) {
  return function* (action) {
    const { disputeId } = action.payload;
    try {
      const res = yield call(apis[`show${crumb}`], disputeId);
      yield put(actions[`handleShow${crumb}`](res));
    } catch (e) {
      console.error(tag, `saga getShow${crumb} error:`, e);
      yield put(actions.handleError(e));
    }
  };
}

function updateOf(crumb) {
  return function* (action) {
    const { disputeId, body, hubSlug, goBack } = action.payload;
    try {
      const res = yield call(apis[`update${crumb}`], disputeId, body);
      yield put(
        actions[`handleUpdate${crumb}`]({
          res,
          isDraft: body?.isDraft,
          goBack: goBack,
        })
      );
      if (body?.isDraft) {
        successMessage("general.draft_saved_successfully.message");
      } else {
        successMessage("general.updated_successfully.message");
      }
      if (crumb === "DefenceTeam") {
        yield put(push(`/${hubSlug}/disputes/${disputeId}`));
      }
    } catch (e) {
      console.error(tag, `saga getUpdate${crumb} error:`, e);
      yield put(actions.handleError(e));
    }
  };
}

export const getShowDefenceTeam = showOf("DefenceTeam");
export const putUpdateDefenceTeam = updateOf("DefenceTeam");

export const getShowKyc = showOf("Kyc");
export const putUpdateKyc = updateOf("Kyc");

export const getShowRespondent = showOf("Respondent");
export const putUpdateRespondent = updateOf("Respondent");

export const getShowResponseDetails = showOf("ResponseDetails");
export const putUpdateResponseDetails = updateOf("ResponseDetails");

export const getShowResponseFiles = showOf("ResponseFiles");
export const putUpdateResponseFiles = updateOf("ResponseFiles");

export const getShowInvites = showOf("Invites");
export const putUpdateInvites = updateOf("Invites");

export const getShowPayment = showOf("Payment");
export const putUpdatePayment = updateOf("Payment");

export const getShowSod = showOf("Sod");

export function* putUpdateSod(action) {
  let { disputeId, body, redirectTo } = action.payload;
  const slug = yield select(selectCurrentSlug);
  redirectTo = redirectTo || `${slug}/response-filing/${disputeId}?step=sign`;
  const createEnvelopeBody = {
    fileIds: [].concat(body.fileId || body.fileIds),
    flow: "Statement Of Defence",
    signType: "Self Sign",
    signableId: disputeId,
  };

  try {
    const resConsent = yield call(signApis.consent);
    if (!resConsent?.data?.attributes?.activationStatus) {
      yield Modal.info({
        title: <ActivationMessage />,
      });
      throw new Error("Activation Missing");
    }
    if (!resConsent?.data?.attributes?.consentStatus) {
      const resConsentUrl = yield call(signApis.consentUrl, redirectTo);
      yield put(
        signActions.handleConsentUrl(
          resConsentUrl?.data?.attributes?.url,
          resConsent?.data?.attributes?.activationStatus
        )
      );
    } else {
      yield put(signActions.handleIsRedirecting(true));
      console.log("putUpdateSod body", body);
      if (!body.envelopeId) {
        yield put(
          signActions.postCreateEnvelope(
            createEnvelopeBody,
            disputeId,
            null,
            slug
          )
        );
      } else {
        console.log("putUpdateSod has envelopeId");
        window.location = body.redirectURL.url;
      }
    }
  } catch (e) {
    console.error(tag, `saga putUpdateSod error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* handleUpdateSod(action) {
  const { disputeId, disputeStatus, signatureStatus } = action.payload;
  try {
    yield put(
      actions.putUpdateCompleted(disputeId, disputeStatus, signatureStatus)
    );
  } catch (e) {
    console.error(tag, `saga handleUpdateSod error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateCompleted(action) {
  const { disputeId, hubSlug } = action.payload?.meta;
  try {
    console.log("received hubSlug as", action.payload);
    yield put(handleUpdateSlug(hubSlug));
    // yield call(apis.updateCompleted, disputeId);
    yield put(push(`/${hubSlug}/response-filing/${disputeId}?step=completed`));
  } catch (e) {
    console.error(tag, `saga putUpdateCompleted error:`, e);
    yield put(actions.handleError(e));
  }
}

export function* handleSignatureError({ payload }) {
  const { disputeId, hubSlug } = payload?.params;
  errorMessage("general.signature_error.text");
  if (hubSlug && disputeId) {
    yield put(handleUpdateSlug(hubSlug));
    yield put(push(`/${hubSlug}/response-filing/${disputeId}?step=sign`));
  } else yield put(push(`/`));
}

export default function* responseFilingSaga() {
  yield takeEvery(types.RESPONSEFILING_POST_CREATE, postCreate);
  yield takeLatest(
    types.RESPONSEFILING_GET_SHOW_DEFENCETEAM,
    getShowDefenceTeam
  );
  yield takeLatest(
    types.RESPONSEFILING_PUT_UPDATE_DEFENCETEAM,
    putUpdateDefenceTeam
  );

  yield takeLatest(types.RESPONSEFILING_GET_SHOW_KYC, getShowKyc);
  yield takeLatest(types.RESPONSEFILING_PUT_UPDATE_KYC, putUpdateKyc);

  yield takeLatest(
    types.RESPONSEFILING_PUT_UPDATE_RESPONDENT,
    putUpdateRespondent
  );
  yield takeLatest(types.RESPONSEFILING_GET_SHOW_RESPONDENT, getShowRespondent);

  yield takeLatest(
    types.RESPONSEFILING_PUT_UPDATE_RESPONSEDETAILS,
    putUpdateResponseDetails
  );
  yield takeLatest(
    types.RESPONSEFILING_GET_SHOW_RESPONSEDETAILS,
    getShowResponseDetails
  );

  yield takeLatest(
    types.RESPONSEFILING_PUT_UPDATE_RESPONSEFILES,
    putUpdateResponseFiles
  );
  yield takeLatest(
    types.RESPONSEFILING_GET_SHOW_RESPONSEFILES,
    getShowResponseFiles
  );

  yield takeLatest(types.RESPONSEFILING_GET_SHOW_INVITES, getShowInvites);
  yield takeLatest(types.RESPONSEFILING_PUT_UPDATE_INVITES, putUpdateInvites);

  yield takeLatest(types.RESPONSEFILING_PUT_UPDATE_PAYMENT, putUpdatePayment);
  yield takeLatest(types.RESPONSEFILING_GET_SHOW_PAYMENT, getShowPayment);

  yield takeLatest(types.RESPONSEFILING_PUT_UPDATE_SOD, putUpdateSod);
  yield takeLatest(types.RESPONSEFILING_GET_SHOW_SOD, getShowSod);
  yield takeEvery(
    types.RESPONSEFILING_PUT_UPDATE_COMPLETED,
    putUpdateCompleted
  );
  yield takeLatest(
    types.RESPONSEFILING_HANDLE_SIGNATURE_ERROR,
    handleSignatureError
  );
}
