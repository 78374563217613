import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "proceduralOrder";
const catcher = logAndThrow(tag);

export function versions(disputeId, orderId) {
  return Api.get(
    `/disputes/${disputeId}/procedural-orders/${orderId}/versions`
  ).catch(catcher("getVersions"));
}

export function create(disputeId, body) {
  return Api.post(`/disputes/${disputeId}/procedural-orders`, body).catch(
    catcher("create")
  );
}

export function createRevision(disputeId, body) {
  return Api.put(
    `/disputes/${disputeId}/procedural-orders/${body?.orderId}/revision`,
    body
  ).catch(catcher("createRevision"));
}

export function flowList(disputeId, flow) {
  return Api.get(
    `/disputes/${disputeId}/procedural-orders/list-only?flow=${flow}`
  ).catch(catcher("flowList"));
}

export function update(disputeId, body) {
  return Api.put(
    `/disputes/${disputeId}/procedural-orders/${body?.orderId}`,
    body
  ).catch(catcher("update"));
}
