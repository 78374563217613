import * as types from "../constants/constants";

export const getIndex = (disputeId) => ({
  type: types.DISPUTEASSIGNMENT_GET_INDEX,
  payload: { disputeId },
});

export const handleReset = () => ({
  type: types.DISPUTEASSIGNMENT_HANDLE_RESET,
});

export const handleSignCompleted = (disputeId, extras) => ({
  type: types.DISPUTEASSIGNMENT_HANDLE_SIGN_COMPLETED,
  payload: { disputeId, ...extras },
});

export const getIndexArbitrators = () => ({
  type: types.DISPUTEASSIGNMENT_GET_INDEX_ARBITRATORS,
});

export const getIndexExperts = () => ({
  type: types.DISPUTEASSIGNMENT_GET_INDEX_EXPERTS,
});

export const getShow = (disputeId, disputeAssignmentId) => ({
  type: types.DISPUTEASSIGNMENT_GET_SHOW,
  payload: { disputeId, disputeAssignmentId },
});

export const getShowDirect = (disputeAssignmentId) => ({
  type: types.DISPUTEASSIGNMENT_GET_SHOW_DIRECT,
  payload: { disputeAssignmentId },
});

export const postCreate = (disputeId, body) => ({
  type: types.DISPUTEASSIGNMENT_POST_CREATE,
  payload: { disputeId, body },
});

export const putUpdate = (disputeId, disputeAssignmentId, body) => ({
  type: types.DISPUTEASSIGNMENT_PUT_UPDATE,
  payload: { disputeId, disputeAssignmentId, body },
});

export const putUpdateFile = (disputeId, fileCategory, fileId) => ({
  type: types.DISPUTEASSIGNMENT_PUT_UPDATE_FILE,
  payload: {
    disputeId,
    disputeAssignmentId: "accept",
    body: {
      flow: fileCategory,
      fileId,
    },
  },
});

export const putUpdateDirect = (disputeAssignmentId, body) => ({
  type: types.DISPUTEASSIGNMENT_PUT_UPDATE_DIRECT,
  payload: { disputeAssignmentId, body },
});

export const handleIndex = (evidenceRequests) => ({
  type: types.DISPUTEASSIGNMENT_HANDLE_INDEX,
  payload: evidenceRequests,
});

export const handleResult = (result) => ({
  type: types.DISPUTEASSIGNMENT_HANDLE_RESULT,
  payload: result,
});

export const handleShow = (evidenceRequest) => ({
  type: types.DISPUTEASSIGNMENT_HANDLE_SHOW,
  payload: evidenceRequest,
});

export const handleCreate = () => ({
  type: types.DISPUTEASSIGNMENT_HANDLE_CREATE,
});

export const handleUpdate = () => ({
  type: types.DISPUTEASSIGNMENT_HANDLE_UPDATE,
});

export const handleError = (e) => ({
  type: types.DISPUTEASSIGNMENT_HANDLE_ERROR,
  error: e,
});

export const handleSignatureError = (disputeId, params) => ({
  type: types.DISPUTEASSIGNMENT_HANDLE__SIGNATURE_ERROR,
  payload: { disputeId, params },
});
