import { Col, Row, Tag, Typography } from "antd";
import React from "react";
import Spinner from "../../Common/Spinner";
import Translation from "../../Common/Translation";
import { UserDisplay } from "../../ResponseFiling/InviteRender";

const { Title, Text } = Typography;

export default function DisputeDetails({ dispute }) {
  if (!dispute) return <Spinner />;
  return (
    <div>
      <Title level={4}>{dispute?.name}</Title>
      <Row gutter={[20, 20]}>
        {columnValues(dispute).map((c) => (
          <>
            <Col span={6}>
              <Text className="secondary-color" strong>
                <Translation text={c?.label} />
              </Text>
            </Col>
            <Col span={18}>{c?.value}</Col>
          </>
        ))}
      </Row>
    </div>
  );
}

const columnValues = (dispute) => [
  {
    label: "dispute.area_of_law.text",
    value: dispute?.claimant?.specializations.map((s) => <Tag>{s}</Tag>),
  },
  {
    label: "dispute.seat_of_arbitration.text",
    value: <ValueTitle>{dispute?.claimant?.seatOfArbitration}</ValueTitle>,
  },
  {
    label: "dispute.dispute_value.text",
    value: <ValueTitle>${dispute?.disputeValue}</ValueTitle>,
  },
  {
    label: "general.claimant.title",
    value: partyDetails(dispute?.claimant),
  },
  {
    label: "general.respondent.title",
    value: partyDetails(dispute?.respondent),
  },
  {
    label: "dispute.claimant_team.text",
    value: (
      <Row>
        {dispute?.claimant?.team?.map((u) => (
          <Col span={12}>
            <UserDisplay party="claimant" user={u} />
          </Col>
        ))}
      </Row>
    ),
  },
  {
    label: "dispute.respondent_team.text",
    value: (
      <Row>
        {dispute?.respondent?.team?.map((u) => (
          <Col span={12}>
            <UserDisplay user={u} />
          </Col>
        ))}
      </Row>
    ),
  },
  dispute?.arbitrator?.fullName
    ? {
        label: "dispute.arbitrator.column",
        value: (
          <Row>
            <Col span={12}>
              <UserDisplay
                user={{ fullName: `${dispute?.arbitrator?.fullName} - ${dispute?.arbitrator?.email || ""}` }}
              />
            </Col>
          </Row>
        ),
      }
    : null,
];

const ValueTitle = ({ children }) => <Text strong>{children}</Text>;

const partyDetails = (party) => {
  return (
    <>
      <ValueTitle>{party?.fullName}</ValueTitle>
      <div className="secondary-color">
        <Translation text="Address" />: {Object.values(party?.primaryAddress).filter(Boolean).join(", ")}
        <br />
        <Translation text="Phone Number" />: {party?.contact}
        <br />
        <Translation text="Email Address" />: {party?.email}
      </div>
    </>
  );
};
