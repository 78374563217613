import * as types from "../constants/constants";

export const getIndex = (disputeId) => ({
  type: types.ARBITRATORPANEL_GET_INDEX,
  payload: { disputeId },
});

export const handleIndex = (data) => ({
  type: types.ARBITRATORPANEL_HANDLE_INDEX,
  payload: data,
});

export const postCreate = (disputeId, list) => ({
  type: types.ARBITRATORPANEL_POST_CREATE,
  payload: { disputeId, list },
});

export const handleCreate = (data) => ({
  type: types.ARBITRATORPANEL_HANDLE_CREATE,
  payload: data,
});

export const handleUpdateStrikeList = (list) => ({
  type: types.ARBITRATORPANEL_HANDLE_UPDATE_STRIKELIST,
  payload: { list },
});

export const putUpdateStrike = (hubSlug, disputeId, list) => ({
  type: types.ARBITRATORPANEL_PUT_UPDATE_STRIKE,
  payload: { hubSlug, disputeId, list },
});

export const handleError = (e) => ({
  type: types.ARBITRATORPANEL_HANDLE_ERROR,
  error: e,
});
