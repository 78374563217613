import { Col, Row } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React from "react";
import { useSelector } from "react-redux";
import { phoneNumberFormatter } from "../../helpers/dataHelpers";
import { AddressFields, toFormItem } from "../../helpers/formHelpers";
import {
  selectErrors,
  selectIsErrorHidden,
  selectProfile,
  selectWhole,
} from "../../redux/selectors/userProfileSelector";
import InputFormatter from "../Common/InputFormatter";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import { handleChange } from "../DisputeFiling/helpers";

export default function ProfileUpdateForm({ onUpdate }) {
  const profile = useSelector(selectProfile);
  const { isLoading } = useSelector(selectWhole);
  const isErrorHidden = useSelector(selectIsErrorHidden);
  const errors = useSelector(selectErrors);
  const errorProps = { isHidden: isErrorHidden };

  return (
    <Formik initialValues={profile}>
      {({ values, setFieldValue }) => {
        const formItemProps = {
          values,
          setFieldValue,
          propogateValues: onUpdate,
          errors,
          errorProps,
        };

        const handleChangeOf = handleChange(values, setFieldValue, onUpdate);

        if (isLoading) return <Spinner />;
        return (
          <div className="onboarding-user-details-step">
            <>
              <Form layout="vertical">
                <NameFields formItemProps={formItemProps} />
                {mobileNumberField({ errors, handleChangeOf })}
                <FormItem name="primaryAddress" label={<Translation text="general.address.text" />} required={true}>
                  <AddressFields {...formItemProps} />
                </FormItem>
              </Form>
            </>
          </div>
        );
      }}
    </Formik>
  );
}

const mobileNumberField = ({ errors, handleChangeOf }) => {
  return (
    <InputFormatter
      name="phone"
      prefix="+01"
      errors={errors}
      label="general.mobile_number.text"
      onChange={handleChangeOf("phone")}
      placeholder={"(000) 000-0000"}
      formatter={phoneNumberFormatter}
    />
  );
};

const NameFields = ({ formItemProps }) => (
  <FormItem name="name" label={<Translation text="general.full_name.text" />} required={true}>
    <Row gutter={[24, 0]}>
      {[
        {
          name: "firstName",
        },
        {
          name: "lastName",
        },
      ]
        .map(toFormItem({ ...formItemProps, compact: true }))
        .map((component, i) => (
          <Col key={i} span={12}>
            {component}
          </Col>
        ))}
    </Row>
  </FormItem>
);
