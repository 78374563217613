const tlParams = {};

export function useTimelineParams({
  isEntireHistory = false,
  activityType,
  activityId,
}) {
  delete tlParams.isEntireHistory;
  delete tlParams.activityId;
  delete tlParams.activityType;
  if (isEntireHistory) tlParams.perPage = -1;
  if (activityType) tlParams.activityType = activityType;
  if (activityId) tlParams.activityId = activityId;
  return tlParams;
}
