import React from "react";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import Spinner from "../Common/Spinner";
import HistoryRender from "./HistoryRender";

export default function () {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();
  const headerProps = {
    disputeId,
    dispute,
    hubSlug,
    extraCrumbs: [
      {
        path: `/${hubSlug}/disputes/${disputeId}/history`,
        breadcrumbName: "History",
      },
    ],
  };

  if (!dispute) return <Spinner />;
  return <HistoryRender headerProps={headerProps} />;
}
