import { Button, Table, Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getShow } from "../../redux/actions/fileActions";
import DateTime from "./DateTime";
import Translation from "./Translation";

export default function ({
  files,
  rowKey = "id",
  pagination = false,
  columnsFilter = null,
}) {
  const dispatch = useDispatch();
  const download = (id) => dispatch(getShow(id));

  return (
    <>
      <Typography.Title level={5}>
        <Translation text="files.files.title" />
      </Typography.Title>
      {files?.length ? (
        <Table
          size="small"
          dataSource={files}
          columns={columns(download, columnsFilter)}
          rowKey={rowKey}
          pagination={pagination}
        />
      ) : (
        <Typography.Text type="secondary">
          <Translation text="files.no_files.text" />
        </Typography.Text>
      )}
    </>
  );
}

function columns(onClick, columnsFilter) {
  return [
    { dataIndex: "name", title: "files.name.column" },
    { dataIndex: "description", title: "files.description.column" },
    {
      dataIndex: "uploadedOn",
      title: "files.uploaded_on.column",
      render: (d) => <DateTime epochSeconds={d} />,
    },
    { dataIndex: ["uploadedBy", "name"], title: "files.uploaded_by.column" },
    { dataIndex: ["uploadedBy", "role"], title: "general.role.column" },
    { dataIndex: "fileCategory", title: "files.fileCategory.column" },
    {
      dataIndex: "id",
      title: "general.action.column",
      render: (id) => (
        <Button onClick={() => onClick(id)}>
          <Translation text="files.download.button" />
        </Button>
      ),
    },
  ]
    .filter((c) => (columnsFilter ? columnsFilter(c) : true))
    .map((c) => ({ ...c, title: <Translation text={c.title} /> }));
}
