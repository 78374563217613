import React from "react";
import { Space, Tag, Typography } from "antd";
import Viewer from "../Common/Editor/Viewer";
import Translation from "../Common/Translation";
import ErrorBoundary from "../Error/ErrorBoundary";

function HearingDetails({ record }) {
  return (
    <Space direction="vertical" size="large">
      <Space direction="vertical" size="small">
        <Typography.Title level={5}>
          <Translation text="hearings.participants.column" />
        </Typography.Title>
        <ErrorBoundary key={1}>
          {record?.attributes?.participants.map((email) => (
            <Tag key={email} color="#f4f5fa">
              <span className="jur-navbar-dropdown-item">{email}</span>
            </Tag>
          ))}
        </ErrorBoundary>
      </Space>
      <div>
        <Typography.Title level={5}>
          <Translation text="hearings.agenda.label" />
        </Typography.Title>
        <div>
          <Viewer value={record?.attributes?.agenda} />
        </div>
      </div>
    </Space>
  );
}

export default HearingDetails;
