import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConsent } from "../../redux/actions/signActions";
import {
  consentStatus,
  isFetchingConsent,
} from "../../redux/selectors/authSelector";
import Translation from "../Common/Translation";

export default function ({ children, redirectUrl }) {
  const dispatch = useDispatch();

  const hasConsent = useSelector(consentStatus);
  const isFetching = useSelector(isFetchingConsent);

  const handleConsent = () => void dispatch(getConsent({ redirectUrl }));

  if (!hasConsent) {
    return (
      <Button
        type="primary"
        onClick={handleConsent}
        loading={isFetching}
        disabled={isFetching}
      >
        <Translation text="general.connect_docusign.button" pad={true} />
      </Button>
    );
  }
  return <div>{children}</div>;
}
