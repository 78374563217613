import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { readEmail } from "../../helpers/authHelpers";
import { postActivate } from "../../redux/actions/authenticateActions";
import Spinner from "../Common/Spinner";

export default function () {
  const { token } = useParams();
  const email = readEmail();
  const dispatch = useDispatch();

  useEffect(() => void dispatch(postActivate({ email, token })), [
    token,
    email,
    dispatch,
  ]);

  return <Spinner />;
}
