import { PageHeader, Typography } from "antd";
import React from "react";
import { breadcrumbRender } from "../../helpers/routeHelpers";
import DisputeStatus from "./DisputeStatus";
import "./styles.less";

const { Paragraph } = Typography;

export default function DisputeHeader({
  disputeId,
  dispute,
  hubSlug,
  extraCrumbs = [],
  children,
  extra = [],
}) {
  const routes = [
    {
      path: `/`,
      breadcrumbName: "Dashboard",
    },
    {
      path: `/${hubSlug}/disputes/${disputeId}`,
      breadcrumbName: dispute?.name,
    },
  ].concat(extraCrumbs);

  const breadcrumb = {
    routes: routes,
    itemRender: breadcrumbRender,
  };

  return (
    <PageHeader
      title={
        <Paragraph
          className="jur-dispute-title"
          ellipsis={{ tooltip: caseTitle(dispute) }}
          level={2}
          strong={true}
        >
          {caseTitle(dispute)}
        </Paragraph>
      }
      breadcrumb={breadcrumb}
      tags={<DisputeStatus status={dispute?.status} />}
      extra={extra}
    >
      {children}
    </PageHeader>
  );
}

const caseTitle = (dispute) =>
  `${dispute?.name} ${
    dispute?.caseNumber ? `(Case #${dispute?.caseNumber})` : ""
  }`;
