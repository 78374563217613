import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import { getShow } from "../../redux/actions/disputeActions";
import { getIndex } from "../../redux/actions/disputeFileActions";
import { selectIndex, selectMeta, selectGroup } from "../../redux/selectors/disputeFilesSelector";
import { fromMeta } from "../../transformers/paginationTransformer";
import Spinner from "../Common/Spinner";
import Translation from "../Common/Translation";
import IndexRender from "./IndexRender";

export default function () {
  const history = useHistory();
  const [disputeId, dispute] = useDispute();
  const disputeFilesData = useSelector(selectIndex);
  const meta = useSelector(selectMeta);
  const userGroup = useSelector(selectGroup);
  const dispatch = useDispatch();
  const hubSlug = useHubSlug();

  const isArbitrator = !userGroup;

  useEffect(() => {
    dispatch(getIndex(disputeId));
    if (!dispute) dispatch(getShow(disputeId));
  }, [dispatch, disputeId, dispute]);

  const extraCrumbs = [
    {
      path: `/${hubSlug}/disputes/${disputeId}/files`,
      breadcrumbName: <Translation text="general.case_files.title" />,
    },
  ];

  const folders = [
    {
      label: "general.arbitral_award.title",
      onClick: () => history.push(`/${hubSlug}/disputes/${disputeId}/arbitral-awards`),
    },
    {
      label: "general.terms_of_reference.button",
      onClick: () => history.push(`/${hubSlug}/disputes/${disputeId}/tor`),
    },
    {
      label: "case_files.procedural_orders.text",
      onClick: () => history.push(`/${hubSlug}/disputes/${disputeId}/procedural-orders`),
    },
    {
      label: "case_files.hearing.text",
      onClick: () => history.push("arbitrator/hearing"),
    },
  ];

  return dispute ? (
    <IndexRender
      disputeParams={{ dispute, disputeId, hubSlug }}
      folders={folders}
      isArbitrator={isArbitrator}
      extraCrumbs={extraCrumbs}
      pagination={fromMeta(meta, (page) => {
        dispatch(getIndex(disputeId, page));
      })}
      disputeFiles={disputeFilesData}
    />
  ) : (
    <Spinner />
  );
}
