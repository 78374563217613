import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAccess } from "../../helpers/accessHelpers";
import { useDispute } from "../../helpers/disputeHelpers";
import { useHubSlug } from "../../helpers/hubHelpers";
import * as actions from "../../redux/actions/arbitralAwardActions";
import * as fileActions from "../../redux/actions/fileActions";
import {
  EDITS_REQUESTED,
  SENT_TO_PARTIES,
} from "../../redux/constants/ArbitralAwardStatuses";
import {
  selectArbitralAward,
  whole,
} from "../../redux/selectors/arbitralAwardSelector";
import { selectAccessor } from "../../redux/selectors/authSelector";
import { selectFile } from "../../redux/selectors/filesSelector";
import Spinner from "../Common/Spinner";
import ShowRender from "./ShowRender";

export default function () {
  const [disputeId, dispute] = useDispute();
  const hubSlug = useHubSlug();

  const { awardId } = useParams();
  const dispatch = useDispatch();
  const award = useSelector(selectArbitralAward);
  const {
    isReviewAwardVisible,
    isRequestEditsVisible,
    isSendToPartiesVisible,
    isSubmitting,
  } = useSelector(whole);
  const { group } = useSelector(selectAccessor);
  const isReviewable =
    !Boolean(award?.attributes?.hubAction?.status) &&
    award?.attributes?.status !== SENT_TO_PARTIES;
  const isSentToParties =
    award?.attributes?.status === SENT_TO_PARTIES ||
    award?.attributes?.status === EDITS_REQUESTED;
  const hasEditPending =
    group === "Respondent"
      ? !Boolean(award?.attributes?.respondentEdits)
      : group === "Claimant"
      ? !Boolean(award?.attributes?.claimantEdits)
      : false;
  const canReview = useAccess("review", "arbitral-award");
  const canCreate = useAccess("create", "arbitral-award");
  const canRequestEdits = useAccess("request-edits", "arbitral-award");
  const file = useSelector(selectFile);
  const fileId = award?.attributes?.fileId;

  const handleReviewAward = (body) => {
    dispatch(
      actions.putUpdate(disputeId, hubSlug, award?.id, null, body, true)
    );
  };

  const handleEditRequest = (body) => {
    dispatch(
      actions.putUpdate(disputeId, hubSlug, award?.id, null, body, true)
    );
  };

  const handleSendToParties = () => {
    dispatch(
      actions.putUpdate(
        disputeId,
        hubSlug,
        awardId,
        award?.attributes?.fileId,
        {
          status: "Sent To Parties",
        },
        false
      )
    );
  };

  useEffect(() => void dispatch(actions.getShow(disputeId, awardId)), [
    disputeId,
    awardId,
    dispatch,
  ]);

  useEffect(() => {
    if (fileId) dispatch(fileActions.getInlineShow(fileId));
  }, [fileId, dispatch]);

  const openReviewAward = () => {
    dispatch(actions.handleIsReviewOpen(true));
  };

  const closeReviewAward = () => {
    dispatch(actions.handleIsReviewOpen(false));
  };

  const openRequestEdits = () => {
    dispatch(actions.handleIsRequestEditsOpen(true));
  };

  const closeRequestEdits = () => {
    dispatch(actions.handleIsRequestEditsOpen(false));
  };

  const openSendToParties = () => {
    dispatch(actions.handleIsSendToPartiesOpen(true));
  };

  const closeSendToParties = () => {
    dispatch(actions.handleIsSendToPartiesOpen(false));
  };

  return award && file ? (
    <ShowRender
      award={award}
      file={file}
      dispute={dispute}
      disputeId={disputeId}
      extraCrumbs={extraCrumbs(hubSlug, disputeId, award)}
      hubSlug={hubSlug}
      canReview={canReview && isReviewable}
      openReviewAward={openReviewAward}
      closeReviewAward={closeReviewAward}
      isReviewAwardVisible={isReviewAwardVisible}
      handleReviewAward={handleReviewAward}
      canRequestEdits={canRequestEdits && isSentToParties && hasEditPending}
      isRequestEditsVisible={isRequestEditsVisible}
      openRequestEdits={openRequestEdits}
      closeRequestEdits={closeRequestEdits}
      handleEditRequest={handleEditRequest}
      isSubmitting={isSubmitting}
      canSendToParties={canCreate && award?.attributes?.eligibleForPartySharing}
      isSendToPartiesVisible={isSendToPartiesVisible}
      handleSendToParties={handleSendToParties}
      openSendToParties={openSendToParties}
      closeSendToParties={closeSendToParties}
    />
  ) : (
    <Spinner />
  );
}

const extraCrumbs = (hubSlug, disputeId, award) => [
  {
    path: `/${hubSlug}/disputes/${disputeId}/arbitral-awards`,
    breadcrumbName: "Arbitral Award",
  },
  {
    path: `/${hubSlug}/disputes/${disputeId}/arbitral-awards/${award?.id}`,
    breadcrumbName: `Version ${award?.attributes?.version}`,
  },
];
