import { Button, Modal, Radio, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React from "react";
import { errorAsExtra, useErrorHider } from "../../helpers/errorHelpers";
import Editor from "../Common/Editor/Editor";
import Spinner from "../Common/Spinner";
import Viewer from "../Common/Editor/Viewer";
import Translation from "../Common/Translation";
import ModalWrapper from "../Common/ModalWrapper";
import { handleChange } from "../DisputeFiling/helpers";
import { modalProps } from "../../helpers/modalHelpers";

export default function ReviewOrderForm({
  orders,
  visible,
  handleReviewOrderSubmit,
  handleReviewOrderClose,
  selectedOrder = null,
  errors,
  selectedPO,
}) {
  const { Title, Text } = Typography;
  const errorProps = useErrorHider();
  const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);

  const Highlights = () => (
    <div className="jur-dispute-show-greeting">
      <Title className="primary-color" level={5}>
        <Translation text="general.highlights.text" />
      </Title>
      <Text className="secondary-color">
        <Viewer value={selectedPO?.attributes?.highlights} />
      </Text>
    </div>
  );

  return (
    <Formik initialValues={{ isApproved: null, orderId: selectedPO?.id }}>
      {({ values, setFieldValue, resetForm }) => {
        const handler = handleChange(values, setFieldValue);
        return (
          <Modal
            width={768}
            visible={visible}
            {...modalProps()}
            maskClosable={false}
            destroyOnClose={true}
            title={
              <Typography.Title level={5}>
                <Translation text="procedural_order.review_order.title" />
              </Typography.Title>
            }
            onCancel={() => {
              handleReviewOrderClose();
              resetForm();
            }}
            footer={[
              <Button
                disabled={!values?.isApproved && !values?.isApproved && !values?.reasonForRejection?.length}
                type="primary"
                onClick={() => handleReviewOrderSubmit({ ...values, orderId: selectedPO?.id }, resetForm)}
              >
                <Translation text="general.submit.button" />
              </Button>,
            ]}
          >
            <ModalWrapper>
              <Highlights />
              {selectedOrder || orders ? (
                <Form layout="vertical">
                  <FormItem name="isApproved" {...apiErrorOf("isApproved", true)}>
                    <Radio.Group onChange={handler("isApproved")}>
                      <Radio value={true}>
                        <Translation text="procedural_order.accept_draft.text" />
                      </Radio>
                      <Radio value={false}>
                        <Translation text="procedural_order.request_revision.text" />
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                  {values.isApproved === false && (
                    <FormItem
                      required
                      name="reasonForRejection"
                      {...apiErrorOf("reasonForRejection")}
                      label={<Translation text="procedural_order.add_motivation.description" />}
                    >
                      <Editor onChange={handler("reasonForRejection")} className="jur-quill-editor-small" />
                    </FormItem>
                  )}
                </Form>
              ) : (
                <Spinner />
              )}
            </ModalWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
}
