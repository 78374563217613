import React, { useEffect } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getIndex } from "../../../redux/actions/disputeFileActions";
import { selectIndex } from "../../../redux/selectors/disputeFilesSelector";
import Spinner from "../../Common/Spinner";
import "../styles.less";
import * as fileActions from "../../../redux/actions/fileActions";

export default function RecentlyUploadedFileList({ dispute, disputeId, hubSlug }) {
  const dispatch = useDispatch();
  const disputeFilesData = useSelector(selectIndex);
  const previewFile = (fileId) => dispatch(fileActions.getShow(fileId));

  useEffect(() => {
    dispatch(getIndex(disputeId));
  }, [dispatch, disputeId, dispute]);

  if (!disputeFilesData) return <Spinner card={true} />;

  return (
    <>
      {disputeFilesData.map((file) => (
        <div className="jur-dispute-show-file-link">
          <Button type="link" onClick={() => previewFile(file?.id)}>
            {file?.attributes?.fileInfo?.name}
          </Button>
        </div>
      ))}
    </>
  );
}
