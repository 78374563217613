import { Button, Col, Row, Typography } from "antd";
import { Formik } from "formik";
import { Checkbox, Form, Input } from "formik-antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { passwordIconRender, getUserOnboardingStatus } from "../../helpers/authHelpers";
import { errorAsExtra } from "../../helpers/errorHelpers";
import { authenticateUser } from "../../redux/actions/authenticateActions";
import { authLoading, isAuthenticated, selectErrors } from "../../redux/selectors/authSelector";
import Translation from "../Common/Translation";
import AuthLayout from "../Layouts/AuthLayout";
import LeftSidebarLayout from "../Layouts/LeftSidebarLayout";
import "./authenticate.less";
import AuthFormItem from "./AuthFormItem";
import AuthenticateSchema from "./validate";

const SignUpHeader = () => (
  <div className="jur-sign-up-text">
    <Translation text="authentication.new_to_jur.text" />
    {"     "}
    <Link className="jur-link" to="/register">
      <Translation text="authentication.sign_up.text" />
    </Link>
  </div>
);

function Authenticate() {
  const history = useHistory();
  const dispatch = useDispatch();
  const _isLoading = useSelector(authLoading);
  const _isAuthenticated = useSelector(isAuthenticated);
  const errors = useSelector(selectErrors);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (_isAuthenticated) {
      if (!getUserOnboardingStatus()) {
        history.push("/onboarding");
      } else {
        history.push("/");
      }
    }
    setIsHidden(true);
  }, [_isAuthenticated, history]);

  const handleSubmit = (values) => {
    dispatch(authenticateUser(values));
  };

  const extraProps = errorAsExtra(errors, isHidden);

  return (
    <LeftSidebarLayout
      mainContent={
        <AuthLayout
          header={<SignUpHeader />}
          content={
            <>
              <div className="jur-auth-title">
                <Translation text="general.sign_in.title" />
              </div>
              <div className="jur-auth-description">
                <Translation text="general.lets_get_started.title" />
              </div>
              <Formik
                initialValues={{ email: "", password: "", remember: false }}
                validationSchema={AuthenticateSchema}
                onSubmit={(values) => {
                  setIsHidden(false);
                  handleSubmit(values);
                }}
              >
                {({ handleChange, setFieldValue, values }) => {
                  const hideOnChange = (e) => {
                    setIsHidden(true);
                    handleChange(e);
                  };
                  const fields = [
                    {
                      label: (
                        <span>
                          <Translation text="authentication.email_address.text" />
                        </span>
                      ),
                      name: "email",
                      ...extraProps("email"),
                      rules: [
                        {
                          required: true,
                          message: <Translation text="messages.email.text" />,
                        },
                      ],
                      component: <Input tabIndex={1} name="email" type="email" onChange={hideOnChange} />,
                    },
                    {
                      customLabel: (
                        <Row justify="space-between" className="jur-auth-password-label">
                          <Col>
                            <Typography.Title level={5}>
                              <Translation text="authentication.password.text" />
                              {<span className="jur-auth-required-asterisk">*</span>}
                            </Typography.Title>
                          </Col>
                          <Col>
                            <Typography.Title level={5}>
                              <Link tabIndex={3} className="jur-link" to="/forgot-password">
                                <Translation text="authentication.forgot_password.text" />
                              </Link>
                            </Typography.Title>
                          </Col>
                        </Row>
                      ),
                      component: (
                        <Input.Password
                          tabIndex={2}
                          onChange={hideOnChange}
                          name="password"
                          iconRender={passwordIconRender(values, "password")}
                        />
                      ),
                      name: "password",
                      ...extraProps("password"),
                      rules: [
                        {
                          required: true,
                          message: <Translation text="messages.password.text" />,
                        },
                      ],
                    },
                    {
                      component: (
                        <Checkbox name="remember" onChange={(e) => setFieldValue("remember", e.target.checked)}>
                          <Typography.Title level={5}>Remember me</Typography.Title>
                        </Checkbox>
                      ),
                      name: "remember",
                    },
                  ];

                  return (
                    <Form layout="vertical">
                      {fields.map((formItem) => (
                        <AuthFormItem key={formItem.name} {...formItem} />
                      ))}
                      <Button
                        disabled={values.password === "" || values.email === ""}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        loading={_isLoading}
                      >
                        <Translation text="authentication.sign_in.text" pad={true} />
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </>
          }
        />
      }
    />
  );
}

export default Authenticate;
