import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

export function index(disputeId, feature, page) {
  return Api.get(`/disputes/${disputeId}/${feature}?page=${page}`).catch(
    logAndThrow(feature)("index")
  );
}

export function show(disputeId, feature, recordId, params = "") {
  return Api.get(
    `/disputes/${disputeId}/${feature}/${recordId}${params}`
  ).catch(logAndThrow(feature)("show"));
}
