import {
  CheckCircleTwoTone,
  TeamOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { Card, Col, Tag, Typography } from "antd";
import React from "react";
import Viewer from "../Common/Editor/Viewer";
import Translation from "../Common/Translation";
import { CardLayout } from "./AchievementTab";
import { InputLabel, ProfessionalExperience, zeroPad } from "./ProfessionalTab";
import { Websites } from "./StaticPanel";
import "./styles.less";

const { Text, Title } = Typography;

export default function SliderPreview({ arbitrator }) {
  return (
    <div className="jur-arbitrator-slider-wrapper">
      <Card className="jur-bottom-border">
        <center>
          <img
            className="jur-arbitrator-slider-avatar"
            src={arbitrator?.avatarUrl}
            alt="arbitrator profile avatar"
          />
          <br />
          <Title level={4} strong={true}>
            {arbitrator?.firstName} {arbitrator?.lastName}
          </Title>
          <StatusTag arbitrator={arbitrator} />
        </center>
      </Card>
      <Card className="jur-bottom-border">
        <InputLabel label="roster.headline.text" />
        <Viewer hideToggle={true} value={arbitrator?.headline} />
      </Card>
      <Card className="jur-bottom-border">
        {arbitrator?.experiences.map((item, i) => {
          return <ProfessionalExperience experience={item} key={i} />;
        })}
      </Card>
      <Card className="jur-bottom-border">
        <ArbitrationExperience arbitrator={arbitrator} />
        <br />
        <InputLabel label="roster.areas_of_practice.text" />
        <div className="jur-profile-list-item-tags-wrapper">
          {arbitrator?.practiceAreas?.map((area) => (
            <span className="jur-profile-list-item-tag">
              <Tag>
                <Title level={5}>{area}</Title>
              </Tag>
            </span>
          ))}
        </div>
        <br />
        <InputLabel label="roster.about_area_of_expertise.text" />
        <div className="jur-profile-list-item-tags-wrapper">
          {arbitrator?.specializations?.map((area) => (
            <div className="jur-profile-list-item-tag">
              <Tag>
                <Title level={5}>{area}</Title>
              </Tag>
            </div>
          ))}
        </div>
      </Card>
      <Card className="jur-bottom-border">
        <Text className="secondary-color">
          <div className="jur-title">
            {<Translation text="roster.special_honours.text" />}
          </div>
        </Text>
        <CardLayout
          list={arbitrator?.appointments}
          itemRender={(item) => (
            <>
              <TeamOutlined />
              <Title level={5} strong className="primary-color">
                {item.title}
              </Title>
              <div>
                <Text className="secondary-color">{item.organization}</Text>
              </div>
              <div className="jur-profile-section-list-item-description">
                <Text strong> {item.year}</Text>
              </div>
            </>
          )}
        />
      </Card>
      <Card className="jur-bottom-border">
        <Text className="secondary-color">
          <div className="jur-title">
            {<Translation text="roster.awards_and_honours.text" />}
          </div>
        </Text>
        <CardLayout
          list={arbitrator?.awards}
          itemRender={(item) => (
            <>
              <TrophyOutlined />
              <Title level={5} strong className="primary-color">
                {item.title}
              </Title>
              <div>
                <Text className="secondary-color">{item.organization}</Text>
              </div>
              <div className="jur-profile-section-list-item-description">
                <Text strong> {item.year}</Text>
              </div>
            </>
          )}
        />
      </Card>
      <>
        <Websites profile={arbitrator} />
      </>
    </div>
  );
}

const StatusTag = ({ arbitrator }) => (
  <div className="jur-arbitrator-status">
    <CheckCircleTwoTone style={{ fontSize: 20 }} twoToneColor={"#69d195"} />
    <Tag color={"#69d195"}>
      <Title level={5} strong style={{ color: "white" }}>
        {arbitrator?.status}
      </Title>
    </Tag>
  </div>
);

const ArbitrationExperience = ({ arbitrator }) => {
  const arbitrationExperience = [
    {
      label: "roster.appointments_as_arbitrator.text",
      points: arbitrator?.experienceAsArbitrator,
      color: "blue",
    },
    {
      label: "roster.appointments_as_counsel.text",
      points: arbitrator?.experienceAsCounsel,
      color: "green",
    },
    {
      label: "roster.appointments_as_secretary.text",
      points: arbitrator?.experienceAsSecretary,
      color: "violet",
    },
    {
      label: "roster.appointments_as_mediator.text",
      points: arbitrator?.experienceAsMediator,
      color: "purple",
    },
  ];
  return arbitrationExperience.map((item) => (
    <div className="jur-horizontal-arbitration-experience">
      <div>
        <div className={`jur-arbitration-experience-${item.color}-wrapper`}>
          <span
            className={`jur-title jur-arbitration-experience-${item.color}`}
          >
            {zeroPad(item?.points ?? 0)}
          </span>
        </div>
      </div>
      <Col flex={"auto"}>
        <InputLabel label={item?.label} />
        <br />
      </Col>
    </div>
  ));
};
