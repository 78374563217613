import { FileTextOutlined, FileTwoTone } from "@ant-design/icons";
import { Badge, Button, Card, Col, Row, Table, Tag, Typography } from "antd";
import React from "react";
import { HUB_APPROVED } from "../../redux/constants/TORStatuses";
import { fromMeta } from "../../transformers/paginationTransformer";
import DateTime from "../Common/DateTime";
import NoData from "../Common/NoData";
import PageHeader from "../Common/PageHeader";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import Spinner from "../Common/Spinner";
import { torStatusColor } from "../Common/TorStatus";
import Translation from "../Common/Translation";
import ReviewTermsForm from "./ReviewTermsForm";
import SendToHubForm from "./SendToHubForm";
import SendToPartiesForm from "./SendToPartiesForm";
import ShowTorReview from "./ShowTorReview";
import CaseFilesBreadcrumb from "../Common/CaseFilesBreadcrumb";

export default function ({
  dispute,
  disputeId,
  disputeParams,
  hubSlug,
  extraCrumbs,
  disputeTors,
  selectedTOR,
  rejectedTor,
  downloadTemplate,
  downloadFile,
  isSendToHubVisible,
  openSendToHub,
  closeSendToHub,
  handleSendToHub,
  isReviewTermsVisible,
  openReviewTerms,
  closeReviewTerms,
  handleReviewTerms,
  handleTorAction,
  handleActionText,
  isSendToPartiesVisible,
  openSendToParties,
  closeSendToParties,
  handleSendToParties,
  canSendTOR,
  canReviewTOR,
  participants,
  errors,
  isLoading,
  pendingTors = [],
  onPageChange,
  deleteTOR,
}) {
  const acceptedTors =
    disputeTors?.data?.data && disputeTors?.data?.data?.filter((tor) => tor.attributes?.status === HUB_APPROVED);

  const TorFiles = () => (
    <Card>
      {disputeTors.data?.data?.length ? (
        <Table
          rowKey="id"
          className="jur-table-white-header"
          dataSource={disputeTors.data?.data}
          columns={columns(downloadFile, handleTorAction, handleActionText)}
          pagination={fromMeta(disputeTors.data?.meta, (page) => {
            onPageChange(page);
          })}
        />
      ) : (
        <NoData
          description={
            <Typography.Title level={5} strong>
              <Translation text="case_files.no_files.text" />
            </Typography.Title>
          }
        />
      )}
    </Card>
  );

  return (
    <SliderMenuWrapper {...disputeParams}>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={24}>
          <CaseFilesBreadcrumb />
        </Col>
        <Col className="gutter-row" span={24}>
          <PageHeader
            title="terms_of_reference.terms_of_reference.title"
            cta={
              canSendTOR && (
                <Button type="primary" onClick={openSendToHub}>
                  <Translation text="case_files.new_upload.text" />
                </Button>
              )
            }
          />
        </Col>
        <Col className="gutter-row" span={24}>
          {disputeTors?.data ? (
            <TorFiles />
          ) : (
            <div className="jur-tor-spinner">
              <Spinner />
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={24}>
          {rejectedTor?.id === selectedTOR?.id && canSendTOR && (
            <ShowTorReview rejectedTor={rejectedTor} selectedTOR={selectedTOR} />
          )}
        </Col>
      </Row>

      <SendToHubForm
        visible={isSendToHubVisible}
        handleSendToHub={handleSendToHub}
        closeSendToHub={closeSendToHub}
        isLoading={isLoading}
        deleteTOR={deleteTOR}
      />
      {disputeTors?.data?.data?.length && selectedTOR?.id ? (
        <>
          <ReviewTermsForm
            errors={errors}
            selectedTOR={selectedTOR}
            closeReviewTerms={closeReviewTerms}
            visible={isReviewTermsVisible}
            handleReviewSubmit={handleReviewTerms}
            isLoading={isLoading}
            pendingTors={pendingTors}
          />

          <SendToPartiesForm
            participants={participants}
            isSendToPartiesVisible={isSendToPartiesVisible}
            handleSendToParties={handleSendToParties}
            closeSendToParties={closeSendToParties}
            tors={acceptedTors}
            selectedTOR={selectedTOR}
            isLoading={isLoading}
          />
        </>
      ) : null}
    </SliderMenuWrapper>
  );
}

const columns = (downloadFile, handleTorAction, handleActionText) => [
  {
    title: <FileTextOutlined className="jur-cf-table-body-file-icon" />,
    render: () => <FileTwoTone className="jur-table-body-file-render-icon" twoToneColor=" #2777EF" />,
  },
  {
    title: <Translation text="files.files.title" />,
    dataIndex: ["attributes", "file", "name"],
    render: (text, record) => (
      <a href="#/" onClick={() => downloadFile(record?.attributes?.file?.id)}>
        {text}
      </a>
    ),
  },
  {
    title: <Translation text="terms_of_reference.status.column" />,
    dataIndex: ["attributes", "status"],
    render: (s) => <Badge color={torStatusColor(s)} text={<span className="jur-case-status">{s}</span>} />,
  },
  {
    title: <Translation text="terms_of_reference.issued_on.column" />,
    dataIndex: ["attributes", "file", "uploadedAt"],
    render: (text) => <DateTime epochSeconds={text} />,
  },
  {
    title: <Translation text="terms_of_reference.signatories.column" />,
    dataIndex: ["attributes", "requiredSignatories"],
    render: renderSignatories,
  },
  {
    title: <Translation text="general.action.column" />,
    dataIndex: ["attributes", "status"],
    render: (text, record) => (
      <a href="#/" onClick={() => handleTorAction(record)}>
        {handleActionText(text, record?.id)}
      </a>
    ),
  },
];

const renderSignatories = (text) => {
  const colors = ["blue", "green", "cyan", "volcano", "lime", "gold", "orange", "geekblue", "magenta", "purple"];
  return text?.length ? text.map((tag, index) => <Tag color={colors[index]}>{tag}</Tag>) : "-";
};
