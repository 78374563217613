import React from "react";
import * as yup from "yup";
import Translation from "../Common/Translation";

export default yup.object().shape({
  firstName: yup
    .string()
    .required(
      <Translation text="validations.this_field_is_required.message" />
    ),
  lastName: yup
    .string()
    .required(
      <Translation text="validations.this_field_is_required.message" />
    ),
  email: yup
    .string()
    .email()
    .required(
      <Translation text="validations.this_field_is_required.message" />
    ),
  password: yup
    .string()
    .required(<Translation text="validations.this_field_is_required.message" />)
    .min(
      6,
      <Translation text="validations.pass_must_be_at_least_six_characters.message" />
    ),
  passwordConfirmation: yup
    .string()
    .required(<Translation text="validations.this_field_is_required.message" />)
    .oneOf(
      [yup.ref("password"), null],
      <Translation text="validations.passwords_must_match.message" />
    ),
  terms_and_conditions: yup
    .boolean()
    .required(<Translation text="validations.this_field_is_required.message" />)
    .oneOf(
      [true],
      <Translation text="validations.must_accept_terms_and_conditions.message" />
    ),
});
