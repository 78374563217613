import { logAndThrow } from "../../helpers/apiHelpers";
import Api from "./Api";

const tag = "arbitrator-panel";
const catcher = logAndThrow(tag);

export function index(disputeId) {
  return Api.get(`/disputes/${disputeId}/panels/latest`)
    .then((res) => res.data)
    .catch(catcher("index"));
}

export function create(disputeId, list) {
  return Api.post(`/disputes/${disputeId}/panels`, {
    arbitratorIds: list,
  })
    .then((res) => res.data)
    .catch(catcher("create"));
}

export function updateStrike(disputeId, list = []) {
  return Api.put(`/disputes/${disputeId}/panels/strike`, {
    panelArbitratorIds: list,
  })
    .then((res) => res.data)
    .catch(catcher("updateStrike"));
}
