import { Button, Col, Row, Typography } from "antd";
import React from "react";
import Translation from "../../components/Common/Translation";
import FolderOverview from "../Common/FolderOverview/index";
import PageHeader from "../Common/PageHeader";
import SliderMenuWrapper from "../Common/SliderMenuWrapper";
import CaseFilesBreadcrumb from "../Common/CaseFilesBreadcrumb";
import FileUploadForm from "./FileUploadForm";
import "./styles.less";
import TableOverview from "./TableOverview";
import Template from "./Template";

const { Title } = Typography;

export default function ({
  errors,
  folders,
  templates,
  isArbitrator,
  isWitness,
  disputeParams,
  disputeFiles,
  isLoading,
  isUploading,
  isUploadFormVisible,
  pagination,
  handleSendDocuments,
  openSendDocuments,
  closeSendDocuments,
}) {
  const renderFoldersList = () => {
    return (
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Title level={5} className="jur-case-files-folders-title">
            <Translation text="folders.folders.title" />
          </Title>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            {folders.map((folder, index) => {
              return (
                folder.condition && (
                  <Col className="gutter-row" span={8} key={index}>
                    <FolderOverview
                      folderLabel={folder.label}
                      onClick={folder.onClick}
                      toolTip={"folders.folders-tooltip-for-case-files.message"}
                    />
                  </Col>
                )
              );
            })}
          </Row>
        </Col>
      </Row>
    );
  };

  const renderTemplates = (templates) => {
    return (
      <Row gutter={[24, 24]}>
        {templates.map((template) => {
          return (
            isArbitrator &&
            template && (
              <Col span={8}>
                <Template template={template} />
              </Col>
            )
          );
        })}
      </Row>
    );
  };

  return (
    <SliderMenuWrapper {...disputeParams}>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={24}>
          <CaseFilesBreadcrumb />
          <PageHeader
            title={"case_files.case_files.title"}
            cta={
              !isArbitrator && (
                <Button type="primary" onClick={openSendDocuments}>
                  <Translation text="case_files.upload_new_doc.button" />
                </Button>
              )
            }
          />
        </Col>
        <Col className="gutter-row" span={24}>
          {templates.length && renderTemplates(templates)}
        </Col>

        <Col className="gutter-row" span={24}>
          {renderFoldersList()}
        </Col>
        <Col className="gutter-row" span={24}>
          <Title level={5} className="jur-case-files-folders-title">
            <Translation text="case_files.files.text" />
          </Title>
          <TableOverview data={disputeFiles} whiteHeader={true} isLoading={isLoading} pagination={pagination} />
        </Col>
      </Row>
      {isUploadFormVisible && (
        <FileUploadForm
          visible={isUploadFormVisible}
          isWitness={isWitness}
          isLoading={isUploading}
          closeSendDocuments={closeSendDocuments}
          handleSendDocuments={handleSendDocuments}
        />
      )}
    </SliderMenuWrapper>
  );
}
