import { push } from "connected-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  errorMessage,
  successMessage,
} from "../../components/Common/Feedback/Message";
import { logAndPass } from "../../helpers/sagaHelpers";
import * as actions from "../actions/disputeAssignmentActions";
import { handleUpdateSlug } from "../actions/hubActions";
import * as apis from "../apis/disputeAssignmentsApi";
import * as types from "../constants/constants";
import { selectCurrentSlug } from "../selectors/hubsSelector";

const tag = "dispute-assignments";

export function* getIndex(action) {
  const { disputeId } = action.payload;
  try {
    const res = yield call(apis.index, disputeId);
    yield put(actions.handleIndex(res));
  } catch (e) {
    console.error(tag, "saga getIndex error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShow(action) {
  const { disputeId, disputeAssignmentId } = action.payload;
  try {
    const res = yield call(apis.show, disputeId, disputeAssignmentId);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getShow error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getShowDirect(action) {
  const { disputeAssignmentId } = action.payload;
  try {
    const res = yield call(apis.showDirect, disputeAssignmentId);
    yield put(actions.handleShow(res));
  } catch (e) {
    console.error(tag, "saga getShowDirect error:", e);
    yield put(actions.handleError(e));
  }
}

export function* postCreate(action) {
  const { disputeId, body } = action.payload;
  try {
    const res = yield call(apis.create, disputeId, body);
    successMessage("dispute_assignment.request_sent.text");
    yield put(actions.handleCreate(res));
    const hubSlug = yield select(selectCurrentSlug);
    yield put(push(`/${hubSlug}/disputes/${disputeId}`));
  } catch (e) {
    console.error(tag, "saga postCreate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdate(action) {
  const { disputeId, disputeAssignmentId, body } = action.payload;
  try {
    const res = yield call(apis.update, disputeId, disputeAssignmentId, body);
    yield put(actions.handleUpdate(res));
    if (!body.updateFile) {
      const hubSlug = yield select(selectCurrentSlug);
      yield put(
        push(
          disputeAssignmentId === "reject"
            ? "/"
            : `/${hubSlug}/disputes/${disputeId}`
        )
      );
    }
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateFile(action) {
  const { disputeId, disputeAssignmentId, body } = action.payload;
  try {
    const res = yield call(
      apis.updateFile,
      disputeId,
      disputeAssignmentId,
      body
    );
    yield put(actions.handleUpdate(res));
    yield put(actions.getShow(disputeId, "latest"));
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* putUpdateDirect(action) {
  const { disputeAssignmentId, body } = action.payload;
  try {
    const res = yield call(apis.updateDirect, disputeAssignmentId, body);
    yield put(actions.handleUpdate(res));
  } catch (e) {
    console.error(tag, "saga putUpdate error:", e);
    yield put(actions.handleError(e));
  }
}

export function* getIndexResult({ type }) {
  try {
    const res = yield call(
      type === types.DISPUTEASSIGNMENT_GET_INDEX_EXPERTS
        ? apis.indexExperts
        : apis.indexArbitrators
    );
    yield put(actions.handleResult(res));
  } catch (e) {
    console.error("DISPUTEASSIGNMENT_GET_INDEX_EXPERTS", e);
    yield put(logAndPass(e, tag, actions));
  }
}

export function* handleSignCompleted({ payload }) {
  const { disputeId, hubSlug } = payload?.meta;
  yield put(handleUpdateSlug(hubSlug));
  if (payload?.data?.attributes?.flow === "Appointment Document") {
    yield put(actions.putUpdate(disputeId, "accept", { accepted: true }));
  }
  yield put(
    push(
      `/${hubSlug}/disputes/${disputeId}/dispute-assignments/respond?state=SOASigned`
    )
  );
}

export function* handleSignatureError({ payload }) {
  const { disputeId, hubSlug } = payload?.params;
  errorMessage("general.signature_error.text");
  if (hubSlug && disputeId) {
    yield put(handleUpdateSlug(hubSlug));
    yield put(
      push(`/${hubSlug}/disputes/${disputeId}/dispute-assignments/accept`)
    );
  } else yield put(push(`/`));
}

export default function* () {
  yield takeLatest(types.DISPUTEASSIGNMENT_GET_INDEX, getIndex);
  yield takeLatest(
    types.DISPUTEASSIGNMENT_GET_INDEX_ARBITRATORS,
    getIndexResult
  );
  yield takeLatest(types.DISPUTEASSIGNMENT_GET_INDEX_EXPERTS, getIndexResult);
  yield takeLatest(types.DISPUTEASSIGNMENT_GET_SHOW, getShow);
  yield takeLatest(types.DISPUTEASSIGNMENT_GET_SHOW_DIRECT, getShowDirect);
  yield takeEvery(types.DISPUTEASSIGNMENT_POST_CREATE, postCreate);
  yield takeLatest(types.DISPUTEASSIGNMENT_PUT_UPDATE, putUpdate);
  yield takeLatest(types.DISPUTEASSIGNMENT_PUT_UPDATE_FILE, putUpdateFile);
  yield takeLatest(types.DISPUTEASSIGNMENT_PUT_UPDATE_DIRECT, putUpdateDirect);
  yield takeLatest(
    types.DISPUTEASSIGNMENT_HANDLE_SIGN_COMPLETED,
    handleSignCompleted
  );
  yield takeLatest(
    types.DISPUTEASSIGNMENT_HANDLE__SIGNATURE_ERROR,
    handleSignatureError
  );
}
