import { Button, Card, Col, Row, Tag, Typography } from "antd";
import React from "react";
import DateTime from "../Common/DateTime";
import DisputeHeader from "../Common/DisputeHeader";
import Viewer from "../Common/Editor/Viewer";
import PDFRender from "../Common/PDFRender";
import TimelineWrapper from "../Common/TimelineWrapper";
import Translation from "../Common/Translation";
import RequestEditsForm from "./RequestEditsForm";
import ReviewAwardForm from "./ReviewAwardForm";
import SendToPartiesForm from "./SendToPartiesForm";

export default function ({
  file,
  dispute,
  disputeId,
  award,
  extraCrumbs,
  hubSlug,
  canReview,
  openReviewAward,
  closeReviewAward,
  isReviewAwardVisible,
  errors,
  handleReviewAward,
  canRequestEdits,
  openRequestEdits,
  closeRequestEdits,
  isRequestEditsVisible,
  handleEditRequest,
  isSubmitting,
  isSendToPartiesVisible,
  handleSendToParties,
  closeSendToParties,
  openSendToParties,
  canSendToParties,
}) {
  const awardEdits = [
    {
      label: "arbitral_award.hubs_edits.text",
      value: award?.attributes?.hubAction?.description,
      time: award?.attributes?.hubAction?.timestamp,
    },
    {
      label: "arbitral_award.claimants_edits.text",
      value: award?.attributes?.claimantAction?.description,
      time: award?.attributes?.claimantAction?.timestamp,
    },
    {
      label: "arbitral_award.respondents_edits.text",
      value: award?.attributes?.respondentAction?.description,
      time: award?.attributes?.respondentAction?.timestamp,
    },
  ];

  return (
    <TimelineWrapper activityId={award?.id} activityType="Arbitral Award Show">
      <Card>
        <DisputeHeader
          dispute={dispute}
          disputeId={disputeId}
          hubSlug={hubSlug}
          extraCrumbs={extraCrumbs}
        >
          <Row justify="space-between">
            <Col>
              <Typography.Title level={3}>
                <Translation
                  text={
                    award?.attributes?.isAdditionalAward
                      ? "arbitral_award.additional_arbitral_award.title"
                      : "arbitral_award.arbitral_award.title"
                  }
                />{" "}
                {`(Version ${award?.attributes?.version})`}
              </Typography.Title>
            </Col>
            <Col>
              <Tag>{award?.attributes?.status}</Tag>
            </Col>
          </Row>
          {canReview && (
            <Button type="primary" onClick={openReviewAward}>
              <Translation text="arbitral_award.review_draft_award.button" />
            </Button>
          )}
          {canRequestEdits && (
            <Button type="primary" onClick={openRequestEdits}>
              <Translation text="arbitral_award.request_edits.button" />
            </Button>
          )}
          {canSendToParties && (
            <Button type="primary" onClick={openSendToParties}>
              <Translation text="arbitral_award.share_award_with_parties.button" />
            </Button>
          )}
          <br />
          <br />
          <Card
            type="inner"
            title={<Typography.Title level={5}>{file?.name}</Typography.Title>}
          >
            <Typography.Text strong={true} level={5}>
              <Translation text="arbitral_award.highlights.text" />
            </Typography.Text>
            <Viewer value={award?.attributes?.highlights} />
            <br />
            {awardEdits.map((e) => {
              if (!Boolean(e?.value)) return null;
              return (
                <>
                  <Row justify="space-between">
                    <Col>
                      <Typography.Text strong={true} level={5}>
                        <Translation text={e?.label} />
                      </Typography.Text>
                    </Col>
                    <Col>
                      <DateTime epochSeconds={e?.time} />{" "}
                    </Col>
                  </Row>
                  <Viewer value={e?.value} />
                  <br />
                </>
              );
            })}
            <PDFRender url={file.downloadUrl} />
          </Card>
          <ReviewAwardForm
            errors={errors}
            selectedAward={award}
            closeReviewTerms={closeReviewAward}
            visible={isReviewAwardVisible}
            onSubmit={handleReviewAward}
          />
          <RequestEditsForm
            isLoading={isSubmitting}
            visible={isRequestEditsVisible}
            onSubmit={handleEditRequest}
            selectedAward={award}
            closeRequestEdits={closeRequestEdits}
          />
          <SendToPartiesForm
            selectedAward={award}
            isSendToPartiesVisible={isSendToPartiesVisible}
            handleSendToParties={handleSendToParties}
            closeSendToParties={closeSendToParties}
            isLoading={isSubmitting}
          />
        </DisputeHeader>
      </Card>
    </TimelineWrapper>
  );
}
