import { Modal, Button, Card, Divider, Typography } from "antd";
import React from "react";
import DisputeHeader from "../Common/DisputeHeader";
import PDFRender from "../Common/PDFRender";
import TimelineWrapper from "../Common/TimelineWrapper";
import Translation from "../Common/Translation";
import Uploader, { UploadButtonRender } from "../Common/Uploader";
import SignatureButton from "../Signs/SignatureButton";

export default function ({
  dispute,
  disputeId,
  hubSlug,
  extraCrumbs,
  fileId,
  onUpload,
  canUpload,
  canSign,
  onSignStart,
  hearingId,
  fileName,
  uiRedirectUrl,
  canAddFields,
  minutes,
  isUploadOpen,
  setIsUploadOpen,
  fileID,
  setFileID,
  handleDelete,
}) {
  return (
    <TimelineWrapper activityId={hearingId} activityType="Hearing Minute Show">
      <Card>
        <DisputeHeader
          dispute={dispute}
          disputeId={disputeId}
          hubSlug={hubSlug}
          extraCrumbs={extraCrumbs}
        >
          <Typography.Title level={3}>
            <Translation text="hearings.minutes.title" />
          </Typography.Title>
          {canUpload ? (
            <Button type="primary" onClick={() => setIsUploadOpen(true)}>
              <Translation text="hearings.upload_minutes.button" />
            </Button>
          ) : (
            <>
              {canAddFields ? (
                <Button
                  onClick={() => {
                    window.location = minutes?.redirectURL;
                  }}
                  type="primary"
                >
                  <Translation text="general.add_fields.button" />
                </Button>
              ) : null}
              {canSign ? (
                <>
                  <SignatureButton redirectUrl={uiRedirectUrl}>
                    <Button onClick={onSignStart} type="primary">
                      <Translation text="general.sign_now.button" />
                    </Button>
                  </SignatureButton>
                  <Divider />
                </>
              ) : null}
              <br />
              <br />
              {fileId ? (
                <Card
                  type="inner"
                  title={
                    <Typography.Title level={5} strong>
                      {fileName}
                    </Typography.Title>
                  }
                >
                  <PDFRender fileId={fileId} />
                </Card>
              ) : (
                <center>
                  <Typography.Paragraph type="secondary">
                    <Translation text="hearings.no_minutes.text" />
                  </Typography.Paragraph>
                </center>
              )}
            </>
          )}
        </DisputeHeader>
      </Card>
      <Modal
        destroyOnClose={true}
        visible={isUploadOpen}
        title={<Translation text="hearings.upload_minutes.button" />}
        onCancel={() => {
          setIsUploadOpen(false);
          setFileID(null);
        }}
        footer={[
          <Button type="primary" disabled={!fileID} onClick={onUpload}>
            <Translation text="general.sign.button" />
          </Button>,
        ]}
      >
        <Uploader
          allowDocumentOnly={true}
          multiple={false}
          fileCategory="Hearing Minutes"
          onDone={(file) => setFileID(file?.id)}
          maxCount={1}
          name="hearingMinutes"
          onRemove={handleDelete}
        >
          <UploadButtonRender label="general.choose_file.button" />
        </Uploader>
      </Modal>
    </TimelineWrapper>
  );
}
