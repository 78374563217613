import { Button, Modal, Radio, Space, Typography } from "antd";
import { Formik } from "formik";
import { Form, FormItem } from "formik-antd";
import React from "react";
import { errorAsExtra, useErrorHider } from "../../helpers/errorHelpers";
import {
  HUB_APPROVED_WITHOUT_EDITS,
  HUB_REQUESTED_EDITS,
} from "../../redux/constants/ArbitralAwardStatuses";
import Editor from "../Common/Editor/Editor";
import Viewer from "../Common/Editor/Viewer";
import Translation from "../Common/Translation";
import { handleChange } from "../DisputeFiling/helpers";
import "./styles.less";
import ModalWrapper from "../Common/ModalWrapper";

export default function ReviewAwardForm({
  closeReviewTerms,
  visible,
  awards = [],
  onSubmit,
  errors,
  isLoading,
  selectedAward = null,
}) {
  const { Title, Text } = Typography;
  const errorProps = useErrorHider();
  const apiErrorOf = errorAsExtra(errors, errorProps.isHidden);

  const Highlights = () => (
    <div className="jur-dispute-show-greeting">
      <Title className="primary-color" level={5}>
        <Translation text="general.highlights.text" />
      </Title>
      <Text className="secondary-color">
        <Viewer value={selectedAward?.attributes?.highlights} />
      </Text>
    </div>
  );

  return (
    <Formik initialValues={initReviewObject(selectedAward)}>
      {({ values, setFieldValue, resetForm }) => {
        const handler = handleChange(values, setFieldValue);
        const disableSubmit =  (!values.requestStatus && !values.requestedEdits.length) || isLoading;

        return (
          <Modal
            width={768}
            visible={visible}
            destroyOnClose={true}
            onCancel={() => {
              errorProps.hide();
              closeReviewTerms();
              resetForm();
            }}
            title={
              <Typography.Title level={5}>
                <Translation text="arbitral_award.send_your_review.text" /> ({selectedAward?.attributes?.file?.name})
              </Typography.Title>
            }
            footer={
              <Button
                loading={isLoading}
                disabled={disableSubmit}
                type="primary"
                onClick={() => errorProps.hideAndCall(onSubmit(values, resetForm))}
              >
                <Translation pad={true} text="general.send.button" />
              </Button>
            }
          >
            <ModalWrapper>
              <Highlights />
              <Form layout="vertical">
                <Space>
                  <FormItem name="requestStatus">
                    <Radio.Group onChange={handler("requestStatus")}>
                      <Radio value={HUB_APPROVED_WITHOUT_EDITS}>
                      <Translation text="arbitral_award.accept_draft.text" />
                      </Radio>
                      <Radio value={HUB_REQUESTED_EDITS}>
                      <Translation text="arbitral_award.suggest_edits.text" />
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                </Space>
              {values.requestStatus === HUB_REQUESTED_EDITS && (
                  <FormItem
                    style={{ minHeight: 80 }}
                    label={<Translation text="arbitral_award.mention_the_edits.text" />}
                    name="requestedEdits"
                    {...apiErrorOf("requestedEdits", true)}
                  >
                    <Editor onChange={handler("requestedEdits")} className="jur-quill-editor-small" />
                  </FormItem>
                )}
              </Form>
            </ModalWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
}

const initReviewObject = (selectedAward) => {
  return {
    awardId: selectedAward ? selectedAward?.id : null,
    requestStatus: null,
    requestedEdits: "",
  };
};
