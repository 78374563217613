import { Badge, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { DRAFT } from "../../redux/constants/DisputeStatuses";
import Amount from "../Common/Amount";
import DateTime from "../Common/DateTime";
import { disputeStatusColor } from "../Common/DisputeStatus";
import Translation from "../Common/Translation";
import UserRole from "../Common/UserRole";
import "./styles.less";

const columnBindings = {
  caseNumber: {
    title: "dispute.case_number.column",
    dataIndex: ["attributes", "caseNumber"],
    render: (n) => (
      <Typography.Text className="jur-case-number">{n}</Typography.Text>
    ),
  },
  filledOn: {
    title: "dispute.started_on.column",
    dataIndex: ["attributes", "filledOn"],
    render: (d) => (
      <Typography.Text className="jur-case-last-update">
        <DateTime epochSeconds={d} onlyDate={true} />
      </Typography.Text>
    ),
  },
  status: {
    title: "dispute.status.column",
    dataIndex: ["attributes", "status"],
    render: (s) => (
      <Badge
        color={disputeStatusColor(s)}
        text={<span className="jur-case-status">{s}</span>}
      />
    ),
    // render: (s) => <DisputeStatus status={s} />,
  },
  name: {
    title: "dispute.name.column",
    dataIndex: ["attributes", "name"],
    render: (name) => (
      <Typography.Text
        ellipsis={{ tooltip: name }}
        level={2}
        className="jur-case-name"
      >
        {name}
      </Typography.Text>
    ),
  },
  claimValue: {
    title: "dispute.claim_value.column",
    dataIndex: ["attributes", "claimValue"],
    render: (c) => (
      <span className="jur-case-claim-value">
        <Amount {...c} />
      </span>
    ),
  },
  // hubName: {
  //   title: "dispute.hub.column",
  //   dataIndex: ["attributes", "hubName"],
  // },
  role: {
    title: "dispute.role.column",
    dataIndex: ["attributes"],
    render: ({ role, group }) => <UserRole role={role} group={group} />,
  },
  lastUpdate: {
    title: "dispute.last_update.column",
    dataIndex: ["attributes", "lastUpdate"],
    render: (d) => (
      <Typography.Text className="jur-case-last-update">
        <DateTime epochSeconds={d} onlyDate={true} />
      </Typography.Text>
    ),
  },
  action: {
    title: "general.action.column",
    dataIndex: ["attributes"],
    render: action,
  },
  arbitratorName: {
    title: "dispute.arbitrator.column",
    dataIndex: ["attributes", "arbitratorName"],
  },
  paymentStatus: {
    title: "dispute.payment_status.column",
    dataIndex: ["attributes", "paymentStatus"],
  },
  disputeFee: {
    title: "dispute.dispute_fee.column",
    dataIndex: ["attributes", "disputeFee"],
    align: "right",
    render: (c) => <Amount {...c} />,
  },
};

const UserColumns = [
  "caseNumber",
  "name",
  "role",
  "filledOn",
  "claimValue",
  "status",
  "lastUpdate",
  "action",
  // "hubName",
];

const HubAdminColumns = [
  "caseNumber",
  "filledOn",
  "status",
  "name",
  "arbitratorName",
  "paymentStatus",
  "disputeFee",
  "claimValue",
  "action",
];

const HubAdminRequestedColumns = [
  "caseNumber",
  "filledOn",
  "name",
  "category",
  "paymentStatus",
  "disputeFee",
  "claimValue",
  "action",
];

const ArbitratorColumns = [
  "caseNumber",
  "filledOn",
  "status",
  "name",
  // "hubName",
  "lastUpdate",
  "action",
];

const ArbitratorRequestedColumns = [
  "caseNumber",
  "filledOn",
  "assignedOn",
  "name",
  // "hubName",
  "claimValue",
  "lastUpdate",
  "action",
];

export function acceptedColumns(role) {
  const roleWise =
    {
      "Hub Admin": HubAdminColumns,
      Arbitrator: ArbitratorColumns,
    }[role] || UserColumns;

  return columnsFrom(roleWise);
}

export function requestedColumns(role) {
  const roleWise =
    {
      "Hub Admin": HubAdminRequestedColumns,
      Arbitrator: ArbitratorRequestedColumns,
    }[role] || [];

  return columnsFrom(roleWise);
}

function columnsFrom(keys) {
  return keys
    .map((key) => columnBindings[key])
    .filter(Boolean)
    .map((column) => ({
      ...column,
      title: <Translation text={column.title} />,
    }));
}

function action(attributes, dispute) {
  if (attributes.status === DRAFT) {
    return (
      <Link to={`/${attributes.hubUrl}/dispute-filing/${dispute.id}`}>
        <Typography.Text className="jur-link">
          <Translation text="dispute.continue_filing.text" />
        </Typography.Text>
      </Link>
    );
  }

  return (
    <Link to={`/${attributes.hubUrl}/disputes/${dispute.id}`}>
      <Typography.Text className="jur-link">
        <Translation text="dispute.view_more.text" />
      </Typography.Text>
    </Link>
  );
}
