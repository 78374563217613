import * as types from "../constants/constants";

export const handleStepBack = () => ({
  type: types.RESPONSEFILING_HANDLE_STEP_BACK,
});

export const handleStepChange = (step) => ({
  type: types.RESPONSEFILING_HANDLE_STEP_CHANGE,
  payload: { step },
});

export const handleStartResponseFiling = () => ({
  type: types.RESPONSEFILING_HANDLE_START,
});

export const postCreate = (disputeId, body) => ({
  type: types.RESPONSEFILING_POST_CREATE,
  payload: { disputeId, body },
});

export const handleCreate = () => ({
  type: types.RESPONSEFILING_HANDLE_CREATE,
});

export const handleError = (e) => ({
  type: types.RESPONSEFILING_HANDLE_ERROR,
  error: e,
});

export const handleUpdateMainRespondentForm = (mainRespondent) => ({
  type: types.RESPONSEFILING_HANDLE_UPDATE_MAINRESPONDENT_FORM,
  payload: mainRespondent,
});

export const handleUpdateResponseFilesStatus = (uid, file) => ({
  type: types.RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_STATUS,
  payload: { uid, file },
});

export const handleUpdateResponseFilesDescription = (file) => ({
  type: types.RESPONSEFILING_HANDLE_UPDATE_RESPONSEFILES_DESCRIPTION,
  payload: { file },
});

function showOf(crumb) {
  return (disputeId) => ({
    type: types[`RESPONSEFILING_GET_SHOW_${crumb}`],
    payload: { disputeId },
  });
}

function updateOf(crumb) {
  return (disputeId, body, hubSlug, goBack) => ({
    type: types[`RESPONSEFILING_PUT_UPDATE_${crumb}`],
    payload: { disputeId, body, hubSlug, goBack },
  });
}

function handleOf(crumb) {
  return (data) => ({
    type: types[`RESPONSEFILING_HANDLE_${crumb}`],
    payload: data,
  });
}

function actionCreatorsOf(crumb) {
  const capitalizedCrumb = crumb.toUpperCase();
  return {
    [`getShow${crumb}`]: showOf(capitalizedCrumb),
    [`putUpdate${crumb}`]: updateOf(capitalizedCrumb),
    [`handleShow${crumb}`]: handleOf(`SHOW_${capitalizedCrumb}`),
    [`handleUpdate${crumb}`]: handleOf(`UPDATE_${capitalizedCrumb}`),
    [`handleUpdate${crumb}Form`]: handleOf(`UPDATE_${capitalizedCrumb}_FORM`),
  };
}

export const {
  getShowDefenceTeam,
  putUpdateDefenceTeam,
  handleShowDefenceTeam,
  handleUpdateDefenceTeam,
  handleUpdateDefenceTeamForm,
} = actionCreatorsOf("DefenceTeam");

export const {
  getShowKyc,
  putUpdateKyc,
  handleShowKyc,
  handleUpdateKyc,
  handleUpdateKycForm,
} = actionCreatorsOf("Kyc");

export const {
  getShowRespondent,
  putUpdateRespondent,
  handleShowRespondent,
  handleUpdateRespondent,
  handleUpdateRespondentForm,
} = actionCreatorsOf("Respondent");

export const {
  getShowResponseDetails,
  handleShowResponseDetails,
  putUpdateResponseDetails,
  handleUpdateResponseDetails,
  handleUpdateResponseDetailsForm,
} = actionCreatorsOf("ResponseDetails");

export const {
  getShowResponseFiles,
  putUpdateResponseFiles,
  handleShowResponseFiles,
  handleUpdateResponseFiles,
  handleUpdateResponseFilesForm,
} = actionCreatorsOf("ResponseFiles");

export const {
  getShowInvites,
  putUpdateInvites,
  handleShowInvites,
  handleUpdateInvites,
  handleUpdateInvitesForm,
} = actionCreatorsOf("Invites");

export const {
  getShowPayment,
  handleShowPayment,
  handleUpdatePayment,
  handleUpdatePaymentForm,
} = actionCreatorsOf("Payment");

export const putUpdatePayment = (disputeId, pendingAmount, payload) => {
  const body = {
    feeSplitPercentage: payload.feeSplitPercentage,
    transaction: {
      amount: {
        value: pendingAmount.value,
        currency: pendingAmount.currency,
      },
      reference: payload["transaction.reference"],
      receiptFileId: payload["transaction.receiptFileId"],
    },
  };

  return {
    type: types.RESPONSEFILING_PUT_UPDATE_PAYMENT,
    payload: { disputeId, body },
  };
};

export const {
  getShowSod,
  putUpdateSod,
  handleShowSod,
  handleUpdateSod,
} = actionCreatorsOf("Sod");

export const { handleUpdateCompleted } = actionCreatorsOf("Completed");

export const putUpdateCompleted = (disputeId, extras) => ({
  type: types.RESPONSEFILING_PUT_UPDATE_COMPLETED,
  payload: { disputeId, ...extras },
});

export const handleSignatureError = (disputeId, params) => ({
  type: types.RESPONSEFILING_HANDLE_SIGNATURE_ERROR,
  payload: { disputeId, params },
});
