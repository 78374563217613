import * as types from "../constants/constants";

export const uploadUrlsFor = (fileNames) => ({
  type: types.FILE_POST_CREATE,
  payload: { fileNames },
});

export const handleReset = () => ({
  type: types.FILE_HANDLE_RESET,
});

export const getShow = (id) => ({
  type: types.FILE_GET_SHOW,
  payload: { id },
});

export const getInlineShow = (id) => ({
  type: types.FILE_GET_INLINE_SHOW,
  payload: { id },
});

export const putUpdate = (id, body, onDone) => ({
  type: types.FILE_PUT_UPDATE,
  payload: { id, body: { ...body, type: body.type, walkaby: true }, onDone },
});

export const handleCreate = (file) => ({
  type: types.FILE_HANDLE_CREATE,
  payload: file,
});

export const handleUpdate = (file) => ({
  type: types.FILE_HANDLE_UPDATE,
  payload: file,
});

export const handleShow = (file) => ({
  type: types.FILE_HANDLE_SHOW,
  payload: file,
});

export const handleInlineShow = (file) => ({
  type: types.FILE_HANDLE_INLINE_SHOW,
  payload: file,
});

export const deleteFile = (id) => ({
  type: types.FILE_DELETE,
  payload: { id },
});

export const handleError = (e) => ({
  type: types.FILE_HANDLE_ERROR,
  error: e,
});
